import {NgModule} from '@angular/core';

import {InformationAssureTableComponent} from './information-assure-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports: [InformationAssureTableComponent],
  declarations: [InformationAssureTableComponent],
  providers: [],
})
export class InformationAssureTableModule {
}

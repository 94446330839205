import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptonPecListeFeatureModule} from './liste/prescripton-pec-liste.feature.module';
import { PrescriptionPecFormComponent } from './prescription-pec-form/prescription-pec-form.component';
import { PrescriptionPecDetailsListeFeatureModule } from './prescription-pec-details/prescription-pec-details-liste.feature.module';

@NgModule({
  imports: [
      CommonModule,
      SharedModule,
      PrescriptonPecListeFeatureModule,
      PrescriptionPecDetailsListeFeatureModule,
  ],
  declarations: [PrescriptionPecFormComponent],
  exports: [PrescriptionPecFormComponent]
})
export class PrescriptonPecFeatureModule {
}

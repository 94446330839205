export interface Stock {
  letter: string;
  value: number;
}

export const STOCKS: Stock[] = [
  {letter: 'a', value: 110.73},
  {letter: 'b', value: 114.01},
  {letter: 'c', value: 114.38},
  {letter: 'd', value: 110.97},
  {letter: 'e', value: 210.58},
  {letter: 'f', value: 211.98},
  {letter: 'g', value: 210.11},
  {letter: 'h', value: 207.72},
  {letter: 'aa', value: 310.73},
  {letter: 'ba', value: 314.01},
  {letter: 'ca', value: 314.38},
  {letter: 'da', value: 310.97},
  {letter: 'ea', value: 410.58},
  {letter: 'fa', value: 411.98},
  {letter: 'ga', value: 410.11},
  {letter: 'ha', value: 407.72},
  {letter: 'ab', value: 110.73},
  {letter: 'bb', value: 114.01},
  {letter: 'cb', value: 114.38},
  {letter: 'db', value: 110.97},
  {letter: 'eb', value: 210.58},
  {letter: 'fb', value: 211.98},
  {letter: 'gb', value: 210.11},
  {letter: 'hb', value: 207.72},
  {letter: 'ad', value: 310.73},
  {letter: 'bd', value: 314.01},
  {letter: 'cd', value: 314.38},
  {letter: 'dd', value: 310.97},
  {letter: 'ed', value: 410.58},
  {letter: 'fd', value: 411.98},
  {letter: 'gd', value: 410.11},
  {letter: 'hd', value: 407.72},
];

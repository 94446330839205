import {Component, Input, OnInit} from '@angular/core';
import {InformationAssureTableListeMetierService} from './information-assure-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-information-assure-table-liste',
  templateUrl: './information-assure-table.liste.component.html'
})

export class InformationAssureTableListeComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: InformationAssureTableListeMetierService;
  @Autowired() metierService: InformationAssureTableListeMetierService;

  @Input('currentDataUser') currentDataUser: any = {};
  searchForm = new FormGroup({});
  infoUser: any;

  constructor() {
    super();
    this.tableActions = [];
  }

  ngOnInit() {
    this.initFormulaire();
  }

  initFormulaire() {
    this.metierService.recupererInfoUserPoursuiteSelectionner(this.currentDataUser.idSouscriptionLigne)
      .subscribe(response => {
        this.currentDataUser = response.body.items[0]
        this.searchForm = this.metierService.initialiserFormulaire(this.currentDataUser);
      });
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {DemandePecComponent} from './demande-pec.component';
import {SharedModule} from '@shared/shared.module';
import {DemandePriseChargeListeFeatureModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/liste/demande-prise-charge-liste.feature.module';
import {PoursuitePriseChargeFeatureModule} from '@app/fonctionnalites/demande-pec/poursuite-prise-charge/poursuite-prise-charge.feature.module';

const routes: Routes = [
    {path: '', component: DemandePecComponent, data: {breadcrumb: 'Demande PEC'}}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    DemandePriseChargeListeFeatureModule,
    PoursuitePriseChargeFeatureModule
  ],
    declarations: [
        DemandePecComponent
    ]
})
export class DemandePecModule {
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {HospitalisationAutreTableRestApiService} from './hospitalisation-autre-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class HospitalisationAutreTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: HospitalisationAutreTableRestApiService;
  @Autowired() formBuilder: FormBuilder;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  requeteRecuperationTypeHospitalisation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationTypeHospitalisation(data, args).pipe(
        map((response: HttpResponse<any>) => {
          response.body.items.forEach(v => {
            v.label = v.libelle;
            v.value = v.libelle;
          });
          return response;
        })
    );
  }

  requeteRecuperationChambreHospitalisation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationChambreHospitalisation(data, args).pipe(
        map((response: HttpResponse<any>) => {
          response.body.items.forEach(v => {
            v.label = v.libelle;
            v.value = v.libelle;
          });
          return response;
        })
    );
  }

  initialiserFormulaireHospitalisation<T>(datas: T | any, args?: any): FormGroup {
    // !!datas && !!datas.medecin ? datas.medecin : null
    const data: any = {
      montant: null,
      accordPrealable: null,
      chambre: null,
      nombreJour: null,
      prixUnitaireJour: null,
      montantHorsPec: null,
      ticketModerateur: null,
      nombreChambre: null,
      service: null,
    };

    return this.formBuilder.group({
      montant: [{value: data.montant, disabled: true}],
      accordPrealable: [{value: data.accordPrealable, disabled: true}],
      chambre: data.chambre,
      nombreJour: data.nombreJour,
      prixUnitaireJour: [{value: data.prixUnitaireJour, disabled: true}],
      montantHorsPec: [{value: data.montantHorsPec, disabled: true}],
      ticketModerateur: [{value: data.ticketModerateur, disabled: true}],
      numeroChambre: data.numeroChambre,
      service: data.service,
    });
  }

  initialiserFormulairePharmacie<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      montant: null,
      accordPrealable: null,
      montantHorsPec: null,
      ticketModerateur: null,
    };

    return this.formBuilder.group({
      montant: data.montant,
      accordPrealable: [{value: data.accordPrealable, disabled: true}],
      montantHorsPec: [{value: data.montantHorsPec, disabled: true}],
      ticketModerateur: [{value: data.ticketModerateur, disabled: true}],
    });
  }

  requeteCalculMontant(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteCalculMontant(data, args);
  }

}

import {Component, OnInit} from '@angular/core';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {Autowired} from "@angular-ru/autowired";
import {PrescriptionFactureMetierService} from "./prescription-facture.metier.service";

@Component({
  selector: 'app-prescriptions-facture',
  templateUrl: './prescriptions-facture.component.html'
})
export class PrescriptionsFactureComponent extends SgiListLayoutComponent implements OnInit {

  @Autowired() recuperationListeDonneesMetier: PrescriptionFactureMetierService;
  @Autowired() convertirFormulaireMetier: PrescriptionFactureMetierService;
  @Autowired() initialiserFormulaireMetier: PrescriptionFactureMetierService;
  @Autowired() requeteCreationMetier: PrescriptionFactureMetierService;
  @Autowired() requeteModificationMetier: PrescriptionFactureMetierService;
  @Autowired() metierService: PrescriptionFactureMetierService;

  colDetails: any[];
  dataDetails: any[];

  constructor() {
    super();

  }

  ngOnInit() {
    this.cols = [
      {field: 'libelle', header: 'Lib. Medicament'},
      {field: 'quantiteRealise', header: 'Qte'},
      {field: 'prixUnitaire', header: 'Prix unitaire', type: () => 'price'},
      {field: 'montantDette', header: 'MT Dette', type: () => 'price'},
      {field: 'montantExclu', header: 'Montant Exclu', type: () => 'price'},
      {field: 'partAssureur', header: 'Part Assurance', type: () => 'price'},
      {field: 'partAssure', header: 'Part Assure', type: () => 'price'},
    ];

    this.notRowActions = [];
    this.tableActions = []

    this.colDetails = [
      {field: 'libelleTermeConvention', header: 'Produit'},
      {field: 'taux', header: 'Taux Couverture'},
      {field: 'montantPrescriptionBrut', header: 'Montant', type: () => 'price'},
      {field: 'quantite', header: 'Qte'},
      {field: 'montantHorsPEC', header: 'Part Hors Pec', type: () => 'price'},
      {field: 'montantExclu', header: 'Montant Exclu', type: () => 'price'},
      {field: 'partAssurance', header: 'MT. Assurance', type: () => 'price'},
      {field: 'partAssure', header: 'MT. Assure', type: () => 'price'},
    ];

  }

  selectCurrentRow($event) {
    this.dataDetails = $event.facturationPrescriptions
  }

}

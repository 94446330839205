import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';

export type ListFormErrorType = 'required'|'invalidEmail'|'minLength'|'maxLength'|'mismatchedPasswords'|'email'|'pattern'|'min'|'max'|'ibanValidator';

@Component({
  selector: 'app-list-form-error',
  template: `
    <app-form-error [control]="control"
                    [condition]="control?.errors?.required || (submitted && control?.errors?.required)">
      <span *ngIf="hasMessage('required')">{{findMessage('required')?.message}}</span>
      <span *ngIf="!hasMessage('required')">{{"Ce champs est réquis."}}</span>
    </app-form-error>

    <app-form-error [control]="control"
                    [condition]="control?.hasError('ibanValidator') || (submitted && control?.hasError('ibanValidator'))">
      <span *ngIf="hasMessage('ibanValidator')">{{findMessage('ibanValidator')?.message}}</span>
      <span *ngIf="!hasMessage('ibanValidator')">{{"Le numéro IBAN est invalide"}}</span>
    </app-form-error>

    <app-form-error [control]="control"
                    [condition]="control?.hasError('email') || (submitted && control?.hasError('email'))">
      <span *ngIf="hasMessage('email')">{{findMessage('email')?.message}}</span>
      <span *ngIf="!hasMessage('email')">{{"L'adresse email est invalide."}}</span>
    </app-form-error>

    <app-form-error [control]="control"
                    [condition]="control?.hasError('minlength') || (submitted && control?.hasError('minlength'))">
      <span *ngIf="hasMessage('minLength')">{{findMessage('minLength')?.message}}</span>
      <span *ngIf="!hasMessage('minLength')">{{"Le nombre de caractère minimal de ce champs n'est pas atteint."}}</span>
    </app-form-error>

    <app-form-error [control]="control"
                    [condition]="control?.hasError('maxlength') || (submitted && control?.hasError('maxlength'))">
      <span *ngIf="hasMessage('maxLength')">{{findMessage('maxLength')?.message}}</span>
      <span *ngIf="!hasMessage('maxLength')">{{"Le nombre de caractère maximal de ce champs est excédé."}}</span>
    </app-form-error>

    <app-form-error [control]="control"
                    [condition]="control?.hasError('min') || (submitted && control?.hasError('min'))">
      <span *ngIf="hasMessage('min')">{{findMessage('min')?.message}}</span>
      <span *ngIf="!hasMessage('min')">{{"La valeur minimale de ce champs n'est pas atteinte."}}</span>
    </app-form-error>

    <app-form-error [control]="control"
                    [condition]="control?.hasError('max') || (submitted && control?.hasError('max'))">
      <span *ngIf="hasMessage('max')">{{findMessage('max')?.message}}</span>
      <span *ngIf="!hasMessage('max')">{{"La valeur maximale de ce champs est excédée."}}</span>
    </app-form-error>

    <app-form-error [control]="control"
                    [condition]="control?.hasError('mismatchedPasswords') || (submitted && control?.hasError('mismatchedPasswords'))">
      <span *ngIf="hasMessage('mismatchedPasswords')">{{findMessage('mismatchedPasswords')?.message}}</span>
      <span *ngIf="!hasMessage('mismatchedPasswords')">{{"Les mots de passe sont différents."}}</span>
    </app-form-error>

    <app-form-error [control]="control"
                    [condition]="control?.errors?.pattern || (submitted && control?.errors?.pattern)">
      <span *ngIf="hasMessage('pattern')">{{findMessage('pattern')?.message}}</span>
      <span *ngIf="!hasMessage('pattern')">{{"Entrez des données correctes."}}</span>
    </app-form-error>
  `
})
export class ListFormErrorComponent {
  @Input('submitted') submitted = false;
  @Input('control') control: AbstractControl = new FormControl();
  @Input('types') types: ListFormErrorType[] = ['required', 'invalidEmail', 'minLength', 'maxLength', 'mismatchedPasswords', 'email', 'pattern', 'min', 'max', 'ibanValidator'];
  @Input('messages') messages: {type: ListFormErrorType, message: string}[] = [];

  hasType(typ: ListFormErrorType): boolean {
    return !ClvTsUtilsCommonsJs.isUndefinedOrNull(this.types.find(v => v === typ));
  }

  hasMessage(typ: ListFormErrorType): boolean {
    return !ClvTsUtilsCommonsJs.isUndefinedOrNull(this.messages.find(m => m.type === typ));
  }

  findMessage(type: ListFormErrorType) {
    return this.messages.find(v => v.type === type);
  }
}

import {Component} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {UtilisateurProfilMetierService} from '../utilisateur-profil.metier.service';
import {ClvFileModel} from 'clv-ts-utils';
import {FullFormComponent} from '@shared/behaviors/full-form.component';

@Component({
    selector: 'app-utilisateur-profil-full-form',
    templateUrl: './utilisateur-profil.full-form.component.html'
})
export class UtilisateurProfilFullFormComponent extends FullFormComponent {
    @Autowired() convertirFormulaireMetier: UtilisateurProfilMetierService;
    @Autowired() initialiserFormulaireMetier: UtilisateurProfilMetierService;
    @Autowired() modificationFullFormulaireMetier: UtilisateurProfilMetierService;
    // @Autowired() recuperationFullFormDonneesMetier: UtilisateurPasswordMetierService;
    @Autowired() metierService: UtilisateurProfilMetierService;

    imageModel: ClvFileModel;
    updatePassword: boolean;

    requeteEnregistrementUpdateDataUser<T>(): any | T {
        this.modificationFullFormulaireMetier.modificationFullFormulaire(this.getArgs());
    }

    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args, imageModel: this.imageModel};
        this.args = {...arg};
    }

    switchOnChange() {
        this.updatePassword = this.formGroup.getRawValue().updatePassword === true;
    }
}

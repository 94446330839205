import {TableColumnState} from '@shared/components/tables/atlantis-table/atlantis-table.models';

/**
 * Utilitaire des composant tables
 */
export class TableUtilities {
  /**
   * Converti la liste des états du tableau pour l'afficher dans un dropdown
   * @param states
   */
  public static statesToDropdown(states: TableColumnState[]): {label: string, value: any}[] {
    try {
      const result: {label: string, value: any}[] = [];
      states.forEach(state => {
        result.push({value: state.key, label: state.label});
      });
      return result;
    } catch (e) {
      return [];
    }
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormInputComponent} from '@shared/components/inputs/form-input/form-input.component';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {TranslateModule} from '@ngx-translate/core';
import {ListFormErrorComponentModule} from '@shared/components/error/list-form-error/list-form-error.component.module';
import {ButtonModule} from 'primeng/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputTextModule} from 'primeng/inputtext';

@NgModule({
    imports: [
        CommonModule,
        InfoBullWidgetComponentModule,
        TranslateModule,
        ListFormErrorComponentModule,
        ButtonModule,
        InputTextModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        FormInputComponent,
    ],
    exports: [
        FormInputComponent,
        InfoBullWidgetComponentModule,
        TranslateModule,
        ListFormErrorComponentModule,
        ButtonModule,
        InputTextModule,
        FormsModule,
        ReactiveFormsModule,
    ]
})
export class FormInputComponentModule {
}

import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import { DetailsDocumentTableListeComponent } from './details-document-table.liste.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [DetailsDocumentTableListeComponent],
  declarations: [DetailsDocumentTableListeComponent],
  providers: [],
})
export class DetailsDocumentTableListeModule {
}

import {Component, OnInit} from '@angular/core';
import {ListLayoutComponent} from '@shared/behaviors/list-layout/list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {LazyLoadEvent} from 'primeng/api';
import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {ISgiSuppressionFormulaireMetier} from '@app/core/interfaces/metier/i-sgi-suppression-formulaire-metier';
import {Autowired} from '@angular-ru/autowired';

/**
 * Gestion du processus des listes avec la méthode SGI.
 * A la modification, la donnée du tableau est prise directement
 *
 * Liste des services metiers à implementer:
 * * suppressionDonneeMetier: ISuppressionDonneeMetier
 * * recuperationListeDonneesMetier: IRecuperationListeDonneesMetier
 * * sgiSuppressionFormulaireMetier: ISgiSuppressionFormulaireMetier
 */
@Component({
  selector: 'app-sgi-list-layout',
  template: ''
})
export class SgiListLayoutComponent extends ListLayoutComponent implements OnInit {
  /**
   * Service conception du formualire de suppression de données
   */
  @Autowired() sgiSuppressionFormulaireMetier: ISgiSuppressionFormulaireMetier;

  ngOnInit() {
    this.initialiserFormulaire();
    this.requetteObtentionDatasource = () =>
        this.recuperationListeDonneesMetier.requeteRecuperationListeDonnees(this.getAllRequestParam(),
            {...this.getArgs()});
    this.selectedColumns = this.cols;
    // this.recuperDonnees();
    this.selectedColumns = this.cols;
    this.getListElements();
  }

  /**
   * Recupère tous les paramètres de la requête. Cette méthode est passée à la requète de récupération des données.
   */
  getAllRequestParam<T>(): any | T {
    const arg: LazyLoadEvent = this.getArgs().lazyLoadEvent;
    try {
      if (!!arg && (!!arg.filters || !!arg.sortField || arg.globalFilter)) {
        return CommonUtilities.GenerateParamsRequest(arg);
      }
      return CommonUtilities.RequestSw(arg.first, this.listeStateService.getPerPage());
    } catch (e) {
      return CommonUtilities.RequestSw(0, this.listeStateService.getPerPage());
    }
  }

  /**
   * récupère la liste des données
   * @param data
   */
  recuperDonnees(data?: any) {
    this.defineArgs();
    this.loading = true;
    try {
      const da = {...this.args, lazyLoadEvent: data, successMute: true};
      this.args = {...da};
    } catch (e) {
    }
    this.requetteObtentionDatasource = () => this.recuperationListeDonneesMetier.requeteRecuperationListeDonnees(this.getAllRequestParam(),
        {...this.getArgs()});
    this.requetteObtentionDatasource().subscribe((response: any) => {
      this.dataSource = response.body.items;
      this.totalRecords = response.body.count;
      if (this.dataSource && this.dataSource.length > 0) {
        this.dataSource.forEach((dat, index) => {
          dat.id_key = index + 1;
          dat.rowCount = index + 1;
        });
      }
      // this.dataSource = (response.body.items.length > 0) ? response.body.items : [response.body.items];
      this.dataSourceChange.emit(this.dataSource);
      this.loading = false;
    });
  }

  /**
   * Réquête de suppression d'un élément.
   * @param data
   */
  requeteSuppression(data: any): Observable<HttpResponse<any>> {
    return this.suppressionDonneeMetier
    .requeteSuppression(CommonUtilities
    .sgiSaveData(this.sgiSuppressionFormulaireMetier.sgiSuppressionFormulaire(data)), {...this.getArgs()});
  }
}

export const EXCEPTIONS = {
  'FichierIllegal': 'Fichier non autorisé.',
  'MotPasseDejaUtilise': 'Ce mot de passe a déjà été utilisé.',
  'UsernameNotFoundException': 'Ce compte utilisateur n\'existe pas.',
  'LoginDejaUtiliseException': 'Nom d\'utilisateur invalide.',
  'NoSuchElementException': 'L\'information cherchée n\'est pas trouvée.',
  'ConcurrencyFailureException': 'Cette ressource est déjà utilisée.',
  'AucuneCorrespondanceCredential': 'Informations de connexion incorrectes.',
  'SuppresionImpossible': 'Impossible de supprimer cet élément car il est déjà utilisé dans un autre processus.',
  'ObjetNonTrouver': 'L\'information cherchée n\'est pas trouvée.',
  'NonTrouve': 'L\'information cherchée n\'est pas trouvée.',
  'TenantExistant': 'Cette clé de licence est déjà utilisée.',
  'AuthenticationException': 'Echec d\'authentification.',
  'AuthentificationEchouer': 'Echec d\'authentification.',
  'InsufficientAuthenticationException': 'Paramètres d\'authentification incomplets',
  'BadCredentialsException': 'Informations de connexion incorrectes.',
  'MethodArgumentNotValidException': 'Vous devez renseigner obligatoirement les champs suivants: ',
  'PremiereDateArreteCalendrierFondsInvalide': 'La première date d\'arrêté du fonds est invalide',
  'ObjetExisteDejaException': 'L\'objet existe dejà !',
  'ConstraintViolationException': 'L\'objet existe dejà !',
  'DateEcheanceCalendrierFondsInvalide': 'Date d\'échéance invalide',
  'ImpossibleDeGenererCalendrierGestion': 'Impossible de générer le calendrier de gestion',
  'FondsPossedeCalendrierGestion': 'Un calendrier de gestion existe dejà pour ce fonds !',
  'ModeFraisFonctionnementInconnu': 'Mode de frais de fonctionnement invalide!',
  'PeriodiciteFraisFonctionnementInvalide': 'Périodicité invalide !',
  'ValeurOffsetInvalide': 'Offset invalide !',
  'CompartimentIncompatibleAvecFonds': 'Informations incompatibles avec celles du fonds maitre !',
  'WaterfallAtteindLimiteDefinieDansCompte': 'Limite de waterfall dejà atteinte !',
  'FraisFonctionnementAvecMemeEvenementException': 'Vous ne pouvez enregistrer deux frais de même nature !',
  'OperationLignePlacementNonSupporte': 'L\'opération que vous tentez de faire n\'est pas supportée !'
};

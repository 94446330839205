import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ModaleHospitalisationMetierService} from './modale-hospitalisation.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
  selector: 'app-modale-hospitalisation',
  templateUrl: './modale-hospitalisation.component.html'
})

export class ModaleHospitalisationComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() metierService: ModaleHospitalisationMetierService;

  @Input() modalManager: any;
  tabActive = 'Informations';
  @Output() dataHospitalisationEvent = new EventEmitter<any[]>();

  documentAjouter: any[] = [];
  @Output() documentAjouterEvent = new EventEmitter<any[]>();

  dataListActeSoin: any[] = [];
  @Output() dataListActeSoinEvent = new EventEmitter<any[]>();

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (!CommonUtilities.isDataNullUndefinedFalseEmpty(this.modalManager) &&
        this.modalManager.datasourceSelected.reponseAdherant.listDocumentExamens) {

      this.documentAjouter = [...this.documentAjouter, ...this.modalManager.datasourceSelected.reponseAdherant.listDocumentExamens];
      this.documentAjouterEvent.emit(this.documentAjouter);
    }
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  /**
   *
   * Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
   *
   */
  getTabChange(event) {
    this.tabActive = event.originalEvent.target['innerText'];
  }

  handleDataHospitalisation(event: any) {
    this.modalManager.datasourceSelected = event;
    this.documentAjouter = this.modalManager.datasourceSelected.reponseAdherant.listDocumentExamens;

    this.dataHospitalisationEvent.emit(this.modalManager);
  }

  handleDataListActeSoin(event: any) {
    this.dataListActeSoin = event;
    this.dataListActeSoinEvent.emit(this.dataListActeSoin);
  }

  handleDocumentAjouter(event: any[]) {
    this.documentAjouter = event;
    this.documentAjouterEvent.emit(this.documentAjouter);
  }

}

import {TableColumn} from '@shared/components/tables/atlantis-table/atlantis-table.models';

export const columns: Array<TableColumn> = [
  {field: 'photo', header: 'Photo', type: () => 'image'},
  {field: 'raisonSocialeGarant', header: 'Garant'},
  {field: 'nomCompletPersonne', header: 'Assuré'},
    {field: 'matricule', header: 'Matricule adhérant'},

  // {field: 'estHospitalisation', header: 'Hospit', type: () => 'dropdown', dropdownList: () => BOOLEAN_TYPE_COMBO},
  /*{field: 'estHospitalisation', header: 'Hospit', type: () => 'chip', states: [
    {key: true, label: 'Oui', classes: 'bg-success-imp'},
    {key: false, label: 'Non', classes: ''},
  ]},
  {field: 'libelleTypeStatut', header: 'Status', type: () => 'chip', states: [
      {key: STATUT_EN_ACCORD_PREALABLE, label: 'En accord préalable', classes: 'color-row-red-PEC'},
      {key: STATUT_EN_ATTENTE_DE_CLOTURE, label: 'En attente de clôture', classes: 'bg-warning-imp'},
      {key: STATUT_CLOTURE, label: 'Clôturé', classes: 'bg-success-imp'},
      {key: STATUT_REJETE, label: 'Rejete', classes: 'bg-amber-imp'},
      {key: STATUT_EN_VALIDATION_AVIS_HOSPI, label: 'En validation de l\'avis d\'hospit.', classes: 'color-row-red-PEC'},
    ]},*/
];

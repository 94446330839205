import {NgModule} from '@angular/core';

import {PrescriptionDossierTableComponent} from './prescription-dossier-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionRealisePharmacieDetailTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prescription-dossier-table/prescription-realise-pharmacie-detail-table/prescription-realise-pharmacie-detail-table.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PrescriptionRealisePharmacieDetailTableModule,
    ],
  exports: [PrescriptionDossierTableComponent],
  declarations: [PrescriptionDossierTableComponent],
  providers: [],
})
export class PrescriptionDossierTableModule {
}

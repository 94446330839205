import {Component, OnInit} from '@angular/core';
import {LazyLoadEvent} from 'primeng/api';
import {ListLayoutComponent} from '@shared/behaviors/list-layout/list-layout.component';

/**
 * Gestion du processus des listes. Recupère les par pages effectuant ainsi une pagination serveur.
 * A la modification, la donnée du tableau est prise directement.
 *
 * Liste des services metiers à implementer:
 * * suppressionDonneeMetier: ISuppressionDonneeMetier
 * * recuperationListeDonneesMetier: IRecuperationListeDonneesMetier
 */
@Component({
  selector: 'app-list-layout-lazy',
  template: ''
})

export class ListLayoutLazyComponent extends ListLayoutComponent implements OnInit {

  ngOnInit() {
    super.ngOnInit();
    this.selectedColumns = this.cols;
    this.getListElements();
  }

  /**
   * récupère la liste des données
   * @param data
   */
  recuperDonnees(data?: any | LazyLoadEvent) {
    this.defineArgs();
    this.loading = true;
    try {
      const da = {...this.args, lazyLoadEvent: data};
      this.args = {...da};
    } catch (e) {
    }
    this.requetteObtentionDatasource = () => this.recuperationListeDonneesMetier.requeteRecuperationListeDonnees(this.getAllRequestParam(),
        {...this.getArgs()});
    this.requetteObtentionDatasource().subscribe((response: any) => {
      this.dataSource = response.body;
      this.totalRecords = response.length;
      this.dataSource.forEach((dat, index) => {
        dat.id_key = index + 1;
        this.totalRecords++;
      });
      // this.dataSource = (response.body.length > 0) ? response.body : [response.body];
      this.dataSourceChange.emit(this.dataSource);
      this.loading = false;
    });
  }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {PrescriptionTableMetierService} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-table/prescription-table.metier.service';

@Component({
    selector: 'app-prescription-table-modale-feature',
    templateUrl: './prescription-table-modale.feature.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrescriptionTableModaleFeatureComponent extends DialogComponent implements OnInit {
    @Autowired() initialiserFormulaireMetier: PrescriptionTableMetierService;
    @Autowired() metierService: PrescriptionTableMetierService;

    @Autowired() securityState: SecurityStateService;


    tabActive: 'Personne medical';
    personneMedical: any;
    specialites: any[] = [];

    @Input() finResponse: boolean;
    @Output() finResponseEvent = new EventEmitter<boolean>();

    constructor() {
        super();

        this.finResponse = false;
    }

    ngOnInit() {
        if (this.modalManager.id === 'modale_medecin_traitant') {
            this.header = 'Informations du médecin traitant';
        }

        this.metierService.requeteGetPoolMedicalPersonnel({}, {...this.args}).subscribe((response) => {
            this.personneMedical = response.body.items[0];

            this.metierService.requeteGetReferenceSpecialite(
                {}, {...this.args, idPersonnelMedical: response.body.items[0].idPersonnelMedical}).subscribe((response2) => {

                this.specialites = response2.body.items;

                this.finResponse = true;
                this.finResponseEvent.emit(this.finResponse);
            });
        });
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    /**
     *
     * Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     *
     */
    getTabChange(event) {
        this.tabActive = event.originalEvent.target['innerText'];
    }

}

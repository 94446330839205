import {NgModule} from '@angular/core';

import {MaladieChroniqueTableComponent} from './maladie-chronique-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionMaladieChroniqueTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/maladie-chronique-dossier-table/maladie-chronique-table/prescription-maladie-chronique/prescription-maladie-chronique-table.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PrescriptionMaladieChroniqueTableModule,
    ],
  exports: [MaladieChroniqueTableComponent],
  declarations: [MaladieChroniqueTableComponent],
  providers: [],
})
export class MaladieChroniqueTableModule {
}

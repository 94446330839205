import {Component, OnInit} from '@angular/core';
import {Autowired} from "@angular-ru/autowired";
import {HeureServeurMetierService} from "../heure-serveur.metier.service";

@Component({
  selector: 'app-heure-serveur-horloge',
  templateUrl: 'heure-serveur-horloge.component.html'
})

export class HeureServeurHorlogeComponent implements OnInit {
  @Autowired() metierService: HeureServeurMetierService;
  date: string = null;
  constructor() {
  }

  ngOnInit() {
    this.metierService.recupererDateServeur().subscribe(reponse => {
      this.date = reponse.body;
    });
  }
}

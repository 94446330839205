import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {LierBordereauModaleListeFeatureModule} from "@app/fonctionnalites/factures/prise-en-charge/lier-bordereau-modale-liste/lier-bordereau-modale-liste.feature.module";
import {InformationFactureModule} from "@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/information/information-facture.module";
import {AffectionFactureModule} from "@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/affection/affection-facture.module";
import {ActesFactureModule} from "@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/actes/actes-facture.module";
import {PrescriptionsFactureModule} from "@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/prescriptions/prescriptions-facture.module";
import { ModaleModifierFactureActionComponent } from './modale-modifier-facture-action.component';
import {PrescriptionsFactureModifiabbleModule} from "@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/prescriptions-modifiable/prescriptions-facture-modifiabble.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        LierBordereauModaleListeFeatureModule,
        InformationFactureModule,
        AffectionFactureModule,
        ActesFactureModule,
        PrescriptionsFactureModule,
        PrescriptionsFactureModifiabbleModule,
    ],
    exports: [
        ModaleModifierFactureActionComponent,
    ],
    declarations: [
        ModaleModifierFactureActionComponent,
    ],
  providers: [],
})
export class ModaleModifierFactureActionModule {
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Store} from '@ngrx/store';
import {Autowired} from '@angular-ru/autowired';
import {AppSettings} from '@app/app.settings';
import {FormControl} from '@angular/forms';
import {ListesStateService} from '@app/core/states/listes/listes.state.service';

@Component({
    selector: 'app-list-per-page-action',
    template: `
        <label>{{'Par page: '}}</label>
        <p-dropdown [options]="data" [formControl]="ctrl" (onChange)="handleChange($event); onChange.emit($event)"
                    optionLabel="name" [autoWidth]="false" [style]="{'min-width': 'auto'}"></p-dropdown>
    `
})
export class ListPerPageActionComponent implements OnInit {
    @Autowired() settings: AppSettings;
    @Autowired() listeStateService: ListesStateService;
    @Output() onChange = new EventEmitter(false);
    data: any[];
    selected: any;
    ctrl = new FormControl({'name': this.listeStateService.getPerPage() || 5});
    // ctrl = this.listeStateService.getPerPage() || 5;

    constructor(private perPageStore: Store<{perPage: number}>) {
    }

    ngOnInit(): void {
        this.data = this.settings.configs.lists.perPages;
        this.ctrl = new FormControl({'name': this.listeStateService.getPerPage() || 5});
        // this.ctrl = this.listeStateService.getPerPage() || 5;

        this.listeStateService.query.perPage$.subscribe(perPage => {
            this.ctrl = new FormControl({'name': perPage});
            // this.ctrl = perPage;
        });
    }

    handleChange(event) {
        this.listeStateService.setPerPage(event.value.name);
    }
}

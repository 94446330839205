import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {ContactTableMetierService} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-table/composants/contact-table/contact-table.metier.service';

@Component({
    selector: 'app-contact-table',
    templateUrl: './contact-table.component.html'
})

export class ContactTableComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() metierService: ContactTableMetierService;

    @Input() contacts: any;

    contactsForm: FormGroup;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.initFormulaire();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initFormulaire() {
        this.contactsForm = this.metierService.initialiserFormulaire(this.contacts);
    }

}

import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {ConnexionRestApiService} from './connexion.rest-api.service';
import {AppSettings} from '@app/app.settings';
import {STORAGE} from '@app/core/constantes';
import {IConnexion} from '@app/core/interfaces/actions/i-connexion';
import {Router} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {TranslateService} from '@ngx-translate/core';
import {IInitialiserFormulaireMetier} from '@app/core/interfaces/metier/i-initialiser-formulaire-metier';
import {UtilisateurAuthentifieRestApiService} from '../utilisateur-authentifie/utilisateur-authentifie.rest-api.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {EcranVeilleService} from '@app/core/services/ecran-veille.service';
import {AlertService} from '@app/core/services/alert.service';
import {IUserConnect, Personne} from '@app/core/interfaces/user/i-user-connect';
import {DemandePriseChargeMetierService} from '../../demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class ConnexionMetierService implements IInitialiserFormulaireMetier, IConnexion {
    @Autowired() formBuilder: FormBuilder;
    @Autowired() restApi: ConnexionRestApiService;
    @Autowired() userRestApi: UtilisateurAuthentifieRestApiService;
    @Autowired() appSettings: AppSettings;
    @Autowired() router: Router;
    @Autowired() translateService: TranslateService;
    @Autowired() securityStore: SecurityStateService;
    @Autowired() ecranVeilleService: EcranVeilleService;
    @Autowired() alertService: AlertService;
    @Autowired() metierServiceDemandePEC: DemandePriseChargeMetierService;

    tentativesRestantes = this.appSettings.settings.tentativesConnexion;

    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
        let formGroup: FormGroup;
        formGroup = this.formBuilder.group({
            'login': [null],
            'password': [null, Validators.compose([Validators.required, Validators.minLength(8)])], //  Validators.pattern(COMPLEXITE_MOT_DE_PASSE)
            'rememberMe': false
        });

        return formGroup;
    }

    detectThemeAppUse() {
        // tslint:disable-next-line:max-line-length
        if (this.securityStore.getThemeApp() !== null &&
            (
                (this.appSettings.settings.sidebarTheme !== this.securityStore.getThemeApp().sb_theme) ||
                (this.appSettings.settings.theme !== this.securityStore.getThemeApp().theme)
            )) {
            this.securityStore.saveThemeApp({sb_theme: this.appSettings.settings.sidebarTheme, theme: this.appSettings.settings.theme});
        }

        if (this.securityStore.getThemeApp() === null) {
            this.securityStore.saveThemeApp({sb_theme: this.appSettings.settings.sidebarTheme, theme: this.appSettings.settings.theme});
        }
    }

    verifierTentativesConnexionRestantes(login: string) {
        this.tentativesRestantes = this.appSettings.settings.tentativesConnexion -
            SecurityStateService.getTentativesConnexion(login);
    }

    /**
     * requête de connexion
     * @param data
     * @param args
     */
    connexion(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.connexion(data);
    }

    /**
     * requête de récupération du token
     * @param data
     */
    recuperationToken(data: any): Observable<any> | Promise<any> | any {
        return this.restApi.recuperationToken(data);
    }

    /*connexionTEST(data: any, args?: any) {
        const v = args.formGroup.value;

        const storageUserConnect = window.localStorage.getItem(STORAGE.USER_CONNECT);

        // tslint:disable-next-line:prefer-const
        let userConnecte: IUserConnect = new class implements IUserConnect {
            permissions: any[] = [];
            idSessionUtilisateur: any;
            personne: Personne = new class implements Personne {
                nom: string;
                email: string;
                entite: string;
                idCentreConventionne: number;
                idMedecin: any;
                idPersonne: number;
                idPrestataireMedical: number;
                logoUrl: string;
                prenom: string;
                roleId: number;
                raisonSocial: string;
                userId: number;
            };
            tenantId: any;
            utilisateurId: any;
        };

        if (args.formGroup.valid) {
            // vérification du nombre de tentatives de connexion
            if (SecurityStateService.getTentativesConnexion(v.login) >= this.appSettings.settings.tentativesConnexion) {
                return this.verouillerCompteApresEchecTentatives(v.login);
            }

            // Lancement de la réquette de connexion
            this.restApi.connexion({
                username: (storageUserConnect !== null && v.login === null)
                    ? storageUserConnect
                    : v.login,
                password: window.btoa(window.btoa(window.btoa(v.password)))
            }).subscribe(reponseUserConnecte => {

                /!** formatage des données de l'utilisateur connecté *!/
                userConnecte.utilisateurId = reponseUserConnecte.body.items[0].user.id;
                userConnecte.idSessionUtilisateur = reponseUserConnecte.body.items[0].user.idSessionUtilisateur;
                userConnecte.tenantId = reponseUserConnecte.body.items[0].user.garant.idTenant;

                userConnecte.personne.nom = reponseUserConnecte.body.items[0].user.userPrestataires[0].nom;
                userConnecte.personne.prenom = reponseUserConnecte.body.items[0].user.userPrestataires[0].prenoms;
                userConnecte.personne.logoUrl = reponseUserConnecte.body.items[0].user.userPrestataires[0].photo;
                userConnecte.personne.email = reponseUserConnecte.body.items[0].user.userPrestataires[0].email;
                userConnecte.personne.userId = reponseUserConnecte.body.items[0].user.userPrestataires[0].userId;

                userConnecte.personne.entite = reponseUserConnecte.body.items[0].user.userPrestataires[0].entite;
                // tslint:disable-next-line:max-line-length
                userConnecte.personne.idCentreConventionne = reponseUserConnecte.body.items[0].user.userPrestataires[0].idCentreConventionne;
                userConnecte.personne.idMedecin = reponseUserConnecte.body.items[0].user.userPrestataires[0].idMedecin;
                userConnecte.personne.idPersonne = reponseUserConnecte.body.items[0].user.userPrestataires[0].idPersonne;
                // tslint:disable-next-line:max-line-length
                userConnecte.personne.idPrestataireMedical = reponseUserConnecte.body.items[0].user.userPrestataires[0].idPrestataireMedical;
                userConnecte.personne.roleId = reponseUserConnecte.body.items[0].user.userPrestataires[0].roleId;
                userConnecte.personne.raisonSocial = reponseUserConnecte.body.items[0].user.userPrestataires[0].userName;


                /!**
                 * récupéeration des permissions de l'utilisateur
                 * *!/
                // this.userRestApi.requeteRecuperationListeDonnees(null).subscribe(response => {
                //     userConnecte.groupeUtilisateurs = response.body.groupeUtilisateurs;
                // });

                userConnecte.permissions = ['app.tableau_de_bord', ...reponseUserConnecte.body.items[0].codeClaims];


                // console.log('userConnecte $$ : ', userConnecte);
                // console.log('reponseUserConnecte $$ : ', reponseUserConnecte.body.items[0].currentUser);
                // console.log('reponseUserConnecte $$ : ', reponseUserConnecte.body.items[0].codeClaims);


                // Lancement de la réquette de récuperation du token
                this.restApi.recuperationToken({
                    username: (storageUserConnect !== null && v.login === null)
                        ? storageUserConnect
                        : v.login,
                    password: v.password
                }).subscribe(reponseToken => {

                    this.securityStore.clearSotre();

                    // enregistrement du login du currentUser connecté, pour reconnection au système de verrouillage
                    window.localStorage.setItem(STORAGE.USER_CONNECT,
                        (storageUserConnect !== null && v.login === null) ? storageUserConnect : v.login);


                    const accessToken = reponseToken.body['access_token'];
                    // console.log('access_token $$ : ', accessToken);

                    this.securityStore.saveToken(accessToken);
                    this.securityStore.saveUser(userConnecte);
                    SecurityStateService.resetTentativesConnexion(v.login);

                    /!**
                     * système de récupération et de stockage des médecins
                     * *!/
                    if (this.metierServiceDemandePEC.medecins.length === 0) {
                        const reqMedecins = this.metierServiceDemandePEC.rechercherMedecin({});
                        reqMedecins.subscribe(res => {
                            // console.log('reqMedecins : ', res.body.items);

                            this.metierServiceDemandePEC.medecins = res.body.items;
                            window.localStorage.setItem(STORAGE.MEDECINS, JSON.stringify(this.metierServiceDemandePEC.medecins));
                        });
                    }

                    /!**
                     * système de récupération et de stockage des actes
                     * *!/
                    if (this.metierServiceDemandePEC.actes.length === 0) {
                        const reqAtes = this.metierServiceDemandePEC.recupererActes({});
                        reqAtes.subscribe(res => {
                            // console.log('reqAtes : ', res.body.items);

                            this.metierServiceDemandePEC.actes = res.body.items;
                            // window.localStorage.setItem(STORAGE.ACTES, JSON.stringify(this.metierServiceDemandePEC.actes));
                        });
                    }

                    /!**
                     * système de récupération et de stockage des actes hospitalisation
                     * *!/
                    if (this.metierServiceDemandePEC.actesHospitalisation.length === 0) {
                        const reqAtesHospitalisation = this.metierServiceDemandePEC.recupererActesHospitalisation({});
                        reqAtesHospitalisation.subscribe(res => {
                            // console.log('reqAtesHospitalisation : ', res.body.items);

                            this.metierServiceDemandePEC.actesHospitalisation = res.body.items;
                            // window.localStorage.setItem(
                            //     STORAGE.ACTES_HOSPITALISATION, JSON.stringify(this.metierServiceDemandePEC.actesHospitalisation));
                        });
                    }

                    /!**
                     * système de récupération et de stockage des affections
                     * *!/
                    if (this.metierServiceDemandePEC.affections.length === 0) {
                        const reqAffections = this.metierServiceDemandePEC.recupererAffections({});
                        reqAffections.subscribe(res => {
                            // console.log('reqAffections : ', res.body.items);

                            this.metierServiceDemandePEC.affections = res.body.items;
                            window.localStorage.setItem(STORAGE.AFFECTIONS, JSON.stringify(this.metierServiceDemandePEC.affections));
                        });
                    }

                    this.metierServiceDemandePEC.requeteRecuperationListGarant({}, {}).subscribe((reponseListGarant) => {

                        this.securityStore.saveListGarant(
                            reponseListGarant.body.items[0].affectionPrescriptionLier,
                            reponseListGarant.body.items[0].affectionPrescriptionFacultativePrestation,
                            reponseListGarant.body.items[0].horaireAutomatiquePrestation,
                            reponseListGarant.body.items[0].autoriserMontantDette,
                            reponseListGarant.body.items[0].automatiqueActeMontantDette,
                            reponseListGarant.body.items[0].affectionFacultativePrestation
                        );
                        // console.log('getListGarant() : ', this.securityStore.getListGarant());
                    });

                    this.securityStore.reloadedAfterTokenExpired = false;

                    if (args.nouvelleConnexion) {
                        this.securityStore.authentification(accessToken, userConnecte);

                        // Ajout pour le nouveau système de dévérouillage si statusCode 401
                        this.securityStore.deverouillage(accessToken, userConnecte);
                    } else {
                        this.securityStore.deverouillage(accessToken, userConnecte);
                    }

                }, err => {
                    // SecurityStateService.setTentativesConnexion(v.login);
                });

            }, err => {
                // lors de la premiere connexion une exception est lancé et l'utilisateur doit changer son mot de passe
                this.redirigerVersChangerModeDePasse(err);
                // SecurityStateService.setTentativesConnexion(v.login);
            });
        }
    }*/

    redirigerVersChangerModeDePasse(error: HttpErrorResponse) {
        let tabException = [];
        if (error.error.exception) {
            tabException = error.error.exception.split('.');
        }
        if (tabException[tabException.length - 1] === 'AuthentificationEchouer') {
            if (error.error.motif === 'PAS_ACTIF') {
                this.router.navigate(['/activate-currentUser']);
            }
            if (error.error.motif === 'MODIFIER_MOT_PASSE') {
                this.router.navigate(['/update-password']);
            }
        }
    }

    verouillerCompteApresEchecTentatives(login: string) {
        this.restApi.verouillerUtilisateur(login).subscribe(() => {
            this.alertService.showError('Votre compte est vérouillé. Vous avez dépassé le nombre de tentatives de connexion: ' +
                this.appSettings.settings.tentativesConnexion + ' tentatives.').show();
        });
    }

    /**
     * :: Système de Reinitialisation du Password ::
     */

    initialiserFormulaireResetPassword<T>(datas: T | any, args?: any): FormGroup {
        let formGroup: FormGroup;
        formGroup = this.formBuilder.group({
            //  Validators.pattern(COMPLEXITE_MOT_DE_PASSE)
            'newPassword': [null, Validators.compose([Validators.required, Validators.minLength(8)])],
            'confirmNewPassword': [null, Validators.compose([Validators.required, Validators.minLength(8)])],
        });

        return formGroup;
    }

    requeteResetPassword(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteResetPassword(data, args);
    }

}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-info-bull-widget',
  template: `
    <a href="javascript:void(0)" class="infos-infos"
       [class.showed]="show"
       [class.direction-left]="direction === 'left'"
       [class.direction-right]="direction === 'right'"
       *ngIf="infos && infos !== ''">
      <div class="infos-content" [innerHtml]="infos"></div>
    </a>
  `,
  styles: [`
    .infos-infos {
      cursor: pointer;
      position: relative;
      transition: 0.2s all ease;
      z-index: 1000;
    }
    .infos-infos .infos-content {
      position: absolute;
      background: wheat;
      padding: 5px;
      font-size: 0.7rem;
      top: 20px;
      width: 250px;
      transform: rotateX(90deg);
      transform-origin: top;
      transition: 0.2s all ease;
    }
    .infos-infos.direction-left .infos-content {
      right: 0;
    }
    .infos-infos.direction-right .infos-content {
      left: 0;
    }
    .infos-infos:after {
      content: '\\f05a';
      font-family: "FontAwesome";
      padding: 1px 5px;
      z-index: 10;
      font-size: 15px;
      color: #646464;
    }
    .infos-infos:focus,
    .infos-infos:hover,
    .infos-infos:active,
    .infos-infos.showed {
       background: wheat;
    }
    .infos-infos:focus .infos-content,
    .infos-infos:hover .infos-content,
    .infos-infos:active .infos-content,
    .infos-infos.showed .infos-content {
      transform: rotateX(0deg);
    }
  `]
})
export class InfoBullWidgetComponent {
  @Input('infos') infos = '';
  @Input('show') show = false;
  @Input('direction') direction: 'left'|'right' = 'left';
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {PrescriptionTableRestApiService} from './prescription-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class PrescriptionTableMetierService implements IRecuperationListeDonneesMetier {
    @Autowired() restApi: PrescriptionTableRestApiService;

    @Autowired() formBuilder: FormBuilder;

    actesComplementaire: any[] = [];

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteRecuperationListeDonnees(data, args);
    }

    initialiserFormulairePrescription<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            medicament: null,
            quantite: null,
            posologie: '',
        };

        return this.formBuilder.group({
            medicament: data.medicament,
            quantite: data.quantite,
            posologie: data.posologie,
        });
    }

    recupererActesComplementaire(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.recupererActesComplementaire(data, args);
    }

}

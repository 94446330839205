import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DatePipe} from '@angular/common';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
  API_RECUPERER_PRESCRIPTION_DOSSIER_MEDICAL_ENCOURS,
} from '@app/core/constantes';
import {CommonUtilities} from '@app/core/utilities/common.utilities';


@Injectable({providedIn: 'root'})
export class PrescriptionDossierTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;
  @Autowired() datePipe: DatePipe;

  storageUser: IUserConnect = this.getUser();

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    let constructorParamRequest: any;

    if (args.formGroup.value.selectTypeSearch === '' || args.formGroup.value.selectTypeSearch === null) {
      constructorParamRequest = {
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchdatePrestation: {IsOrderByField: true, Consider: false},
        InfoSearchnomCompletMedecin: {Consider: false},
      };
    }
    if (args.formGroup.value.selectTypeSearch === 'medecin') {
      constructorParamRequest = {
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchdatePrestation: {IsOrderByField: true, Consider: false},
        InfoSearchnomCompletMedecin: {Consider: true},
        nomCompletMedecin: args.formGroup.value.inputSearch,
      };
    }
    if (args.formGroup.value.selectTypeSearch === 'medicament') {
      constructorParamRequest = {
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchdatePrestation: {IsOrderByField: true, Consider: false},
        InfoSearchlibelle: {Consider: true},
        libelle: args.formGroup.value.inputSearch,
      };
    }
    if (args.formGroup.value.selectTypeSearch === 'date_prescription') {
      constructorParamRequest = {
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchdatePrestation: {IsOrderByField: true, Consider: true},
        datePrestation: `${CommonUtilities.formatDateReverseToServer(args.formGroup.value.inputSearch, this.datePipe)}T00:00:00.000Z`,
      };
    }

    // update du paramètre
    data.SortOrder = null;

    const sendData = {
      ...data,
      itemToSearch: {
        ...data.itemToSearch,
        ...constructorParamRequest
      }
    };

    // tslint:disable-next-line:max-line-length
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PRESCRIPTION_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
  }

}

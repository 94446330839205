import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {FormControl, FormGroup} from '@angular/forms';
import {AjoutPrescriptionModaleMetierService} from './ajout-prescription.modale.metier.service';

@Component({
    selector: 'app-ajout-prescription-modale',
    templateUrl: './ajout-prescription.modale.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class AjoutPrescriptionModaleComponent extends DialogComponent {
    @Autowired() convertirFormulaireMetier: AjoutPrescriptionModaleMetierService;
    @Autowired() initialiserFormulaireMetier: AjoutPrescriptionModaleMetierService;
    @Autowired() requeteCreationMetier: AjoutPrescriptionModaleMetierService;
    @Autowired() requeteModificationMetier: AjoutPrescriptionModaleMetierService;
    @Autowired() metierService: AjoutPrescriptionModaleMetierService;

    searchForm: FormGroup;
    medicamentSelectionner: any;

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.searchForm = this.metierService.initialiserFormulaire(null);
    }


    actionSelectMedicament($event: any) {
        this.medicamentSelectionner = $event;
    }

    verification() {
        const data: any = this.searchForm.getRawValue();

        if (!this.medicamentSelectionner) {
            this.alertService.showError('Veillez selectionner un substituant').show();
        } else if (data.prixUnitaire === '' || data.prixUnitaire === '0' || data.prixUnitaire === null) {
            this.alertService.showError('Veillez saisir le prix unitaire').show();
        } else if (data.quantite === '' || data.quantite === '0' || data.quantite === null) {
            this.alertService.showError('Veillez saisir la quantité').show();
        } else if (data.posologie === '' || data.posologie === '0' || data.posologie === null) {
            this.alertService.showError('Veillez saisir la posologie').show();
        } else {
            this.enregistrerPrescription(data);
        }
    }

    enregistrerPrescription(data) {

        this.medicamentSelectionner.prixUnitaire = parseInt(data.prixUnitaire);
        this.medicamentSelectionner.posologie = data.posologie;
        this.medicamentSelectionner.quantiteRestante = parseInt(data.quantite);
        this.medicamentSelectionner.quantite = parseInt(data.quantite);
        this.medicamentSelectionner.quantiteRealise = 0;
        this.medicamentSelectionner.idPrestationPrescription = null;

        this.modalManager.datasourceSelected = this.medicamentSelectionner;
        super.fermerModale();
    }

    clearAutocomplete() {
        this.searchForm.setControl('medicament', new FormControl(''));
        this.medicamentSelectionner = null;
    }

}

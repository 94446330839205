import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {AssureListeFeatureComponent} from './assure-liste.feature.component';
import {AssureModaleFeatureModule} from '../modale/assure-modale.feature.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        AssureModaleFeatureModule,
    ],
  exports: [AssureListeFeatureComponent],
  declarations: [AssureListeFeatureComponent],
  providers: [],
})
export class AssureListeFeatureModule {
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {ActePrestationTableListeRestApiService} from './acte-prestation-table.liste.rest-api.service';

@Injectable({providedIn: 'root'})
export class ActePrestationTableListeMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: ActePrestationTableListeRestApiService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  /**
   * requête de recupération du document
   * @param data
   * @param agrs
   */
  requetePrintAvisHospitalisation(data: any, agrs: any) {
    return this.restApi.requetePrintAvisHospitalisation(data, agrs);
  }

  requetePrintBonPECHospitalisation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requetePrintBonPECHospitalisation(data, args);
  }

}

import {Injectable} from '@angular/core';
import {
    IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecuperationListeDonneesMetier,
    IRecupererOptionsMetier
} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {PrescriptionTableRestApiService} from './prescription-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class PrescriptionTableMetierService implements IRecuperationListeDonneesMetier,
    IInitialiserFormulaireMetier {
    @Autowired() restApi: PrescriptionTableRestApiService;
    @Autowired() formBuilder: FormBuilder;

    @Autowired() datePipe: DatePipe;


    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteRecuperationListeDonnees(data, args);
    }

    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            inputSearch: null,
            selectTypeSearch: !!datas && !!datas.selectTypeSearch ? datas.selectTypeSearch : null,
        };

        return this.formBuilder.group({
            inputSearch: data.inputSearch,
            selectTypeSearch: data.selectTypeSearch,
        });
    }

  requeteGetPoolMedicalPersonnel(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteGetPoolMedicalPersonnel(data, args);
  }

  requeteGetReferenceSpecialite(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteGetReferenceSpecialite(data, args);
  }

}

import {Component, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {ConnexionRestApiService} from '../../../fonctionnalites/authentification/connexion/connexion.rest-api.service';
import {AlertService} from '../../../core/services/alert.service';

@Component({
  selector: 'app-veille-button',
  templateUrl: './veille-button.component.html',
  styleUrls: ['./veille-button.component.scss']
})
export class VeilleButtonComponent implements OnInit {
  @Autowired() connexionRestApiService: ConnexionRestApiService;
  @Autowired() alertService: AlertService;
  constructor() { }

  ngOnInit() {
  }

  verrouiller() {
    const shower = this.alertService.showWarning('Voulez-vous vérrouiller l\' application?');
    shower.accept = () => {
      this.connexionRestApiService.deconnexionVerouillage();
    };
    shower.show();
  }
}

import {Component, Input} from '@angular/core';
import {AbstractFormOptionInputs} from '@shared/components/inputs/abstract-form-option-inputs';

@Component({
  selector: 'app-form-dropdown',
  template: `<div class="input-container">
    <div class="d-flex justify-between">
      <label for="{{id}}" *ngIf="label && label !== ''">{{label}}&nbsp;<span *ngIf="required" class="danger-color">*</span></label>
      <div>
        <app-info-bull-widget [infos]="infos"></app-info-bull-widget>
      </div>
    </div>
    <div [class.ui-inputgroup]="hasAction">
      <button *ngIf="hasAction" pButton type="button"
              (click)="actionDeclenche.emit()" icon="pi pi-{{actionIcon}}" class="ui-button-{{actionColor}}"></button>
      <p-dropdown *ngIf="useNgModel" [required]="required" id="{{id}}"
        [filter]="filter"
        class="w-100"
        [showClear]="showClear"
        appendTo="body"
        [style]="style"
        [styleClass]="styleClass"
        [placeholder]="placeholder"
        [options]="options"
         [attr.minlength]="minlength"
         [attr.min]="min"
         [attr.max]="max"
         [attr.maxlength]="maxlength"
          (onChange)="onChange?.emit($event)"
          (onBlur)="onBlur?.emit($event)"
          (onClick)="onClick?.emit($event)"
          (onFocus)="onFocus?.emit($event)"
          (onShow)="onShow?.emit($event)"
          (onHide)="onHide?.emit($event)"
          [baseZIndex]="100002" [autoZIndex]="true"
          [(ngModel)]="ngModel"
          (ngModelChange)="ngModelChange.emit($event)"
         [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
         [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"></p-dropdown>
      <p-dropdown *ngIf="!useNgModel" [required]="required" id="{{id}}"
        [filter]="filter"
        class="w-100"
        [showClear]="showClear"
        appendTo="body"
        [style]="style"
        [styleClass]="styleClass"
        [placeholder]="placeholder"
        [options]="options"
       [attr.minlength]="minlength"
       [attr.min]="min"
       [attr.max]="max"
       [attr.maxlength]="maxlength"
        (onChange)="onChange?.emit($event)"
        (onBlur)="onBlur?.emit($event)"
        (onClick)="onClick?.emit($event)"
        (onFocus)="onFocus?.emit($event)"
        (onShow)="onShow?.emit($event)"
        (onHide)="onHide?.emit($event)"
        [baseZIndex]="100002" [autoZIndex]="true"
       [formControl]="control"
       [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
       [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"></p-dropdown>
    </div>
    <app-list-form-error [control]="control"
                         [types]="errorTypes"
                         [submitted]="submitted"
                         [messages]="messages"></app-list-form-error>
  </div>`,
})
export class FormDropdownComponent extends AbstractFormOptionInputs {
  /**
   * Défini le placeholder fu formulaire, c'est à dire une chaine de caractères qui sera affichée à
   * l'utilisateur lorsque le champs sera vide.
   */
  @Input('placeholder') placeholder = 'Sélectionnez';

  /**
   * Indique si les données du champs peuvent être filtrées
   */
  @Input('filter') filter = true;

  /**
   * Affiche un bouton d'effacage du contenu du champs
   */
  @Input('showClear') showClear = true;
}

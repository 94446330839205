import {Component} from '@angular/core';
import {PageLayoutComponent} from '@shared/behaviors/page-layout/page-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {PERMISSION_PRESTATION_FACTURE_BORDEREAU} from '@app/core/constantes';

@Component({
    selector: 'app-factures',
    templateUrl: './factures.component.html'
})
export class FacturesComponent extends PageLayoutComponent {

    permissionViewTabBordereau: boolean;

    constructor() {
        super();
        this.title = 'Facture prestataire';
        this.icon = 'how_to_vote';
        this.hasToolbar = false;

        this.permissionViewTabBordereau = CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_BORDEREAU);
    }
}

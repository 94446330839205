export const columns = [
  // {field: 'libelle', header: 'Libelle FCTC'},
];

export type operationRefreshPassword = 'activate_user' | 'refresh_mdp';

export const operations = {
  'activate_user': 'activate_user',
  'refresh_mdp': 'refresh_mdp',
};

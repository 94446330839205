import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {DatePipe} from '@angular/common';
import { LierBordereauModaleListeRestApiService } from './lier-bordereau-modale-liste.rest-api.service';

@Injectable({providedIn: 'root'})
export class LierBordereauModaleListeMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier {

  @Autowired() datePipe: DatePipe;
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: LierBordereauModaleListeRestApiService;

  medecins: any = [];
  actes: any = [];
  affections: any = [];

  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
    v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
    return v;
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      radioAction: !!datas && !!datas.radioAction ? datas.radioAction : null,
      nom: !!datas && !!datas.nom ? datas.nom : null,
      searchBordereau: !!datas && !!datas.searchBordereau ? datas.searchBordereau : null,
      numeroFacture: !!datas && !!datas.numeroFacture ? datas.numeroFacture : null,
      searchKey: !!datas && !!datas.searchKey ? datas.searchKey : null,
    };

    return this.formBuilder.group({
      radioAction: data.radioAction,
      nom: [{value: data.nom,  disabled: true}],
      searchBordereau: data.searchBordereau,
      searchKey: data.searchKey,
      numeroFacture: [{value: data.numeroFacture, disabled: args.desableNumeroFacture}],
    });
  }

  initialiserFormulaireToDisable<T>(datas: T | any, args?: any): FormGroup {

    const data: any = {
      radioAction: !!datas && !!datas.radioAction ? datas.radioAction : null,
      nom: !!datas && !!datas.nom ? datas.nom : null,
      searchBordereau: null,
      numeroFacture: !!datas && !!datas.numeroFacture ? datas.numeroFacture : null,
    };

    return this.formBuilder.group({
      radioAction: data.radioAction,
      nom: [{value: data.nom,  disabled: true}],
      searchBordereau: [{value: data.searchBordereau,  disabled: true}],
      numeroFacture: [{value: data.numeroFacture, disabled: args.desableNumeroFacture}],
    });
  }

  recupererOptions(args?: any): Observable<Array<any>> {
    return this.restApi.requeteRecuperationListeDonnees(null, args)
    .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
      if (!(reponse.body.items && reponse.body.items.length > 0)) {
        return reponse;
      }
      return reponse.body.items;
     })
    );
  }

}

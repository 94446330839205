import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {AbstractControl} from '@angular/forms';
import {ListFormErrorType} from '@shared/components/error/list-form-error/list-form-error.component';
import {LazyLoadEvent} from 'primeng/api';
import {DatePipe} from '@angular/common';
import {CODE_OBJET_DEMANDE_PEC, URL_SOUND_ERROR} from '@app/core/constantes';

/**
 * Utilitaire commun
 */
// @dynamic
export class CommonUtilities {

    public static playAudioError() {
        const audio = new Audio();
        audio.src = URL_SOUND_ERROR;
        audio.load();
        audio.play();
    }

    /**
     * fonction de détection si une valeur est null, vide ou undefined
     * @param value: any
     * => return null si condition vérifier
     */
    public static isDataNullUndefinedFalseEmpty(value: any) {
        return value === null || value === undefined || value === false || value === '' || value === 0;
    }

    /**
     * fonction pour formatter les document uploader depuis le composant de document
     * @param array
     */
    public static formatDataDocumentSend(array: any[]) {
        const dataFormat: any[] = [];

        array.forEach((item) => {
            if (item.isDeleted !== undefined) {
                dataFormat.push(item);
            } else {
                dataFormat.push({
                    codeObjet: CODE_OBJET_DEMANDE_PEC,
                    codeDocument: item.type.codeDocument,
                    dataKey: item.type.dataKey,
                    dateCreation: item.type.dateCreation,
                    dateMaj: item.type.dateMaj,
                    etude: item.type.etude,
                    idDocument: null,
                    idDocumentAfournir: item.type.idDocument,
                    idObjet: item.type.idObjet,
                    idTenant: item.type.idTenant,
                    idTypeDocument: item.type.idTypeDocument,
                    infoSearchCodeDocument: {consider: true, isOrderByField: false, isSumField: false},
                    infoSearchCodeObjet: {consider: true, isOrderByField: false, isSumField: false},
                    infoSearchLibelle: {consider: true, isOrderByField: false, isSumField: false},
                    infoSearchLibelleDocument: {consider: true, isOrderByField: false, isSumField: false},
                    isDeleted: false,
                    libelle: item.type.libelle,
                    libelleDocument: item.type.libelleDocument,
                    libelleObjet: item.type.libelleObjet,
                    obligatoire: null,

                    observations: item.titre,
                    extension: this.recupererExtension(item.fichier.extension),
                    pieceJointe: item.fichier.fullDataUrl,
                    requiredTenant: true,
                    selected: false,
                    tabDisplay: item.fichier.data,
                });
            }
        });

        return dataFormat;
    }

    public static recupererExtension(item) {
        const value = item.split('.');
        return value[1];
    }


    /**
     * fonction de vérification si un objet est vide
     * @param obj: {}
     * => return true, si le tableau est vide
     */
    public static isEmptyObject(obj): boolean {
        for (const prop in obj) {
            if (obj.hasOwnProperty(prop)) {
                return false;
            }
        }
        return JSON.stringify(obj) === JSON.stringify({});
    }

    /**
     * fonction de reformatage de date en format yyyy/MM/dd
     * @param value: string
     * @param datePipe: DatePipe
     * => return string
     */
    public static formatDateReverseToServer(value, datePipe: DatePipe) {
        // const monthNamesShort = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

        if (typeof value === 'string') {
            const format = value.split('/');
            return `${format[2]}-${format[1]}-${format[0]}`; // Ex: 2020-06-18
        } else {
            return datePipe.transform(value, 'yyyy-MM-dd'); // Ex: 2020-06-18
        }
    }

    /**
     * Vérifie que l'utilisateur connecté possède une liste de permissions
     * @param claims
     */
    public static currentUserHasPermissions(claims: string | string[]): boolean {
        try {
            let found = false;
            const currentUser = JSON.parse(window.sessionStorage.getItem('currentUser'));
            if (ClvTsUtilsCommonsJs.isUndefinedOrNull(currentUser)) {
                throw new Error('no currentUser define');
            }

            if (ClvTsUtilsCommonsJs.jsType(claims) === 'array') {
                (<string[]>claims).forEach(claim => {
                    const permission = currentUser.permissions.find(v => v === claim);
                    if (!ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(permission)) {
                        found = true;
                    }
                });
                return found;
            }

            const permission2 = currentUser.permissions.find(v => v === claims);
            if (!ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(permission2)) {
                found = true;
            }

            return found;
        } catch (e) {
            return false;
        }
    }

    /**
     * Construction d'une requete pour le format SGI
     * @param index
     * @param size
     * @param takeAll
     * @param deepth
     * @param isNotificationToShow
     * @param titleNotificationToShow
     * @param notificationToShow
     * @param showErrorInAlert
     * @param showLoader
     * @param mustFilterByTenant
     * @constructor
     */
    public static RequestSw = function(index?, size?, takeAll?, deepth?, isNotificationToShow?, titleNotificationToShow?,
                                       notificationToShow?, showErrorInAlert?, showLoader?, mustFilterByTenant?) {
        const request: any = {
            Index: index || 0,
            Size: size || 10,
            TakeAll: takeAll || false,
            Deepth: ClvTsUtilsCommonsJs.isUndefinedOrNull(deepth) ? 0 : deepth,
            IsNotificationToShow: isNotificationToShow || false,
            TitleNotificationToShow: titleNotificationToShow || '',
            NotificationToShow: notificationToShow || '',
            ShowErrorInAlert: showErrorInAlert === true ? true : (showErrorInAlert === false ? false : null),
            ShowLoader: ClvTsUtilsCommonsJs.isUndefinedOrNull(showLoader) ? true : showLoader,
            Navigator: navigator,
            MustFilterByTenant: mustFilterByTenant || true
        };
        return request;
    };

    public static ResponseSw = function(data, indexDebut, indexFin, count, message, hasError) {
        return {
            Data: data,
            IndexDebut: indexDebut || 0,
            IndexFin: indexFin || 0,
            Count: count || 0,
            Message: message || '',
            hasError: hasError || false
        };
    };

    /**
     *
     * @param loadOptions
     * @param index
     * @param size
     * @param takeAll
     * @param deepth
     * @param isNotificationToShow
     * @param titleNotificationToShow
     * @param notificationToShow
     * @param showErrorInAlert
     * @param showLoader
     * @param mustFilterByTenant
     * @constructor
     */
    public static GenerateSearchInputRequest(loadOptions: LazyLoadEvent, index?, size?, takeAll?, deepth?, isNotificationToShow?,
                                             titleNotificationToShow?, notificationToShow?, showErrorInAlert?, showLoader?,
                                             mustFilterByTenant?) {
        const tableParams = CommonUtilities.RequestSw(index, size, takeAll, deepth, isNotificationToShow,
            titleNotificationToShow, notificationToShow, showErrorInAlert, showLoader, mustFilterByTenant);
        tableParams.SortOrder = 'Ascending';

        const itemToSearch: any = {};
        itemToSearch['InfoSearchglobal'] = {
            Consider: true,
            IsOrderByField: false,
            OperatorToUse: this.getOperator(loadOptions.filters['global'].matchMode)
        };
        itemToSearch['global'] = loadOptions.globalFilter;
        tableParams.itemToSearch = itemToSearch;
        return tableParams;
    }

    public static sgiSaveData(data: any): any {
        return {
            ItemsToSave: [data]
        };
    }

    public static GenerateParamsRequest(loadOptions: LazyLoadEvent, takeAll?, deepth?, isNotificationToShow?, titleNotificationToShow?,
                                        notificationToShow?, showErrorInAlert?, showLoader?, mustFilterByTenant?) {

        const tableParams = CommonUtilities.RequestSw(loadOptions.first, loadOptions.rows, takeAll, deepth,
            isNotificationToShow, titleNotificationToShow, notificationToShow, showErrorInAlert,
            showLoader, mustFilterByTenant);
        tableParams.SortOrder = 'Ascending';


        const itemToSearch: any = {};
        if (loadOptions.sortField) {
            if (loadOptions.sortOrder !== -1) {
                tableParams.SortOrder = 'Descending';
            }
            itemToSearch['InfoSearch' + loadOptions.sortField] = {
                Consider: true,
                IsOrderByField: true
            };
        }
        if (loadOptions.filters && loadOptions.filters) {
          const queryKeys = Object.keys(loadOptions.filters);
          queryKeys.forEach(key => {
            itemToSearch[key] = loadOptions.filters[key].value;
          });
        }
        tableParams.itemToSearch = itemToSearch;
        return tableParams;
    }

    public static getOperator(searchOption: string) {
        let operator = '';
        switch (searchOption) {
            case 'contains':
                operator = 'CONTAINS';
                break;
            case 'startswith':
                operator = 'STARSTWITH';
                break;
            case 'endswith':
                operator = 'ENDSWITH';
                break;
            case 'between':
                operator = 'BETWEEN';
                break;
            case 'in':
                operator = 'BETWEEN';
                break;
            case '=':
                operator = 'EQUAL';
                break;
            case 'equals':
                operator = 'EQUAL';
                break;
            case '<>':
                operator = 'NOTEQUAL';
                break;
            case '<':
                operator = 'LESS';
                break;
            case '>':
                operator = 'MORE';
                break;
            case '<=':
                operator = 'LESS OR EQUAL';
                break;
            case '>=':
                operator = 'MORE OR EQUAL';
                break;
            default:
                operator = '';

        }
        return operator;
    }

    /**
     * Crée à l'aide d'un objet la liste des colonnes selon le format de la librairie primeNG
     * @param obj
     */
    public static buildPrimeNgTableColumnsByObject(obj: object): {field: string, header: string}[] {
        try {
            if (ClvTsUtilsCommonsJs.isUndefinedOrNull(obj)) {
                throw new Error('Object not defined');
            }

            if (ClvTsUtilsCommonsJs.jsType(obj) !== 'object') {
                throw new Error('Parameter must be an object');
            }

            const columns: {header: string, field: string}[] = [];
            Object.keys(obj).forEach(key => {
                columns.push({field: key, header: key});
            });

            return columns;
        } catch (e) {
            // console.error('Object not defined or Parameter must be an object');
            return [];
        }
    }

    /**
     * Vérifie qu'un champ héritant de la classe AbstractControl) respecte la liste deconditions fournie
     *
     * @param control
     * @param typ
     */
    public static getFullErrorCondition(control: AbstractControl, typ: ListFormErrorType[]) {
        /**
         * Vérifie qu'une condition fait partie de la liste des conditions fournies
         *
         * @param types
         * @param type
         */
        const hasType = (types: ListFormErrorType[], type: ListFormErrorType) => {
            return !ClvTsUtilsCommonsJs.isUndefinedOrNull(types.find(v => v === type));
        };

        if (ClvTsUtilsCommonsJs.isUndefinedOrNull(control)) {
            throw new Error('Control is not given');
        }

        if (ClvTsUtilsCommonsJs.isUndefinedOrNull(typ)) {
            throw new Error('Type not given');
        }

        let condition = true;

        if (hasType(typ, 'invalidEmail')) {
            condition = condition || (control.dirty || control.touched) && control.hasError('invalidEmail');
        }

        if (hasType(typ, 'required')) {
            condition = condition || (control.dirty || control.touched) && control.hasError('required');
        }

        if (hasType(typ, 'maxLength')) {
            condition = condition || (control.dirty || control.touched) && control.hasError('maxLength');
        }

        if (hasType(typ, 'minLength')) {
            condition = condition || (control.dirty || control.touched) && control.hasError('minLength');
        }

        if (hasType(typ, 'mismatchedPasswords')) {
            condition = condition || (control.dirty || control.touched) && control.hasError('mismatchedPasswords');
        }

        return condition;
    }

    /**
     * Ajuste un arbre de données au format d'arbre (TreeView) de PrimeNG
     *
     * @param noeuds
     * @param noeudParent
     * @param labelName
     * @param keyName
     * @param childrenName
     */
    public static ajusterArbre(noeuds: any[], noeudParent?: any, labelName: string = 'description',
                               keyName: string = 'id', childrenName: string = 'enfants') {
        const donneesIncorrectesMessage = 'Le format des données est incorrecte.';
        if (!noeuds || !(noeuds.length > 0)) {
            return [];
        }

        noeuds.forEach(noeud => {
            switch (ClvTsUtilsCommonsJs.jsType(noeud)) {
                case 'string':
                    throw new Error(donneesIncorrectesMessage);
                    break;
                case 'null':
                    throw new Error(donneesIncorrectesMessage);
                    break;
                case 'undefined':
                    throw new Error(donneesIncorrectesMessage);
                    break;
                default:
            }
            noeud.label = noeud[labelName];
            noeud.key = noeud[keyName];
            noeud.parent = noeudParent;
            if (noeud[childrenName] && noeud[childrenName].length > 0) {
                noeud.children = noeud[childrenName];
                // noeud.enfants = null;
                this.ajusterArbre(noeud.children, noeud, labelName, keyName, childrenName);
            }
        });
    }

    /**
     * Converti un arbre de données en mode sélection (mettre tout les noeuds au même niveau dans une liste)
     *
     * @param noeuds
     * @param tableau
     */
    public static convertirArbreEnModeSelection(noeuds: any[], tableau = []) {
        if (!noeuds || !(noeuds.length > 0)) {
            return [];
        }
        noeuds.forEach((noeud: any) => {
            tableau.push(noeud);
            if (noeud.children && noeud.children.length > 0) {
                this.convertirArbreEnModeSelection(noeud.children, tableau);
            }
        });
    }

    /**
     * Converti les permissions une liste de chaine de caractères
     *
     * @param noeuds
     * @param labelName
     * @param childrenName
     */
    public static convertirArbrePermissionEnListeDeChainesDeCaracteres(noeuds: any[], labelName: string = 'libelle', childrenName: string = 'enfants') {
        if (!noeuds || !(noeuds.length > 0)) {
            return [];
        }
        const liste: string[] = [];
        noeuds.forEach((noeud) => {
           if (noeud[labelName]) {
               liste.push(noeud[labelName]);
           }
           if (noeud[childrenName] && noeud[childrenName].length > 0) {
               const enfants = this.convertirArbrePermissionEnListeDeChainesDeCaracteres(noeud[childrenName]);
               enfants.forEach((enfant) => {
                   liste.push(enfant);
               });
           }
        });
        // @ts-ignore
        return [...new Set(liste)];
    }

    /**
     * Recupères les permissions depuis un arbre
     *
     * @param noeuds
     * @param libelleParent
     * @param labelName
     * @param childrenName
     */
    public static recupererPermissionsDepuisArbre(noeuds: any[], libelleParent: string = '', labelName: string = 'libelle',
                                                  childrenName: string = 'enfants') {
        if (!noeuds || !(noeuds.length > 0)) {
            return [];
        }

        const liste: string[] = [];
        const getBonLibelle = (libel: string) => {
            if (!libel) { return ''; }
            const tabs = libel.split('.');
            if (!(tabs.length > 0)) { return ''; }
            if (tabs.length > 1) { return tabs[tabs.length - 1]; }
            return tabs[0];
        };
        noeuds.forEach((noeud) => {
            let libelle = '';
            if (noeud[labelName]) {
                if (libelleParent && libelleParent !== '') {
                    libelle = libelleParent + '.' + getBonLibelle(noeud[labelName]);
                } else {
                    libelle = getBonLibelle(noeud[labelName]);
                }
            }
            liste.push(libelle);
            if (noeud[childrenName] && noeud[childrenName].length > 0) {
                const enfants = this.recupererPermissionsDepuisArbre(noeud[childrenName], libelle);
                enfants.forEach((enfant) => {
                    liste.push(enfant);
                });
            }
        });
        // @ts-ignore
        return [...new Set(liste)];
    }

    /**
     * Recupère la liste des permissions contenues dans une liste de groupes utilisateurs
     *
     * @param groupesUtilisateurs
     * @param permissionsName
     */
    public static recupererListePermissionsDepuisListeDeGroupesUtilisateurs(groupesUtilisateurs: any[], permissionsName: string = 'permissions') {
        if (!groupesUtilisateurs || !(groupesUtilisateurs.length > 0)) {
            return [];
        }
        const liste: any[] = [];
        groupesUtilisateurs.forEach((groupe) => {
           if (groupe && groupe[permissionsName]) {
               const listePermissions =
                   this.convertirArbrePermissionEnListeDeChainesDeCaracteres(groupe[permissionsName]);
               listePermissions.forEach((elt) => {
                   liste.push(elt);
               });
           }
        });
        // @ts-ignore
        return [...new Set(liste)];
    }

    /**
     * Modifier les noeuds sélectionnés pour l'affichage
     *
     * @param arbreOriginal
     * @param selections
     * @param labelName
     */
    public static modifierSelectionArbrePourAffichage(arbreOriginal: Array<any>, selections: Array<any>, labelName: string = 'libelle') {
        if (!arbreOriginal || !(arbreOriginal.length > 0) || !selections || !(selections.length > 0)) {
            return;
        }
        const resultat = [];
        selections.forEach((selection) => {
            resultat.push(this.rechercherNoeudParLibelleDansArbre(arbreOriginal, selection[labelName]));
        });
        return resultat;
    }

    /**
     * Retouver un noeud par son libellé dans l'arbre.
     * Nb: le libellé est unique
     *
     * @param noeuds
     * @param libelle
     * @param selections
     * @param labelName
     */
    public static rechercherNoeudParLibelleDansArbre (noeuds: Array<any>, libelle: string, selections = [], labelName: string = 'libelle') {
        if (!noeuds || !(noeuds.length > 0)) {
            return;
        }
        noeuds.forEach(noeud => {
            if (noeud[labelName] === libelle) {
                noeud.partialSelected = false;
                selections.push(noeud);
            } else {
                const arr = this.rechercherNoeudParLibelleDansArbre(noeud.children, libelle, selections);
                if (!ClvTsUtilsCommonsJs.isUndefinedOrNull(arr) && arr.length > 0) {
                    arr.forEach(sele => {
                        sele.partialSelected = false;
                        selections.push(sele);
                    });
                }
            }
        });
        // @ts-ignore
        return [...new Set(selections)][0];
    }

    /**
     * Recupère le label correspondant dans la liste déroulante.
     *
     * @param list
     * @param value
     */
    public static dropdownValue(list: {label: string, value: any}[], value: any) {
        try {
            return list.find(v => v.value === value).label || value;
        } catch (e) {
            return value;
        }
    }

    // for library
    /**
     * Retourner une valeur aléatoire de n-digits (par defaut 3)
     *
     * @param size
     */
    public static randomValue(size: number = 3) {
        let nbr = '';
        for (let i = 0; i < size; i++) {
            nbr = nbr + Math.random();
        }
        return nbr;
    }

    /**
     * Retourner une valeur aléatoire entre deux nombres
     * @param min
     * @param max
     */
    public static randomInRange(min: number, max: number): number {
        if (max < min) {
            return 0;
        }
        return Math.floor(Math.random() * (max - min + 1) ) + min;
    }

    /**
     * Retourner une valeur aléatoire dans un tableau
     * @param arr
     */
    public static randomInSelection(arr: Array<any>): any {
        if (!arr || !(arr.length > 0)) {
            return undefined;
        }
        return arr[this.randomInRange(0, arr.length - 1)];
    }

    /**
     * Retourne la liste des valeurs d'un colonne dans un tableau
     *
     * @param items
     * @param colonne
     */
    public static listeDeColonnesDansTableau(items: Array<any>, colonne: string) {
        const notesIdsList = [];
        if (!items || !(items.length > 0)) {
            return [];
        }
        items.forEach(item => {
            const noeuds = colonne.split('.');
            if (noeuds.length > 1) {
                let noeud = ClvTsUtilsCommonsJs.copy(item);
                noeuds.forEach(n => {
                    noeud = noeud[n];
                });
                if (!ClvTsUtilsCommonsJs.isUndefinedOrNull(noeud)) {
                    notesIdsList.push(noeud);
                }
            } else {
                if (!ClvTsUtilsCommonsJs.isUndefinedOrNull(item[colonne])) {
                    notesIdsList.push(item[colonne]);
                }
            }
        });
        return notesIdsList;
    }

    /**
     * Retourne la liste les objet d'un tableau dont la valeur d'un champ est comprise dans une liste de valeur
     *
     * @param items
     * @param fields
     * @param fieldName
     */
    public static getObjectListByFieldValueList(items: Array<any>, fields: Array<any>, fieldName: string) {
        if (!items || !(items.length > 0)) {
            return [];
        }
        if (!fields || !(fields.length > 0)) {
            return items;
        }
        if (!fieldName || fieldName === '') {
            return items;
        }

        const uniqueFields = [...new Set(fields)];
        const uniqueItems = [...new Set(items)];
        const result: Set<any> = new Set();
        uniqueFields.forEach(field => {
            const listItems = uniqueItems.filter(item => item[fieldName] === field);
            listItems.forEach(ite => {
                if (!result.has(ite)) {
                    result.add(ite);
                }
            });
        });
        return [...result];
    }

    /**
     * Retourne la liste les éléments dans un tableau source ne se trouvant pas dans un tableau cible
     *
     * @param source
     * @param cible
     */
    public static intrusSourceDansCible(source: string[], cible: string[]): string[] {
        const resultat: string[] = [];
        if (!cible || !(cible.length > 0) || !source || !(source.length > 0)) {
            return [];
        }

        source.forEach(s => {
            const res = cible.find(v => v === s);
            if (!res) {
                resultat.push(s);
            }
        });

        return resultat;
    }

    /**
     * Modifie un objet dans un tableau par sa clé
     *
     * @param source
     * @param item
     * @param cleReconnaissance
     */
    public static modifierItemDansTableau(source: Array<any>, item: any, cleReconnaissance: any): Array<any> {
        const insertFirstTime = () => {
            source = [];
            source.push(item);
            return source;
        };
        if (!source) {
            if (!item) { return []; }
            return insertFirstTime();
        }
        if (!(source.length > 0)) {
            if (item) {
                return insertFirstTime();
            }
            return [];
        }

        if (!item || !(typeof item === 'object')) {
            source.push(item);
            return source;
        }

        if (!cleReconnaissance) {
            source.push(item);
            return source;
        }
        const elt = source.find(v => v[cleReconnaissance] === item[cleReconnaissance]);
        if (!elt) {
            source.push(item);
            return source;
        }
        const index = source.indexOf(elt);
        const premierePartie = source.slice(0, index);
        const deuxiemePartie = source.slice(index + 1);
        source = [];
        premierePartie.forEach(v => {
            source.push(v);
        });
        source.push(item);
        deuxiemePartie.forEach(v => {
            source.push(v);
        });
        return source;
    }

    /**
     * Supprimes les espaces d'une chaine de caractères
     * @param str
     */
    public static cleanSpace(str: string) {
        return str.replace(/\s/g, '');
    }

    /**
     * Ajoute des séparateurs de milliers à une chaine de caractères
     *
     * @param str
     */
    public static ajouterSeparateurDeMillier(str: string) {
        let result = '';
        for (let i = 0; i < Math.ceil(str.length / 3); i++) {
            const ofs = str.length - (i + 1) * 3;
            if (ofs > 0) {
                result = `${str.substr(ofs, 3)} ${result}`;
            } else {
                result = `${str.substr(0, 3 + ofs)} ${result}`;
            }
            if (i === 0) {
                result = result.substr(0, result.length - 1);
            }
        }
        return result;
    }

    /**
     * Ajoute des séparateurs de milliers à un nombre.
     *
     * propriété args:
     * * decimal: boolean => indique le nombre possède une partie décimale
     * * pres: number => indique le nombre de chiffres après la virgule
     * * arround: boolean => indique que la partie décimale est arrondie
     *
     * @param nombre
     * @param args
     */
    public static thousandSeparator(nombre: string | number, args: ThousandSeparatorArg = {decimal: true, pres: 2, arround: false}) {
        if (typeof nombre !== 'string' && typeof nombre !== 'number') {
            return null;
        }

        // verifier la definition des arguments
        if (!args.pres) {
            args.pres = 2;
        }

        let nombreStr = '';
        // rendre la chaine utilisable
        if (typeof nombre === 'string') {
            nombreStr = this.cleanSpace(nombre);
        }

        if (typeof nombre === 'number') {
            nombreStr = `${nombre}`;
        }

        // separer la partie entiere de la partie decimale
        let partieEntiere = nombreStr.split('.')[0];
        let partieDecimale = nombreStr.split('.')[1];

        // ajouter separateur de millier à la partie entiere
        partieEntiere = this.ajouterSeparateurDeMillier(partieEntiere);

        // arrondir partie decimale
        partieDecimale = `0.${partieDecimale}`;
        if (args.arround) {
            if (args.pres && args.pres > 0) {
                const num = Number(+partieDecimale);
                partieDecimale = `${Number(num.toFixed(args.pres))}`;
            }
        }
        partieDecimale = partieDecimale.split('.')[1];

        // construire le resultat
        let resultat = partieEntiere;

        if (args.decimal) {
            if (nombre.toString().indexOf('.') !== -1) {
                if (partieDecimale && partieDecimale !== 'undefined') {
                    resultat += `.`;
                    resultat += `${partieDecimale}`;
                } else if (nombre && nombre !== '') {
                    resultat += `.`;
                }
            }
        }
        return resultat;
    }
}

/**
 * Arguments du separateur de milliers
 */
export interface ThousandSeparatorArg {
    /**
     * indique le nombre possède une partie décimale
     */
    decimal: boolean;
    /**
     * indique le nombre de chiffres après la virgule
     */
    pres: number;
    /**
     * indique que la partie décimale est arrondie
     */
    arround: boolean;
}

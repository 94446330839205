import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {ModaleLierBordereauMetierService} from './modale-lier-bordereau.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SgiListLayoutComponent} from "@shared/behaviors/list-layout/sgi/sgi-list-layout.component";
import {SecurityStateService} from "@app/core/states/security/security.state.service";
import {AlertService} from "@app/core/services/alert.service";

@Component({
  selector: 'app-modale-lier-bordereau-feature',
  templateUrl: './modale-lier-bordereau.feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModaleLierBordereauFeatureComponent extends DialogComponent implements OnInit {
  @Autowired() convertirFormulaireMetier: ModaleLierBordereauMetierService;
  @Autowired() initialiserFormulaireMetier: ModaleLierBordereauMetierService;
  @Autowired() requeteCreationMetier: ModaleLierBordereauMetierService;
  @Autowired() requeteModificationMetier: ModaleLierBordereauMetierService;
  @Autowired() metierService: ModaleLierBordereauMetierService;
  @Autowired() SgiListLayoutComponent: SgiListLayoutComponent;
  @Autowired() securityService: SecurityStateService;
  @Autowired() alert: AlertService;


  searchForm: FormGroup;
  dataSource: any = [];
  selectedColumns: any = [];

  saveData: any;

  constructor() {
    super();
  }

  ngOnInit() {
    this.selectedColumns = [
      {field: 'numBonPriseEnCharge', header: 'N° Prise en charge'},
      {field: 'montantNet', header: 'Montant', type: () => 'price'},
      {field: 'partAssuranceBrut', header: 'Part Assurance Brut', type: () => 'price'},
      {field: 'ticketModerateur', header: 'Ticket Moderateur', type: () => 'price'},
      {field: 'partAssurance', header: 'Net à Payer', type: () => 'price'},
    ];
    console.log('modalManager : ', this.modalManager.datasourceSelected);
    //this.dataSource = this.gestionnaireDeModales[0].datasourceSelected
  }

  /** Cette foction recupere les données du Outpout de lier modale-subtitution liste **/
  recupererDonneAenregistrer($event: any) {
    this.saveData = $event
    console.log("evoyer vers l'enfant", $event)
  }

  /** Cette foction enregistrer les données récupérer de la liste **/
  enregistrer($event: any) {
    console.log("evoyer vers l'enfant", $event)
    if ($event.formGroup.numeroFacture === "" || $event.formGroup.numeroFacture === null) {
      this.alert.showError("Saisisser ou selectionner un Numero Facture").show()
      return
    }

    let sendata: any;

    if ($event.bordereauSelected) {
      sendata = {
        existeNumeroFacture: true,
        idBordereau: $event.bordereauSelected.idBordereau,
        idPrestataireMedical: this.securityService.getUser().personne.idPrestataireMedical,
        numeroFacture: $event.formGroup.numeroFacture,
        prestataireMedical: $event.formGroup.nom,
        listePriseEncharges: this.modalManager.datasourceSelected,
        isActive: false
      };
    }else
      if ($event.bordereauSelected === null || $event.bordereauSelected  === undefined) {
        sendata = {
          existeNumeroFacture: false,
          idPrestataireMedical: this.securityService.getUser().personne.idPrestataireMedical,
          numeroFacture: $event.formGroup.numeroFacture,
          prestataireMedical: $event.formGroup.nom,
          listePriseEncharges: this.modalManager.datasourceSelected,
          isActive: false
        };
      }

    this.metierService.enregistrerBordereauLier(sendata).subscribe((response: any) => {
      console.log(response);
      if (response.body.hasError === true) {
        this.alert.showError(response.body.message).show()
        return
      }
      this.fermerModale()
    })
  }


}

import {Injectable} from '@angular/core';
import {IRestApi} from '@app/core/interfaces/rest/i-rest-api';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable, of} from 'rxjs';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';

@Injectable({providedIn: 'root'})
export class ActesFactureRestApiService extends SecurityStateService implements IRestApi {
  @Autowired() restService: SgiRestService;

  storageUser: IUserConnect = this.getUser();

  requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
  }

  requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.putRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
  }

  requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
    let id = null; try { id = data.id ? data.id : null; } catch (e) {}
    return this.restService.getRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur/${id}`);
  }

  requeteSuppression(data: any, args?: any): any {
    return this.restService.deleteRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
  }

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {

    return of({body: {items: null}})
  }

}

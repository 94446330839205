import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {ModaleLierBordereauRestApiService} from './modale-lier-bordereau.rest-api.service';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ModaleLierBordereauMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier {

  @Autowired() datePipe: DatePipe;
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: ModaleLierBordereauRestApiService;



  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
    v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
    return v;
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {

    return this.formBuilder.group({});
  }

  recupererOptions(args?: any): Observable<Array<any>> {
    return this.restApi.requeteRecuperationListeDonnees(null, args)
    .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
      if (!(reponse.body.items && reponse.body.items.length > 0)) {
        return reponse;
      }
      return reponse.body.items;
    }));
  }

  enregistrerBordereauLier(data) {
    return  this.restApi.enregistrerBordereau(data)
  }

}

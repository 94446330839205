import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import { RechercheBordereauAutocompleteComponent } from './recherche-bordereau.autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [RechercheBordereauAutocompleteComponent],
  exports: [RechercheBordereauAutocompleteComponent]
})
export class RechercheBordereauAutocompleteFormModule {
}


import {Component, OnInit} from '@angular/core';
import {HttpResponse} from '@angular/common/http';
import {Autowired} from '@angular-ru/autowired';
import {AtlantisReactiveForm} from '@shared/behaviors/atlantis-extend-clv/atlantis-reactive-form';
import {
    IActionFullFormulaire,
    IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecuperationFullFormDonneesMetier
} from '@app/core/interfaces';
import {AlertService} from '@app/core/services/alert.service';
import {IModificationFullFormulaireMetier} from '@app/core/interfaces/metier/i-modification-full-donnees-metier';

/**
 * Formulaire plein page, n'étant pas modifié par l'intermédiaire d'une fenêtre modale-subtitution.
 *
 * Services metiers à implémenter:
 * convertirFormulaireMetier: IConvertirFormulaireMetier;
 * initialiserFormulaireMetier: IInitialiserFormulaireMetier;
 * modificationFullFormulaireMetier: IModificationFullFormulaireMetier;
 * recuperationFullFormDonneesMetier: IRecuperationFullFormDonneesMetier;
 */
@Component({
    selector: 'app-full-form',
    template: ``
})
export class FullFormComponent extends AtlantisReactiveForm implements IActionFullFormulaire, OnInit {
    /**
     * Service de convertion du formulaire.
     */
    convertirFormulaireMetier: IConvertirFormulaireMetier;

    /**
     * Service d'initialisation du formulaire
     */
    initialiserFormulaireMetier: IInitialiserFormulaireMetier;


    /**
     * Service d'appel de la requête de création
     */
    modificationFullFormulaireMetier: IModificationFullFormulaireMetier;

    /**
     * Service service de modification des données
     */
    recuperationFullFormDonneesMetier: IRecuperationFullFormDonneesMetier;

    @Autowired() alertService: AlertService;

    ngOnInit(): void {
        this.initialiserFormulaire();
        // this.recuperDonnees();
    }

    /**
     * Converti le formulaire au format attendu au backend
     */
    convertirFormulaire<T>(): any | T {
        return this.convertirFormulaireMetier.convertirFormulaire(this.formGroup, this.getArgs());
    }

    /**
     * Initialise le formulaire
     */
    initialiserFormulaire(): void {
        this.formGroup = this.initialiserFormulaireMetier.initialiserFormulaire(this.defaultObject || null, this.getArgs());
    }

    /**
     * Récupère la liste des données
     * @param data
     */
    recuperDonnees<T>(data?: any | T): void {
        this.recuperationFullFormDonneesMetier.requeteRecuperationFullFormDonnee(this.getArgs()).subscribe((reponse: HttpResponse<any>) => {
            this.defaultObject = reponse.body;
            this.initialiserFormulaire();
        });
    }

    /**
     * Enregistre le formulaire pour la modification des sonnées du user
     */
    enregistrerFormulaire() {
        this.submitted = true;
        this.dirtyFormGroup();
        if (this.formGroup.valid) {
            this.requeteEnregistrementUpdateDataUser();
        } else {
            this.alertService.showError(this.constantes.FORM_INVALID_MESSAGE).show();
        }
    }

    /**
     * Appelle la requête d'enregistrement d'une donnée
     */
    requeteEnregistrement<T>(): any | T {
        return this.modificationFullFormulaireMetier.modificationFullFormulaire(this.convertirFormulaire(), this.getArgs())
            .subscribe((reponse: HttpResponse<any>) => {
                this.afterRequeteEnregistrement(reponse);
            });
    }

    /**
     * ********************************************************************************************
     * ********************************************************************************************
     */

    /**
     * Enregistre le formulaire pour la modification des sonnées du user
     */
    enregistrerFormulaireUpdateDataUser() {
        this.submitted = true;
        this.dirtyFormGroup();
        if (this.formGroup.valid) {

            /**
             * Contrôl des données
             */
            if (this.formGroup.getRawValue().updatePassword &&
                (this.formGroup.getRawValue().oldPassword === ''
                    || this.formGroup.getRawValue().newPassword === ''
                    || this.formGroup.getRawValue().confirmNewPassword === '')) {
                this.alertService.showError('Veuillez remplire tout les champs pour les modification du mot de passe !').show();
            } else {
                if (this.formGroup.getRawValue().updatePassword &&
                    (this.formGroup.getRawValue().newPassword !== this.formGroup.getRawValue().confirmNewPassword)) {
                    this.alertService.showError('Votre nouveau mot de passe est different de la confirmation de mot de passe !').show();
                    return;
                }

                this.requeteEnregistrementUpdateDataUser();
            }

        } else {
            this.alertService.showError(this.constantes.FORM_INVALID_MESSAGE).show();
        }
    }

    /**
     * Appelle la requête d'enregistrement d'une donnée
     */
    requeteEnregistrementUpdateDataUser<T>(): any | T {
        return this.modificationFullFormulaireMetier.modificationFullFormulaire(this.convertirFormulaire(), this.getArgs())
            .subscribe((reponse: HttpResponse<any>) => {
                this.afterRequeteEnregistrement(reponse);
            });
    }

    /**
     * ********************************************************************************************
     * ********************************************************************************************
     */

    /**
     * Enregistre le formulaire de modification du user
     */
    enregistrerFormulaireUpdatePassword() {
        this.submitted = true;
        this.dirtyFormGroup();
        if (this.formGroup.valid) {
            this.requeteEnregistrementUpdatePassword();
        } else {
            this.alertService.showError(this.constantes.FORM_INVALID_MESSAGE).show();
        }
    }

    /**
     * Appelle la requête d'enregistrement d'une donnée
     */
    requeteEnregistrementUpdatePassword<T>(): any | T {
        return this.modificationFullFormulaireMetier.modificationFullFormulaire(this.convertirFormulaire(), this.getArgs())
            .subscribe((reponse: HttpResponse<any>) => {
                this.afterRequeteEnregistrement(reponse);
            });
    }

    /**
     * S'exécute après la réquête d'enregistrement
     * @param reponse
     */
    afterRequeteEnregistrement(reponse?: any): void {
    }
}

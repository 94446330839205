import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionAutocompleteComponent} from './prescription.autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [PrescriptionAutocompleteComponent],
  exports: [PrescriptionAutocompleteComponent]
})
export class PrescriptionAutocompleteFormModule {
}


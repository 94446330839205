import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrestationActeTableMetierService} from './prestation-acte-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-prestation-acte-table',
  templateUrl: './prestation-acte-table.component.html'
})

export class PrestationActeTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: PrestationActeTableMetierService;
  @Autowired() metierService: PrestationActeTableMetierService;

  @Input() currentRow: any;
  @Input() responsePrestationsActe: any;
  @Output() responsePrestationsActeEvent = new EventEmitter<any>();

  formGroup: FormGroup;
  typeRechercheCombo: any[] = [];
  placeholderInputSearch: string;


  constructor() {
    super();
    this.cols = [
      {field: 'libelle', header: 'Libelle '},
      {field: 'datePrestation', header: 'Date prestation', type: () => 'date'},
      {field: 'dateRealisation', header: 'Date Réalisation', type: () => 'date'},
    ];
    this.tableActions = [];
    this.notRowActions = [];
  }

  ngOnInit(): void {
    this.initialiserTypeRechercheCombo();

    this.initFormulaire();

    this.formatTableListAddIndex(this.responsePrestationsActe);
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args};
    this.args = {...arg};
  }

  recuperationDonnees(data?: any) {
    const da = {...this.args, lazyLoadEvent: data, successMute: true, currentRow: this.currentRow};
    this.args = {...da};
    this.metierService.requeteRecuperationListeDonnees(this.getAllRequestParam(), {...this.args}).subscribe((r_PrestationsActe) => {
      const responsePrestationsActe = r_PrestationsActe.body;

      this.formatTableListAddIndex(responsePrestationsActe);

      this.responsePrestationsActeEvent.emit(r_PrestationsActe.body);
    });
  }

  formatTableListAddIndex(responseData) {
    if (responseData === undefined) {
      this.dataSource = [];
      this.totalRecords = 0;
      return;
    }
    this.dataSource = responseData.items;
    this.totalRecords = responseData.count;
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach((dat, index) => {
        dat.id_key = index + 1;
      });
    }
  }

  initFormulaire() {
    this.formGroup = this.metierService.initialiserFormulaire(null);
  }

  initialiserTypeRechercheCombo() {
    this.placeholderInputSearch = 'Rechercher';

    this.typeRechercheCombo = [
      { label: 'Libelle', value: 'libelle'},
      { label: 'Date prestation', value: 'date_prestation'},
    ];
  }

  handleChangeTypeRecherche(event: any) {
    if (event.value === null) {
      this.placeholderInputSearch = 'Rechercher';

      this.formGroup.patchValue({ inputSearch: null });

      // fonction de recherche assuré avec les valeurs null
      this.recuperationDonnees();
    }

    if (event.value === 'libelle') {
      this.placeholderInputSearch = 'Rechercher par libelle';
    }
    if (event.value === 'date_prestation') {
      this.placeholderInputSearch = 'Rechercher par date';
    }

    this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue());
  }

  rechercheItems(event) {
    if (this.formGroup.getRawValue().selectTypeSearch === null) { return false; }

    // fonction de recherche assuré avec les valeurs entrées
    this.recuperationDonnees();
  }

}

import {NgModule} from '@angular/core';

import {MotifRejetTableListeComponent} from './motif-rejet-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [MotifRejetTableListeComponent],
  declarations: [MotifRejetTableListeComponent],
  providers: [],
})
export class MotifRejetTableListeModule {
}

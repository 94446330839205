import {Injectable} from '@angular/core';
import {IRestApi} from '@app/core/interfaces/rest/i-rest-api';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {
    API_RECUPERER_ACTE_DOSSIER_MEDICAL_ENCOURS, API_RECUPERER_AFFECTION_DOSSIER_MEDICAL_ENCOURS,
    API_RECUPERER_ASSURE_DOSSIER_MEDICAL_ENCOURS, API_RECUPERER_PRESCRIPTION_DOSSIER_MEDICAL_ENCOURS,
    API_RECUPERER_PRESTATION_DOSSIER_MEDICAL_ENCOURS,
    API_RECUPERER_RESULTAT_EXAMEN_DOSSIER_MEDICAL_ENCOURS,
} from '@app/core/constantes';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';


@Injectable({providedIn: 'root'})
export class AssureRestApiService extends SecurityStateService implements IRestApi {
    @Autowired() restService: SgiRestService;

    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteSuppression(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        let constructorParamRequest: any;

        if (args.formGroup.value.selectTypeSearch === '' || args.formGroup.value.selectTypeSearch === null) {
            constructorParamRequest = {
                IdPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                InfoSearchIdPrestataireMedical: { consider: true },
            };

        } else if (args.formGroup.value.selectTypeSearch === 'matricule') {
            constructorParamRequest = {
                IdPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                InfoSearchIdPrestataireMedical: { consider: true },

                InfoSearchmatricule: { consider: true },
                matricule: args.formGroup.value.inputSearch,
            };

        } else if (args.formGroup.value.selectTypeSearch === 'garant') {
            constructorParamRequest = {
                IdPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                InfoSearchIdPrestataireMedical: { consider: true },

                InfoSearchraisonSocialeGarant: { consider: true },
                raisonSocialeGarant: args.formGroup.value.inputSearch,
            };
        }

        // update du paramètre
        data.SortOrder = null;

        const sendData = {...data, itemToSearch: {
                ...data.itemToSearch,
                ...constructorParamRequest
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ASSURE_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (!(response.body.items.length > 0)) {
                        return response;
                    }
                    return response;
                })
            );
    }

    requeteRecuperationDossierMedicalAssure(data: any, args?: any): Observable<any> | Promise<any> | any {

        console.log(data);
        console.log(args);

        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                IdSouscriptionLigne: args.idSouscriptionLigne,
                Matricule: args.matricule,

                InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
                InfoSearchMatricule: {Consider: true},
            }
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PRESTATION_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
    }

    requeteRecuperationPrestationActes(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                IdSouscriptionLigne: args.idSouscriptionLigne,

                InfoSearchdateRealisation: {Consider: true, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
            }
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ACTE_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
    }

    requeteRecuperationResultatsExamen(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                IdSouscriptionLigne: args.idSouscriptionLigne,

                InfoSearchdateRealisation: {Consider: true, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
            }
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_RESULTAT_EXAMEN_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
    }

    requeteRecuperationPrescriptions(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                IdSouscriptionLigne: args.idSouscriptionLigne,

                InfoSearchdatePrestation: {Consider: true, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
            }
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PRESCRIPTION_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
    }

    requeteRecuperationAffections(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                IdSouscriptionLigne: args.idSouscriptionLigne,

                InfoSearchdatePrestation: {Consider: true, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
            }
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_AFFECTION_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UserProfileImageComponent} from '@shared/components/gravatar/user-profile-image.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        UserProfileImageComponent,
    ],
    exports: [
        UserProfileImageComponent,
    ]
})
export class UserProfileImageComponentModule {
}

import {AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';


import * as d3 from 'd3';

import {STOCKSBIG} from './stocks-big';

@Component({
  selector: 'app-d3-transition-feature',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './transition.feature.component.html',
  styleUrls: ['./transition.feature.component.scss']
})
export class TransitionFeatureComponent implements AfterViewInit {
  @ViewChild('chart', {static: false})
  private chartContainer: ElementRef;

  data = STOCKSBIG;

  margin = {top: 20, right: 10, bottom: 30, left: 10};

  constructor() {
  }

  ngAfterViewInit(): void {
    if (!this.data) {
      return;
    }
    this.createChart();
  }

  onResize(e) {
    this.createChart();
  }

  private createChart(): void {
    const element = this.chartContainer.nativeElement;
    // Pour effacer le graphe déjà existant
    d3.select(element).selectAll('svg').remove();
    const data = this.data;

    /* const svg = d3.select(element).append('svg')
     .attr('width', element.offsetWidth)
     .attr('height', element.offsetHeight);

     const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
     const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;

     const x = d3
     .scaleBand()
     .rangeRound([0, contentWidth])
     .padding(0.05)
     .domain(data.map(d => d.letter));

     const y = d3
     .scaleLinear()
     .rangeRound([contentHeight, 0])
     .domain([0, d3.max(data, d => d.value)]);

     const g = svg.append('g')
     .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

     g.append('g')
     .attr('class', 'axis axis--x')
     .attr('transform', 'translate(0,' + contentHeight + ')')
     .call(d3.axisBottom(x));

     g.append('g')
     .attr('class', 'axis axis--y')
     .call(d3.axisLeft(y).ticks(10, '%'))
     .append('text')
     .attr('transform', 'rotate(-90)')
     .attr('y', 6)
     .attr('dy', '0.71em')
     .attr('text-anchor', 'end')
     .text('Frequency');

     g.selectAll('.bar')
     .data(data)
     .enter().append('rect')
     .attr('class', 'bar')
     .attr('x', d => x(d.letter))
     .attr('y', d => y(d.value))
     .attr('width', x.bandwidth())
     .attr('height', d => contentHeight - y(d.value));*/

    const margin = {top: 50, right: 50, bottom: 50, left: 50}
        , width = window.innerWidth - margin.left - margin.right // Use the window's width
        , height = window.innerHeight - margin.top - margin.bottom; // Use the window's height

// The number of datapoints
    const n = 21;

// 5. X scale will use the index of our data
    const xScale = d3.scaleLinear()
    .domain([0, n - 1]) // input
    .range([0, width]); // output

// 6. Y scale will use the randomly generate number
    const yScale = d3.scaleLinear()
    .domain([0, 1]) // input
    .range([height, 0]); // output

// 7. d3's line generator
    const line = d3.line()
    .x(function (d, i) {
      return xScale(i);
    }) // set the x values for the line generator
    .y(function (d) {
      return yScale(d.y);
    }) // set the y values for the line generator
    .curve(d3.curveMonotoneX); // apply smoothing to the line
    const valueline = d3.line()
    .x(function(d, i) { return xScale(i); })
    .y(function(d) { return yScale(d) });

// 8. An array of objects of length N. Each object has key -> value pair, the key being "y" and the value is a random number
    const dataset = d3.range(n).map(function (d) {
      return {'y': d3.randomUniform(1)()};
    });

// 1. Add the SVG to the page and employ #2
    const svg = d3.select(element).append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

// 3. Call the x axis in a group tag
    svg.append('g')
    .attr('class', 'x axis')
    .attr('transform', 'translate(0,' + height + ')')
    .call(d3.axisBottom(xScale)); // Create an axis component with d3.axisBottom

// 4. Call the y axis in a group tag
    svg.append('g')
    .attr('class', 'y axis')
    .call(d3.axisLeft(yScale)); // Create an axis component with d3.axisLeft

// 9. Append the path, bind the data, and call the line generator
    svg.append('path')
    .datum(dataset) // 10. Binds data to the line
    .attr('class', 'line') // Assign a class for styling
    .attr('d', line); // 11. Calls the line generator

// 12. Appends a circle for each datapoint
    svg.selectAll('.dot')
    .data(dataset)
    .enter().append('circle') // Uses the enter().append() method
    .attr('class', 'dot') // Assign a class for styling
    .attr('cx', function (d, i) {
      return xScale(i);
    })
    .attr('cy', function (d) {
      return yScale(d.y);
    })
    .attr('r', 5)
    .on('mouseover', function (a, b, c) {
      console.log(a);
      this.attr('class', 'focus');
    })
    .on('mouseout', function () {
    });

    const g = svg.append('g')
    .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    /*g.append('g')
    .attr('class', 'axis axis--x')
    .attr('transform', 'translate(0,' + height + ')')
    .call(d3.axisBottom(x));*/

    /*g.append('g')
    .attr('class', 'axis axis--y')
    .call(d3.axisLeft(yScale).ticks(10, '%'))
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 6)
    .attr('dy', '0.71em')
    .attr('text-anchor', 'end')
    .text('Frequency');*/

    /*g.selectAll('.bar')
    .data(data)
    .enter().append('rect')
    .attr('class', 'bar')
  .attr('x', function (d, i) {
      return xScale(i);
    })
    .attr('y', function (d) {
      return yScale(d.y);
    })
    .attr('width', 10)
    .attr('height', d => width - d.y);
*/
  }
}

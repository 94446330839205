import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import * as prescription from '../prescription-periodique.constante';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE} from '@app/core/constantes';
import {FormBuilder} from '@angular/forms';
import {PrescriptionPeriodiqueMetierService} from '../prescription-periodique.metier.service';

@Component({
    selector: 'app-prescription-periodique-liste-feature',
    templateUrl: './prescription-periodique-liste.feature.component.html'
})
export class PrescriptionPeriodiqueListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: PrescriptionPeriodiqueMetierService;
    @Autowired() recuperationListeDonneesMetier: PrescriptionPeriodiqueMetierService;
    @Autowired() metierService: PrescriptionPeriodiqueMetierService;

    prescriptionPeriodiqueConstantes = prescription;
    tabActive = 'Informations sur l\'assuré';
    currentDataTemp: any;

    @Autowired() formBuilder: FormBuilder;

    affichModalPrintBon = false;
    tableSelectedElement: any;

    @Input() sectionAffichage: string;


    constructor() {
        super();
        this.cols = this.prescriptionPeriodiqueConstantes.columns;
        this.tableActions = [];
    }

    ngOnInit() {
        this.selectedColumns = this.cols;
        this.getListElements();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }


    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
        this.tabActive = '';
    }

    /**
     * => return true, si le tableau est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }

    getTabChange(event) {
        this.currentRow = null;
        this.currentRow = this.currentDataTemp;
        this.tabActive = event.originalEvent.target['innerText'];
    }


    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        if (data.libelleTypeStatut === 'Cloturé') {
            shower = this.alertService.showError('La demande de prise en charge ne doit pas ètre cloturé');
            shower.show();
            return;
        }

        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.metierService.requeteSuppression(data).subscribe(() => {
                this.recuperDonnees();
            });
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    /**
     * Systeme de detection et de rechargement des données après enregistrement
     * @param event
     */
    handleSavePoursuiteSuccess(event) {
        if (event === null) {
            this.recuperDonnees();
            return;
        }

        /*this.requetePrintDocument(
            {numBonPriseEnCharge: event.numBonPriseEnCharge},
            '-- Bon de prise en charge N°',
            event.numBonPriseEnCharge,
        );*/
        this.recuperDonnees();
    }

    poursuivreDemande(data: any) {
        let alertMessage: any;

        if (!this.tableSelectedElement) {
            alertMessage = this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE);
            alertMessage.show();
            return;
        }

        this.metierService.requeteRecuperationActesPoursuite({}, {adherant: this.tableSelectedElement}).subscribe((reponseActe) => {

            this.metierService.requeteActionCanClosePoursuite({}, {actePoursuite: reponseActe}).subscribe(() => {

                this.metierService.requeteRecuperationPrixActesPoursuite({}, {actePoursuite: reponseActe}).subscribe((reponse) => {
                    const actesPoursuite = reponse.body.items;
                    this.gestionnaireDeModales[0].id = 'poursuite_demande';
                    this.gestionnaireDeModales[0].datasourceSelected = {...data, listActesPoursuite: actesPoursuite};
                    this.gestionnaireDeModales[0].display = true;
                });
            });
        });

    }

    demandeDossireMedical(data: any) {
        console.log('demandeDossireMedical : ', data);
    }

    imprimerBon(data: any) {
        console.log('imprimerBon : ', data);
    }

}

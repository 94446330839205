import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ActeComplementaireTableMetierService} from './acte-complementaire-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FORMULAIRE_INVALIDE_MESSAGE, VERIFICATION_CALCUL_PART_ASSURE} from '@app/core/constantes';
import {FormGroup} from '@angular/forms';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
  selector: 'app-acte-complementaire-table',
  templateUrl: './acte-complementaire-table.component.html'
})

export class ActeComplementaireTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: ActeComplementaireTableMetierService;
  @Autowired() metierService: ActeComplementaireTableMetierService;

  @Input() modalOpen: any;
  @Input() useCalculPartAssure: boolean;
  @Input() dataListActes: any[] = [];
  @Input() isCalculerMontantPartAssure: any;
  @Input() dataListActesComp: any[] = [];
  @Output() dataActesCompEvent = new EventEmitter<any[]>();

  acteComplementaireForm: FormGroup;

  @Input() adherant: any;
  acteCompSelected: any;

  descriptionsForm: FormGroup;
  @Input() descriptionAll: any;
  @Output() descriptionsEvent = new EventEmitter<any>();
  disabledInput: boolean;


  constructor() {
    super();
    this.tableActions = [
      this.supprimerAction
    ];
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'libelle', header: 'Acte' },
      {field: 'accordPrealable', header: 'Accord préalable ?', type: () => 'chip', states: [
          {key: true, label: 'Oui', classes: 'color-row-red-PEC'},
          {key: null, label: 'Non', classes: ''},
      ]},
      { field: 'quantite', header: 'Qté' },
      { field: 'descriptions', header: 'Commentaire', type: (data) => {
          if (data['accordPrealable'] === true) {
            return 'input';
          }
          return null;
      } },
    ];

    this.initFormulaire();

    this.controlDeDonnee();
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, adherant: this.adherant };
    this.args = {...arg};
  }

  initFormulaire() {
    this.acteComplementaireForm = this.metierService.initialiserFormulaireActeComplementaire(null);

    this.descriptionsForm = this.metierService.initialiserFormulaireDescriptionAll({description: this.descriptionAll});
    // this.descriptionsForm.patchValue({ descriptionAll: this.descriptionAll });

    this.disabledInput =  true;
  }

  controlDeDonnee() {
    let errorText = '';
    let ErrorAlert: any ;

    if (this.dataListActes.length === 0) {
      errorText = 'Aucun acte n\'a été renseigné !';
    }

    /*if (this.useCalculPartAssure) {
      if (!this.isCalculerMontantPartAssure.verif) {
        errorText = VERIFICATION_CALCUL_PART_ASSURE;
      }
    }*/

    if (errorText !== '') {
      ErrorAlert = this.alertService.showError(errorText);
      ErrorAlert.show();

      this.acteComplementaireForm.reset();
      return false;
    }

    return true;
  }

  actionSelectedActe(event) {
    this.acteCompSelected = event;
    let ErrorAlert: any ;

    this.controlDeDonnee();

    // update de la quatité par défaut
    this.acteCompSelected = {...this.acteCompSelected, quantite: 1};

    const findEltOldActe = this.dataListActes.findIndex((elt) => elt.libelle === this.acteCompSelected.libelle);
    const findEltActeComp = this.dataListActesComp.findIndex((elt) => elt.libelle === this.acteCompSelected.libelle);
    if (findEltOldActe >= 0 || findEltActeComp >= 0) {
      ErrorAlert = this.alertService.showError('Cet acte a déjà été ajouté !');
      ErrorAlert.show();

      this.acteComplementaireForm.reset();
      return;
    }

    this.acteComplementaireForm.patchValue({
      quantiteActe: 1,
    });

    this.saisirQuantiteInput(this.acteCompSelected);
  }

  saisirQuantiteInput(reponse) {
    if (reponse.quantiteEnSaisie) {
      this.acteComplementaireForm = this.metierService.initialiserFormulaireActeSelectedQuantite(this.acteComplementaireForm.getRawValue());
      this.disabledInput = false;
      this.dataActesCompEvent.emit(this.dataListActesComp);
    } else {
      this.acteComplementaireForm = this.metierService.initialiserFormulaireActeComplementaire(this.acteComplementaireForm.getRawValue());
      this.disabledInput = true;
      this.dataActesCompEvent.emit(this.dataListActesComp);
    }

    this.acteComplementaireForm.patchValue({
      quantiteActe: 1,
    });
  }

  ajouterActeComp() {
    let ErrorAlert: any ;
    if (!this.acteComplementaireForm.getRawValue().libelleActe ||
        !this.acteComplementaireForm.getRawValue().quantiteActe) {
      ErrorAlert = this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE);
      ErrorAlert.show();
      return;
    }

    if (!CommonUtilities.isDataNullUndefinedFalseEmpty(this.acteCompSelected.message)) {
      this.alertService.showError(this.acteCompSelected.message).show();
    }

    // update de la quatité
    this.acteCompSelected = {...this.acteCompSelected, quantite: +this.acteComplementaireForm.getRawValue().quantiteActe};

    this.dataListActesComp = [ ...this.dataListActesComp, this.acteCompSelected];

    if (this.dataListActesComp && this.dataListActesComp.length > 0) {
      this.dataListActesComp.forEach((dat, index) => {
        dat.id_key = index + 1;
      });
    }

    this.acteComplementaireForm.reset();

    this.dataActesCompEvent.emit(this.dataListActesComp);
  }

  /**
   * Effectue une demande de suppression.
   * @param data
   */
  demanderSuppression(data: any) {
    // console.log('demanderSuppression : ', data);

    let shower: any;
    shower = this.alertService.showWarning(this.messageDemandeSuppression);
    shower.accept = () => {
      const index = this.dataListActesComp.findIndex((elt) => elt.id_key === data.id_key);
      this.dataListActesComp.splice(index, 1);
      this.dataActesCompEvent.emit(this.dataListActesComp);
    };
    shower.reject = () => {
      this.rejeterSuppressionElement(data);
    };
    shower.show();
  }

  ajouterCommentairActe() {
    console.log('ajouterCommentairActe :: ');
  }

  onchangeDescriptions(event) {
    this.descriptionsEvent.emit(event.target.value);
  }

}

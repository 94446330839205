import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
    API_AFFECTION_CODITION_DETAILS_PRISE_EN_CHARGE,
    API_AFFECTION_DETAILS_PRISE_EN_CHARGE,
    STATUT_EN_ACCORD_PREALABLE,
    STATUT_EN_VALIDATION_AVIS_HOSPI,
    STATUT_REJETE
} from '@app/core/constantes';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Injectable({providedIn: 'root'})
export class AffectionTableListeRestApiService implements IRecuperationListeDonneesRequete {
    @Autowired() restService: SgiRestService;

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        // console.log('data : ', data);
        // console.log('args : ', args);

        const {currentDataUser} = args;

        let apiUrl: string;

        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(currentDataUser.libelleTypeStatut) &&
            (currentDataUser.libelleTypeStatut === STATUT_EN_ACCORD_PREALABLE ||
                currentDataUser.libelleTypeStatut === STATUT_EN_VALIDATION_AVIS_HOSPI ||
                currentDataUser.libelleTypeStatut === STATUT_REJETE)) {

            apiUrl = API_AFFECTION_CODITION_DETAILS_PRISE_EN_CHARGE;

        } else {
            apiUrl = API_AFFECTION_DETAILS_PRISE_EN_CHARGE;
        }

        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                idDemandePriseEnCharge: currentDataUser.idDemandePriseEnCharge,
                InfoSearchidDemandePriseEnCharge: {Consider: true},
                InfoSearchlibelleAffection: {IsOrderByField: true, Consider: false}
            }
        };

      // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${apiUrl}`, sendData, {...this.postArgsMute});
    }
}

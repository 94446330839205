import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MedecinMultiselectComponent} from './medecin.multiselect.component';
import {MedecinComboComponent} from './medecin.combo.component';
import {SharedModule} from '@shared/shared.module';
import {MedecinAutocompleteComponent} from './medecin.autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [MedecinMultiselectComponent, MedecinComboComponent, MedecinAutocompleteComponent],
  exports: [MedecinMultiselectComponent, MedecinComboComponent, MedecinAutocompleteComponent]
})
export class MedecinFormModule {
}


import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {FormGroup} from '@angular/forms';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {AlertService} from '@app/core/services/alert.service';
import {ModaleModifierFactureActionMetierService} from './modale-modifier-facture-action.metier.service';

@Component({
    selector: 'app-modale-modifier-facture-action',
    templateUrl: './modale-modifier-facture-action.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModaleModifierFactureActionComponent extends DialogComponent implements OnInit {
    @Autowired() convertirFormulaireMetier: ModaleModifierFactureActionMetierService;
    @Autowired() initialiserFormulaireMetier: ModaleModifierFactureActionMetierService;
    @Autowired() requeteCreationMetier: ModaleModifierFactureActionMetierService;
    @Autowired() requeteModificationMetier: ModaleModifierFactureActionMetierService;
    @Autowired() metierService: ModaleModifierFactureActionMetierService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() alert: AlertService;

    searchForm: FormGroup;
    affections: any = [];
    actes: any = [];
    prescriptions: any = [];
    document: any[];
    ArgsdData: any;

    finResponse: boolean;

    constructor() {
        super();
    }

    ngOnInit() {}

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.ArgsdData = {idPrestation: this.modalManager.datasourceSelected.idPrestation};
        this.recupererDetatailsFacture(this.ArgsdData);
    }

    recupererDetatailsFacture(data) {
        this.metierService.recupererDonneesFacture(data).subscribe((response: any) => {
            this.modalManager.datasourceSelected = {
                ...this.modalManager.datasourceSelected,
                ...response.body.items[0],
            };

            console.log(this.modalManager.datasourceSelected);

            if (this.modalManager.datasourceSelected.prestationPrescriptionRealises != null) {
                if (this.modalManager.datasourceSelected.prestationPrescriptionRealises.length !== 0) {
                    this.modalManager.datasourceSelected.prestationPrescriptionRealises.forEach(item => {
                        item.totalLigne = item.quantiteRealise * item.prixUnitaire;
                    });
                }
            }

            this.finResponse = true;
        });
    }

    handleDocumentAjouter($event: any[]) {
        this.document = $event;
    }

    recupererPrescriptionModifier($event) {
        this.modalManager.datasourceSelected.prestationPrescriptionRealises = $event;
    }

    enregistrer() {
        this.metierService.enregistrerModification(this.modalManager.datasourceSelected).subscribe((resp: any) => {
            if (resp.body.hasError === false) {
                this.fermerModale();
            }
        });
    }

}

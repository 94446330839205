import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {AntecedentDossierTableRestApiService} from './antecedent-dossier-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class AntecedentDossierTableMetierService implements IRecuperationListeDonneesMetier {
    @Autowired() restApi: AntecedentDossierTableRestApiService;
    @Autowired() formBuilder: FormBuilder;

    @Autowired() datePipe: DatePipe;


    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteRecuperationListeDonnees(data, args);
    }

    initialiserFormulaireMedicaux<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            hta: !!datas && !!datas.hta ? datas.hta : null,
            diabete: !!datas && !!datas.diabete ? datas.diabete : null,
            asthme: !!datas && !!datas.asthme ? datas.asthme : null,
            ugd: !!datas && !!datas.ugd ? datas.ugd : null,
            hvc: !!datas && !!datas.hvc ? datas.hvc : null,
            hvb: !!datas && !!datas.hvb ? datas.hvb : null,
            obesite: !!datas && !!datas.obesite ? datas.obesite : null,
            drepanocytose: !!datas && !!datas.drepanocytose ? datas.drepanocytose : null,
        };

        return this.formBuilder.group({
            hta: [{value: data.hta, disabled: true}],
            diabete: [{value: data.diabete, disabled: true}],
            asthme: [{value: data.asthme, disabled: true}],
            ugd: [{value: data.ugd, disabled: true}],
            hvc: [{value: data.hvc, disabled: true}],
            hvb: [{value: data.hvb, disabled: true}],
            obesite: [{value: data.obesite, disabled: true}],
            drepanocytose: [{value: data.drepanocytose, disabled: true}],
        });
    }

    initialiserFormulaireModeVie<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            alcool: !!datas && !!datas.alcool ? datas.alcool : null,
            tabac: !!datas && !!datas.tabac ? datas.tabac : null,
            sport: !!datas && !!datas.sport ? datas.sport : null,
        };

        return this.formBuilder.group({
            alcool: [{value: data.alcool, disabled: true}],
            tabac: [{value: data.tabac, disabled: true}],
            sport: [{value: data.sport, disabled: true}],
        });
    }

    initialiserFormulaireRegimes<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            regimeDiabetique: !!datas && !!datas.regimeDiabetique ? datas.regimeDiabetique : null,
            regimeAmaigrissement: !!datas && !!datas.regimeAmaigrissement ? datas.regimeAmaigrissement : null,
            regimeHyposode: !!datas && !!datas.regimeHyposode ? datas.regimeHyposode : null,
        };

        return this.formBuilder.group({
            regimeDiabetique: [{value: data.regimeDiabetique, disabled: true}],
            regimeAmaigrissement: [{value: data.regimeAmaigrissement, disabled: true}],
            regimeHyposode: [{value: data.regimeHyposode, disabled: true}],
        });
    }

    requeteRecuperationListeAntecedent(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteRecuperationListeAntecedent(data, args);
    }

}

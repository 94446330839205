/**
 * Methode API
 */
export const METHOD_GET = 'get';
export const METHOD_POST = 'post';
export const METHOD_DELETE = 'delete';
export const METHOD_PUT = 'put';


/**
 * Lien API :: accounts
 */
export const API_CONNEXION_USER = 'accounts/signin';
export const API_DECONNEXION_USER = 'accounts/LogoutUser';

export const API_UPDATE_DATA_USER = 'accounts/EditUtilisateur'; // ReinitialiserUserPassword
export const API_UPDATE_PASSWORD_USER = 'accounts/EditUserPassword';
export const API_RESET_PASSWORD_USER = 'accounts/ReinitialiserUserPassword';


/**
 * Lien API :: assurance
 */
export const API_GET_LISTE_GARANT = 'assurance/GetListeGarantByCriteria';
export const API_GET_TERME_CONVENTION = 'assurance/GetTermeConventionByCriteria';


/**
 * Lien API :: prestation
 */
export const API_LISTE_DEMANDE_PRISE_EN_CHARGE = 'prestation/GetDemandePriseEnChargesByCriteria';
export const API_LISTE_POURSUITE_PRISE_EN_CHARGE = 'prestation/GetPoursuiteDemandePriseEnChargesByCriteria';
export const API_DELETE_PEC = 'prestation/DeleteCustomDemandePriseEnCharge';

export const API_ACTE_PRESTATION_DETAILS_PRISE_EN_CHARGE = 'prestation/GetViewPrestationActesByCriteria';
export const API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE = 'prestation/GetViewDemandePriseEnChargesLigneByCriteria';
export const API_ACTE_POURSUITE_DEMANDE_PRISE_EN_CHARGE = 'prestation/GetListePriseEnChargesLigneByCriteria';
export const API_HOSPITALISATION_DETAILS_PRISE_EN_CHARGE = 'prestation/GetDemandePriseEnChargeSoinHospitalisation';
export const API_AFFECTION_DETAILS_PRISE_EN_CHARGE = 'prestation/GetViewPrestationAffectionsByCriteria';
export const API_AFFECTION_CODITION_DETAILS_PRISE_EN_CHARGE = 'prestation/GetViewDemandePriseEnChargeAffections';
export const API_PRESCRIPTION_DETAILS_PRISE_EN_CHARGE = 'prestation/GetViewPrestationPrescriptionsByCriteria';
export const API_PHARMACIE_PRESCRIPTION_DETAILS_PRISE_EN_CHARGE = 'prestation/GetViewPrestationPrescriptionPharmaciesByCriteria';
export const API_PRESCRIPTION_DETAILS_PERIODIQUE = 'prestataire/GetPrescriptionMaladieChroniquePharmacie';
export const API_MOTIF_ACCORD_PREALABLE_DETAILS_PRISE_EN_CHARGE = 'prestation/GetRegleGestionActesByCriteria';
export const API_MOTIF_REJET_DETAILS_PRISE_EN_CHARGE = 'prestation/GetRegleGestionActesByCriteria';
export const API_DOCUMENT_BD_PRESTATION_DETAILS_PRISE_EN_CHARGE = 'prestation/GetListDocumentBdPrestations';
export const API_DOCUMENT_BD_DETAILS_PRISE_EN_CHARGE = 'prestation/GetListDocumentBd';
export const API_PRINT_AVIS_HOSPI_PRISE_EN_CHARGE = 'prestation/PrintAvisHospitalisation';

export const API_GET_ASSURE_NOUVELLE_DEMANDE_PRISE_EN_CHARGE = 'prestation/GetRechercheAdherentAffilieByCriteria';

export const API_GET_MEDECINS_DEMANDE_PRISE_EN_CHARGE = 'prestation/GetPoolMedicalDemandePecClotureByCriteria';
export const API_GET_ACTES_DEMANDE_PRISE_EN_CHARGE = 'prestation/GetRechercheActeDemandePECsByCriteria';

export const API_GET_ACTES_HOSPITILISATION_DEMANDE_PRISE_EN_CHARGE = 'prestation/GetActeConventionnePecByCriteria';

export const API_GET_AFFECTIONS_DEMANDE_PRISE_EN_CHARGE = 'prestation/GetAffectionDemandePecByCriteria';

export const API_RECUPERER_ACTES_DEMANDE_PEC = 'prestation/GetActeDemandePECByCriteria';
export const API_CALCULER_ACTES_DEMANDE_PEC = 'prestation/CalculMontantPriseEnChargeTemp';

export const API_CALCULER_ACTES_HOSPITALISATION_DEMANDE_PEC = 'prestation/CalculMontantAPayerPriseEnChargeAvecSoinTemp';

export const API_VERIFICATION_NOUVELLE_DEMANDE_PEC = 'prestation/GetAutoriserCentreConventionneHeureAccesByCriteria';

export const API_SAVE_NOUVELLE_DEMANDE_PEC = 'prestation/SaveViewDemandePriseEnCharges';

export const API_TYPE_DOCUMENT_DEMANDE_PEC = 'parametre/GetListeDocumentAfournir';

export const API_PRINT_BON_PEC = 'prestation/PrintBonPec';
export const API_PRINT_BON_VIDE_PEC = 'prestation/PrintBonPecVide';
export const API_PRINT_AVIS_HOSPITALISATION = 'prestation/PrintAvisHospitalisation';
export const API_PRINT_BON_PEC_HOSPITALISATION = 'prestation/PrintBonPecHospit';

export const API_VERIFICATION_ACTION = 'prestation/GetAutoriserCentreConventionneHeureAccesByCriteria';
// export const API_RECUPERER_ACTES_CLOTURE = 'prestation/GetViewPrestationActesDemandeLigneByCriteria';
export const API_RECUPERER_ACTES_CLOTURE = 'prestation/GetCustomPrestationActeInterpretationByCriteria';
export const API_RECUPERER_ACTES_COMPLEMENTAIRE = 'prestation/GetActeDemandePECClotureByCriteria';
export const API_RECUPERER_MEDICAMENT_PAR_LIBELLE = 'prestation/GetMedicamentDemandePECByCriteria';

export const API_RECUPERER_INFO_USER_POURSUITE = 'assurance/GetListeAssureByCriteria';

export const API_CLOTURE_POURSUITE_PEC = 'prestation/CloseViewDemandePriseEnCharges';
export const API_CAN_CLOSE_POURSUITE_PEC = 'prestation/CanClosePrestation';
export const API_RECUPERER_PRIX_ACTES_POURSUITE = 'prestation/GetViewDemandePriseEnChargeLignesFacturation';

export const API_CALCUL_MONTANT_PART_ASSURE = 'prestation/CalculMontantPriseEnCharge';

export const API_RECUPERER_BON_PRESCRIPTION_EN_ATTENTE = 'prestation/GetPrescriptionEnAttente';
export const API_RECUPERER_BON_PRESCRIPTION_PERIODIQUE = 'prestataire/GetListeAssureMaladieChroniquesPharmacieByCriteria';
export const API_RECUPERER_BON_PRESCRIPTION_ADHERENT_AFFILLIE = 'prestation/GetViewAdherentAffilieByCriteria';
export const API_CALCULER_MONTANT_PRESCRIPTION = 'prestation/CalculMontantPrescription';
export const API_ENREGISTRER_LES_PRESCRIPTION = 'prestation/SaveViewPrestationPrescriptions';
export const API_POUR_RECUPERER_LES_MEDICAMENTS_DE_SUBSTITUTIONS = 'reference/GetViewMedicamentSubstitutionByCriteria';

export const API_RECUPERER_ADHERANT_HOSPITALISATION = 'prestation/GetCustomViewDemandePriseEnChargeActeHospitalisationsByCriteria';
export const API_RECUPERER_SOINS_HOSPITALISATION = 'prestation/GetDemandePriseEnChargeSoinHospitalisation';
export const API_SUPPRESSION_SOINS_HOSPITALISATION = 'prestation/CalculMontantAPayerPriseEnChargeSoinTemp';

export const API_ENREGISTRER_SOINS_HOSPITALISATION = 'prestation/SaveDemandePriseEnChargeSoinHospitalisation';

export const API_POUR_RECUPERER_LISTE_FACTURE_PRISE_EN_CHARGE = 'prestataire/GetListePriseEnChargesByCriteria';
export const API_POUR_RECUPERER_LISTE_CLIENT_GARANTS = 'assurance/GetListeClientGarantsByCriteria';
export const API_POUR_RECUPERER_LISTE_DES_BRDEREAUX = 'prestataire/GetListeBordereauxByCriteria';
export const API_POUR_ENREGISTRER_LES_BRDEREAUX = 'prestataire/SaveBordereaux';
export const API_PRINT_BORDEREAU = 'prestataire/PrintBordereau';
export const API_VALIDER_BORDEREAU = 'prestataire/ValiderBordereau';
export const API_RECUPERER_BORDEREAU = 'prestataire/GetBordereaux';
export const API_RECUPERER_DETAILS_FACTURE = 'prestataire/GetListeCustomPriseEnChargesByCriteria';
export const API_ENREGISTRER_FACTURE_MODIFIER = 'prestataire/RessaisirPrestationSinistreTp';
export const API_MIDIFIER_FACTURE = 'prestataire/UpdatePrestationSinistreTp';
export const API_RETIRER_FACTURE = 'prestataire/RetirerFacture';

export const API_ENREGISTRER_DEMANDE_DOSSIER_MEDICAL = 'prestation/SaveDossierMedicalDemandeAcces';

export const API_RECUPERER_INFO_USER = 'prestation/GetCustomDemandePriseEnChargesByCriteria';

export const API_RECUPERER_ACTES_ANALYSE = 'prestation/GetCustomPrestationActeInterpretationByCriteria';


/**
 * Lien API :: dossiermedical
 */
export const API_RECUPERER_ASSURE_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetListAssureDossierMedicalEnCours';
export const API_RECUPERER_PRESTATION_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetDossierMedicalPrestationAssure';
export const API_RECUPERER_ACTE_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetViewPrestationActesByCriteria';
export const API_RECUPERER_RESULTAT_EXAMEN_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetViewPrestationActesResultatsExamenByCriteria';
export const API_RECUPERER_PRESCRIPTION_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetViewPrestationPrescriptionsByCriteria';
export const API_RECUPERER_AFFECTION_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetViewPrestationAffectionsByCriteria';
export const API_RECUPERER_PRESCRIPTION_REALISE_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetPrescriptionsRealieesPharmacie';
// tslint:disable-next-line:max-line-length
export const API_RECUPERER_PRESTATION_RESULTATS_EXAMEN_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetViewPrestationResultatsExamenByCriteria';
export const API_RECUPERER_MALADIE_CHRONIQUE_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetListeMaladieChroniquesByCriteria';
export const API_RECUPERER_ANTECEDENT_DOSSIER_MEDICAL_ENCOURS = 'dossiermedical/GetAntecedentsByCriteria';

/**
 * Lien API :: parametre
 */
export const API_RECUPERER_PERSONNEL_MEDICAL = 'parametre/GetPoolMedicalPersonnel';

/**
 * Lien API :: reference
 */
export const API_RECUPERER_REFERENCE_SPECIALITE = 'reference/GetReferencePersonnelMedicalSpecialiteByCriteria';


/**
 * Lien API :: prestataire
 */
export const API_RECUPERER_PRESCRIPTION_MALADIE_CHRONIQUE_DOSSIER_MEDICAL_ENCOURS = 'prestataire/GetListePrescriptionMaladieChroniquesByCriteria';

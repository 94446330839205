import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {ThemeStoreService} from './theme.store.service';
import {ThemeQueryService} from './theme.query.service';

/**
 * Service d'état du thème de l'application
 */
@Injectable({providedIn: 'root'})
export class ThemeStateService {
  @Autowired() store: ThemeStoreService;
  @Autowired() query: ThemeQueryService;

  /**
   * Définir le theme
   */
  setTheme(theme: string) {
    window.localStorage.setItem('theme', theme);
    const sbtheme = window.localStorage.getItem('sb-theme');
    this.store.update({theme: theme, sidebar: sbtheme});

    const body = document.querySelector('body');
    body.classList.forEach((value, key, parent) => {
      if (value.startsWith('thm-')) {
        body.classList.remove(value);
      }
    });
    body.classList.add(this.getTheme());
  }

  /**
   * Recuperer le theme
   */
  getTheme() {
    return this.query.__store__._value().theme;
  }

  /**
   * Définir le theme du sidebar
   *
   * @param sbtheme
   */
  setSideBarTheme(sbtheme: string) {
    window.localStorage.setItem('sb-theme', sbtheme);
    const theme = window.localStorage.getItem('theme');
    this.store.update({theme: theme, sidebar: sbtheme});

    const body = document.querySelector('body');
    body.classList.forEach((value, key, parent) => {
      if (value.startsWith('sb-')) {
        body.classList.remove(value);
      }
    });
    body.classList.add(this.getSidebarTheme());
  }

  /**
   * Recuperer le theme du sidebar
   */
  getSidebarTheme() {
    return this.query.__store__._value().sidebar;
  }

  /**
   * Initialise l'état
   */
  init(defaultTheme: string, defaultSideBarTheme: string) {
    try {
      let theme = window.localStorage.getItem('theme');
      let sbTheme = window.localStorage.getItem('sb-theme');
      if (!theme) {
        theme = defaultTheme;
      }
      if (!sbTheme) {
        sbTheme = defaultSideBarTheme;
      }
      this.setTheme(theme);
      this.setSideBarTheme(sbTheme);
    } catch (e) {
    }
  }
}

import {Component, Input, OnInit} from '@angular/core';
import {Table} from 'primeng/table';
import {TableColumn} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {TableUtilities} from '@app/core/utilities/table.utilities';

/**
 * Implémente les filtres d'un tableau
 */
@Component({
  selector: 'app-table-filter',
  templateUrl: 'table-filter.component.html'
})

export class TableFilterComponent implements OnInit {
  private column: TableColumn;
  private statesColumnDropdown: {label: string, value: any}[];
  /**
   * Représente la colonne à filtrer
   */
  @Input('column') set s_column(col: TableColumn) {
    this.column = col;
    this.statesColumnDropdown = TableUtilities.statesToDropdown(this.column.states);
  }

  /**
   * Reférence de l'objet p-table (Ex: #dtable)
   */
  @Input('table') table: Table;

  /**
   * Indique si le composnant doit afficher le filtre global ou les filtres de colonnes.
   *
   * defaut: false.
   */
  @Input('global') global = false;

  /**
   * Défini le placeholder du filtre global
   */
  @Input('globalPlaceholder') globalPlaceholder = 'Rechercher tout';

  constructor() {
  }

  ngOnInit() {
  }
}

import {Component, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {TableAction} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {
    ERROR_MESSAGE_BORDEREAU_STATUT_CREE,
    ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE,
    ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC,
    PERMISSION_PRESTATION_FACTURE_IMPRIMERBORDEREAU,
    PERMISSION_PRESTATION_FACTURE_MODIFIERBORDEREAU,
    PERMISSION_PRESTATION_FACTURE_VALIDERBORDERAU,
} from '@app/core/constantes';
import {FormBuilder} from '@angular/forms';
import {BordereauListeMetierService} from '@app/fonctionnalites/factures/bordereau/liste/bordereau-liste.metier.service';

@Component({
    selector: 'app-bordereau-liste-feature',
    templateUrl: './bordereau-liste.feature.component.html'
})

export class BordereauListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: BordereauListeMetierService;
    @Autowired() recuperationListeDonneesMetier: BordereauListeMetierService;
    @Autowired() metierService: BordereauListeMetierService;

    tabActive = 'Actes';
    currentDataTemp: any;

    @Autowired() formBuilder: FormBuilder;

    affichModalModifierBordereau = false;
    affichModalPrintBon = false;
    tableSelectedElement: any;

    statusSelect = [
        {label: 'Payé', value: 'Payé'},
        {label: 'Validé rattaché à un décompte', value: 'Validé rattaché à un décompte'},
        {label: 'Validé non rattaché à un décompte', value: 'Validé non rattaché à un décompte'},
        {label: 'Créé', value: 'Créé'},
    ];

    /**
     * Boutton Selectionner tout
     */
    selectionnerToutAction: TableAction = {
        type: 'button', title: 'Selectionner tout', flat: false, icon: 'done', notFlatClass: '', actionPerformance: (data) => {
            this.selectionnerTouteLaListe();
        }, canShow: (data) => true
    };

    /**
     * Boutton DéSelectionner tout
     */
    deselectionnerToutAction: TableAction = {
        type: 'button', title: 'Déselectionner tout', flat: false, icon: 'done', notFlatClass: '', actionPerformance: (data) => {
            this.deselectionnerTouteLaListe();
        }, canShow: (data) => true
    };

    /**
     * Boutton de valider
     */
    validerBordereauAction: TableAction = {
        type: 'button', title: 'Valider', flat: false, icon: 'description', notFlatClass: '', actionPerformance: (data) => {
            this.validerBordereau();
        }, canShow: (data) => true
    };

    /**
     * Boutton 'imprimer
     */
    imprimerBonAction: TableAction = {
        type: 'button', title: 'Imprimer.', flat: false, icon: 'print', notFlatClass: '', actionPerformance: (data) => {
            this.imprimerBon();
        }, canShow: (data) => true
    };

    /**
     * Boutton 'imprimer
     */
    /*prescriptionSuppAction: TableAction = {
        type: 'button', title: 'Oui.', flat: false, icon: 'print', notFlatClass: '', actionPerformance: (data) => {
            this.imprimerBon();
        }, canShow: (data) => true
    };*/

    columns: any = [];
    typeRechercheCombo: any[] = [];
    placeholderInputSearch: string;

    constructor() {
        super();
        this.cols = [
            {field: 'numeroBordereau', header: 'Ref Bordereau'},
            {field: 'numeroFacture', header: 'Numero Facture'},
            {field: 'montantBrut', header: 'Montant', type: () => 'price'},
            {field: 'montantPartAssurance', header: 'Part Assurance Brut', type: () => 'price'},
            {field: 'montantExclu', header: 'Montant Exclu', type: () => 'price'},
            {field: 'montantDette', header: 'Montant Dette', type: () => 'price'},
            {field: 'montantPartAssurance', header: 'Net à Payer', type: () => 'price'},
            {field: 'statut', header: 'Statut'},
        ];

        this.notRowActions = [
            this.selectionnerToutAction,
            /*this.prescriptionSuppAction*/
        ];
        this.tableActions = [];

        this.typeRechercheCombo = [
            {label: 'Ref Bordereau', value: 'numeroBordereau'},
            {label: 'Numero Facture', value: 'numeroFacture'},
        ];
    }

    ngOnInit() {
        super.ngOnInit();
        this.recuperDonnees();

        /*if (CommonUtilities.currentUserHasPermissions('PERMISSION_PRESTATION_FACTURE_VALIDERBORDERAU')) {
          this.notRowActions = [...this.notRowActions, this.prescriptionSuppAction];
        }*/
        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_VALIDERBORDERAU)) {
            this.notRowActions = [...this.notRowActions, this.validerBordereauAction];
        }
        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_IMPRIMERBORDEREAU)) {
            this.notRowActions = [...this.notRowActions, this.imprimerBonAction];
        }

        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_MODIFIERBORDEREAU)) {
            this.tableActions = [...this.tableActions, this.modifierAction];
        }

        this.placeholderInputSearch = 'numeroBordereau';
    }

    selectChange(event) {
        this.recuperDonnees({Statut: event.value});
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
            // dateNow: this.formGroup.value.dateNow,
            // dateDebut: this.formGroup.value.dateDebut,
            // dateFin: this.formGroup.value.dateFin
        };
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }


    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
        this.tabActive = '';
    }


    /**
     * Selectionne toutes les lignes de la liste
     */
    selectionnerTouteLaListe() {
        this.tableSelectedElement = this.dataSource;
        this.notRowActions = [
            this.deselectionnerToutAction,
            this.imprimerBonAction,
            /*this.prescriptionSuppAction,*/
        ];
    }

    /**
     * Déselectionne toutes les lignes selectionnées de la liste
     */
    deselectionnerTouteLaListe() {
        this.tableSelectedElement = null;
        this.notRowActions = [
            this.selectionnerToutAction,
            this.imprimerBonAction,
            // this.prescriptionSuppAction,
        ];
    }

    /**
     * => return true, si le tableau est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }


    getTabChange(event) {
        this.currentRow = null;
        this.currentRow = this.currentDataTemp;
        this.tabActive = event.originalEvent.target['innerText'];
    }

    /**
     * Effectue une demande de modification
     * @param modalManager
     * @param data
     */
    modifierDonnee(modalManager, data: any) {
        this.affichModalModifierBordereau = false;
        this.affichModalPrintBon = false;
        this.gestionnaireDeModales[0].display = false;

        if (data.statut !== 'Créé') {
            this.alertService.showError(ERROR_MESSAGE_BORDEREAU_STATUT_CREE).show();
            return;
        }

        // this.gestionnaireDeModales[0].id = 'nouvelle_demande';
        this.affichModalModifierBordereau = true;
        this.gestionnaireDeModales[0].datasourceSelected = {...data};
        this.gestionnaireDeModales[0].display = true;
    }

    imprimerBon() {
        this.affichModalModifierBordereau = false;
        this.affichModalPrintBon = false;
        this.gestionnaireDeModales[0].display = false;

        if (!this.tableSelectedElement) {
            let alertMessage: any;
            alertMessage = this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE);
            alertMessage.show();
            return;
        }

        this.requetePrintDocument(
            {numeroBordereau: this.tableSelectedElement[0].numeroBordereau},
            '-- Bon de prise en charge N°',
            this.tableSelectedElement[0].numeroBordereau,
        );
    }

    requetePrintDocument(arg, message, value) {
        this.affichModalModifierBordereau = false;
        this.affichModalPrintBon = false;
        this.gestionnaireDeModales[0].display = false;

        this.metierService.requetePrintDocument({}, {...arg}).subscribe((reponse) => {

            const arrayConstruct: any[] = [];
            arrayConstruct.push({
                File: reponse.body.items[0],
                Name: `${message} ${value}`,
            });

            this.affichModalPrintBon = true;
            this.gestionnaireDeModales[0].datasourceSelected = arrayConstruct;
            this.gestionnaireDeModales[0].display = true;
        });
    }

    validerBordereau() {

        if (!this.tableSelectedElement) {
            let alertMessage: any;
            alertMessage = this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE);
            alertMessage.show();
            return;
        }

        this.metierService.requeteValiderBordereau(this.tableSelectedElement).subscribe(response => {
            this.recuperDonnees();
        });
    }

    rechercheFacture($event: any) {

        if (this.placeholderInputSearch === 'numeroBordereau') {
            this.recuperDonnees({numeroBordereau: this.formGroup.getRawValue().searchKey});
        } else if (this.placeholderInputSearch === 'numeroFacture') {
            this.recuperDonnees({numeroFacture: this.formGroup.getRawValue().searchKey});
        }
    }

    handleChangeTypeRecherche($event: any) {
        this.placeholderInputSearch = $event.value;
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        if (data.libelleTypeStatut === 'Cloturé') {
            shower = this.alertService.showError(ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC);
            shower.show();
            return;
        }

        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.metierService.requeteSuppression(data).subscribe(() => {
                // this.metierService.requeteRecuperationListeDonnees({});
                this.recuperDonnees();
            });
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }
}

import {NgModule} from '@angular/core';

import {MedecinActeTableComponent} from './medecin-acte-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../../shared/shared.module';
import {MedecinFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/medecin/form/medecin.form.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MedecinFormModule,
    ],
  exports: [MedecinActeTableComponent],
  declarations: [MedecinActeTableComponent],
  providers: [],
})
export class MedecinActeTableModule {
}

import {Component, Input} from '@angular/core';
import {MotifRejetTableListeMetierService} from './motif-rejet-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';

@Component({
  selector: 'app-motif-rejet-table-liste',
  templateUrl: './motif-rejet-table.liste.component.html'
})

export class MotifRejetTableListeComponent extends SgiListLayoutComponent {
  @Autowired() recuperationListeDonneesMetier: MotifRejetTableListeMetierService;

  @Input('currentDataUser') currentDataUser: any;

  constructor() {
    super();
    this.cols = [
      { field: 'codeMessage', header: 'Libellé' },
    ];

    this.tableActions = [];
  }

  ngOnInit(): void {
    if (this.currentDataUser) {
      this.recuperDonnees();
    }
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, currentDataUser: this.currentDataUser};
    this.args = {...arg};
  }

}

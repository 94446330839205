import {Component, Input, OnInit} from '@angular/core';
import {DetailsAffectionTableListeMetierService} from './details-affection-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@app/shared/behaviors/list-layout/sgi/sgi-list-layout.component';

@Component({
    selector: 'app-details-affection-table-liste',
    templateUrl: './details-affection-table.liste.component.html'
})

export class DetailsAffectionTableListeComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: DetailsAffectionTableListeMetierService;
    @Autowired() metierService: DetailsAffectionTableListeMetierService;

    @Input('currentDataUser') currentDataUser: any = {};
    dataSourceLength: any;
    tabActive = 'Prescriptions';
    hasDetailsPrescriptionLier: boolean;

    constructor() {
        super();
        this.tableActions = [];
    }


    ngOnInit(): void {
        this.cols = [
            {field: 'codeAffection', header: 'Code Affection'},
            // {field: 'libelleAffection', header: 'Libellé'},
        ];

        this.recuperDonnees();
        //this.ListPoursuiteListeAffection()

        this.hasDetailsPrescriptionLier = this.currentDataUser.affectionPrescriptionLier;
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args, currentDataUser: this.currentDataUser};
        this.args = {...arg};
    }

    getTabChange(event) {
        this.currentRow = null;
        this.tabActive = event.originalEvent.target['innerText'];
    }

    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
    }

    ListPoursuiteListeAffection() {
        this.metierService.recupererPoursuiteListeAffection(this.currentDataUser).subscribe(response => {
            this.dataSource = response.body.items;
            this.dataSourceLength = response.body.items.length;
            this.dataSource.forEach((data, index) => {
                data.index = index + 1;
                this.dataSourceLength++;
                data.id_key = index + 1;
            });
        });
    }

}

import {NgModule} from '@angular/core';

import {ListAffectionPrescriptionTableListeComponent} from './list-affection-prescription-table.liste.component';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {AffectionAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/affection-autocomplete/form/affection-autocomplete.form.module';
import {PrescriptionAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/composants/prescription-table/prescription-autocomplete/form/prescription-autocomplete.form.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AffectionAutocompleteFormModule,
        PrescriptionAutocompleteFormModule,
    ],
  exports: [ListAffectionPrescriptionTableListeComponent],
  declarations: [ListAffectionPrescriptionTableListeComponent],
  providers: [],
})
export class ListAffectionPrescriptionTableListeModule {
}

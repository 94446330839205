import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {PaginationPipe} from './pagination/pagination.pipe';
import {ProfilePicturePipe} from './profilePicture/profilePicture.pipe';
import {ChatPersonSearchPipe} from '@app/theme/pipes/search/chat-person-search.pipe';
import {UserSearchPipe} from '@app/theme/pipes/search/user-search.pipe';
import {TruncatePipe} from '@app/theme/pipes/truncate/truncate.pipe';
import {MailSearchPipe} from '@app/theme/pipes/search/mail-search.pipe';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        PaginationPipe,
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe
    ],
    exports: [
        PaginationPipe,
        ProfilePicturePipe,
        ChatPersonSearchPipe,
        UserSearchPipe,
        TruncatePipe,
        MailSearchPipe
    ]
})
export class PipesModule {
}

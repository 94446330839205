import {AfterViewInit, Component, Input} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {Settings} from '@app/app.settings.model';
import {AppSettings} from '@app/app.settings';
import {AtlantisReactiveForm} from '@shared/behaviors/atlantis-extend-clv/atlantis-reactive-form';

/**
 * Défini les propriétés communes aux pages
 */
@Component({
    selector: 'app-page-layout',
    templateUrl: './page-layout.component.html',
    styleUrls: ['./page-layout.component.scss']
})
export class PageLayoutComponent extends AtlantisReactiveForm implements AfterViewInit {
    /**
     * Titre de la page
     */
    @Input('title') title = 'Page';

    /**
     * Icone de la page
     */
    @Input('icon') icon = '';

    /**
     * Indique que l'entête a un toolbar
     */
    @Input('hasToolbar') hasToolbar = false;

    /**
     * Service de configuration de l'application
     */
    @Autowired() appSettings: AppSettings;

    /**
     * Configurations de l'aplication.
     * Une instance de cette classe est crée dans l'application et contient les parametres de l'application.
     */
    public settings: Settings = this.appSettings.settings;

    /**
     * Indique l'onglet de premier niveau actif
     */
    tabViewFirstLevelActive = 0;

    /**
     * Indique l'onglet de second niveau actif
     */
    tabViewSecondLevelActive = 0;

    ngAfterViewInit() {
        super.ngAfterViewInit();
        this.settings.loadingSpinner = false;
    }
}

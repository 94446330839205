import {Component, Input} from '@angular/core';
import {AbstractFormInputs} from '../abstract-form-inputs';

@Component({
  selector: 'app-form-input',
  template: `
    <div class="input-container">
      <div class="d-flex justify-between">
        <label for="{{id}}">{{label}}&nbsp;<span *ngIf="required" class="danger-color">*</span></label>
        <div>
          <app-info-bull-widget [infos]="infos"></app-info-bull-widget>
        </div>
      </div>
      <div [class.ui-inputgroup]="hasAction">
        <button *ngIf="hasAction" pButton type="button"
                (click)="actionDeclenche.emit()" [icon]="actionIcon ? 'pi pi-' + actionIcon : null"
                class="ui-button-{{actionColor}}"
                [label]="actionText ? actionText : null"></button>
        <input *ngIf="useNgModel"
               class="w-100"
            [attr.type]="type" [required]="required" id="{{id}}"
               [placeholder]="placeholder"
               [attr.minlength]="minlength"
               [attr.min]="min"
               [attr.max]="max"
               autocomplete="off"
               [email]="email"
               (change)="onChange?.emit($event)"
               (blur)="onBlur?.emit($event)"
               (click)="onClick?.emit($event)"
               (focus)="onFocus?.emit($event)"
               (show)="onShow?.emit($event)"
               [attr.maxlength]="maxlength"
               [(ngModel)]="ngModel"
               [value]="value"
               (ngModelChange)="ngModelChange.emit($event)"
               [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
               [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"
               [attr.size]="size" pInputText>

        <input *ngIf="!useNgModel"
               class="w-100"
            [attr.type]="type" [required]="required" id="{{id}}"
               [placeholder]="placeholder"
               [attr.minlength]="minlength"
               [attr.min]="min"
               [attr.max]="max"
               autocomplete="off"
               [email]="email"
               [value]="value"
               (change)="onChange?.emit($event)"
               (blur)="onBlur?.emit($event)"
               (click)="onClick?.emit($event)"
               (focus)="onFocus?.emit($event)"
               (show)="onShow?.emit($event)"
               [attr.maxlength]="maxlength"
               [formControl]="control || defaultControl"
               [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
               [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"
               [attr.size]="size" pInputText>
      </div>
      <app-list-form-error [control]="control"
                           [types]="errorTypes"
                           [submitted]="submitted"
                           [messages]="messages"></app-list-form-error>
    </div>`
})
export class FormInputComponent extends AbstractFormInputs {
  @Input('size') size = 'auto';
  @Input('type') type = 'text';
  @Input('value') value = '';
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListAffectionPrescriptionTableListeMetierService} from './list-affection-prescription-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {FormGroup} from '@angular/forms';
import {AUCUN_ASSURE_RECHERCHER, AUCUN_MEDECIN_RECHERCHER, FORMULAIRE_INVALIDE_MESSAGE} from '@app/core/constantes';
import {objectKeys} from 'codelyzer/util/objectKeys';
import {TableAction, TableColumn} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {PrescriptionTableMetierService} from '@app/fonctionnalites/demande-pec/composants/prescription-table/prescription-table.metier.service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IListGarant} from '@app/core/interfaces/list-garant/i-list-garant';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

@Component({
    selector: 'app-list-affection-prescription-table-liste',
    templateUrl: './list-affection-prescription-table.liste.component.html'
})

export class ListAffectionPrescriptionTableListeComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: ListAffectionPrescriptionTableListeMetierService;
    @Autowired() demandePECMetierService: DemandePriseChargeMetierService;
    @Autowired() metierServicePrescription: PrescriptionTableMetierService;
    @Autowired() securityService: SecurityStateService;


    @Input() adherant: any;
    @Input() medecinSelected: any;

    /** Add affection liée */
    @Input() dataListAffections: any[] = [];
    @Output() dataListAffectionsSelectedEvent = new EventEmitter<any[]>();
    affectionSelectedForm: FormGroup;
    affectionSelected: any;
    listAffectionTable: any[] = [];

    /** Add prescription liée */
    @Input() modalOpen: any;
    @Input() dataListPrescription: any[] = [];
    @Output() dataListPrescriptionEvent = new EventEmitter<any[]>();
    prescriptionForm: FormGroup;
    prescriptionSelected: any;
    prescriptionSelectedTemp: any[] = [];

    tableListAffectionPrescription: any[] = [];
    tableListPrescriptionDetails: any[] = [];

    tableActionsAffection: Array<TableAction> = [];
    tableActionsPrescription: Array<TableAction> = [];
    colsAffection: TableColumn[] = [];
    colsPrescription: TableColumn[] = [];

    /**
     * Bouton pour ajouter un commentaire dans le cas d'un acte soumis a accord préalable
     */
    ajouterCommentaireAction: TableAction = {
        // tslint:disable-next-line:max-line-length
        type: 'button',
        title: 'Ajouter commentaire',
        flat: true,
        icon: 'assignment',
        flatClass: 'warning-color',
        actionPerformance: (data) => {
            this.ajouterCommentairActePrescrition(data);
        },
        canShow: (data) => !!data.accordPrealable
    };

    /**
     * Bouton de suppression
     */
    supprimerAffectionAction: TableAction = {
        type: 'button', title: 'Supprimer', flat: true, icon: 'delete', flatClass: 'danger-color', actionPerformance: (data) => {
            this.demanderSuppression(data);
        }, canShow: (data) => true
    };
    /**
     * Bouton de suppression
     */
    supprimerPrescriptionAction: TableAction = {
        type: 'button', title: 'Supprimer', flat: true, icon: 'delete', flatClass: 'danger-color', actionPerformance: (data) => {
            this.demanderSuppressionPrescription(data);
        }, canShow: (data) => true
    };

    listGarantUser: IListGarant;


    constructor() {
        super();
        this.tableActionsAffection = [
            this.supprimerAffectionAction,
        ];
        this.tableActionsPrescription = [
            this.supprimerPrescriptionAction,
            this.ajouterCommentaireAction,
        ];
    }

    ngOnInit(): void {
        this.colsAffection = [
            {field: 'codeAffection', header: 'Code'},
            {field: 'libelleAffection', header: 'Libelle'},
        ];

        this.colsPrescription = [
            {field: 'libelle', header: 'Médicament'},
            {field: 'quantite', header: 'Quantité'},
            {field: 'posologie', header: 'Posologie'},
            {field: 'medecin', header: 'Médecin'},
        ];

        this.recuperationAffectionSave();

        this.initFormulaireAffection();
        this.initFormulairePrescription();

        this.formatTableListAddIndex(this.dataListAffections);

        this.listGarantUser = this.securityService.getListGarant();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args, adherant: this.adherant};
        this.args = {...arg};
    }

    initFormulaireAffection() {
        this.affectionSelectedForm = this.demandePECMetierService.initialiserFormulaireAffectionSelected(null);
    }

    recuperationAffectionSave() {
        if (!this.demandePECMetierService.affections || this.demandePECMetierService.affections.length === 0) {
            const reqAtes = this.demandePECMetierService.recupererAffections({});
            reqAtes.subscribe(res => {
                this.demandePECMetierService.affections = res.body.items;
            });
        }
    }

    formatTableListAddIndex(responseData) {
        if (responseData === undefined) {
            this.listAffectionTable = [];
            this.totalRecords = 0;
            return;
        }

        this.listAffectionTable = [...this.listAffectionTable, ...responseData];
        this.totalRecords = null;
        if (this.listAffectionTable && this.listAffectionTable.length > 0) {
            this.listAffectionTable.forEach((data, index) => {
                data.id_key = index + 1;
            });
        }

        this.tableListAffectionPrescription = [...this.listAffectionTable];
    }

    actionSelectedAffection(event) {
        this.affectionSelected = event;
        let ErrorAlert: any;

        if (!this.adherant || objectKeys(this.adherant).length === 0) {
            ErrorAlert = this.alertService.showError(AUCUN_ASSURE_RECHERCHER);
            ErrorAlert.show();
            this.affectionSelectedForm.patchValue({
                codeAffection: null
            });
            return;
        }
        /*if (!this.medecinSelected || objectKeys(this.medecinSelected).length === 0) {
          ErrorAlert = this.alertService.showError(AUCUN_MEDECIN_RECHERCHER);
          ErrorAlert.show();
          this.affectionSelectedForm.patchValue({
            codeAffection: null
          });
          return;
        }*/

        const findElt = this.listAffectionTable.find((elt) => elt.codeAffection === this.affectionSelected.codeAffection);
        if (findElt !== undefined) {
            ErrorAlert = this.alertService.showError('Cette affection a déjà été ajoutée !');
            ErrorAlert.show();
            this.resetFormulaire();
            return;
        }
    }

    ajouterAffection() {
        if (!this.affectionSelectedForm.getRawValue().codeAffection) {
            this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        this.dataListAffections = [
            ...this.dataListAffections,
            this.affectionSelected
        ];
        this.listAffectionTable = [
            ...this.listAffectionTable,
            this.affectionSelected
        ];

        if (this.listAffectionTable && this.listAffectionTable.length > 0) {
            this.listAffectionTable.forEach((data, index) => {
                data.id_key = index + 1;
            });
        }

        // this.resetFormulaure();
        this.affectionSelectedForm.reset();

        this.dataListAffectionsSelectedEvent.emit(this.dataListAffections);
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {

            this.dataListAffections.forEach((elt) => {
                if ((elt.codeAffection === data.codeAffection) && elt.addElementExtern !== undefined) {
                    elt.isDeleted = true;
                }
            });

            const findElt = this.dataListAffections.find((elt) =>
                (elt.codeAffection === data.codeAffection) && data.addElementExtern === undefined);

            if (findElt !== undefined) {
                this.dataListAffections = [...this.dataListAffections.filter((item) => item !== findElt)];
            }

            this.listAffectionTable = [...this.listAffectionTable.filter((item) => item !== data)];

            /**
             * suppression dans des presxcriptions dans la liste de prescriptions retournées
             */
            this.tableListAffectionPrescription.forEach((itemTab) => {
                if ((itemTab && itemTab.listPrescriptionLier) && (itemTab.idAffection === data.idAffection)) {
                    itemTab.listPrescriptionLier.forEach((elt) => {
                        this.dataListPrescription = [
                            ...this.dataListPrescription.filter((itemList) => itemList.idMedicament !== elt.idMedicament)
                        ];
                    });
                }
            });

            /**
             * suppression dans le tableau lié
             */
            this.tableListAffectionPrescription = [
                ...this.tableListAffectionPrescription.filter((item) => item.idAffection !== data.idAffection)
            ];

            this.dataListPrescriptionEvent.emit(this.dataListPrescription);

            this.dataListAffectionsSelectedEvent.emit(this.dataListAffections);
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    resetFormulaire() {
        this.affectionSelectedForm.patchValue({
            codeAffection: null,
        });
    }


    /**
     *
     *
     */


    initFormulairePrescription() {
        this.prescriptionForm = this.metierServicePrescription.initialiserFormulairePrescription(null);
    }

    actionSelectedPrescription(event) {
        this.prescriptionSelected = event;
        let ErrorAlert: any;

        const findElt = this.dataListPrescription.findIndex((elt) => elt.libelle === this.prescriptionSelected.libelle);
        if (findElt >= 0) {
            ErrorAlert = this.alertService.showError('Cet médicament a déjà été ajouté !');
            ErrorAlert.show();

            this.prescriptionForm.reset();
            return;
        }

        this.prescriptionForm.patchValue({
            quantite: 1,
        });
    }

    ajouterPrescription() {
        let medecinName: string;

        if (this.modalOpen === 'cloture_demande') {
            medecinName = this.medecinSelected;

        } else if (this.modalOpen === 'poursuite_demande') {
            if (!this.medecinSelected.nomCompletMedecin) {
                this.alertService.showError(AUCUN_MEDECIN_RECHERCHER).show();
                return;

            } else {
                medecinName = this.medecinSelected.nomCompletMedecin;
            }
        }

        if (!this.prescriptionForm.getRawValue().medicament ||
            !this.prescriptionForm.getRawValue().quantite) {
            this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        // ajout du médecin, quantité, posologie dans le tableau temporaire
        this.prescriptionSelectedTemp = [
            ...this.prescriptionSelectedTemp,
            {
                ...this.prescriptionSelected,
                quantite: this.prescriptionForm.getRawValue().quantite > 0
                    ? this.prescriptionForm.getRawValue().quantite
                    : 1,
                posologie: this.prescriptionForm.getRawValue().posologie,
                medecin: medecinName,
            }
        ];

        if (this.prescriptionSelectedTemp && this.prescriptionSelectedTemp.length > 0) {
            this.prescriptionSelectedTemp.forEach((data, index) => {
                data.id_key = index + 1;
            });
        }

        this.prescriptionForm.reset();

        this.initFormulairePrescription();
    }

    ajouterAffectioPrescriptionLier() {
        if (this.affectionSelected === undefined || this.affectionSelected === null) {
            this.alertService.showError('Veuillez choisir une affection !').show();
            return;
        }
        if (this.prescriptionSelectedTemp.length === 0) {
            this.alertService.showError('Veuillez sélectionner au moins une prescription !').show();
            return;
        }

        this.ajouterAffection();

        // ajout du médecin, quantité, posologie
        this.dataListPrescription = [
            ...this.dataListPrescription,
            ...this.prescriptionSelectedTemp,
        ];

        if (this.dataListPrescription && this.dataListPrescription.length > 0) {
            this.dataListPrescription.forEach((data, index) => {
                data.id_key = index + 1;
            });
        }
        this.dataListPrescriptionEvent.emit(this.dataListPrescription);


        this.prescriptionSelectedTemp.forEach((data) => {
            data.idAffection = this.affectionSelected.idAffection;
        });

        /**
         * Formatage pour affichage dans le tableau (data affection & prescription)
         */
        this.tableListAffectionPrescription = [
            ...this.tableListAffectionPrescription,
            {
                ...this.affectionSelected,
                listPrescriptionLier: this.prescriptionSelectedTemp,
            }
        ];

        this.affectionSelected = null;
        this.prescriptionSelected = null;
        this.prescriptionSelectedTemp = [];
    }

    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est dépliée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = null;
        this.currentRow = data;

        this.tableListPrescriptionDetails = [];

        if (this.currentRow !== null &&
            this.currentRow.listPrescriptionLier !== undefined && this.currentRow.listPrescriptionLier !== null) {
            this.tableListPrescriptionDetails = [...this.currentRow.listPrescriptionLier];
        }
    }

    /**
     * => return true, si l'objet est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }

    /**
     * Effectue une demande de suppression prescription
     * @param dataPrescChild
     */
    demanderSuppressionPrescription(dataPrescChild: any) {
        // console.log('demanderSuppressionPrescription : ', dataPrescChild);

        let shower: any;
        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.dataListPrescription = [...this.dataListPrescription.filter((item) => item !== dataPrescChild)];

            this.tableListAffectionPrescription.forEach((item) => {
                if ((item && item.listPrescriptionLier) && (item.idAffection === dataPrescChild.idAffection)) {
                    item.listPrescriptionLier.forEach((elt) => {
                        if ((elt && elt.idMedicament) && (elt.idMedicament === dataPrescChild.idMedicament)) {

                            item.listPrescriptionLier = [...item.listPrescriptionLier.filter((data) => data !== elt)];

                            this.tableListPrescriptionDetails = item.listPrescriptionLier;
                            return false;
                        }
                    });
                }
            });

            this.dataListPrescriptionEvent.emit(this.dataListPrescription);

            this.dataListAffectionsSelectedEvent.emit(this.dataListAffections);
        };
        shower.reject = () => {
            // this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    /**
     * Ajouter commentaire pour un acte prescription
     * @param data
     */
    ajouterCommentairActePrescrition(data: any) {
        console.log('ajouterCommentairActePrescrition ::');
    }

}

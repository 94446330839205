import {Component, OnInit} from '@angular/core';
import {ClvFileModel, ClvFilesUtils} from 'clv-ts-utils';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';

@Component({
    selector: 'app-file-preview',
    templateUrl: './file-preview.component.html',
    styleUrls: ['./file-preview.component.scss']
})
export class FilePreviewComponent extends DialogComponent implements OnInit {

    csvJson: any;
    image: string;
    xlsxJson: any;

    ngOnInit(): void {
        super.ngOnInit();
        switch (this.defaultObject.extension) {
            case '.csv':
                this.csvJson = ClvFilesUtils.csvToJson((<ClvFileModel>this.defaultObject).content);
                break;
            case '.xlsx':
                this.xlsxJson = ClvFilesUtils.xlsxToJson((<ClvFileModel>this.defaultObject).content);
                break;
            default:
        }
    }
}

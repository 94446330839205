import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {AuthorizationInterceptor} from '@app/core/intercepteurs/authorization-interceptor.service';
import {LoaderInterceptor} from '@app/core/intercepteurs/loader-interceptor.service';
import {IntercepteurSgiRequest} from '@app/core/intercepteurs/intercepteur.sgi-request';

export const authorizationInterceptorProvider = [
  {provide: HTTP_INTERCEPTORS, useClass: AuthorizationInterceptor, multi: true}
];

export const loadInterceptorProvider = [
  {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true}
];

export const sgiRequestInterceptorProvider = [
  {provide: HTTP_INTERCEPTORS, useClass: IntercepteurSgiRequest, multi: true}
];

export * from './authorization-interceptor.service';
export * from './intercepteur.sgi-request';
export * from './loader-interceptor.service';

import {Injectable} from '@angular/core';
import {IRestApi} from '@app/core/interfaces/rest/i-rest-api';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {
  API_POUR_RECUPERER_LISTE_FACTURE_PRISE_EN_CHARGE
} from '@app/core/constantes';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {OperatorEnum} from '@app/core/utilities/operators.utilities';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class InformationFactureRestApiService extends SecurityStateService implements IRestApi {
  @Autowired() restService: SgiRestService;
  @Autowired() datePipe: DatePipe;

  storageUser: IUserConnect = this.getUser();

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  dateSave = {
    dateDebut: this.datePipe.transform(Date.now(), 'dd/MM/yyyy'),
    dateFin: this.datePipe.transform(Date.now(), 'dd/MM/yyyy')
  };


  requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
  }

  requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.putRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
  }

  requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
    let id = null; try { id = data.id ? data.id : null; } catch (e) {}
    return this.restService.getRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur/${id}`);
  }

  requeteSuppression(data: any, args?: any): any {
    return this.restService.deleteRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
  }

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {

    return of({body: {items: null}})
  }

}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {PoursuitePriseChargeMetierService} from '../poursuite-prise-charge.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {
  AUCUN_ACTE_AJOUTER, AUCUN_ACTE_COMPLEMENTAIRE_AJOUTER,
  AUCUN_ASSURE_RECHERCHER,
  AUCUN_MEDECIN_RECHERCHER,
  CODE_OBJET_DEMANDE_PEC,
  ERROR_MESSAGE_ADD_DESCRIPTION_ACTE,
  STORAGE, VERIFICATION_CALCUL_PART_ASSURE
} from '@app/core/constantes';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {objectKeys} from 'codelyzer/util/objectKeys';

@Component({
  selector: 'app-poursuite-prise-charge-modale-feature',
  templateUrl: './poursuite-prise-charge-modale.feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PoursuitePriseChargeModaleFeatureComponent extends DialogComponent {
  @Autowired() convertirFormulaireMetier: PoursuitePriseChargeMetierService;
  @Autowired() initialiserFormulaireMetier: PoursuitePriseChargeMetierService;
  @Autowired() requeteCreationMetier: PoursuitePriseChargeMetierService;
  @Autowired() requeteModificationMetier: PoursuitePriseChargeMetierService;
  @Autowired() metierService: PoursuitePriseChargeMetierService;

  @Autowired() metierServiceDemnadePEC: DemandePriseChargeMetierService;



  /**
   * Fonctionnalité Section POURSUITE DEMANDE DE PRISE EN CHARGE
   */
  assureSelected: any;
  medecinSelected: any;
  listActesSelected: any[] = [];
  listAffectionsSelected: any[] = [];
  documentAjouter: any[] = [];
  descriptionsAll = '';
  @Output() savePoursuiteSuccessEvent = new EventEmitter<any>();

  @Input() tableSelectedElement: any;
  tabActive = 'Informations';
  listActesComp: any[] = [];
  listPrescriptionSelected: any[] = [];

  isCalculerMontantPartAssure = {
    verif: false,
    montantTotal: 0
  };

  /** Fonctionnalité Section DOSSIER MEDICAL */
  dossierMedical: any;


  constructor() {
    super();
  }

  ngOnInit() {
    if (this.modalManager.id === 'poursuite_demande') {
      this.header = 'Poursuite Prise en charge';

    } else if (this.modalManager.id === 'dossier_medical_demande') {
      this.header = `Demande d'accès au dossier médical de ${this.modalManager.datasourceSelected.adherent.nomAssure}`;
      this.dossierMedical = {};
    }

    this.resetDatasComponent();
  }

  handleMedecin(event) {
    // console.log('handleMedecin : ', event);

    this.medecinSelected = event;
  }

  handleListActes(event: any[]) {
    // console.log('handleListActes : ', event);

    this.listActesSelected = event;
  }

  handleIsCalculerMontantPartAssure(event) {
    // console.log('handleIsCalculerMontantPartAssure : ', event);

    this.isCalculerMontantPartAssure = event;
  }

  handleListActesComp(event: any[]) {
    // console.log('handleListActesComp : ', event);

    this.listActesComp = event;
  }

  handleDescriptionsAll(event) {
    // console.log('handleDescriptionsAll : ', event);

    this.descriptionsAll = event;
  }

  handleAffections(event: any[]) {
    // console.log('handleAffections : ', event);

    this.listAffectionsSelected = event;
  }

  handleListPrescription(event: any[]) {
    // console.log('handleListPrescription : ', event);

    this.listPrescriptionSelected = event;
  }

  handleDocumentAjouter(event: any[]) {
    // console.log('handleDocumentAjouter : ', event);

    this.documentAjouter = event;
  }

  /**
   *
   * Fonctionnalité Section DOSSIER MEDICAL
   *
   */
  handleDossierMedical(event) {
    this.dossierMedical = event;
  }

  formatDataDocumentSend(array: any[] = []) {
    const dataFormat: any[] = [];

    array.forEach((item) => {
      dataFormat.push({
        codeObjet: CODE_OBJET_DEMANDE_PEC,
        codeDocument: item.type.codeDocument,
        dataKey: item.type.dataKey,
        dateCreation: item.type.dateCreation,
        dateMaj: item.type.dateMaj,
        etude: item.type.etude,
        idDocument: null,
        idDocumentAfournir: 21,
        idObjet: item.type.idObjet,
        idTenant: item.type.idTenant,
        idTypeDocument: item.type.idTypeDocument,
        infoSearchCodeDocument: {consider: true, isOrderByField: false, isSumField: false},
        infoSearchCodeObjet: {consider: true, isOrderByField: false, isSumField: false},
        infoSearchLibelle: {consider: true, isOrderByField: false, isSumField: false},
        infoSearchLibelleDocument: {consider: true, isOrderByField: false, isSumField: false},
        isDeleted: false,
        libelle: item.type.libelle,
        libelleDocument: item.type.libelleDocument,
        libelleObjet: item.type.libelleObjet,
        obligatoire: null,

        observations: item.titre,
        extension: this.recupererExtension(item.fichier.extension),
        pieceJointe: item.fichier.fullDataUrl,
        requiredTenant: true,
        selected: false,
        tabDisplay: item.fichier.data,
      });
    });

    return dataFormat;
  }

  recupererExtension(item) {
    const value = item.split('.');
    return value[1];
  }

  enregistrerFormulaire() {
    if (this.modalManager.id === 'poursuite_demande') {
      this.creationPoursuiteDemandePEC();
    } else if (this.modalManager.id === 'dossier_medical_demande') {
      this.creationDossierMedicalDemandePEC();
    }
  }


  /**
   *
   * Fonctionnalité Section POURSUITE DEMANDE DE PRISE EN CHARGE
   *
   */
  getTabChange(event) {
    this.tabActive = event.originalEvent.target['innerText'];
  }

  getMedecinObject(nomMedecin) {
    if (this.metierService.medecins.length === 0) {
      let listMedecinsStorage = window.localStorage.getItem(STORAGE.MEDECINS);
      if (!listMedecinsStorage) {
        this.metierServiceDemnadePEC.rechercherMedecin({}).subscribe(res => {
          this.metierService.medecins = res.body.items;
          window.localStorage.setItem(STORAGE.MEDECINS, JSON.stringify(this.metierService.medecins));
          listMedecinsStorage = window.localStorage.getItem(STORAGE.MEDECINS);
        });
      }
      listMedecinsStorage = JSON.parse(listMedecinsStorage);
      this.metierService.medecins = listMedecinsStorage;
    }
    return this.metierService.medecins.find((elt) => elt.nomCompletMedecin === nomMedecin);
  }

  /**
   * Enregistre le formulaire pour la poursuite demande
   */
  creationPoursuiteDemandePEC() {
    let shower: any;
    let message = '';
    let sendData = false;


    /*console.log(this.tableSelectedElement);
    console.log(this.medecinSelected);
    console.log(this.listActesSelected);
    console.log(this.listActesComp);
    console.log(this.isCalculerMontantPartAssure);
    console.log(this.listAffectionsSelected);
    console.log(this.listPrescriptionSelected);
    console.log(this.documentAjouter);

    else if (!this.isCalculerMontantPartAssure.verif) {
      message = VERIFICATION_CALCUL_PART_ASSURE;
    }

    return;*/


    if (!this.tableSelectedElement || objectKeys(this.tableSelectedElement).length === 0) {
      message = AUCUN_ASSURE_RECHERCHER;
    } else if (!this.medecinSelected || objectKeys(this.medecinSelected).length === 0) {
      message = AUCUN_MEDECIN_RECHERCHER;
    } else if (this.listActesSelected.length === 0) {
      message = 'Veuillez effectuer le calcul pour la part de l\assuré !'; // AUCUN_ACTE_AJOUTER
    } else {

      let countCommentaireValid: boolean;
      countCommentaireValid = this.listActesComp.every(
          (elt) => (elt.accordPrealable && elt.descriptions !== '') || (!elt.accordPrealable && elt.descriptions === ''));

      if (countCommentaireValid) {
        message = `Total part assuré : ${this.isCalculerMontantPartAssure.montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
        sendData = true;
      } else if (!countCommentaireValid && this.descriptionsAll === '') {
        message = ERROR_MESSAGE_ADD_DESCRIPTION_ACTE;
      } else {
        message = `Total part assuré : ${this.isCalculerMontantPartAssure.montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
        sendData = true;
      }
    }

    if (sendData) {
      shower = this.alertService.showWarning(message);
      shower.accept = () => {
        this.saveDonneesFormulairePoursuiteDemande();
      };
      shower.reject = () => {
      };
      shower.show();
    } else {
      this.alertService.showError(message).show();
    }
  }

  deletePropertyObject(object) {
    delete object.id_key;
    return object;
  }

  deletePropertyArrayObject(arrayObject: any[]) {
    const arrayItems: any[] = [];
    arrayObject.forEach((item) => {
      delete item.id_key;
      arrayItems.push(item);
    });

    return arrayItems;
  }

  saveDonneesFormulairePoursuiteDemande() {
    const agrs = {
      adherant: this.tableSelectedElement,
      medecin: this.medecinSelected,
      listActes: [...this.listActesSelected, ...this.deletePropertyArrayObject(this.listActesComp)], //
      listAffections: this.deletePropertyArrayObject(this.listAffectionsSelected),
      listPrescriptions: this.deletePropertyArrayObject(this.listPrescriptionSelected),
      listDocuments: this.formatDataDocumentSend(this.documentAjouter),
      descriptionsAll: this.descriptionsAll
    };

    this.metierService.requeteSaveDonneesFormulairePoursuiteDemande({}, agrs).subscribe((reponse) => {
      const response = reponse.body.items[0];
      this.savePoursuiteSuccessEvent.emit(response);
      this.fermerModale();
    });
  }
  /**
   *
   * END :: Fonctionnalité Section POURSUITE DEMANDE DE PRISE EN CHARGE
   *
   */


  /**
   * Enregistre le formulaire pour la demande dossier médical
   */
  creationDossierMedicalDemandePEC() {
    let shower: any;
    let message = '';
    let sendData = false;

    if (!this.dossierMedical || objectKeys(this.dossierMedical).length === 0) {
      message = 'Veuillez renseigner les champs date de début et date de fin !';
    } else if (this.dossierMedical.dateDebut === undefined || this.dossierMedical.dateDebut === null) {
      message = 'Veuillez sélectionner une date de début !';
    } else if (this.dossierMedical.dateFin === undefined || this.dossierMedical.dateFin === null) {
      message = 'Veuillez sélectionner une date de fin !';
    } else {
      message = `Voulez-vous valider cette opération ?`;
      sendData = true;
    }

    if (sendData) {
      shower = this.alertService.showWarning(message);
      shower.accept = () => {
        this.saveDonneesFormulaireDossierMedicalDemandePEC();
      };
      shower.reject = () => {
      };
      shower.show();
    } else {
      this.alertService.showError(message).show();
    }
  }

  saveDonneesFormulaireDossierMedicalDemandePEC() {
    const agrs = {
      dossierMedical: this.dossierMedical,
    };

    this.metierService.requeteSaveDonneesFormulaireDossierMedicalDemande({}, agrs).subscribe((reponse) => {
      this.fermerModale();

      const response = reponse.body.items[0];

      this.savePoursuiteSuccessEvent.emit(null);
    });
  }
  /**
   *
   * END :: Fonctionnalité Section Demande dossier médical
   *
   */

  private resetDatasComponent() {
    this.assureSelected = {};
    this.medecinSelected = {};
    this.listActesSelected = [];
    this.listAffectionsSelected = [];
    this.documentAjouter = [];
    this.descriptionsAll = '';
    this.listActesComp = [];
  }

}

import {Component} from '@angular/core';
import {PageLayoutComponent} from '@shared/behaviors/page-layout/page-layout.component';

@Component({
    selector: 'app-dossier-medical-temporaire',
    templateUrl: './dossier-medical-temporaire.component.html'
})
export class DossierMedicalTemporaireComponent extends PageLayoutComponent {
    constructor() {
        super();
        this.title = 'Dossier médical';
        this.icon = 'book';
        this.hasToolbar = false;
    }
}

import {Component, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {AlertService} from '@app/core/services/alert.service';
import {PoursuitePriseChargeMetierService} from '../poursuite-prise-charge.metier.service';
import {PageLayoutComponent} from '@shared/behaviors/page-layout/page-layout.component';
import {ERROR_MESSAGE_VERIFICATION_RECHERCHE_POURSUITE} from '@app/core/constantes';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-poursuite-pec-form',
  templateUrl: './poursuite-pec-form.component.html',
  styleUrls: ['./poursuite-pec-form.component.css']
})
export class PoursuitePecFormComponent extends PageLayoutComponent implements OnInit {

  @Autowired() formBuilder: FormBuilder;
  @Autowired() metierService: PoursuitePriseChargeMetierService;
  @Autowired() securityService: SecurityStateService;
  @Autowired() alertService: AlertService;
  result$: Observable<any> = new Observable<any>();
  searchForm: FormGroup;

  constructor() {
    super();
  }

  ngOnInit() {
    this.initFormulaire();
  }

  initFormulaire() {
    return this.searchForm = this.metierService.initialiserFormulaire(null);
  }

  rechercheBon() {
    this.searchForm.setValue({Matricule: this.searchForm.getRawValue().Matricule.trim(),
      NumBon: this.searchForm.getRawValue().NumBon.trim()
    });

    if (!this.searchForm.getRawValue().Matricule && !this.searchForm.getRawValue().NumBon) {
      this.alertService.showError(ERROR_MESSAGE_VERIFICATION_RECHERCHE_POURSUITE).show();
    } else {
      this.result$ = this.metierService.recupererListePoursuitePEC({
        matricule: this.searchForm.getRawValue().Matricule,
        numBonPriseEnChargeParent: this.searchForm.getRawValue().NumBon
      });
    }
  }

}

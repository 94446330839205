import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {DossierMedicalTemporaireComponent} from './dossier-medical-temporaire.component';
import {SharedModule} from '@shared/shared.module';
import {AssureListeFeatureModule} from '@app/fonctionnalites/dossier-medical-prestation/assure/liste/assure-liste.feature.module';

const routes: Routes = [
    {path: '', component: DossierMedicalTemporaireComponent, data: {breadcrumb: 'Dossier médical temporaire'}}
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule,
        AssureListeFeatureModule,
    ],
    declarations: [
        DossierMedicalTemporaireComponent
    ]
})
export class DossierMedicalTemporaireModule {
}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable, of} from 'rxjs';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {API_RECUPERER_BORDEREAU} from '@app/core/constantes';
import {DatePipe} from '@angular/common';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

@Injectable({providedIn: 'root'})
export class RechercheFactureAutocompleteFormRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;
  @Autowired() datePipe: DatePipe;

  storageUser: IUserConnect = this.getUser();

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {

    if(args.autocomplete) {
      console.log('args args args ',args)
      if (args.autocomplete.bordereau === '') {
         const body = {items: []};
       }

     const sendData = {...data,
       itemToSearch: {
         numeroFacture: args.autocomplete.facture,
         IdPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
         InfoSearchIdPrestataireMedical: {Consider: true},
         InfoSearchStatut: {Consider: true},
         InfoSearchnumeroFacture: {Consider: true},
         Statut: "Créé",
       },
       TakeAll: false,
     };
     // tslint:disable-next-line:max-line-length
     return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_BORDEREAU}`, sendData, {...this.postArgsMute});
    }
    return of({body: {items: []}});

  }

}

import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface IListesState {
  listMode: 'table'|'grid';
  perPage: number;
}

export function defaultListesState(): IListesState {
  return {
   listMode: 'table',
   perPage: 5
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'listesState'
})
export class ListesStoreService extends Store<IListesState> {

  constructor() {
    super(defaultListesState());
  }
}

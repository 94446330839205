import {Directive, ElementRef, Input, TemplateRef} from "@angular/core";

@Directive({
  selector: '[appDefColumnTable]'
})
export class DefColumnTableDirective {
  @Input('appDefColumnTable') appDefColumnTable: string;
  constructor(public element: ElementRef, public template: TemplateRef<any>) {

  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListDocumentTableListeMetierService} from './list-document-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';

@Component({
  selector: 'app-list-document-table-liste',
  templateUrl: './list-document-table.liste.component.html'
})

export class ListDocumentTableListeComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: ListDocumentTableListeMetierService;
  @Autowired() metierService: ListDocumentTableListeMetierService;

  @Autowired() demandePECMetierService: DemandePriseChargeMetierService;


  @Input() listDocumentAjouter: any;
  @Output() listDocumentAjouterEvent = new EventEmitter<any[]>();


  constructor() {
    super();
    this.tableActions = [
      this.supprimerAction,
    ];
  }

  ngOnInit(): void {
    this.cols = [
      { field: 'type', header: 'Type' },
      { field: 'titre', header: 'Titre' },
    ];
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  /**
   * Effectue une demande de suppression.
   * @param data
   */
  demanderSuppression(data: any) {
    // console.log('demanderSuppression : ', data);

    let shower: any;
    shower = this.alertService.showWarning(this.messageDemandeSuppression);
    shower.accept = () => {
      const index = this.listDocumentAjouter.findIndex((elt) => elt.id_key === data.id_key);
      this.listDocumentAjouter.splice(index, 1);
      this.listDocumentAjouterEvent.emit(this.listDocumentAjouter);
    };
    shower.reject = () => {
      this.rejeterSuppressionElement(data);
    };
    shower.show();
  }

}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

/**
 * Intercepteur Ajoutant aux requêtes le token de l'utilisateur connecté
 */
@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (window.sessionStorage.getItem('token') && !req.headers.has('Authorization')) {
      const reqClone = req.clone({headers: req.headers.set('Authorization', `Bearer ${window.sessionStorage.getItem('token')}`)});
      return next.handle(reqClone);
    } else {
      return next.handle(req);
    }
  }

}

import {Component, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-ngxcharts-linechart-feature',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './linechart.feature.component.html',
  styleUrls: ['./linechart.feature.component.scss']
})
export class LinechartFeatureComponent {
  // view: any[] = [1000, 500];

  // options
  data = [
    {
      'name': 'Iran',
      'series': [
        {
          'value': 3838,
          'name': '2016-09-17T17:42:42.932Z'
        },
        {
          'value': 2694,
          'name': '2016-09-21T16:55:31.011Z'
        },
        {
          'value': 4826,
          'name': '2016-09-21T19:17:31.071Z'
        },
        {
          'value': 4233,
          'name': '2016-09-16T18:05:43.642Z'
        },
        {
          'value': 3480,
          'name': '2016-09-17T23:25:24.880Z'
        }
      ]
    },
    {
      'name': 'South Africa',
      'series': [
        {
          'value': 6214,
          'name': '2016-09-17T17:42:42.932Z'
        },
        {
          'value': 6415,
          'name': '2016-09-21T16:55:31.011Z'
        },
        {
          'value': 4009,
          'name': '2016-09-21T19:17:31.071Z'
        },
        {
          'value': 3063,
          'name': '2016-09-16T18:05:43.642Z'
        },
        {
          'value': 5348,
          'name': '2016-09-17T23:25:24.880Z'
        }
      ]
    },
    {
      'name': 'Bolivia',
      'series': [
        {
          'value': 5791,
          'name': '2016-09-17T17:42:42.932Z'
        },
        {
          'value': 4368,
          'name': '2016-09-21T16:55:31.011Z'
        },
        {
          'value': 5708,
          'name': '2016-09-21T19:17:31.071Z'
        },
        {
          'value': 6669,
          'name': '2016-09-16T18:05:43.642Z'
        },
        {
          'value': 3450,
          'name': '2016-09-17T23:25:24.880Z'
        }
      ]
    },
    {
      'name': 'Sierra Leone',
      'series': [
        {
          'value': 5920,
          'name': '2016-09-17T17:42:42.932Z'
        },
        {
          'value': 4362,
          'name': '2016-09-21T16:55:31.011Z'
        },
        {
          'value': 5262,
          'name': '2016-09-21T19:17:31.071Z'
        },
        {
          'value': 5927,
          'name': '2016-09-16T18:05:43.642Z'
        },
        {
          'value': 2918,
          'name': '2016-09-17T23:25:24.880Z'
        }
      ]
    },
    {
      'name': 'Honduras',
      'series': [
        {
          'value': 6432,
          'name': '2016-09-17T17:42:42.932Z'
        },
        {
          'value': 3741,
          'name': '2016-09-21T16:55:31.011Z'
        },
        {
          'value': 4939,
          'name': '2016-09-21T19:17:31.071Z'
        },
        {
          'value': 6545,
          'name': '2016-09-16T18:05:43.642Z'
        },
        {
          'value': 3443,
          'name': '2016-09-17T23:25:24.880Z'
        }
      ]
    }
  ];
  legend: true;
  showLabels: true;
  animations: true;
  xAxis: true;
  yAxis: true;
  showYAxisLabel: true;
  showXAxisLabel: true;
  xAxisLabel: 'Year';
  yAxisLabel: 'Population';
  timeline: true;

  colorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };

  constructor() {
  }

  onSelect(data): void {
    console.log('Item clicked', JSON.parse(JSON.stringify(data)));
  }

  onActivate(data): void {
    console.log('Activate', JSON.parse(JSON.stringify(data)));
  }

  onDeactivate(data): void {
    console.log('Deactivate', JSON.parse(JSON.stringify(data)));
  }


}

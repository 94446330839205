import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import { InformationFactureComponent } from './information-facture.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
    ],
  exports: [InformationFactureComponent],
  declarations: [InformationFactureComponent],
  providers: [],
})
export class InformationFactureModule {
}

import {Injectable} from '@angular/core';
import {IRestApi} from '@app/core/interfaces/rest/i-rest-api';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {
    API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE, API_ACTE_POURSUITE_DEMANDE_PRISE_EN_CHARGE,
    API_CAN_CLOSE_POURSUITE_PEC, API_CLOTURE_POURSUITE_PEC, API_ENREGISTRER_DEMANDE_DOSSIER_MEDICAL,
    API_LISTE_POURSUITE_PRISE_EN_CHARGE,
    API_RECUPERER_PRIX_ACTES_POURSUITE, CODE_ENTITE_DEMANDE_DOSSIER_MEDICAL
} from '@app/core/constantes';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {DatePipe} from '@angular/common';


@Injectable({providedIn: 'root'})
export class PoursuitePriseChargeRestApiService extends SecurityStateService implements IRestApi {
    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;
    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};


    requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = {items: []};
        return of({body: body});
    }
    requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = {items: []};
        return of({body: body});
    }
    requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = {items: []};
        return of({body: body});
    }
    requeteSuppression(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = {items: []};
        return of({body: body});
    }


    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {itemToSearch: data};

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_LISTE_POURSUITE_PRISE_EN_CHARGE}`, sendData)
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (!(response.body.items.length > 0)) {
                        return response;
                    }
                    return response;
                })
            );
    }

    requeteRecuperationActesPoursuite(data: any, args?: any): Observable<any> | Promise<any> | any {
        const { adherant } = args;

        // update du paramètre
        data.SortOrder = null;

        const sendData = {...data,
            itemToSearch: {
                ...data.itemToSearch,
                idDemandePriseEnCharge: adherant.idDemandePriseEnCharge,

                InfoSearchAccordPrealable: {Consider: false},
                InfoSearchCodeActeMedical: {Consider: false},
                InfoSearchCoeficient: {Consider: false},
                InfoSearchCreatedBy: {Consider: false},
                InfoSearchDataKey: {Consider: false},
                InfoSearchDateCreation: {Consider: false},
                InfoSearchDateDemande: {Consider: false},
                InfoSearchDateMaj: {Consider: false},
                InfoSearchDelaiCarence: {Consider: false},
                InfoSearchEstGratuit: {Consider: false},
                InfoSearchIdActe: {Consider: false},
                InfoSearchIdDemandePriseEnCharge: {Consider: false},
                InfoSearchIdDemandePriseEnChargeLigne: {Consider: false},
                InfoSearchIdPrestataireMedical: {Consider: false},
                InfoSearchIdSouscriptionLigne: {Consider: false},
                InfoSearchIdSpecialisation: {Consider: false},
                InfoSearchIdTenant: {Consider: false},
                InfoSearchIdTermeConvention: {Consider: false},
                InfoSearchIsDeleted: {Consider: false},
                InfoSearchLibelle: {Consider: false},
                InfoSearchModifiedBy: {Consider: false},
                InfoSearchMontantHT: {Consider: false},
                InfoSearchMotifRejet: {Consider: false},
                InfoSearchNbreActeMaxParFamille: {Consider: false},
                InfoSearchNbreActeMaxParInvidu: {Consider: false},
                InfoSearchNbreActePEC: {Consider: false},
                InfoSearchPrixActe: {Consider: false},
                InfoSearchTva: {Consider: false},
                InfoSearchValiditeControle: {Consider: false},
                InfoSearchidDemandePriseEnCharge: {Consider: true},
            },
            Size: 20,
            TakeAll: false,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_ACTE_POURSUITE_DEMANDE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    response.body.items.forEach(v => {
                        v.idSouscriptionLigne = adherant.idSouscriptionLigne;
                    });
                    return response;
                })
            );
    }

    requeteActionCanClosePoursuite(data: any, args?: any): Observable<any> | Promise<any> | any {
        const { actePoursuite } = args;

        const sendData = {
            ItemsToSave: actePoursuite,
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_CAN_CLOSE_POURSUITE_PEC}`, sendData, {...this.postArgsMute});
    }

    requeteRecuperationPrixActesPoursuite(data: any, args?: any): Observable<any> | Promise<any> | any {
        const { actePoursuite } = args;

        const sendData = {
            ItemsToSave: actePoursuite,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PRIX_ACTES_POURSUITE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    response.body.items.forEach(v => {
                        if (v.estConventionne === true) {
                            v.estRealise = true;
                        }
                    });
                    return response;
                })
            );
    }

    requeteSaveDonneesFormulairePoursuiteDemande(data: any, args?: any): Observable<any> | Promise<any> | any {
        const { adherant, medecin, listActes, listAffections, listPrescriptions, listDocuments, descriptionsAll } = args;

        const sendData = {
            bloqued: true,
            ItemsToSave: [
                {
                    ...adherant,
                    accordPrealableDette: false,
                    descriptions: descriptionsAll,
                    estPoursuite: true,
                    idMedecin: medecin.idMedecin,
                    idPoolMedicalMedecin: medecin.idPoolMedical,
                    // idPrestataireCloture: adherant.idPrestataireMedicalAncien,
                    isDetailsVisible: false,
                    listAffection: listAffections,
                    listDemandePriseEnChargeLigne: listActes,
                    listDocumentExamens: listDocuments,
                    listPrescriptions: listPrescriptions,
                    miseEnHospitalisation: false,
                    selected: true,

                    idPrestataireCloture: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                    idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                    idSouscriptionLigne: adherant.idSouscriptionLigne,
                    idTermeConvention: adherant.idTermeConvention,
                    matricule: adherant.matricule,
                },
            ],
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_CLOTURE_POURSUITE_PEC}`, sendData);
    }

    requeteSaveDonneesFormulaireDossierMedicalDemande(data: any, args?: any): Observable<any> | Promise<any> | any {
        const { dossierMedical } = args;

        const sendData = {
            bloqued: true,
            ItemsToSave: [
                {
                    ...dossierMedical,
                    entite: CODE_ENTITE_DEMANDE_DOSSIER_MEDICAL,
                    idEntite: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                    isDetailsVisible: false,
                },
            ],
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_ENREGISTRER_DEMANDE_DOSSIER_MEDICAL}`, sendData);
    }

}

import {AfterViewInit, Component} from '@angular/core';
import {AppSettings} from '../../app.settings';
import {Autowired} from '@angular-ru/autowired';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements AfterViewInit {
  @Autowired() appSettings: AppSettings;

  calendarEvents = [
    {
      id: 'a',
      title: 'Informations',
      start: '2020-02-10',
      color: 'green'
    },
    {
      id: 'b',
      title: 'Calcul',
      start: '2020-02-11',
      color: 'brown'
    },
    {
      id: 'c',
      title: 'Paiement',
      start: '2020-02-13',
      color: 'blue'
    },
    {
      id: 'd',
      title: 'Arrêté',
      start: '2020-02-07',
      color: '#26A5A5'
    }
  ];
  calendarOptions = {
    defaultDate: Date.now(),
    header: {
      center: 'Date de gestion',
    },
    editable: true
  };

  constructor() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => { this.appSettings.settings.loadingSpinner = false; }, 300);
  }

}

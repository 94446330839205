import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {UtilisateurProfilRestApiService} from './utilisateur-profil.rest-api.service';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {UtilisateurAuthentifieRestApiService} from '@app/fonctionnalites/authentification/utilisateur-authentifie/utilisateur-authentifie.rest-api.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {Observable} from 'rxjs';
import {AlertService} from '@app/core/services/alert.service';

@Injectable({providedIn: 'root'})
export class UtilisateurProfilMetierService implements IConvertirFormulaireMetier, IInitialiserFormulaireMetier {
    // IModificationFullFormulaireMetier
    // IRecuperationFullFormDonneesMetier

    @Autowired() formBuilder: FormBuilder;
    @Autowired() restApi: UtilisateurProfilRestApiService;
    @Autowired() userAuthRestApi: UtilisateurAuthentifieRestApiService;
    @Autowired() securityStore: SecurityStateService;

    @Autowired() alertService: AlertService;

    storageUser: IUserConnect = this.securityStore.getUser();

    // userLogoUrl$: Observable<string> = new Observable<any>();

    convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
        const formdata: T | any = new FormData();
        if (args.imageModel && args.imageModel.file) {
            formdata.append('logo', args.imageModel.file);
        } else {
            formdata.append('logo', null);
        }
        const form = {
            /** Update infos User */
            photo: formGroup.value.photo,
            nom: formGroup.value.nom,
            prenoms: formGroup.value.prenoms,
            login: formGroup.value.login,
            email: formGroup.value.email,

            /** Reset Password */
            updatePassword: formGroup.value.updatePassword,
            oldPassword: formGroup.value.oldPassword,
            newPassword: formGroup.value.newPassword,
            confirmNewPassword: formGroup.value.confirmNewPassword,
        };
        formdata.append('profile', new Blob([JSON.stringify(form)], {type: 'application/octet-binary'}));
        return formdata;
    }

    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
        // this.userLogoUrl$ = of(this.storageUser.personne.logoUrl);

        let formGroup: FormGroup;
        const initObject: T | any = {
            /** Update infos User */
            photo: this.storageUser.personne.logoUrl,
            nom: this.storageUser.personne.nom,
            prenoms: this.storageUser.personne.prenom,
            login: this.storageUser.personne.raisonSocial,
            email: this.storageUser.personne.email,

            /** Reset Password */
            updatePassword: false,
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
        };
        formGroup = this.formBuilder.group({
            photo: initObject.photo,
            nom: initObject.nom,
            prenoms: initObject.prenoms,
            login: [{value: initObject.login, disabled: true}],
            email: initObject.email,

            updatePassword: initObject.updatePassword,
            oldPassword: [initObject.oldPassword, Validators.compose([Validators.minLength(8)])],
            newPassword: [initObject.newPassword, Validators.compose([Validators.minLength(8)])],
            confirmNewPassword: [initObject.confirmNewPassword, Validators.compose([Validators.minLength(8)])],
        });
        return formGroup;
    }


    modificationFullFormulaire(data: any, args?: any): Observable<any> | Promise<any> | any {
        this.restApi.modificationFullFormulaire(data, args).subscribe((response) => {

            // console.log('response : ', response);

            const userConnecte: IUserConnect = this.securityStore.getUser();

            userConnecte.personne.nom = response.body.items[0].nom;
            userConnecte.personne.prenom = response.body.items[0].prenoms;
            userConnecte.personne.email = response.body.items[0].email;
            userConnecte.personne.logoUrl = response.body.items[0].photo;

            // console.log('userConnecte ** : ', userConnecte);

            /**
             * Sauvegarde des données modifiées
             */
            this.securityStore.saveUser(userConnecte);

            /** Reset du formulaire */
            this.initialiserFormulaire({});

            // this.userAuthRestApi.requeteRecuperationListeDonnees(null, args).subscribe(reponseUserConnecte => {});
        });
    }


    /**
     * Système de récupération au chargement de la page
     */
    /*requeteRecuperationFullFormDonnee<E, S>(args?: any): Observable<any | S> | Promise<any | S> | any {
      return this.restApi.requeteRecuperationFullFormDonnee(null, args);
    }*/
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {ListAffectionTableListeRestApiService} from './list-affection-table.liste.rest-api.service';

@Injectable({providedIn: 'root'})
export class ListAffectionTableListeMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: ListAffectionTableListeRestApiService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrescriptionTableMetierService} from './prescription-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {AUCUN_MEDECIN_RECHERCHER, FORMULAIRE_INVALIDE_MESSAGE} from '@app/core/constantes';
import {FormGroup} from '@angular/forms';
import {TableAction} from '@shared/components/tables/atlantis-table/atlantis-table.models';

@Component({
    selector: 'app-prescription-table',
    templateUrl: './prescription-table.component.html'
})

export class PrescriptionTableComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: PrescriptionTableMetierService;
    @Autowired() metierService: PrescriptionTableMetierService;

    @Input() modalOpen: any;
    @Input() adherant: any;
    @Input() medecinSelected: any;
    @Input() dataListPrescription: any[] = [];
    @Output() dataListPrescriptionEvent = new EventEmitter<any[]>();

    prescriptionForm: FormGroup;

    prescriptionSelected: any;

    /**
     * Bouton pour ajouter un commentaire dans le cas d'un acte soumis a accord préalable
     */
    ajouterCommentaireAction: TableAction = {
        // tslint:disable-next-line:max-line-length
        type: 'button',
        title: 'Ajouter commentaire',
        flat: true,
        icon: 'assignment',
        flatClass: 'warning-color',
        actionPerformance: (data) => {
            this.ajouterCommentairActe(data);
        },
        canShow: (data) => !!data.accordPrealable
    };

    constructor() {
        super();
        this.tableActions = [
            this.supprimerAction,
            this.ajouterCommentaireAction
        ];
    }

    ngOnInit(): void {
        this.cols = [
            {field: 'libelle', header: 'Médicament'},
            {field: 'quantite', header: 'Quantité'},
            {field: 'posologie', header: 'Posologie'},
            {field: 'medecin', header: 'Médecin'},
        ];

        this.initFormulaire();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args, adherant: this.adherant};
        this.args = {...arg};
    }

    initFormulaire() {
        this.prescriptionForm = this.metierService.initialiserFormulairePrescription(null);
    }

    actionSelectedPrescription(event) {
        this.prescriptionSelected = event;
        let ErrorAlert: any;

        const findElt = this.dataListPrescription.findIndex((elt) => elt.libelle === this.prescriptionSelected.libelle);
        if (findElt >= 0) {
            ErrorAlert = this.alertService.showError('Cet médicament a déjà été ajouté !');
            ErrorAlert.show();

            this.prescriptionForm.reset();
            return;
        }

        this.prescriptionForm.patchValue({
            quantite: 1,
        });
    }

    ajouterPrescription() {
        let ErrorAlert: any;
        let medecinName: string;

        if (this.modalOpen === 'cloture_demande') {
            medecinName = this.medecinSelected;

        } else if (this.modalOpen === 'poursuite_demande') {
            if (!this.medecinSelected.nomCompletMedecin) {
                ErrorAlert = this.alertService.showError(AUCUN_MEDECIN_RECHERCHER);
                ErrorAlert.show();
                return;

            } else {
                medecinName = this.medecinSelected.nomCompletMedecin;
            }
        }

        if (!this.prescriptionForm.getRawValue().medicament ||
            !this.prescriptionForm.getRawValue().quantite) {
            ErrorAlert = this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE);
            ErrorAlert.show();
            return;
        }

        // ajout du médecin, quantité, posologie
        this.dataListPrescription = [
            ...this.dataListPrescription,
            {
                ...this.prescriptionSelected,
                quantite: this.prescriptionForm.getRawValue().quantite > 0
                    ? this.prescriptionForm.getRawValue().quantite
                    : 1,
                posologie: this.prescriptionForm.getRawValue().posologie,
                medecin: medecinName,
            }
        ];

        if (this.dataListPrescription && this.dataListPrescription.length > 0) {
            this.dataListPrescription.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }

        this.prescriptionForm.reset();

        this.dataListPrescriptionEvent.emit(this.dataListPrescription);

        this.initFormulaire();
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            const index = this.dataListPrescription.findIndex((elt) => elt.id_key === data.id_key);
            this.dataListPrescription.splice(index, 1);
            this.dataListPrescriptionEvent.emit(this.dataListPrescription);
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    /**
     * Ajouter commentaire pour un acte
     * @param data
     */
    ajouterCommentairActe(data: any) {
        console.log('ajouterCommentairActe ::');
    }

}

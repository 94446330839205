import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {MedecinFormModule} from "@app/fonctionnalites/demande-pec/demande-prise-charge/form/medecin/form/medecin.form.module";
import { AjoutPrescriptionModaleComponent } from './ajout-prescription.modale.component';
import {PrescriptionAutocompleteFormModule} from "@app/fonctionnalites/demande-pec/composants/prescription-table/prescription-autocomplete/form/prescription-autocomplete.form.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MedecinFormModule,
        PrescriptionAutocompleteFormModule,
    ],
  exports: [AjoutPrescriptionModaleComponent],
  declarations: [AjoutPrescriptionModaleComponent],
  providers: [],
})
export class AjoutPrescriptionModaleModule {
}

import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-update-button',
  template: `
      <ng-container *ngIf="isFlat">
        <button mat-icon-button [matTooltip]="title" [ngClass]="'info-color'">
          <mat-icon>{{icon}}</mat-icon>
        </button>
      </ng-container>
      <ng-container *ngIf="!isFlat">
        <button mat-raised-button [ngClass]="'bg-info'">
          <mat-icon>{{icon}}</mat-icon>&nbsp;{{title}}
        </button>
      </ng-container>
  `
})
export class UpdateButtonComponent {
  @Input('isFlat') isFlat = false;
  @Input('icon') icon = 'create';
  @Input('title') title = 'Modifier';
}

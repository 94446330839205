import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {NavigationExtras, Router} from '@angular/router';

/**
 * Utilitaire de routage
 */
@Injectable({providedIn: 'root'})
export class RoutingService {
  @Autowired() router: Router;

  /**
   * Aller à une url
   *
   * @param commands
   * @param extra
   */
  gotoUrl(commands: any[], extra?: NavigationExtras) {
    this.router.navigate(commands, extra);
  }
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {FactureListeFeatureComponent} from './facture-liste.feature.component';
import {SelectGarantModule} from "@app/fonctionnalites/factures/select-garant/select-garant.module";
import {ModaleLierBordereauFeatureModule} from "@app/fonctionnalites/factures/prise-en-charge/modale-lier-bordereau/modale-lier-bordereau.feature.module";
import {ModaleRessaisirFactureFeatureModule} from "@app/fonctionnalites/factures/prise-en-charge/modale-ressaisir-facture-liste/modale-ressaisir-facture.feature.module";
import {ModaleModifierFactureActionModule} from "@app/fonctionnalites/factures/prise-en-charge/modale-modifier-facture-action-boutton/modale-modifier-facture-action.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        SelectGarantModule,
        ModaleLierBordereauFeatureModule,
        ModaleRessaisirFactureFeatureModule,
        ModaleModifierFactureActionModule,
    ],
  exports: [FactureListeFeatureComponent],
  declarations: [FactureListeFeatureComponent],
  providers: [],
})
export class FactureListeFeatureModule {
}

import {ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {ConnexionMetierService} from '@app/fonctionnalites/authentification/connexion/connexion.metier.service';

@Component({
    selector: 'app-connexion-layout-modale-feature',
    templateUrl: './connexion-layout-modale.feature.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConnexionLayoutModaleFeatureComponent extends DialogComponent implements OnInit {
    @Autowired() connexionMetierService: ConnexionMetierService;

    // @Autowired() securityState: SecurityStateService;

    /**
     *
     */
    newPassword: string;
    confirmNewPassword: string;
    resetPasswordformGroupValid: boolean;
    @Output() saveResetPasswordSuccessEvent = new EventEmitter<any>();


    constructor() {
        super();
        // this.listGarantUser = this.securityState.getListGarant();
    }

    ngOnInit() {
        // console.log('modalManager : ', this.modalManager);

        if (this.modalManager.id === 'reinitialiser-user-password') {
            this.header = 'Réinitialiser votre mot de passe';
        }
    }

    handleNewPassword({event, formGroupValid}) {
        this.newPassword = event;
        this.resetPasswordformGroupValid = formGroupValid;
    }

    handleCofirmNewPassword({event, formGroupValid}) {
        this.confirmNewPassword = event;
        this.resetPasswordformGroupValid = formGroupValid;
    }

    private resetDatasComponent() {
        this.newPassword = '';
        this.confirmNewPassword = '';
    }


    enregistrerFormulaire() {
        if (this.modalManager.id === 'reinitialiser-user-password') {
            this.reinitialiserUserPassword();
        }
    }

    /** Enregistre le formulaire pour la reinitialisation du password */
    reinitialiserUserPassword() {
        if (this.resetPasswordformGroupValid) {
            if (this.newPassword === '' || this.confirmNewPassword === '') {
                this.alertService.showError('Veuillez remplire tout les champs pour les modification du mot de passe !').show();
                return;

            } else {
                if (this.newPassword !== this.confirmNewPassword) {
                    this.alertService.showError('Votre nouveau mot de passe est different de la confirmation de mot de passe !').show();
                    return;
                }
            }
        } else {
            this.alertService.showError(this.constantes.FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        this.saveFormulaireResetPassword();
    }

    saveFormulaireResetPassword() {
        const args = {
            user: this.modalManager.datasourceSelected,
            newPassword: this.newPassword,
        };

        this.connexionMetierService.requeteResetPassword({}, args).subscribe((reponse) => {
            this.saveResetPasswordSuccessEvent.emit({
                event: reponse.body.items[0],
                args: null,
            });
        });
    }

    /**
     *
     * END :: Fonctionnalité Section RESET PASSWORD
     *
     */

}

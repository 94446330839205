import {Pipe, PipeTransform} from '@angular/core';
import {CommonUtilities, ThousandSeparatorArg} from '@app/core/utilities/common.utilities';

@Pipe({
  name: 'separatorTousand'
})
export class SeparatorThousandPipe implements PipeTransform {
  transform(value: any, args: ThousandSeparatorArg = {decimal: true, pres: 2, arround: false}): any {
    return CommonUtilities.thousandSeparator(value, args);
  }
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {MaladieChroniqueTableRestApiService} from './maladie-chronique-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';


@Injectable({providedIn: 'root'})
export class MaladieChroniqueTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: MaladieChroniqueTableRestApiService;
  @Autowired() formBuilder: FormBuilder;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      inputSearch: null,
      selectTypeSearch: !!datas && !!datas.selectTypeSearch ? datas.selectTypeSearch : null,
    };

    return this.formBuilder.group({
      inputSearch: data.inputSearch,
      selectTypeSearch: data.selectTypeSearch,
    });
  }

}

import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {ConnexionRestApiService} from '../../../fonctionnalites/authentification/connexion/connexion.rest-api.service';
import {Autowired} from '@angular-ru/autowired';
import {SecurityStateService} from '../../../core/states/security/security.state.service';
import {AlertService} from '../../../core/services/alert.service';

@Component({
    selector: 'app-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
    @Autowired() securityStore: SecurityStateService;
    @Autowired() alertService: AlertService;

    constructor(public router: Router,
                public connexionRestApiService: ConnexionRestApiService) {
    }

    ngOnInit() {
    }

    logout() {
        const shower = this.alertService.showWarning('Voulez-vous vraimant vous déconnecter ?', 'Déconnexion');
        shower.accept = () => {
            this.connexionRestApiService.deconnexion().subscribe(() => {
                this.securityStore.saveToken(null);
                this.securityStore.saveUser(null);
                this.router.navigate(['/login']);
            });
        };
        shower.show();
    }

}

import {NgModule} from '@angular/core';

import {SoinsHospitalisationTableListeComponent} from './soins-hospitalisation-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [SoinsHospitalisationTableListeComponent],
  declarations: [SoinsHospitalisationTableListeComponent],
  providers: [],
})
export class SoinsHospitalisationTableListeModule {
}

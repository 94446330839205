import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormTextareaComponent} from '@shared/components/inputs/form-textarea/form-textarea.component';
import {ListFormErrorComponentModule} from '@shared/components/error/list-form-error/list-form-error.component.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {InputTextareaModule} from 'primeng/inputtextarea';

@NgModule({
    imports: [
        CommonModule,
        ListFormErrorComponentModule,
        FormsModule,
        ReactiveFormsModule,
        InfoBullWidgetComponentModule,
        InputTextareaModule,
    ],
    declarations: [
        FormTextareaComponent,
    ],
    exports: [
        FormTextareaComponent,
        ListFormErrorComponentModule,
        FormsModule,
        ReactiveFormsModule,
        InfoBullWidgetComponentModule,
        InputTextareaModule,
    ]
})
export class FormTextareaComponentModule {
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {ClvFileModel, ClvFilesUtils, ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {Autowired} from '@angular-ru/autowired';
import {AlertService} from '@app/core/services/alert.service';
import {FilesUtilities} from '@app/core/utilities/files.utilities';

@Component({
    selector: 'app-drop-file',
    templateUrl: './drop-file.component.html',
    styleUrls: ['./drop-file.component.scss']
})
export class DropFileComponent {
    ngxFiles: NgxFileDropEntry[] = [];
    files: File[] = [];

    /**
     * Objet de gestion du fichier.
     *
     * Passez cet objet au composant, et celui-ci se chargera de renseigner les propriétés utiles dont vous aurez besoin.
     */
    @Input('clvFiles') clvFiles: ClvFileModel[] = [];

    /**
     * Taille maximale du fichier en bytes
     */
    @Input('maxSize') maxSize: number = 75 * 1000000; // 75000000 en Byte ; 75 * 1024 * 1024 = 78643200 en Octé

    /**
     * Liste des extentions acceptées.
     *
     * Les extentions doivvent être séparées par des virgules. (Ex: '.docx, .xlsx')
     */
    @Input('accept') accept = '';
    @Output() ngxFilesChange = new EventEmitter<NgxFileDropEntry[]>(false);
    @Output() filesChange = new EventEmitter<File[]>(true);
    @Output() clvFilesChange = new EventEmitter<ClvFileModel[]>(true);
    @Input('multiple') multiple: boolean;
    @Input('description') description = 'Déposez votre fichier ici';
    @Input('descriptionMultiple') descriptionMultiple = 'Déposez vos fichiers ici';
    @Input('buttonText') buttonText = 'Sélectionner Fichier(s)';
    @Input('hasDownloadButton') hasDownloadButton: boolean;
    @Input('hasPreviewButton') hasPreviewButton: boolean;
    @Autowired() alerteService: AlertService;

  /**
   * Recupère les informations des fichiers sélectionnés
   *
   * @param files
   */
  public dropped(files: NgxFileDropEntry[]) { // gestion du glissé déposé
        this.ngxFiles = files;
        this.ngxFilesChange.emit(files);
        for (const droppedFile of files) {                                              // pour chaque fichier déposé

            if (droppedFile.fileEntry.isFile) {                                         // c'est un fichier
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {                                // on recupere le fichier source pour traitement
                    this.files.push(file);
                    ClvFilesUtils.serializeFile(file).subscribe(resp => {
                        if (FilesUtilities.fileMaxSizeExceded([...this.clvFiles, resp], this.maxSize)) {
                            this.alerteService.showError(`La taille maximale du fichier ne doit pas excéder ${this.maxSize}bytes!<br> Votre fichier fait ${file.size}bytes`).show();
                            return;
                        }

                        if (!FilesUtilities.fileAccepted(resp, this.accept.toLowerCase())) {
                            this.alerteService.showError(`L'extention du fichier chargé est inattendue!`).show();
                            return;
                        }

                        this.reinitClvFileIfNotMultiple();
                        this.clvFiles.push(resp);                                   // on insère le fichier a la liste des fichiers
                        this.clvFilesChange.emit(this.clvFiles);
                    });

                    /*// Here you can access the real file
                    // console.log(droppedFile.relativePath, file);*/
                });
            } else {
                /*// It was a directory (empty directories are added, otherwise only files)
                // const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
                // console.log(droppedFile.relativePath, fileEntry);*/
            }
        }
        this.filesChange.emit(this.files);
    }

    public fileOver(event) {
        // console.log(event);
    }

    public fileLeave(event) {
        // console.log(event);
    }

    private reinitClvFileIfNotMultiple() {
        if (!this.multiple) {
            this.clvFiles = [];
        }
    }

    private reinitFileIfNotMultiple() {
        if (!this.multiple) {
            this.files = [];
        }
    }

    handleFileChange(clvFiles: ClvFileModel[]) {
        const fileCoppy: File[] = ClvTsUtilsCommonsJs.copy(this.files);
        this.files = [];
        clvFiles.forEach(clvFile => {
            const ofs = ClvTsUtilsCommonsJs.getObjectOffsetInArrayByField(fileCoppy, clvFile, 'name');
            if (ofs !== -1) {
                this.reinitFileIfNotMultiple();
                this.files.push(fileCoppy[ofs]);
            }
        });
        this.clvFilesChange.emit(clvFiles);
        this.filesChange.emit(this.files);
    }
}

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Autowired} from '@angular-ru/autowired';
import {LoaderStateService} from '@app/core/states/loader/loader.state.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  @Autowired() loaderStore: LoaderStateService;

  compteurOut: any;

  decrementer(condition) {
    if (condition) {
      this.loaderStore.decrement();
      this.demarrerCompteur();
    }
  }

  incrementer(condition) {
    if (condition) {
      this.loaderStore.increment();
      this.demarrerCompteur();
    }
  }

  demarrerCompteur() {
    if (this.compteurOut) {
      clearTimeout(this.compteurOut);
    }
    if (!(this.loaderStore.getState().count > 0)) {
      return;
    }
    this.compteurOut = setTimeout(() => {
      this.loaderStore.reset();
      clearTimeout(this.compteurOut);
    }, 60000);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const condition = req.method === 'post' || req.method === 'POST' ||
        req.method === 'get' || req.method === 'GET' ||
        req.method === 'put' || req.method === 'PUT' ||
        req.method === 'delete' || req.method === 'DELETE';
    this.incrementer(condition);
    return next.handle(req).pipe(tap(() => {
    }, () => {
      this.decrementer(condition);
    }, () => {
      this.decrementer(condition);
    }));
  }

}

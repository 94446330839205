import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import { EvenementModaleModule } from '../../evenement/modale/evenement.modale.module';
import { SelectGarantComponent } from './select-garant.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    EvenementModaleModule
  ],
  declarations: [SelectGarantComponent],
  exports: [SelectGarantComponent]
})
export class SelectGarantModule {
}

import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {AlertService} from '@app/core/services/alert.service';
import {STORAGE} from '@app/core/constantes';
import {Observable, of} from 'rxjs';
import {DemandeHospitalisationTableMetierService} from '@app/fonctionnalites/demande-pec/composants/demande-hospitalisation-table/demande-hospitalisation-table.metier.service';

@Component({
    selector: 'app-demande-hospitalisation-table',
    templateUrl: './demande-hospitalisation-table.component.html',
    styleUrls: ['./demande-hospitalisation-table.component.css']
})
export class DemandeHospitalisationTableComponent extends SgiListLayoutComponent implements AfterViewChecked {
    @Autowired() formBuilder: FormBuilder;
    @Autowired() securityService: SecurityStateService;
    @Autowired() alertService: AlertService;
    @Autowired() metierService: DemandeHospitalisationTableMetierService;

    @Autowired() metierServiceDemandePEC: DemandePriseChargeMetierService;

    userData: IUserConnect;

    @Input() refresh: boolean;
    @Input() assureSelected: any;
    @Input() medecinSelected: any;

    dataListActesSelected: any[] = [];
    @Output() dataListActesSelectedEvent = new EventEmitter<any[]>();

    dataListAffectionsSelected: any[] = [];
    @Output() dataListAffectionsSelectedEvent = new EventEmitter<any[]>();

    descriptionsAll: any;
    @Output() descriptionsAllEvent = new EventEmitter<any>();

    /**
     *
     */
    choixHospitalisationCombo: any[] = [];
    choixHospitalisationSelected: boolean;
    typeHospitalisationCombo$: Observable<any> = new Observable<any[]>();
    typeHospitalisationSelected = '';
    objectTypeHospitalisationSelected: any;
    hospitalisationForm: FormGroup;

    requestGetTypeHospitalisation: boolean;
    dataRequestGetTypeHospitalisation: any[] = [];

    listActeAutocomplete: any[] = [];

    descriptionsForm: FormGroup;


    constructor() {
        super();
        // this.recuperationActesStorage();
        this.recuperationAffectionsStorage();

        this.choixHospitalisationSelected = false;
    }

    ngOnInit() {
        this.userData = this.securityService.getUser();

        this.initComboSelect();

        if (!this.requestGetTypeHospitalisation) {
            this.metierService.requeteRecuperationTypeHospitalisation({}, this.getArgs()).subscribe((response) => {
                this.dataRequestGetTypeHospitalisation = response.body.items;
                this.requestGetTypeHospitalisation = true;

                this.typeHospitalisationCombo$ = of([
                    ...this.dataRequestGetTypeHospitalisation.filter(
                        (elt) => (elt.idActeChambreGroupeSoin !== 0) && (elt.estHospitalisation === false)
                    )
                ]);
            });
        }

        this.initFormulaire();
    }

    ngAfterViewChecked() {
        if (this.refresh) {
            this.resetAll();

            /**
             * reset all datas
             */
            this.resetDatasComponent();

            /**
             * Add config reset
             */
            this.initComboSelect();
            this.hospitalisationForm.reset();
            this.initFormulaire();
        }
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args, adherant: this.assureSelected};
        this.args = {...arg};
    }

    initComboSelect() {
        this.choixHospitalisationCombo = [];

        this.choixHospitalisationCombo = [
            {label: 'Oui', value: true},
            {label: 'Non', value: false}
        ];
    }

    initFormulaire() {
        this.hospitalisationForm = this.metierService.initialiserFormulaireHospitalisation(null);

        this.descriptionsForm = this.metierService.initialiserFormulaireDescription(null);
    }

    resetDatasComponent() {
        this.dataListActesSelected = [];
        this.dataListAffectionsSelected = [];
        this.descriptionsAll = '';
    }

    resetAll() {
        this.dataListActesSelected = [];
        this.dataListAffectionsSelected = [];
        this.descriptionsAll = '';

        this.dataListActesSelectedEvent.emit(this.dataListActesSelected);
        this.dataListAffectionsSelectedEvent.emit(this.dataListAffectionsSelected);
        this.descriptionsAllEvent.emit(this.descriptionsAll);
    }

    recuperationActesStorage() {
        let actesList = window.localStorage.getItem(STORAGE.ACTES);
        if (!actesList) {
            this.metierServiceDemandePEC.recupererActes({}).subscribe(res => {
                this.metierServiceDemandePEC.actes = res.body.items;
            });
            return;
        }
        actesList = JSON.parse(actesList);
        this.metierServiceDemandePEC.actes = actesList;
    }

    recuperationAffectionsStorage() {
        let affectionsList = window.localStorage.getItem(STORAGE.AFFECTIONS);
        if (!affectionsList) {
            this.metierServiceDemandePEC.recupererAffections({}).subscribe(res => {
                this.metierServiceDemandePEC.affections = res.body.items;
            });
            return;
        }
        affectionsList = JSON.parse(affectionsList);
        this.metierServiceDemandePEC.affections = affectionsList;
    }

    selectionChange($event) {
        /**
         * reset all datas
         */
        this.resetAll();
        this.resetDatasComponent();
    }

    /** ** récupératyion et redistribution des données pour les actes ajouté ** */
    handleDataListActesSelected(event: any[]) {
        this.dataListActesSelected = event;
        this.dataListActesSelectedEvent.emit(this.dataListActesSelected);
    }

    /** ** récupération et redistribution des données pour les actes ajouté ** */
    handleDataListAffectionsSelected(event: any[]) {
        this.dataListAffectionsSelected = event;
        this.dataListAffectionsSelectedEvent.emit(this.dataListAffectionsSelected);
    }

    /** ** récupération et redistribution de la description générale pour les actes à accord préalable ** */
    handleDescriptionsAll(event: any) {
        this.descriptionsAll = event;
        this.descriptionsAllEvent.emit(this.descriptionsAll);
    }

    onchangeDescriptions(event) {
        this.handleDescriptionsAll(event.target.value);
    }


    /**
     *
     */
    /**
     *
     */
    handleChoixHospitalisation(event: any) {
        this.listActeAutocomplete = [];
        this.dataListActesSelected = [];

        this.hospitalisationForm.reset();

        this.choixHospitalisationSelected = event.value;

        // console.log('choixHospitalisationSelected ** : ', this.choixHospitalisationSelected);

        /**
         * uodate choixHospitalisationSelected - service
         */
        this.metierService.choixHospitalisationSelected = this.choixHospitalisationSelected;

        this.typeHospitalisationCombo$ = of([]);
        this.typeHospitalisationSelected = '';

        if (this.choixHospitalisationSelected) {
            this.typeHospitalisationCombo$ = of([
                ...this.dataRequestGetTypeHospitalisation.filter(
                    (elt) => (elt.idActeChambreGroupeSoin !== 0) && (elt.estHospitalisation === true)
                )
            ]);
        } else {
            this.typeHospitalisationCombo$ = of([
                ...this.dataRequestGetTypeHospitalisation.filter(
                    (elt) => (elt.idActeChambreGroupeSoin !== 0) && (elt.estHospitalisation === false)
                )
            ]);
        }

        this.typeHospitalisationCombo$.subscribe((v) => {
            // console.log('vvv : ', v);
            this.listActeAutocomplete = v;
        });
    }

    handleTypeHospitalisation(event: any) {
        this.hospitalisationForm.reset();

        this.typeHospitalisationSelected = '';
        this.typeHospitalisationSelected = event.value;

        if (this.choixHospitalisationSelected && this.typeHospitalisationSelected !== '' && this.typeHospitalisationSelected !== null) {
            // this.initFormulaire();

            this.objectTypeHospitalisationSelected = this.dataRequestGetTypeHospitalisation.find(
                (elt) => elt.libelle === this.typeHospitalisationSelected);

            this.hospitalisationForm.patchValue({
                montant: this.objectTypeHospitalisationSelected.montantAPayer,
                accordPrealable: this.objectTypeHospitalisationSelected.accordPrealable ? 'Oui' : 'Non',
            });

            /** **************************************************** */
            /** **************************************************** */
            // this.dataHospitalisationAutres.hospitalisation.objectType = this.objectTypeHospitalisationSelected;
            // this.dataHospitalisationAutres.hospitalisation.montant = this.hospitalisationForm.getRawValue().montant;
            // this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
            /** **************************************************** */

        } else if (!this.choixHospitalisationSelected &&
            this.typeHospitalisationSelected !== '' &&
            this.typeHospitalisationSelected !== null) {
            // this.initFormulairePharmacie();

            this.objectTypeHospitalisationSelected = this.dataRequestGetTypeHospitalisation.find(
                (elt) => elt.libelle === this.typeHospitalisationSelected);


            // this.changeMontantInPharmacie();
        }

        // console.log('objectType : ', this.objectTypeHospitalisationSelected);

        /**
         * uodate objectTypeHospitalisationSelected - service
         */
        this.metierService.objectTypeHospitalisationSelected = this.objectTypeHospitalisationSelected;
    }

    changeNumeroChambre($event: Event) {
        // console.log('changeNumeroChambre : ', this.hospitalisationForm.getRawValue());

        this.metierService.objectTypeHospitalisationSelected.numeroChambre = this.hospitalisationForm.getRawValue().numeroChambre;
    }

    changeService($event: Event) {
        // console.log('changeService : ', this.hospitalisationForm.getRawValue());

        this.metierService.objectTypeHospitalisationSelected.service = this.hospitalisationForm.getRawValue().service;
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormErrorComponent} from '@shared/components/error/form-error/form-error.component';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
    imports: [
        CommonModule,
        MatFormFieldModule,
    ],
    declarations: [
        FormErrorComponent,
    ],
    exports: [
        FormErrorComponent,
    ]
})
export class FormErrorComponentModule {
}

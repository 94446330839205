import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

/**
 * Guard vérifiant que l'utilisateur possède les permissions requises.
 */
@Injectable({
    providedIn: 'root'
})
export class HasPermissionGuard implements CanActivate {
    constructor(private router: Router) {}

    canActivate(route: ActivatedRouteSnapshot,
                state: RouterStateSnapshot): Observable<boolean | UrlTree> |
        Promise<boolean | UrlTree> | boolean | UrlTree {
        const routeRoles = (<any>route.data).roles;
        if (routeRoles === null || routeRoles === undefined) {
            return true;
        }

        if (ClvTsUtilsCommonsJs.jsType(routeRoles) === 'array') {
            if (routeRoles.length < 1) {
                return true;
            }
        }
        if (SecurityStateService.currentUserHasPermissions(routeRoles)) {
            return true;
        }
        this.router.navigate(['error']);
        return true;
    }
}

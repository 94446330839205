import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {AlertService} from '@app/core/services/alert.service';
import {ERROR_MESSAGE_VERIFICATION_RECHERCHE_POURSUITE} from '@app/core/constantes';
import {Observable} from 'rxjs';
import {PrescriptonPecMetierService} from '../prescripton-pec.metier.service';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';

@Component({
    selector: 'app-prescription-pec-form',
    templateUrl: './prescription-pec-form.component.html',
    styleUrls: ['./prescription-pec-form.component.css']
})
export class PrescriptionPecFormComponent extends SgiListLayoutComponent implements OnInit {

    @Autowired() formBuilder: FormBuilder;
    @Autowired() metierService: PrescriptonPecMetierService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() alertService: AlertService;
    result$: Observable<any> = new Observable<any>();
    searchForm: FormGroup;

    @Input() sectionAffichage: string;

    constructor() {
        super();
    }

    ngOnInit() {
        this.initFormulaire();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
        };
        this.args = {...arg};
    }

    initFormulaire() {
        return this.searchForm = this.metierService.initialiserFormulaire(null);
    }

    rechercheBon() {
        this.searchForm.setValue({
            Matricule: this.searchForm.getRawValue().Matricule.trim(),
            NumBon: this.searchForm.getRawValue().NumBon.trim()
        });
        if (!this.searchForm.getRawValue().Matricule && !this.searchForm.getRawValue().NumBon) {
            this.alertService.showError(ERROR_MESSAGE_VERIFICATION_RECHERCHE_POURSUITE).show();
        } else {
            this.result$ = this.metierService.recupererListePoursuitePEC(
                {
                    InfoSearchdateEmissionBon: {IsOrderByField: true, Consider: false},
                    InfoSearchnumBonPriseEnCharge: {Consider: true},
                    matricule: this.searchForm.getRawValue().Matricule,
                    numBonPriseEnCharge: this.searchForm.getRawValue().NumBon
                },
                {getAllRequestParam: this.getAllRequestParam()}
            );
        }
    }

}

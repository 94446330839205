import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {ModuleWithProviders} from '@angular/core';

import {PagesComponent} from './pages/pages.component';
import {NotFoundComponent} from './pages/errors/not-found/not-found.component';
import {AuthenticationGuard} from './core/guards/authentication.guard';
import {HasPermissionGuard} from './core/guards/has-permission.guard';

export const routes: Routes = [
    {
        path: '',
        canActivate: [AuthenticationGuard],
        data: {
            permissions: {
                are: 'AUTENTICATED'
            },
            breadcrumb: ''
        },
        component: PagesComponent, children: [
            {
                path: '',
                loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule),
                canActivate: [HasPermissionGuard],
                data: {
                    breadcrumb: 'Dashboard',
                    roles: ['app.tableau_de_bord']
                }
            },
            {
                path: 'demandes-pec',
                loadChildren: () => import('./pages/demande-pec/demande-pec.module').then(m => m.DemandePecModule),
                canActivate: [HasPermissionGuard],
                data: {
                    breadcrumb: 'Demandes PEC',
                    roles: ['app.prestation.demandepec']
                }
            },
            {
                path: 'prestation-pharmacie',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./pages/prestation-pharmacie/prestation-pharmacie.module').then(m => m.PrestationPharmacieModule),
                canActivate: [HasPermissionGuard],
                data: {
                    breadcrumb: 'prestation pharmacie',
                    roles: ['app.prestation.pharmacie']
                }
            },
            {
                path: 'prestation-facture',
                loadChildren: () => import('./pages/factures/factures.module').then(m => m.FacturesModule),
                canActivate: [HasPermissionGuard],
                data: {
                    breadcrumb: 'Factures',
                    roles: ['app.prestation.facture']
                }
            },
            {
                path: 'dossier-medical-temporaire',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./pages/dossier-medical-temporaire/dossier-medical-temporaire.module').then(m => m.DossierMedicalTemporaireModule),
                canActivate: [HasPermissionGuard],
                data: {
                    breadcrumb: 'Dossier médical temporaire',
                    roles: ['app.dossiermedicaltemporaire']
                }
            },


            //
            //
            //


            {
                path: 'profil-utilisateur',
                // tslint:disable-next-line:max-line-length
                loadChildren: () => import('./pages/atlantis/profil-utilisateur/profil-utilisateur.module').then(m => m.ProfilUtilisateurModule),
                data: {breadcrumb: 'Profil utilisateur'}
            },
            {
                path: 'd3',
                loadChildren: () => import('./pages/atlantis/d3/d3-page.module').then(m => m.D3PageModule),
                data: {breadcrumb: 'd3Example'}
            },
            {
                path: 'ngxcharts',
                loadChildren: () => import('./pages/atlantis/ngxcharts/ngxcharts-page.module').then(m => m.NgxchartsPageModule),
                data: {breadcrumb: 'Ngxcharts'}
            },
            // @end_adding_page_routes
        ]
    },
    {
        path: 'login',
        loadChildren: () => import('./pages/atlantis/login/login.module').then(m => m.LoginModule),
        canActivate: [AuthenticationGuard],
        data: {
            permissions: {
                except: 'AUTENTICATED'
            }
        }
    },
    {
        path: 'activate-currentUser',
        loadChildren: () => import('./pages/atlantis/activer-utilisateur/activer-utilisateur.module').then(m => m.ActiverUtilisateurModule),
        canActivate: [AuthenticationGuard],
        data: {
            permissions: {
                except: 'AUTENTICATED'
            }
        }
    },
    {
        path: 'update-password',
        loadChildren: () => import('./pages/atlantis/refresh-password/refresh-password.module').then(m => m.RefreshPasswordModule),
        canActivate: [AuthenticationGuard],
        data: {
            permissions: {
                except: 'AUTENTICATED'
            }
        }
    },
    /*{
        path: 'register---',
        loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule),
        canActivate: [AuthenticationGuard],
        data: {
            permissions: {
                except: 'AUTENTICATED'
            }
        }
    },
    {
        path: 'registersgi',
        loadChildren: () => import('./pages/register-sgi/register.module').then(m => m.RegisterSgiModule),
        canActivate: [AuthenticationGuard],
        data: {
            permissions: {
                except: 'AUTENTICATED'
            }
        }
    },*/
    {
        path: 'error', component: NotFoundComponent, data: {breadcrumb: 'Error'}
    },
    {
        path: '', redirectTo: 'login', pathMatch: 'full'
    },
    {
        path: '**', component: NotFoundComponent
    },
    /*{
        path: '**',
        loadChildren: () => import('./pages/atlantis/login/login.module').then(m => m.LoginModule),
        canActivate: [AuthenticationGuard],
        data: { permissions: { except: 'AUTENTICATED' } }
    },*/
    // @end_adding_routes
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
    // preloadingStrategy: PreloadAllModules,  // <- comment this line for activate lazy load
    // useHash: true
});


import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {ConnexionLayoutModaleFeatureComponent} from './connexion-layout-modale.feature.component';
import {ResetPasswordFormModule} from '@app/fonctionnalites/authentification/connexion/composants/reset-password-form/reset-password-form.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ResetPasswordFormModule,
    ],
    exports: [
        ConnexionLayoutModaleFeatureComponent,
    ],
    declarations: [
        ConnexionLayoutModaleFeatureComponent,
    ],
    providers: [],
})
export class ConnexionLayoutModaleFeatureModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
// import {ClvAlertMessageDialogComponent} from './comunication/clv-alert-message-dialog.component';
import {ClvComboTemplateComponent} from './data-management/clv-combo-template.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ClvAutocomplateTemplateComponent} from './data-management/clv-autocomplate-template.component';
import {ClvOverlaysComponent} from './clv-overlays.component';
import {BeanAccessibleModule} from '@angular-ru/autowired';
import {MatInputModule} from '@angular/material/input';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import { ClvConfirmPrimeNgShower } from './comunication/clv-confirm-prime-ng-shower';
import { ClvSnakebarMessageShower } from './comunication/clv-snakebar-message-shower';
import { ClvToastMessageShower } from './comunication/clv-toast-message-shower';

/**
 * @deprecated
 */
@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
    MatSnackBarModule,
    MatFormFieldModule,
    MatSelectModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatInputModule,
    BeanAccessibleModule.forRoot()
  ],
  declarations: [ClvComboTemplateComponent, ClvAutocomplateTemplateComponent,
    ClvOverlaysComponent],
  exports: [ClvComboTemplateComponent, ClvAutocomplateTemplateComponent,
    ClvOverlaysComponent],
    providers: [
      ClvConfirmPrimeNgShower,
      ClvSnakebarMessageShower,
      ClvToastMessageShower
    ],
  entryComponents: []
})
export class ClvAngularBootModule { }

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormBuilder} from '@angular/forms';
import {LierBordereauModaleListeMetierService} from './lier-bordereau-modale-liste.metier.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';

@Component({
    selector: 'app-lier-bordereau-modale-liste-feature',
    templateUrl: './lier-bordereau-modale-liste.feature.component.html',
})
export class LierBordereauModaleListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: LierBordereauModaleListeMetierService;
    @Autowired() recuperationListeDonneesMetier: LierBordereauModaleListeMetierService;
    @Autowired() metierService: LierBordereauModaleListeMetierService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() dialogComponent: DialogComponent;

    tabActive = 'Actes';

    @Autowired() formBuilder: FormBuilder;
    @Output() open: EventEmitter<any> = new EventEmitter();
    @Output() enregistrerLiaison: EventEmitter<any> = new EventEmitter();

    affichModalPrintBon = false;
    tableSelectedElement: any;

    columns: any = [];
    radioAction: boolean;
    bordereauSelected: any;
    afficherNumeroFacture: boolean;

    typeRechercheCombo: any = [
      {label: 'Numero Bordereau', value: 'numeroBordereau'},
      {label: 'Numero Facture', value: 'numeroFacture'}
    ];
    placeholderInputSearch: string;
    prestataireName: any;

    constructor() {
        super();
    }

    ngOnInit() {
        this.selectedColumns = [
            {field: 'numBonPriseEnCharge', header: 'N° Prise en charge'},
            {field: 'montantNet', header: 'Montant', type: () => 'price'},
            {field: 'partAssuranceBrut', header: 'Part Assurance Brut', type: () => 'price'},
            {field: 'ticketModerateur', header: 'Ticket Moderateur', type: () => 'price'},
            {field: 'partAssurance', header: 'Net à Payer', type: () => 'price'},
        ];

        const data = {
            radioAction: false,
            nom: this.securityService.getUser().personne.prenom,
            numeroFacture: null,
        };

        this.prestataireName = data.nom

      console.log("data data", data)

      const args = {desableNumeroFacture: false};

        this.initialiserFormulaire(data, args);
        this.afficherNumeroFacture = false;
        this.placeholderInputSearch = 'numeroBordereau';
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
        };
        this.args = {...arg};
    }

    initialiserFormulaire(data?, args?) {
      this.radioAction = false;
      //console.log('formgroyup avant', this.formGroup)
      //console.log('données passe', data, 'args', args)
      this.formGroup = this.metierService.initialiserFormulaire(data, args);
      console.log('formgroyup apres', this.formGroup)
      this.enregistrerToParent();
    }

    radioChange() {
        if (this.formGroup.getRawValue().radioAction === true) {
            this.formGroup = this.metierService.initialiserFormulaireToDisable(this.formGroup.getRawValue(), {desableNumeroFacture: false});
            this.radioAction = true;
            this.afficherNumeroFacture = true;
            this.open.emit({});
        } else {
            this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue(), {desableNumeroFacture: false});
            this.radioAction = false;
            this.afficherNumeroFacture = false;
            this.open.emit({});
        }
        this.enregistrerToParent();
    }

    bordereauSelectionner($event: any) {
        this.formGroup.patchValue({numeroFacture: $event.numeroFacture});
        this.bordereauSelected = $event;
        this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue(), {desableNumeroFacture: true});
        this.afficherNumeroFacture = true;
        this.enregistrerToParent();
    }

    viderBordereauAutocomplete() {
        this.formGroup.patchValue({searchBordereau: '', numeroFacture: ''});
        this.bordereauSelected = null;
        this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue(), {desableNumeroFacture: false});
        this.radioAction = false;
        this.afficherNumeroFacture = false;
        this.enregistrerToParent();
    }

    enregistrerToParent() {
        const data = {
            formGroup: this.formGroup.getRawValue(),
            bordereauSelected: this.bordereauSelected
        };
      console.log('la fonction a ete exécutuer', data);
      this.enregistrerLiaison.emit(data);
    }

  testCheck(event) {
    console.log(event);
  }

  handleChangeTypeRecherche($event: any) {
    console.log("event", $event)
    this.placeholderInputSearch = $event.value
  }

  rechercheFacture($event: KeyboardEvent) {
    console.log("event", $event)
  }
}

import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface IThemeState {
  theme: string;
  sidebar: string;
}

export function defaultThemeState(): IThemeState {
  return {
    theme: 'thm-blue-light',
  sidebar: 'sb-init'
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'themeState'
})
export class ThemeStoreService extends Store<IThemeState> {

  constructor() {
    super(defaultThemeState());
  }
}

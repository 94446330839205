import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ConnexionLayoutComponent} from './layout/connexion-layout.component';
import {DeconnexionXTimeAlertComponent} from '@app/fonctionnalites/authentification/connexion/deconnexion-x-time-alert.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {FormInputComponentModule} from '@shared/components/inputs/form-input/form-input.component.module';
import {MatButtonModule} from '@angular/material/button';
import {CardModule} from 'primeng/card';
import {ConnexionLayoutModaleFeatureModule} from '@app/fonctionnalites/authentification/connexion/modale/connexion-layout-modale.feature.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        TranslateModule,
        MatIconModule,
        FormInputComponentModule,
        MatButtonModule,
        CardModule,
        ConnexionLayoutModaleFeatureModule,
    ],
    declarations: [
        ConnexionLayoutComponent,
        DeconnexionXTimeAlertComponent,
    ],
    exports: [
        ConnexionLayoutComponent,
        DeconnexionXTimeAlertComponent,
    ]
})
export class ConnexionModule {
}

import {Component, Input, OnInit} from '@angular/core';
import {DocumentTableListeMetierService} from './document-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';

@Component({
  selector: 'app-document-table-liste',
  templateUrl: './document-table.liste.component.html'
})

export class DocumentTableListeComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: DocumentTableListeMetierService;

  @Input() currentDataUser: any;
  @Input() sectionAffichage: string;

  constructor() {
    super();
    this.cols = [
      { field: 'libelle', header: 'Type' },
      { field: 'observations', header: 'Titre' },
    ];

    this.tableActions = [this.consulterAction];
  }

  ngOnInit(): void {
    if (this.currentDataUser) {
      this.recuperDonnees();
    }
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, currentDataUser: this.currentDataUser, sectionAffichage: this.sectionAffichage};
    this.args = {...arg};
  }

  /**
   * Effectue une demande de visualisation du document
   * @param data
   */
  consulterDocument(data: any) {
    const shower = this.alertService.showWarning(this.messageDemandeVisualisation);
    shower.accept = () => {
      const arrayConstruct: any[] = [];
      arrayConstruct.push({
        File: data.pieceJointe,
        Name: `-- Type: ${data.libelle}, Titre: ${data.observations}`,
      });
      this.gestionnaireDeModales[0].datasourceSelected = arrayConstruct;
      this.gestionnaireDeModales[0].display = true;
    };
    shower.reject = () => {};
    shower.show();
  }

}

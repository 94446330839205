import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {MenuService} from '../menu.service';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {AppSettings} from '@app/app.settings';
import {Settings} from '@app/app.settings.model';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class VerticalMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  parentMenu: Array<any>;
  public settings: Settings;
  constructor(public appSettings: AppSettings, public menuService: MenuService) {
    this.settings = this.appSettings.settings;
  }

  ngOnInit() {
    this.parentMenu = this.menuItems.filter(item => item.parentId === this.menuParentId);
  }

  onClick(menuId) {
    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
  }

  canShow(menu) {
    if (ClvTsUtilsCommonsJs.isUndefinedOrNull(menu.permissions)) {
      return true;
    }
    return SecurityStateService.currentUserHasPermissions(menu.permissions);
  }
}

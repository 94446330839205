import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {LoaderStoreService} from '@app/core/states/loader/loader.store.service';
import {LoaderQueryService} from '@app/core/states/loader/loader.query.service';

/**
 * Service d'état du compteur de requêtes en cours pour l'affichage du composant de simulation de "chargenemt en cours"
 */
@Injectable({providedIn: 'root'})
export class LoaderStateService {
  @Autowired() store: LoaderStoreService;
  @Autowired() query: LoaderQueryService;
  /**
   * Incremente l'état
   */
  increment() {
    this.store.update({count: this.query.__store__._value().count + 1});
  }
  /**
   * Decremente l'état
   */
  decrement() {
    if (this.query.__store__._value().count > 0) {
      this.store.update({count: this.query.__store__._value().count - 1});
    }
  }
  reset() {
    this.store.update({count: 0});
  }
  /**
   * Recuperer la langue active
   */
  getState() {
    return this.query.__store__._value();
  }
}
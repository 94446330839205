import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DemandeDossierMedicalTableMetierService} from './demande-dossier-medical-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE} from '@app/core/constantes';

@Component({
  selector: 'app-demande-dossier-medical-table',
  templateUrl: './demande-dossier-medical-table.component.html'
})

export class DemandeDossierMedicalTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: DemandeDossierMedicalTableMetierService;
  @Autowired() metierService: DemandeDossierMedicalTableMetierService;

  @Autowired() datePipe: DatePipe;

  @Input() modalOpen: any;
  @Input() adherentSelected: any;
  @Input() nomPrenoms: any;
  @Input() dateNaissance: any;
  @Input() genre: any;
  @Input() numBonPriseEnCharge: any;
  @Input() prestataire: any;
  @Output() dossierMedicalEvent = new EventEmitter<any>();
  adherentForm: FormGroup;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initFormulaire();

    this.dossierMedicalEvent.emit({});
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  initFormulaire() {
    this.adherentForm = this.metierService.initialiserFormulaireDossierMedical({
      nomPrenoms: this.nomPrenoms,
      dateNaissance: this.dateNaissance,
      genre: this.genre,
      numBonPriseEnCharge: this.numBonPriseEnCharge,
      prestataire: this.prestataire,
    });
  }

  changValueCalendar(event: any, typeInput: string) {
    if (this.adherentForm.getRawValue().dateDebut && this.adherentForm.getRawValue().dateFin) {
      if (new Date(CommonUtilities.formatDateReverseToServer(this.adherentForm.getRawValue().dateDebut, this.datePipe)).getTime() >=
          new Date(CommonUtilities.formatDateReverseToServer(this.adherentForm.getRawValue().dateFin, this.datePipe)).getTime()) {

        this.alertService.showError('La date de fin ne doit pas être inférieure ou égale à la date de début !').show();

        if (typeInput === 'dateDebut') { this.adherentForm.patchValue({ dateDebut: null }); this.adherentSelected.dateDebut = undefined; }
        if (typeInput === 'dateFin') { this.adherentForm.patchValue({ dateFin: null }); this.adherentSelected.dateFin = undefined; }

        this.dossierMedicalEvent.emit(this.adherentSelected);
        return;
      }
    }

    if (typeInput === 'dateDebut') {
      this.adherentSelected.dateDebut = CommonUtilities.formatDateReverseToServer(this.adherentForm.getRawValue().dateDebut, this.datePipe);
      // tslint:disable-next-line:max-line-length
      // this.adherentSelected.dateDebut = `/Date(${new Date(CommonUtilities.formatDateReverseToServer(this.adherentForm.getRawValue().dateDebut, this.datePipe)).getTime()})/`;
    }
    if (typeInput === 'dateFin') {
      this.adherentSelected.dateFin = CommonUtilities.formatDateReverseToServer(this.adherentForm.getRawValue().dateFin, this.datePipe);
      // tslint:disable-next-line:max-line-length
      // this.adherentSelected.dateFin =`/Date(${new Date(CommonUtilities.formatDateReverseToServer(this.adherentForm.getRawValue().dateFin, this.datePipe)).getTime()})/`;
    }

    this.dossierMedicalEvent.emit(this.adherentSelected);
  }
}

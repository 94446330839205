import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {MaladieChroniqueDossierTableRestApiService} from './maladie-chronique-dossier-table.rest-api.service';
import {FormBuilder} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class MaladieChroniqueDossierTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: MaladieChroniqueDossierTableRestApiService;
  @Autowired() formBuilder: FormBuilder;

  @Autowired() datePipe: DatePipe;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

}

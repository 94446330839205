import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {DemandeHospitalisationTableRestApiService} from './demande-hospitalisation-table.rest-api.service';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';

@Injectable({providedIn: 'root'})
export class DemandeHospitalisationTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: DemandeHospitalisationTableRestApiService;
  @Autowired() formBuilder: FormBuilder;

  choixHospitalisationSelected: boolean;
  objectTypeHospitalisationSelected: any;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  requeteRecuperationTypeHospitalisation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationTypeHospitalisation(data, args).pipe(
        map((response: HttpResponse<any>) => {
          response.body.items.forEach(v => {
            v.label = v.libelle;
            v.value = v.libelle;
          });
          return response;
        })
    );
  }

  initialiserFormulaireHospitalisation<T>(datas: T | any, args?: any): FormGroup {
    // !!datas && !!datas.medecin ? datas.medecin : null
    const data: any = {
      choixHospitalisation: null,
      typeHospitalisation: null,
      numeroChambre: null,
      service: null,
    };

    return this.formBuilder.group({
      choixHospitalisation: data.chambre,
      typeHospitalisation: data.nombreJour,
      numeroChambre: data.numeroChambre,
      service: data.service,
    });
  }

  initialiserFormulaireDescription<T>(datas: T | any, args?: any): FormGroup {
    const formGroup: FormGroup = new FormGroup({});
    formGroup.setControl('descriptionsAll', new FormControl(null));
    return formGroup;
  }

  requeteCalculMontant(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteCalculMontant(data, args);
  }

}

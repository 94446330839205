import {ClvFileModel, ClvFilesUtils, ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {EventEmitter} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

/**
 * Utilitaire de fichiers
 */
export class FilesUtilities {
  /**
   * Vérifie que l'extension d'un fichier est accepté
   *
   * @param file
   * @param accept
   */
  public static fileAccepted(file: ClvFileModel, accept: string = '') {
    if (!accept || accept === '') {
      return true;
    }

    return -1 !== accept.search(file.extension);
  }

  /**
   * Vérifier que la taille d'une liste de fichiers n'excede pas une taille maximale
   *
   * @param files
   * @param maxSize
   */
  public static fileMaxSizeExceded(files: ClvFileModel[], maxSize: number) {
    let filesSize = 0;
    files.forEach(file => {
      filesSize += file.size;
    });
    return filesSize > maxSize;
  }

  /**
   * Recupère le fichier chargé et le sérialise
   * @param event
   * @param clvFiles
   * @param emitter
   * @param accept
   */
  public static handleFileChange(event, clvFiles: ClvFileModel[] = [],
                                 emitter: any = new EventEmitter<any>(true),
                                 accept: string = '') {
    const files: Array<File> = event.target.files;
    if (files) {
      files.forEach(file => {
        ClvFilesUtils.serializeFile(file).subscribe(clvFile => {
          if (this.fileAccepted(clvFile, accept)) {
            clvFiles.push(clvFile);
            emitter.emit(clvFiles);
          }
        });
      });
    }
  }

  /**
   * Supprimer un fichier d'une liste de fichier
   *
   * @param clvFiles
   * @param file
   */
  public static clvDeleteFile(clvFiles: ClvFileModel[], file: ClvFileModel) {
    const files = ClvTsUtilsCommonsJs.deleteFromArray(clvFiles, file);
    if (files !== -1) {
      return files;
    }
    return clvFiles;
  }

  /**
   * Telecharger un fichier
   *
   * @param file
   * @param sanitize
   */
  public static clvDownloadUrl(file: ClvFileModel, sanitize: DomSanitizer) {
    return ClvFilesUtils.buildDownloadFileUrl(sanitize, file);
  }
}

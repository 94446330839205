import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {SubstitustionModaleMetierService} from './substitustion.modale.metier.service';
import {FormControl, FormGroup} from '@angular/forms';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

@Component({
    selector: 'app-substitustion-modale',
    templateUrl: './substitustion.modale.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubstitustionModaleComponent extends DialogComponent {
    @Autowired() convertirFormulaireMetier: SubstitustionModaleMetierService;
    @Autowired() initialiserFormulaireMetier: SubstitustionModaleMetierService;
    @Autowired() requeteCreationMetier: SubstitustionModaleMetierService;
    @Autowired() requeteModificationMetier: SubstitustionModaleMetierService;
    @Autowired() metierService: SubstitustionModaleMetierService;

    @Autowired() securityStateService: SecurityStateService;

    searchForm: FormGroup;
    medicamentTrouver: any = [];
    medicamentSelectionner: any;
    storageUser: IUserConnect = this.securityStateService.getUser();

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.header = this.modalManager.datasourceSelected.libelle;
        this.searchForm = this.metierService.initialiserFormulaire(null);
        this.recupererMedicamentsDeSeSubstitution();
    }

    recupererMedicamentsDeSeSubstitution() {
        this.metierService.requeteRecuperationDonnee(this.modalManager.datasourceSelected)
            .subscribe(response => {
                this.medicamentTrouver = response.body.items;
            });
    }

    actionSelectMedicament($event: any) {
        this.medicamentSelectionner = $event;
    }

    verification() {
        const data = this.searchForm.getRawValue();

        if (!this.medicamentSelectionner) {
            this.alertService.showError('Veillez selectionner un substituant').show();
        } else if (data.prixUnitaire === '' || data.prixUnitaire === '0' || data.prixUnitaire === null) {
            this.alertService.showError('Veillez saisir le prix unitaire').show();
        } else if (data.quantite === '' || data.quantite === '0' || data.quantite === null) {
            this.alertService.showError('Veillez saisir la quantité').show();
        } else if (data.posologie === '' || data.posologie === '0' || data.posologie === null) {
            this.alertService.showError('Veillez saisir la posologie').show();
        } else {
            this.enregistrerSubstitution(data);
        }
    }

    enregistrerSubstitution(data) {
        // tslint:disable-next-line:radix
        this.medicamentSelectionner.prixUnitaire = parseInt(data.prixUnitaire);
        this.medicamentSelectionner.posologie = data.posologie;
        this.medicamentSelectionner.quantiteRestante = data.quantite;
        this.medicamentSelectionner.quantite = data.quantite;
        this.medicamentSelectionner.nomOrigine = this.modalManager.datasourceSelected.libelle;
        this.medicamentSelectionner.idMedicamentOrigine = this.modalManager.datasourceSelected.idPrestationPrescription;
        this.medicamentSelectionner.idPrestationPrescription = null;
        this.medicamentSelectionner.nomOrigine = this.modalManager.datasourceSelected.libelle;
        this.medicamentSelectionner.estSubstituantNew = true;
        this.medicamentSelectionner.quantiteRealise = 0;

        this.medicamentSelectionner.idPrestatairePharmacie = this.modalManager.datasourceSelected.idPrestatairePharmacie;
        this.medicamentSelectionner.idBonPriseEnCharge = this.modalManager.datasourceSelected.idBonPriseEnCharge;
        this.medicamentSelectionner.idPrestataireMedical = this.modalManager.datasourceSelected.idPrestataireMedical;
        this.medicamentSelectionner.idTermeConvention = this.modalManager.datasourceSelected.idTermeConvention;

        this.modalManager.datasourceSelected.substitue = true;
        this.modalManager.datasourceSelected.estSubstitueNew = true;
        this.modalManager.datasourceSelected.nomOrigine = 'Substitué';

        const subtituantData = {

            idPrestation: this.modalManager.datasourceSelected.idPrestation,
            estSubstituantNew: true,
            idBonPriseEnCharge: this.medicamentSelectionner.idBonPriseEnCharge,
            idMedecin: null,
            idMedicament: this.medicamentSelectionner.idSubstituant,
            idMedicamentOrigine: this.modalManager.datasourceSelected.idPrestationPrescription,
            idPoolMedicalPharmacien: null,
            idPrescriptionMaladieChronique: this.modalManager.datasourceSelected.idPrescriptionMaladieChronique,
            idPrestataireMedical: this.modalManager.datasourceSelected.idPrestataireMedical,
            idPrestatairePharmacie: this.modalManager.datasourceSelected.idPrestatairePharmacie,
            idPrestationAffection: 0,
            idPrestationPrescription: null,
            idSouscriptionLigne: this.medicamentSelectionner.idSouscriptionLigne,
            idTermeConvention: this.modalManager.datasourceSelected.idTermeConvention,
            libelle: this.medicamentSelectionner.libelle,
            nomOrigine: this.modalManager.datasourceSelected.libelle,
            posologie: this.medicamentSelectionner.posologie,
            prixUnitaire: this.medicamentSelectionner.prixUnitaire,
            quantite: this.medicamentSelectionner.quantite,
            quantiteRealise: this.medicamentSelectionner.quantiteRealise,
            quantiteRestante: this.medicamentSelectionner.quantiteRestante,
            realise: false,
            substitue: false,

            accordPrealable: this.medicamentSelectionner.accordPrealable,
        };

        this.modalManager.datasourceSelected = {
            substitue: this.modalManager.datasourceSelected,
            substituant: subtituantData,
        };
        super.fermerModale();
    }

    clearAutocomplete() {
        this.searchForm.setControl('medicamentSubstituant', new FormControl(''));
        this.medicamentSelectionner = null;
    }
}

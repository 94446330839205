import {Component, Input, OnInit} from '@angular/core';
import {PersonneMedicalTableMetierService} from './personne-medical-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-personne-medical-table',
  templateUrl: './personne-medical-table.component.html'
})

export class PersonneMedicalTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: PersonneMedicalTableMetierService;
  @Autowired() metierService: PersonneMedicalTableMetierService;

  @Input() personneMedical: any;

  personneMedicalForm: FormGroup;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initFormulaire();
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  initFormulaire() {
    this.personneMedicalForm = this.metierService.initialiserFormulaire(this.personneMedical);
  }

}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import { DetailsAffectionTableListeRestApiService } from './details-affection-table.liste.rest-api.service';


@Injectable({providedIn: 'root'})
export class DetailsAffectionTableListeMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: DetailsAffectionTableListeRestApiService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  recupererPoursuiteListeAffection(data: any,) {
    return this.restApi.requeteRecuperationListeDonnees(data);
  }
}

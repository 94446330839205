export interface StocksBig {
  letter: string;
  value: number;
}

export const STOCKSBIG: StocksBig[] = [
  {letter: 'a', value: 10},
  {letter: 'b', value: 30},
  {letter: 'c', value: 50},
  {letter: 'd', value: 260},
  {letter: 'e', value: 210.58},
  {letter: 'f', value: 55},
  {letter: 'g', value: 800},
  {letter: 'h', value: 410},
];

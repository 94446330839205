import {AfterViewInit, Directive, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {CommonUtilities, ThousandSeparatorArg} from '@app/core/utilities/common.utilities';
import {DirectiveLazyUtilities} from '@app/core/utilities/directive-lazy.utilities';

@Directive({
    selector: '[separatorThousand]',
})
export class SeparatorThousandDirective implements OnInit, AfterViewInit {
  control: AbstractControl;
  @Input('separatorThousandArgs') separatorThousandArgs: ThousandSeparatorArg = {decimal: true, pres: 2, arround: false};
    @Input('separatorThousand') set separatorThousand(data: AbstractControl) {
      try {
        this.control = data;
        data.setValue(CommonUtilities.thousandSeparator(data.value, this.separatorThousandArgs));
      } catch (e) {
      }
    }
    constructor(private el: ElementRef) {
    }

    ngOnInit(): void {
      DirectiveLazyUtilities.inView(this.el.nativeElement).subscribe(inv => {
        if (inv) {
          this.onKeyUp();
        }
      });
    }

    ngAfterViewInit(): void {
      DirectiveLazyUtilities.inView(this.el.nativeElement).subscribe(inv => {
        if (inv) {
          this.onKeyUp();
        }
      });
    }

  @HostListener('keyup') onKeyUp() {
      try {
        this.control.setValue(CommonUtilities.thousandSeparator(this.control.value, this.separatorThousandArgs));
      } catch (e) {}
  }

}

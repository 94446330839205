import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DatePipe} from '@angular/common';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
  API_RECUPERER_PRESTATION_DOSSIER_MEDICAL_ENCOURS,
  API_RECUPERER_RESULTAT_EXAMEN_DOSSIER_MEDICAL_ENCOURS
} from '@app/core/constantes';
import {CommonUtilities} from '@app/core/utilities/common.utilities';


@Injectable({providedIn: 'root'})
export class PrestationDossierTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;
  @Autowired() datePipe: DatePipe;

  storageUser: IUserConnect = this.getUser();

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    let constructorParamRequest: any;

    if (args.formGroup.value.selectTypeSearch === '' || args.formGroup.value.selectTypeSearch === null) {
      constructorParamRequest = {
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
        InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchMatricule: {Consider: true},
        Matricule: args.assure.matricule,
      };
    }
    if (args.formGroup.value.selectTypeSearch === 'prestataire') {
      constructorParamRequest = {
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
        InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchMatricule: {Consider: true},
        InfoSearchraisonSociale: {Consider: true},
        Matricule: args.assure.matricule,
        raisonSociale: args.formGroup.value.inputSearch,
      };
    }
    if (args.formGroup.value.selectTypeSearch === 'num_bon_pec') {
      constructorParamRequest = {
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
        InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchMatricule: {Consider: true},
        InfoSearchnumBonPriseEnCharge: {Consider: true},
        Matricule: args.assure.matricule,
        numBonPriseEnCharge: args.formGroup.value.inputSearch,
      };
    }
    if (args.formGroup.value.selectTypeSearch === 'date_prestation') {
      constructorParamRequest = {
        datePrestation: `${CommonUtilities.formatDateReverseToServer(args.formGroup.value.inputSearch, this.datePipe)}T00:00:00.000Z`,
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
        InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchMatricule: {Consider: true},
        Matricule: args.assure.matricule,
      };
    }

    // update du paramètre
    data.SortOrder = null;

    const sendData = {
      ...data,
      itemToSearch: {
        ...data.itemToSearch,
        ...constructorParamRequest
      }
    };

    // tslint:disable-next-line:max-line-length
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PRESTATION_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
  }

  requeteGetPrestationActesResultatsExamen(data: any, args?: any): Observable<any> | Promise<any> | any {
    const sendData = {
      itemToSearch: {
        IdPrestation: args.idPrestation,
        InfoSearchDatePrestation: {Consider: false},
        InfoSearchDateRealisation: {Consider: false},
        InfoSearchEstSinistreTp: {Consider: false},
        InfoSearchIdActeMedical: {Consider: false},
        InfoSearchIdBonPriseEnCharge: {Consider: false},
        InfoSearchIdDemandePriseEnCharge: {Consider: false},
        InfoSearchIdPrestataireMedical: {Consider: false},
        InfoSearchIdPrestation: {Consider: true},
        InfoSearchIdPrestationActe: {Consider: false},
        InfoSearchIdTenant: {Consider: false},
        InfoSearchIsDeleted: {Consider: false},
        InfoSearchLibelle: {Consider: false},
        InfoSearchMontantExclu: {Consider: false},
        InfoSearchMontantHT: {Consider: false},
        InfoSearchMontantNet: {Consider: false},
        InfoSearchPartAssure: {Consider: false},
        InfoSearchPartAssureur: {Consider: false},
        InfoSearchRealise: {Consider: false},
        InfoSearchTauxTva: {Consider: false},
      }
    };

    // tslint:disable-next-line:max-line-length
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_RESULTAT_EXAMEN_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
  }

}

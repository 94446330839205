import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
  API_AFFECTION_DETAILS_PRISE_EN_CHARGE
} from '@app/core/constantes';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

@Injectable({providedIn: 'root'})
export class DetailsAffectionTableListeRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;

  storageUser: IUserConnect = this.getUser();
  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

 requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
     const { currentDataUser } = args;

     const  sendData = {
         itemToSearch: {
             InfoSearchidDemandePriseEnCharge: {Consider: true},
             InfoSearchlibelleAffection: {
                 IsOrderByField: true,
                 Consider: false
             },
             idDemandePriseEnCharge: currentDataUser.idDemandePriseEnChargeParent
         }};

     // tslint:disable-next-line:max-line-length
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_AFFECTION_DETAILS_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
  }
}

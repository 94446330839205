import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {RefreshPasswordComponent} from './refresh-password.component';
import {SharedModule} from '../../../shared/shared.module';
import {ResetPasswordFeatureModule} from '../../../fonctionnalites/authentification/reset-password/reset-password.feature.module';

export const routes = [
  { path: '', component: RefreshPasswordComponent, pathMatch: 'full' },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ResetPasswordFeatureModule
  ],
  declarations: [
    RefreshPasswordComponent
  ]
})
export class RefreshPasswordModule { }

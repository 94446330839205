import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
  API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE,
  API_ACTE_PRESTATION_DETAILS_PRISE_EN_CHARGE,
  STATUT_CLOTURE
} from '@app/core/constantes';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

@Injectable({providedIn: 'root'})
export class ActeRealiseDetailsTableListeRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;

  storageUser: IUserConnect = this.getUser();
  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    const { currentDataUser } = args;

    let urlApi: string;
    if (currentDataUser.libelleTypeStatut === STATUT_CLOTURE) {
      urlApi = API_ACTE_PRESTATION_DETAILS_PRISE_EN_CHARGE;
    } else {
      urlApi = API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE;
    }

    // update du paramètre
    data.SortOrder = null;

    const sendData = {...data, itemToSearch: {
      ...data.itemToSearch,
      idDemandePriseEnCharge: currentDataUser.idDemandePriseEnCharge,
      InfoSearchidDemandePriseEnCharge: { Consider: true },
    }};
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${urlApi}`, sendData, {...this.postArgsMute});
  }


  recupererPoursuiteActeResRealise(data) {

    // console.log('data : ', data);

   const  sendData = {
      itemToSearch: {
        InfoSearchidBonPriseEnCharge: {Consider: true},
        InfoSearchlibelle: {IsOrderByField: true, Consider: false},
        InfoSearchmontantHT: {IsSumField: true, Consider: false},
        idBonPriseEnCharge: data.idBonPriseEnChargeParent
      }};
    // tslint:disable-next-line:max-line-length
   return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_ACTE_PRESTATION_DETAILS_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
  }
}

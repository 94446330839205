import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {ListDocumentTableListeRestApiService} from './list-document-table.liste.rest-api.service';

@Injectable({providedIn: 'root'})
export class ListDocumentTableListeMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: ListDocumentTableListeRestApiService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  recupererMontantActe(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.recupererMontantActe(data, args);
  }

  recupererMontantPEC(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.recupererMontantPEC(data, args);
  }
}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import * as jwtDecode from 'jwt-decode';
import {FormGroup} from '@angular/forms';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {SecurityStoreService} from '@app/core/states/security/security.store.service';
import {SecurityQueryService} from '@app/core/states/security/security.query.service';
import {EcranVeilleService} from '@app/core/services/ecran-veille.service';
import {RoutingService} from '@app/core/services/routing-service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {ERROR_MESSAGE_NOT_PERMISSION_ACTION, STORAGE} from '@app/core/constantes';
import {AlertService} from '@app/core/services/alert.service';
import {IListGarant} from '@app/core/interfaces/list-garant/i-list-garant';

/**
 * Gère la sécurité de l'application
 */
@Injectable({providedIn: 'root'})
export class SecurityStateService {
    @Autowired() store: SecurityStoreService;
    @Autowired() query: SecurityQueryService;
    @Autowired() ecranVeille: EcranVeilleService;
    @Autowired() routerService: RoutingService;

    @Autowired() alertService: AlertService;

    reloadedAfterTokenExpired = false;

    constructor() {
    }

    /**
     * Vérifie que l'utilisateur connecté possède les permissions indiquées
     *
     * @param claims
     */
    public static currentUserHasPermissions(claims: string | string[]): boolean {
        try {
            let found = false;
            const currentUser = JSON.parse(window.sessionStorage.getItem('currentUser'));
            if (ClvTsUtilsCommonsJs.isUndefinedOrNull(currentUser)) {
                throw new Error('no currentUser define');
            }

            if (ClvTsUtilsCommonsJs.jsType(claims) === 'array') {
                (<string[]>claims).forEach(claim => {
                    const permission = currentUser.permissions.find(v => v === claim);
                    if (!ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(permission)) {
                        found = true;
                    }
                });
                return found;
            }

            const permission2 = currentUser.permissions.find(v => v === claims);
            if (!ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(permission2)) {
                found = true;
            }

            return found;
        } catch (e) {
            return false;
        }
    }

    /**
     * Mets à jours les tentatives de connexions d'un login
     * @param login
     */
    static setTentativesConnexion(login: string) {
        const KEY = 'tmpcnt' + login;
        const _tentatives: string = window.localStorage.getItem(KEY);
        let tentatives = 1;
        if (!_tentatives) {
            window.localStorage.setItem(KEY, tentatives + '');
            return tentatives;
        }
        tentatives = +_tentatives;
        tentatives = tentatives + 1;
        window.localStorage.setItem(KEY, tentatives + '');
        return tentatives;
    }

    /**
     * Réinitialise les tentatives de connexion d'un login
     *
     * @param login
     */
    static resetTentativesConnexion(login: string) {
        const KEY = 'tmpcnt' + login;
        window.localStorage.setItem(KEY, '0');
    }

    /**
     * Recupère les tentatives de connexion d'un login
     *
     * @param login
     */
    static getTentativesConnexion(login: string) {
        const KEY = 'tmpcnt' + login;
        return +window.localStorage.getItem(KEY);
    }

    /**
     * Verifie que le mot de passe et la confirmation du mot de passe sont identiques
     * @param formGroup
     */
    static verifierMotDePasse(formGroup: FormGroup) {
        const pass = formGroup.get('password').value;
        const confirmPass = formGroup.get('confirmPassword').value;
        return pass === confirmPass ? null : {notSame: true};
    }

    /**
     * Enregistre le token de l'utilisateur courant
     *
     * @param token
     */
    saveToken(token: string) {
        window.sessionStorage.setItem('token', token);
        this.store.update({...this.query.__store__._value(), token: token});
    }

    /**
     * Enregistre l'utilisateur courant
     *
     * @param currentUser
     */
    saveUser(currentUser: any) {
        /* if (currentUser && currentUser.groupeUtilisateurs) {
           currentUser.permissions = CommonUtilities.recupererListePermissionsDepuisListeDeGroupesUtilisateurs(currentUser.groupeUtilisateurs);
         }*/
        /*if (currentUser && currentUser.personne && currentUser.personne.logoUrl) {
            currentUser.personne.logoUrl = WebUtilities.baseUrl() + (currentUser.personne.logoUrl).substr(1);
        }*/
        window.sessionStorage.setItem('currentUser', JSON.stringify(currentUser));
        this.store.update({...this.query.__store__._value(), currentUser: currentUser});
    }

    clearSotre() {
        this.query.__store__.destroy();
        this.query.__store__.reset();
    }

    /**
     * recupère le token de l'utilisateur courant
     */
    getToken() {
        return this.query.__store__._value().token;
    }

    /**
     * recupère l'utilisateur courant
     */
    getUser() {
        return this.query.__store__._value().currentUser;
    }

    /**
     * Effectue l'opération d'authentification
     *
     * @param token
     * @param currentUser
     */
    authentification(token: string, currentUser: any) {
        this.saveUser(currentUser);
        this.saveToken(token);
        this.routerService.gotoUrl(['/']);
    }

    /**
     * Déverrouille l'application
     *
     * @param token
     * @param currentUser
     */
    deverouillage(token: string, currentUser: any) {
        this.saveUser(currentUser);
        this.saveToken(token);
        this.ecranVeille.deverrouiller();
    }

    /**
     * Mets en veille l'application
     */
    miseEnVeille() {
        this.saveToken('token');
        this.ecranVeille.verrouiller();
    }

    /**
     * Non utiliser pour l'instant
     */
    deconnexionClient() {
        this.query.__store__.destroy();
        this.query.__store__.reset();

        window.sessionStorage.clear();

        window.localStorage.removeItem(STORAGE.MEDECINS);
        window.localStorage.removeItem(STORAGE.AFFECTIONS);
        window.localStorage.removeItem(STORAGE.USER_CONNECT);

        this.routerService.gotoUrl(['/login']);
        window.location.reload();
    }

    /**
     * Vérifie l'expiration du token
     */
    tokenExpire() {
        try {
            const data = jwtDecode(this.getToken());
            if ((data.exp * 1000) < Date.now()) {
                return true;
            }
            return false;
        } catch (e) {
            return true;
        }
    }

    /**
     * Vérifie que l'utilisateur connecté possède une liste de permissions
     * @param perm
     */
    hasPermission(perm: Array<string>) {
        return CommonUtilities.currentUserHasPermissions(perm);
    }

    /**
     * Initialise l'état
     */
    init() {
        try {
            const token = window.sessionStorage.getItem('token');
            const currentUser = window.sessionStorage.getItem('currentUser');
            const themeApp = window.sessionStorage.getItem('themeApp');
            const listGarant = window.sessionStorage.getItem('listGarant');
            this.store.update({
                token: token,
                currentUser: JSON.parse(currentUser),
                themeApp: JSON.parse(themeApp),
                listGarant: JSON.parse(listGarant),
            });
        } catch (e) {
        }
    }


    /**
     * Enregistre la version et du statut du theme de l'application
     *
     * @param themeApp
     * @param sb_theme
     * @param theme
     */
    saveThemeApp({sb_theme, theme}) {
        // update du thème à utiliser dans le localStorage
        window.localStorage.setItem('sb-theme', sb_theme);
        window.localStorage.setItem('theme', theme);

        // update du thème à utiliser dans la sessionStorage
        window.sessionStorage.setItem('themeApp', JSON.stringify({sb_theme, theme}));
        this.store.update({...this.query.__store__._value(), themeApp: {sb_theme, theme}});

        // rechargement de l'application pour utilisation des nouveaux paramètres
        // window.location.reload();
    }

    /**
     * recupère le token de l'utilisateur courant
     */
    getThemeApp() {
        return this.query.__store__._value().themeApp;
    }


    /**
     * Alert not permission pour cette action
     *
     * @param userPermissionAction string | string[]
     *
     * return boolean
     *
     * true: permission not accept
     * false: permission accept
     */
    alertNotPermissionAction(userPermissionAction: string | string[]): boolean {
        if (!CommonUtilities.currentUserHasPermissions(userPermissionAction)) {
            this.alertService.showError(ERROR_MESSAGE_NOT_PERMISSION_ACTION).show();
            return true;
        }
        return false;
    }


    /**
     * Enregistre les données de list garant pour les vérifications
     *
     * @param
     */
    saveListGarant(
        {
            affectionPrescriptionLier,
            affectionPrescriptionFacultativePrestation,
            horaireAutomatiquePrestation,
            autoriserMontantDette,
            automatiqueActeMontantDette,
            affectionFacultativePrestation,
            gestionBonPhysique,
        }) {

        const listGarant: IListGarant = {
            affectionPrescriptionLier: affectionPrescriptionLier,
            affectionPrescriptionFacultativePrestation: affectionPrescriptionFacultativePrestation,
            horaireAutomatiquePrestation: horaireAutomatiquePrestation,
            autoriserMontantDette: autoriserMontantDette,
            automatiqueActeMontantDette: automatiqueActeMontantDette,
            affectionFacultativePrestation: affectionFacultativePrestation,
            gestionBonPhysique: gestionBonPhysique,
        };

        window.sessionStorage.setItem('listGarant', JSON.stringify(listGarant));
        this.store.update({...this.query.__store__._value(), listGarant: listGarant});
    }

    /**
     * recupère les données de list garant
     */
    getListGarant() {
        return this.query.__store__._value().listGarant;
    }

}

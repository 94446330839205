import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ListFormErrorComponent} from '@shared/components/error/list-form-error/list-form-error.component';
import {FormErrorComponentModule} from '@shared/components/error/form-error/form-error.component.module';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        FormErrorComponentModule,
        TranslateModule,
    ],
    declarations: [
        ListFormErrorComponent,
    ],
    exports: [
        ListFormErrorComponent,
        FormErrorComponentModule,
        TranslateModule,
    ]
})
export class ListFormErrorComponentModule {
}

import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface ILoaderStore {
  count: number;
}

export function loaderDefaultState(): ILoaderStore {
  return {count: 0};
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'loaderState'
})
export class LoaderStoreService extends Store<ILoaderStore> {

  constructor() {
    super(loaderDefaultState());
  }
}

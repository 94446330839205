import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {ActeComplementaireTableRestApiService} from './acte-complementaire-table.rest-api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class ActeComplementaireTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: ActeComplementaireTableRestApiService;

  @Autowired() formBuilder: FormBuilder;

  actesComplementaire: any[] = [];

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaireActeComplementaire<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      libelleActe: !!datas && !!datas.libelleActe ? datas.libelleActe : null,
      quantiteActe: !!datas && !!datas.quantiteActe ? datas.quantiteActe : null,
    };

    return this.formBuilder.group({
      libelleActe: data.libelleActe,
      quantiteActe: [{value: data.quantiteActe, disabled: true}, Validators.required],
    });
  }

  initialiserFormulaireActeSelectedQuantite<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      libelleActe: !!datas && !!datas.libelleActe ? datas.libelleActe : null,
      quantiteActe: !!datas && !!datas.quantiteActe ? datas.quantiteActe : null,
    };

    return this.formBuilder.group({
      libelleActe: data.libelleActe,
      quantiteActe: [{value: data.quantiteActe}, Validators.required],
    });
  }

  initialiserFormulaireDescriptionAll<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      descriptionAll: !!datas && !!datas.description ? datas.description : null,
    };

    return this.formBuilder.group({
      descriptionAll: data.descriptionAll,
    });
  }

  recupererActesComplementaire(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.recupererActesComplementaire(data, args);
  }

}

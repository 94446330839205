import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeAutocompleteComponent} from './acte.autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [ActeAutocompleteComponent],
  exports: [ActeAutocompleteComponent]
})
export class ActeAutocompleteFormModule {
}


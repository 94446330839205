import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {AffectionTableListeRestApiService} from './affection-table.liste.rest-api.service';

@Injectable({providedIn: 'root'})
export class AffectionTableListeMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: AffectionTableListeRestApiService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }
}

import {AfterViewInit, Component, ElementRef, ViewChild, ViewEncapsulation} from '@angular/core';


import * as d3 from 'd3';

import {STOCKS} from './stocks';

@Component({
  selector: 'app-d3-example-feature',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './example.feature.component.html',
  styleUrls: ['./example.feature.component.scss']
})
export class ExampleFeatureComponent implements AfterViewInit {
  @ViewChild('chart', {static: false})
  private chartContainer: ElementRef;

  data = STOCKS;

  margin = {top: 20, right: 20, bottom: 30, left: 40};

  constructor() { }


  ngAfterViewInit(): void {
    if (!this.data) {
      return;
    }
    this.createChart();
  }

  onResize(e) {
    this.createChart();
  }

  private createChart(): void {
    const element = this.chartContainer.nativeElement;
    // Pour effacer le graphe déjà existant
    d3.select(element).selectAll('svg').remove();

    const data = this.data;

    const svg = d3.select(element).append('svg')
    .attr('width', element.offsetWidth)
    .attr('height', element.offsetHeight);

    const contentWidth = element.offsetWidth - this.margin.left - this.margin.right;
    const contentHeight = element.offsetHeight - this.margin.top - this.margin.bottom;

    const x = d3
    .scaleBand()
    .rangeRound([0, contentWidth])
    .padding(0.1)
    .domain(data.map(d => d.letter));

    const y = d3
    .scaleLinear()
    .rangeRound([contentHeight, 0])
    .domain([0, d3.max(data, d => d.value)]);

    const g = svg.append('g')
    .attr('transform', 'translate(' + this.margin.left + ',' + this.margin.top + ')');

    g.append('g')
    .attr('class', 'axis axis--x')
    .attr('transform', 'translate(0,' + contentHeight + ')')
    .call(d3.axisBottom(x));

    g.append('g')
    .attr('class', 'axis axis--y')
    .call(d3.axisLeft(y).ticks(5, ''))
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('y', 6)
    .attr('dy', '0.71em')
    .attr('text-anchor', 'end')
    .text('Valeur');

    g.selectAll('.bar')
    .data(data)
    .enter().append('rect')
    .attr('class', 'bar')
    .attr('x', d => x(d.letter))
    .attr('y', d => y(d.value))
    .attr('width', x.bandwidth())
    .attr('height', d => contentHeight - y(d.value));
  }
}

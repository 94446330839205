/**
 * Utilitaire d'erreurs
 */
import {IErrorApi} from '@app/core/interfaces';
import {EXCEPTIONS} from '@app/core/erreurs/exceptions-erreurs';

export class ErreursUtilities {
  /**
   * Récupérer le message d'érreur en fonction de l'exception backend levée et/ou d'autres paramètres
   *
   * @param erreur
   */
  public static recupererMessageException(erreur: IErrorApi) {
    let cleClass = '';
    if (erreur.exception) {
      const tabs = erreur.exception.split('.');
      cleClass = tabs[tabs.length - 1];
    }
    if (cleClass === 'MethodArgumentNotValidException') {
      let msg = EXCEPTIONS[cleClass];
      if (erreur && erreur.fieldErrors && erreur.fieldErrors.length > 0) {
        erreur.fieldErrors.forEach(field => {
          msg += `<br>- ${this.fieldsMapper(field.field)}`;
        });
      }
      return msg;
    }
    if (cleClass === 'AuthentificationEchouer') {
      if (erreur && erreur.motif) {
        switch (erreur.motif) {
          case 'VERROUILLER':
            return 'Votre compte est verrouillé ! veuillez contacter votre administrateur';
          case 'MODIFIER_MOT_PASSE':
            return 'Veuillez modifier votre mot de passe avant de continuer';
          case 'DEJA_CONNECTE':
            return 'Impossible de vous connecter à nouveau. Une session est déjà ouverte !';
          case 'UTILISATEUR_NON_TROUVE':
            return 'Compte utilisateur non trouvé';
          case 'AUCUN_UTILISATEUR':
            return 'Aucun utilisateur trouvé !';
          case 'PAS_ACTIF':
            return 'Première connexion ! Vous devez modifier votre mot de passe afin de vous connecter.';
        }
      }
    }

    if (EXCEPTIONS[cleClass]) {
      return EXCEPTIONS[cleClass];
    } else {
      return `Une erreur s'est produite. Veuillez contacter votre administrateur. <br><br>
      <strong>Détails:</strong> ${this.removeInutileWordsForUser(erreur.detail)}`;
    }
  }

  /**
   * Libellé des champs intervenant dans le message d'erreur
   *
   * @param field
   */
  private static fieldsMapper(field: string): string {
    switch (field) {
      case 'idDepositaire':
        return 'Dépositaire';

      case 'dateEmission':
        return 'Date émission';

      case 'dateMaturite':
        return 'Date maturité';

      case 'dateCession':
        return 'Date cession';

      case 'idSocieteGestion':
        return 'Société de gestion';

      case 'typeCollecteFond':
        return 'Type de collecte de fond';

      case 'dateConstitution':
        return 'Date de constitution';

      case 'typeOperationId':
        return 'Type opération';

      case 'numeroAgrement':
        return 'Numéro agrément';

      default:
        return field;
    }
  }

  /**
   * Supprime les mots dont l'utilisateur n'a pas besoin.
   *
   * @param message
   */
  public static removeInutileWordsForUser(message: string = ''): string {
    const result = message
    .replace(/composant\./g, '')
    .replace(/com\./g, '')
    .replace(/inexa\./g, '')
    .replace(/atlantis\./g, '')
    .replace(/fctc\./g, '')
    .replace(/core\./g, '')
    .replace(/fonds\./g, '')
    .replace(/application\./g, '')
    .replace(/commande\./g, '')
    .replace(/fasterxml\./g, '')
    .replace(/jackson\./g, '')
    .replace(/databind\./g, '')
    .replace(/entite\./g, '')
    .replace(/exc\./g, '')
    .replace(/domaine\./g, '');
    return result;
  }
}

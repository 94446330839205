import {Component, Input, OnInit} from '@angular/core';
import {PrescriptionTableListeMetierService} from './prescription-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';

@Component({
  selector: 'app-prescription-table-liste',
  templateUrl: './prescription-table.liste.component.html'
})

export class PrescriptionTableListeComponent extends SgiListLayoutComponent implements OnInit{
  @Autowired() recuperationListeDonneesMetier: PrescriptionTableListeMetierService;

  @Input('currentDataUser') currentDataUser: any;
  @Input('affectionPrescriptionLier') affectionPrescriptionLier: boolean;
  @Input() sectionAffichage = '';

  constructor() {
    super();
    this.cols = [
      { field: 'libelle', header: 'Médicament' },
      { field: 'nom', header: 'Nom du prescripteur' },
      { field: 'prenoms', header: 'Prénoms du prescripteur' },
      { field: 'fonctionAdministrative', header: 'Fonction' },
      { field: 'quantite', header: 'Quantité prescrite' },
      { field: 'posologie', header: 'Posologie' },
    ];

    this.tableActions = [];
  }

  ngOnInit(): void {
    if (this.currentDataUser) {
      this.recuperDonnees();
    }
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {
      ...this.args,
      currentDataUser: this.currentDataUser,
      affectionPrescriptionLier: this.affectionPrescriptionLier,
      sectionAffichage: this.sectionAffichage
    };
    this.args = {...arg};
  }

}

import {Component, Input} from '@angular/core';
import {AbstractFormInputs} from '@shared/components/inputs/abstract-form-inputs';

@Component({
  selector: 'app-form-radio',
  template: `
    <div class="input-container">
<!--      <label for="{{id}}">{{label}}&nbsp;<span *ngIf="required" class="danger-color">*</span></label>-->
      <div class="">
<!--        <p-radioButton name="group1" value="Option 1" label="Option 1" [(ngModel)]="val1" inputId="opt1"></p-radioButton>-->
        <p-radioButton id="{{id}}"
               [label]="label"
               [value]="value"
               [name]="name"
               autocomplete="off"
                       (change)="onChange?.emit($event)"
                       (onBlur)="onBlur?.emit($event)"
                       (onClick)="onClick?.emit($event)"
                       (onFocus)="onFocus?.emit($event)"
                       (show)="onShow?.emit($event)"
               [formControl]="control"
               [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
               [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"></p-radioButton>
      </div>
      <app-list-form-error [control]="control"
                           [types]="errorTypes"
                           [submitted]="submitted"
                           [messages]="messages"></app-list-form-error>
    </div>`
})
export class FormRadioComponent extends AbstractFormInputs {
  @Input('name') name = Date.now();
  @Input('value') value = null;
}

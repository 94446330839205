import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrescriptionMaladieChroniqueTableMetierService} from './prescription-maladie-chronique-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-prescription-maladie-chronique-table',
  templateUrl: './prescription-maladie-chronique-table.component.html'
})

export class PrescriptionMaladieChroniqueTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: PrescriptionMaladieChroniqueTableMetierService;
  @Autowired() metierService: PrescriptionMaladieChroniqueTableMetierService;

  @Input() currentRow: any;
  @Input() typeAffichage: any;
  @Input() responsePrescriptionsMaladieChronique: any;
  @Output() responsePrescriptionsMaladieChroniqueEvent = new EventEmitter<any>();

  formGroup: FormGroup;
  typeRechercheCombo: any[] = [];
  placeholderInputSearch: string;


  constructor() {
    super();
    this.cols = [
      {field: 'libelleMedicament', header: 'Medicament'},
      {field: 'nomCompletPersonneMedecin', header: 'Medecin'},
      {field: 'dateDebut', header: 'Date Debut', type: () => 'date'},
      {field: 'dateFin', header: 'Date Fin', type: () => 'date'},
      {field: 'libellePeriodicite', header: 'Periodicite'},
      {field: 'frequencePeriodicite', header: 'Freq Periodicite'},
      {field: 'quantite', header: 'Qte'},
      {field: 'statut', header: 'Statut'},
    ];
    this.tableActions = [];
    this.notRowActions = [];
  }

  ngOnInit(): void {
    this.initialiserTypeRechercheCombo();

    this.initFormulaire();

    this.formatTableListAddIndex(this.responsePrescriptionsMaladieChronique);
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args};
    this.args = {...arg};
  }

  recuperationDonnees(data?: any) {
    const da = {
      ...this.args, lazyLoadEvent: data,
      successMute: true,
      currentRow: this.currentRow,
      typeAffichage: this.typeAffichage
    };
    this.args = {...da};

    // tslint:disable-next-line:max-line-length
    this.metierService.requeteRecuperationListeDonnees(this.getAllRequestParam(), {...this.args}).subscribe((r_PerscriptionsMaladieChronique) => {
      const responsePrescriptionsMaladieChronique = r_PerscriptionsMaladieChronique.body;

      this.formatTableListAddIndex(responsePrescriptionsMaladieChronique);

      this.responsePrescriptionsMaladieChroniqueEvent.emit(r_PerscriptionsMaladieChronique.body);
    });
  }

  formatTableListAddIndex(responseData) {
    if (responseData === undefined) {
      this.dataSource = [];
      this.totalRecords = 0;
      return;
    }
    this.dataSource = responseData.items;
    this.totalRecords = responseData.count;
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach((dat, index) => {
        dat.id_key = index + 1;
      });
    }
  }

  initFormulaire() {
    this.formGroup = this.metierService.initialiserFormulaire(null);
  }

  initialiserTypeRechercheCombo() {
    this.placeholderInputSearch = 'Rechercher';

    if (this.typeAffichage === 'liste') {
      this.typeRechercheCombo = [
        { label: 'Medicament', value: 'medicament'},
        { label: 'Medecin', value: 'medecin'},
        { label: 'Freq Periodicite', value: 'freq_periodicite'},
        { label: 'Statut', value: 'statut'},
      ];
    }
    if (this.typeAffichage === 'historique') {
      this.typeRechercheCombo = [
        { label: 'Medicament', value: 'medicament'},
        { label: 'Medecin', value: 'medecin'},
        { label: 'Freq Periodicite', value: 'freq_periodicite'},
      ];
    }
  }

  handleChangeTypeRecherche(event: any) {
    if (event.value === null) {
      this.placeholderInputSearch = 'Rechercher';

      this.formGroup.patchValue({ inputSearch: null });

      // fonction de recherche assuré avec les valeurs null
      this.recuperationDonnees();
    }

    if (event.value === 'medicament') {
      this.placeholderInputSearch = 'Rechercher par médicament';
    }
    if (event.value === 'medecin') {
      this.placeholderInputSearch = 'Rechercher par médecin';
    }
    if (event.value === 'freq_periodicite') {
      this.placeholderInputSearch = 'Rechercher par fréquence périodicite';
    }
    if (event.value === 'statut') {
      this.placeholderInputSearch = 'Rechercher par statut';
    }

    this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue());
  }

  rechercheItems(event) {
    if (this.formGroup.getRawValue().selectTypeSearch === null) { return false; }

    // fonction de recherche assuré avec les valeurs entrées
    this.recuperationDonnees();
  }

}

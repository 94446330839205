import {Injectable} from '@angular/core';
import {ConnexionRestApiService} from './connexion.rest-api.service';
import {AppSettings} from '@app/app.settings';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

/**
 * Service d'évènements de l'application
 */
@Injectable({providedIn: 'root'})
export class EventsService {

  constructor(public connexionRestApiService: ConnexionRestApiService,
              public settings: AppSettings, public securityState: SecurityStateService) {
    this.init();
  }

  init() {
    // this.rechargementApresInvalidationDuToken();
    this.deconnexionALaFermeture();
  }

  /**
   * Lance une requête de déconnexion lorsque l'utilisateur ferme la fenêtre du navigateur ou le navigateur.
   * Nb: Cette fonctionnalité ne fonctionne pas correctement.
   */
  private deconnexionALaFermeture() {
    window.addEventListener('beforeunload', (e) => {
      // e.returnValue = '';
      e.preventDefault();
      this.connexionRestApiService.deconnexionALaFermeture();
      delete e.returnValue;
    });
  }

  private rechargementApresInvalidationDuToken() {
    setInterval(() => {
      if (this.securityState.tokenExpire()) {
        if (!this.securityState.reloadedAfterTokenExpired) {
          window.location.reload();
          this.securityState.reloadedAfterTokenExpired = true;
        }
      }
    }, 20000);
  }
}

import {Injectable, SkipSelf} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {Autowired} from '@angular-ru/autowired';
import {AppSettings} from '@app/app.settings';
import {LanguageStateService} from '@app/core/states/language/language.state.service';

/**
 * Format d'une langue
 */
export interface LanguageFlag {
    /**
     * Code de la langue
     */
    lang: string;

    /**
     * Pays de la langue
     */
    pays: string;

    /**
     * Url du drapeau
     */
    drapeau: string;
    actif?: boolean;
}

/**
 * Service de traduction. Recupère les fichiers de langues et initie les configurations de la traduction
 */
@Injectable({providedIn: 'root'})
export class TraductionService {
    /**
     * Langue active
     */
    public language: LanguageFlag;

    /**
     * Liste des langues
     */
    public languages: LanguageFlag[];

    /**
     * Service de translation (librairie)
     */
    @Autowired() translate: TranslateService;

    /**
     * Service de configuration de l'application
     */
    @Autowired() settings: AppSettings;

    /**
     * Service d'état de la langue active
     */
    @Autowired() languageStateService: LanguageStateService;

    /**
     * Service de requêtes Http
     */
    @SkipSelf() @Autowired() httpClient: HttpClient;

    constructor() {
        if (AppSettings.configs) {
            this.languages = <LanguageFlag[]>AppSettings.configs.language.flags;
            this.language = this.languages.find(v => v.lang === this.languageStateService.getLanguage());
            this.translate.setDefaultLang(AppSettings.configs.language.default);
        }
    }

    /**
     * Recupère une langue dans la liste des langues chargées à partir de son code.
     * @param code
     */
    getGoodLangue(code: string): LanguageFlag {
        try {
            return this.languages.find(v => v.lang === code);
        } catch (e) {
            return this.languages.find(v => v.lang === this.settings.settings.defaultLanguage);
        }
    }

    /**
     * Charge tout les fichiers de traduction se trouvant dans le dossier /src/assets/i18n/
     */
    public autoLoadTranslations(): void {
        if (AppSettings.configs) {
            this.translate.addLangs(AppSettings.configs.language.list);
            (<string[]>AppSettings.configs.language.list).forEach(lang => {
                this.httpClient.get(`../../assets/i18n/${lang}.json`).toPromise().then((resp: any) => {
                    this.translate.setTranslation(lang, resp.data, true);
                });
            });
        }
    }
}

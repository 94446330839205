import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormCalendarComponent} from '@shared/components/inputs/form-calendar/form-calendar.component';
import {CalendarModule} from 'primeng/calendar';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {ListFormErrorComponentModule} from '@shared/components/error/list-form-error/list-form-error.component.module';

@NgModule({
    imports: [
        CommonModule,
        CalendarModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        InfoBullWidgetComponentModule,
        ListFormErrorComponentModule,
    ],
    declarations: [
        FormCalendarComponent,
    ],
    exports: [
        FormCalendarComponent,
        CalendarModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        InfoBullWidgetComponentModule,
        ListFormErrorComponentModule,
    ]
})
export class FormCalendarComponentModule {
}

import {Directive, ElementRef, Input, OnInit} from '@angular/core';
// import {LinqService} from 'ng2-linq';
import {select, Store} from '@ngrx/store';
import {Observable} from 'rxjs';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DirectiveLazyUtilities} from '@app/core/utilities/directive-lazy.utilities';

/*<!-- [appHasPermission]='{ "value" : "permissionCode", "type" : "disabled" }'
 *     [appHasPermission]='{ "value" : "permissionCode", "type" : "hidden" }'
 *     [appHasPermission]="permissionCode"
 *     [appHasPermission]="[permissionCode]"
 *     [appHasPermission]="!permissionCode"
 * */
@Directive({
    selector: '[appHasPermission]',
})
export class HasPermissionDirective implements OnInit {
    user$: Observable<any>;

    constructor(private el: ElementRef,
                // private linq: LinqService,
                private userStore: Store<{user: any}>
    ) {
        this.user$ = userStore.pipe(select('user'));
    }

    // permissions: any = Security.getCurrentUser();

    @Input() default: string;

    @Input('appHasPermission') claim: any;


    ngOnInit(): void {
        DirectiveLazyUtilities.inView(this.el.nativeElement).subscribe(inv => {
            if (inv) {
                if (!SecurityStateService.currentUserHasPermissions(this.claim.value)) {
                    if (this.claim && this.claim.type) {
                        this.disableElement(this.claim.type);
                    } else {
                        this.disableElement('hidden');
                    }
                }
            }
        });
    }


    private disableElement(type: string) {
        switch (type) {
            case 'hidden':
                this.el.nativeElement.style.display = 'none';
                break;
            case 'disabled':
                this.el.nativeElement.disabled = true;
                break;
            default:
                // this.el.nativeElement.style.display = 'none';
                this.el.nativeElement.remove();
        }
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {AppSettings} from '@app/app.settings';
import {ConnexionRestApiService} from '@app/fonctionnalites/authentification/connexion/connexion.rest-api.service';
import {EcranVeilleService} from '@app/core/services/ecran-veille.service';
import {HorlogeService} from '@app/core/services/horloge.service';
import {zoomerEnEntrantAnimation} from '@app/core/animations/animations';

@Component({
  selector: 'app-deconnexion-x-time-alert',
  template: `
  <div class="deconnexion" [ngClass]="{'hidden': !show}">
    <div class="backdrop"></div>
    <div class="dec-card-container">
      <div class="dec-card-cont">
        <p-card [styleClass]="'bg-e2'">
          <div class="message">{{'Vous serez déconnecté dans:'}}</div>
          <app-deminuteur [heures]="heures"
                          [minutes]="minutes"
                          [secondes]="secondes"
                          (timeUp)="handleTimeUp($event)"
                          (time)="handleTime($event)"></app-deminuteur>
          <div class="message">{{'Cliquer sur le bouton Annuler pour empêcher la fermeture'}}</div>
          <br>
          <app-cancel-button (click)="redefinirTime()"></app-cancel-button>
        </p-card>
      </div>
    </div>
  </div>
  `,
  styles: [`
      .deconnexion {
        display: flex;
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 1;
      }
      .backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0,0,0,.4);
        z-index: 0;
      }
      .dec-card-container {
        display: flex;
        align-self: center;
        justify-content: center;
        width: 100%;
        z-index: 1;
        text-align: center;
      }
      .message {
        font-size: 1.2rem;
        font-weight: 600;
      }
      .dec-card-cont {
        -moz-animation: zoomAllerRetour 10s infinite linear;
        -o-animation: zoomAllerRetour 10s infinite linear;
        -webkit-animation: zoomAllerRetour 10s infinite linear;
        animation: zoomAllerRetour 10s infinite linear;
      }
  `],
  animations: [zoomerEnEntrantAnimation()]
})

export class DeconnexionXTimeAlertComponent implements OnInit {
  @Autowired() appSettings: AppSettings;
  @Autowired() connexionRestApiService: ConnexionRestApiService;
  @Autowired() ecranVeilleService: EcranVeilleService;

  show = false;
  heures: number;
  minutes: number;
  secondes: number;
  statTime: {h: number, m: number, s: number} = {h: 0, m: 1, s: 0};
  @Input('startTime') set statTimes(ti: {h: number, m: number, s: number}) {
    this.statTime.h = ti && ti.h ? ti.h : 0;
    this.statTime.m = ti && ti.m ? ti.m : 1;
    this.statTime.s = ti && ti.s ? ti.s : 0;
  }
  constructor() {
  }

  ngOnInit() {
    this.redefinirTime();

    window.addEventListener('click', () => {
      this.redefinirTime();
    });
    window.addEventListener('mousemove', () => {
      this.redefinirTime();
    });
    window.addEventListener('keypress', () => {
      this.redefinirTime();
    });
  }

  /**
   * Recupere le temps initiale du compteur et le rédefini
   */
  redefinirTime() {
    const time = HorlogeService.convertSecondsToHourMinutesSeconds(this.appSettings.settings.deconnexionApresXSecondes);
    this.reinitTime(time.hours, time.minutes, time.secondes);
  }

  /**
   * Réinitialise le decompteur
   * @param h
   * @param m
   * @param s
   */
  private reinitTime(h: number, m: number, s: number) {
    this.heures = undefined;
    this.minutes = undefined;
    this.secondes = undefined;
    setTimeout(() => {
      this.heures = h;
      this.minutes = m;
      this.secondes = s;
    }, 10);
  }

  handleTime(event: {h: number, m: number, s: number}) {
    if ( (this.statTime.h * 3600 + this.statTime.m * 60 + this.statTime.s >=
        event.h * 3600 + event.m * 60 + event.s)
    ) {
      this.show = true;
    } else {
      this.show = false;
    }
  }

  handleTimeUp(event) {
    this.show = false;
    // this.ecranVeilleService.verrouiller();
    this.connexionRestApiService.deconnexionVerouillage();
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrescriptionRealiseTableMetierService} from './prescription-realise-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {TableAction} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {
  ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE,
} from '@app/core/constantes';

@Component({
  selector: 'app-prescription-realise-table',
  templateUrl: './prescription-realise-table.component.html'
})

export class PrescriptionRealiseTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: PrescriptionRealiseTableMetierService;
  @Autowired() metierService: PrescriptionRealiseTableMetierService;

  @Input() currentRow: any;
  @Input() responsePrescriptionsRealise: any;
  @Output() responsePrescriptionsRealiseEvent = new EventEmitter<any>();

  formGroup: FormGroup;
  typeRechercheCombo: any[] = [];
  placeholderInputSearch: string;
  tableSelectedElement: any;


  constructor() {
    super();
    this.cols = [
      {field: 'dateRealisation', header: 'Date de réalisation', type: () => 'date'},
      {field: 'libelle', header: 'Libelle'},
      {field: 'posologie', header: 'Posologie'},
      {field: 'quantite', header: 'Quantité'},
      {field: 'nomOrigine', header: 'Médicament'},
    ];
    this.tableActions = [];
    this.notRowActions = [];
  }

  ngOnInit(): void {
    this.initialiserTypeRechercheCombo();

    this.initFormulaire();

    this.formatTableListAddIndex(this.responsePrescriptionsRealise);
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args};
    this.args = {...arg};
  }

  recuperationDonnees(data?: any) {
    const da = {...this.args, lazyLoadEvent: data, successMute: true, currentRow: this.currentRow};
    this.args = {...da};
    this.metierService.requeteRecuperationListeDonnees(this.getAllRequestParam(), {...this.args}).subscribe((r_PrescriptionsRealise) => {
      const responsePrescriptionsRealise = r_PrescriptionsRealise.body;

      this.formatTableListAddIndex(responsePrescriptionsRealise);

      this.responsePrescriptionsRealiseEvent.emit(r_PrescriptionsRealise.body);
    });
  }

  formatTableListAddIndex(responseData) {
    if (responseData === undefined) {
      this.dataSource = [];
      this.totalRecords = 0;
      return;
    }
    this.dataSource = responseData.items;
    this.totalRecords = responseData.count;
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach((dat, index) => {
        dat.id_key = index + 1;
      });
    }
  }

  initFormulaire() {
    this.formGroup = this.metierService.initialiserFormulaire(null);
  }

  initialiserTypeRechercheCombo() {
    this.placeholderInputSearch = 'Rechercher';

    this.typeRechercheCombo = [
      { label: 'Libelle', value: 'libelle'},
      { label: 'Quantité', value: 'quantite'},
      { label: 'Date de réalisation', value: 'date_realisation'},
      { label: 'Médicament', value: 'medicament'},
    ];
  }

  handleChangeTypeRecherche(event: any) {
    if (event.value === null) {
      this.placeholderInputSearch = 'Rechercher';

      this.formGroup.patchValue({ inputSearch: null });

      // fonction de recherche assuré avec les valeurs null
      this.recuperationDonnees();
    }

    if (event.value === 'libelle') {
      this.placeholderInputSearch = 'Rechercher par libelle';
    }
    if (event.value === 'quantite') {
      this.placeholderInputSearch = 'Rechercher par quantité';
    }
    if (event.value === 'date_realisation') {
      this.placeholderInputSearch = 'Rechercher par date';
    }
    if (event.value === 'medicament') {
      this.placeholderInputSearch = 'Rechercher par médecin';
    }

    this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue());
  }

  rechercheItems(event) {
    if (this.formGroup.getRawValue().selectTypeSearch === null) { return false; }

    // fonction de recherche assuré avec les valeurs entrées
    this.recuperationDonnees();
  }

}

import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {Store} from '@ngrx/store';
import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {ListLayoutComponent} from '@shared/behaviors/list-layout/list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
    selector: 'app-csv-preview',
    template: `
        <p-table #dt [style]="{width:'100%'}" scrollHeight="{{scrollHeight}}"
                 [value]="dataSource"
                 [scrollable]="true"
                 [columns]="cols"
                 [paginator]="false"
                 [rows]="listPerPage">
            <ng-template pTemplate="header" let-columns>
                <tr>
                    <th *ngFor="let column of cols" [pSortableColumn]="column?.field">{{column?.header}}
                        <p-sortIcon [field]="column.field"
                                    ariaLabel="Activate to sort"
                                    ariaLabelDesc="Activate to sort in descending order"
                                    ariaLabelAsc="Activate to sort in ascending order"></p-sortIcon>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex"
                         let-expanded="expanded" let-columns="columns">
                <tr [pEditableRow]="rowData">
                    <td *ngFor="let column of cols">
                        <div *ngIf="isString(rowData[column.field])" [innerHTML]="rowData[column.field]"></div>
                        <div *ngIf="!isString(rowData[column.field])" [innerHTML]="rowData[column.field]"></div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    `
})
export class CsvPreviewComponent extends ListLayoutComponent implements OnInit {
    @Input('scrollHeight') scrollHeight = '250px';
    @Autowired() listDisplayModeStore: Store<{ listDisplayMode: string }>;
    @Autowired() listPerPageStore: Store<{ perPage: number }>;

    ngOnInit(): void {
        this.cols = CommonUtilities.buildPrimeNgTableColumnsByObject(this.dataSource[0]);
    }

    isString(value: any): boolean {
        return ClvTsUtilsCommonsJs.jsType(value) === 'string';
    }
}

import {NgModule} from '@angular/core';
import {ClvTsUtilsComponent} from './clv-ts-utils.component';

@NgModule({
  declarations: [ClvTsUtilsComponent],
  imports: [
  ],
  exports: [ClvTsUtilsComponent]
})
export class ClvTsUtilsModule { }

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {ModaleLierBordereauFeatureComponent} from './modale-lier-bordereau.feature.component';
import {LierBordereauModaleListeFeatureModule} from "@app/fonctionnalites/factures/prise-en-charge/lier-bordereau-modale-liste/lier-bordereau-modale-liste.feature.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        LierBordereauModaleListeFeatureModule,
    ],
    exports: [
        ModaleLierBordereauFeatureComponent,
    ],
    declarations: [
        ModaleLierBordereauFeatureComponent,
    ],
  providers: [],
})
export class ModaleLierBordereauFeatureModule {
}

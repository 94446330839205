import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {DemandeHospitalisationFormComponent} from '@app/fonctionnalites/demande-pec/composants/demande-hospitalisation-form/demande-hospitalisation-form.component';
import {MedecinFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/medecin/form/medecin.form.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MedecinFormModule,
    ],
  exports: [DemandeHospitalisationFormComponent],
  declarations: [DemandeHospitalisationFormComponent],
  providers: [],
})
export class DemandeHospitalisationFormModule {
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {ModaleClotureComponent} from './modale-cloture.component';
import {SharedModule} from '../../../../shared/shared.module';
import {InformationAssureTableModule} from '../../composants/information-assure-table/information-assure-table.module';
import {MedecinActeTableModule} from '../../composants/medecin-acte-table/medecin-acte-table.module';
import {ActeComplementaireTableModule} from '../../composants/acte-complementaire-table/acte-complementaire-table.module';
import {ListAffectionTableListeModule} from '../../demande-prise-charge/form/list-affection-table/liste/list-affection-table.liste.module';
import {PrescriptionTableModule} from '../../composants/prescription-table/prescription-table.module';
import {HospitalisationAutreTableModule} from '../../composants/hospitalisation-autre-table/hospitalisation-autre-table.module';
import {DemandePecDocumentModule} from '@app/fonctionnalites/demande-pec/composants/demande-pec-document/demande-pec-document.module';
import {ListAffectionPrescriptionTableListeModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/list-affection-prescription-table/liste/list-affection-prescription-table.liste.module';
import {ResultatExamenDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/resultat-examen-dossier-table/resultat-examen-dossier-table.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        InformationAssureTableModule,
        MedecinActeTableModule,
        ActeComplementaireTableModule,
        ListAffectionTableListeModule,
        PrescriptionTableModule,
        HospitalisationAutreTableModule,
        DemandePecDocumentModule,
        ListAffectionPrescriptionTableListeModule,
        ResultatExamenDossierTableModule,
    ],
    exports: [
        ModaleClotureComponent,
    ],
    declarations: [
        ModaleClotureComponent,
    ],
  providers: [],
})
export class ModaleClotureModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrescriptionTableMetierService} from './prescription-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {TableAction} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE,} from '@app/core/constantes';

@Component({
    selector: 'app-prescription-table',
    templateUrl: './prescription-table.component.html'
})

export class PrescriptionTableComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: PrescriptionTableMetierService;
    @Autowired() metierService: PrescriptionTableMetierService;

    @Input() currentRow: any;
    @Input() responsePrescriptions: any;
    @Output() responsePrescriptionsEvent = new EventEmitter<any>();

    formGroup: FormGroup;
    typeRechercheCombo: any[] = [];
    placeholderInputSearch: string;
    tableSelectedElement: any;

    finResponse: boolean;

    /**
     * Boutton clôture demande
     */
    medecinTraitantAction: TableAction = {
        type: 'button', title: 'Médecin traitant', flat: false, icon: 'memory', notFlatClass: '', actionPerformance: (data) => {
            this.medecinTraitant(data);
        }, canShow: (data) => true
    };

    constructor() {
        super();
        this.cols = [
            {field: 'libelle', header: 'Libelle '},
            {field: 'posologie', header: 'Posologie '},
            {field: 'quantite', header: 'Quantité '},
            {field: 'datePrestation', header: 'Date de prescription', type: () => 'date'},
            {field: 'nomCompletMedecin', header: 'Médecin'},
        ];
        this.tableActions = [];
        this.notRowActions = [
            this.medecinTraitantAction,
        ];
    }

    ngOnInit(): void {
        this.initialiserTypeRechercheCombo();

        this.initFormulaire();

        this.formatTableListAddIndex(this.responsePrescriptions);
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    recuperationDonnees(data?: any) {
        const da = {...this.args, lazyLoadEvent: data, successMute: true, currentRow: this.currentRow};
        this.args = {...da};
        this.metierService.requeteRecuperationListeDonnees(this.getAllRequestParam(), {...this.args}).subscribe((r_Prescriptions) => {
            const responsePrescriptions = r_Prescriptions.body;

            this.formatTableListAddIndex(responsePrescriptions);

            this.responsePrescriptionsEvent.emit(r_Prescriptions.body);
        });
    }

    formatTableListAddIndex(responseData) {
        if (responseData === undefined) {
            this.dataSource = [];
            this.totalRecords = 0;
            return;
        }
        this.dataSource = responseData.items;
        this.totalRecords = responseData.count;
        if (this.dataSource && this.dataSource.length > 0) {
            this.dataSource.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }
    }

    initFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }

    initialiserTypeRechercheCombo() {
        this.placeholderInputSearch = 'Rechercher';

        this.typeRechercheCombo = [
            {label: 'Libelle', value: 'libelle'},
            {label: 'Quantité', value: 'quantite'},
            {label: 'Date de prescription', value: 'date_prescription'},
            {label: 'Médecin', value: 'medecin'},
        ];
    }

    handleChangeTypeRecherche(event: any) {
        if (event.value === null) {
            this.placeholderInputSearch = 'Rechercher';

            this.formGroup.patchValue({inputSearch: null});

            // fonction de recherche assuré avec les valeurs null
            this.recuperationDonnees();
        }

        if (event.value === 'libelle') {
            this.placeholderInputSearch = 'Rechercher par libelle';
        }
        if (event.value === 'quantite') {
            this.placeholderInputSearch = 'Rechercher par quantité';
        }
        if (event.value === 'date_prescription') {
            this.placeholderInputSearch = 'Rechercher par date';
        }
        if (event.value === 'medecin') {
            this.placeholderInputSearch = 'Rechercher par médecin';
        }

        this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue());
    }

    rechercheItems(event) {
        if (this.formGroup.getRawValue().selectTypeSearch === null) {
            return false;
        }

        // fonction de recherche assuré avec les valeurs entrées
        this.recuperationDonnees();
    }


    /**
     *
     */

    medecinTraitant(data: any) {
        let alertMessage: any;

        if (!this.tableSelectedElement) {
            alertMessage = this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE);
            alertMessage.show();
            return;
        }

        this.gestionnaireDeModales[0].id = 'modale_medecin_traitant';
        this.gestionnaireDeModales[0].datasourceSelected = {
            tableSelectedElement: this.tableSelectedElement,
        };
        this.gestionnaireDeModales[0].display = true;
    }

    finResponseEvent(event) {
        this.finResponse = event;
    }

}

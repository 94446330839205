import {Component} from '@angular/core';
import {PageLayoutComponent} from '@shared/behaviors/page-layout/page-layout.component';

@Component({
    selector: 'app-profil-utilsateur',
    templateUrl: './profil-utilisateur.component.html',
})
export class ProfilUtilisateurComponent extends PageLayoutComponent {

    constructor() {
        super();
        this.title = 'Profil utilisateur';
        this.icon = 'person';
        this.hasToolbar = false;
    }
}

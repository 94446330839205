import {Injectable} from '@angular/core';
import {IRestApi} from '@app/core/interfaces/rest/i-rest-api';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {
    API_RECUPERER_DETAILS_FACTURE,
    API_MIDIFIER_FACTURE
} from '@app/core/constantes';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {OperatorEnum} from '@app/core/utilities/operators.utilities';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ModaleRessaisirFactureRestApiService extends SecurityStateService implements IRestApi {

    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;

    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
    }

    requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restService.putRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
    }

    requeteSuppression(data: any, args?: any): any {
        return this.restService.putRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur`, data);
    }

    requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
        let id = null;
        try {
            id = data.id ? data.id : null;
        } catch (e) {
        }
        return this.restService.getRequest(`${WebUtilities.baseUrlApi()}groupe-utilisateur/${id}`);
    }

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {

        let sendData: any;

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${''}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (!(response.body.items.length > 0)) {
                        return response;
                    }
                    return response;
                })
            );
    }

    recupererDonneesFacture(data) {
        const sendData = {
            ItemToSearch: data
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_DETAILS_FACTURE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (!(response.body.items.length > 0)) {
                        return response;
                    }
                    return response;
                })
            );
    }

    updateFacture(data) {
        const sendData = {
            ItemsToSave: [data]
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_MIDIFIER_FACTURE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (!(response.body.items.length > 0)) {
                        return response;
                    }
                    return response;
                })
            );
    }

}


import {Component, Input, OnInit} from '@angular/core';
import {ActeRealiseDetailsTableListeMetierService} from './acte-realise-details-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-acte-realise-details-table-liste',
    templateUrl: './acte-realise-details-table.liste.component.html'
})

export class ActeRealiseDetailsTableListeComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: ActeRealiseDetailsTableListeMetierService;
    @Autowired() metierService: ActeRealiseDetailsTableListeMetierService;

    @Input('currentDataUser') currentDataUser: any = {};

    constructor() {
        super();
        this.tableActions = [];

    }


    ngOnInit(): void {
        this.cols = [
            {field: 'libelle', header: 'Libellé'},
            {field: 'montantHT', header: 'Montant'}, // , type: () => 'price'
            {field: 'partAssureur', header: 'Part Assureur'},
            {field: 'partAssure', header: 'Part assuré'}, // , type: () => 'price'
            {
                field: 'dateRealisation', header: 'Date de réalisation', composedField: (data) => {
                    const datePipe = new DatePipe('en-US');
                    return datePipe.transform(data['dateRealisation'], 'dd/MM/yyyy H:mm:s');
                }
            }, // , type: () => 'price'
        ];
        this.listPoursuiteActeResRealise();
    }

    listPoursuiteActeResRealise() {
        this.metierService.recupererPoursuiteActeResRealise(this.currentDataUser).subscribe(response => {
            this.dataSource = response.body.items;
        });
    }

}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {PersonneMedicalTableRestApiService} from './personne-medical-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class PersonneMedicalTableMetierService implements IRecuperationListeDonneesMetier {
    @Autowired() restApi: PersonneMedicalTableRestApiService;
    @Autowired() formBuilder: FormBuilder;

    @Autowired() datePipe: DatePipe;


    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteRecuperationListeDonnees(data, args);
    }

    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            typePersonnelMedical: !!datas && !!datas.libelleTypePersonnelMedical ? datas.libelleTypePersonnelMedical : null,
            nom: !!datas && !!datas.nom ? datas.nom : null,
            prenoms: !!datas && !!datas.prenoms ? datas.prenoms : null,
            dateNaissance: !!datas && !!datas.dateNaissance ? this.datePipe.transform(datas.dateNaissance, 'dd/MM/yyyy') : null,
            sexe: !!datas && !!datas.sexe ? datas.sexe : null,
            civilite: !!datas && !!datas.civilite ? datas.civilite : null,
            profession: !!datas && !!datas.profession ? datas.profession : null,
            fonction: !!datas && !!datas.fonction ? datas.fonction : null,
            situationMatrimoniale: !!datas && !!datas.situationMatrimoniale ? datas.situationMatrimoniale : null,
            typePiece: !!datas && !!datas.typePiece ? datas.typePiece : null,
            numeroPiece: !!datas && !!datas.numeroPiece ? datas.numeroPiece : null,
            numeroOrdre: !!datas && !!datas.matricule ? datas.matricule : null,
            grade: !!datas && !!datas.grade ? datas.grade : null,
        };

        return this.formBuilder.group({
            typePersonnelMedical: [{value: data.typePersonnelMedical, disabled: true}],
            nom: [{value: data.nom, disabled: true}],
            prenoms: [{value: data.prenoms, disabled: true}],
            dateNaissance: [{value: data.dateNaissance, disabled: true}],
            sexe: [{value: data.sexe, disabled: true}],
            civilite: [{value: data.civilite, disabled: true}],
            profession: [{value: data.profession, disabled: true}],
            fonction: [{value: data.fonction, disabled: true}],
            situationMatrimoniale: [{value: data.situationMatrimoniale, disabled: true}],

            typePiece: [{value: data.typePiece, disabled: true}],
            numeroPiece: [{value: data.numeroPiece, disabled: true}],
            numeroOrdre: [{value: data.numeroOrdre, disabled: true}],
            grade: [{value: data.grade, disabled: true}],
        });
    }

}

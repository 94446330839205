import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {AlertService} from '@app/core/services/alert.service';
import {ConnexionMetierService} from '@app/fonctionnalites/authentification/connexion/connexion.metier.service';

@Component({
    selector: 'app-reset-password-form',
    templateUrl: './reset-password-form.component.html',
    styleUrls: ['./reset-password-form.component.scss']
})
export class ResetPasswordFormComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() formBuilder: FormBuilder;
    @Autowired() metierService: ConnexionMetierService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() alertService: AlertService;

    initGroupForm: FormGroup;
    @Output() newPasswordEvent = new EventEmitter<any>();
    @Output() confirmNewPasswordEvent = new EventEmitter<any>();


    constructor() {
        super();
    }

    ngOnInit() {
        this.initFormulaire();
    }

    initFormulaire() {
        this.initGroupForm = this.metierService.initialiserFormulaireResetPassword(null);
    }

    onchangeNewPassword(event) {
        this.newPasswordEvent.emit({event: event.target.value, formGroupValid: this.initGroupForm.valid});
    }

    onchangeConfirmNewPassword(event) {
        this.confirmNewPasswordEvent.emit({event: event.target.value, formGroupValid: this.initGroupForm.valid});
    }
}

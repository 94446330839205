import {Component, Input, OnInit} from '@angular/core';
import {DetailsDocumentTableListeMetierService} from './details-document-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {BOOLEAN_TYPE_COMBO} from '@app/core/constantes';

@Component({
  selector: 'app-details-document-table-liste',
  templateUrl: './details-document-table.liste.component.html'
})

export class DetailsDocumentTableListeComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: DetailsDocumentTableListeMetierService;
  @Autowired() metierService: DetailsDocumentTableListeMetierService;

  @Input('currentDataUser') currentDataUser: any = {};
  dataSourceLength: any;

  constructor() {
    super();
    this.tableActions = [];

  }


  ngOnInit(): void {
    this.cols = [
      { field: 'libelle', header: 'Type' },
      { field: 'observations', header: 'Titre' },
    ];
    this.ListPoursuiteDocument()
  }


  ListPoursuiteDocument() {
    this.metierService.recupererPoursuiteDocument(this.currentDataUser).subscribe(response => {
      this.dataSource = response.body.items
      this.dataSourceLength = response.body.items.length
      this.dataSource.forEach((data, index) => {
        data.index = index + 1;
        this.dataSourceLength++;
      });
    });
  }

}

import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {AlertService} from '@app/core/services/alert.service';
import {AUCUN_MATRICULE_SAISI, AUCUNE_DONNEE_TROUVEE, STORAGE} from '@app/core/constantes';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
    selector: 'app-demande-hospitalisation-form',
    templateUrl: './demande-hospitalisation-form.component.html',
    styleUrls: ['./demande-hospitalisation-form.component.css']
})
export class DemandeHospitalisationFormComponent extends SgiListLayoutComponent implements AfterViewChecked {
    @Autowired() formBuilder: FormBuilder;
    @Autowired() metierService: DemandePriseChargeMetierService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() alertService: AlertService;

    @Input() refresh: boolean;

    searchForm: FormGroup;
    @Output() assureTrouveEvent = new EventEmitter<any>();
    userData: IUserConnect;
    adherent: any;

    medecinSelected: any;
    @Output() medecinSelectedEvent = new EventEmitter<any[]>();

    @Output() numeroBonEvent = new EventEmitter<any>();

    dataListAdherant: any = [];
    adherentChoisit: any;

    @Output() resetDocumentEvent = new EventEmitter<any[]>();


    constructor() {
        super();
        this.recuperationMedecinsStorage();
        // this.recuperationActesStorage();
        this.recuperationAffectionsStorage();
    }

    ngOnInit() {
        this.userData = this.securityService.getUser();

        this.initFormulaire();
        this.cols = [
            {field: 'nomCompletPersonne', header: 'Nom & Prénoms'},
            {field: 'matricule', header: 'Matricule'},
        ];
    }

    ngAfterViewChecked() {
        if (this.refresh) {
            this.resetAll();

            /**
             * reset all datas
             */
            this.resetDatasComponent();
        }
    }

    resetDatasComponent() {
        this.adherent = {};
        this.medecinSelected = {};
        this.adherentChoisit = {};
    }

    resetAll() {
        // this.searchForm = this.metierService.initialiserFormulaireDemandePEC(null);
        this.searchForm.patchValue({
            Matricule: null,
            Nom: null,
            Prenoms: null,
            Genre: null,
            medecin: null,
            numeroBon: null,
        });

        this.adherent = {};
        this.medecinSelected = {};

        this.assureTrouveEvent.emit(this.adherent);
        this.medecinSelectedEvent.emit(this.medecinSelected);
        this.numeroBonEvent.emit('');
    }

    initFormulaire() {
        this.searchForm = this.metierService.initialiserFormulaireDemandePEC(null);
    }

    recuperationMedecinsStorage() {
        let medecinsList = window.localStorage.getItem(STORAGE.MEDECINS);
        if (!medecinsList) {
            this.metierService.rechercherMedecin({}).subscribe(res => {
                this.metierService.medecins = res.body.items;
            });
            return;
        }
        medecinsList = JSON.parse(medecinsList);
        this.metierService.medecins = medecinsList;
    }

    recuperationActesStorage() {
        let actesList = window.localStorage.getItem(STORAGE.ACTES);
        if (!actesList) {
            this.metierService.recupererActes({}).subscribe(res => {
                this.metierService.actes = res.body.items;
            });
            return;
        }
        actesList = JSON.parse(actesList);
        this.metierService.actes = actesList;
    }

    recuperationAffectionsStorage() {
        let affectionsList = window.localStorage.getItem(STORAGE.AFFECTIONS);
        if (!affectionsList) {
            this.metierService.recupererAffections({}).subscribe(res => {
                this.metierService.affections = res.body.items;
            });
            return;
        }
        affectionsList = JSON.parse(affectionsList);
        this.metierService.affections = affectionsList;
    }

    selectionChange($event) {
        /**
         * reset all datas
         */
        this.resetAll();
        this.resetDatasComponent();

        this.resetDocumentEvent.emit([]);

        this.searchForm = this.metierService.initialiserFormulaireDemandePEC($event);
        this.adherent = $event;

        this.assureTrouveEvent.emit(this.adherent);
    }

    rechercheAssure() {
        this.adherentChoisit = {};

        let ErrorAlert: any;

        if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.searchForm.value.Matricule)) {
            return false;
        }

        let matriculeRefact = this.searchForm.value.Matricule.trim();
        matriculeRefact = matriculeRefact.toUpperCase();
        this.searchForm.patchValue({
            Matricule: matriculeRefact,
        });

        if (this.searchForm.value.Matricule === null) {
            ErrorAlert = this.alertService.showError(AUCUN_MATRICULE_SAISI);
            ErrorAlert.show();
        } else {
            const data = {
                matricule: this.searchForm.value.Matricule,
                idPrestataireMedical: this.userData.personne.idPrestataireMedical,
            };
            const resultatRecherche$ = this.metierService.rechercherAssureParMatricule(data);
            resultatRecherche$.subscribe(reponse => {
                if (reponse.body.items.length === 0) {
                    ErrorAlert = this.alertService.showError(AUCUNE_DONNEE_TROUVEE);
                    ErrorAlert.show();
                } else {
                    this.dataListAdherant = reponse.body.items;
                    this.dataListAdherant.forEach((item, index) => {
                        item.id_key = index + 1;
                        if (this.searchForm.value.Matricule === item.matricule) {
                            this.adherent = item;

                            this.searchForm.patchValue({
                                Matricule: this.adherent.matricule,
                                Nom: this.adherent.nom,
                                Prenoms: this.adherent.prenoms,
                                Genre: this.adherent.sexe,
                                tauxCouverture: `${(this.adherent.tauxCouverture || this.adherent.tauxCouverture !== null)
                                    ? this.adherent.tauxCouverture
                                    : 0} %`,
                            });

                            this.assureTrouveEvent.emit(this.adherent);
                        }
                    });
                }
            });
        }
    }


    /** ** récupératyion dun medecin selectioné ** */
    actionSelectedMedecin(event) {
        this.medecinSelected = event;
        this.medecinSelectedEvent.emit(this.medecinSelected);
    }

    clearAutocomplete() {
        this.medecinSelected = {};
        this.medecinSelectedEvent.emit(this.medecinSelected);
        this.searchForm.patchValue({medecin: null});
    }

    onchangeNumeroBon(event) {
        this.numeroBonEvent.emit(event.target.value);
    }
}

import {Component, OnInit} from '@angular/core';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {Autowired} from "@angular-ru/autowired";
import {ActesFactureMetierService} from "@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/actes/actes-facture.metier.service";
import {AffectionsFactureMetierService} from "./affections-facture.metier.service";

@Component({
  selector: 'app-affection-facture',
  templateUrl: './affection-facture.component.html'
})
export class AffectionFactureComponent extends SgiListLayoutComponent implements OnInit {

  @Autowired() recuperationListeDonneesMetier: AffectionsFactureMetierService;
  @Autowired() convertirFormulaireMetier: AffectionsFactureMetierService;
  @Autowired() initialiserFormulaireMetier: AffectionsFactureMetierService;
  @Autowired() requeteCreationMetier: AffectionsFactureMetierService;
  @Autowired() requeteModificationMetier: AffectionsFactureMetierService;
  @Autowired() metierService: AffectionsFactureMetierService;

  constructor() {
    super();

  }

  ngOnInit() {
    this.cols = [
      {field: 'codeAffection', header: 'Code Affection'},
      // {field: 'libelleAffection', header: 'Lib. Affection'},
    ];
    this.notRowActions = [];
    this.tableActions = [];
  }

}

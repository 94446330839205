import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import * as prescription from '../prescripton-pec.constante';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {FormBuilder} from '@angular/forms';
import {PrescriptonPecMetierService} from '../prescripton-pec.metier.service';

@Component({
    selector: 'app-prescripton-pec-liste-feature',
    templateUrl: './prescripton-pec-liste.feature.component.html',
})
export class PrescriptonPecListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: PrescriptonPecMetierService;
    @Autowired() recuperationListeDonneesMetier: PrescriptonPecMetierService;
    @Autowired() metierService: PrescriptonPecMetierService;

    @Autowired() formBuilder: FormBuilder;


    prescriptionPECConstantes = prescription;
    tabActive = 'Informations sur l\'assuré';
    currentDataTemp: any;

    affichModalPrintBon = false;
    tableSelectedElement: any;

    @Input() sectionAffichage: string;
    @Input() searchForm: any;
    @Input() totalRecords: any;


    constructor() {
        super();
        this.cols = this.prescriptionPECConstantes.columns;
        this.tableActions = [];
    }

    ngOnInit() {
        this.selectedColumns = this.cols;
        // this.getListElements();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
            matricule: this.searchForm.value.Matricule,
            numBonPriseEnCharge: this.searchForm.value.NumBon,
        };
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }


    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
        this.tabActive = '';
    }

    /**
     * => return true, si le tableau est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }

    getTabChange(event) {
        this.currentRow = null;
        this.currentRow = this.currentDataTemp;
        this.tabActive = event.originalEvent.target['innerText'];
    }


    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        if (data.libelleTypeStatut === 'Cloturé') {
            shower = this.alertService.showError('La demande de prise en charge ne doit pas ètre cloturé');
            shower.show();
            return;
        }

        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.metierService.requeteSuppression(data).subscribe(() => {
                this.recuperDonnees();
            });
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    refreshRecuperationDonnees(data?: any) {
        this.args = {...this.args, lazyLoadEvent: data, successMute: true};

        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(this.searchForm.value.Matricule)
            || !CommonUtilities.isDataNullUndefinedFalseEmpty(this.searchForm.value.NumBon)) {

            const requeteRecuperationListeDonnees = this.metierService.recupererListeTableRefresh(
                {
                    getAllRequestParam: this.getAllRequestParam(), argsParam: {...this.args},
                });

            requeteRecuperationListeDonnees.subscribe((responseList: any) => {
                console.log('responseList : ', responseList);
                /* if (!CommonUtilities.isDataNullUndefinedFalseEmpty(responseList.body)) {
                     this.dataSource = responseList.body.items;
                 } else {
                     this.dataSource = responseList;
                 }*/

                this.formatTableListAddIndex(responseList.body);
            });
        }
    }

    formatTableListAddIndex(responseData) {
        if (responseData === undefined) {
            this.dataSource = [];
            this.totalRecords = 0;
            return;
        }
        this.dataSource = responseData.items;
        this.totalRecords = responseData.count;
        if (this.dataSource && this.dataSource.length > 0) {
            this.dataSource.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }
    }

}

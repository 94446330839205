import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import { SubstitustionModaleComponent } from './substitustion.modale.component';
import {MedecinFormModule} from "@app/fonctionnalites/demande-pec/demande-prise-charge/form/medecin/form/medecin.form.module";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MedecinFormModule,

    ],
  exports: [SubstitustionModaleComponent],
  declarations: [SubstitustionModaleComponent],
  providers: [],
})
export class SubstitustionModaleModule {
}

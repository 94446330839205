import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable} from 'rxjs';
import {IRequeteCreation} from '@app/core/interfaces/actions/i-requete-creation';
import {IRequeteModification} from '@app/core/interfaces/actions/i-requete-modification';
import {IRecuperationDonneeRequete} from '@app/core/interfaces/actions/i-recuperation-donnee-requete';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import { API_POUR_RECUPERER_LES_MEDICAMENTS_DE_SUBSTITUTIONS} from '@app/core/constantes';

@Injectable({providedIn: 'root'})
export class SubstitustionModaleRestApiService implements IRequeteCreation, IRequeteModification, IRecuperationDonneeRequete {
  @Autowired() restService: SgiRestService;

  requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}evenement`, data);
  }

  requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.putRequest(`${WebUtilities.baseUrlApi()}evenement`, data);
  }

  requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
    const SendData =  {ItemToSearch : {
        InfoSearchidMedicament: {Consider: true},
        idMedicament: data.idMedicament
      },
      TakeAll: true,
    };
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_POUR_RECUPERER_LES_MEDICAMENTS_DE_SUBSTITUTIONS}`, SendData);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ActiverUtilisateurComponent} from './activer-utilisateur.component';
import {SharedModule} from '../../../shared/shared.module';
import {ConnexionModule} from '../../../fonctionnalites/authentification/connexion/connexion.module';
import {ResetPasswordFeatureModule} from '../../../fonctionnalites/authentification/reset-password/reset-password.feature.module';

export const routes = [
  { path: '', component: ActiverUtilisateurComponent, data: {breadcrumb: 'Activer utilisateur'} },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    ConnexionModule,
    ResetPasswordFeatureModule
  ],
  declarations: [
    ActiverUtilisateurComponent
  ]
})
export class ActiverUtilisateurModule { }

//@dynamic
export class ClvStringsUtils {
  /**
   * insert a string into a string above an anchor
   */
  public static insertAboveAnchor(str: string, toAdd: string, anchor: string): string {
    const indexAncor = str.search(anchor);
    const result = str.slice(0, indexAncor - 1) + toAdd + str.slice(indexAncor);
    return result;
  }

  /**
   * insert into a string below an anchor
   */
  public static insertBelowAnchor(str: string, toAdd: string, anchor: string): string {
    const indexAncor = str.search(anchor) + anchor.length;
    const result = str.slice(0, indexAncor) + toAdd + str.slice(indexAncor);
    return result;
  }

  /**
   * RecupÃ¨re les X caractÃ¨res depuis la fin du fichier en commenÃ§ant par un offset.
   */
  public static strXCharsFromBackAt(value: string, charLength: number, position: number) {
    if (value.length - position * charLength < 0) {
      return value.substr(0, charLength + value.length - position * charLength);
    }
    return value.substr(value.length - position * charLength, charLength);
  }
  public static strNbrXChars(value: string, charLength: number) {
    return Math.ceil(value.length / charLength);
  }
}

import {ThousandSeparatorMode} from './thousand-separator-mode';
import {AbstractControl, FormArray, FormControl, FormGroup} from '@angular/forms';

/**
 * Js Utils
 */
//@dynamic
export class ClvTsUtilsCommonsJs {
  /**
   * Alias of jsType. Get Type of any given element
   */
  public static typeOf(obj: any): string {
    return this.jsType(obj);
  }

  /**
   * Return a sublist from a list that has column that contain a certain value.
   */
  public static enumarableFromListEqual(list: any[], column: string, value: any) {
    const subList: any[] = [];
    if (list == null) {
      return subList;
    }
    for (let i = 0; i < list.length; i++) {
      if (list[i][column] === value) {
        subList.push(list[i]);
      }
    }
    return subList;
  }

  /**
   * Get an object in a list by value of one field, like id.
   */
  public static getObjectByFieldValue(obj: any[], fieldname: string, fieldvalue: any): any {
    let found = false;
    let cp = 0;
    while (cp < obj.length && !found) {
      if (obj[cp][fieldname] === fieldvalue) {
        found = true;
      } else {
        cp++;
      }
    }

    if (found) {
      return obj[cp];
    }
    return -1;
  }

  /**
   * Deprecated: Format number
   */
  public static separatorThousand(val: string | number): string {
    val = this.cleanSpace(`${val}`);
    const value = this.copy(val);
    let result: string;
    let i = -3;
    let j = 0;
    let end = false;
    let cp = 0;
    result = '';
    while (!end) {
      if (j < value.length) {
        i += 3;
        j += 3;
        result = ' ' + value.substring(value.length - j, value.length - i) + result;
      } else {
        i += 3;
        result = ' ' + value.substring(0, value.length - i) + result;
        end = true;

      }
      cp++;
    }
    return result;
  }

  /**
   * Return the sum of field of an object.
   */
  public static objectSumField(objs: any[], fieldName: string): number {
    let sum: number;
    sum = 0;
    if (objs && objs !== null && objs !== undefined) {
      objs.map((obj, index) => {
        if (
          this.typeOf(+this.cleanSpace(`${obj[fieldName]}`)) === 'number' &&
          obj[fieldName] !== null &&
          obj[fieldName] !== undefined
        ) {
          sum += +this.cleanSpace(`${obj[fieldName]}`);
        }
      });
    }
    return sum;
  }

  /**
   * Get offset of an object in a FormArray by fieldName
   */
  public static getObjectOffsetInArrayByField(arr: any[], obj: any, fieldName: string): number {
    let found = false;
    let cp = 0;
    while (cp < arr.length && !found) {
      if (arr[cp].value[fieldName] === obj[fieldName]) {
        found = true;
      } else {
        cp++;
      }
    }

    if (found) {
      return cp;
    } else {
      return -1;
    }
  }

  /**
   * Find offset of object in array
   * @param arr
   * @param obj
   */
  public static getObjectOffsetInArray(arr: any[], obj: any): number {
    let found = false;
    let cp = 0;
    while (cp < arr.length && !found) {
      if (arr[cp] === obj) {
        found = true;
      } else {
        cp++;
      }
    }

    if (found) {
      return cp;
    } else {
      return -1;
    }
  }


  /**
   * Determine le type des objets et variables en JS
   */
  public static jsType(obj: any): string {
    if (obj === null) {
      return 'null';
    }
    if (obj === undefined) {
      return 'undefined';
    }
    if (typeof obj === 'string') {
      return 'string';
    }
    if (obj.length >= 0) {
      return 'array';
    }
    return typeof obj;
  }

  /**
   * Duplique une variable. Cette fonction est utilisÃ©e pour Ã©viter l'affectation par referencement d'addresse mÃ©moire.
   */
  public static copy<O>(obj: O): O {
    if (this.jsType(obj) === 'array') {
      return Object.assign([], obj);
    }
    if (this.jsType(obj) === 'object') {
      return Object.assign({}, obj);
    }
    return JSON.parse(JSON.stringify(obj));
  }

  /**
   * Efface les espaces contenus dans une chaine de caractÃ¨res
   */
  public static cleanSpace(value: string): string {
    return `${value}`.replace(/\s/g, '');
  }

  /**
   * Teste si un objet est nul ou indÃ©fini
   */
  public static isUndefinedOrNull(value: any): boolean {
    return value === null || value === undefined;
  }

  /**
   * teste si une chaine de caractÃ¨re est vide, nulle ou indÃ©finie
   */
  public static stringIsUndefinedOrNull(value: string): boolean {
    return value === null || value === undefined || value === '';
  }

  public static ajouterSeparateurDeMillier(str: string) {
    let result = '';
    for (let i = 0; i < Math.ceil(str.length / 3); i++) {
      const ofs = str.length - (i + 1) * 3;
      if (ofs > 0) {
        result = `${str.substr(ofs, 3)} ${result}`;
      } else {
        result = `${str.substr(0, 3 + ofs)} ${result}`;
      }
      if (i === 0) {
        result = result.substr(0, result.length - 1);
      }
    }
    return result;
  }

  /**
   * SÃ©parateur de milliers.
   */
  public static thousandSeparator(nombre: string | number, args: ThousandSeparatorArg = {decimal: true, pres: 2, arround: false}) {
    if (typeof nombre !== 'string' && typeof nombre !== 'number') {
      return null;
    }

    // verifier la definition des arguments
    if (!args.pres) {
      args.pres = 2;
    }

    let nombreStr = '';
    // rendre la chaine utilisable
    if (typeof nombre === 'string') {
      nombreStr = this.cleanSpace(nombre);
    }

    if (typeof nombre === 'number') {
      nombreStr = `${nombre}`;
    }

    // separer la partie entiere de la partie decimale
    let partieEntiere = nombreStr.split('.')[0];
    let partieDecimale = nombreStr.split('.')[1];

    // ajouter separateur de millier Ã  la partie entiere
    partieEntiere = this.ajouterSeparateurDeMillier(partieEntiere);

    // arrondir partie decimale
    partieDecimale = `0.${partieDecimale}`;
    if (args.arround) {
      if (args.pres && args.pres > 0) {
        const num = Number(+partieDecimale);
        partieDecimale = `${Number(num.toFixed(args.pres))}`;
      }
    }
    partieDecimale = partieDecimale.split('.')[1];

    // construire le resultat
    let resultat = partieEntiere;

    if (args.decimal) {
      if (nombre.toString().indexOf('.') !== -1) {
        if (partieDecimale && partieDecimale !== 'undefined') {
          resultat += `.`;
          resultat += `${partieDecimale}`;
        } else if (nombre && nombre !== '') {
          resultat += `.`;
        }
      }
    }
    return resultat;
  }

  /**
   * Arrondi un nombre Ã  n chiffres aprÃ¨s la virgule.
   */
  public static roundDecimalNumber(value: number, decimalPre: number = 1) {
    let multiplier = 1;
    for (let i = 0; i < decimalPre; i++) {
      multiplier = multiplier * 10;
    }
    // console.log(value + ' ' + decimalPre + ' ' + multiplier);
    return Math.round(value * multiplier) / multiplier;
  }

  /**
   * Supprime un item ou une liste d'items dans un tableau
   */
  public static deleteFromArray (array: any[], item: any) {
    if (this.isUndefinedOrNull(array)) {
      return -1;
    }
    if (this.isUndefinedOrNull(item)) {
      return array;
    }

    let arr = this.copy(array);
    if (this.jsType(item) === 'array') {
      item.map((itm: any) => {
        if (array.find(v => v === itm)) {
          arr = arr.slice(0, arr.findIndex(v => v === itm)).concat(
            arr.slice(arr.findIndex(v => v === itm) + 1)
          );
        }
      });
      return arr;
    }
    if (!array.find(v => v === item)) {
      return arr;
    }
    return arr.slice(0, arr.findIndex(v => v === item)).concat(
      arr.slice(arr.findIndex(v => v === item) + 1)
    );
  }

  /**
   * Convert object to reactive form
   * @param obj
   * @param defaultArrayName
   */
  public static objectToReactiveForm(obj: any, defaultArrayName: string = 'arrayName'): AbstractControl {
    const formGroup: FormGroup = new FormGroup({});
    if (ClvTsUtilsCommonsJs.jsType(obj) === 'array') {
      this.formBuildingEngine(defaultArrayName, obj, formGroup);
    } else {
      this.formBuildingEngine(null, obj, formGroup);
    }
    return formGroup;
  }
  private static formBuildingEngine(key: any, obj: any, control: AbstractControl): void {
    switch (ClvTsUtilsCommonsJs.jsType(obj)) {
      case 'array':
        this.arrayFieldBuilderEngine(key, obj, control);
        break;

      case 'object':
        this.objectFieldBuilderEngine(key, obj, control);
        break;

      default:
        (<FormGroup> control).setControl(key, new FormControl(obj));
    }
  }
  private static objectFieldBuilderEngine(key: any, obj: any, control: AbstractControl) {
    if (key === null) {
      Object.keys(obj).map(ke => {
        this.formBuildingEngine(ke, obj[ke], control);
      });
    } else {
      const formGroup: FormGroup = new FormGroup({});
      (<FormGroup> control).setControl(key, formGroup);
      Object.keys(obj).map(ke => {
        this.formBuildingEngine(ke, obj[ke], formGroup);
      });
    }
  }
  private static arrayFieldBuilderEngine(key: any, obj: any, control: AbstractControl) {
    const array = new FormArray([]);
    (<FormGroup> control).setControl(key, array);
    if (ClvTsUtilsCommonsJs.jsType(obj) === 'array') {
      obj.map(k => {
        const formGroup: FormGroup = new FormGroup({});
        array.push(formGroup);
        this.formBuildingEngine(null, k, formGroup);
      });
    }
  }
}
export interface ThousandSeparatorArg {
  decimal: boolean;
  pres: number;
  arround: boolean;
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {LoginComponent} from './login.component';
import {SharedModule} from '../../../shared/shared.module';
import {ConnexionModule} from '@app/fonctionnalites/authentification/connexion/connexion.module';

export const routes = [
    {path: '', component: LoginComponent, pathMatch: 'full'},
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        // SharedModule,
        ConnexionModule,
    ],
    declarations: [
        LoginComponent
    ]
})
export class LoginModule {
}

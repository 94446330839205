import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {InformationAssureTableListeRestApiService} from './information-assure-table.liste.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class InformationAssureTableListeMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: InformationAssureTableListeRestApiService;
  @Autowired() formBuilder: FormBuilder;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      nomCompletPersonneAdh: !!datas && !!datas.nomCompletPersonneAdh ? datas.nomCompletPersonneAdh : null,
      matricule: !!datas && !!datas.matricule ? datas.matricule : null,
      libelleTypeprestataire: !!datas && !!datas.libelleTypeprestataire ? datas.libelleTypeprestataire : null,
      Localite: !!datas && !!datas.Localite ? datas.Localite : null,
      statutClientGarant: !!datas && !!datas.statutClientGarant ? datas.statutClientGarant : null,
    };

    return this.formBuilder.group({
      nomCompletPersonneAdh: [{value: data.nomCompletPersonneAdh, disabled: true}],
      matricule: [{value: data.matricule, disabled: true}],
      libelleTypeprestataire: [{value: data.libelleTypeprestataire, disabled: true}],
      Localite: [{value: data.Localite, disabled: true}],
      statutClientGarant: [{value: data.statutClientGarant, disabled: true}],
    });
  }

  recupererInfoUserPoursuiteSelectionner(id): Observable<any> | Promise<any> | any  {
    return this.restApi.recupererInfoUserPoursuite(id)
  }
}

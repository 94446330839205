import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-add-button',
  template: `
  <ng-container *ngIf="isFlat">
    <button mat-icon-button [matTooltip]="title" [disabled]="disabled" [ngClass]="'success-color'">
      <mat-icon>{{icon}}</mat-icon>
    </button>
  </ng-container>
  <ng-container *ngIf="!isFlat">
    <button mat-raised-button [ngClass]="'bg-success'">
      <mat-icon>{{icon}}</mat-icon>&nbsp;{{title}}
    </button>
  </ng-container>
  `
})
export class AddButtonComponent {
  @Input('isFlat') isFlat = false;
  @Input('icon') icon = 'add';
  @Input('title') title = 'Ajouter';
  @Input('disabled') disabled = false;
}

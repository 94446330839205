import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'lib-clv-ts-utils',
  template: `
    <p>
      clv-ts-utils works!
    </p>
  `,
  styles: []
})
export class ClvTsUtilsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

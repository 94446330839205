import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {IThemeState, ThemeStoreService} from '@app/core/states/theme/theme.store.service';

@Injectable({providedIn: 'root'})
export class ThemeQueryService extends Query<IThemeState> {

  constructor(protected store: ThemeStoreService) {
    super(store);
  }
}

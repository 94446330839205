import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {map, tap} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {IConnexion, IDeconnexion} from '@app/core/interfaces';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {Router} from '@angular/router';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {API_CONNEXION_USER, API_RESET_PASSWORD_USER} from '@app/core/constantes/api.constante';
import {HttpHeaders, HttpResponse} from '@angular/common/http';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {STORAGE} from '@app/core/constantes';

@Injectable({providedIn: 'root'})
export class ConnexionRestApiService implements IConnexion, IDeconnexion {
    @Autowired() restService: SgiRestService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() router: Router;

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    tokenOptions: any = {
        headers: new HttpHeaders({
            'Content-Type': 'application/x-www-form-urlencoded',
        }),
    };

    /**
     * Effectue la connexion d'un utilisateur
     * @param data
     */
    connexion(data: any) {
        // return this.restService.postRequest(WebUtilities.baseUrlApi() + 'authentification', data);
        // return of({body: {
        //   'id_token': 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJPbmxpbmUgSldUIEJ1aWxkZXIiLCJpYXQiOjE1NzI1MjE1NjMsImV4cCI6MTYwNDA1NzU2MywiYXVkIjoid3d3LmV4YW1wbGUuY29tIiwic3ViIjoianJvY2tldEBleGFtcGxlLmNvbSIsIkdpdmVuTmFtZSI6IkpvaG5ueSIsIlN1cm5hbWUiOiJSb2NrZXQiLCJFbWFpbCI6Impyb2NrZXRAZXhhbXBsZS5jb20iLCJSb2xlIjpbIk1hbmFnZXIiLCJQcm9qZWN0IEFkbWluaXN0cmF0b3IiXX0.rUcdXiodnXNt4dnFB8jOuCNF03IAs6wX0k1G4yaqWpU'
        // }});

        return this.restService.postRequest(WebUtilities.baseUrlApi() + API_CONNEXION_USER, {
            ItemToSearch: {
                InfoSearchUserName: {Consider: true},
                InfoSearchPassword: {Consider: true},
                UserName: data.username.trim(),
                Password: data.password.trim(),
            },
        }, {...this.postArgsMute});
    }

    /**
     * Effectue la récupération du token de l'utilisateur connecté
     * @param data
     */
    recuperationToken(data: any) {
        const sendData =
            'grant_type=password&username=' +
            data.username.trim() +
            '&password=' +
            data.password.trim();
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(WebUtilities.baseUrlApiToken() + 'token', sendData, {...this.tokenOptions, ...this.postArgsMute});
    }

    /**
     * Effectue la déconnexion d'un utilisateur
     */
    deconnexion() {
        return of({body: null}).pipe(
            tap(() => {
                setTimeout(() => {
                    this.securityService.clearSotre();

                    window.sessionStorage.clear();

                    window.localStorage.removeItem(STORAGE.MEDECINS);
                    window.localStorage.removeItem(STORAGE.AFFECTIONS);
                    window.localStorage.removeItem(STORAGE.USER_CONNECT);
                    // window.localStorage.removeItem('listMode');

                    this.router.navigate(['/login']);
                    window.location.reload();
                }, 1000);
            })
        );
        /*return this.restService.postRequest(WebUtilities.baseUrlApi() + 'deconnexion', null)
        .pipe(
            tap(() => {
              setTimeout(() => {
                window.sessionStorage.clear();
                this.router.navigate(['/login']);
                // window.location.reload();
              }, 1000);
            })
        );*/
    }

    /**
     * Effectue un deconnexion synchrone utile avant la fermeture d'un onglet
     */
    deconnexionALaFermeture() {
        const request = new XMLHttpRequest();
        request.open('POST', WebUtilities.baseUrlApi() + 'deconnexion', false);
        request.setRequestHeader('Authorization', 'Bearer ' + window.sessionStorage.getItem('token'));
        request.onload = () => {
            this.router.navigate(['/login']);
            window.sessionStorage.clear();
            window.location.reload();
        };
        request.send(null);
    }

    deconnexionVerouillage() {
        const request = new XMLHttpRequest();
        request.open('POST', WebUtilities.baseUrlApi() + 'deconnexion', false);
        request.setRequestHeader('Authorization', 'Bearer ' + window.sessionStorage.getItem('token'));
        request.onload = () => {
            this.securityService.miseEnVeille();
        };
        request.send(null);
    }

    /**
     * Requete de verouillage d'un utilisateur après un nombre de tentatives de connexion echoué
     * @param login
     */
    verouillerUtilisateur(login: string) {
        return this.restService.putRequest(WebUtilities.baseUrlApi() + 'utilisateur/' + login + '/verouillage', null, {successMute: true});
    }

    /**
     * Requête reset mot de passe
     * @param data
     * @param args
     */

    requeteResetPassword(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {
            IdCurrentUser: args.user.utilisateurId,
            ItemsToSave: [
                {
                    NewPassword: window.btoa(window.btoa(window.btoa(args.newPassword))),
                    Email: args.user.personne.email,
                    Nom: args.user.personne.nom,
                    Prenoms: args.user.personne.prenom,
                    login: args.user.personne.raisonSocial,
                }
            ],
        };

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RESET_PASSWORD_USER}`, sendData)
            .pipe(
                map((response: HttpResponse<any>) => {
                    return response;
                }),
            );
    }
}

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {Autowired} from "@angular-ru/autowired";
import { PrescriptionFactureModifiabbleMetierService } from './prescription-facture-modifiabble.metier.service';

@Component({
  selector: 'app-prescriptions-facture-modifiabble',
  templateUrl: './prescriptions-facture-modifiabble.component.html'
})
export class PrescriptionsFactureModifiabbleComponent extends SgiListLayoutComponent implements OnInit {

  @Autowired() recuperationListeDonneesMetier: PrescriptionFactureModifiabbleMetierService;
  @Autowired() convertirFormulaireMetier: PrescriptionFactureModifiabbleMetierService;
  @Autowired() initialiserFormulaireMetier: PrescriptionFactureModifiabbleMetierService;
  @Autowired() requeteCreationMetier: PrescriptionFactureModifiabbleMetierService;
  @Autowired() requeteModificationMetier: PrescriptionFactureModifiabbleMetierService;
  @Autowired() metierService: PrescriptionFactureModifiabbleMetierService;

  @Output() renvoieDonnees = new EventEmitter<any[]>();

  constructor() {
    super();
  }

  ngOnInit() {
    console.log("this.dataSource this.dataSource", this.dataSource)
  }

  change() {
    this.dataSource.forEach(data => {
      if (data.quantiteRealise != null) {
        if (data.quantiteRealise > data.quantite) {
          this.alertService.showError('La quantité saisie est supérieur à la quantité prescrite').show();
          return;
        }
        data.totalLigne = data.quantiteRealise * data.prixUnitaire;
        this.misAjourTableauAuComposantParent();
      }
    });
  }

  misAjourTableauAuComposantParent() {
    this.renvoieDonnees.emit(this.dataSource);
  }

}

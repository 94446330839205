import {NgModule} from '@angular/core';

import {ContactTableComponent} from './contact-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../../../../../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [ContactTableComponent],
    declarations: [ContactTableComponent],
    providers: [],
})
export class ContactTableModule {
}

import {NgModule} from '@angular/core';

import {DocumentTableListeComponent} from './document-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [DocumentTableListeComponent],
  declarations: [DocumentTableListeComponent],
  providers: [],
})
export class DocumentTableListeModule {
}

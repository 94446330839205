import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {Autowired} from '@angular-ru/autowired';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

/**
 * Guard vérifiant que l'utilisateur est authentifié.
 */
@Injectable({
    providedIn: 'root'
})
export class AuthenticationGuard implements CanActivate {
    @Autowired() securityService: SecurityStateService;
    constructor(public router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        const isLogged = (tok) => {
            return !ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(tok) && !this.securityService.tokenExpire();
        };
        const hasToken = (tok) => {
            return !ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(tok);
        };
        const tokenExipre = () => {
            return this.securityService.tokenExpire();
        };


        const token = this.securityService.getToken();
        // console.log(token);

        // Si la page requière une authentification:
        if ((<any>next.data).permissions.are === 'AUTENTICATED') {
            // Si l'utilisateur n'est pas connecté, on le rédirige sur la page de connexion
            if (hasToken(token)) {
                if (token === 'token') {
                    this.securityService.miseEnVeille();
                }
            } else {
                this.router.navigate(['/login']);
            }
            /*if (!isLogged(token)) {
                this.router.navigate(['/login']);
            }*/
            return isLogged(token) || token === 'token';
            // Si la page ne requière pas d'authentification:
        } else if ((<any>next.data).permissions.except === 'AUTENTICATED') {
            // Si l'utilidateur est connecté, on le rédirige vers l'accueil
            if (isLogged(token)) {
                this.router.navigate(['/']);
            }
            return !isLogged(token);
        }
    }
}

import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {Autowired} from '@angular-ru/autowired';
import {
    IRecuperationDonneeMetier,
    IRecuperationFullFormDonneesMetier,
    IRecuperationListeDonneesMetier,
    IRequeteCreationMetier,
    IRequeteModificationMetier,
    IRestApi,
    ISuppressionDonneeMetier
} from '@app/core/interfaces';
import {AlertService} from '@app/core/services/alert.service';
import {RequestUtilities} from '@app/core/utilities/request-utilities';

/**
 * Opérations de base d'une API REST
 */
export class BasicRestMetierService implements IRequeteCreationMetier, IRequeteModificationMetier,
    IRecuperationDonneeMetier, IRecuperationListeDonneesMetier, ISuppressionDonneeMetier, IRecuperationFullFormDonneesMetier {
  /**
   * Service Rest. Cette interface doit être implémenté
   */
  restApi: IRestApi;

  /**
   * Service d'affichage de messages d'alertes
   */
  @Autowired() alertService: AlertService;

  /**
   * Réquête de création d'une ressource
   *
   * @param data
   * @param args
   */
  requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteCreation(data, args);
  }

  /**
   * Requête de modification d'une ressource
   *
   * @param data
   * @param args
   */
  requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteModification(data, args);
  }

  /**
   * Requêete de récupération d'une ressource
   *
   * @param data
   * @param args
   */
  requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
    let id = null; try { id = data.id ? data.id : null; } catch(e) {}
    return this.restApi.requeteRecuperationDonnee(id, args);
  }

  /**
   * Requêete de récupération d'une liste de ressources
   *
   * @param data
   * @param args
   */
  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  /**
   * Requête de suppression d'une ressource
   * @param data
   * @param args
   */
  requeteSuppression(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteSuppression(data, args);
  }

  /**
   * Requête de récupération des données d'un formulaire plein-page
   * @param args
   */
  requeteRecuperationFullFormDonnee(args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(null, args).pipe(
        map((reponse: HttpResponse<any>) => {
          return RequestUtilities.ajustementRecuperationFullForm(reponse);
        })
    );
  }
}

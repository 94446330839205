import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormBuilder} from '@angular/forms';
import {BordereauListeDetailsMetierService} from '@app/fonctionnalites/factures/bordereau/bordereau-liste-details/bordereau-liste-details.metier.service';
import {TableAction} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {PERMISSION_PRESTATION_FACTURE_RETIRERFACTUREBORDEREAU} from '@app/core/constantes';


@Component({
    selector: 'app-bordereau-liste-details-feature',
    templateUrl: './bordereau-liste-details.feature.component.html'
})

export class BordereauListeDetailsFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: BordereauListeDetailsMetierService;
    @Autowired() recuperationListeDonneesMetier: BordereauListeDetailsMetierService;
    @Autowired() metierService: BordereauListeDetailsMetierService;

    tabActive = 'Actes';
    currentDataTemp: any;

    @Autowired() formBuilder: FormBuilder;
    @Input() data: any;

    columns: any = [];
    tableSelectedElement: any;
    finResponse: boolean;

    /**
     * Boutton pour retirer les factures selectionnées du bordereau
     */
    retirerfactures: TableAction = {
        type: 'button',
        title: 'Retirer Facture(s) du bordereau',
        flat: false,
        icon: 'delete_sweep',
        notFlatClass: '',
        actionPerformance: (data) => {
            this.retirerfacturesBordereau();
        },
        canShow: (data) => true
    };

    constructor() {
        super();
        this.cols = [
            {field: 'raisonSocialeGarant', header: 'Garant'},
            {field: 'numBonPriseEnCharge', header: 'Num Prise en charge'},
            {field: 'matricule', header: 'Matricule'},
            {field: 'montantBrut', header: 'Montant', type: () => 'price'},
            {field: 'partAssuranceBrut', header: 'Part Assurance Brut', type: () => 'price'},
            {field: 'montantDette', header: 'Montant Dette', type: () => 'price'},
            {field: 'ticketModerateur', header: 'Ticket Moderateur', type: () => 'price'},
            {field: 'montantExclu', header: 'Montant Exclu', type: () => 'price'},
            {field: 'partAssurance', header: 'Net à Payer', type: () => 'price'},
        ];

        this.notRowActions = [];
        this.tableActions = [this.modifierAction];
    }

    ngOnInit() {
        super.ngOnInit();
        this.recuperDonnees();

        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_RETIRERFACTUREBORDEREAU)) {
            this.notRowActions = [...this.notRowActions, this.retirerfactures];
        }
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
            IdBordereau: this.data.idBordereau
        };
        this.args = {...arg};
    }

    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
        this.tabActive = '';
    }

    retirerfacturesBordereau() {
        this.metierService.retirerFactureBordereauMetier(this.tableSelectedElement)
            .subscribe(response => {});
    }

    fermerModifierDialogue() {
        this.recuperDonnees();
    }

    finResponseEvent(event) {
        this.finResponse = event;
    }
}

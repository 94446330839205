import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import { RechercheFactureAutocompleteComponent } from './recherche-facture.autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [RechercheFactureAutocompleteComponent],
  exports: [RechercheFactureAutocompleteComponent]
})
export class RechercheFactureAutocompleteFormModule {
}


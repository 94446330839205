import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {Autowired} from '@angular-ru/autowired';
import {AppSettings} from '@app/app.settings';

/**
 * Intercepteur ajoutant aux requêtes les données requises selon la methode SGI (Authorisation, TenantId, etc)
 */
@Injectable({
    providedIn: 'root'
})
export class IntercepteurSgiRequest extends SecurityStateService implements HttpInterceptor {

    @Autowired() appSettings: AppSettings;

    params: any = {};
    pathUrl: string = null;
    token: string = null;
    user: any;

    // function which will be called for all http calls
    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        // how to update the request Parameters
        // let updatedRequest = request.clone({
        //   headers: request.headers.set('Authorization', 'Some-dummyCode'),
        // });
        // /*
        //   //logging the updated Parameters to browser's console
        //   console.log("Before making api call : ", updatedRequest);*/
        //
        // //  ESDRAS 18062019
        //
        // if (request.body !== null) {
        //     this.params = request.body;
        // }
        // if (!ClvTsUtilsCommonsJs.isUndefinedOrNull(request) && !ClvTsUtilsCommonsJs.isUndefinedOrNull(request.headers)) {
        //     request.headers.append('Content-Type', 'application/json');
        // }
        // // let IdCurrentStructure = 0;
        // // let IdCurrentTenant = 0;
        // let IdCurrentUser = 0;
        // // let IdCurrentPays = 0;
        // // let IdCurrentVille = 0;
        // // let IdCurrentClient = 0;
        // // let Token = null;
        // if (Security.userIsLogin()) {
        //     // IdCurrentTenant = 1;
        //     // IdCurrentStructure = Security.getCurrentUser().IdStructure;
        //     IdCurrentUser = Security.getIdUtilisateur();
        //     // IdCurrentPays = Security.getCurrentUser().IdPays;
        //     // IdCurrentVille = Security.getCurrentUser().IdVille;
        //     // IdCurrentClient = Security.getCurrentUser().IdClient;
        //     // Token = Security.getCurrentUser().token;
        // }
        //
        // if (!ClvTsUtilsCommonsJs.isUndefinedOrNull(request) && !ClvTsUtilsCommonsJs.isUndefinedOrNull(request.body)) {
        //     // request.body.IdCurrentTenant = IdCurrentTenant;
        //     // request.body.IdCurrentStructure = IdCurrentStructure;
        //     request.body.IdCurrentUser = IdCurrentUser;
        //     // request.body.IdCurrentPays = IdCurrentPays;
        //     // request.body.IdCurrentVille = IdCurrentVille;
        //     // request.body.IdCurrentClient = IdCurrentClient;
        // }
        //
        // if (!ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(Security.getToken() + '')) {
        //     if (!ClvTsUtilsCommonsJs.isUndefinedOrNull(request) && !ClvTsUtilsCommonsJs.isUndefinedOrNull(request.headers)) {
        //         updatedRequest = request.clone({
        //             headers: request.headers.append('Authorization', 'Bearer ' + Security.getToken()),
        //         });
        //         // updatedRequest.headers.append('Authorization', 'Bearer ' + Security.getToken());
        //         // console.log(updatedRequest.headers.getAll('Authorization'));
        //     }
        // }

        this.token = this.getToken();
        this.user = this.getUser();

        if (this.user && this.token !== null && this.token !== 'token') {
            const body = request.body;

            request = request.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    Authorization: 'Bearer ' + this.token
                },
                body: {
                    // Donné à passé dans le body de la requette
                    IdCurrentUser: this.user.utilisateurId,
                    IdCurrentSession: this.user.idSessionUtilisateur,
                    IdCurrentTenant: this.user.tenantId,
                    ...body,
                }
            });

            this.appSettings.settings.isBloqued = false;
            if (request.body.bloqued === true) {
                this.appSettings.settings.isBloqued = true;
            }

            return next.handle(request);
        } else {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + this.token
                },
            });
            return next.handle(request);
        }
    }
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {AssureModaleFeatureComponent} from './assure-modale.feature.component';
import {InformationDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/information-dossier-table/information-dossier-table.module';
import {PrestationDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prestation-dossier-table.module';
import {ActeDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/acte-dossier-table/acte-dossier-table.module';
import {AffectionDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/affection-dossier-table/affection-dossier-table.module';
import {PrescriptionDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prescription-dossier-table/prescription-dossier-table.module';
import {ResultatExamenDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/resultat-examen-dossier-table/resultat-examen-dossier-table.module';
import {MaladieChroniqueDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/maladie-chronique-dossier-table/maladie-chronique-dossier-table.module';
import {AntecedentDossierTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/antecedent-dossier-table/antecedent-dossier-table.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        InformationDossierTableModule,
        PrestationDossierTableModule,
        ActeDossierTableModule,
        AffectionDossierTableModule,
        PrescriptionDossierTableModule,
        ResultatExamenDossierTableModule,
        MaladieChroniqueDossierTableModule,
        AntecedentDossierTableModule,
    ],
    exports: [AssureModaleFeatureComponent],
    declarations: [AssureModaleFeatureComponent],
  providers: [],
})
export class AssureModaleFeatureModule {
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from "@shared/shared.module";
import { PrescriptionsFactureComponent } from './prescriptions-facture.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
    ],
  exports: [PrescriptionsFactureComponent],
  declarations: [PrescriptionsFactureComponent],
  providers: [],
})
export class PrescriptionsFactureModule {
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormRadioComponent} from '@shared/components/inputs/form-radio/form-radio.component';
import {RadioButtonModule} from 'primeng/primeng';
import {TranslateModule} from '@ngx-translate/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ListFormErrorComponentModule} from '@shared/components/error/list-form-error/list-form-error.component.module';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        RadioButtonModule,
        FormsModule,
        ReactiveFormsModule,
        ListFormErrorComponentModule,
    ],
    declarations: [
        FormRadioComponent,
    ],
    exports: [
        FormRadioComponent,
        TranslateModule,
        RadioButtonModule,
        FormsModule,
        ReactiveFormsModule,
        ListFormErrorComponentModule,
    ]
})
export class FormRadioComponentModule {
}

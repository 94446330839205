import {NgModule} from '@angular/core';

import {PrescriptionTableComponent} from './prescription-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionTableModaleFeatureModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-table/modale/prescription-table-modale.feature.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PrescriptionTableModaleFeatureModule,
    ],
  exports: [PrescriptionTableComponent],
  declarations: [PrescriptionTableComponent],
  providers: [],
})
export class PrescriptionTableModule {
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {ListActeHospitalisationTableRestApiService} from './list-acte-hospitalisation-table.rest-api.service';

@Injectable({providedIn: 'root'})
export class ListActeHospitalisationTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: ListActeHospitalisationTableRestApiService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  recupererMontantActe(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.recupererMontantActe(data, args);
  }

  calculeMontantActe(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.calculeMontantActe(data, args);
  }
}

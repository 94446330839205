import {Component} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {AtlantisAutocompleteComponent} from '@shared/behaviors/atlantis-extend-clv/atlantis-autocomplete/atlantis-autocomplete.component';
import {PrescriptionAutocompleteFormMetierService} from '@app/fonctionnalites/demande-pec/composants/prescription-table/prescription-autocomplete/form/prescription-autocomplete.form.metier.service';

@Component({
  selector: 'app-prescription-autocomplete',
  template: `
    <app-atlantis-autocomplete [label]="label"
                               [options]="options$ | async"
                               [hasAction]="hasAction"
                               [actionGestionnaireDeModale]="actionGestionnaireDeModale"
                               [required]="required"
                               [control]="control"
                               [placeholder]="placeholder"
                               [actionColor]="actionColor"
                               [actionIcon]="actionIcon"
                               (onChange)="onChange?.emit($event)"
                               (onBlur)="onBlur?.emit($event)"
                               (onClick)="onClick?.emit($event)"
                               (onFocus)="onFocus?.emit($event)"
                               (onShow)="onShow?.emit($event)"
                               (onHide)="onHide?.emit($event)"
                               [recupererOptionsMetier]="recupererOptionsMetier"
                               [args]="args"
                               [name]="name"
                               [extOptions]="extOptions"
                               [searchField]="searchField"
                               [field]="field"
                               (actionDeclenche)="actionDeclenche.emit($event)"
                               (onSelect)="onSelect.emit($event)">
    </app-atlantis-autocomplete>
  `
})
export class PrescriptionAutocompleteComponent extends AtlantisAutocompleteComponent {
  @Autowired() recupererOptionsMetier: PrescriptionAutocompleteFormMetierService;

  constructor() {
    super();
    this.label = 'Recherche prescription';
    this.name = 'medicament';
  }
}

import {AfterViewInit, Input} from '@angular/core';
import {IManageArgs} from '@app/core/interfaces';
import {AbstractFormInputs} from '@shared/components/inputs/abstract-form-inputs';

export abstract class AbstractFormOptionInputs extends AbstractFormInputs implements IManageArgs, AfterViewInit {
  /**
   * Reçoit la liste d'options
   *
   * Le format par défaut est le suivant:
   *
   * <code>[
   *  {label: 'Toyota', value: 'toy'},
   *  {label: 'Nissan sanjero', value: 'nis_sanjero'}
   * ]</code>
   *
   * Le <code>label</code> defini la valeur visible à l'utilisateur et <code>value</code>, la valeur
   * renvoyée par le champ.
   */
  @Input('options') options: {label: string, value: any}[] = [];

  /**
   * Indique que la valeur retourné est l'objet complet sélectionné et non l'id
   */
  @Input('returnObject') returnObject = false;

  /**
   * Si elle est définie, elle indique que les données seront fournies par un module externe.
   *
   * Le format par défaut est le suivant:
   *
   * <code>[
   *  {label: 'Toyota', value: 'toy'},
   *  {label: 'Nissan sanjero', value: 'nis_sanjero'}
   * ]</code>
   *
   * Le <code>label</code> defini la valeur visible à l'utilisateur et <code>value</code>, la valeur
   * renvoyée par le champ.
   */
  @Input('extOptions') extOptions: {label: string, value: any}[] = null;
  mustReloadIfChange = ['control', 'value'];
  @Input('args') args: any | Object = {};

  ngAfterViewInit(): void {
    this.defineMustReloadIfChange();
    this.defineArgs();
  }

  defineMustReloadIfChange(): void {
  }

  getMustReloadIfChange() {
    this.defineMustReloadIfChange();
    return this.mustReloadIfChange;
  }

  defineArgs(): void {
    const arg = {...this.args, returnObject: this.returnObject};
    this.args = {...arg};
  }

  getArgs(): any {
    this.defineArgs();
    return this.args;
  }

}

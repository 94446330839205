import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';
import {FormDropdownComponent} from '@shared/components/inputs/form-dropdown/form-dropdown.component';

/**
 * Champ de selection.
 *
 * Services metier à implémenter:
 * recupererOptionsMetier: IRecupererOptionsMetier
 */
@Component({
  selector: 'app-atlantis-combo',
  template: `
    <app-form-dropdown [label]="label"
                       [showClear]="showClear"
                                [required]="required"
                                [hasAction]="hasAction"
                                (actionDeclenche)="actionGestionnaireDeModale.display = true;actionDeclenche.emit($event)"
                                [placeholder]="placeholder"
                                [control]="control"
                                [options]="options"
                       (onChange)="onChange?.emit($event)"
                       (onBlur)="onBlur?.emit($event)"
                       (onClick)="onClick?.emit($event)"
                       (onFocus)="onFocus?.emit($event)"
                       (onShow)="onShow?.emit($event)"
                       (onHide)="onHide?.emit($event)"
                       [actionColor]="actionColor"
                       [actionIcon]="actionIcon"></app-form-dropdown>

  <ng-content></ng-content>
  `
})
export class AtlantisComboComponent extends FormDropdownComponent implements OnInit, OnChanges {
  /**
   * Defini la liste de choix.
   *
   * Le format par défaut est le suivant:
   *
   * <code>[
   *  {label: 'Toyota', value: 'toy'},
   *  {label: 'Nissan sanjero', value: 'nis_sanjero'}
   * ]</code>
   *
   * Le <code>label</code> defini la valeur visible à l'utilisateur et <code>value</code>, la valeur
   * renvoyée par le champ.
   *
   */
  options$: Observable<Array<any>>;

  /**
   * Représente le service metier implémentant l'interface ou les interfaces metiers requis
   */
  recupererOptionsMetier: IRecupererOptionsMetier;

  /**
   * Permet d'actionner le gestionnaire de modales du composant afin d'afficher, de masquer,
   * d'affecter des donnnées la/au modale-subtitution.
   */
  @Input('actionGestionnaireDeModale') actionGestionnaireDeModale: GestionnaireDeModales = new class implements GestionnaireDeModales {
    datasourceSelected: any;
    display = false;
    typeOpenModal: 'update' | 'create' = 'create';
  };

  ngOnChanges(changes: SimpleChanges): void {
    const test = Object.keys(changes).find(v => v === 'control' || v === 'value');
    if (!test) {
      this.recupererOptions();
    }
  }

  ngOnInit() {
    this.recupererOptions();
  }

  /**
   * Exécute la requête de récupération des options. Elle permet de définir les options
   */
  recupererOptions() {
    if (!this.extOptions) {
      if (this.recupererOptionsMetier) {
        this.options$ = this.recupererOptionsMetier.recupererOptions(this.getArgs());
      }
    } else {
      this.options$ = of(this.extOptions);
    }
  }

}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DatePipe} from '@angular/common';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
    API_RECUPERER_PERSONNEL_MEDICAL,
    API_RECUPERER_PRESCRIPTION_DOSSIER_MEDICAL_ENCOURS,
    API_RECUPERER_REFERENCE_SPECIALITE,
} from '@app/core/constantes';


@Injectable({providedIn: 'root'})
export class PrescriptionTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;

    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        /*let constructorParamRequest: any;

        if (args.formGroup.value.selectTypeSearch === '' || args.formGroup.value.selectTypeSearch === null) {
          constructorParamRequest = {
          };
        }
        if (args.formGroup.value.selectTypeSearch === 'libelle') {
          constructorParamRequest = {
          };
        }
        if (args.formGroup.value.selectTypeSearch === 'quantite') {
          constructorParamRequest = {
          };
        }
        if (args.formGroup.value.selectTypeSearch === 'date_prescription') {
          constructorParamRequest = {
          };
        }
        if (args.formGroup.value.selectTypeSearch === 'medecin') {
          constructorParamRequest = {
          };
        }*/
        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                // ...constructorParamRequest,
                IdPrestation: args.currentRow.idPrestation,
                InfoSearchAdresseGeographique: {Consider: false},
                InfoSearchDateCreation: {Consider: false},
                InfoSearchDatePrestation: {Consider: false},
                InfoSearchDateRealisation: {Consider: false},
                InfoSearchEmail: {Consider: false},
                InfoSearchFax: {Consider: false},
                InfoSearchIdBonPriseEnCharge: {Consider: false},
                InfoSearchIdMedicament: {Consider: false},
                InfoSearchIdMedicamentOrigine: {Consider: false},
                InfoSearchIdPoolMedicalMedecin: {Consider: false},
                InfoSearchIdPoolMedicalPharmacien: {Consider: false},
                InfoSearchIdPrestataireMedical: {Consider: false},
                InfoSearchIdPrestation: {Consider: true},
                InfoSearchIdPrestationAffection: {Consider: false},
                InfoSearchIdRefPrestataireMedical: {Consider: false},
                InfoSearchIdSouscriptionLigne: {Consider: false},
                InfoSearchIdTenant: {Consider: false},
                InfoSearchIsDeleted: {Consider: false},
                InfoSearchLibelle: {Consider: false},
                InfoSearchLibellePays: {Consider: false},
                InfoSearchLibelleTypeprestataire: {Consider: false},
                InfoSearchLibelleVille: {Consider: false},
                InfoSearchNomOrigine: {Consider: false},
                InfoSearchPosologie: {Consider: false},
                InfoSearchQuantite: {Consider: false},
                InfoSearchQuantiteRealise: {Consider: false},
                InfoSearchRaisonSociale: {Consider: false},
                InfoSearchRealise: {Consider: false},
                InfoSearchStatut: {Consider: false},
                InfoSearchSubstitue: {Consider: false},
                InfoSearchTauxSinistre: {Consider: false},
                InfoSearchTelephone1: {Consider: false},
                InfoSearchTelephone2: {Consider: false},
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PRESCRIPTION_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
    }

    requeteGetPoolMedicalPersonnel(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                InfoSearchFonctionAdministrative: {Consider: false},
                InfoSearchIdPersonnelMedical: {Consider: false},
                InfoSearchIdPoolMedical: {Consider: false},
                InfoSearchIdPrestataireMedical: {Consider: false},
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PERSONNEL_MEDICAL}`, sendData, {...this.postArgsMute});
    }

    requeteGetReferenceSpecialite(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                IdPersonnelMedical: args.idPersonnelMedical,
                InfoSearchCodeSpecialisation: {Consider: false},
                InfoSearchIdPersonnelMedical: {Consider: true},
                InfoSearchIdPersonnelMedicalSpecialite: {Consider: false},
                InfoSearchIdSpecialite: {Consider: false},
                InfoSearchIsDeleted: {Consider: false},
                InfoSearchLibelleSpecialisation: {Consider: false},
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_REFERENCE_SPECIALITE}`, sendData, {...this.postArgsMute});
    }

}

import {NgModule} from '@angular/core';

import {InformationAssureComponent} from './information-assure.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeComplementaireAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/composants/acte-complementaire-table/acte-complementaire-autocomplete/form/acte-complementaire-autocomplete.form.module';
import {AdherentAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/modale-action-row/modale-hospitalisation/information-assure/adherent-autocomplete/form/adherent-autocomplete.form.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ActeComplementaireAutocompleteFormModule,
        AdherentAutocompleteFormModule,
    ],
  exports: [InformationAssureComponent],
  declarations: [
      InformationAssureComponent,
  ],
  providers: [],
})
export class InformationAssureModule {
}

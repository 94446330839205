import {Component, Input} from '@angular/core';
import {AbstractFormInputs} from '@shared/components/inputs/abstract-form-inputs';

@Component({
  selector: 'app-form-calendar',
  template: `
    <div class="input-container">
      <div class="d-flex justify-between">
        <label for="{{id}}">{{label}}&nbsp;<span *ngIf="required" class="danger-color">*</span></label>
        <div>
          <app-info-bull-widget [infos]="infos"></app-info-bull-widget>
        </div>
      </div>
      <div class="">
        <p-calendar *ngIf="useNgModel" [required]="required" id="{{id}}"
                    [placeholder]="placeholder"
                    [styleClass]="'w-100'"
                    [attr.minlength]="minlength"
                    (change)="onChange?.emit($event)"
                    (onBlur)="onBlur?.emit($event)"
                    (click)="onClick?.emit($event)"
                    (onFocus)="onFocus?.emit($event)"
                    (show)="onShow?.emit($event)"
                    [attr.min]="min"
                    [attr.max]="max"
                    [(ngModel)]="ngModel"
                    (ngModelChange)="ngModelChange.emit($event)"
                    [dateFormat]="dateFormat"
                    [attr.maxlength]="maxlength"
                    [defaultDate]="defaultDate"
                    appendTo="body"
                    [baseZIndex]="100002" [autoZIndex]="true"
                    [locale]="calendar_fr"
                    [maxDate]="maxDate"
                    [minDate]="minDate"
                    [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
                    [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"
                    (onSelect)="onSelect?.emit($event)"></p-calendar>
        <p-calendar *ngIf="!useNgModel" [required]="required" id="{{id}}"
                    [placeholder]="placeholder"
                    [styleClass]="'w-100'"
                    [attr.minlength]="minlength"
                    (change)="onChange?.emit($event)"
                    (onBlur)="onBlur?.emit($event)"
                    (click)="onClick?.emit($event)"
                    (onFocus)="onFocus?.emit($event)"
                    (show)="onShow?.emit($event)"
                    [attr.min]="min"
                    [attr.max]="max"
                    [dateFormat]="dateFormat"
                    [attr.maxlength]="maxlength"
                    [defaultDate]="defaultDate"
                    appendTo="body"
                    [baseZIndex]="100002" [autoZIndex]="true"
                    [formControl]="control"
                    [locale]="calendar_fr"
                    [maxDate]="maxDate"
                    [minDate]="minDate"
                    [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
                    [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"
                    (onSelect)="onSelect?.emit($event)"></p-calendar>
      </div>
      <app-list-form-error [control]="control"
                           [types]="errorTypes"
                           [submitted]="submitted"
                           [messages]="messages"></app-list-form-error>
    </div>`
})
export class FormCalendarComponent extends AbstractFormInputs {
  @Input('icon') icon = 'person';
  @Input('dateFormat') dateFormat = 'dd/mm/yy';
  @Input('defaultDate') defaultDate = null;
  @Input('placeholder') placeholder = '';
  @Input('maxDate') maxDate: any;
  @Input('minDate') minDate: any;
  calendar_fr = {
    closeText: 'Fermer',
    prevText: 'Précédent',
    nextText: 'Suivant',
    currentText: 'Aujourd\'hui',
    monthNames: ['janvier', 'février', 'mars', 'avril', 'mai', 'juin',
      'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'],
    monthNamesShort: ['janv.', 'févr.', 'mars', 'avr.', 'mai', 'juin',
      'juil.', 'août', 'sept.', 'oct.', 'nov.', 'déc.'],
    dayNames: ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'],
    dayNamesShort: ['dim.', 'lun.', 'mar.', 'mer.', 'jeu.', 'ven.', 'sam.'],
    dayNamesMin: ['D', 'L', 'M', 'M', 'J', 'V', 'S'],
    weekHeader: 'Sem.',
    dateFormat: 'dd/mm/yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
  calendar_en = {
    closeText: 'Done',
    prevText: 'Prev',
    nextText: 'Next',
    currentText: 'Today',
    monthNames: ['January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'],
    monthNamesShort: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
      'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
    dayNames: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
    dayNamesShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    dayNamesMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    weekHeader: 'Wk',
    dateFormat: 'dd/mm/yy',
    firstDay: 1,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ProfilUtilisateurComponent} from './profil-utilisateur.component';
import {SharedModule} from '@shared/shared.module';
import {UtilisateurProfilFullFormModule} from '@app/fonctionnalites/administration/utilisateur-profil/full-form/utilisateur-profil.full-form.module';
import {GravatarComponentModule} from '@shared/components/gravatar/gravatar.component.module';
import {UtilisateurPasswordFullFormModule} from '@app/fonctionnalites/administration/utilisateur-password/full-form/utilisateur-password.full-form.module';

const routes: Routes = [
    {path: '', component: ProfilUtilisateurComponent, data: {breadcrumb: 'Profil utilisateur'}}
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule,
        UtilisateurProfilFullFormModule,
        GravatarComponentModule,
        UtilisateurPasswordFullFormModule,
    ],
    declarations: [
        ProfilUtilisateurComponent
    ]
})
export class ProfilUtilisateurModule {
}

import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {AssureRestApiService} from './assure.rest-api.service';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';

@Injectable({providedIn: 'root'})
export class AssureMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier {

  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: AssureRestApiService;


  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
    v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
    return v;
  }

  /*initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const formGroup: FormGroup = new FormGroup({});
    formGroup.setControl('inputSearch', new FormControl(null));
    formGroup.setControl('boutonSearch', new FormControl(null));
    return formGroup;
  }*/

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      inputSearch: !!datas && !!datas.inputSearch ? datas.inputSearch : null,
      selectTypeSearch: !!datas && !!datas.selectTypeSearch ? datas.selectTypeSearch : null,
    };

    return this.formBuilder.group({
      inputSearch: data.inputSearch,
      selectTypeSearch: data.selectTypeSearch,
    });
  }

  recupererOptions(args?: any): Observable<Array<any>> {
    return this.restApi.requeteRecuperationListeDonnees(null, args)
        .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
          if (!(reponse.body.items && reponse.body.items.length > 0)) {
            return reponse;
          }
          return reponse.body.items;
        }));
  }

  /**
   * requête de récupération du dossier médicale de l'assuré
   * @param data
   * @param agrs
   */
  requeteDossierMedicalAssure(data: any, agrs: any) {
    return this.restApi.requeteRecuperationDossierMedicalAssure(data, agrs);
  }

  /**
   * requête de récupération des actes du dossier médical
   * @param data
   * @param agrs
   */
  requetePrestationActes(data: any, agrs: any) {
    return this.restApi.requeteRecuperationPrestationActes(data, agrs);
  }

  /**
   * requête de récupération des resultats d'examen du dossier médical
   * @param data
   * @param agrs
   */
  requeteResultatsExamen(data: any, agrs: any) {
    return this.restApi.requeteRecuperationResultatsExamen(data, agrs);
  }

  /**
   * requête de récupération des prescriptions d'examen du dossier médical
   * @param data
   * @param agrs
   */
  requetePrescriptions(data: any, agrs: any) {
    return this.restApi.requeteRecuperationPrescriptions(data, agrs);
  }

  /**
   * requête de récupération des affections d'examen du dossier médical
   * @param data
   * @param agrs
   */
  requeteAffections(data: any, agrs: any) {
    return this.restApi.requeteRecuperationAffections(data, agrs);
  }
}

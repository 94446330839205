import {NgModule} from '@angular/core';

import {PrestationDossierTableComponent} from './prestation-dossier-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-table/prescription-table.module';
import {PrescriptionRealiseTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-realise-table/prescription-realise-table.module';
import {AffectionTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/affection-table/affection-table.module';
import {PrestationActeTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prestation-acte-table/prestation-acte-table.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PrescriptionTableModule,
        PrescriptionRealiseTableModule,
        AffectionTableModule,
        PrestationActeTableModule,
    ],
  exports: [PrestationDossierTableComponent],
  declarations: [PrestationDossierTableComponent],
  providers: [],
})
export class PrestationDossierTableModule {
}

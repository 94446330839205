import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-page-en-construction',
  template: `
    <div fxLayout="row wrap">
      <div fxFlex="100" class="">
        <img src="{{img}}" style="display: block;max-width: 100%;max-height: 300px;margin: 0 auto;"/>
        <div class="text-center" style="font-weight: bold; font-size: 2rem;">{{title}}</div>
        <div class="text-center" style="color:red; font-size: 1.1rem;">{{details}}</div>
      </div>
    </div>
  `
})
export class PageEnConstructionComponent {
  @Input('img') img = '../../../../assets/img/constr.jpg';
  @Input('title') title = 'Page en construction';
  @Input('details') details = 'Nos ingénieurs travaillent à la réalisation de cette page!';
}

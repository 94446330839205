import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-image-preview',
    template: `
        <div class="text-center">
            <img [src]="url"
                 [style]="{minWidth: '200px', maxWidth: '800px', maxHeight: '700px'}"/>
        </div>
    `
})
export class ImagePreviewComponent {
    @Input('url') url: string;
}

import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {DemandePriseChargeMetierService} from '../demande-prise-charge.metier.service';
import * as demandePECConstantes from '../demande-prise-charge.constante';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {TableAction} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {
    ERROR_MESSAGE_CLOTURE_DEMANDE_PEC,
    ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE,
    ERROR_MESSAGE_ITEMS_VIDE,
    ERROR_MESSAGE_PRISE_EN_CHARGE_HOSPITILISATION,
    ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC,
    ERROR_MESSAGE_VERIFICATION_NOUVELLE_DEMANDE,
    MESSAGE_CONTINUE_FAMILLE_DEMANDE_PEC,
    MESSAGE_POURSUIVRE_DEMANDE_APRES_CLOTURE,
    PERMISSION_PRESTATION_DEMANDEPEC_AJOUTER,
    PERMISSION_PRESTATION_DEMANDEPEC_CLOTURER,
    PERMISSION_PRESTATION_DEMANDEPEC_IMPRIMERBON,
    PERMISSION_PRESTATION_DEMANDEPEC_NOUVELLE_DEMANDE_HOSPI,
    PERMISSION_PRESTATION_DEMANDEPEC_PROLONGERHOSPIT,
    PERMISSION_PRESTATION_DEMANDEPEC_SUPPRIMER,
    STATUT_CLOTURE,
    STATUT_EN_ACCORD_PREALABLE,
    STATUT_EN_ATTENTE_DE_CLOTURE,
    STATUT_EN_VALIDATION_AVIS_HOSPI,
    STATUT_REJETE,
} from '@app/core/constantes';
import {FormBuilder} from '@angular/forms';
import {AtlantisTableComponent} from '@shared/components/tables/atlantis-table/atlantis-table.component';
import {SecurityStateService} from '@app/core/states/security/security.state.service';


@Component({
    selector: 'app-demande-prise-charge-liste-feature',
    templateUrl: './demande-prise-charge-liste.feature.component.html'
})
export class DemandePriseChargeListeFeatureComponent extends SgiListLayoutComponent implements OnInit, OnDestroy {
    @Autowired() suppressionDonneeMetier: DemandePriseChargeMetierService;
    @Autowired() recuperationListeDonneesMetier: DemandePriseChargeMetierService;
    @Autowired() metierService: DemandePriseChargeMetierService;
    @ViewChild('table', {static: false}) table: AtlantisTableComponent;

    @Autowired() securityStore: SecurityStateService;

    demandePECConstantes = demandePECConstantes;
    tabActive = 'Actes';
    currentDataTemp: any;

    @Autowired() formBuilder: FormBuilder;

    affichModalDemandePEC = false;
    affichModalPrintBon = false;
    tableSelectedElement: any;

    refresh: boolean;

    /**
     * la fonction se lance avec un rafraichissement de 5 min
     * 1s = 1000 / 1min = 1000 * 60 / 5min = 1000 * 60 * 5
     */
    intervalSubcription: any;
    refreshActive = false;


    /**
     * Boutton nouvelle demande
     */
    novelleDemandeAction: TableAction = {
        type: 'button', title: 'Nouvelle demande', flat: false, icon: 'add', notFlatClass: 'bg-success', actionPerformance: (data) => {
            this.ajouterNouvelleDemande();
        }, canShow: (data) => true
    };

    /**
     * Boutton nouvelle demande
     */
    novelleDemandeActionHospitalisation: TableAction = {
        type: 'button',
        title: 'Nouvelle demande hospitalisation et autres',
        flat: false,
        icon: 'add',
        notFlatClass: 'bg-success',
        actionPerformance: (data) => {
            this.ajouterNouvelleDemandeHospitalisation();
        },
        canShow: (data) => true
    };

    /**
     * Boutton clôture demande
     */
    cloturerDemandeAction: TableAction = {
        type: 'button', title: 'Clôturer', flat: false, icon: 'stop', notFlatClass: '', actionPerformance: (data) => {
            this.cloturerDemande(data);
        }, canShow: (data) => true
    };

    /**
     * Boutton imprimer bon
     */
    imprimerBonAction: TableAction = {
        type: 'button', title: 'Imprimer', flat: false, icon: 'print', notFlatClass: '', actionPerformance: (data) => {
            this.imprimerBon(data);
        }, canShow: (data) => true
    };

    /**
     * Boutton prolonger Hospitalisattion
     */
    prescriptionSupplementaireAction: TableAction = {
        type: 'button', title: 'Prescription supplémentaire', flat: false, icon: 'print', notFlatClass: '', actionPerformance: (data) => {
            this.prescriptionSupplementaire(data);
        }, canShow: (data) => true
    };

    /**
     * Boutton prolonger Hospitalisattion
     */
    hospitalisationAction: TableAction = {
        type: 'button', title: 'Soins d\'hospitalisation', flat: false, icon: 'queue', notFlatClass: '', actionPerformance: (data) => {
            this.soinsHospitalisation(data);
        }, canShow: (data) => true
    };

    constructor() {
        super();
        this.cols = this.demandePECConstantes.columns;
        this.notRowActions = [];
        this.tableActions = [];

    }

    ngOnInit() {
        super.ngOnInit();
        // this.recuperDonnees();
        this.getListElements();

        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_DEMANDEPEC_AJOUTER)) {
            this.notRowActions = [...this.notRowActions, this.novelleDemandeAction];
        }
        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_DEMANDEPEC_NOUVELLE_DEMANDE_HOSPI)) {
            this.notRowActions = [...this.notRowActions, this.novelleDemandeActionHospitalisation];
        }
        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_DEMANDEPEC_CLOTURER)) {
            this.notRowActions = [...this.notRowActions, this.cloturerDemandeAction];
        }
        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_DEMANDEPEC_IMPRIMERBON)) {
            this.notRowActions = [...this.notRowActions, this.imprimerBonAction, this.prescriptionSupplementaireAction];
        }
        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_DEMANDEPEC_PROLONGERHOSPIT)) {
            this.notRowActions = [...this.notRowActions, this.hospitalisationAction];
        }

        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_DEMANDEPEC_SUPPRIMER)) {
            this.tableActions = [...this.tableActions, this.supprimerAction];
        }
    }

    ngOnDestroy() {
        this.stopLunchIntervalRefresh();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
            table: this.table,
            // dateNow: this.formGroup.value.dateNow,
            // dateDebut: this.formGroup.value.dateDebut,
            // dateFin: this.formGroup.value.dateFin
        };
        this.args = {...arg};
    }

    recuperationDonnees(data?: any) {

        const arg = {...this.args, lazyLoadEvent: data, successMute: true};
        this.args = {...arg};
        const requeteRecuperationListeDonnees = this.metierService.requeteRecuperationListeDonnees(
            this.getAllRequestParam(), {...this.args});

        requeteRecuperationListeDonnees.subscribe((responseList) => {

            this.formatTableListAddIndex(responseList.body);

            if (responseList.body.items.length > 0) {
                const acceptLunch = responseList.body.items.some((item) => item.libelleTypeStatut === STATUT_EN_ACCORD_PREALABLE);

                if (acceptLunch) {
                    if (!this.refreshActive) {
                        this.lunchIntervalRefresh();
                    }
                } else {
                    if (this.refreshActive) {
                        this.stopLunchIntervalRefresh();
                    }
                }
            }
        });
    }

    lunchIntervalRefresh() {
        this.refreshActive = true;

        this.intervalSubcription = setInterval(() => {
            this.recuperationDonnees({});
        }, 1000 * 60 * 5); // 5min = 1000 * 60 * 5
    }

    stopLunchIntervalRefresh() {
        this.refreshActive = false;
        clearInterval(this.intervalSubcription);
    }


    formatTableListAddIndex(responseData) {
        if (responseData === undefined) {
            this.dataSource = [];
            this.totalRecords = 0;
            return;
        }
        this.dataSource = responseData.items;
        this.totalRecords = responseData.count;
        if (this.dataSource && this.dataSource.length > 0) {
            this.dataSource.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }


    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = null;
        this.currentDataTemp = null;
        this.tabActive = 'Actes';
        this.tabViewFirstLevelActive = 0;

        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
    }

    /**
     * => return true, si l'objet est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }

    /**
     * fonction de retour du statut de l'utilisateur courant
     * => return string value
     */
    getLibelleStatus() {
        if (this.currentRow && this.currentRow.libelleTypeStatut) {
            return this.currentRow.libelleTypeStatut;
        }
        return '';
    }

    /**
     * fonction de retour de affectionPrescriptionLier de l'utilisateur courant
     * => return boolean
     */
    getAffectionPrescriptionLier() {
        if (this.currentRow && this.currentRow.affectionPrescriptionLier) {
            return this.currentRow.affectionPrescriptionLier;
        }
        return false;
    }

    getTabChange(event) {
        this.currentRow = null;
        this.tabActive = '';
        this.tabViewFirstLevelActive = 0;

        this.currentRow = this.currentDataTemp;
        this.tabActive = event.originalEvent.target['innerText'];
    }

    systemeAffichActe() {
        return this.getLibelleStatus() === STATUT_CLOTURE;
    }

    systemeAffichSoinsHospitalisation() {
        return !!this.currentRow.estHospitalisation;

        // return this.getLibelleStatus() === STATUT_EN_VALIDATION_AVIS_HOSPI;
        // ||
        // this.getLibelleStatus() === STATUT_EN_ATTENTE_DE_CLOTURE;
    }

    systemeAffichAffections() {
        // return this.getLibelleStatus() !== STATUT_EN_VALIDATION_AVIS_HOSPI;
        /*&&
        this.getLibelleStatus() !== STATUT_REJETE &&
        this.getLibelleStatus() !== STATUT_EN_ATTENTE_DE_CLOTURE;*/

        return true;
    }

    systemeAffichPrescriptions() {
        return !this.getAffectionPrescriptionLier() &&
            this.getLibelleStatus() !== STATUT_EN_VALIDATION_AVIS_HOSPI &&
            this.getLibelleStatus() !== STATUT_REJETE &&
            this.getLibelleStatus() !== STATUT_EN_ATTENTE_DE_CLOTURE;
    }

    systemeAffichAutreMotifAccord() {
        return this.getLibelleStatus() === STATUT_EN_ACCORD_PREALABLE;
    }

    systemeAffichMotifRejet() {
        return this.getLibelleStatus() === STATUT_REJETE;
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        if (data.libelleTypeStatut === 'Cloturé') {
            shower = this.alertService.showError(ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC);
            shower.show();
            return;
        }

        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.metierService.requeteSuppression(data).subscribe(() => {
                // this.metierService.requeteRecuperationListeDonnees({});
                this.recuperationDonnees();
            });
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    /**
     * Systeme de detection et de rechargement des données après enregistrement
     * @param event
     */
    handleSaveDemandeSuccess(dataEvent) {
        const {event, args} = dataEvent;

        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(event) &&
            CommonUtilities.isDataNullUndefinedFalseEmpty(event.numBonPriseEnCharge) &&
            !CommonUtilities.isDataNullUndefinedFalseEmpty(event.descriptions)) {

            this.alertService.showError(`Demande de prise en charge est au statut << ${event.libelleTypeStatut} >> , car : ${event.descriptions}`).show();

            CommonUtilities.playAudioError();
        }

        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(event) &&
            CommonUtilities.isDataNullUndefinedFalseEmpty(event.numBonPriseEnCharge)) {

            this.recuperationDonnees();
        }

        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(event) &&
            !CommonUtilities.isDataNullUndefinedFalseEmpty(event.numBonPriseEnCharge)) {

            this.recuperationDonnees();

            if (CommonUtilities.isDataNullUndefinedFalseEmpty(args)) {
                this.requetePrintDocument(
                    {numBonPriseEnCharge: event.numBonPriseEnCharge},
                    '-- Bon de prise en charge N°',
                    event.numBonPriseEnCharge,
                );
            }
        }

        if (CommonUtilities.isDataNullUndefinedFalseEmpty(event)) {

            this.closeAllModal();
            this.recuperationDonnees();

            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(args) &&
                !CommonUtilities.isDataNullUndefinedFalseEmpty(args.data) &&
                !CommonUtilities.isDataNullUndefinedFalseEmpty(args.statut) &&
                !CommonUtilities.isDataNullUndefinedFalseEmpty(args.data.responseClotureDemande)) {

                /** */
                if (!CommonUtilities.isDataNullUndefinedFalseEmpty(args.data.responseClotureDemande.descriptions)) {

                    this.alertService.showError(`${args.data.responseClotureDemande.descriptions}`).show();

                    CommonUtilities.playAudioError();
                }
                /** */

                if ((!CommonUtilities.isDataNullUndefinedFalseEmpty(args.data.responseClotureDemande.idDemandeComplementaire) &&
                    args.data.responseClotureDemande.idDemandeComplementaire !== 0) &&

                    (args.data.responseClotureDemande.listDemandePriseEnChargeLigneComplementaire &&
                        args.data.responseClotureDemande.listDemandePriseEnChargeLigneComplementaire.length !== 0) &&

                    this.isDetecteEstConventionner(args.data.responseClotureDemande.listDemandePriseEnChargeLigneComplementaire)) {

                    let messageAlert: string;
                    if (!CommonUtilities.isDataNullUndefinedFalseEmpty(args.data.adherent.numBonPriseEnCharge)) {
                        messageAlert = `${MESSAGE_POURSUIVRE_DEMANDE_APRES_CLOTURE}, numéro : ${args.data.adherent.numBonPriseEnCharge} ?`;
                    } else {
                        messageAlert = `${MESSAGE_POURSUIVRE_DEMANDE_APRES_CLOTURE} ?`;
                    }

                    let showerContinuerSurPoursuite: any;
                    showerContinuerSurPoursuite = this.alertService.showWarning(messageAlert);
                    showerContinuerSurPoursuite.accept = () => {
                        this.affichModalDemandePEC = true;
                        this.gestionnaireDeModales[0].id = 'poursuite_demande';
                        this.gestionnaireDeModales[0].datasourceSelected = {
                            adherent: args.data.adherent,
                            responseClotureDemande: args.data.responseClotureDemande,
                            listActesPoursuite: []
                        };
                        this.gestionnaireDeModales[0].display = true;
                    };
                    showerContinuerSurPoursuite.reject = () => {
                        this.closeAllModal();
                    };
                    showerContinuerSurPoursuite.show();
                }

            }

            return;
        }

        /**
         * Demande si continuer avec la famille
         */
        let showerContinueFamille: any;
        showerContinueFamille = this.alertService.showWarning(MESSAGE_CONTINUE_FAMILLE_DEMANDE_PEC);
        showerContinueFamille.accept = () => {
            this.refresh = true;
            setTimeout(() => {
                this.refresh = false;
            }, 2000);
        };
        showerContinueFamille.reject = () => {
            this.closeAllModal();
            this.refresh = false;
        };
        showerContinueFamille.show();
    }

    isDetecteEstConventionner(arrayList: any[]) {
        return arrayList.some((elt) => elt.estConventionne === true);
    }


    /**
     *
     * ## Action des boutons situé au dessus du tableau
     *
     */

    ajouterNouvelleDemande() {
        /*if (this.securityStore.alertNotPermissionAction('userPermissionAction')) {
            return false;
        }*/

        this.closeAllModal();

        let alertMessage: any;
        this.metierService.verificationNouvelleDemandePrestataire({}).subscribe((data) => {
            if (!data.body.items) {
                alertMessage = this.alertService.showError(ERROR_MESSAGE_VERIFICATION_NOUVELLE_DEMANDE);
                alertMessage.show();
                return;
            }

            this.affichModalDemandePEC = true;
            this.gestionnaireDeModales[0].id = 'nouvelle_demande';
            this.gestionnaireDeModales[0].datasourceSelected = undefined;
            this.gestionnaireDeModales[0].display = true;
        });
    }

    ajouterNouvelleDemandeHospitalisation() {
        this.closeAllModal();

        let alertMessage: any;
        this.metierService.verificationNouvelleDemandePrestataire({}).subscribe((data) => {
            if (!data.body.items) {
                alertMessage = this.alertService.showError(ERROR_MESSAGE_VERIFICATION_NOUVELLE_DEMANDE);
                alertMessage.show();
                return;
            }

            this.affichModalDemandePEC = true;
            this.gestionnaireDeModales[0].id = 'nouvelle_demande_hospitalisation';
            this.gestionnaireDeModales[0].display = true;
            this.gestionnaireDeModales[0].datasourceSelected = undefined;
        });
    }

    cloturerDemande(data: any) {
        this.closeAllModal();

        let alertMessage: any;

        if (!this.tableSelectedElement) {
            alertMessage = this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE);
            alertMessage.show();
            return;
        }

        if (this.tableSelectedElement.libelleTypeStatut !== STATUT_EN_ATTENTE_DE_CLOTURE) {
            alertMessage = this.alertService.showError(ERROR_MESSAGE_CLOTURE_DEMANDE_PEC);
            alertMessage.show();
            return;
        }

        this.metierService.requeteVerificationFaireAction({}, this.getArgs()).subscribe(() => {
            this.metierService.requeteRecuperationActesCloture(
                {},
                {...this.getArgs(), adherant: this.tableSelectedElement}
            ).subscribe((reponseActes) => {
                const actesCloture = reponseActes.body.items;

                /*this.metierService.requeteRecuperationListGarant({}, {...this.getArgs()}).subscribe((reponseListGarant) => {
                    this.securityStore.saveListGarant({
                        affectionPrescriptionLier: reponseListGarant.body.items[0].affectionPrescriptionLier,
                        affectionPrescriptionFacultativePrestation: reponseListGarant.body.items[0].affectionPrescriptionFacultativePrestation,
                        horaireAutomatiquePrestation: reponseListGarant.body.items[0].horaireAutomatiquePrestation,
                        autoriserMontantDette: reponseListGarant.body.items[0].autoriserMontantDette,
                        automatiqueActeMontantDette: reponseListGarant.body.items[0].automatiqueActeMontantDette,
                        affectionFacultativePrestation: reponseListGarant.body.items[0].affectionFacultativePrestation,
                        gestionBonPhysique: reponseListGarant.body.items[0].gestionBonPhysique
                    });
                });*/

                this.affichModalDemandePEC = true;
                this.gestionnaireDeModales[0].id = 'cloture_demande';
                this.gestionnaireDeModales[0].datasourceSelected = {
                    ...data,
                    listActesCloture: actesCloture,
                };
                this.gestionnaireDeModales[0].display = true;
            });
        });

    }

    imprimerBon(data: any) {
        this.closeAllModal();

        if (!this.tableSelectedElement) {
            this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE).show();
            return;
        }

        this.requetePrintDocument(
            {numBonPriseEnCharge: this.tableSelectedElement.numBonPriseEnCharge},
            '-- Bon de prise en charge N°',
            this.tableSelectedElement.numBonPriseEnCharge,
        );
    }

    soinsHospitalisation(data: any) {
        this.closeAllModal();

        if (this.tableSelectedElement && !this.tableSelectedElement.estHospitalisation) {
            this.alertService.showError(ERROR_MESSAGE_PRISE_EN_CHARGE_HOSPITILISATION).show();
            return;
        }

        if (this.tableSelectedElement && this.tableSelectedElement.libelleTypeStatut !== STATUT_EN_ATTENTE_DE_CLOTURE) {
            this.alertService.showError(ERROR_MESSAGE_CLOTURE_DEMANDE_PEC).show();
            return;
        }

        if (this.tableSelectedElement) {
            this.metierService.requeteRecuperationAdherantHospitalisation(
                {}, {...this.getArgs(), adherant: this.tableSelectedElement}).subscribe((reponseAdherant) => {

                if (reponseAdherant.body.items.length === 0) {
                    this.alertService.showError(ERROR_MESSAGE_ITEMS_VIDE).show();
                    return false;
                }

                const dataAdherant = reponseAdherant.body.items[0];

                this.metierService.requeteRecuperationSoinsHospitalisation(
                    {},
                    {
                        ...this.getArgs(),
                        adherant: {
                            idDemandePEC: dataAdherant.idDemandePriseEnCharge,
                            idDemandePECLigne: dataAdherant.idDemandePriseEnChargeLigne
                        }
                    }).subscribe((reponseSoins) => {
                    this.affichModalDemandePEC = true;
                    this.gestionnaireDeModales[0].id = 'prolonger_hospitalisation';
                    this.gestionnaireDeModales[0].datasourceSelected = {
                        ...data,
                        adherant: this.tableSelectedElement,
                        reponseAdherant: dataAdherant,
                        reponseSoins: reponseSoins.body.items,
                        dataExist: true,
                        searchUser: false,
                        choixHospitalisation: null,
                        typeHospitalisation: null,
                    };
                    this.gestionnaireDeModales[0].display = true;
                });
            });
        } else {
            this.affichModalDemandePEC = true;
            this.gestionnaireDeModales[0].id = 'prolonger_hospitalisation';
            this.gestionnaireDeModales[0].datasourceSelected = {
                ...data,
                adherant: {},
                reponseAdherant: {},
                reponseSoins: [],
                dataExist: false,
                searchUser: false,
                choixHospitalisation: null,
                typeHospitalisation: null,
            };
            this.gestionnaireDeModales[0].display = true;
        }

    }

    prescriptionSupplementaire(data: any) {
        this.closeAllModal();

        if (!this.tableSelectedElement) {
            this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE).show();
            return;
        }

        if (this.tableSelectedElement.libelleTypeStatut !== STATUT_EN_ATTENTE_DE_CLOTURE) {
            this.alertService.showError(ERROR_MESSAGE_CLOTURE_DEMANDE_PEC).show();
            return;
        }

        this.requetePrintBonVide(
            {numBonPriseEnCharge: this.tableSelectedElement.numBonPriseEnCharge},
            '-- Bon de prise en charge N°',
            this.tableSelectedElement.numBonPriseEnCharge,
        );
    }

    requetePrintDocument(arg, message, value) {
        this.closeModalRapport();

        this.metierService.requetePrintDocument({}, {...arg}).subscribe((reponse) => {
            const arrayConstruct: any[] = [];
            arrayConstruct.push({
                File: reponse.body.items[0],
                Name: `${message} ${value}`,
            });

            this.affichModalPrintBon = true;
            this.gestionnaireDeModales[1].datasourceSelected = arrayConstruct;
            this.gestionnaireDeModales[1].display = true;
        });
    }

    requetePrintBonVide(arg, message, value) {
        this.closeModalRapport();

        this.metierService.requetePrintBonVide({}, {...arg}).subscribe((reponse) => {
            const arrayConstruct: any[] = [];
            arrayConstruct.push({
                File: reponse.body.items[0],
                Name: `${message} ${value}`,
            });

            this.affichModalPrintBon = true;
            this.gestionnaireDeModales[1].datasourceSelected = arrayConstruct;
            this.gestionnaireDeModales[1].display = true;
        });
    }

    colorLigne(row) {
        switch (row.libelleTypeStatut) {
            case STATUT_EN_ACCORD_PREALABLE :
                return 'color-row-red-PEC';
            default:
                return '';
        }

        // if (row.libelleTypeStatut === STATUT_EN_ACCORD_PREALABLE) { return 'color-row-red-PEC'; }
    }

    closeAllModal() {
        this.affichModalDemandePEC = false;
        this.gestionnaireDeModales[0].display = false;
        this.gestionnaireDeModales[0].datasourceSelected = undefined;

        this.affichModalPrintBon = false;
        this.gestionnaireDeModales[1].display = false;
        this.gestionnaireDeModales[1].datasourceSelected = undefined;
    }

    closeModalDemandePEC() {
        this.affichModalDemandePEC = false;
        this.gestionnaireDeModales[0].display = false;
        this.gestionnaireDeModales[0].datasourceSelected = undefined;
    }

    closeModalRapport() {
        this.affichModalPrintBon = false;
        this.gestionnaireDeModales[1].display = false;
        this.gestionnaireDeModales[1].datasourceSelected = undefined;
    }

}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {API_CALCULER_ACTES_DEMANDE_PEC, API_RECUPERER_ACTES_DEMANDE_PEC} from '@app/core/constantes';


@Injectable({providedIn: 'root'})
export class DemandeHospitalisationTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
    @Autowired() restService: SgiRestService;
    // @Autowired() datePipe: DatePipe;

    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteRecuperationTypeHospitalisation(data: any, args?: any): Observable<any> | Promise<any> | any {
        const {adherant} = args;

        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                datePrestation: adherant.dateDemande,
                estChambre: false,
                estGroupeSoin: true,
                idPrestataire: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                idSouscriptionLigne: adherant.idSouscriptionLigne,
                InfoSearchdatePrestation: {Consider: true},
                InfoSearchestChambre: {Consider: true},
                InfoSearchestGroupeSoin: {Consider: true},
                InfoSearchidPrestataire: {Consider: true},
                InfoSearchidSouscriptionLigne: {Consider: true},
            },
            Siz: 100,
            TakeAll: true,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ACTES_DEMANDE_PEC}`, sendData, {...this.postArgsMute});
    }

    requeteCalculMontant(data: any, args?: any): Observable<any> | Promise<any> | any {
        const {adherant, dataActe} = args;
        const sendData = {
            bloqued: true,
            ItemsToSave: [
                {
                    ...dataActe,
                    idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                    idSouscriptionLigne: adherant.idSouscriptionLigne
                }
            ],
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_CALCULER_ACTES_DEMANDE_PEC}`, sendData, {...this.postArgsMute});
    }

}

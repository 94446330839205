import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {TypeDocumentAutocompleteComponent} from './type-document.autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [TypeDocumentAutocompleteComponent],
  exports: [TypeDocumentAutocompleteComponent]
})
export class TypeDocumentAutocompleteFormModule {
}


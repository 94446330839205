import {TableColumn} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {
    STATUT_CLOTURE,
    STATUT_EN_ACCORD_PREALABLE,
    STATUT_EN_ATTENTE_DE_CLOTURE,
    STATUT_EN_VALIDATION_AVIS_HOSPI,
    STATUT_REJETE,
} from '@app/core/constantes';

export const columns: Array<TableColumn> = [
    // {field: 'rowCount', header: '#', type: () => 'rowCount'},
    {field: 'photo', header: 'Photo', type: () => 'image'},
    {field: 'raisonSocialeClientGarant', header: 'Garant'},
    {field: 'dateDemande', header: 'Date', type: () => 'date'},
    {field: 'matricule', header: 'Matricule'},
    {field: 'nomAssure', header: 'Assuré'},
    {field: 'numBonPriseEnCharge', header: 'N° Bon'},
    // {field: 'estHospitalisation', header: 'Hospit', type: () => 'dropdown', dropdownList: () => BOOLEAN_TYPE_COMBO},
    /*  {field: 'estHospitalisation', header: 'Hospit', type: () => 'combo', states: [
        {key: true, label: 'Oui', classes: 'bg-success-imp'}, // bg-success-imp
        {key: false, label: 'Non', classes: ''},
      ]},*/
    {
        field: 'estHospitalisation', header: 'Hospit', composedField: (data) => {
            return data['estHospitalisation'] ? 'Oui' : 'Non';
        }
    }, // , type: () => 'price'
    {
        field: 'libelleTypeStatut', header: 'Statut', type: () => 'chip', states: [
            {key: STATUT_EN_ACCORD_PREALABLE, label: 'En accord préalable', classes: 'color-row-red-PEC'},
            {key: STATUT_EN_ATTENTE_DE_CLOTURE, label: 'En attente de clôture', classes: 'color-row-white-PEC'}, // bg-warning-imp
            {key: STATUT_CLOTURE, label: 'Clôturé', classes: 'color-row-white-PEC'}, // bg-success-imp
            {key: STATUT_REJETE, label: 'Rejete', classes: 'color-row-white-PEC'}, // bg-amber-imp
            {key: STATUT_EN_VALIDATION_AVIS_HOSPI, label: 'En validation de l\'avis d\'hospit.', classes: 'color-row-red-PEC'},
        ]
    },
];

import {Pipe, PipeTransform} from '@angular/core';

/**
 * Trunc des chaines de caractères. La limite de chaine par defaut est 10.
 * Prends un argument de type entier permettant de définir la limite
 */
@Pipe({
  name: 'truncate'
})
export class TruncatePipe implements PipeTransform {
  transform(value: string, args?): string {
    const limit = args > 0 ? parseInt(`${args}`) : 10;
    return value.length > limit ? value.substring(0, limit) + '...' : value;
  }
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {InformationDossierTableRestApiService} from './information-dossier-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class InformationDossierTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: InformationDossierTableRestApiService;
  @Autowired() formBuilder: FormBuilder;

  @Autowired() datePipe: DatePipe;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaireAssure<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      garant: !!datas && !!datas.raisonSocialeGarant ? datas.raisonSocialeGarant : null,
      matricule: !!datas && !!datas.matricule ? datas.matricule : null,
      nom: !!datas && !!datas.nom ? datas.nom : null,
      prenoms: !!datas && !!datas.prenoms ? datas.prenoms : null,
      sexe: !!datas && !!datas.sexe ? datas.sexe : null,
      age: !!datas && !!datas.age ? datas.age : null,
      dateNaissance: !!datas && !!datas.dateNaissance ? this.datePipe.transform(datas.dateNaissance, 'dd/MM/yyyy') : null,
      tauxCouverture: !!datas && !!datas.tauxCouverture ? datas.tauxCouverture : null,
      telephone: !!datas && !!datas.telephone1 ? datas.telephone1 : null,

      matriculeAdherent: !!datas && !!datas.matriculeAdherent ? datas.matriculeAdherent : null,
      nomPrenomsAdherent: !!datas && !!datas.nomCompletPersonneAdh ? datas.nomCompletPersonneAdh : null,
      sexeAdherent: !!datas && !!datas.sexeAdh ? datas.sexeAdh : null,
      tel1Adherent: !!datas && !!datas.telephone1Adh ? datas.telephone1Adh : null,
      tel2Adherent: !!datas && !!datas.telephone2Adh ? datas.telephone2Adh : null,
    };

    return this.formBuilder.group({
      garant: [{value: data.garant, disabled: true}],
      matricule: [{value: data.matricule, disabled: true}],
      nom: [{value: data.nom, disabled: true}],
      prenoms: [{value: data.prenoms, disabled: true}],
      sexe: [{value: data.sexe, disabled: true}],
      age: [{value: data.age, disabled: true}],
      dateNaissance: [{value: data.dateNaissance, disabled: true}],
      tauxCouverture: [{value: data.tauxCouverture, disabled: true}],
      telephone: [{value: data.telephone, disabled: true}],

      matriculeAdherent: [{value: data.matriculeAdherent, disabled: true}],
      nomPrenomsAdherent: [{value: data.nomPrenomsAdherent, disabled: true}],
      sexeAdherent: [{value: data.sexeAdherent, disabled: true}],
      tel1Adherent: [{value: data.tel1Adherent, disabled: true}],
      tel2Adherent: [{value: data.tel2Adherent, disabled: true}],
    });
  }

}

import {Component, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {AssureMetierService} from '../assure.metier.service';
import * as assureConstantes from '../assure.constante';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';


@Component({
  selector: 'app-assure-liste-feature',
  templateUrl: './assure-liste.feature.component.html'
})
export class AssureListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() suppressionDonneeMetier: AssureMetierService;
  @Autowired() recuperationListeDonneesMetier: AssureMetierService;
  @Autowired() metierService: AssureMetierService;

  assureConstantes = assureConstantes;

  tableSelectedElement: any;
  typeRechercheCombo: any[] = [];
  placeholderInputSearchAssure: string;

  constructor() {
    super();
    this.cols = this.assureConstantes.columns;
    this.tableActions = [this.consulterAction];
    this.notRowActions = [];
  }

  ngOnInit() {
    super.ngOnInit();
    // this.recuperDonnees();
    this.getListElements();

    this.initialiserTypeRechercheCombo();
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args,
      // matricule: '',
      // garant: '',
      // systemeRecherche: '', // matricule, garant
    };
    this.args = {...arg};
  }

  initialiserFormulaire() {
    this.formGroup = this.metierService.initialiserFormulaire(null);
  }


  /**
   * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
   */
  selectCurrentRow(data?: any) {
    this.currentRow = data;
  }

  /**
   * => return true, si le tableau est vide
   */
  isEmptyObject(obj) {
    return CommonUtilities.isEmptyObject(obj);
  }

  initialiserTypeRechercheCombo() {
    this.placeholderInputSearchAssure = 'Rechercher';
    this.typeRechercheCombo = [
      { label: 'Nom & Prénoms', value: 'garant'},
      { label: 'Matricule', value: 'matricule'}
    ];
    // this.formGroup.patchValue({ selectTypeSearch: 'garant' });
  }

  handleChangeTypeRecherche(event: any) {
    if (event.value === null) {
      this.placeholderInputSearchAssure = 'Rechercher';

      this.formGroup.patchValue({ inputSearch: null });

      // fonction de recherche assuré avec les valeurs null
      this.recuperDonnees();
    }

    if (event.value === 'garant') {
      this.placeholderInputSearchAssure = 'Rechercher par garant';
    }

    if (event.value === 'matricule') {
      this.placeholderInputSearchAssure = 'Rechercher par matricule';
    }
  }

  rechercheAssure(event) {
    if (this.formGroup.getRawValue().selectTypeSearch === null) { return false; }

    // fonction de recherche assuré avec les valeurs entrées
    this.recuperDonnees();
  }

  /**
   * Effectue une visualisation du dossier médical
   * @param data
   */
  consulterDocument(data: any) {
    const assureSelected = data;

    /**
     * Ouverture du modale-subtitution => Get datas request
     */
    this.gestionnaireDeModales[0].id = 'consulter_dossier_medical';
    this.gestionnaireDeModales[0].datasourceSelected = {
      assureSelected: assureSelected,
      responsePrestations: [],
      responseActes: [],
      responseResultatsExamen: [],
      responsePrescriptions: [],
      responseAffections: [],
      maladieChronique: null,
      antecedent: null,
    };
    this.gestionnaireDeModales[0].display = true;

    /**
     * Get datas request => Ouverture du modale-subtitution
     */
    /*this.metierService.requeteDossierMedicalAssure({}, assureSelected).subscribe((r_Prestations) => {
      const responsePrestations = r_Prestations.body;

      this.metierService.requetePrestationActes({}, assureSelected).subscribe((r_Actes) => {
        const responseActes = r_Actes.body;

        this.metierService.requeteResultatsExamen({}, assureSelected).subscribe((r_ResultatsExamen) => {
          const responseResultatsExamen = r_ResultatsExamen.body;

          this.metierService.requetePrescriptions({}, assureSelected).subscribe((r_Prescriptions) => {
            const responsePrescriptions = r_Prescriptions.body;

            this.metierService.requeteAffections({}, assureSelected).subscribe((r_Affections) => {
              const responseAffections = r_Affections.body;

              this.gestionnaireDeModales[0].id = 'consulter_dossier_medical';
              this.gestionnaireDeModales[0].datasourceSelected = {
                assureSelected: data,
                prestations: responsePrestations,
                actes: responseActes,
                resultatsExamen: responseResultatsExamen,
                prescriptions: responsePrescriptions,
                affections: responseAffections,
                maladieChronique: null,
                antecedent: null,
              };
              this.gestionnaireDeModales[0].display = true;
            });
          });
        });
      });
    });*/

  }

}

import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import * as prescription from '../prescripton-pec.constante';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {
    PERMISSION_PRESTATION_PHARMACIE_AJOUTER,
    PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE_PRISEENCHARGE,
    PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE_SUBSTITUER,
    PERMISSION_PRESTATION_PHARMACIE_PRISEENCHARGE,
    PERMISSION_PRESTATION_PHARMACIE_SUBSTITUER,
    STATUT_EN_ACCORD_PREALABLE,
    STATUT_REJETE_PRESCRIPTION
} from '@app/core/constantes';
import {FormBuilder, FormGroup} from '@angular/forms';
import {PrescriptonPecMetierService} from '../prescripton-pec.metier.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';

@Component({
    selector: 'app-prescription-pec-details-liste-feature',
    templateUrl: './prescription-pec-details-liste.feature.component.html',
    styleUrls: ['./prescription-pec-details-liste.feature.component.css']
})
export class PrescriptionPecDetailsListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: PrescriptonPecMetierService;
    @Autowired() recuperationListeDonneesMetier: PrescriptonPecMetierService;
    @Autowired() metierService: PrescriptonPecMetierService;
    @Autowired() securityStateService: SecurityStateService;

    poursuitePECConstantes = prescription;
    tabActive = 'Informations sur l\'assuré';
    currentDataTemp: any;

    @Autowired() formBuilder: FormBuilder;

    @Input() dataPrescription: any;
    @Input() sectionAffichage: string;

    affichModalPrintBon = false;
    tableSelectedElement: any;
    searchForm: FormGroup;
    prescription: any = [];
    // prescription$: Observable<any[]>
    retourCalcul: any;
    montantTotal: any;
    calculEffectuer: boolean;
    autoristaionDeCalcul = true;
    ajouterPrescription: boolean;
    subtitution: boolean;
    storageUser: IUserConnect = this.securityStateService.getUser();
    descriptions: string;

    permissionViewBoutonAjouter: boolean;
    permissionViewBoutonSubstituer: boolean;
    permissionViewBoutonEnregistrer: boolean;

    maxMontantPrescription: number;

    enAttenteValidationDette: boolean;

    STATUT_REJETE = STATUT_REJETE_PRESCRIPTION;
    STATUT_EN_ACCORD_PREALABLE = STATUT_EN_ACCORD_PREALABLE;

    constructor() {
        super();
        this.cols = this.poursuitePECConstantes.columns;
        this.tableActions = [];
        this.gestionnaireDeModales.push({display: false, datasourceSelected: new Object(null), typeOpenModal: 'create'});
    }

    ngOnInit() {
        this.selectedColumns = this.cols;
        this.getListElements();
        this.conditonDeRecuperation();

        if (this.sectionAffichage === 'prescriptions_prise_en_charge') {
            this.permissionViewBoutonAjouter = CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_PHARMACIE_AJOUTER);
            this.permissionViewBoutonSubstituer = CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_PHARMACIE_SUBSTITUER);
            this.permissionViewBoutonEnregistrer = CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_PHARMACIE_PRISEENCHARGE);

        } else if (this.sectionAffichage === 'prescriptions_periodiques') {
            this.permissionViewBoutonAjouter = CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_PHARMACIE_AJOUTER);
            this.permissionViewBoutonSubstituer =
                CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE_SUBSTITUER);
            this.permissionViewBoutonEnregistrer =
                CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE_PRISEENCHARGE);
        }
    }


    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }


    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
        this.tabActive = '';
    }

    /**
     * => return true, si le tableau est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }

    getTabChange(event) {
        this.currentRow = null;
        this.currentRow = this.currentDataTemp;
        this.tabActive = event.originalEvent.target['innerText'];
    }

    modifierDonnee(modalManager: GestionnaireDeModales, data: any) {
        data.newSubtituant = true;
        data.idPrestatairePharmacie = JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical;
        data.idBonPriseEnCharge = this.dataPrescription.idBonPriseEnCharge;
        data.idPrestataireMedical = this.dataPrescription.idPrestataire;
        data.idTermeConvention = this.dataPrescription.idTermeConvention;
        data.quantiteRealise = 0;

        modalManager.typeOpenModal = 'update';
        modalManager.datasourceSelected = data;
        modalManager.display = true;
        this.onModifier.emit(modalManager);
    }


    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        if (data.libelleTypeStatut === 'Cloturé') {
            shower = this.alertService.showError('La demande de prise en charge ne doit pas ètre cloturé');
            shower.show();
            return;
        }

        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.metierService.requeteSuppression(data).subscribe(() => {
                this.recuperDonnees();
            });
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }


    /**
     * Vérifier si c'est une prescription de prise en charge ou une prescription périodique avec le 'numBonPriseEnCharge'
     * qui vient d'une prescription de prise en charge.
     */
    conditonDeRecuperation() {
        if (this.dataPrescription.numBonPriseEnCharge) {
            this.recuperDonneesPrescription();
            this.maxMontantPrescriptionFunc();
        } else {
            this.recuperDonneesPrescriptionPeriodique();
        }
    }

    recuperDonneesPrescription() {
        this.ajouterPrescription = true;
        this.metierService.recupererPrescriptionPEC({
            InfoSearchidPrestation: {Consider: true},
            idPrestation: this.dataPrescription.idPrestation
        }).subscribe(response => {
            /**
             * Affichage générale des description pour les prescriptions
             */
            let affichAllDescription = '';
            response.body.items.forEach((v) => {
                if (!CommonUtilities.isDataNullUndefinedFalseEmpty(v.descriptions)) {
                    affichAllDescription += `<br/> "${v.libelle}" : ${v.descriptions}`;
                }
            });
            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(affichAllDescription)) {
                this.alertService.showWarningCustom(`${affichAllDescription}`).show();
            }

            this.retourCalcul = null;
            this.calculEffectuer = false;
            this.prescription = response.body.items;

            // console.log('enAttenteValidationDette : ', this.enAttenteValidationDette);
            // console.log('prescription : ', this.prescription);

            /**
             * Système de vérouillage des boutons si l'un des éléments à enAttenteValidationDette = true
             *
             * on veut que toutes les ligne renvoyées soit False qui donne True, et on retourne le contraire False sur les boutons
             */
            // console.log('every ** : ', !this.prescription.every((v) => !v.enAttenteValidationDette));
            this.enAttenteValidationDette = !this.prescription.every((v) => !v.enAttenteValidationDette);

            this.prescription.forEach(data => {

                if (data.idMedecin !== null && data.idMedecin !== 0) {
                    this.ajouterPrescription = false;
                }

                if (data.substitue === true) {
                    data.nomOrigine = 'Substitué';
                } else if (data.realise === true) {
                    data.nomOrigine = 'Réalisé';
                } else {
                    data.estSubstituantNew = false;
                }

                if (data.quantiteRealise) {
                    data.quantiteRestante = data.quantite - data.quantiteRealise;
                } else {
                    data.quantiteRestante = data.quantite;
                }

                data.quantiteRealise = 0;
                data.prixUnitaire = 0;
                data.montantHT = 0;


            });
            this.montantTotal = 0;
        });
    }

    maxMontantPrescriptionFunc() {
        this.metierService.recupererMaxMontantPrescriptionPEC({
            idTermeConvention: this.dataPrescription.idTermeConvention
        }).subscribe(response => {
            this.maxMontantPrescription = response.body.items[0].maxMontantPrescription;
        });
    }

    recuperDonneesPrescriptionPeriodique() {
        this.ajouterPrescription = false;
        this.metierService.recupererPrescriptionPeriodique({
            IdSouscriptionLigne: this.dataPrescription.idSouscriptionLigne,
            InfoSearchIdSouscriptionLigne: {Consider: true}
        }).subscribe(response => {
            this.calculEffectuer = false;
            this.retourCalcul = null;
            this.prescription = response.body.items;
            this.prescription.forEach(data => {

                if (data.substitue === true) {
                    data.nomOrigine = 'Substitué';
                } else {
                    data.estSubstituantNew = false;
                }

                if (data.quantiteRealise) {
                    data.quantiteRestante = data.quantite - data.quantiteRealise;
                } else {
                    data.quantiteRestante = data.quantite;
                }

                data.quantiteRealise = 0;
                data.prixUnitaire = 0;
                data.montantHT = 0;

            });
            this.montantTotal = 0;
        });
    }


    change() {
        this.autoristaionDeCalcul = true;
        this.calculEffectuer = false;
        this.prescription.forEach(data => {
            if (data.quantiteRealise !== null) {
                data.quantiteRealise = +data.quantiteRealise;
                data.quantiteRestante = +data.quantiteRestante;
                if (data.quantiteRealise > data.quantiteRestante) {
                    this.alertService.showError('La quantité réalisé doit être inferieur à la quantité restante').show();
                    this.autoristaionDeCalcul = false;
                    return false;
                }
                data.montantHT = data.quantiteRealise * data.prixUnitaire;
            }
        });
    }

    /** CETTE FONCTION PERMET DE LES DIFFERENTS MONTANT DES MEDICAMENT ET LES PARTS DE CHAQU'UN */
    calculer() {

        if (this.autoristaionDeCalcul === false) {
            this.alertService.showError('La quantité réalisé doit être inferieur à la quantité restante').show();
            return;
        }

        this.change();
        this.dataPrescription.prestationPrescriptionRealises = this.prescription;

        this.metierService.calculerMontantPrescription(this.dataPrescription)
            .subscribe(response => {
                this.retourCalcul = response.body.items[0];
                this.calculEffectuer = true;
                this.montantTotal = this.retourCalcul.partAssureNet + this.retourCalcul.partAssurance;

                if (this.montantTotal > this.maxMontantPrescription) {
                    this.alertService.showWarning('Le montant total est supérieur au montant maximum de prescription !',
                        'Attention').show();
                }

                this.prescription.forEach((itemPrescrit, index) => {
                    this.retourCalcul.prestationPrescriptionRealises.forEach(data => {
                        if (itemPrescrit.idMedicament === data.idMedicament
                            && itemPrescrit.idPrestationPrescription === data.idPrestationPrescription) {
                            itemPrescrit.partAssure = data.partAssure;
                        }
                        if (itemPrescrit.descriptions !== '' && itemPrescrit.descriptions !== null
                            && itemPrescrit.descriptions !== undefined) {
                            this.descriptions += itemPrescrit.descriptions + ' ';
                        }
                        if (this.prescription.length === index) {
                            this.alertService.showWarning(this.descriptions).show();
                        }
                    });
                });

                let affichAllDescription = '';
                if (!CommonUtilities.isDataNullUndefinedFalseEmpty(this.retourCalcul.descriptions)) {
                    affichAllDescription += this.retourCalcul.descriptions;
                }

                if (!CommonUtilities.isDataNullUndefinedFalseEmpty(this.retourCalcul.prestationPrescriptionRealises)) {
                    this.retourCalcul.prestationPrescriptionRealises.forEach((v) => {
                        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(v.descriptions)) {
                            affichAllDescription += `<br/> ${v.descriptions}`;
                        }
                    });
                }

                if (!CommonUtilities.isDataNullUndefinedFalseEmpty(affichAllDescription)) {
                    this.alertService.showWarningCustom(`${affichAllDescription}`).show();
                }
            });
    }

    /** CETTE FONCTION PERMET DE RECUPERER LE SUBTITUANT A LA FERMETURE DE LA MODALE DE SUBTITUTION */
    recupSubstituant(data) {
        data.substituant.newSubtituant = true;
        data.substituant.idPrestatairePharmacie = JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical;
        data.substituant.idBonPriseEnCharge = this.dataPrescription.idBonPriseEnCharge;
        data.substituant.idPrestataireMedical = this.dataPrescription.idPrestataire;
        data.substituant.idTermeConvention = this.dataPrescription.idTermeConvention;
        data.substituant.quantiteRealise = 0;
        data.substituant.idSouscriptionLigne = this.dataPrescription.idSouscriptionLigne;

        if (data.substituant.accordPrealable) {
            data.substituant.statutPrescription = STATUT_EN_ACCORD_PREALABLE;
        }

        this.prescription.push(data.substituant);
    }

    /** CETTE FONCTION PERMET DE RECUPERER LA PRESCRIPTION A LA FERMETURE DE LA MODALE DE D'AJOUT DE PRESCRIPTION */
    recupPrescription(item) {
        item.idPrestation = this.dataPrescription.idPrestation;
        item.idPrestationPrescription = null;
        item.idBonPriseEnCharge = this.dataPrescription.idBonPriseEnCharge;
        item.idSouscriptionLigne = this.dataPrescription.idSouscriptionLigne;
        item.idPrestataireMedical = null;
        item.idPrestataireMedical = this.dataPrescription.idPrestataire;
        item.idPrestataireMedical = this.dataPrescription.idPrestataire;
        item.idTermeConvention = this.dataPrescription.idTermeConvention;
        item.idPrestatairePharmacie = JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical;
        item.idPoolMedicalPharmacien = null;
        item.idMedecin = null;
        item.realise = false;
        item.substitue = false;
        item.quantiteRealise = 0;

        if (item.accordPrealable) {
            item.statutPrescription = STATUT_EN_ACCORD_PREALABLE;
        }

        this.prescription.push(item);
    }

    /** CETTE FONCTION PERMET DE SUPRIMER UN MEDICAMENT DE LA LISTE*/
    supprimerSubstituant(data, index) {
        this.prescription.splice(index, 1);

        this.prescription.forEach(item => {
            if (item.idMedicament === data.idMedicament || item.idPrestationPrescription === data.idPrestationPrescription) {
                item.substitue = false;
                item.estSubstitueNew = false;
                item.nomOrigine = null;
            }
        });
    }


    /** CETTE FONCTION PERMET D'ENREGISTRER LA LISTE DES MEDICAMENTS  PRESCRITE*/
    enregistrerPrescription() {
        const message: string = 'Total part assuré :' + this.retourCalcul.partAssureNet + 'Fcfa. Voulez - vous valider cette operation ?';
        let shower: any;
        shower = this.alertService.showWarning(message);
        shower.accept = () => {
            this.prescription.forEach(item => {
                item.idPrestataireMedical = this.securityStateService.getUser().personne.idPrestataireMedical;
                item.idPrestatairePharmacie = this.securityStateService.getUser().personne.idPrestataireMedical;
            });
            this.metierService.enregistrerPrescription(this.prescription).subscribe(response => {

                if (response.body.items[0].montantPrescription > this.maxMontantPrescription) {
                    this.alertService.showWarning('Votre ordonnance a été envoyé en validation chez le medecin conseil !',
                        'Information').show();
                }

                this.prescription = [];
                this.conditonDeRecuperation();
            });
        };
        shower.reject = () => {
        };
        shower.show();

    }

    getStatutPrescription(statutPrescription: any) {
        return statutPrescription === STATUT_EN_ACCORD_PREALABLE || statutPrescription === STATUT_REJETE_PRESCRIPTION;
    }
}

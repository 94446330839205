import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {AlertService} from '@app/core/services/alert.service';
import {PageLayoutComponent} from '@shared/behaviors/page-layout/page-layout.component';
import {Observable} from 'rxjs';
import {PrescriptionPeriodiqueMetierService} from '../prescription-periodique.metier.service';

@Component({
  selector: 'app-prescription-periodique-form',
  templateUrl: './prescription-periodique-form.component.html',
  styleUrls: ['./prescription-periodique-form.component.css']
})
export class PrescriptionPeriodiqueFormComponent extends PageLayoutComponent implements OnInit {

  @Autowired() formBuilder: FormBuilder;
  @Autowired() metierService: PrescriptionPeriodiqueMetierService;
  @Autowired() securityService: SecurityStateService;
  @Autowired() alertService: AlertService;
  result$: Observable<any> = new Observable<any>();
  searchForm: FormGroup;

  @Input() sectionAffichage: string;

  constructor() {
    super();
  }

  ngOnInit() {
    this.initFormulaire();
  }

  initFormulaire() {
    return this.searchForm = this.metierService.initialiserFormulaire(null);
  }

  rechercheBon() {
    this.searchForm.setValue({
      Matricule: this.searchForm.getRawValue().Matricule.trim(),
      NumBon: this.searchForm.getRawValue().NumBon.trim()
    });
    if (!this.searchForm.getRawValue().Matricule) {
      this.alertService.showError('Veillez remplir le champs "Numéro de Matricule"').show();
    } else {
      this.metierService.recupererListe({
        InfoSearchinfoSearchMatricule: {Consider: true},
        InfoSearchmatricule: {Consider: true},
        infoSearchMatricule: {consider: true, operatorToUse: 'EQUAL'},
        matricule: this.searchForm.getRawValue().Matricule,
      }).subscribe((response: any) => {
        if (response.body.items.length === 0) {
          this.alertService.showError('Matricule assuré Inexistant', 'ATTENTON').show();
        } else {
          this.result$ = this.metierService.recupererListeMaladieChroniquePharmacie({
            InfoSearchidSouscriptionLigne: {Consider: true},
            InfoSearchraisonSocialeGarant: {IsOrderByField: true, Consider: false},
            idSouscriptionLigne: response.body.items[0].idSouscriptionLigne
          });
        }
      });
    }
  }

}

import {Component, Input, OnInit} from '@angular/core';

@Component({
    selector: 'app-xlsx-preview',
    template: `
        <p-tabView>
            <ng-container *ngFor="let sheet of sheets">
                <p-tabPanel *ngIf="sheets" [closable]="true" (close)="handle($event)" [header]="sheet.header">
                    <app-csv-preview *ngIf="sheet" [(dataSource)]="sheet.content"></app-csv-preview>
                </p-tabPanel>
            </ng-container>
        </p-tabView>
    `
})
export class XlsxPreviewComponent implements OnInit {
    _dataSource: any;
    @Input('mode') mode: 'add'|'replace' = 'replace';
    @Input('dataSource') set dataSource(data: any) {
        this._dataSource = data;
        if (this.mode === 'replace') {
            this.sheets = [];
        }
        Object.keys(this._dataSource).forEach(sheet => {
            this.sheets.push({header: sheet, content: this._dataSource[sheet]});
        });
    }
    sheets: {header: string, content: any}[] = [];

    ngOnInit(): void {
    }

    handle(ev) {
        console.log(ev);
    }
}

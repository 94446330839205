import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DatePipe} from '@angular/common';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {API_RECUPERER_AFFECTION_DOSSIER_MEDICAL_ENCOURS} from '@app/core/constantes';
import {CommonUtilities} from '@app/core/utilities/common.utilities';


@Injectable({providedIn: 'root'})
export class AffectionDossierTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;

    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        let constructorParamRequest: any;

        if (args.formGroup.value.selectTypeSearch === '' || args.formGroup.value.selectTypeSearch === null) {
            constructorParamRequest = {
                IdSouscriptionLigne: args.assure.idSouscriptionLigne,
                InfoSearchIdSouscriptionLigne: {Consider: true},
                InfoSearchdatePrestation: {IsOrderByField: true, Consider: false},
            };
        }
        if (args.formGroup.value.selectTypeSearch === 'code_affection') {
            constructorParamRequest = {
                codeAffection: args.formGroup.value.inputSearch,
                IdSouscriptionLigne: args.assure.idSouscriptionLigne,
                InfoSearchcodeAffection: {Consider: true},
                InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
            };
        }
        if (args.formGroup.value.selectTypeSearch === 'libelle') {
            constructorParamRequest = {
                IdSouscriptionLigne: args.assure.idSouscriptionLigne,
                InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
                InfoSearchlibelleAffection: {Consider: true},
                libelleAffection: args.formGroup.value.inputSearch,
            };
        }
        if (args.formGroup.value.selectTypeSearch === 'type_affection') {
            constructorParamRequest = {
                IdSouscriptionLigne: args.assure.idSouscriptionLigne,
                InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
                InfoSearchlibelleTypeAffection: {Consider: true},
                libelleTypeAffection: args.formGroup.value.inputSearch,
            };
        }
        if (args.formGroup.value.selectTypeSearch === 'date_prestation') {
            constructorParamRequest = {
                datePrestation: `${CommonUtilities.formatDateReverseToServer(args.formGroup.value.inputSearch, this.datePipe)}T00:00:00.000Z`,
                IdSouscriptionLigne: args.assure.idSouscriptionLigne,
                InfoSearchdatePrestation: {Consider: false, IsOrderByField: true},
                InfoSearchIdSouscriptionLigne: {Consider: true},
            };
        }

        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                ...constructorParamRequest
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_AFFECTION_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
    }

}

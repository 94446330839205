import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {InformationAssureMetierService} from './information-assure.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {ERROR_MESSAGE_ITEMS_VIDE} from '@app/core/constantes';
import {HospitalisationAutreTableMetierService} from '@app/fonctionnalites/demande-pec/composants/hospitalisation-autre-table/hospitalisation-autre-table.metier.service';
import {Observable, of} from 'rxjs';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {InformationAssureTableMetierService} from '@app/fonctionnalites/demande-pec/composants/information-assure-table/information-assure-table.metier.service';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-information-assure-hospitalisation',
  templateUrl: './information-assure.component.html'
})

export class InformationAssureComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: InformationAssureMetierService;
  @Autowired() metierService: InformationAssureMetierService;

  @Autowired() metierServiceDemandePec: DemandePriseChargeMetierService;
  @Autowired() metierServiceHospitalisationAutre: HospitalisationAutreTableMetierService;
  @Autowired() metierServiceInformationTable: InformationAssureTableMetierService;
  @Autowired() datePipe: DatePipe;


  @Input() datasourceSelected: any;
  adherantForm: FormGroup;
  @Output() dataHospitalisationEvent = new EventEmitter<any>();

  requestGetTypeHospitalisation: boolean;
  dataRequestGetTypeHospitalisation: any[] = [];

  choixHospitalisationCombo: any[] = [];
  choixHospitalisationSelected: boolean;

  typeHospitalisationCombo$: Observable<any> = new Observable<any[]>();
  typeHospitalisationSelected = '';
  objectTypeHospitalisationSelected: any;


  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initFormulaire();

    if (!CommonUtilities.isDataNullUndefinedFalseEmpty(this.datasourceSelected) && this.datasourceSelected.dataExist) {

      this.metierServiceInformationTable.requeteGetAssure(
          {},
          {idDemandePriseEnCharge: this.datasourceSelected.adherant.idDemandePriseEnCharge}).subscribe(res => {
        // this.datasourceSelected.adherant.numBonPriseEnCharge = ;
        this.datasourceSelected.adherant.matricule = res.body.items[0].matricule;
        this.datasourceSelected.adherant.nomAssure = res.body.items[0].nomAssure;
        this.datasourceSelected.adherant.dateNaissance = res.body.items[0].dateNaissance;
        this.datasourceSelected.adherant.sexe = res.body.items[0].sexe;

        this.updateDataInput();
      });
    } else {
      this.initFormulaireNotAdherent();
    }

    /*if (!this.requestGetTypeHospitalisation) {
      this.metierServiceHospitalisationAutre.requeteRecuperationTypeHospitalisation({}, this.getArgs()).subscribe((response) => {
        this.dataRequestGetTypeHospitalisation = response.body.items;
        this.requestGetTypeHospitalisation = true;
      });
    }*/
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  initFormulaire() {
    this.adherantForm = this.metierService.initialiserFormulaireAssure(this.datasourceSelected.adherant);
  }

  initFormulaireNotAdherent() {
    this.adherantForm = this.metierService.initialiserFormulaireAssureSearch(null);
  }

  updateDataInput() {
    this.adherantForm.patchValue({
      priseEnCharge: this.datasourceSelected.adherant.numBonPriseEnCharge,
      matricule: this.datasourceSelected.adherant.matricule,
      nomComplet: this.datasourceSelected.adherant.nomAssure,
      dateNaissance: this.datePipe.transform(this.datasourceSelected.adherant.dateNaissance, 'dd/MM/yyyy'),
      genre: this.datasourceSelected.adherant.sexe,
    });
  }

  actionSelectedAdherent(event) {
    this.metierServiceDemandePec.requeteRecuperationAdherantHospitalisation(
        {}, {...this.getArgs(), adherant: event}).subscribe((reponseAdherant) => {

      if (reponseAdherant.body.items.length === 0) {
        this.alertService.showError(ERROR_MESSAGE_ITEMS_VIDE).show();
        this.adherantForm.reset();
        return false;
      }

      const dataAdherant = reponseAdherant.body.items[0];

      this.metierServiceDemandePec.requeteRecuperationSoinsHospitalisation(
          {},
          {
            ...this.getArgs(),
            adherant: { idDemandePEC: dataAdherant.idDemandePriseEnCharge, idDemandePECLigne: dataAdherant.idDemandePriseEnChargeLigne }
          }).subscribe((reponseSoins) => {

        this.datasourceSelected.adherant = event;
        this.datasourceSelected.reponseAdherant = dataAdherant;
        this.datasourceSelected.reponseSoins = reponseSoins.body.items;
        this.adherantForm = this.metierService.initialiserFormulaireAssureSearch(this.datasourceSelected.adherant);
        this.datasourceSelected.searchUser = true;

        this.dataHospitalisationEvent.emit(this.datasourceSelected);
      });
    });
  }


  /*handleChangeHospitalisation(event: any) {
    this.choixHospitalisationSelected = event.value;

    this.typeHospitalisationCombo$ = of([]);
    this.typeHospitalisationSelected = '';

    if (this.choixHospitalisationSelected !== null) {
      this.typeHospitalisationSelected = '';
      this.typeHospitalisationCombo$ = of([
        ...this.dataRequestGetTypeHospitalisation.find(
            (elt) => elt.estHospitalisation === this.choixHospitalisationSelected
        )
      ]);
    }

    /!** **************************************************** *!/
    this.datasourceSelected.choixHospitalisation = this.choixHospitalisationSelected;
    this.dataHospitalisationEvent.emit(this.datasourceSelected);
    /!** **************************************************** *!/
  }*/

  /*handleChangeType(event: any) {
    this.typeHospitalisationSelected = '';
    this.typeHospitalisationSelected = event.value;

    if (this.choixHospitalisationSelected && this.typeHospitalisationSelected !== '' && this.typeHospitalisationSelected !== null) {

      this.objectTypeHospitalisationSelected = this.dataRequestGetTypeHospitalisation.find(
          (elt) => elt.libelle === this.typeHospitalisationSelected);

      /!** **************************************************** *!/
      // this.dataHospitalisationAutres.hospitalisation.objectType = this.objectTypeHospitalisationSelected;
      // this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
      /!** **************************************************** *!/

    } else if (!this.choixHospitalisationSelected && this.typeHospitalisationSelected !== '' && this.typeHospitalisationSelected !== null) {

      this.objectTypeHospitalisationSelected = this.dataRequestGetTypeHospitalisation.find(
          (elt) => elt.libelle === this.typeHospitalisationSelected);

      /!** **************************************************** *!/
      // this.dataHospitalisationAutres.pharmacie.objectType = this.objectTypeHospitalisationSelected;
      // this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
      /!** **************************************************** *!/

    }
  }*/

}

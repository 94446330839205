import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormInputGroupComponent} from '@shared/components/inputs/form-input-group/form-input-group.component';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {ListFormErrorComponentModule} from '@shared/components/error/list-form-error/list-form-error.component.module';
import {ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,

        InfoBullWidgetComponentModule,
        TranslateModule,
        MatIconModule,
        ListFormErrorComponentModule,
        ReactiveFormsModule
    ],
    declarations: [
        FormInputGroupComponent,
    ],
    exports: [
        FormInputGroupComponent,
    ]
})
export class FormInputGroupComponentModule {
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IListGarant} from '@app/core/interfaces/list-garant/i-list-garant';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {PERMISSION_PRESTATION_DEMANDEPEC_CLOTURER_CLOTURE_ANALYSE} from '@app/core/constantes';


interface IDataHospitalisationAutres {
    choixHospitalisation: boolean;
    hospitalisation: {
        objectType: any;
        montant: number;
        accordPrealable: string;
        objectChambre: any;
        nombreJour: number;
        prixUnitaireChambre: number;
        montantHorsPec: number;
        ticketModerateur: number;
        numeroChambre: any;
        service: any;
    };
    pharmacie: {
        objectType: any;
        montant: number;
        accordPrealable: string;
        montantHorsPec: number;
        ticketModerateur: number;
    };
    motifSoin: string;
    affichageMontantTotal: {
        hospitalisation: number;
        pharmacie: number;
    };
}

@Component({
    selector: 'app-modale-cloture',
    templateUrl: './modale-cloture.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModaleClotureComponent extends DialogComponent implements OnInit {
    @Autowired() metierServiceDemande: DemandePriseChargeMetierService;

    @Autowired() securityState: SecurityStateService;


    listGarantUser: IListGarant;

    @Input() tableSelectedElement: any;
    tabActive = 'Informations';

    medecinSelected: any;
    @Output() medecinSelectedEvent = new EventEmitter<any>();

    listActesComp: any[] = [];
    @Output() dataActesCompEvent = new EventEmitter<any>();

    descriptionAll = '';
    @Output() descriptionsEvent = new EventEmitter<any>();

    listAffectionsSelected: any[] = [];
    @Output() dataListAffectionsSelectedEvent = new EventEmitter<any>();

    listPrescriptionSelected: any[] = [];
    @Output() dataListPrescriptionEvent = new EventEmitter<any>();

    affichageMontantTotal = {
        hospitalisation: 0,
        pharmacie: 0
    };
    @Output() affichageMontantTotalEvent = new EventEmitter<any>();

    dataHospitalisationAutres = {
        choixHospitalisation: null,
        hospitalisation: {
            objectType: {},
            montant: 0,
            objectChambre: {},
            nombreJour: 0,
            prixUnitaireChambre: 0,
            montantHorsPec: 0,
            ticketModerateur: 0,
            numeroChambre: '',
            service: '',
        },
        pharmacie: {
            objectType: {},
            montant: 0,
            montantHorsPec: 0,
            ticketModerateur: 0,
        },
        motifSoin: '',
        affichageMontantTotal: {
            hospitalisation: 0,
            pharmacie: 0,
        }
    };
    @Output() dataHospitalisationAutresEvent = new EventEmitter<any>();

    documentAjouter: any[] = [];
    @Output() documentAjouterEvent = new EventEmitter<any>();

    /**
     * View Tab Analyse
     */
    responseActesAnalyse: any[] = [];
    dataConstructTabAnalyse: any;

    permissionViewTabAnalyse: boolean;

    constructor() {
        super();

        this.listGarantUser = this.securityState.getListGarant();
    }

    ngOnInit() {
        /**
         * View Tab Analyse
         */
        this.dataConstructTabAnalyse = {
            medecin: this.modalManager.datasourceSelected.listActesCloture[0].nomCompletMedecin,
            idDemandePriseEnCharge: this.tableSelectedElement.idDemandePriseEnCharge,
        };
        // tslint:disable-next-line:max-line-length
        this.permissionViewTabAnalyse = CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_DEMANDEPEC_CLOTURER_CLOTURE_ANALYSE);

        /*if (this.permissionViewTabAnalyse) {
            this.metierServiceDemande.requeteRecuperationActesAnalyse(
                {}, {idDemandePriseEnCharge: this.tableSelectedElement.idDemandePriseEnCharge}).subscribe((reponseActe) => {
                this.responseActesAnalyse = reponseActe.body.items;
            });
        }*/
        /**
         *
         */


        if (this.modalManager.datasourceSelected.listActesCloture && this.modalManager.datasourceSelected.listActesCloture.length > 0) {
            this.modalManager.datasourceSelected.listActesCloture.forEach((data) => {

                /** préchargement des documents dans la liste */
                if (data && data.listBdDocuments && data.listBdDocuments.length > 0) {
                    data.listBdDocuments.forEach((itemDocument) => {
                        this.documentAjouter = [
                            ...this.documentAjouter,
                            ...itemDocument
                        ];
                    });
                }

                /** préchargement des affections dans la liste */
                if (data && data.listAffection && data.listAffection.length > 0) {
                    data.listAffection.forEach((itemAffection) => {
                        this.listAffectionsSelected = [
                            ...this.listAffectionsSelected,
                            {...itemAffection, addElementExtern: true}
                        ];
                    });
                }
            });

            this.documentAjouterEvent.emit(this.documentAjouter);
            this.dataListAffectionsSelectedEvent.emit(this.listAffectionsSelected);
        }
    }

    getTabChange(event) {
        this.tabActive = event.originalEvent.target['innerText'];
    }

    handleMedecin(event) {
        this.medecinSelected = event;
        this.medecinSelectedEvent.emit(this.medecinSelected);
    }

    handleListActesComp(event: any[]) {
        this.listActesComp = event;
        this.dataActesCompEvent.emit(this.listActesComp);
    }

    handleDescriptionAll(event) {
        this.descriptionAll = event;
        this.descriptionsEvent.emit(this.descriptionAll);
    }

    handleAffections(event: any[]) {
        this.listAffectionsSelected = event;
        this.dataListAffectionsSelectedEvent.emit(this.listAffectionsSelected);
    }

    handleListPrescription(event: any[]) {
        this.listPrescriptionSelected = event;
        this.dataListPrescriptionEvent.emit(this.listPrescriptionSelected);
    }

    handleMontantHospitalisation(event: any) {
        this.affichageMontantTotal = event;
        this.affichageMontantTotalEvent.emit(this.affichageMontantTotal);
    }

    handleDataHospitalisationAutres(event: any) {
        this.dataHospitalisationAutres = event;
        this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
    }

    handleDocumentAjouter(event: any[]) {
        this.documentAjouter = event;
        this.documentAjouterEvent.emit(this.documentAjouter);
    }

    /**
     * View Tab Analyse
     */
    handleResponseActesAnalyse(event: any) {
        // console.log('handleResponseActesAnalyse : ', event); // event.body.items
        // this.responseActesAnalyse = event;
    }

}

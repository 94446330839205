import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';
import {NgxchartsPageComponent} from './ngxcharts-page.component';
import {NgxchartsFeatureModule} from '../../../fonctionnalites/ngxcharts/ngxcharts.feature.module';

const routes: Routes = [
  {
    path: '', component: NgxchartsPageComponent, pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    NgxchartsFeatureModule
  ],
  declarations: [
    NgxchartsPageComponent
  ]
})
export class NgxchartsPageModule {
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';
import {FormDropdownComponent} from '@shared/components/inputs/form-dropdown/form-dropdown.component';

/**
 * Champ d'autocomplétion.
 *
 * Services metier à implémenter:
 * recupererOptionsMetier: IRecupererOptionsMetier
 */
@Component({
    selector: 'app-atlantis-autocomplete',
    template: `
        <app-form-autocomplete [label]="label"
                               [required]="required"
                               [hasAction]="hasAction"
                               (actionDeclenche)="actionGestionnaireDeModale.display = true; actionDeclenche.emit($event)"
                               [placeholder]="placeholder"
                               [control]="control"
                               [options]="options$ | async"
                               (onChange)="onChange?.emit($event)"
                               (onBlur)="onBlur?.emit($event)"
                               (onClick)="onClick?.emit($event)"
                               (onFocus)="onFocus?.emit($event)"
                               (onShow)="onShow?.emit($event)"
                               (onHide)="onHide?.emit($event)"
                               (onSelect)="onSelect.emit($event)"
                               [field]="field"
                               (completeMethod)="autocompleteExecute($event.query)"
                               [actionColor]="actionColor"
                               [actionIcon]="actionIcon"></app-form-autocomplete>
        <ng-content></ng-content>
    `
})
export class AtlantisAutocompleteComponent extends FormDropdownComponent implements OnInit, OnChanges {
    /**
     * Defini la liste de choix.
     *
     * Le format par défaut est le suivant:
     *
     * <code>[
     *  {label: 'Toyota', value: 'toy'},
     *  {label: 'Nissan sanjero', value: 'nis_sanjero'}
     * ]</code>
     *
     * Le <code>label</code> defini la valeur visible à l'utilisateur et <code>value</code>, la valeur
     * renvoyée par le champ.
     *
     */
    options$: Observable<Array<any>>;
    /**
     * Champ qui sera affiché dans l'autocomplétion
     */
    @Input('field') field = 'label';
    /**
     * Champ qui sera utilisé pour la recherche
     */
    @Input('searchField') searchField = 'value';
    /**
     * Représente le service metier implémentant l'interface ou les interfaces metiers requis
     */
    @Input('recupererOptionsMetier') recupererOptionsMetier: IRecupererOptionsMetier;
    @Input('name') name: string;
    /**
     *
     */
    @Output() onSelect = new EventEmitter<any>(false);
    /**
     * Permet d'actionner le gestionnaire de modales du composant afin d'afficher, de masquer,
     * d'affecter des donnnées la/au modale-subtitution.
     */
    @Input('actionGestionnaireDeModale') actionGestionnaireDeModale: GestionnaireDeModales = new class implements GestionnaireDeModales {
        datasourceSelected: any;
        display = false;
        typeOpenModal: 'update' | 'create' = 'create';
    };

    ngOnChanges(changes: SimpleChanges): void {
        const test = Object.keys(changes).find(v => v === 'control' || v === 'value');
        if (!test) {
            this.recupererOptions();
        }
    }

    ngOnInit() {
        this.recupererOptions();
    }

    autocompleteExecute(data: string) {
        if (!this.args['autocomplete']) {
            this.args['autocomplete'] = {};
        }
        const arg = {...this.args};
        arg['autocomplete'][this.name] = data;
        this.args = {...arg};
        this.recupererOptions();
    }

    /**
     * Exécute la requête de récupération des options. Elle permet de définir les options
     */
    recupererOptions() {
        if (!this.extOptions) {
            if (!!this.recupererOptionsMetier) {
                this.options$ = this.recupererOptionsMetier.recupererOptions(this.getArgs());
            }
        } else {
            try {
                const value = this.extOptions.filter(v => (v[this.searchField] as string).match(new RegExp(`${this.getArgs()['autocomplete'][this.name]}`, 'ig')));
                this.options$ = of(value);
            } catch (e) {
            }
        }
    }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AntecedentDossierTableMetierService} from './antecedent-dossier-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-antecedent-dossier-table',
  templateUrl: './antecedent-dossier-table.component.html'
})

export class AntecedentDossierTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: AntecedentDossierTableMetierService;
  @Autowired() metierService: AntecedentDossierTableMetierService;

  @Input() modalAssureSelected: any;
  formGroupMedicaux: FormGroup;
  formGroupModeVie: FormGroup;
  formGroupRegimes: FormGroup;
  // @Output() responseResultatsExamenEvent = new EventEmitter<any>();

  tabActive = 'Informations';

  listeAntecedentsAllergies: any[] = [];
  listeAntecedentsChirurgicaux: any[] = [];
  listeAntecedentsFamilliaux: any[] = [];
  listeAntecedentsRegimes: any[] = [];
  listeAntecedentsModeDeVie: any[] = [];
  listeAntecedentsMedicaux: any[] = [];


  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initFormulaire();

    this.recuperationListeAntecedent();
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args};
    this.args = {...arg};
  }

  initFormulaire() {
    this.formGroupMedicaux = this.metierService.initialiserFormulaireMedicaux(this.modalAssureSelected);
    this.formGroupModeVie = this.metierService.initialiserFormulaireModeVie(this.modalAssureSelected);
    this.formGroupRegimes = this.metierService.initialiserFormulaireRegimes(this.modalAssureSelected);
  }

  recuperationListeAntecedent(data?: any) {
    const da = {...this.args, lazyLoadEvent: data, successMute: true, assure: this.modalAssureSelected};
    this.args = {...da};
    // tslint:disable-next-line:max-line-length
    this.metierService.requeteRecuperationListeAntecedent(this.getAllRequestParam(), {...this.args}).subscribe((r_listeAntecedent) => {
      const responseListeAntecedent: any[] = r_listeAntecedent.body.items;

      responseListeAntecedent.forEach((item) => {
        if (item.typeAntecedent === 'A') { this.listeAntecedentsAllergies.push(item); }
        if (item.typeAntecedent === 'C') { this.listeAntecedentsChirurgicaux.push(item); }
        if (item.typeAntecedent === 'F') { this.listeAntecedentsFamilliaux.push(item); }
        if (item.typeAntecedent === 'R') { this.listeAntecedentsRegimes.push(item); }
        if (item.typeAntecedent === 'MD') { this.listeAntecedentsModeDeVie.push(item); }
        if (item.typeAntecedent === 'M') { this.listeAntecedentsMedicaux.push(item); }
      });
    });
  }

  getTabChange(event) {
    this.tabActive = event.originalEvent.target['innerText'];
  }

}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import { DetailsDocumentTableListeRestApiService } from './details-document-table.liste.rest-api.service';


@Injectable({providedIn: 'root'})
export class DetailsDocumentTableListeMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: DetailsDocumentTableListeRestApiService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  recupererPoursuiteDocument(data: any,) {
    return this.restApi.recupererPoursuiteDocument(data);
  }
}

import {ChangeDetectionStrategy, Component} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {EvenementModaleMetierService} from './evenement.modale.metier.service';

@Component({
  selector: 'app-evenement-modale',
  templateUrl: './evenement.modale.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EvenementModaleComponent extends DialogComponent {
  @Autowired() convertirFormulaireMetier: EvenementModaleMetierService;
  @Autowired() initialiserFormulaireMetier: EvenementModaleMetierService;
  @Autowired() requeteCreationMetier: EvenementModaleMetierService;
  @Autowired() requeteModificationMetier: EvenementModaleMetierService;

  constructor() {
    super();
    this.header = 'Evenement';
  }
}

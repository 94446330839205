import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {DemandePriseChargeModaleFeatureComponent} from './demande-prise-charge-modale.feature.component';
import {ListAffectionTableListeModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/list-affection-table/liste/list-affection-table.liste.module';
import {DemandePECFormComponent} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-pec-form/demande-pec-form.component';
import {ListActeTableListeModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/list-acte-table/liste/list-acte-table.liste.module';
import {MedecinFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/medecin/form/medecin.form.module';
import {TypeDocumentAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/type-document-autocomplete/form/type-document-autocomplete.form.module';
import {ListDocumentTableListeModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/list-document-table/liste/list-document-table.liste.module';

import {DemandePecDocumentModule} from '@app/fonctionnalites/demande-pec/composants/demande-pec-document/demande-pec-document.module';
import {AdherentTableModule} from '@app/fonctionnalites/demande-pec/composants/adherent-table/adherent-table.module';

// section modale-subtitution import
import {ModaleHospitalisationModule} from '@app/fonctionnalites/demande-pec/modale-action-row/modale-hospitalisation/modale-hospitalisation.module';
import {ModaleClotureModule} from '@app/fonctionnalites/demande-pec/modale-action-row/modale-cloturer/modale-cloture.module';

// section section poursuite demande apres clôture
import {InformationAssureTableModule} from '@app/fonctionnalites/demande-pec/composants/information-assure-table/information-assure-table.module';
import {MedecinActeTableModule} from '@app/fonctionnalites/demande-pec/composants/medecin-acte-table/medecin-acte-table.module';
import {ActeComplementaireTableModule} from '@app/fonctionnalites/demande-pec/composants/acte-complementaire-table/acte-complementaire-table.module';
import {PrescriptionTableModule} from '@app/fonctionnalites/demande-pec/composants/prescription-table/prescription-table.module';

// section section demande hospitalisation
import {DemandeHospitalisationFormModule} from '@app/fonctionnalites/demande-pec/composants/demande-hospitalisation-form/demande-hospitalisation-form.module';
import {DemandeHospitalisationTableModule} from '@app/fonctionnalites/demande-pec/composants/demande-hospitalisation-table/demande-hospitalisation-table.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MedecinFormModule,
        ListActeTableListeModule,
        ListAffectionTableListeModule,
        TypeDocumentAutocompleteFormModule,
        ListActeTableListeModule,
        ListDocumentTableListeModule,

        // create UI component
        DemandePecDocumentModule,

        // section modale-subtitution import
        ModaleHospitalisationModule,
        ModaleClotureModule,
        AdherentTableModule,

        // section section poursuite demande apres clôture
        InformationAssureTableModule,
        MedecinActeTableModule,
        ActeComplementaireTableModule,
        PrescriptionTableModule,

        // section section demande hospitalisation
        DemandeHospitalisationFormModule,
        DemandeHospitalisationTableModule,
    ],
    exports: [
        DemandePriseChargeModaleFeatureComponent,
        DemandePECFormComponent,
    ],
    declarations: [
        DemandePriseChargeModaleFeatureComponent,
        DemandePECFormComponent,
    ],
  providers: [],
})
export class DemandePriseChargeModaleFeatureModule {
}

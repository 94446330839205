import {NgModule} from '@angular/core';

import {ActePrestationTableListeComponent} from './acte-prestation-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {DemandePriseChargeModaleFeatureModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/modale/demande-prise-charge-modale.feature.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        DemandePriseChargeModaleFeatureModule
    ],
  exports: [ActePrestationTableListeComponent],
  declarations: [ActePrestationTableListeComponent],
  providers: [],
})
export class ActePrestationTableListeModule {
}

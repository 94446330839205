import {Injectable} from '@angular/core';
import {Autowired} from "@angular-ru/autowired";
import {BasicRestService} from "../../../core/services/basic-rest.service";
import {Observable, of} from "rxjs";

@Injectable({providedIn: 'root'})
export class HeureServeurRestApiService {
  @Autowired() restService: BasicRestService;
  constructor() {
  }

  requeteReccuperationDateServeur(): Observable<any> | Promise<any> | any {
    return of({body: `${new Date()}`});
  }
}

import {NgModule} from '@angular/core';

import {PrescriptionRealiseTableComponent} from './prescription-realise-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports: [PrescriptionRealiseTableComponent],
  declarations: [PrescriptionRealiseTableComponent],
  providers: [],
})
export class PrescriptionRealiseTableModule {
}

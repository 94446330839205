import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {DemandePriseChargeListeFeatureComponent} from './demande-prise-charge-liste.feature.component';
import {DemandePriseChargeModaleFeatureModule} from '../modale/demande-prise-charge-modale.feature.module';
import {AffectionTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/affection-table/liste/affection-table.liste.module';
import {PrescriptionTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/prescription-table/liste/prescription-table.liste.module';
import {MotifAccordPrealableTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/motif-accord-prealable-table/liste/motif-accord-prealable-table.liste.module';
import {DocumentTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/document-table/liste/document-table.liste.module';
import {MotifRejetTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/motif-rejet-table/liste/motif-rejet-table.liste.module';
import {SoinsHospitalisationTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/soins-hospitalisation-table/liste/soins-hospitalisation-table.liste.module';
import {ActePrestationTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/acte-prestation-table/liste/acte-prestation-table.liste.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        DemandePriseChargeModaleFeatureModule,
        ActePrestationTableListeModule,
        AffectionTableListeModule,
        PrescriptionTableListeModule,
        MotifAccordPrealableTableListeModule,
        DocumentTableListeModule,
        MotifRejetTableListeModule,
        SoinsHospitalisationTableListeModule
    ],
  exports: [DemandePriseChargeListeFeatureComponent],
  declarations: [DemandePriseChargeListeFeatureComponent],
  providers: [],
})
export class DemandePriseChargeListeFeatureModule {
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {AdherentTableRestApiService} from './adherent-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class AdherentTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: AdherentTableRestApiService;
  @Autowired() formBuilder: FormBuilder;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaireAdherent<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      matriculeAdherent: !!datas && !!datas.matriculeAdherent ? datas.matriculeAdherent : null,
      nomPrenomsAdherent: !!datas && !!datas.nomCompletPersonneAdh ? datas.nomCompletPersonneAdh : null,
      sexeAdherent: !!datas && !!datas.sexeAdh ? datas.sexeAdh : null,
    };

    return this.formBuilder.group({
      matriculeAdherent: [{value: data.matriculeAdherent, disabled: true}],
      nomPrenomsAdherent: [{value: data.nomPrenomsAdherent, disabled: true}],
      sexeAdherent: [{value: data.sexeAdherent, disabled: true}],
    });
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormAutocompleteComponent} from '@shared/components/inputs/form-autocomplete/form-autocomplete.component';
import {ListFormErrorComponentModule} from '@shared/components/error/list-form-error/list-form-error.component.module';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {ButtonModule} from 'primeng/button';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    imports: [
        CommonModule,
        ListFormErrorComponentModule,
        AutoCompleteModule,
        InfoBullWidgetComponentModule,
        ButtonModule,
        FormsModule,
        ReactiveFormsModule,
    ],
    declarations: [
        FormAutocompleteComponent,
    ],
    exports: [
        FormAutocompleteComponent,
    ]
})
export class FormAutocompleteComponentModule {
}

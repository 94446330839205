import {Injectable} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {ActeAutocompleteFormRestApiService} from './acte-autocomplete.form.rest-api.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {DemandePriseChargeRestApiService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.rest-api.service';

@Injectable({providedIn: 'root'})
export class ActeAutocompleteFormMetierService implements IRecupererOptionsMetier {
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: ActeAutocompleteFormRestApiService;

  @Autowired() restApiDemandePEC: DemandePriseChargeRestApiService;


  recupererOptions(args?: any): Observable<Array<any>> {
    return this.restApiDemandePEC.recupererActes(null);
  }

}

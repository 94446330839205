import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ClvFileModel, ClvFilesUtils, ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {DomSanitizer} from '@angular/platform-browser';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';

@Component({
    selector: 'app-drop-file-list',
    template: `
        <div fxLayout="row wrap">
            <div fxFlex="100">
                <p-table [value]="clvFiles">
                    <ng-template pTemplate="header">
                        <tr>
                            <th *ngFor="let column of columns">{{column?.header}}</th>
                            <th>Actions</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-rowData let-editing="editing" let-ri="rowIndex">
                        <tr [pEditableRow]="rowData">
                            <td>
                                {{rowData?.name}}
                            </td>
                            <td>
                                {{(rowData?.size / 1024) | separatorTousand}}
                            </td>
                            <td style="text-align:center">
                                <button type="button"
                                        mat-button
                                        color="warn"
                                        (click)="deleteFile(rowData)">
                                    <mat-icon>clear</mat-icon>
                                </button>
                                <ng-container *ngIf="hasDownloadButton">
                                    <a type="button"
                                       mat-button
                                       [href]="downloadUrl(rowData)"
                                       color="primary"
                                       [download]="rowData?.name">
                                        <mat-icon>cloud_download</mat-icon>
                                    </a>
                                </ng-container>
                                <ng-container *ngIf="hasPreviewButton">
                                    <button type="button"
                                            mat-button
                                            (click)="previewFile(rowData)">
                                        <mat-icon>visibility</mat-icon>
                                    </button>
                                </ng-container>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
        <app-file-preview *ngIf="filePreviewVisible" [data]="selectedFile"
        [(modalManager)]="modalManager"></app-file-preview>
    `,
    styles: [``]
})
export class DropFileListComponent {
    @Input('clvFiles') clvFiles: ClvFileModel[];
    @Input('hasDownloadButton') hasDownloadButton: boolean;
    @Input('hasPreviewButton') hasPreviewButton: boolean;
    @Output() clvFilesChange = new EventEmitter<ClvFileModel[]>(true);
    modalManager: GestionnaireDeModales = {display: false, typeOpenModal: 'create', datasourceSelected: null};
    selectedFile: ClvFileModel;
    filePreviewVisible = false;
    columns = [
        {field: 'name', header: 'Nom'},
        {field: 'size', header: 'Taille (ko)'}
    ];

    constructor(public sanitize: DomSanitizer) {

    }

    deleteFile(file: ClvFileModel) {
        const files = ClvTsUtilsCommonsJs.deleteFromArray(this.clvFiles, file);
        if (files !== -1) {
            this.clvFilesChange.emit(files);
        }
    }
    downloadUrl(file: ClvFileModel) {
        return ClvFilesUtils.buildDownloadFileUrl(this.sanitize, file);
    }

    previewFile(file: ClvFileModel) {
        this.selectedFile = file;
        // const data = atob(<string>file.data);
        this.filePreviewVisible = true;
    }
}

import {Component, Input} from '@angular/core';
import {AbstractFormInputs} from '@shared/components/inputs/abstract-form-inputs';

@Component({
  selector: 'app-form-input-group',
  template: `
    <div class="input-container">
      <div class="d-flex justify-between">
        <label for="{{id}}">{{label}}&nbsp;<span *ngIf="required" class="danger-color">*</span></label>
        <div>
          <app-info-bull-widget [infos]="infos"></app-info-bull-widget>
        </div>
      </div>
      <div class="ui-inputgroup">
                  <span class="ui-inputgroup-addon">
                    <mat-icon class="mat-icon-sm secondary-text-color">{{icon}}</mat-icon>
                  </span>
        <input type="{{type}}" [required]="required" id="{{id}}"
               [placeholder]="placeholder"
               [attr.minlength]="minlength"
               [attr.min]="min"
               [attr.max]="max"
               [email]="email"
               [attr.maxlength]="maxlength"
               [formControl]="control"
               (change)="onChange?.emit($event)"
               (blur)="onBlur?.emit($event)"
               (click)="onClick?.emit($event)"
               (focus)="onFocus?.emit($event)"
               (show)="onShow?.emit($event)"
               [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
               [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"
               [attr.size]="size" pInputText>
      </div>
      <app-list-form-error [control]="control"
                           [types]="errorTypes"
                           [submitted]="submitted"
                           [messages]="messages"></app-list-form-error>
    </div>`
})
export class FormInputGroupComponent extends AbstractFormInputs {
  @Input('icon') icon = 'person';
  @Input('size') size = 'auto';
  @Input('type') type = 'text';
  @Input('placeholder') placeholder = '';
}

import {Component, Input} from '@angular/core';
import {AbstractFormInputs} from '@shared/components/inputs/abstract-form-inputs';

@Component({
  selector: 'app-form-input-checkbox',
  template: `
    <div class="input-container">
      <div class="d-flex justify-between">
        <label for="{{id}}" *ngIf="!control.value">{{label}}</label>
        <div>
          <app-info-bull-widget [infos]="infos"></app-info-bull-widget>
        </div>
      </div>
      <ng-container *ngIf="control.value">
        <label for="{{id}}" *ngIf="labelNegate">{{labelNegate}}</label>
        <label for="{{id}}" *ngIf="!labelNegate">{{label}}</label>
      </ng-container>
    <div>
      <p-checkbox
          id="{{id}}"
          [formControl]="control"
          (onChange)="onChange?.emit($event)"
          (blur)="onBlur?.emit($event)"
          (click)="onClick?.emit($event)"
          (focus)="onFocus?.emit($event)"
          (show)="onShow?.emit($event)"></p-checkbox>
    </div>
  </div>
  `
})
export class FormInputCheckboxComponent extends AbstractFormInputs {
  @Input('labelNegate') labelNegate: string;
}

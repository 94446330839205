import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {PrestationPharmacieComponent} from './prestation-pharmacie.component';
import {SharedModule} from '@shared/shared.module';
import {DemandePriseChargeListeFeatureModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/liste/demande-prise-charge-liste.feature.module';
import { PrescriptonPecFeatureModule } from '@app/fonctionnalites/prestation-pharmacie/prescripton-pec/prescripton-pec.feature.module';
import {PrescriptionPeriodiqueFeatureModule} from "@app/fonctionnalites/prestation-pharmacie/prescripton-periodique/prescription-periodique.feature.module";

const routes: Routes = [
    {path: '', component: PrestationPharmacieComponent, data: {breadcrumb: 'Demande PEC'}}
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    DemandePriseChargeListeFeatureModule,
    PrescriptonPecFeatureModule,
    PrescriptionPeriodiqueFeatureModule
  ],
    declarations: [
        PrestationPharmacieComponent
    ]
})
export class PrestationPharmacieModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SoinRealiseMetierService} from './soin-realise.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {Observable, of} from 'rxjs';


@Component({
  selector: 'app-soin-realise-hospitalisation',
  templateUrl: './soin-realise.component.html'
})

export class SoinRealiseComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: SoinRealiseMetierService;
  @Autowired() metierService: SoinRealiseMetierService;


  @Input() datasourceSelected: any;
  @Output() dataHospitalisationEvent = new EventEmitter<any>();
  reponseSoinsList$: Observable<any> = new Observable<any[]>();


  constructor() {
    super();

    this.tableActions = [this.supprimerAction];

    this.cols = [
      { field: 'libelle', header: 'Libellé' },
      { field: 'accordPrealableHospit', header: 'Acc. pec ?', type: () => 'chip', states: [
          {key: true, label: 'Oui', classes: 'color-row-red-PEC'},
          {key: false, label: 'Non', classes: ''},
        ]},
      { field: 'dateSoin', header: 'Date soin', type: () => 'date' },
      {field: 'prixUnitaire', header: 'Prix', type: () => 'price' },
      { field: 'quantite', header: 'Qté', type: () => 'price' },
      { field: 'montantHorsPec', header: 'P.Hors Pec', type: () => 'price' },
      { field: 'partAssurance', header: ' Part Assurance', type: () => 'price' },
      { field: 'partAssureNet', header: 'Part Assure ', type: () => 'price' },
    ];
  }

  ngOnInit(): void {
    this.formatTableList(this.datasourceSelected.reponseSoins);
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  formatTableList(arrayList: any[]) {
    if (arrayList.length > 0) {
      this.reponseSoinsList$ = of([
        ...arrayList.filter((elt) => elt.isDeleted === false)
      ]);
    }
  }

  /**
   * Effectue une demande de suppression.
   * @param data
   */
  demanderSuppression(dataSoinDelete: any) {
    /**
     * Reformatage de la liste des soins avec la mise (isDeleted = true) pour le soin à supprimer
     */
    const args = this.constructParamRequestDeleteSoin(dataSoinDelete);

    let shower: any;
    shower = this.alertService.showWarning(this.messageDemandeSuppression);
    shower.accept = () => {
      const requeteSuppressionSoinRealise$ = this.metierService.requeteSuppressionSoinRealise({} , args);
      requeteSuppressionSoinRealise$.subscribe((reponse) => {

        this.formatTableList(reponse.body.items[0].listeDemandePriseEnChargeSoinHospitalisation);
        this.datasourceSelected.reponseSoins = reponse.body.items[0].listeDemandePriseEnChargeSoinHospitalisation;
        this.dataHospitalisationEvent.emit(this.datasourceSelected);
      });
    };
    shower.reject = () => {};
    shower.show();
  }

  constructParamRequestDeleteSoin(dataSoinDelete) {
    const reponseAdherant: any = {...this.datasourceSelected.reponseAdherant};
    const reponseSoins: any[] = [...this.datasourceSelected.reponseSoins];
    reponseSoins.forEach((item) => {
      if (item.idDemandePriseEnChargeSoinHospitalisation === dataSoinDelete.idDemandePriseEnChargeSoinHospitalisation) {
        item.isDeleted = true;
      }
    });
    reponseAdherant.listeDemandePriseEnChargeSoinHospitalisation = reponseSoins;
    return reponseAdherant;
  }

}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import { PecRetourneListeFeatureComponent } from './pec-retourne-liste.feature.component';
import {ModaleRessaisirFactureFeatureModule} from "@app/fonctionnalites/factures/prise-en-charge/modale-ressaisir-facture-liste/modale-ressaisir-facture.feature.module";
import {SelectGarantModule} from "@app/fonctionnalites/factures/select-garant/select-garant.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ModaleRessaisirFactureFeatureModule,
        SelectGarantModule,
    ],
  exports: [PecRetourneListeFeatureComponent],
  declarations: [PecRetourneListeFeatureComponent],
  providers: [],
})
export class PecRetourneListeFeatureModule {
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {AbstractFormOptionInputs} from '@shared/components/inputs/abstract-form-option-inputs';

@Component({
  selector: 'app-form-autocomplete',
  template: `<div class="input-container">
    <div class="d-flex justify-between">
      <label for="{{id}}" *ngIf="label && label !== ''">{{label}}&nbsp;<span *ngIf="required" class="danger-color">*</span></label>
      <div>
        <app-info-bull-widget [infos]="infos"></app-info-bull-widget>
      </div>
    </div>
    <div [class.ui-inputgroup]="hasAction">
      <button *ngIf="hasAction" pButton type="button"
              (click)="actionDeclenche.emit()" icon="pi pi-{{actionIcon}}" class="ui-button-{{actionColor}}"></button>
      <p-autoComplete *ngIf="useNgModel" [required]="required" id="{{id}}"
                      class="w-100"
                      appendTo="body"
                      [style]="style"
                      [styleClass]="styleClass"
                      [placeholder]="placeholder"
                      [attr.minlength]="minlength"
                      [attr.min]="min"
                      [attr.max]="max"
                      [field]="field"
                      [attr.maxlength]="maxlength"
                      (change)="onChange?.emit($event)"
                      (onBlur)="onBlur?.emit($event)"
                      (click)="onClick?.emit($event)"
                      (onFocus)="onFocus?.emit($event)"
                      (onSelect)="onSelect?.emit($event)"
                      [suggestions]="options"
                      (completeMethod)="completeMethod.emit($event)"
                      [baseZIndex]="100002" [autoZIndex]="true"
                      [(ngModel)]="ngModel"
                      [dropdown]="dropdown"
                      (ngModelChange)="ngModelChange.emit($event)"
                      [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
                      [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"></p-autoComplete>
      <p-autoComplete *ngIf="!useNgModel" [required]="required" id="{{id}}"
                      class="w-100"
                      appendTo="body"
                      [style]="style"
                      [styleClass]="styleClass"
                      [placeholder]="placeholder"
                      [attr.minlength]="minlength"
                      [attr.min]="min"
                      [attr.max]="max"
                      [field]="field"
                      [attr.maxlength]="maxlength"
                      (change)="onChange?.emit($event)"
                      (onBlur)="onBlur?.emit($event)"
                      (click)="onClick?.emit($event)"
                      (onFocus)="onFocus?.emit($event)"
                      (onSelect)="onSelect?.emit($event)"
                      [suggestions]="options"
                      (completeMethod)="completeMethod.emit($event)"
                      [baseZIndex]="100002" [autoZIndex]="true"
                      [formControl]="control"
                      [dropdown]="dropdown"
                      [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
                      [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"></p-autoComplete>
    </div>
    <app-list-form-error [control]="control"
                         [types]="errorTypes"
                         [submitted]="submitted"
                         [messages]="messages"></app-list-form-error>
  </div>`,
})
export class FormAutocompleteComponent extends AbstractFormOptionInputs {
  /**
   * Défini le placeholder fu formulaire, c'est à dire une chaine de caractères qui sera affichée à
   * l'utilisateur lorsque le champs sera vide.
   */
  @Input('placeholder') placeholder = 'Recherchez';
  /**
   * Champ qui sera affiché dans l'autocomplétion
   */
  @Input('field') field = 'label';
  /**
   * Indique que le composant affiche une fleche pour monter les liste des options
   */
  @Input('dropdown') dropdown = false;
  /**
   * Emets un objet contenant la chaine de caractère présente dans le composant. Retrouvez la chaine par la propriété query
   */
  @Output() completeMethod = new EventEmitter<any>(false);
  /**
   *
   */
  @Output() onSelect = new EventEmitter<any>(false);
}

import {Injectable} from '@angular/core';
import {IRestApi} from '@app/core/interfaces/rest/i-rest-api';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {API_POUR_RECUPERER_LISTE_FACTURE_PRISE_EN_CHARGE} from '@app/core/constantes';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {OperatorEnum} from '@app/core/utilities/operators.utilities';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {DatePipe} from '@angular/common';
import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class FacturesRestApiService extends SecurityStateService implements IRestApi {
    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;

    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    dateSave = {
        // dateDebut: this.datePipe.transform(Date.now(), 'dd/MM/yyyy'),
        // dateFin: this.datePipe.transform(Date.now(), 'dd/MM/yyyy')

        dateDebut: this.datePipe.transform(moment().startOf('month').format(), 'dd/MM/yyyy'),
        dateFin: this.datePipe.transform(moment().endOf('month').format(), 'dd/MM/yyyy'),
    };

    requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteSuppression(data: any, args?: any): any {
        const body = [];
        return of({body: body});
    }

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        const dateNow = Date.now();

        if (typeof args.formGroup.value.dateDebut !== 'string') {
            this.dateSave.dateDebut = this.datePipe.transform(args.formGroup.value.dateDebut, 'dd/MM/yyyy'); // Ex: 09/07/2020
        }
        if (typeof args.formGroup.value.dateFin !== 'string') {
            this.dateSave.dateFin = this.datePipe.transform(args.formGroup.value.dateFin, 'dd/MM/yyyy'); // Ex: 09/07/2020
        }

        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch, ...args.lazyLoadEvent,
                IdPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,

                DatePrestation: CommonUtilities.formatDateReverseToServer(dateNow, this.datePipe),

                infoSearchDatePrestation: {
                    Consider: true,
                    operatorToUse: OperatorEnum.BETWEEN,
                    intervalle: {
                        Debut: CommonUtilities.formatDateReverseToServer(args.formGroup.value.dateDebut, this.datePipe),
                        Fin: CommonUtilities.formatDateReverseToServer(args.formGroup.value.dateFin, this.datePipe),
                    },
                },

                EstEnAttente: false,
                EstSinistreTp: true,
                // InfoSearchPartAssurance: {Consider: true, operatorToUse: OperatorEnum.NOTEQUAL},
                // PartAssurance: 0,
                Statut: 'Créé',

            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_POUR_RECUPERER_LISTE_FACTURE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (!(response.body.items.length > 0)) {
                        return response;
                    }
                    return response;
                })
            );
    }

}

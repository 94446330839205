import {NgModule} from '@angular/core';

import {AffectionTableComponent} from './affection-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports: [AffectionTableComponent],
  declarations: [AffectionTableComponent],
  providers: [],
})
export class AffectionTableModule {
}

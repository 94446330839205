import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-top-bar-infos',
  template: `
    <div class="surface">
      <div>
        <ng-content select=".start"></ng-content>
      </div>
      <div>
        <ng-content select=".middle"></ng-content>
      </div>
      <div>
        <ng-content select=".end"></ng-content>
      </div>
    </div>
    <div class="clone-surface"></div>`,
  styles: [`
      .surface {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: fixed;
        width: 100%;
        height: 40px;
        background: #1d2124;
        z-index: 999;
        color: #ffffff;
      }
      .clone-surface {
        position: relative;
        height: 40px;
      }
  `]
})

export class TopBarInfosComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}

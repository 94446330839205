import {NgModule} from '@angular/core';
import {CommonModule, DatePipe} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FlexLayoutModule} from '@angular/flex-layout';
import {StoreModule} from '@ngrx/store';
import {ToastrModule} from 'ngx-toastr';
import {Ng2SmartTableModule} from 'ng2-smart-table';

import {TranslateModule} from '@ngx-translate/core';

import {NgxPaginationModule} from 'ngx-pagination/dist/ngx-pagination';
import {NgxFileDropModule} from 'ngx-file-drop';

import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BeanAccessibleModule} from '@angular-ru/autowired';
import {HotTableModule} from '@handsontable/angular';

/**
 * CUSTOM IMPORT : Directives
 */
import {ShowWhenScrollNotTopDirective} from '@shared/directives/show-when-scroll-not-top.directive';
import {HasPermissionDirective} from '@shared/directives/has-permission.directive';

/**
 * CUSTOM IMPORT : Pipes
 */
import {SeparatorThousandPipe} from '@shared/pipes/separator-thousand.pipe';
import {SeparatorThousandDirective} from '@shared/directives/separator-thousand.directive';
import {CorrespondToInArrayPipe} from '@shared/pipes/correspond-to-in-array.pipe';
import {BooleanToStringPipe} from '@shared/pipes/boolean-to-string.pipe';
import {DisableEffectDirective} from '@shared/directives/disable-effect.directive';

/**
 * CUSTOM IMPORT : Components
 */
import {PageLayoutComponent} from '@shared/behaviors/page-layout/page-layout.component';
import {PageEnConstructionComponent} from '@shared/components/page-en-construction/page-en-construction.component';
import {TableFilterComponent} from '@shared/components/tables/table-filter/table-filter.component';
import {AtlantisTableComponent} from '@shared/components/tables/atlantis-table/atlantis-table.component';
import {ListLayoutLazyComponent} from '@shared/behaviors/list-layout/list-layout-lazy.component';
import {ContentHeaderComponent} from '@shared/components/content-header/content-header.component';
import {FullFormComponent} from '@shared/behaviors/full-form.component';
import {BreadcrumbComponent} from '@shared/components/breadcrumb/breadcrumb.component';
import {ListLayoutComponent} from '@shared/behaviors/list-layout/list-layout.component';
import {DropFileComponent} from '@shared/components/drop-file/drop-file.component';
import {DropFileListComponent} from '@shared/components/drop-file/drop-file-list.component';
import {ConfirmErrorComponent} from '@shared/components/confirm-error/confirm-error.component';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {XlsxSheetPreviewComponent} from '@shared/components/file-preview/xlsx-sheet-preview.component';
import {XlsxPreviewComponent} from '@shared/components/file-preview/xlsx-preview.component';
import {ImagePreviewComponent} from '@shared/components/file-preview/image-preview.component';
import {CsvPreviewComponent} from '@shared/components/file-preview/csv-preview.component';
import {FilePreviewComponent} from '@shared/components/file-preview/file-preview.component';
import {ListPerPageActionComponent} from '@shared/components/list-actions/list-per-page-action.component';
import {ListActionsComponent} from '@shared/components/list-actions/list-actions.component';
import {SaveButtonComponent} from '@shared/components/buttons/save-button.component';
import {CancelButtonComponent} from '@shared/components/buttons/cancel-button.component';
import {AddButtonComponent} from '@shared/components/buttons/add-button.component';
import {UpdateButtonComponent} from '@shared/components/buttons/update-button.component';
import {DeleteButtonComponent} from '@shared/components/buttons/delete-button.component';
import {AtlantisMultiselectComponent} from '@shared/behaviors/atlantis-extend-clv/atlantis-multiselect.component';
import {AtlantisComboModalComponent} from '@shared/behaviors/atlantis-extend-clv/atlantis-combo-modal.component';
import {AtlantisComboComponent} from '@shared/behaviors/atlantis-extend-clv/atlantis-combo.component';
import {DeminuteurComponent} from '@shared/components/widgets/deminuteur.component';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {RapportModalFeatureComponent} from '@shared/components/rapport-modal/rapport-modal.feature.component';
import {SgiRapportLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-rapport-layout.component';
import {SgiDialogComponent} from '@shared/behaviors/dialog/sgi-dialog.component';

import {UserProfileImageComponentModule} from '@shared/components/gravatar/user-profile-image.component.module';

/**
 * CUSTOM IMPORT : ClvTsUtils
 */
import {ClvTsUtilsModule} from 'clv-ts-utils';
import {ClvAngularPipesUtilitiesModule} from 'clv-angular-pipes-utilities';
import {ClvAngularDirectivesUtilitiesModule} from 'clv-angular-directives-utilities';

/**
 * CUSTOM IMPORT : Primeng
 */
import {CardModule} from 'primeng/components/card/card';
import {TableModule} from 'primeng/components/table/table';
import {ButtonModule} from 'primeng/components/button/button';
import {AutoCompleteModule} from 'primeng/components/autocomplete/autocomplete';
import {InputTextModule} from 'primeng/components/inputtext/inputtext';
import {InputSwitchModule} from 'primeng/components/inputswitch/inputswitch';
import {InputTextareaModule} from 'primeng/components/inputtextarea/inputtextarea';
import {DialogModule} from 'primeng/components/dialog/dialog';
import {DropdownModule} from 'primeng/components/dropdown/dropdown';
import {ConfirmDialogModule} from 'primeng/components/confirmdialog/confirmdialog';
import {OverlayPanelModule} from 'primeng/components/overlaypanel/overlaypanel';
import {PanelModule} from 'primeng/components/panel/panel';
import {MessagesModule} from 'primeng/components/messages/messages';
import {FileUploadModule} from 'primeng/components/fileupload/fileupload';
import {TabMenuModule} from 'primeng/components/tabmenu/tabmenu';
import {MenuModule} from 'primeng/components/menu/menu';
import {TabViewModule} from 'primeng/components/tabview/tabview';
import {MultiSelectModule} from 'primeng/components/multiselect/multiselect';
import {CheckboxModule} from 'primeng/components/checkbox/checkbox';
import {CalendarModule} from 'primeng/components/calendar/calendar';
import {PasswordModule} from 'primeng/components/password/password';
import {RadioButtonModule} from 'primeng/components/radiobutton/radiobutton';
// import {MessageService} from 'primeng/components/message/message';
/**
 * CUSTOM IMPORT : Material
 */
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatChipsModule} from '@angular/material/chips';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';

/**
 *
 */
import {MessagesService} from '@app/theme/components/messages/messages.service';

/**
 * CUSTOM IMPORT : Modules Components
 */
import {FormCalendarComponentModule} from '@shared/components/inputs/form-calendar/form-calendar.component.module';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {ListFormErrorComponentModule} from '@shared/components/error/list-form-error/list-form-error.component.module';
import {FormErrorComponentModule} from '@shared/components/error/form-error/form-error.component.module';
import {FormAutocompleteComponentModule} from '@shared/components/inputs/form-autocomplete/form-autocomplete.component.module';
import {AtlantisAutocompleteComponentModule} from '@shared/behaviors/atlantis-extend-clv/atlantis-autocomplete/atlantis-autocomplete.component.module';
import {FormInputComponentModule} from '@shared/components/inputs/form-input/form-input.component.module';
import {FormDropdownComponentModule} from '@shared/components/inputs/form-dropdown/form-dropdown.component.module';
import {FormTextareaComponentModule} from '@shared/components/inputs/form-textarea/form-textarea.component.module';
import {FormMultiselectComponentModule} from '@shared/components/inputs/form-multiselect/form-multiselect.component.module';
import {FormRadioComponentModule} from '@shared/components/inputs/form-radio/form-radio.component.module';
import {FormInputSwitchComponentModule} from '@shared/components/inputs/form-input-switch/form-input-switch.component.module';
import {FormInputCheckboxComponentModule} from '@shared/components/inputs/form-input-checkbox/form-input-checkbox.component.module';

import * as moment from 'moment';
import {DefColumnTableDirective} from '@shared/components/tables/atlantis-table/def-column-table.directive';
moment.locale('fr');

/** update information user */
import {FormInputGroupComponentModule} from '@shared/components/inputs/form-input-group/form-input-group.component.module';
import {GravatarComponentModule} from '@shared/components/gravatar/gravatar.component.module';


const PRIME_NG_MODULES = [
    AutoCompleteModule,
    CardModule,
    TableModule,
    ButtonModule,
    InputTextModule,
    InputSwitchModule,
    InputTextareaModule,
    DialogModule,
    DropdownModule,
    ConfirmDialogModule,
    OverlayPanelModule,
    PanelModule,
    MessagesModule,
    FileUploadModule,
    TabMenuModule,
    MenuModule,
    TabViewModule,
    MultiSelectModule,
    CheckboxModule,
    CalendarModule,
    PasswordModule,
    RadioButtonModule,
];

const MAT_MODULES = [
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSidenavModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatTooltipModule,
];

const PRIME_NG_SERVICES = [
    // ConfirmationService
];

const CLV_MODULES = [
    ClvTsUtilsModule,
    ClvAngularPipesUtilitiesModule,
    ClvAngularDirectivesUtilitiesModule,
];

const NGX_MODULES = [
    NgxPaginationModule,
    NgxFileDropModule,
];

const CLV_DIRECTIVES = [
    ShowWhenScrollNotTopDirective,
    DisableEffectDirective,
    SeparatorThousandDirective,
    HasPermissionDirective,
];

const CLV_DECLARATIONS = [
    // AtlantisAutocompleteComponent, => AtlantisAutocompleteComponentModule
    // ListLayoutLazyWithUpdateRequestComponent, => ListLayoutLazyWithUpdateResquestComponentModule
    ListLayoutLazyComponent,
    DefColumnTableDirective,
    AtlantisTableComponent,
    TableFilterComponent,
    PageEnConstructionComponent,
    // XlsxBindingPreviewComponent, => XlsxBindingPreviewComponentModule
    // InfoBullWidgetComponent, => InfoBullWidgetComponentModule
    // GoodFormatValueInTableWidgetComponent,
    // InfoWidgetComponent, => InfoWidgetComponentModule
    // ListeWidgetComponent, => ListeWidgetComponentModule
    FullFormComponent,
    // DetailsWidgetComponent, => DetailsWidgetComponentModule
    // AccordionFormComponent, => AccordionFormComponentModule
    ContentHeaderComponent,
    BreadcrumbComponent,
    // WidgetListGridComponent,
    // WidgetListGridComponent, => WidgetListGridComponentModule
    // DetailPanelComponent, => DetailPanelComponentModule
    PageLayoutComponent,
    ListLayoutComponent,
    // ListLayoutWithUpdateRequestComponent, => ListLayoutWithUpdateRequestComponentModule
    // ListLayoutPartComponent, => ListLayoutPartComponentModule
    DropFileComponent,
    DropFileListComponent,
    ConfirmErrorComponent,
    DialogComponent,
    // DialogPartComponent, => DialogComponentModule
    ListActionsComponent,
    ListPerPageActionComponent,
    FilePreviewComponent,
    CsvPreviewComponent,
    ImagePreviewComponent,
    XlsxPreviewComponent,
    XlsxSheetPreviewComponent,
    // FormErrorComponent, => FormErrorComponentModule
    // ListFormErrorComponent, => ListFormErrorComponentModule

    AddButtonComponent,
    CancelButtonComponent,
    SaveButtonComponent,
    UpdateButtonComponent,
    DeleteButtonComponent,

    // ListDetailActionButtonComponent, => ListDetailActionButtonComponentModule

    // FormAutocompleteComponent, => FormAutocompleteComponentModule
    // FormInputComponent, => FormInputComponentModule
    // FormInputIbanComponent, => FormInputIbanComponentModule
    // FormInputGroupComponent, => FormInputGroupComponentModule
    // FormDropdownComponent, => FormDropdownComponentModule
    // FormCalendarComponent, => FormCalendarComponentModule
    // FormDateNaissanceComponent, => FormDateNaissanceComponentModule
    // GravatarComponent, => GravatarComponentModule
    // FormTextareaComponent, => FormTextareaComponentModule
    // FormMultiselectComponent, => FormMultiselectComponentModule
    // FormPasswordComponent, => FormPasswordComponentModule
    // FormRadioComponent, => FormRadioComponentModule
    // FormInputSwitchComponent, => FormInputSwitchComponentModule
    // FormInputCheckboxComponent, => FormInputCheckboxComponentModule
    // FormTelephoneComponent, => FormTelephoneComponentModule
    // FormTauxComponent, => FormTauxComponentModule
    // FormInputFileComponent, => FormInputFileComponentModule
    // FormTauxDecimalComponent, => FormTauxDecimalComponentModule

    // UserProfileImageComponent, // => UserProfileImageComponentModule

    AtlantisComboComponent,
    AtlantisComboModalComponent,
    AtlantisMultiselectComponent,

    // PreviewButtonComponent, => PreviewButtonComponentModule
    // DownloadButtonComponent, => DownloadButtonComponent
    // RaccourciFormComponent, => RaccourciFormComponentModule
    DeminuteurComponent,
    SgiListLayoutComponent,
    // SgiListLayoutWithUpdateRequestComponent, => SgiListLayoutWithUpdateRequestComponentModule
    RapportModalFeatureComponent,
    SgiRapportLayoutComponent,
    SgiDialogComponent,
    // FormDropdownSelctListeComponent, => FormDropdownSelctListeComponentModule
];

const REDUCERS_MODULES = [];

const COMPONENTS_MODULES = [
    UserProfileImageComponentModule,
    InfoBullWidgetComponentModule,
    ListFormErrorComponentModule,

    FormCalendarComponentModule,
    FormErrorComponentModule,
    FormAutocompleteComponentModule,
    AtlantisAutocompleteComponentModule,
    FormInputComponentModule,
    FormDropdownComponentModule,
    FormTextareaComponentModule,
    FormMultiselectComponentModule,
    FormRadioComponentModule,
    FormInputSwitchComponentModule,
    FormInputCheckboxComponentModule,

    /** update information user */
    FormInputGroupComponentModule,
    GravatarComponentModule,
];

const PIPES_MODULES = [
    BooleanToStringPipe,
    CorrespondToInArrayPipe,
    SeparatorThousandPipe,
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        FlexLayoutModule,
        ToastrModule.forRoot({
            timeOut: 10000
        }),
        NGX_MODULES,
        Ng2SmartTableModule,
        TranslateModule,
        MAT_MODULES,
        REDUCERS_MODULES,
        PRIME_NG_MODULES,
        CLV_MODULES,
        FormsModule,
        ReactiveFormsModule,
        BeanAccessibleModule.forRoot(),
        HotTableModule,

        COMPONENTS_MODULES,
    ],
    exports: [
        FlexLayoutModule,
        StoreModule,
        ToastrModule,
        Ng2SmartTableModule,
        TranslateModule,

        BeanAccessibleModule,
        HotTableModule,
        FormsModule,
        ReactiveFormsModule,

        NGX_MODULES,
        MAT_MODULES,
        PRIME_NG_MODULES,
        PRIME_NG_SERVICES,

        CLV_DECLARATIONS,

        CLV_DIRECTIVES,
        CLV_MODULES,

        COMPONENTS_MODULES,

        PIPES_MODULES,
    ],
    declarations: [
        CLV_DECLARATIONS,

        CLV_DIRECTIVES,

        PIPES_MODULES,
    ],
    providers: [
        DatePipe,
        PRIME_NG_SERVICES, MessagesService
        // LoaderInterceptorProvider
    ]
})
export class SharedModule {
}

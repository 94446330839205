import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HeureServeurHorlogeComponent} from '@app/fonctionnalites/administration/heure-serveur/horloge/heure-serveur-horloge.component';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        SharedModule
    ],
  exports: [HeureServeurHorlogeComponent],
  declarations: [HeureServeurHorlogeComponent],
})
export class HeureServeurHorlogeModule {
}

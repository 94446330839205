import {Component, Input, OnInit} from '@angular/core';
import {MaladieChroniqueDossierTableMetierService} from './maladie-chronique-dossier-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
  selector: 'app-maladie-chronique-dossier-table',
  templateUrl: './maladie-chronique-dossier-table.component.html'
})

export class MaladieChroniqueDossierTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: MaladieChroniqueDossierTableMetierService;
  @Autowired() metierService: MaladieChroniqueDossierTableMetierService;

  @Input() modalAssureSelected: any;

  /**
   *
   */

  tabActive = 'Maladie Chronique';
  responseMaladieChronique: any;


  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args};
    this.args = {...arg};
  }

  /**
   *
   */

  /**
   * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
   */
  getTabChange(event) {
    this.tabActive = '';
    this.tabActive = event.originalEvent.target['innerText'];
  }

  /**
   * => return true, si l'objet est vide
   */
  isEmptyObject(obj) {
    return CommonUtilities.isEmptyObject(obj);
  }

  handleResponseMaladieChronique(event: any) {
    this.responseMaladieChronique = event;
  }

}

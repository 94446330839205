import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {ClvConfirmPrimeNgShower, ClvMessage, ClvMessageType, ClvPromptAction, ClvToastMessageShower} from 'clv-angular-boot';
import {ToastrService} from 'ngx-toastr';

/**
 * Service d'affichage de messages d'alertes
 */
@Injectable({
  providedIn: 'root'
})
export class AlertService {
  @Autowired() confirmationService: ConfirmationService;
  @Autowired() translateService: TranslateService;
  @Autowired() toastService: ToastrService;

  /**
   * Affiche une boite d'érreur
   *
   * Cette methode retourne un objet appelant une methode <code>show()</code> pour afficher l'alerte.
   *
   * S'il est necessaire de mener une action avant l'affichage de l'alerte, capturez les évènements (accept / reject) de l'objet
   * avant l'appel de la methode <code>show()</code>
   *
   * @param message
   * @param title
   */
  showError(message: string, title: string = 'Alerte', closeLabel: string = 'Fermer') {
    const shower = new ClvConfirmPrimeNgShower(this.confirmationService);
    shower.key = 'app';
    const msg = new ClvMessage(title, message);
    msg.text = this.translateService.instant(msg.text);
    msg.title = this.translateService.instant(msg.title);
    msg.messageType = ClvMessageType.ERROR;
    shower.message = msg;
    shower.actionsManager.actions = [
      new ClvPromptAction(true, this.translateService.instant(closeLabel))
    ];
    // console.log(message);
    return shower;
  }

  /**
   * Affiche une boite d'alerte et donne à l'utilisateur des options.
   *
   * Cette methode retourne un objet appelant une methode <code>show()</code> pour afficher l'alerte.
   *
   * S'il est necessaire de mener une action avant l'affichage de l'alerte, capturez les évènements (accept / reject) de l'objet
   * avant l'appel de la methode <code>show()</code>
   *
   * @param msg
   * @param title
   */
  showWarning(msg: string, title: string = 'Alerte', trueOptionLabel: string = 'Oui', falseOptionLabel: string = 'Non') {
    const shower = new ClvConfirmPrimeNgShower(this.confirmationService);
    shower.key = 'appwarning';
    const message = new ClvMessage(title, msg);
    message.text = this.translateService.instant(message.text);
    message.title = this.translateService.instant(message.title);
    message.messageType = ClvMessageType.WARNING;
    shower.message = message;
    shower.actionsManager.actions = [
      new ClvPromptAction(true, this.translateService.instant(trueOptionLabel)),
      new ClvPromptAction(false, this.translateService.instant(falseOptionLabel))
    ];
    // console.log(message);
    return shower;
  }

  /**
   * Affiche une boite d'alerte et donne à l'utilisateur des options.
   *
   * Cette methode retourne un objet appelant une methode <code>show()</code> pour afficher l'alerte.
   *
   * S'il est necessaire de mener une action avant l'affichage de l'alerte, capturez les évènements (accept / reject) de l'objet
   * avant l'appel de la methode <code>show()</code>
   *
   * @param msg
   * @param title
   */
  showWarningCustom(msg: string, title: string = 'Alerte', falseOptionLabel: string = 'Fermer') {
    const shower = new ClvConfirmPrimeNgShower(this.confirmationService);
    shower.key = 'appwarning';
    const message = new ClvMessage(title, msg);
    message.text = this.translateService.instant(message.text);
    message.title = this.translateService.instant(message.title);
    message.messageType = ClvMessageType.WARNING;
    shower.message = message;
    shower.actionsManager.actions = [
      new ClvPromptAction(false, this.translateService.instant(falseOptionLabel))
    ];
    // console.log(message);
    return shower;
  }

  /**
   * Affiche une message de succès
   *
   * @param msg
   * @param title
   */
  showSuccess(msg: string, title: string = 'Succès') {
    const shower = new ClvToastMessageShower(this.toastService);
    shower.message.text = msg;
    shower.message.title = title;
    shower.message.messageType = ClvMessageType.SUCCESS;
    return shower;
  }
}

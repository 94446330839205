import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResultatDetailTableMetierService} from './resultat-detail-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {ViewMenuResultatExamenEnum} from '@app/core/enums/view-menu-resultat-examen.enum';

@Component({
    selector: 'app-resultat-detail-table',
    templateUrl: './resultat-detail-table.component.html'
})

export class ResultatDetailTableComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: ResultatDetailTableMetierService;
    @Autowired() metierService: ResultatDetailTableMetierService;

    @Input() viewMenu: string;
    @Input() currentRow: any;
    @Input() responseResultatsExamenRealise: any;
    formGroup: FormGroup;
    @Output() responseResultatsExamenRealiseEvent = new EventEmitter<any>();


    constructor() {
        super();
        this.cols = [
            {field: 'libelle', header: 'Paramètre'},
            {field: 'resultat', header: 'Résultat'},
            {field: 'quantite', header: 'Valeur normale', composedField: (row) => `${row.valeurMin} - ${row.valeurMax} ${row.unite}`},
            {field: 'libelleGenreEligibilite', header: 'Sexe'},
        ];
        this.tableActions = [];
        this.notRowActions = [];
    }

    ngOnInit(): void {
        console.log('viewMenu  : ', this.viewMenu);
        console.log('currentRow  : ', this.currentRow);
        console.log('responseResultatsExamenRealise  : ', this.responseResultatsExamenRealise);

        if (this.viewMenu === ViewMenuResultatExamenEnum.dossierMedical) {
            this.recuperationDonnees();

        } else if (this.viewMenu === ViewMenuResultatExamenEnum.clotureDemande) {
            this.dataSource = this.currentRow.acteMedicalValeursExamen;
        }

        this.initFormulaire();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire({interpretation: this.currentRow.interpretation});
    }

    recuperationDonnees(data?: any) {
        const da = {...this.args, lazyLoadEvent: data, successMute: true, currentRow: this.currentRow};
        this.args = {...da};
        // tslint:disable-next-line:max-line-length
        this.metierService.requeteRecuperationListeDonnees(this.getAllRequestParam(), {...this.args}).subscribe((r_Prescriptions) => {
            const responseResultatsExamenRealise = r_Prescriptions.body;

            this.formatTableListAddIndex(responseResultatsExamenRealise);

            this.responseResultatsExamenRealiseEvent.emit(r_Prescriptions.body);
        });
    }

    formatTableListAddIndex(responseData) {
        if (responseData === undefined) {
            this.dataSource = [];
            this.totalRecords = 0;
            return;
        }
        this.dataSource = responseData.items;
        this.totalRecords = responseData.count;
        if (this.dataSource && this.dataSource.length > 0) {
            this.dataSource.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }
    }

}

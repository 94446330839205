import {animate, style, transition, trigger} from '@angular/animations';

/**
 * Annimation de zoom in out
 */
export function zoomerEnEntrantAnimation() {
  return trigger('zoomerEnEntrantAnimation', [
    transition(':enter', [style({transform: 'scale(0)'}), animate('0.3s', style({transform: 'scale(1)'}))]),
    transition(':leave', [animate('0.3s', style({transform: 'scale(0)'}))])
  ]);
}

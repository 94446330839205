import { AfterViewChecked, Component, Input, OnInit} from '@angular/core';
import {AdherentTableMetierService} from './adherent-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-adherent-table',
  templateUrl: './adherent-table.component.html'
})

export class AdherentTableComponent extends SgiListLayoutComponent implements OnInit, AfterViewChecked {
  @Autowired() recuperationListeDonneesMetier: AdherentTableMetierService;
  @Autowired() metierService: AdherentTableMetierService;

  @Input() updateSearch: any;
  @Input() adherentSelected: any;
  adherentForm: FormGroup;

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.initFormulaire();
  }

  ngAfterViewChecked() {
    this.initFormulaire();
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  initFormulaire() {
    this.adherentForm = this.metierService.initialiserFormulaireAdherent(this.adherentSelected);
  }

}

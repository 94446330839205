import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionPeriodiqueListeFeatureModule} from './liste/prescription-periodique-liste.feature.module';
import { PrescriptionPeriodiqueFormComponent } from './prescription-periodique-form/prescription-periodique-form.component';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PrescriptionPeriodiqueListeFeatureModule,
    ],
  declarations: [PrescriptionPeriodiqueFormComponent],
  exports: [PrescriptionPeriodiqueFormComponent]
})
export class PrescriptionPeriodiqueFeatureModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListActeHospitalisationTableMetierService} from './list-acte-hospitalisation-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {
    AUCUN_ASSURE_RECHERCHER,
    ERROR_MESSAGE_ACTE_CHAMBRE_HOSPITALISATION_EXIST,
    ERROR_MESSAGE_NOT_TYPE_CHAMBRE_HOSPITALISATION,
    FORMULAIRE_INVALIDE_MESSAGE,
} from '@app/core/constantes';
import {FormGroup} from '@angular/forms';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {objectKeys} from 'codelyzer/util/objectKeys';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {DemandeHospitalisationTableMetierService} from '@app/fonctionnalites/demande-pec/composants/demande-hospitalisation-table/demande-hospitalisation-table.metier.service';

@Component({
    selector: 'app-list-acte-hospitalisation-table',
    templateUrl: './list-acte-hospitalisation-table.component.html'
})

export class ListActeHospitalisationTableComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: ListActeHospitalisationTableMetierService;
    @Autowired() metierService: ListActeHospitalisationTableMetierService;

    @Autowired() metierServiceDemandeHospitalisation: DemandeHospitalisationTableMetierService;

    @Autowired() demandePECMetierService: DemandePriseChargeMetierService;


    @Input() adherant: any;
    @Input() medecinSelected: any;
    @Input() dataListActes: any[] = [];
    @Output() dataListActesSelectedEvent = new EventEmitter<any[]>();

    acteSelectedForm: FormGroup;

    acteSelected: any;
    reponseActeSelected: any;
    montantTotal: number;
    disabledInput: boolean;

    @Input() listActeAutocomplete: any[] = [];


    constructor() {
        super();
        this.tableActions = [
            this.supprimerAction,
            this.ajouterCommentaireAction
        ];
    }

    ngOnInit(): void {
        this.cols = [
            {field: 'libelle', header: 'Acte'},
            {field: 'prixUnitaire', header: 'Prix', type: () => 'price'}, // , type: () => 'price'
            {field: 'quantite', header: 'Qté'},
            {
                field: 'accordPrealable', header: 'Accord préalable ?', type: () => 'chip', states: [
                    {key: true, label: 'Oui', classes: 'color-row-red-PEC'},
                    {key: false, label: 'Non', classes: ''},
                ]
            },
            {field: 'montantHorsPecList', header: 'Montant Hors PEC', type: () => 'price'},
            {field: 'ticketModerateurList', header: 'Ticket modérateur', type: () => 'price'},
        ];

        this.initFormulaire();
        this.recuperationActesHospitalisationSave();
        this.sytheseCalculMontanTotal(this.dataListActes);
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initFormulaire() {
        this.acteSelectedForm = this.demandePECMetierService.initialiserFormulaireActeDemandeHospitalisation(null);

        this.disabledInput = true;
    }

    recuperationActesHospitalisationSave() {
        if (!this.demandePECMetierService.actesHospitalisation || this.demandePECMetierService.actesHospitalisation.length === 0) {
            const reqAtesHospitalisation = this.demandePECMetierService.recupererActesHospitalisation({});

            reqAtesHospitalisation.subscribe(res => {
                this.demandePECMetierService.actesHospitalisation = res.body.items;

                this.reorganistionActeHospitalisationParChoix(this.metierServiceDemandeHospitalisation.choixHospitalisationSelected);
            });
        }

        this.reorganistionActeHospitalisationParChoix(this.metierServiceDemandeHospitalisation.choixHospitalisationSelected);
    }

    actionSelectedActe(event) {
        // console.log('objectTypeHospitalisation : ', this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected);
        // console.log('event : ', event);

        if (this.metierServiceDemandeHospitalisation.choixHospitalisationSelected) {
            if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected)) {
                this.alertService.showError(ERROR_MESSAGE_NOT_TYPE_CHAMBRE_HOSPITALISATION).show();
                this.resetFormulaure();
                return;
            }
        }
        if (this.verificationDoublonChambre(event)) {
            this.alertService.showError(ERROR_MESSAGE_ACTE_CHAMBRE_HOSPITALISATION_EXIST).show();
            this.resetFormulaure();
            return;
        }

        this.acteSelected = event;

        if (!this.adherant || objectKeys(this.adherant).length === 0) {
            this.alertService.showError(AUCUN_ASSURE_RECHERCHER).show();
            this.resetFormulaure();
            return;
        }
        /* if (!this.medecinSelected || objectKeys(this.medecinSelected).length === 0) {
          ErrorAlert = this.alertService.showError(AUCUN_MEDECIN_RECHERCHER);
          ErrorAlert.show();
          this.acteSelectedForm.patchValue({
            libelleActe: null
          });
          return;
        } */

        const args = {
            acteHospitalisation: this.acteSelected,
            adherant: this.adherant,
        };

        const findElt = this.dataListActes.findIndex((elt) => elt.libelle === this.acteSelected.libelle);
        if (findElt >= 0) {
            this.alertService.showError('Cet acte a déjà été ajouté !').show();
            this.resetFormulaure();
            return;
        }

        this.recupererMontantActe({}, args);
    }

    recupererMontantActe(data?, args?: any) {
        const recupererMontantActe$ = this.metierService.recupererMontantActe({}, args);
        recupererMontantActe$.subscribe((res) => {

            // console.log('res.body.items : ', res.body.items);

            if (res.body.items.length !== 0) {
                // const reponse =  res.body.items[0];

                if (!CommonUtilities.isDataNullUndefinedFalseEmpty(res.body.items[0].descriptions)) {
                    this.alertService.showError(`${res.body.items[0].descriptions}`).show();
                    // return;
                }
                if (res.body.items[0].message && !CommonUtilities.isDataNullUndefinedFalseEmpty(res.body.items[0].message)) {
                    this.alertService.showError(`${res.body.items[0].message}`).show();
                    // return;
                }

                // console.log('this.acteSelected, **** ', this.acteSelected);

                this.reponseActeSelected = {
                    ...res.body.items[0],
                    idActeMedical: res.body.items[0].idActeMedical,
                    montantAPayer: res.body.items[0].montantAPayer,
                    accordPrealable: res.body.items[0].accordPrealable,
                    isDeleted: res.body.items[0].isDeleted,
                    libelle: res.body.items[0].libelle,
                };

                this.acteSelectedForm.patchValue({
                    montantActe: res.body.items[0].montantAPayer,
                    quantiteActe: 1,
                });
            }
        });
    }

    ajouterActe() {
        // console.log('objectTypeHospitalisation ** : ', this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected);

        if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.acteSelected)) {
            this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        if (!this.acteSelectedForm.getRawValue().libelleActe ||
            !this.acteSelectedForm.getRawValue().quantiteActe) {
            this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        let argsConstruct: any;
        let objectActeAjouter: any;

        if (this.metierServiceDemandeHospitalisation.choixHospitalisationSelected) {
            objectActeAjouter = {
                ...this.reponseActeSelected,
                montantHorsPecList: this.reponseActeSelected.accordPrealable ? '' : this.reponseActeSelected.montantHorsPec,
                ticketModerateurList: this.reponseActeSelected.accordPrealable ? '' : this.reponseActeSelected.ticketModerateur,
                libelle: this.reponseActeSelected.libelle,
                // idActeMedical: this.reponseActeSelected.idActe,

                montantAPayer: +this.acteSelectedForm.getRawValue().montantActe,
                montantHT: +this.acteSelectedForm.getRawValue().montantActe,
                quantite: +this.acteSelectedForm.getRawValue().quantiteActe,

                idSouscriptionLigne: this.adherant.idSouscriptionLigne,

                accordPrealable: false,
                accordPrealableHospit: false,
                estChambre: this.reponseActeSelected.estChambre,

                idActeMedicalSoin: this.reponseActeSelected.idActeMedical,
                idActeMedical: this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected.idActeMedical,
                prixUnitaire: +this.acteSelectedForm.getRawValue().montantActe,
            };

            argsConstruct = {
                choixHospitalisationSelected: this.metierServiceDemandeHospitalisation.choixHospitalisationSelected,

                acteHospitalisation: this.reponseActeSelected,
                adherant: this.adherant,

                objectTypeHospitalisationSelected: this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected,
                listActesTable: [...this.dataListActes, objectActeAjouter],
            };

        } else {
            argsConstruct = {
                choixHospitalisationSelected: this.metierServiceDemandeHospitalisation.choixHospitalisationSelected,

                acteHospitalisation: this.reponseActeSelected,
                adherant: this.adherant,
                montantHT: +this.acteSelectedForm.getRawValue().montantActe,
                quantite: +this.acteSelectedForm.getRawValue().quantiteActe,
            };
        }

        this.calculeMontantActe({}, argsConstruct);
    }

    calculeMontantActe(data?, args?: any) {
        this.metierService.calculeMontantActe({}, args).subscribe((res) => {
            const reponse = res.body.items[0];

            let affichAllDescription = '';

            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(reponse.descriptions)) {
                affichAllDescription += reponse.descriptions;
            }

            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(reponse.listeDemandePriseEnChargeSoinHospitalisation)) {
                reponse.listeDemandePriseEnChargeSoinHospitalisation.forEach((v) => {
                    if (CommonUtilities.isDataNullUndefinedFalseEmpty(v.idDemandePriseEnChargeSoinHospitalisation)) {
                        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(v.description)) {
                            affichAllDescription += `<br/> ${v.description}`;
                        }
                    }
                });
            }

            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(affichAllDescription)) {
                this.alertService.showWarningCustom(`${affichAllDescription}`).show();
            }

            // console.log('reponseActeSelected :', this.reponseActeSelected);
            // console.log('reponse : ', reponse);

            if (this.metierServiceDemandeHospitalisation.choixHospitalisationSelected) {
                /****
                 *
                 */
                reponse.listeDemandePriseEnChargeSoinHospitalisation.forEach((item) => {
                    if (item.libelle === this.reponseActeSelected.libelle) {
                        item.accordPrealable = false;
                        item.accordPrealableHospit = false;

                        item.estChambre = this.reponseActeSelected.estChambre;
                        item.idActeMedicalSoin = this.reponseActeSelected.idActeMedical;

                        item.idActeMedical = this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected.idActeMedical;

                        item.montantHorsPecList = item.montantHorsPec;
                        item.ticketModerateurList = item.ticketModerateur;

                        /**
                         * Add
                         */
                        this.dataListActes = [
                            ...this.dataListActes, item
                        ];
                    }
                });
                /****
                 *
                 */

            } else {
                const dataListActes = {
                    /** inclusion des données depuis la selection ++ */
                    ...this.reponseActeSelected,

                    ...reponse,
                    montantHorsPecList: reponse.accordPrealable ? '' : reponse.montantHorsPec,
                    ticketModerateurList: reponse.accordPrealable ? '' : reponse.ticketModerateur,
                    libelle: this.reponseActeSelected.libelle,
                    idActeMedical: reponse.idActe,
                };

                this.dataListActes = [
                    ...this.dataListActes,
                    {
                        ...dataListActes,
                        prixUnitaire: +this.acteSelectedForm.getRawValue().montantActe,
                        quantite: +this.acteSelectedForm.getRawValue().quantiteActe,
                    }
                ];
            }

            if (this.dataListActes && this.dataListActes.length > 0) {
                this.dataListActes.forEach((item, index) => {
                    item.id_key = index + 1;
                    // item.idActeMedical = item.idActe;
                    item.montantAPayer = item.montantHT;
                    // item.prixUnitaire = item.montantAPayer;
                });
            }

            // calcul du montant total, ticket moderateur
            this.sytheseCalculMontanTotal(this.dataListActes);

            // this.resetFormulaure();
            this.acteSelectedForm.reset();

            this.dataListActesSelectedEvent.emit(this.dataListActes);

            // console.log('dataListActes : ', this.dataListActes);
        });

        this.acteSelected = null;
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            const index = this.dataListActes.findIndex((elt) => elt.id_key === data.id_key);
            this.dataListActes.splice(index, 1);
            this.dataListActesSelectedEvent.emit(this.dataListActes);

            // calcul du montant total, ticket moderateur
            this.sytheseCalculMontanTotal(this.dataListActes);
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    resetFormulaure() {
        this.acteSelectedForm.patchValue({
            libelleActe: null,
            montantActe: null,
            quantiteActe: null,
        });
    }

    ajouterCommentairActe() {
        console.log('ajouterCommentairActe :: ');
    }

    sytheseCalculMontanTotal(array: any[]) {
        this.montantTotal = 0;

        array.forEach((item) => {
            if (!item.accordPrealable) {
                this.montantTotal = this.montantTotal + (item.ticketModerateur + item.montantHorsPec);
            }
        });
    }


    /**
     *
     */
    /**
     *
     */
    reorganistionActeHospitalisationParChoix(choix) {
        // console.log('choix : ', choix);

        this.listActeAutocomplete = [];

        if (choix) {
            (this.demandePECMetierService.actesHospitalisation as any[]).forEach((elt) => {
                if (elt.estHospitalisation === false) {
                    this.listActeAutocomplete.push(elt);
                }
            });
        } else {
            (this.demandePECMetierService.actesHospitalisation as any[]).forEach((elt) => {
                if (elt.idActeChambreGroupeSoin !== 0 && elt.estChambre === false && elt.estHospitalisation === false) {
                    this.listActeAutocomplete.push(elt);
                }
            });
        }

        // console.log('listActeAutocomplete ** // : ', this.listActeAutocomplete);
    }

    verificationDoublonChambre(event): boolean {
        const arrayfindElt: any[] = this.dataListActes.filter((elt) => elt.estChambre === true);

        return event.estChambre === true && arrayfindElt.length !== 0;
    }

}

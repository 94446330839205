import {AfterViewInit, Component} from '@angular/core';
import {PageLayoutComponent} from '../../../shared/behaviors/page-layout/page-layout.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent extends PageLayoutComponent implements AfterViewInit {

  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }

}

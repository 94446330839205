import {ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {AssureMetierService} from '../assure.metier.service';
import {Autowired} from '@angular-ru/autowired';

@Component({
  selector: 'app-assure-modale-feature',
  templateUrl: './assure-modale.feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssureModaleFeatureComponent extends DialogComponent implements OnInit {
  @Autowired() convertirFormulaireMetier: AssureMetierService;
  @Autowired() initialiserFormulaireMetier: AssureMetierService;
  @Autowired() requeteCreationMetier: AssureMetierService;
  @Autowired() requeteModificationMetier: AssureMetierService;
  @Autowired() metierService: AssureMetierService;

  tabActive = 'Informations';

  constructor() {
    super();
  }

  ngOnInit() {
    if (this.modalManager.id === 'consulter_dossier_medical') {
      this.header = 'Dossier médical du patient';
    }

    /*this.metierService.requeteDossierMedicalAssure({}, this.modalManager.datasourceSelected.assureSelected).subscribe((r_Prestations) => {
      const responsePrestations = r_Prestations.body;
      this.modalManager.datasourceSelected = {
        ...this.modalManager.datasourceSelected,
        responsePrestations: responsePrestations,
      };
    });

    this.metierService.requetePrestationActes({}, this.modalManager.datasourceSelected.assureSelected).subscribe((r_Actes) => {
      const responseActes = r_Actes.body;
      this.modalManager.datasourceSelected = {
        ...this.modalManager.datasourceSelected,
        responseActes: responseActes,
      };
    });

    // tslint:disable-next-line:max-line-length
    this.metierService.requeteResultatsExamen({}, this.modalManager.datasourceSelected.assureSelected).subscribe((r_ResultatsExamen) => {
      const responseResultatsExamen = r_ResultatsExamen.body;
      this.modalManager.datasourceSelected = {
        ...this.modalManager.datasourceSelected,
        responseResultatsExamen: responseResultatsExamen,
      };
    });

    this.metierService.requetePrescriptions({}, this.modalManager.datasourceSelected.assureSelected).subscribe((r_Prescriptions) => {
      const responsePrescriptions = r_Prescriptions.body;
      this.modalManager.datasourceSelected = {
        ...this.modalManager.datasourceSelected,
        responsePrescriptions: responsePrescriptions,
      };
    });

    this.metierService.requeteAffections({}, this.modalManager.datasourceSelected.assureSelected).subscribe((r_Affections) => {
      const responseAffections = r_Affections.body;
      this.modalManager.datasourceSelected = {
        ...this.modalManager.datasourceSelected,
        responseAffections: responseAffections,
      };
    });*/

  }

  getTabChange(event) {
    this.tabActive = event.originalEvent.target['innerText'];
  }

  handleResponsePrestations(event: any) {
    this.modalManager.datasourceSelected.responsePrestations = event;
  }

  handleResponseActes(event: any) {
    this.modalManager.datasourceSelected.responseActes = event;
  }

  handleResponseAffections(event: any) {
    this.modalManager.datasourceSelected.responseAffections = event;
  }

  handleResponsePrescriptions(event: any) {
    this.modalManager.datasourceSelected.responsePrescriptions = event;
  }

  handleResponseResultatsExamen(event: any) {
    this.modalManager.datasourceSelected.responseResultatsExamen = event;
  }

}

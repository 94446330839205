import {HttpResponse} from '@angular/common/http';
import {ISgiHttpResponse} from '@app/core/interfaces';

/**
 * Utilitaire s'appliquant aux requetes
 */
export class RequestUtilities {
  /**
   * Ajuste les reponses des requêtes selon la méthode SGI
   * @param reponse
   */
  public static ajusteSgiResponse(reponse: HttpResponse<any>) {
    const rep: any | HttpResponse<ISgiHttpResponse> = reponse;
    if (reponse.body.Items) {
      rep.body.items = reponse.body.Items;
    }
    if (reponse.body.Message) {
      rep.body.message = reponse.body.Message;
    }
    if (reponse.body.Count) {
      rep.body.count = reponse.body.Count;
    }
    if (reponse.body.HasError) {
      rep.body.hasError = reponse.body.HasError;
    }
    if (reponse.body.IndexDebut) {
      rep.body.indexDebut = reponse.body.IndexDebut;
    }
    if (reponse.body.IndexFin) {
      rep.body.indexFin = reponse.body.IndexFin;
    }
    if (reponse.body.IsAuthentify) {
      rep.body.isAuthentify = reponse.body.IsAuthentify;
    }
    if (reponse.body.IsConnected) {
      rep.body.isConnected = reponse.body.IsConnected;
    }
      return rep;
  }
  /**
   * Corrige le format pour le formulaire full
   * @param reponse
   */
  public static  ajustementRecuperationFullForm(reponse: HttpResponse<any>) {
    if (typeof reponse.body === 'object' && reponse.body.length > 0) {
      return {body: reponse.body[0]};
    }
    return reponse;
  }
  /**
   * Corrige le format de l'adresse des utilisateurs depuis la reponse du serveur
   *
   * @param reponse
   */
  public static homogeneiserAdresseUtilisateurs(reponse: HttpResponse<any>) {
    try {
      reponse.body.forEach(utilisateur => {
        utilisateur.email = utilisateur.adresse.email ? utilisateur.adresse.email : null;
        utilisateur.adresseGeographique = utilisateur.adresse.adresseGeographique ? utilisateur.adresse.adresseGeographique : null;
        utilisateur.adressePostale = utilisateur.adresse.adressePostale ? utilisateur.adresse.adressePostale : null;
        // utilisateur.boitePostale = utilisateur.adresse.boitePostale ? utilisateur.adresse.boitePostale : null;
        utilisateur.emailSecondaire = utilisateur.adresse.emailSecondaire ? utilisateur.adresse.emailSecondaire : null;
        utilisateur.fax = utilisateur.adresse.fax ? utilisateur.adresse.fax : null;
        utilisateur.telephone1 = utilisateur.adresse.telephone1 ? utilisateur.adresse.telephone1 : null;
        utilisateur.telephone2 = utilisateur.adresse.telephone2 ? utilisateur.adresse.telephone2 : null;
        utilisateur.webSite = utilisateur.adresse.webSite ? utilisateur.adresse.webSite : null;
      });
    } catch (e) {
    }
    return reponse;
  }

  /**
   * Corrige le format de l'adresse d'un utilisateur authentifié
   * @param reponse
   */
  public static homogeneiserAdresseUtilisateur(reponse: HttpResponse<any>) {
    try {
      reponse.body.email = reponse.body.adresse.email ? reponse.body.adresse.email : null;
      reponse.body.adresseGeographique = reponse.body.adresse.adresseGeographique ? reponse.body.adresse.adresseGeographique : null;
      reponse.body.adressePostale = reponse.body.adresse.adressePostale ? reponse.body.adresse.adressePostale : null;
      // reponse.body.boitePostale = reponse.body.adresse.boitePostale ? reponse.body.adresse.boitePostale : null;
      reponse.body.emailSecondaire = reponse.body.adresse.emailSecondaire ? reponse.body.adresse.emailSecondaire : null;
      reponse.body.fax = reponse.body.adresse.fax ? reponse.body.adresse.fax : null;
      reponse.body.telephone1 = reponse.body.adresse.telephone1 ? reponse.body.adresse.telephone1 : null;
      reponse.body.telephone2 = reponse.body.adresse.telephone2 ? reponse.body.adresse.telephone2 : null;
      reponse.body.webSite = reponse.body.adresse.webSite ? reponse.body.adresse.webSite : null;
    } catch (e) {
    }
    return reponse;
  }

  /**
   * Corrige le format de l'adresse des utilisateurs depuis la reponse du serveur
   * @param reponse
   */
  public static homogeneiserAdresseUtilisateurAuthentifie(reponse: HttpResponse<any>) {
    try {
      reponse.body.personne.email = reponse.body.personne.adresse.email ? reponse.body.personne.adresse.email : null;
      reponse.body.personne.adresseGeographique = reponse.body.personne.adresse.adresseGeographique ? reponse.body.personne.adresse.adresseGeographique : null;
      reponse.body.personne.adressePostale = reponse.body.personne.adresse.adressePostale ? reponse.body.personne.adresse.adressePostale : null;
      // reponse.body.personne.boitePostale = reponse.body.personne.adresse.boitePostale ? reponse.body.personne.adresse.boitePostale : null;
      reponse.body.personne.emailSecondaire = reponse.body.personne.adresse.emailSecondaire ? reponse.body.personne.adresse.emailSecondaire : null;
      reponse.body.personne.fax = reponse.body.personne.adresse.fax ? reponse.body.personne.adresse.fax : null;
      reponse.body.personne.telephone1 = reponse.body.personne.adresse.telephone1 ? reponse.body.personne.adresse.telephone1 : null;
      reponse.body.personne.telephone2 = reponse.body.personne.adresse.telephone2 ? reponse.body.personne.adresse.telephone2 : null;
      reponse.body.personne.webSite = reponse.body.personne.adresse.webSite ? reponse.body.personne.adresse.webSite : null;
    } catch (e) {
    }
    return reponse;
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SoinMetierService} from './soin.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {FORMULAIRE_INVALIDE_MESSAGE} from '@app/core/constantes';
import {CommonUtilities} from '@app/core/utilities/common.utilities';


@Component({
  selector: 'app-soin-hospitalisation',
  templateUrl: './soin.component.html'
})

export class SoinComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: SoinMetierService;
  @Autowired() metierService: SoinMetierService;


  @Input() datasourceSelected: any;

  /** */
  acteSelectedForm: FormGroup;
  dataListActes: any[] = [];
  @Output() dataListActesSelectedEvent = new EventEmitter<any[]>();
  acteSelected: any;
  montantTotal = 0;

  reponseCalculActeSoin: any;
  affichageBouton = 'ajouter';


  constructor() {
    super();

    this.tableActions = [this.modifierAction, this.supprimerAction];

    this.cols = [
      { field: 'libelle', header: 'Libellé' },
      {field: 'accordPrealableHospit', header: 'Acc. pec ?', type: () => 'chip', states: [
          {key: true, label: 'Oui', classes: 'color-row-red-PEC'},
          {key: false, label: 'Non', classes: ''},
        ]},
      { field: 'prixUnitaire', header: 'Prix', type: () => 'price' },
      { field: 'quantite', header: 'Qté' },
      { field: 'montantHorsPec', header: 'Montant Hors PEC', type: () => 'price' },
      { field: 'partAssurance', header: 'Part assurance', type: () => 'price' },
      { field: 'partAssureNet', header: 'A payer', type: () => 'price' },
    ];

    this.initFormulaire();
  }

  ngOnInit(): void {
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  initFormulaire() {
    this.acteSelectedForm = this.metierService.initialiserFormulaireActeSoin(null);
  }

  actionSelectedActe(event) {
    this.acteSelected = event;

    console.log('acteSelected : ', this.acteSelected);

    // update de la quatité par défaut
    this.acteSelected = {...this.acteSelected, quantite: 1};

    const findEltActeSoin = this.dataListActes.findIndex((elt) => elt.libelle === this.acteSelected.libelle);
    if (findEltActeSoin >= 0) {
      this.alertService.showError('Cet acte a déjà été ajouté !').show();

      this.acteSelectedForm.reset();
      return;
    }

    this.acteSelected.quantite = 1;

    this.acteSelectedForm.patchValue({
      accordPrealable: this.acteSelected.accordPrealableHospit ? 'Oui' : 'Non',
      libelleActe: this.acteSelected.libelle,
      quantiteActe: this.acteSelected.quantite,
      montantActe: this.acteSelected.montantAPayer
    });
  }

  ajouterActe() {
    if (!this.acteSelectedForm.getRawValue().searchActe ||
        !this.acteSelectedForm.getRawValue().accordPrealable ||
        !this.acteSelectedForm.getRawValue().libelleActe ||
        !this.acteSelectedForm.getRawValue().quantiteActe ||
        !this.acteSelectedForm.getRawValue().montantActe) {

      this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
      return;
    }

    if (this.acteSelectedForm.getRawValue().accordPrealable === 'Oui' && !this.acteSelectedForm.getRawValue().description) {
      this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
      return;
    }

    this.acteSelected.quantite = +this.acteSelectedForm.getRawValue().quantiteActe;
    this.acteSelected.prixUnitaire = +this.acteSelectedForm.getRawValue().montantActe;
    this.acteSelected.description = this.acteSelectedForm.getRawValue().description;

    this.calculeMontantActeAjout(this.acteSelected);
  }

  calculeMontantActeAjout(acteSelected) {
    let args: any;
    args = this.constructParamRequest(this.dataListActes, acteSelected);

    // let args: any;
    // if (this.dataListActes.length === 0) {
    //   args = this.constructParamRequest(acteSelected);
    // } else {
    //   this.dataListActes.forEach((item) => {
    //     args = this.constructParamRequest(item);
    //   });
    //   args = this.constructParamRequest(acteSelected);
    // }

    const requeteSuppressionSoinRealise$ = this.metierService.calculMontantActe({} , args);
    requeteSuppressionSoinRealise$.subscribe((reponse) => {

      const items = reponse.body.items[0];

      let affichAllDescription = '';

      if (!CommonUtilities.isDataNullUndefinedFalseEmpty(items.descriptions)) {
        affichAllDescription += items.descriptions;
      }
      items.listeDemandePriseEnChargeSoinHospitalisation.forEach((v) => {
        if (CommonUtilities.isDataNullUndefinedFalseEmpty(v.idDemandePriseEnChargeSoinHospitalisation)) {
          if (!CommonUtilities.isDataNullUndefinedFalseEmpty(v.description)) {
            affichAllDescription += `<br/> ${v.description}`;
          }
        }
      });

      if (!CommonUtilities.isDataNullUndefinedFalseEmpty(affichAllDescription)) {
        this.alertService.showError(`${affichAllDescription}`).show();
        // return; /* pas nécéssaire */
      }

      /**
       *
       */

      this.reponseCalculActeSoin = reponse.body.items[0].listeDemandePriseEnChargeSoinHospitalisation;

      this.acteSelectedForm.reset();

      // calcul du montant total,
      this.sytheseCalculMontanTotal(this.reponseCalculActeSoin);
    });
  }

  constructParamRequest(listTableActe, acteSelected) {
    let reponseAdherant: any;
    let reponseSoins: any[];

    reponseAdherant = {...this.datasourceSelected.reponseAdherant};

    if (listTableActe !== null && acteSelected !== null) {
      acteSelected.idSouscriptionLigne = reponseAdherant.idSouscriptionLigne;
      acteSelected.idActeMedicalSoin = acteSelected.idActeMedical;
      reponseSoins = [...this.datasourceSelected.reponseSoins, ...listTableActe, ...acteSelected];

      reponseAdherant.listeDemandePriseEnChargeSoinHospitalisation = reponseSoins;
      return reponseAdherant;
    }

    if (listTableActe !== null && acteSelected === null) {
      reponseSoins = [...this.datasourceSelected.reponseSoins, ...listTableActe];

      reponseAdherant.listeDemandePriseEnChargeSoinHospitalisation = reponseSoins;
      return reponseAdherant;
    }

    reponseAdherant = {...this.datasourceSelected.reponseAdherant};
    reponseSoins = [...this.datasourceSelected.reponseSoins];
    reponseAdherant.listeDemandePriseEnChargeSoinHospitalisation = reponseSoins;
    return reponseAdherant;
  }

  sytheseCalculMontanTotal(reponseActeSoin: any[]) {
    this.dataListActes = [];
    this.montantTotal = 0;

    reponseActeSoin.forEach((item) => {
      if (
          (item.idDemandePriseEnChargeSoinHospitalisation === 0 || item.idDemandePriseEnChargeSoinHospitalisation === null)
      ) {
        this.montantTotal = this.montantTotal + item.partAssureNet;

        /*acteSelected.prixUnitaire = item.prixUnitaire;
        acteSelected.quantite = item.quantite;
        acteSelected.montantHorsPec = item.montantHorsPec;
        acteSelected.partAssurance = item.partAssurance;
        acteSelected.partAssureNet = item.partAssureNet;*/

        this.dataListActes = [ ...this.dataListActes, item];

        this.dataListActesSelectedEvent.emit(this.dataListActes);
      }
    });

    if (this.dataListActes && this.dataListActes.length > 0) {
      this.dataListActes.forEach((dat, index) => {
        dat.id_key = index + 1;
      });
    }
  }

  /**
   * Effectue une demande de suppression.
   * @param data
   */
  demanderSuppression(data: any) {
    let shower: any;

    shower = this.alertService.showWarning(this.messageDemandeSuppression);
    shower.accept = () => {
      const cloneDataListActes: any[] = [...this.dataListActes];
      const index = cloneDataListActes.findIndex((elt) => elt.id_key === data.id_key);

      cloneDataListActes.splice(index, 1);

      let args: any;
      if (cloneDataListActes.length === 0) {
        args = this.constructParamRequest(null, null);
      } else {
        args = this.constructParamRequest(cloneDataListActes, null);
      }

      const requeteSuppressionSoinRealise$ = this.metierService.calculMontantActe({} , args);
      requeteSuppressionSoinRealise$.subscribe((reponse) => {

        this.reponseCalculActeSoin = reponse.body.items[0].listeDemandePriseEnChargeSoinHospitalisation;

        this.acteSelectedForm.reset();

        // calcul du montant total,
        this.sytheseCalculMontanTotal(this.reponseCalculActeSoin);
      });
    };
    shower.reject = () => {};
    shower.show();
  }

  /**
   * Modifie une donnée d'un élément de la liste
   *
   * @param modalManager
   * @param data
   */
  modifierDonnee(modalManager, data: any) {
    this.affichageBouton = 'update';

    let shower: any;

    shower = this.alertService.showWarning('Voulez - vous modifier le soin d\'hospitalisation ?');
    shower.accept = () => {

      this.acteSelectedForm.patchValue({
        searchActe: {libelle: data.libelle},
        accordPrealable: data.accordPrealableHospit ? 'Oui' : 'Non',
        libelleActe: data.libelle,
        quantiteActe: data.quantite,
        montantActe: data.prixUnitaire,
        description: data.description
      });
    };
    shower.reject = () => {};
    shower.show();
  }

  updateActe() {
    if (!this.acteSelectedForm.getRawValue().searchActe ||
        !this.acteSelectedForm.getRawValue().accordPrealable ||
        !this.acteSelectedForm.getRawValue().libelleActe ||
        !this.acteSelectedForm.getRawValue().quantiteActe ||
        !this.acteSelectedForm.getRawValue().montantActe) {

      this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
      return;
    }

    if (this.acteSelectedForm.getRawValue().accordPrealable === 'Oui' && !this.acteSelectedForm.getRawValue().description) {
      this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
      return;
    }

    const cloneDataListActes: any[] = [...this.dataListActes];
    const index = cloneDataListActes.findIndex((elt) => elt.libelle === this.acteSelectedForm.getRawValue().libelleActe);

    cloneDataListActes[index].quantite = this.acteSelectedForm.getRawValue().quantiteActe;
    cloneDataListActes[index].prixUnitaire = this.acteSelectedForm.getRawValue().montantActe;
    cloneDataListActes[index].description = this.acteSelectedForm.getRawValue().description;

    let args: any;
    if (cloneDataListActes.length === 0) {
      args = this.constructParamRequest(null, null);
    } else {
      args = this.constructParamRequest(cloneDataListActes, null);
    }

    const requeteSuppressionSoinRealise$ = this.metierService.calculMontantActe({} , args);
    requeteSuppressionSoinRealise$.subscribe((reponse) => {

      this.reponseCalculActeSoin = reponse.body.items[0].listeDemandePriseEnChargeSoinHospitalisation;

      this.acteSelectedForm.reset();

      // calcul du montant total,
      this.sytheseCalculMontanTotal(this.reponseCalculActeSoin);

      this.affichageBouton = 'ajouter';
    });
  }
}

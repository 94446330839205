import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {DatePipe} from '@angular/common';
import {PrescriptonPecRestApiService} from './prescripton-pec.rest-api.service';

@Injectable({providedIn: 'root'})
export class PrescriptonPecMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier {

    @Autowired() datePipe: DatePipe;
    @Autowired() formBuilder: FormBuilder;
    @Autowired() restApi: PrescriptonPecRestApiService;

    medecins: any = [];
    actes: any[] = [];

    convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
        const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
        v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
        return v;
    }

    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {

        const formGroup: FormGroup = new FormGroup({});
        formGroup.setControl('Matricule', new FormControl(''));
        formGroup.setControl('NumBon', new FormControl(''));
        return formGroup;
    }

    recupererOptions(args?: any): Observable<Array<any>> {
        return this.restApi.requeteRecuperationListeDonnees(null, args)
            .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
                if (!(reponse.body.items && reponse.body.items.length > 0)) {
                    return reponse;
                }
                return reponse.body.items;
            }));
    }

    recupererListeTableRefresh(args?: any): Observable<Array<any>> {
        const dataParam = {
            InfoSearchdateEmissionBon: {IsOrderByField: true, Consider: false},
            InfoSearchnumBonPriseEnCharge: {Consider: true},
            matricule: args.argsParam.matricule,
            numBonPriseEnCharge: args.argsParam.numBonPriseEnCharge,
            getAllRequestParam: args.getAllRequestParam,
        };

        console.log('dataParam : ', dataParam);
        console.log('args : ', args);

        return this.restApi.requeteRecuperationListeDonnees(dataParam, args)
            .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
                if (!(reponse.body.items && reponse.body.items.length > 0)) {
                    return reponse;
                }
                return reponse;
            }));
    }

    recupererListePoursuitePEC(data: any, args?: any) {
        return this.restApi.requeteRecuperationListeDonnees(data, args);
    }

    recupererPrescriptionPEC(data: any) {
        return this.restApi.requeteRecuperationListeDonneesPrescription(data);
    }

    recupererMaxMontantPrescriptionPEC(data: any) {
        return this.restApi.requeteRecuperationMaxMontantPrescription(data);
    }

    recupererPrescriptionPeriodique(data: any) {
        return this.restApi.requeteRecuperationListeDonneesPrescriptionPeriodique(data);
    }

    calculerMontantPrescription(data: any) {
        return this.restApi.requeteCalculPrescription(data);
    }

    enregistrerPrescription(data: any) {
        return this.restApi.requeteEnregistrerPrescription(data);
    }

    requeteRecuperationActesPoursuite(data: any, args?: any) {
        return this.restApi.requeteRecuperationActesPoursuite(data, args).pipe(
            map((response: HttpResponse<any>) => {
                response.body.items.forEach(item => {
                    if (item.idPrestataireMedical === 0) {
                        item.idPrestataireMedical = this.restApi.storageUser.personne.idPrestataireMedical;
                    }
                });
                return response.body.items;
            })
        );
    }

    requeteActionCanClosePoursuite(data: any, args?: any) {
        return this.restApi.requeteActionCanClosePoursuite(data, args);
    }

    requeteRecuperationPrixActesPoursuite(data: any, args?: any) {
        return this.restApi.requeteRecuperationPrixActesPoursuite(data, args);
    }

}

import {Component, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {PoursuitePriseChargeMetierService} from '../poursuite-prise-charge.metier.service';
import * as poursuitePECConstantes from '../poursuite-prise-charge.constante';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {TableAction} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE} from '@app/core/constantes';
import {FormBuilder} from '@angular/forms';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';

@Component({
    selector: 'app-poursuite-prise-charge-liste-feature',
    templateUrl: './poursuite-prise-charge-liste.feature.component.html'
})
export class PoursuitePriseChargeListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: PoursuitePriseChargeMetierService;
    @Autowired() recuperationListeDonneesMetier: PoursuitePriseChargeMetierService;
    @Autowired() metierService: PoursuitePriseChargeMetierService;

    @Autowired() metierServiceDemandePEC: DemandePriseChargeMetierService;

    poursuitePECConstantes = poursuitePECConstantes;
    tabActive = 'Informations sur l\'assuré';
    currentDataTemp: any;

    @Autowired() formBuilder: FormBuilder;

    affichModalPrintBon = false;
    affichModalPoursuite = false;
    tableSelectedElement: any;


    /**
     * Boutton Poursuivre
     */
    poursuivre: TableAction = {
        type: 'button', title: 'Poursuivre', flat: false, icon: 'keyboard_arrow_right', notFlatClass: '', actionPerformance: (data) => {
            this.poursuivreDemande(data);
        }, canShow: (data) => true
    };

    /**
     * Boutton demande de dossier médical
     */
    demandeDossierMedical: TableAction = {
        type: 'button',
        title: 'Demande de dossier médical',
        flat: false,
        icon: 'menu_book',
        notFlatClass: '',
        actionPerformance: (data) => {
            this.demandeDossireMedical(data);
        },
        canShow: (data) => true
    };

    /**
     * Boutton imprimer bon
     */
    imprimerBonAction: TableAction = {
        type: 'button', title: 'Imprimer', flat: false, icon: 'print', notFlatClass: '', actionPerformance: (data) => {
            this.imprimerBon(data);
        }, canShow: (data) => true
    };

    constructor() {
        super();
        this.cols = this.poursuitePECConstantes.columns;
        this.tableActions = [];
        this.notRowActions = [
            this.poursuivre,
            this.demandeDossierMedical,
            this.imprimerBonAction,
        ];
    }

    ngOnInit() {
        this.selectedColumns = this.cols;
        this.getListElements();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }


    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
        this.tabActive = '';
    }

    /**
     * => return true, si le tableau est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }

    getTabChange(event) {
        this.currentRow = null;
        this.currentRow = this.currentDataTemp;
        this.tabActive = event.originalEvent.target['innerText'];
    }


    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        if (data.libelleTypeStatut === 'Cloturé') {
            shower = this.alertService.showError('La demande de prise en charge ne doit pas ètre cloturé');
            shower.show();
            return;
        }

        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.metierService.requeteSuppression(data).subscribe(() => {
                this.recuperDonnees();
            });
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    /**
     * Systeme de detection et de rechargement des données après enregistrement
     * @param event
     */
    handleSavePoursuiteSuccess(event) {
        if (event === null) {
            // this.recuperDonnees();
            return;
        }

        this.closeAllModal();

        this.requetePrintDocument(
            {numBonPriseEnCharge: event.numBonPriseEnCharge},
            '-- Bon de prise en charge N°',
            event.numBonPriseEnCharge,
        );
        // this.recuperDonnees();
    }

    requetePrintDocument(arg, message, value) {
        this.metierServiceDemandePEC.requetePrintDocument({}, {...arg}).subscribe((reponse) => {
            const arrayConstruct: any[] = [];
            arrayConstruct.push({
                File: reponse.body.items[0],
                Name: `${message} ${value}`,
            });
            this.gestionnaireDeModales[0].datasourceSelected = arrayConstruct;
            this.gestionnaireDeModales[0].display = true;

            this.affichModalPrintBon = true;
        });
    }

    poursuivreDemande(data: any) {
        if (!this.tableSelectedElement) {
            this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE).show();
            return;
        }

        this.closeAllModal();

        this.metierService.requeteRecuperationActesPoursuite({}, {adherant: this.tableSelectedElement}).subscribe((reponseActe) => {

            this.metierService.requeteActionCanClosePoursuite({}, {actePoursuite: reponseActe}).subscribe(() => {

                this.metierService.requeteRecuperationPrixActesPoursuite({}, {actePoursuite: reponseActe}).subscribe((reponse) => {
                    const actesPoursuite = reponse.body.items;
                    this.gestionnaireDeModales[0].id = 'poursuite_demande';
                    this.gestionnaireDeModales[0].datasourceSelected = {...data, listActesPoursuite: actesPoursuite};
                    this.gestionnaireDeModales[0].display = true;

                    this.affichModalPoursuite = true;
                });
            });
        });

    }

    demandeDossireMedical(data: any) {
        if (!this.tableSelectedElement) {
            this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE).show();
            return;
        }

        this.closeAllModal();

        this.gestionnaireDeModales[0].id = 'dossier_medical_demande';
        this.gestionnaireDeModales[0].datasourceSelected = {...data, adherent: this.tableSelectedElement};
        this.gestionnaireDeModales[0].display = true;

        this.affichModalPoursuite = true;
    }

    imprimerBon(data: any) {
        console.log('imprimerBon : ', data);
    }

    closeAllModal() {
        this.affichModalPoursuite = false;
        this.affichModalPrintBon = false;
        this.gestionnaireDeModales[0].display = false;
        this.gestionnaireDeModales[0].datasourceSelected = undefined;
    }

}

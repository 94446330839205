import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {PoursuitePriseChargeRestApiService} from './poursuite-prise-charge.rest-api.service';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class PoursuitePriseChargeMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier {

  @Autowired() datePipe: DatePipe;
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: PoursuitePriseChargeRestApiService;
  medecins: any = [];
  actes: any[] = [];

  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
    v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
    return v;
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {

    const formGroup: FormGroup = new FormGroup({});
    formGroup.setControl('Matricule', new FormControl(''));
    formGroup.setControl('NumBon', new FormControl(''));
    return formGroup;
  }

  recupererOptions(args?: any): Observable<Array<any>> {
    return this.restApi.requeteRecuperationListeDonnees(null, args)
        .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
          if (!(reponse.body.items && reponse.body.items.length > 0)) {
            return reponse;
          }
          return reponse.body.items;
        }));
  }

  recupererListePoursuitePEC(data: any) {
    return this.restApi.requeteRecuperationListeDonnees(data);
  }

  requeteRecuperationActesPoursuite(data: any, args?: any) {
    return this.restApi.requeteRecuperationActesPoursuite(data, args).pipe(
        map((response: HttpResponse<any>) => {
          response.body.items.forEach(item => {
            if (CommonUtilities.isDataNullUndefinedFalseEmpty(item.idPrestataireMedical) || item.idPrestataireMedical === 0) {
              item.idPrestataireMedical = this.restApi.storageUser.personne.idPrestataireMedical;
            }
          });
          return response.body.items;
        })
    );
  }

    requeteActionCanClosePoursuite(data: any, args?: any) {
        return this.restApi.requeteActionCanClosePoursuite(data, args);
    }

    requeteRecuperationPrixActesPoursuite(data: any, args?: any) {
        return this.restApi.requeteRecuperationPrixActesPoursuite(data, args);
    }

    /**
     * requête d'enregistrement d'une poursuite demande
     * @param data
     * @param agrs
     */
    requeteSaveDonneesFormulairePoursuiteDemande(data: any, agrs: any) {
        return this.restApi.requeteSaveDonneesFormulairePoursuiteDemande(data, agrs);
    }

    /**
     * requête d'enregistrement d'une demande dossier médical
     * @param data
     * @param agrs
     */
    requeteSaveDonneesFormulaireDossierMedicalDemande(data: any, agrs: any) {
        return this.restApi.requeteSaveDonneesFormulaireDossierMedicalDemande(data, agrs);
    }

}

import {NgModule} from '@angular/core';

import {AffectionDossierTableComponent} from './affection-dossier-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports: [AffectionDossierTableComponent],
  declarations: [AffectionDossierTableComponent],
  providers: [],
})
export class AffectionDossierTableModule {
}

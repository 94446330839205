import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {ModaleHospitalisationRestApiService} from './modale-hospitalisation.rest-api.service';

@Injectable({providedIn: 'root'})
export class ModaleHospitalisationMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: ModaleHospitalisationRestApiService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

}

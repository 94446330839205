import {Component, Input, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import * as resetPasswordConstantes from '../reset-password.constante';
import {ResetPasswordMetierService} from '../reset-password.metier.service';
import {AppSettings} from '@app/app.settings';
import {FormGroup} from '@angular/forms';

@Component({
  selector: 'app-rest-password-layout',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  formGroup: FormGroup;
  defaultObject: any;
  resetPasswordConstantes = resetPasswordConstantes;
  @Input('operation') operation: resetPasswordConstantes.operationRefreshPassword = 'activate_user';
  @Autowired() metierService: ResetPasswordMetierService;

  bg: string = AppSettings.configs.assets.loginBackground;

  @Autowired() appSettings: AppSettings;

  constructor() {
    this.initialiserFormulaire();
  }

  ngOnInit(): void {
  }

  initialiserFormulaire() {
    this.formGroup = this.metierService.initialiserFormulaire(null, {});
  }

  modifier() {
    switch (this.operation) {
      case 'activate_user':
        this.metierService.activerUtilisateur(this.formGroup);
        break;

      case 'refresh_mdp':
        this.metierService.modifierMotDePasse(this.formGroup);
    }
  }
}

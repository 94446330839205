import {Component, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {TableAction} from '@shared/components/tables/atlantis-table/atlantis-table.models';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {
    ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE,
    ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC,
    PERMISSION_PRESTATION_FACTURE_LIERBORDEREAU,
    PERMISSION_PRESTATION_FACTURE_MODIFIERFACTURE,
    PERMISSION_PRESTATION_FACTURE_RESSAISIRFACTURE,
} from '@app/core/constantes';
import {FormBuilder} from '@angular/forms';
import {FacturesMetierService} from './factures.metier.service';

@Component({
    selector: 'app-facture-liste-feature',
    templateUrl: './facture-liste.feature.component.html'
})
export class FactureListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: FacturesMetierService;
    @Autowired() recuperationListeDonneesMetier: FacturesMetierService;
    @Autowired() metierService: FacturesMetierService;

    tabActive = 'Actes';
    currentDataTemp: any;

    @Autowired() formBuilder: FormBuilder;

    affichModalPrintBon = false;
    tableSelectedElement: any;
    modifierFacture: boolean;
    modifierFactureAction: boolean;
    lierBordereauModale: boolean;
    finResponse: boolean;

    /**
     * Boutton Selectionner tout
     */
    selectionnerToutAction: TableAction = {
        type: 'button', title: 'Selectionner tout', flat: false, icon: 'done', notFlatClass: '', actionPerformance: (data) => {
            this.selectionnerTouteLaListe();
        }, canShow: (data) => true
    };

    /**
     * Boutton DéSelectionner tout
     */
    deselectionnerToutAction: TableAction = {
        type: 'button', title: 'Déselectionner tout', flat: false, icon: 'done', notFlatClass: '', actionPerformance: (data) => {
            this.deselectionnerTouteLaListe();
        }, canShow: (data) => true
    };

    /**
     * Boutton Lier a un bordereau
     */
    lierBordereauAction: TableAction = {
        type: 'button', title: 'Lier a un bordereau', flat: false, icon: 'description', notFlatClass: '', actionPerformance: (data) => {
            this.lierBordereauModale = true;
            this.openModalLierBordereau();
        }, canShow: (data) => true
    };

    /**
     * Boutton 'Modifier facture
     */
    ressaisirFactureActionBouttonAction: TableAction = {
        type: 'button', title: 'Ressaisir facture', flat: false, icon: 'edit', notFlatClass: '', actionPerformance: (data) => {
            this.modifierFactureAction = true;
            this.openRessaisirFactureAction();
        }, canShow: (data) => true
    };

    /**
     * Boutton 'Modifier une facture dans le tabbleau
     */
    modifficationFactureAction: TableAction = {
        type: 'button', title: 'Modifier', flat: true, icon: 'create', flatClass: 'info-color', actionPerformance: (data) => {
            this.modifierFacture = true;
            this.modifierDonnee(this.gestionnaireDeModales[0], data);
        }, canShow: (data) => true
    };

    columns: any = [];
    typeRechercheCombo = [
        {label: 'N°Prise en charge', value: 'numBonPriseEnCharge'},
        {label: 'Date', value: 'datePrestation'},
        {label: 'Garant', value: 'raisonSocialeGarant'},
    ];
    placeholderInputSearch: string;
    formGroupFilter: any;

    constructor() {
        super();
        this.cols = [
            {field: 'numBonPriseEnCharge', header: 'N° Prise en charge'},
            {field: 'raisonSocialeGarant', header: 'Garant'},
            {field: 'datePrestation', header: 'Date', type: () => 'date'},
            {field: 'montantBrut', header: 'Montant', type: () => 'price'},
            {field: 'partAssuranceBrut', header: 'Part Assurance Brut', type: () => 'price'},
            {field: 'ticketModerateur', header: 'Ticket Moderateur', type: () => 'price'},
            {field: 'montantDette', header: 'Montant Dette', type: () => 'price'},
            {field: 'partAssurance', header: 'Net à Payer', type: () => 'price'},
        ];

        this.notRowActions = [this.selectionnerToutAction];
        this.tableActions = [];
    }

    ngOnInit() {
        super.ngOnInit();
        this.recuperDonnees();

        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_LIERBORDEREAU)) {
            this.notRowActions = [...this.notRowActions, this.lierBordereauAction];
        }
        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_RESSAISIRFACTURE)) {
            this.notRowActions = [...this.notRowActions, this.ressaisirFactureActionBouttonAction];
        }

        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_MODIFIERFACTURE)) {
            this.tableActions = [...this.tableActions, this.modifficationFactureAction];
        }
    }

    selectChange(event) {
        this.dataSource = [];
        this.recuperDonnees({idClientGarant: event.value});
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
        };
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }


    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
        this.tabActive = '';
    }

    /**
     * => return true, si le tableau est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }


    getTabChange(event) {
        this.currentRow = null;
        this.currentRow = this.currentDataTemp;
        this.tabActive = event.originalEvent.target['innerText'];
    }


    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {

        let shower: any;

        if (data.libelleTypeStatut === 'Cloturé') {
            shower = this.alertService.showError(ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC);
            shower.show();
            return;
        }

        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.metierService.requeteSuppression(data).subscribe(() => {
                // this.metierService.requeteRecuperationListeDonnees({});
                this.dataSource = [];
                this.recuperDonnees();
            });
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }


    selectionnerTouteLaListe() {
        this.tableSelectedElement = this.dataSource;
        this.notRowActions = [
            this.deselectionnerToutAction,
            this.lierBordereauAction,
            this.ressaisirFactureActionBouttonAction,
        ];
    }

    deselectionnerTouteLaListe() {
        this.tableSelectedElement = null;
        this.notRowActions = [
            this.selectionnerToutAction,
            this.lierBordereauAction,
            this.ressaisirFactureActionBouttonAction,
        ];
    }

    openModalLierBordereau() {
        let shower: any;
        if (!this.tableSelectedElement || this.tableSelectedElement.length === 0) {
            shower = this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE);
            shower.show();
            return;
        }

        this.gestionnaireDeModales[0].datasourceSelected = this.tableSelectedElement;
        this.gestionnaireDeModales[0].display = true;
    }

    fermerModifierDialogue() {
        this.modifierFacture = false;
        this.modifierFactureAction = false;
        this.lierBordereauModale = false;
        this.dataSource = [];
        this.recuperDonnees();
    }

    private openRessaisirFactureAction() {
        let shower: any;
        if (!this.tableSelectedElement || this.tableSelectedElement.length === 0) {
            shower = this.alertService.showError(ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE);
            shower.show();
            return;
        }

        console.log(this.tableSelectedElement);

        this.modifierFactureAction = true;
        this.gestionnaireDeModales[0].datasourceSelected = this.tableSelectedElement[0];
        this.gestionnaireDeModales[0].display = true;
    }

    handleChangeTypeRecherche($event: any) {
        this.placeholderInputSearch = $event.value;
    }

    rechercheFacture($event: KeyboardEvent) {

        if (this.placeholderInputSearch === 'numBonPriseEnCharge') {
            this.recuperDonnees({numBonPriseEnCharge: this.formGroup.getRawValue().searchKey});
        } else if (this.placeholderInputSearch === 'raisonSocialeGarant') {
            this.recuperDonnees({raisonSocialeGarant: this.formGroup.getRawValue().searchKey});
        } else if (this.placeholderInputSearch === 'datePrestation') {
            this.recuperDonnees({datePrestation: this.formGroup.getRawValue().searchKey});
        }
    }

    finResponseEvent(event) {
        this.finResponse = event;
    }

}

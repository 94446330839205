import {Injectable, SkipSelf} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {ClvMessage} from 'clv-angular-boot';
import {Autowired} from '@angular-ru/autowired';
import {AlertService} from '@app/core/services/alert.service';
import {IErrorApi} from '@app/core/interfaces';
import {ErreursUtilities} from '@app/core/utilities/erreurs.utilities';

/**
 * Implémentation des methodes REST de base (GET, POST, PUT, DELETE)
 */
@Injectable()
export class RestService {
  /**
   * Service de requêtes http
   */
  @SkipSelf() @Autowired() httpClient: HttpClient;

  /**
   * Service d'affichage de messages d'alertes
   */
  @Autowired() alertService: AlertService;

  /**
   * Message de succès par défaut
   */
  protected defaultMessageSucces: ClvMessage =  new ClvMessage('Succès', 'L\'opération s\'est achevée avec succès');

  /**
   * Affiche un message de succès
   *
   * @param successMute
   * @param message
   */
  protected afficherMessageSucces(successMute: boolean, message: ClvMessage = this.defaultMessageSucces) {
    const shower = this.alertService.showSuccess(message.text);
    if (!successMute) {
      shower.show();
    }
  }

  /**
   * Afficher message d'erreur
   *
   * @param errorMute
   * @param error1
   */
  protected afficherMessageErreur(errorMute: boolean, error1: HttpErrorResponse) {
    const error: IErrorApi = error1.error;
    const shower = this.alertService.showError(ErreursUtilities.recupererMessageException(error));
    if (!errorMute) {
      shower.show();
    }
  }

  /**
   * Requête GET
   *
   * @param url
   * @param params
   * @param headers
   * @param successMute
   * @param errorMute
   */
  getRequest<T>(url: string, params?: any, headers?: any, successMute: boolean = true, errorMute: boolean = true) {
    // return this.httpClient.request('GET', url, {observe: 'response', headers: headers, params: params});
    return this.httpClient.get<T>(url, {observe: 'response', headers: headers, params: params}).pipe(
        tap(() => {
          this.afficherMessageSucces(successMute);
        }, error1 => {
          this.afficherMessageErreur(errorMute, error1);
        })
    );
  }

  /**
   * Requête POST
   *
   * @param url
   * @param data
   * @param params
   * @param headers
   * @param successMute
   * @param errorMute
   */
  postRequest<T>(url: string, data: any, params?: any, headers?: any, successMute: boolean = false, errorMute: boolean = false) {
    return this.httpClient.post<T>(url, data, {observe: 'response', headers: headers, params: params})
        .pipe(
            tap(() => {
              this.afficherMessageSucces(successMute);
            }, error1 => {
              this.afficherMessageErreur(errorMute, error1);
            })
        );
  }

  /**
   * Requête PUT
   *
   * @param url
   * @param data
   * @param params
   * @param headers
   * @param successMute
   * @param errorMute
   */
  putRequest<T>(url: string, data: any, params?: any, headers?: any, successMute: boolean = false, errorMute: boolean = false) {
    return this.httpClient.put<T>(url, data, {observe: 'response', headers: headers, params: params})
    .pipe(
        tap(() => {
          this.afficherMessageSucces(successMute);
        }, error1 => {
          this.afficherMessageErreur(errorMute, error1);
        })
    );
  }

  /**
   * Requête DELETE
   *
   * @param url
   * @param params
   * @param headers
   * @param successMute
   * @param errorMute
   */
  deleteRequest<T>(url: string, params?: any, headers?: any, successMute: boolean = true, errorMute: boolean = true) {
    return this.httpClient.delete<T>(url, {observe: 'response', headers: headers, params: params}).pipe(
        tap(() => {
          this.afficherMessageSucces(successMute);
        }, error1 => {
          this.afficherMessageErreur(errorMute, error1);
        })
    );
  }
}

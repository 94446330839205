import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {PrescriptonPecListeFeatureComponent} from './prescripton-pec-liste.feature.component';
import {PoursuitePriseChargeModaleFeatureModule} from '@app/fonctionnalites/demande-pec/poursuite-prise-charge/modale/poursuite-prise-charge-modale.feature.module';
import {AffectionTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/affection-table/liste/affection-table.liste.module';
import {PrescriptionTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/prescription-table/liste/prescription-table.liste.module';
import {MotifAccordPrealableTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/motif-accord-prealable-table/liste/motif-accord-prealable-table.liste.module';
import {DocumentTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/document-table/liste/document-table.liste.module';
import {MotifRejetTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/motif-rejet-table/liste/motif-rejet-table.liste.module';
import {SoinsHospitalisationTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/soins-hospitalisation-table/liste/soins-hospitalisation-table.liste.module';
import {ActePrestationTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/acte-prestation-table/liste/acte-prestation-table.liste.module';
import {PrescriptionPecDetailsListeFeatureModule} from '@app/fonctionnalites/prestation-pharmacie/prescripton-pec/prescription-pec-details/prescription-pec-details-liste.feature.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        PoursuitePriseChargeModaleFeatureModule,
        ActePrestationTableListeModule,
        AffectionTableListeModule,
        PrescriptionTableListeModule,
        MotifAccordPrealableTableListeModule,
        DocumentTableListeModule,
        MotifRejetTableListeModule,
        SoinsHospitalisationTableListeModule,
        PrescriptionPecDetailsListeFeatureModule,
    ],
    exports: [PrescriptonPecListeFeatureComponent],
    declarations: [PrescriptonPecListeFeatureComponent],
    providers: [],
})
export class PrescriptonPecListeFeatureModule {
}

import {AbstractControl, FormBuilder, FormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import {Autowired} from '@angular-ru/autowired';
import * as CONSTANTES from '@app/core/constantes';
import {AfterViewInit, EventEmitter, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {IActionFormulaire, IActionsListe, IManageArgs} from '@app/core/interfaces';
import {ListFormErrorType} from '@shared/components/error/list-form-error/list-form-error.component';
import {RestService} from '@app/core/services/rest.service';
import {AlertService} from '@app/core/services/alert.service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

/**
 * Formulaire reactif (Angular). Défini une logique d'oppérations communes à la gestion des formulaires
 */
export class AtlantisReactiveForm implements OnInit,
    AfterViewInit, IActionFormulaire, IActionsListe,
  IManageArgs {
  formGroup: FormGroup;
  defaultObject: any;
  submitted = false;
  constantes = CONSTANTES;

  /**
   * librairie de translation
   */
  @Autowired() translateService: TranslateService;

  /**
   * formBuilder. Permet la construction de formulaire dynamique
   */
  @Autowired() fb: FormBuilder;
  // @SkipSelf() @Autowired() httpClient: HttpClient;

  /**
   * Implémentation des methodes REST de base (GET, POST, PUT, DELETE)
   */
  @Autowired() restService: RestService;

  /**
   * Evènement émis à la suppression d'une donnée
   */
  @Output() onSupprimer = new EventEmitter<any>(false);

  /**
   * Evènement émis lors de la réussite d'une donnée
   */
  @Output() onSuccess = new EventEmitter<any>(false);

  // etats de l'application
  /**
   * Service d'affichage de messages d'alertes
   */
  @Autowired() alertService: AlertService;

  /**
   * Message de damande de confirmation de la suppression
   */
  messageDemandeSuppression = 'Voulez-vous vraimment effectuer cette suppression ?';

  /**
   * Message de damande de confirmation pour la visualisation
   */
  messageDemandeVisualisation = 'Voulez-vous visualiser le document ?';

  /**
   * Arguments qui seront passés aux réquêtes et services
   */
  args: any = null;

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    const arg = {...this.args, formGroup: this.formGroup};
    this.args = {...arg};
  }

  /**
   * Récupération des arguments
   */
  getArgs(): any {
    this.defineArgs();
    return this.args;
  }

  ngOnInit(): void {
    this.initialiserFormulaire();
  }

  ngAfterViewInit(): void {
    // this.initialiserFormulaire();
  }

  /**
   * Vérifie qu'un champ héritant de la classe AbstractControl) respecte la liste deconditions fournie
   * @param control
   * @param typ
   */
  getFullErrorCondition(control: AbstractControl, typ: ListFormErrorType[]) {
    return CommonUtilities.getFullErrorCondition(control, typ);
  }

  /**
   * Mettre à jours le formulaire
   * @param data
   */
  mettreAjourFormulaire<T>(data: any | T): void {
  }

  /**
   * Récupère la liste des données
   * @param data
   */
  recuperDonnees(data?: any) {
    this.defineArgs();
  }

  /**
   * Effectue une demande de suppression.
   * @param data
   */
  demanderSuppression(data: any) {
    const shower = this.alertService.showWarning(this.messageDemandeSuppression);
    shower.accept = () => {
      this.supprimerElement(data);
    };
    shower.reject = () => {
      this.rejeterSuppressionElement(data);
    };
    shower.show();
  }

  /**
   * Supprimme une donnée
   * @param data
   */
  supprimerElement(data: any) {
    this.onSupprimer.emit(data);
    try {
      this.requeteSuppression(data).subscribe(() => {
        this.recuperDonnees();
      });
    } catch (e) {
      console.error('Erreur de suppression', e);
    }
  }

  /**
   * Effectue une demande de visualisation du document
   * @param data
   */
  consulterDocument(data: any) {
    const shower = this.alertService.showWarning(this.messageDemandeVisualisation);
    shower.accept = () => {
      console.log('consulterDocument');
      // this.supprimerElement(data);
    };
    shower.reject = () => {
      // this.rejeterSuppressionElement(data);
    };
    shower.show();
  }

  /**
   * Ajouter commentaire pour un acte
   * @param data
   */
  ajouterCommentairActe(data: any) {
    console.log('ajouterCommentairActe ::');
  }

  /**
   * Est exécuté lors du réjet de la suppression d'un élément
   * @param data
   */
  rejeterSuppressionElement(data: any) {}

  /**
   * Réquête de suppression d'un élément.
   * @param data
   */
  requeteSuppression(data: any): Observable<any> {
    return null;
  }

  /**
   * Initialise le formulaire
   */
  initialiserFormulaire() {
  }

  /**
   * Converti le formulaire au format attendu au backend
   */
  convertirFormulaire(): any {
    return this.formGroup.getRawValue();
  }

  /**
   * Appelle la requête d'enregistrement d'une donnée
   */
  requeteEnregistrement() {
  }

  /**
   * Appelle la requête de modification d'une donnée
   */
  requeteModification() {}

  /**
   * Teste les champs du formulaire
   */
  protected dirtyFormGroup() {
    this.formGroup.markAllAsTouched();
  }

  /**
   * Enregistre le formulaire
   */
  enregistrerFormulaire() {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.requeteEnregistrement();
    } else {
      this.dirtyFormGroup();
      this.alertService.showError(this.constantes.FORM_INVALID_MESSAGE).show();
    }
  }

  /**
   * Modifie le formulaire
   */
  modifierFormulaire() {
    this.submitted = true;
    if (this.formGroup.valid) {
      this.requeteModification();
    } else {
      this.dirtyFormGroup();
      this.alertService.showError(this.constantes.FORM_INVALID_MESSAGE).show();
    }
  }
}

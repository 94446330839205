import {Component, OnInit} from '@angular/core';
import {MenuItem} from 'primeng/api';
import {Store} from '@ngrx/store';
import {Autowired} from '@angular-ru/autowired';
import {ListLayoutComponent} from '@shared/behaviors/list-layout/list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
    selector: 'app-xlsx-sheet-preview',
    template: ``
})
export class XlsxSheetPreviewComponent  extends ListLayoutComponent implements OnInit {

    @Autowired() listDisplayModeStore: Store<{listDisplayMode: string}>;
    @Autowired() listPerPageStore: Store<{perPage: number}>;


    items2: MenuItem[];
    activeItem: MenuItem;

    ngOnInit(): void {
        this.activeItem = this.items2[0];
        this.cols = CommonUtilities.buildPrimeNgTableColumnsByObject(this.dataSource[0]);
    }

    handle(ev) {
        console.log(ev);
    }
}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {LoaderStateService} from '@app/core/states/loader/loader.state.service';
import { RechercheBordereauAutocompleteFormRestApiService } from './recherche-bordereau-autocomplete.form.rest-api.service';

@Injectable({providedIn: 'root'})
export class RechercheBordereauAutocompleteFormMetierService implements IRecupererOptionsMetier {
  @Autowired() restApi: RechercheBordereauAutocompleteFormRestApiService;

  @Autowired() loaderStateService: LoaderStateService;


  recupererOptions(args?: any): Observable<Array<any>> {
    return this.restApi.requeteRecuperationListeDonnees(null, args).pipe(
      map((response: HttpResponse<any>) => {
          this.loaderStateService.decrement();

          response.body.items.forEach(v => {
          v.label = v.numeroBordereau +' | '+ v.numeroFacture;
          v.value = v.idBordereau;
        });

        this.loaderStateService.decrement();
        return response.body.items;
      })
    );
  }

}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {
    IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRequeteCreationMetier,
    IRequeteModificationMetier
} from '@app/core/interfaces';
import {AtlantisReactiveForm} from '@shared/behaviors/atlantis-extend-clv/atlantis-reactive-form';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';

/**
 * Défini le process de traitement des formulaire à l'interieur d'un modale-subtitution
 *
 * Services metiers à implémenter:
 * * convertirFormulaireMetier: IConvertirFormulaireMetier;
 * * initialiserFormulaireMetier: IInitialiserFormulaireMetier;
 * * requeteCreationMetier: IRequeteCreationMetier;
 * * requeteModificationMetier: IRequeteModificationMetier;
 */
@Component({
    selector: 'app-dialog',
    template: `
      <p-dialog [header]="header" [(visible)]="modalManager.display" appendTo="body"
                [dismissableMask]="false" [modal]="modal"
                [responsive]="responsive" [style]="{width: width, minWidth: minWidth}" [minY]="0"
                [maximizable]="maximizable" [baseZIndex]="baseZIndex" [autoZIndex]="true"
                [positionTop]="20"
                [draggable]="draggable"
                [styleClass]="styleClass"
                (visibleChange)="lorsDeLaFermeture($event)"
                [closeOnEscape]="false">
        <div>
          <ng-content select=".content"></ng-content>
        </div>
        <p-footer>
          <ng-content select=".footer"></ng-content>
        </p-footer>
      </p-dialog>`
})
export class DialogComponent extends AtlantisReactiveForm implements OnInit {
  /**
   * Service de convertion du formulaire.
   */
  convertirFormulaireMetier: IConvertirFormulaireMetier;

  /**
   * Service d'initialisation du formulaire
   */
  initialiserFormulaireMetier: IInitialiserFormulaireMetier;


  /**
   * Service d'appel de la requête de création
   */
  requeteCreationMetier: IRequeteCreationMetier;

  /**
   * Service d'appel de la requête de modification
   */
  requeteModificationMetier: IRequeteModificationMetier;

    @Input('modalManager') modalManager: GestionnaireDeModales = {display: false, typeOpenModal: 'create', datasourceSelected: null};
    @Input('header') header: string;
    @Input('responsive') responsive = true;
    @Input('styleClass') styleClass = '';
    @Input('maximizable') maximizable = true;
    @Input('draggable') draggable = true;
    @Input('modal') modal: true;
    @Input('width') width = '65%';
    @Input('baseZIndex') baseZIndex = 100000;
    @Input('minWidth') minWidth = '200px';
    @Input('key') key: string;
    @Input('data') set dataObject(data: any) {
        if (!ClvTsUtilsCommonsJs.isUndefinedOrNull(data)) {
            this.defaultObject = data;
        }
    }
    @Output() modalManagerChange = new EventEmitter<GestionnaireDeModales>(false);
    @Output() onHideDialog = new EventEmitter<any>(false);
  peuxAcceder = false;

    ngOnInit(): void {
        super.ngOnInit();
    }

  /**
   * S'exécute lors de la fermeture
   *
   * @param event
   */
  lorsDeLaFermeture(event: boolean) {
      if (event === false) {
        this.fermerModale();
      }
    }

  /**
   * Défini les arguments à passer aux requêtes.
   *
   * Ex:
   *
   * defineArgs(): void {
   *   super.defineArgs();
   *   const arg1 = {...this.args, modalManager: this.modalManager};
   *  this.args = {...arg1};
   * }
   *
   */
  defineArgs(): void {
      super.defineArgs();
       const arg1 = {...this.args, modalManager: this.modalManager};
      this.args = {...arg1};
    }

  /**
   * Ferme le modale-subtitution
   */
  fermerModale() {
      this.modalManager.display = false;
      this.modalManagerChange.emit(this.modalManager);
      this.onHideDialog.emit();
    }

  /**
   * Initialise le formulaire. Initialise la propriété formGroup à partir du service métier
   */
  initialiserFormulaire() {
      if (this.initialiserFormulaireMetier) {
        this.formGroup = this.initialiserFormulaireMetier.initialiserFormulaire<any>(this.defaultObject, this.getArgs());
      }
  }

  /**
   * Converti le formulaire pour l'envoi au serveur
   */
  convertirFormulaire() {
    return this.convertirFormulaireMetier.convertirFormulaire(this.formGroup, this.getArgs());
  }

  /**
   * Appelle la requête d'enregistrement d'une donnée
   */
  requeteEnregistrement() {
    this.requeteCreationMetier.requeteCreation(this.convertirFormulaire(), this.getArgs()).subscribe(() => {
      this.fermerModale();
      this.onSuccess.emit();
    });
  }

  /**
   * Appelle la requête de modification d'une donnée
   */
  requeteModification() {
    this.requeteModificationMetier.requeteModification(this.convertirFormulaire(), this.getArgs()).subscribe(() => {
      this.fermerModale();
      this.onSuccess.emit();
    });
  }

  /**
   * Vérifie la condition passée en parametre. si elle est vraie, permet d'azccéder au formulaire.
   *
   * @param e
   */
  handlePeutAcceder(e) {
    if (e) {
      this.modalManager.typeOpenModal === 'update' ?
          this.modifierFormulaire() : this.enregistrerFormulaire();
    }
  }
}

import {NgModule} from '@angular/core';

import {AntecedentDossierTableComponent} from './antecedent-dossier-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionRealisePharmacieDetailTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prescription-dossier-table/prescription-realise-pharmacie-detail-table/prescription-realise-pharmacie-detail-table.module';
import {ResultatDetailTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/resultat-examen-dossier-table/resultat-detail-table/resultat-detail-table.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PrescriptionRealisePharmacieDetailTableModule,
        ResultatDetailTableModule,
    ],
  exports: [AntecedentDossierTableComponent],
  declarations: [AntecedentDossierTableComponent],
  providers: [],
})
export class AntecedentDossierTableModule {
}

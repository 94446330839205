import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {AppSettings} from '../../../app.settings';
import {TraductionService} from '../../../core/services/traduction.service';
import {Autowired} from '@angular-ru/autowired';
import {LanguageStateService} from '../../../core/states/language/language.state.service';

@Component({
  selector: 'app-flags-menu',
  templateUrl: './flags-menu.component.html',
  styleUrls: ['./flags-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlagsMenuComponent implements OnInit {
  @Autowired() languageService: LanguageStateService;

  constructor(public appSettings: AppSettings,
              public translationService: TraductionService) {
  }

  ngOnInit() {
  }

  setLanguage(lang) {
    this.languageService.setLanguage(lang);
  }

}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE, API_ACTE_PRESTATION_DETAILS_PRISE_EN_CHARGE, STATUT_CLOTURE} from '@app/core/constantes';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {API_RECUPERER_INFO_USER_POURSUITE} from '@app/core/constantes/api.constante';

@Injectable({providedIn: 'root'})
export class InformationAssureTableListeRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;

  storageUser: IUserConnect = this.getUser();
  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    const { currentDataUser } = args;

    let urlApi: string;
    if (currentDataUser.libelleTypeStatut === STATUT_CLOTURE) {
      urlApi = API_ACTE_PRESTATION_DETAILS_PRISE_EN_CHARGE;
    } else {
      urlApi = API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE;
    }

    // update du paramètre
    data.SortOrder = null;

    const sendData = {...data, itemToSearch: {
      ...data.itemToSearch,
      idDemandePriseEnCharge: currentDataUser.idDemandePriseEnCharge,
      InfoSearchidDemandePriseEnCharge: { Consider: true },
    }};

    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${urlApi}`, sendData, {...this.postArgsMute});
  }

  recupererInfoUserPoursuite(id) {
    const sendData = {itemToSearch: {
        idSouscriptionLigne: id,
        InfoSearchidSouscriptionLigne: { Consider: true },
      }};

    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_INFO_USER_POURSUITE}`, sendData, {...this.postArgsMute});
  }

}

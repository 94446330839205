import {Component, Input} from '@angular/core';
import {IRapportRecuperationMetier} from '@app/core/interfaces/metier/i-rapport-recuperation-metier';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';
import {RepportingUtilities} from '@app/core/utilities/repporting-utilities';

/**
 * Classe définissant le layout d'affichage
 *
 * Services metier à implémenter:
 * * rapportRecuperationMetierService: IRapportRecuperationMetier
 */
@Component({
  selector: 'app-sgi-rapport-layout',
  template: ''
})
export class SgiRapportLayoutComponent {
  rapportRecuperationMetierService: IRapportRecuperationMetier;
  @Input('donneeRapport') donneeRapport: {name: string, param: Array<{Key: any, Value: any}>};

  /**
   * Gestionnaire de modales. Une liste peut avoir plusieurs gestionnaires de modales. Cepandant, le premier est celui qui
   * est affecté au modale-subtitution de base, servant à la création et la modification des données.
   */
  gestionnaireDeModales: GestionnaireDeModales[] = [new class implements GestionnaireDeModales {
    datasourceSelected: any;
    display: boolean;
    typeOpenModal: 'update' | 'create';
  }];

  /**
   * Arguments qui seront passés aux réquêtes et services
   */
  args: any = null;

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    const arg = {...this.args, donneeRapport: this.donneeRapport};
    this.args = {...arg};
  }

  /**
   * Récupération des arguments
   */
  getArgs(): any {
    this.defineArgs();
    return this.args;
  }

  /**
   * Lance la requete de chargement du rapport
   */
  afficherRapport() {
    const donnes = RepportingUtilities.getSgiReportQuery(this.getArgs().donneeRapport.name, this.getArgs().donneeRapport.param);
    this.rapportRecuperationMetierService.rapportRecuperationDonnee(donnes, this.getArgs()).subscribe(reponse => {
      this.gestionnaireDeModales[0].datasourceSelected = reponse.body.items;
      this.gestionnaireDeModales[0].display = true;
    });
  }

}

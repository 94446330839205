import {AfterViewInit, ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {DialogComponent} from '../../behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';

// @dynamic
@Component({
  selector: 'app-rapport-modal',
  templateUrl: './rapport-modal.feature.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: []
})
export class RapportModalFeatureComponent extends DialogComponent implements OnInit, AfterViewInit {
  @Autowired() sanitizer: DomSanitizer;
  @Output() modaleManagerChange = new EventEmitter<void>(false);

  fileToDisplay: any = {};
  display = false;

  constructor() {
    super();
    this.header = 'Document';
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
    this.display = true;
  }

  handleChangeModaleManager() {
    this.modaleManagerChange.emit();
  }

  ngOnInit(): void {
    this.redefinitionDuHeader();
    this.fileToDisplay = this.sanitizer.bypassSecurityTrustResourceUrl(this.modalManager.datasourceSelected[0].File);
    super.ngOnInit();
  }

  /**
   * Redéfini le Header du modale-subtitution
   */
  redefinitionDuHeader() {
    this.header = `Document ${this.modalManager.datasourceSelected[0].Name}`;
  }
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DatePipe} from '@angular/common';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {API_RECUPERER_INFO_USER} from '@app/core/constantes';


@Injectable({providedIn: 'root'})
export class InformationAssureTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;

    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteGetAssure(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                IdDemandePriseEnCharge: args.idDemandePriseEnCharge,
            },
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_INFO_USER}`, sendData, {...this.postArgsMute});
    }

}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ContactTableMetierService {
    @Autowired() formBuilder: FormBuilder;

    @Autowired() datePipe: DatePipe;


    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            telephone1: !!datas && !!datas.telephone1 ? datas.telephone1 : null,
            telephone2: !!datas && !!datas.telephone2 ? datas.telephone2 : null,
            fax: !!datas && !!datas.fax ? datas.fax : null,
            siteWeb: !!datas && !!datas.siteWeb ? this.datePipe.transform(datas.siteWeb, 'dd/MM/yyyy') : null,
            email: !!datas && !!datas.email ? datas.email : null,
            adressePostale: !!datas && !!datas.adressePostale ? datas.adressePostale : null,
            adresseGeographique: !!datas && !!datas.adresseGeographique ? datas.adresseGeographique : null,
        };

        return this.formBuilder.group({
            telephone1: [{value: data.telephone1, disabled: true}],
            telephone2: [{value: data.telephone2, disabled: true}],
            fax: [{value: data.fax, disabled: true}],
            siteWeb: [{value: data.siteWeb, disabled: true}],
            email: [{value: data.email, disabled: true}],
            adressePostale: [{value: data.adressePostale, disabled: true}],
            adresseGeographique: [{value: data.adresseGeographique, disabled: true}],
        });
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {ExampleFeatureComponent} from './example/example.feature.component';
import {TransitionFeatureComponent} from './transition/transition.feature.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [ExampleFeatureComponent, TransitionFeatureComponent],
  exports: [ExampleFeatureComponent, TransitionFeatureComponent]
})
export class D3FeatureModule {
}

import {Component, Input} from '@angular/core';
import {AbstractFormInputs} from '@shared/components/inputs/abstract-form-inputs';

@Component({
    selector: 'app-form-textarea',
    template: `
        <div class="input-container">
            <div class="d-flex justify-between">
                <label for="{{id}}">{{label}}&nbsp;<span *ngIf="required" class="danger-color">*</span></label>
                <div>
                    <app-info-bull-widget [infos]="infos"></app-info-bull-widget>
                </div>
            </div>
            <div class="">
        <textarea [attr.type]="type" [required]="required" id="{{id}}"
                  [placeholder]="placeholder"
                  [attr.minlength]="minlength"
                  [attr.min]="min"
                  [attr.max]="max"
                  (change)="onChange?.emit($event)"
                  (blur)="onBlur?.emit($event)"
                  (click)="onClick?.emit($event)"
                  (focus)="onFocus?.emit($event)"
                  (show)="onShow?.emit($event)"
                  [attr.maxlength]="maxlength"
                  [formControl]="control"
                  [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
                  [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"
                  [attr.size]="size" pInputTextarea></textarea>
            </div>
            <app-list-form-error [control]="control"
                                 [types]="errorTypes"
                                 [submitted]="submitted"
                                 [messages]="messages"></app-list-form-error>
        </div>`
})
export class FormTextareaComponent extends AbstractFormInputs {
    @Input('size') size = 'auto';
    @Input('type') type = 'text';
    @Input('placeholder') placeholder = '';
}

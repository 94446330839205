import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {RouterModule, Routes} from '@angular/router';
import {D3PageComponent} from './d3-page.component';
import {D3FeatureModule} from '../../../fonctionnalites/d3/d3.feature.module';

const routes: Routes = [
  {
    path: '', component: D3PageComponent, pathMatch: 'full'
  }
];

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule.forChild(routes),
    D3FeatureModule
  ],
  declarations: [
    D3PageComponent
  ]
})
export class D3PageModule {
}

import {NgModule} from '@angular/core';

import {ListAffectionTableListeComponent} from './list-affection-table.liste.component';
import {SharedModule} from '@shared/shared.module';
import {CommonModule} from '@angular/common';
import {AffectionAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/affection-autocomplete/form/affection-autocomplete.form.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        AffectionAutocompleteFormModule,
    ],
  exports: [ListAffectionTableListeComponent],
  declarations: [ListAffectionTableListeComponent],
  providers: [],
})
export class ListAffectionTableListeModule {
}

import {NgModule} from '@angular/core';

import {ListActeHospitalisationTableComponent} from './list-acte-hospitalisation-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/acte-autocomplete/form/acte-autocomplete.form.module';

@NgModule({
    imports: [CommonModule, SharedModule, ActeAutocompleteFormModule],
  exports: [ListActeHospitalisationTableComponent],
  declarations: [ListActeHospitalisationTableComponent],
  providers: [],
})
export class ListActeHospitalisationTableModule {
}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {BasicRestService} from '../../../core/services/basic-rest.service';
import {WebUtilities} from '../../../core/utilities/web.utilities';
import {Observable} from 'rxjs';
import {IActiverUtilisateurRestApi, IModifierMotDePasse} from './rest-password.interfaces';

@Injectable({providedIn: 'root'})
export class ResetPasswordRestApiService implements IActiverUtilisateurRestApi, IModifierMotDePasse {
  @Autowired() restService: BasicRestService;

  activerUtilisateur(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}utilisateur/active`, data);
  }

  modifierMotDePasse(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}utilisateur/reset-password/finish`, data);
  }
}

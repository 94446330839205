import {FormControl, FormGroup} from '@angular/forms';

/**
 * Validateur d'email.
 *
 * @param control
 */
export function emailValidator(control: FormControl): {[key: string]: any} {
    const emailRegexp = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$/;
    if (control.value && !emailRegexp.test(control.value)) {
        return {invalidEmail: true};
    }
}

/**
 * Vérifie que les champs "password" et "confirmPassword" sont identiques.
 *
 * Prends en paramètres les nom des champs et retourne un closure ayant en paramètre un objet <code>FormGroup</code>.
 * Ce closure sera utilisé pour valider le formulaire
 * @param passwordKey
 * @param passwordConfirmationKey
 */
export function matchingPasswords(passwordKey: string, passwordConfirmationKey: string) {
    return (group: FormGroup) => {
        const password = group.controls[passwordKey];
        const passwordConfirmation = group.controls[passwordConfirmationKey];
        if (password.value !== passwordConfirmation.value) {
            return passwordConfirmation.setErrors({mismatchedPasswords: true});
        }
    };
}

import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import { ActeRealiseDetailsTableListeComponent } from './acte-realise-details-table.liste.component';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [ActeRealiseDetailsTableListeComponent],
  declarations: [ActeRealiseDetailsTableListeComponent],
  providers: [],
})
export class ActeRealiseDetailsTableListeModule {
}

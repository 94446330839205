import {NgModule} from '@angular/core';

import {ModaleHospitalisationComponent} from './modale-hospitalisation.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeComplementaireAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/composants/acte-complementaire-table/acte-complementaire-autocomplete/form/acte-complementaire-autocomplete.form.module';
import {InformationAssureModule} from '@app/fonctionnalites/demande-pec/modale-action-row/modale-hospitalisation/information-assure/information-assure.module';
import {SoinRealiseModule} from '@app/fonctionnalites/demande-pec/modale-action-row/modale-hospitalisation/soin-realise/soin-realise.module';
import {SoinModule} from '@app/fonctionnalites/demande-pec/modale-action-row/modale-hospitalisation/soin/soin.module';
import {DemandePecDocumentModule} from '@app/fonctionnalites/demande-pec/composants/demande-pec-document/demande-pec-document.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        ActeComplementaireAutocompleteFormModule,
        InformationAssureModule,
        SoinRealiseModule,
        SoinModule,
        DemandePecDocumentModule,
    ],
  exports: [
      ModaleHospitalisationComponent,
  ],
  declarations: [
      ModaleHospitalisationComponent,
  ],
  providers: [],
})
export class ModaleHospitalisationModule {
}

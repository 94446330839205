import {AfterViewInit, Component, HostListener, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {PerfectScrollbarConfigInterface, PerfectScrollbarDirective} from 'ngx-perfect-scrollbar';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {rotate} from '@app/theme/utils/app-animation';
import {MenuService} from '@app/theme/components/menu/menu.service';
import {Settings} from '@app/app.settings.model';
import {AppSettings} from '@app/app.settings';
import {ThemeStateService} from '@app/core/states/theme/theme.state.service';
import {EcranVeilleService} from '@app/core/services/ecran-veille.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';

@Component({
    selector: 'app-pages',
    templateUrl: './pages.component.html',
    styleUrls: ['./pages.component.scss'],
    animations: [rotate],
    providers: [MenuService]
})
export class PagesComponent implements OnInit, AfterViewInit {
    @ViewChild('sidenav', {static: false}) sidenav: any;
    @ViewChild('backToTop', {static: false}) backToTop: any;
    @ViewChildren(PerfectScrollbarDirective) pss: QueryList<PerfectScrollbarDirective>;
    public optionsPsConfig: PerfectScrollbarConfigInterface = {};
    public settings: Settings;
    public showSidenav = false;
    public showInfoContent = false;
    public toggleSearchBar = false;
    private defaultMenu: string; // declared for return default menu when window resized
    public menus = ['vertical', 'horizontal'];
    public menuOption: string;
    public menuTypes = ['default', 'compact', 'mini'];
    public menuTypeOption: string;
    year: any;

    theme$: Observable<{ theme: string }>;
    @Autowired() ecranVeilleService: EcranVeilleService;
    @Autowired() themeStore: ThemeStateService;
    @Autowired() securityStateService: SecurityStateService;

    storageUser: IUserConnect = this.securityStateService.getUser();

    constructor(public appSettings: AppSettings,
                public router: Router,
                public menuService: MenuService) {
        this.settings = this.appSettings.settings;

    }

    ngOnInit() {
        this.optionsPsConfig.wheelPropagation = false;
        if (window.innerWidth <= 960) {
            this.settings.menu = 'vertical';
            this.settings.sidenavIsOpened = false;
            this.settings.sidenavIsPinned = false;
        }
        this.menuOption = this.settings.menu;
        this.menuTypeOption = this.settings.menuType;
        this.defaultMenu = this.settings.menu;
        this.buildYear();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.settings.loadingSpinner = false;
        }, 300);
        this.backToTop.nativeElement.style.visibility = 'none';
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.scrollToTop();
            }
            if (window.innerWidth <= 960) {
                this.sidenav.close();
            }
        });
        if (this.settings.menu === 'vertical') {
            this.menuService.expandActiveSubMenu(this.menuService.getVerticalMenuItems());
        }
    }

    buildYear() {
        this.year = new Date(Date.now()).getFullYear();
    }

    public toggleSidenav() {
        this.sidenav.toggle();
    }

    public chooseMenu() {
        this.settings.menu = this.menuOption;
        this.defaultMenu = this.menuOption;
        if (this.menuOption === 'horizontal') {
            this.settings.fixedSidenav = false;
        }
        this.router.navigate(['/']);
    }

    public chooseMenuType() {
        this.settings.menuType = this.menuTypeOption;
    }

    public changeTheme(theme) {
        this.themeStore.setTheme(theme);
    }

    public changeSidebarTheme(theme) {
        this.themeStore.setSideBarTheme(theme);
    }

    public closeInfoContent(showInfoContent) {
        this.showInfoContent = !showInfoContent;
    }

    @HostListener('window:resize')
    public onWindowResize(): void {
        if (window.innerWidth <= 960) {
            this.settings.sidenavIsOpened = false;
            this.settings.sidenavIsPinned = false;
            this.settings.menu = 'vertical';
        } else {
            (this.defaultMenu === 'horizontal') ? this.settings.menu = 'horizontal' : this.settings.menu = 'vertical';
            this.settings.sidenavIsOpened = true;
            this.settings.sidenavIsPinned = true;
        }
    }

    public onPsScrollY(event) {
        try {
            (event.target.scrollTop > 300) ?
                this.backToTop.nativeElement.style.visibility = 'flex' :
                this.backToTop.nativeElement.style.visibility = 'none';
        } catch (e) {
            console.log('onPsScrollY : error');
        }
    }

    public scrollToTop() {
        this.pss.forEach(ps => {
            if (ps.elementRef.nativeElement.id === 'main') {
                ps.scrollToTop(0, 250);
            }
        });
    }

    public closeSubMenus() {
        if (this.settings.menu === 'vertical') {
            this.menuService.closeAllSubMenus();
        }
    }
}

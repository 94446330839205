import {Injectable} from '@angular/core';
import {Autowired} from "@angular-ru/autowired";
import {HeureServeurRestApiService} from "./heure-serveur.rest-api.service";
import {Observable} from "rxjs";

@Injectable({providedIn: 'root'})
export class HeureServeurMetierService {
  @Autowired() restApi: HeureServeurRestApiService;

  constructor() {
  }

  recupererDateServeur(): Observable<any> | Promise<any> | any {
    return this.restApi.requeteReccuperationDateServeur();
  }
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from "@shared/shared.module";
import { AffectionFactureComponent } from './affection-facture.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
    ],
  exports: [AffectionFactureComponent],
  declarations: [AffectionFactureComponent],
  providers: [],
})
export class AffectionFactureModule {
}

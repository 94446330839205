import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {
    API_CALCULER_ACTES_HOSPITALISATION_DEMANDE_PEC,
    API_RECUPERER_ACTES_DEMANDE_PEC
} from '@app/core/constantes';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ListActeHospitalisationTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;

    storageUser: IUserConnect = this.getUser();

    postArgs = {successMute: true, errorMute: false, headers: null, params: null};

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        // console.log('data : ', data);
        // console.log('args : ', args);

        const body = [];
        return of({body: body});
    }

    recupererMontantActe(data: any, args?: any): Observable<any> | Promise<any> | any {
        // console.log('data : ', data);
        // console.log('args : ', args);

        const {acteHospitalisation, adherant} = args;

        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,

                InfoSearchdatePrestation: {Consider: false},
                InfoSearchestHospitalisation: {Consider: true},
                InfoSearchidActeChambreGroupeSoin: {Consider: true},
                InfoSearchidActeMedical: {Consider: true},
                InfoSearchidPrestataire: {Consider: true},
                InfoSearchidSouscriptionLigne: {Consider: true},
                estHospitalisation: acteHospitalisation.estHospitalisation,
                idActeChambreGroupeSoin: acteHospitalisation.idActeChambreGroupeSoin,
                idActeMedical: acteHospitalisation.idActeMedical,
                idPrestataire: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                idSouscriptionLigne: adherant.idSouscriptionLigne,
            }
        };

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ACTES_DEMANDE_PEC}`, sendData, {...this.postArgs});
    }

    calculeMontantActe(data: any, args?: any): Observable<any> | Promise<any> | any {
        // console.log('data : ', data);
        // console.log('args : ', args);

        let sendData: any;
        if (args.choixHospitalisationSelected) {
            const {adherant, objectTypeHospitalisationSelected, listActesTable} = args;

            sendData = {
                ...data,
                ItemsToSave: [
                    {
                        autoriseChambre: objectTypeHospitalisationSelected.autoriseChambre,
                        estChambre: objectTypeHospitalisationSelected.estChambre,
                        estHospitalisation: objectTypeHospitalisationSelected.estHospitalisation,
                        idActeChambreGroupeSoin: objectTypeHospitalisationSelected.idActeChambreGroupeSoin,
                        idActeMedical: objectTypeHospitalisationSelected.idActeMedical,
                        isNewItem: objectTypeHospitalisationSelected.isNewItem,
                        libelle: objectTypeHospitalisationSelected.libelle,
                        requiredTenant: objectTypeHospitalisationSelected.requiredTenant,
                        saisieUniquementMontant: objectTypeHospitalisationSelected.saisieUniquementMontant,

                        listeDemandePriseEnChargeSoinHospitalisation: [...listActesTable],

                        idPrestataire: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                        idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                        idSouscriptionLigne: adherant.idSouscriptionLigne,
                    },
                ]
            };

        } else {
            const {acteHospitalisation, adherant, montantHT, quantite} = args;

            sendData = {
                ...data,
                ItemsToSave: [
                    {
                        ...acteHospitalisation,

                        idPrestataire: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                        idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                        idSouscriptionLigne: adherant.idSouscriptionLigne,

                        idActe: acteHospitalisation.idActeMedical,
                        montantAPayer: montantHT ? montantHT : acteHospitalisation.montantAPayer,
                        montantHT: montantHT ? montantHT : acteHospitalisation.montantAPayer,
                        accordPrealable: acteHospitalisation.accordPrealable,
                        dateRealisation: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
                        quantite: quantite ? quantite : 1,
                        estActive: true,
                        // montantDette:?,
                        isDeleted: acteHospitalisation.isDeleted,
                    },
                ]
            };
        }

        return this.restService.postRequest(
            `${WebUtilities.baseUrlApi()}${API_CALCULER_ACTES_HOSPITALISATION_DEMANDE_PEC}`, sendData, {...this.postArgs});
    }
}

import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {DemandePriseChargeMetierService} from '../demande-prise-charge.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {
    AUCUN_ACTE_AJOUTER,
    AUCUN_ASSURE_RECHERCHER,
    AUCUN_MEDECIN_RECHERCHER,
    AUCUNE_AFFECTION_AJOUTER,
    AUCUNE_PRESCRIPTION_AJOUTER,
    CODE_OBJET_DEMANDE_PEC,
    ERROR_MESSAGE_ACTE_CHAMBRE_HOSPITALISATION_NOT_EXIST,
    ERROR_MESSAGE_ADD_DESCRIPTION_ACTE,
    ERROR_MESSAGE_ADD_DESCRIPTION_ACTE_HOSPITALISATION,
    STORAGE
} from '@app/core/constantes';
import {objectKeys} from 'codelyzer/util/objectKeys';
import {PoursuitePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/poursuite-prise-charge/poursuite-prise-charge.metier.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IListGarant} from '@app/core/interfaces/list-garant/i-list-garant';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {DemandeHospitalisationTableMetierService} from '@app/fonctionnalites/demande-pec/composants/demande-hospitalisation-table/demande-hospitalisation-table.metier.service';

interface IDataHospitalisationAutres {
    choixHospitalisation: boolean;
    hospitalisation: {
        objectType: any;
        montant: number;
        accordPrealable: string;
        objectChambre: any;
        nombreJour: number;
        prixUnitaireChambre: number;
        montantHorsPec: number;
        ticketModerateur: number;
        numeroChambre: any;
        service: any;
    };
    pharmacie: {
        objectType: any;
        montant: number;
        accordPrealable: string;
        montantHorsPec: number;
        ticketModerateur: number;
    };
    motifSoin: string;
    affichageMontantTotal: {
        hospitalisation: number;
        pharmacie: number;
    };
}

@Component({
    selector: 'app-demande-prise-charge-modale-feature',
    templateUrl: './demande-prise-charge-modale.feature.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DemandePriseChargeModaleFeatureComponent extends DialogComponent implements OnInit {
    @Autowired() convertirFormulaireMetier: DemandePriseChargeMetierService;
    @Autowired() initialiserFormulaireMetier: DemandePriseChargeMetierService;
    @Autowired() requeteCreationMetier: DemandePriseChargeMetierService;
    @Autowired() requeteModificationMetier: DemandePriseChargeMetierService;
    @Autowired() metierService: DemandePriseChargeMetierService;

    @Autowired() metierServicePoursuitePEC: PoursuitePriseChargeMetierService;

    @Autowired() securityState: SecurityStateService;

    @Autowired() metierServiceDemandeHospitalisation: DemandeHospitalisationTableMetierService;


    listGarantUser: IListGarant;

    @Input() refresh: boolean;

    /**
     * Fonctionnalité Section NOUVELLE DEMANDE DE PRISE EN CHARGE
     */
    assureSelected: any;
    medecinSelected: any;
    listActesSelected: any[] = [];
    listAffectionsSelected: any[] = [];
    documentAjouter: any[] = [];
    descriptionAll = '';
    @Output() saveDemandeSuccessEvent = new EventEmitter<any>();
    adherantPrincipale: any;

    /**
     * Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     */
    @Input() tableSelectedElement: any;
    tabActive = 'Demande de Prise en Charge';
    listActesComp: any[] = [];
    listPrescriptionSelected: any[] = [];
    affichageMontantTotal = {
        hospitalisation: 0,
        pharmacie: 0
    };
    dataHospitalisationAutres = {
        choixHospitalisation: null,
        hospitalisation: {
            objectType: {},
            montant: 0,
            objectChambre: {},
            nombreJour: 0,
            prixUnitaireChambre: 0,
            montantHorsPec: 0,
            ticketModerateur: 0,
            numeroChambre: '',
            service: '',
        },
        pharmacie: {
            objectType: {},
            montant: 0,
            montantHorsPec: 0,
            ticketModerateur: 0,
        },
        motifSoin: '',
        affichageMontantTotal: {
            hospitalisation: 0,
            pharmacie: 0,
        }
    };

    /**
     * Fonctionnalité Section NOUVELLE DEMANDE DE PRISE EN CHARGE
     */
    /**
     * END :: Fonctionnalité Section NOUVELLE DEMANDE DE PRISE EN CHARGE
     */


    /**
     * Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     */
    /**
     * END :: Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     */


    /**
     * Fonctionnalité Section HOSPITALISATION
     */
    dataHospitalisationSoin: any;
    dataListActeSoin: any[] = [];
    numeroBon = '';

    /**
     * END :: Fonctionnalité Section HOSPITALISATION
     */


    /**
     * Fonctionnalité Section POURSUITE DEMANDE DE PRISE EN CHARGE
     */
    isCalculerMontantPartAssure = {
        verif: false,
        montantTotal: 0
    };

    /**
     * END :: Fonctionnalité Section POURSUITE DEMANDE DE PRISE EN CHARGE
     */


    constructor() {
        super();

        this.listGarantUser = this.securityState.getListGarant();

        console.log('listGarantUser : ', this.listGarantUser);
    }

    ngOnInit() {
        if (this.modalManager.id === 'nouvelle_demande') {
            this.header = 'Demande Prise en charge';

        } else if (this.modalManager.id === 'cloture_demande') {
            this.header = 'Clôture Prise en charge';

        } else if (this.modalManager.id === 'prolonger_hospitalisation') {
            this.header = 'Enregistrer des soins d\'hospitalisation ';

        } else if (this.modalManager.id === 'poursuite_demande') {
            this.header = 'Poursuite Prise en charge';

            /**
             * continuer sur la poursuite de demande de prise en charge
             */
            const responseClotureDemande = this.modalManager.datasourceSelected.responseClotureDemande;
            responseClotureDemande.idDemandePriseEnCharge = responseClotureDemande.idDemandeComplementaire;

            this.metierServicePoursuitePEC.requeteRecuperationActesPoursuite(
                {}, {adherant: responseClotureDemande}).subscribe((reponseActe) => {

                this.metierServicePoursuitePEC.requeteRecuperationPrixActesPoursuite(
                    {}, {actePoursuite: reponseActe}).subscribe((reponsePrixActes) => {

                    this.modalManager.datasourceSelected.listActesPoursuite = reponsePrixActes.body.items;
                });
            });
            /**
             *
             */

        } else if (this.modalManager.id === 'nouvelle_demande_hospitalisation') {
            this.header = 'Demande de prise en charge hospitalisation';

            this.metierServiceDemandeHospitalisation.choixHospitalisationSelected = false;
            this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected = null;
        }

        this.resetDatasComponent();

        /** initialisation des données du modale dans la variable de partage :: modal Hospitalisation */
        this.dataHospitalisationSoin = this.modalManager;
    }

    ngAfterViewChecked() {
        if (this.refresh) {
            this.resetDatasComponent();
        }
    }


    enregistrerFormulaire() {
        if (this.modalManager.id === 'nouvelle_demande') {
            this.creationNouvelleDemandePEC();
        } else if (this.modalManager.id === 'cloture_demande') {
            this.creationClotureDemandePEC();
        } else if (this.modalManager.id === 'prolonger_hospitalisation') {
            this.creationHospitalisationDemandePEC();
        } else if (this.modalManager.id === 'poursuite_demande') {
            this.creationContinuerPoursuiteDemandePEC();
        } else if (this.modalManager.id === 'nouvelle_demande_hospitalisation') {
            this.creationNouvelleDemandeHospitalisationPEC();
        }
    }


    /**
     *
     * Fonctionnalité Section NOUVELLE DEMANDE DE PRISE EN CHARGE
     *
     */
    handleAssureTrouve(event) {
        this.assureSelected = event;

        // console.log('assureSelected : ', this.assureSelected);
    }

    handleListActes(event: any[]) {
        this.listActesSelected = event;

        // console.log('listActesSelected : ', this.listActesSelected);
    }

    /** Enregistre le formulaire pour la création demande */
    creationNouvelleDemandePEC() {
        let shower: any;
        let message = '';
        let sendData = false;

        let montantTotal = 0;

        if (!this.assureSelected || objectKeys(this.assureSelected).length === 0) {
            message = AUCUN_ASSURE_RECHERCHER;

        } else if (!this.medecinSelected || objectKeys(this.medecinSelected).length === 0) {
            message = AUCUN_MEDECIN_RECHERCHER;

        } else if (this.listActesSelected.length === 0) {
            message = AUCUN_ACTE_AJOUTER;

        } else if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.listGarantUser.affectionFacultativePrestation) &&
            this.listAffectionsSelected.length === 0) {
            message = AUCUNE_AFFECTION_AJOUTER;

        } else {

            // calcul du montant total, ticket moderateur
            this.listActesSelected.forEach((item) => {
                if (!item.accordPrealable) {
                    montantTotal = montantTotal + (item.ticketModerateur + item.montantHorsPec);
                }
            });

            const countCommentaireValid = this.funcCountCommentaireValid(this.listActesSelected);

            if (countCommentaireValid) {
                message = `Total part assuré : ${montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
                sendData = true;
            } else if (!countCommentaireValid && this.descriptionAll === '') {
                message = ERROR_MESSAGE_ADD_DESCRIPTION_ACTE;
            } else {
                message = `Total part assuré : ${montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
                sendData = true;
            }
        }

        if (sendData) {
            shower = this.alertService.showWarning(message);
            shower.accept = () => {
                this.saveDonneesFormulaireNouvelleDemande();
            };
            shower.reject = () => {
            };
            shower.show();
        } else {
            this.alertService.showError(message).show();
        }
    }

    saveDonneesFormulaireNouvelleDemande() {
        const args = {
            adherant: this.assureSelected,
            medecin: this.medecinSelected,
            listActes: this.listActesSelected,
            listAffections: this.listAffectionsSelected,
            listDocuments: this.formatDataDocumentSend(this.documentAjouter),
            descriptionAll: this.descriptionAll,
            numeroBon: this.numeroBon,

            modalNouvelleDemandeHospitalisation: false,
            estHospitalisation: null,
        };

        this.metierService.requeteSaveDonneesFormulaireNouvelleDemande({}, args).subscribe((reponse) => {
            this.saveDemandeSuccessEvent.emit({
                event: reponse.body.items[0],
                args: null,
            });
        });
    }

    /**
     *
     * END :: Fonctionnalité Section NOUVELLE DEMANDE DE PRISE EN CHARGE
     *
     */

    handleMedecin(event) {
        this.medecinSelected = event;

        // console.log('medecinSelected : ', this.medecinSelected);
    }

    handleNumeroBon(event) {
        this.numeroBon = event;

        // console.log('numeroBon : ', this.numeroBon);
    }

    handleAffections(event: any[]) {
        this.listAffectionsSelected = event;

        // console.log('listAffectionsSelected : ', this.listAffectionsSelected);
    }

    handleDescriptionAll(event) {
        this.descriptionAll = event;

        // console.log('descriptionAll : ', this.descriptionAll);
    }

    handleDocumentAjouter(event: any[]) {
        this.documentAjouter = event;

        // console.log('documentAjouter : ', this.documentAjouter);
    }

    handleResetDocumentAjouter(event: any[]) {
        this.tabActive = 'Demande de Prise en Charge';
        this.documentAjouter = event;

        // console.log('handleResetDocumentAjouter : ', this.documentAjouter);
    }

    handleMontantHospitalisation(event: any) {
        this.affichageMontantTotal = event;

        // console.log('affichageMontantTotal : ', this.affichageMontantTotal);
    }

    handleDataHospitalisationAutres(event: any) {
        this.dataHospitalisationAutres = event;

        // console.log('dataHospitalisationAutres : ', this.dataHospitalisationAutres);
    }

    formatDataDocumentSend(array: any[]) {
        const dataFormat: any[] = [];

        array.forEach((item) => {
            if (item.isDeleted !== undefined) {
                dataFormat.push(item);
            } else {
                dataFormat.push({
                    codeObjet: CODE_OBJET_DEMANDE_PEC,
                    codeDocument: item.type.codeDocument,
                    dataKey: item.type.dataKey,
                    dateCreation: item.type.dateCreation,
                    dateMaj: item.type.dateMaj,
                    etude: item.type.etude,
                    idDocument: null,
                    idDocumentAfournir: item.type.idDocument,
                    idObjet: item.type.idObjet,
                    idTenant: item.type.idTenant,
                    idTypeDocument: item.type.idTypeDocument,
                    infoSearchCodeDocument: {consider: true, isOrderByField: false, isSumField: false},
                    infoSearchCodeObjet: {consider: true, isOrderByField: false, isSumField: false},
                    infoSearchLibelle: {consider: true, isOrderByField: false, isSumField: false},
                    infoSearchLibelleDocument: {consider: true, isOrderByField: false, isSumField: false},
                    isDeleted: false,
                    libelle: item.type.libelle,
                    libelleDocument: item.type.libelleDocument,
                    libelleObjet: item.type.libelleObjet,
                    obligatoire: null,

                    observations: item.titre,
                    extension: this.recupererExtension(item.fichier.extension),
                    pieceJointe: item.fichier.fullDataUrl,
                    requiredTenant: true,
                    selected: false,
                    tabDisplay: item.fichier.data,
                });
            }
        });

        return dataFormat;
    }

    recupererExtension(item) {
        const value = item.split('.');
        return value[1];
    }

    funcCountCommentaireValid(arrayList) {
        return arrayList.every(
            (elt) => (elt.accordPrealable && elt.descriptions !== '') || (!elt.accordPrealable && elt.descriptions === ''));
    }


    /**
     *
     * Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     *
     */
    getTabChange(event) {
        this.tabActive = event.originalEvent.target['innerText'];
    }

    handleListActesComp(event: any[]) {
        this.listActesComp = event;

        // console.log('listActesComp : ', this.listActesComp);
    }

    handleListPrescription(event: any[]) {
        this.listPrescriptionSelected = event;

        // console.log('listPrescriptionSelected : ', this.listPrescriptionSelected);
    }

    getMedecinObject(nomMedecin) {
        if (this.metierService.medecins.length === 0) {
            let listMedecinsStorage = window.localStorage.getItem(STORAGE.MEDECINS);
            if (!listMedecinsStorage) {
                this.metierService.rechercherMedecin({}).subscribe(res => {
                    this.metierService.medecins = res.body.items;
                    window.localStorage.setItem(STORAGE.MEDECINS, JSON.stringify(this.metierService.medecins));
                    listMedecinsStorage = window.localStorage.getItem(STORAGE.MEDECINS);
                });
            }
            listMedecinsStorage = JSON.parse(listMedecinsStorage);
            this.metierService.medecins = listMedecinsStorage;
        }
        return this.metierService.medecins.find((elt) => elt.nomCompletMedecin === nomMedecin);
    }

    /** Enregistre le formulaire pour la clôture demande */
    creationClotureDemandePEC() {
        let shower: any;
        let message = '';
        let sendData = false;

        let montantTotal = 0;


        /*else if (this.listActesComp.length === 0) {
         message = AUCUN_ACTE_COMPLEMENTAIRE_AJOUTER;
       } else if (this.listAffectionsSelected.length === 0) {
         message = AUCUNE_AFFECTION_AJOUTER;
       } else if (this.listPrescriptionSelected.length === 0) {
         message = AUCUNE_PRESCRIPTION_AJOUTER;
       } else if (this.documentAjouter.length === 0) {
         message = AUCUN_DOCUMENT_AJOUTER;
       }*/


        if (!this.tableSelectedElement || objectKeys(this.tableSelectedElement).length === 0) {
            message = AUCUN_ASSURE_RECHERCHER;
        } else if (
            objectKeys(this.getMedecinObject(this.modalManager.datasourceSelected.listActesCloture[0].nomCompletMedecin)).length === 0
        ) {
            message = AUCUN_MEDECIN_RECHERCHER;

        } else if (this.modalManager.datasourceSelected.listActesCloture.length === 0) {
            message = AUCUN_ACTE_AJOUTER;

        } else if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.listGarantUser.affectionFacultativePrestation) &&
            this.listAffectionsSelected.length === 0) {
            message = AUCUNE_AFFECTION_AJOUTER;

        } else if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.listGarantUser.affectionFacultativePrestation) &&
            this.listAffectionsSelected.length !== 0 &&
            (this.listAffectionsSelected.length === this.detectEltDisableTable(this.listAffectionsSelected))) {
            message = AUCUNE_AFFECTION_AJOUTER;

            // tslint:disable-next-line:max-line-length
        } else if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.listGarantUser.affectionPrescriptionFacultativePrestation) &&
            this.listPrescriptionSelected.length === 0) {
            message = AUCUNE_PRESCRIPTION_AJOUTER;

        } else {

            // calcul du montant total, part Assuré
            const listActesCloture = this.modalManager.datasourceSelected.listActesCloture;
            if (listActesCloture && listActesCloture.length > 0) {
                listActesCloture.forEach((item) => {
                    montantTotal = montantTotal + item.partAssure;
                });
            }

            const countCommentaireValid = this.funcCountCommentaireValid(this.listActesComp);

            if (countCommentaireValid) {
                message = `Total part assuré : ${montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
                sendData = true;
            } else if (!countCommentaireValid && this.descriptionAll === '') {
                message = ERROR_MESSAGE_ADD_DESCRIPTION_ACTE;
            } else {
                message = `Total part assuré : ${montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
                sendData = true;
            }
        }

        // tslint:disable-next-line:max-line-length
        this.modalManager.datasourceSelected.listActesCloture = this.activeEstRealiseActe(this.modalManager.datasourceSelected.listActesCloture);


        /**
         * Ajout de validation pour hospitalisation et autres
         */
        if (this.dataHospitalisationAutres.choixHospitalisation === false) {
            if (objectKeys(this.dataHospitalisationAutres.pharmacie.objectType).length === 0) {
                sendData = false;
                message = `Veuillez sélectionner un type d'hospitalisation.`;
            }
        }

        if (this.dataHospitalisationAutres.choixHospitalisation === true) {
            if (objectKeys(this.dataHospitalisationAutres.hospitalisation.objectType).length === 0) {
                sendData = false;
                message = `Veuillez sélectionner un type d'hospitalisation.`;
            } else {
                if (objectKeys(this.dataHospitalisationAutres.hospitalisation.objectChambre).length === 0) {
                    sendData = false;
                    message = `Veuillez sélectionner au moins une chambre pour mettre le patient en hospitalisation.`;
                } else {
                    if (this.dataHospitalisationAutres.motifSoin === '') {
                        sendData = false;
                        message = `Veuillez saisir la descriptions de l'acte soumis à accord préalable`;
                    }
                }

            }
        }
        /**
         *
         */

        if (sendData) {
            shower = this.alertService.showWarning(message);
            shower.accept = () => {
                this.saveDonneesFormulaireClotureDemande();
            };
            shower.reject = () => {
            };
            shower.show();
        } else {
            this.alertService.showError(message).show();
        }
    }

    detectEltDisableTable(list: any[] = []) {
        let number = 0;
        list.forEach((elt) => {
            if (elt.isDeleted && elt.isDeleted === true) {
                number += 1;
            }
        });

        return number;
    }

    activeEstRealiseActe(actes) {
        const arrayItems: any[] = [];
        actes.forEach((elt) => {
            if (elt.realise === true) {
                elt.estRealise = true;
                elt.estConventionne = true;
            }
            arrayItems.push(elt);
        });

        return arrayItems;
    }

    deletePropertyObject(object) {
        delete object.id_key;
        return object;
    }

    deletePropertyArrayObject(arrayObject: any[]) {
        const arrayItems: any[] = [];
        arrayObject.forEach((item) => {
            delete item.id_key;
            arrayItems.push(item);
        });

        return arrayItems;
    }

    saveDonneesFormulaireClotureDemande() {
        let args: any;

        // console.log('this.dataHospitalisationAutres : ', this.dataHospitalisationAutres);

        if (this.dataHospitalisationAutres.choixHospitalisation === null) {
            args = {
                adherant: this.deletePropertyObject(this.tableSelectedElement),
                medecin: this.getMedecinObject(this.modalManager.datasourceSelected.listActesCloture[0].nomCompletMedecin),
                listActes: [
                    ...this.modalManager.datasourceSelected.listActesCloture,
                    ...this.deletePropertyArrayObject(this.listActesComp)
                ], //
                listAffections: this.deletePropertyArrayObject(this.listAffectionsSelected),
                listPrescriptions: this.deletePropertyArrayObject(this.listPrescriptionSelected),
                listDocuments: this.formatDataDocumentSend(this.documentAjouter),
                descriptionsAll: this.descriptionAll,

                acteGroupeSoin: {},
                chambreHospitalisation: {},
                currentChambreHospitalisation: {},
                currentTypeHospitalisation: {}
            };
        }

        if (this.dataHospitalisationAutres.choixHospitalisation === true) {

            const HOSPITALISATION: any = {...this.dataHospitalisationAutres.hospitalisation};

            args = {
                adherant: this.deletePropertyObject(this.tableSelectedElement),
                medecin: this.getMedecinObject(this.modalManager.datasourceSelected.listActesCloture[0].nomCompletMedecin),
                listActes: [
                    ...this.modalManager.datasourceSelected.listActesCloture,
                    ...this.deletePropertyArrayObject(this.listActesComp)
                ],
                listAffections: this.deletePropertyArrayObject(this.listAffectionsSelected),
                listPrescriptions: this.deletePropertyArrayObject(this.listPrescriptionSelected),
                listDocuments: this.formatDataDocumentSend(this.documentAjouter),
                descriptionsAll: this.descriptionAll,

                acteGroupeSoin: {
                    ...HOSPITALISATION.objectType,

                    montantHT: HOSPITALISATION.objectType.prixUnitaireChambre,
                    montantAPayer: HOSPITALISATION.objectType.prixUnitaireChambre,

                    descriptions: this.dataHospitalisationAutres.motifSoin,
                    numeroChambre: HOSPITALISATION.numeroChambre,
                    serviceMedical: HOSPITALISATION.service,

                    ListeDemandePriseEnChargeSoinHospitalisation: [{...HOSPITALISATION.objectChambre}],
                },
            };
        }

        if (this.dataHospitalisationAutres.choixHospitalisation === false) {

            const PHARMACIE: any = {...this.dataHospitalisationAutres.pharmacie};

            args = {
                adherant: this.deletePropertyObject(this.tableSelectedElement),
                medecin: this.getMedecinObject(this.modalManager.datasourceSelected.listActesCloture[0].nomCompletMedecin),
                listActes: [
                    ...this.modalManager.datasourceSelected.listActesCloture,
                    ...this.deletePropertyArrayObject(this.listActesComp)
                ],
                listAffections: this.deletePropertyArrayObject(this.listAffectionsSelected),
                listPrescriptions: this.deletePropertyArrayObject(this.listPrescriptionSelected),
                listDocuments: this.formatDataDocumentSend(this.documentAjouter),
                descriptionsAll: this.descriptionAll,

                acteGroupeSoin: {
                    ...PHARMACIE.objectType,
                    descriptions: this.dataHospitalisationAutres.motifSoin,
                    montantHT: PHARMACIE.objectType.montantAPayer,
                    montantAPayer: PHARMACIE.objectType.montantAPayer,
                },
            };
        }

        this.metierService.requeteSaveDonneesFormulaireClotureDemande({}, args).subscribe((responseClotureDemande) => {

            console.log('tableSelectedElement : ', this.tableSelectedElement);
            console.log('responseClotureDemande : ', responseClotureDemande.body.items[0]);

            this.saveDemandeSuccessEvent.emit({
                event: null,
                args: {
                    data: {adherent: this.tableSelectedElement, responseClotureDemande: responseClotureDemande.body.items[0]},
                    statut: 'continuer_poursuite_apres_cloture'
                },
            });
            this.fermerModale();
        });
    }

    /**
     *
     * END :: Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     *
     */


    /**
     *
     * Fonctionnalité Section HOSPITALISATION
     *
     */
    handleDataHospitalisationSoin(event: any[]) {
        this.dataHospitalisationSoin = event;

        // console.log('dataHospitalisationSoin : ', this.dataHospitalisationSoin);
    }

    handleDataListActeSoin(event: any[]) {
        this.dataListActeSoin = event;

        // console.log('dataListActeSoin : ', this.dataListActeSoin);
    }

    /** Enregistre le formulaire pour Hospuitalisation */
    creationHospitalisationDemandePEC() {
        let shower: any;
        let message = '';
        let sendData = false;
        let montantTotal = 0;

        // tslint:disable-next-line:max-line-length
        if ((!this.dataHospitalisationSoin.datasourceSelected.adherant || objectKeys(this.dataHospitalisationSoin.datasourceSelected.adherant).length === 0) ||
            // tslint:disable-next-line:max-line-length
            !this.dataHospitalisationSoin.datasourceSelected.reponseAdherant || objectKeys(this.dataHospitalisationSoin.datasourceSelected.reponseAdherant).length === 0) {
            message = AUCUN_ASSURE_RECHERCHER;
        } else if ((this.dataHospitalisationSoin.datasourceSelected.reponseSoins.length === 0) && (this.dataListActeSoin.length === 0)) {
            message = 'Veuillez saisir des soins';
        } else {

            // calcul du montant total,
            if (this.dataListActeSoin && this.dataListActeSoin.length > 0) {
                this.dataListActeSoin.forEach((item) => {
                    montantTotal = montantTotal + item.partAssureNet;
                });
            }
        }

        if (message === '') {
            message = `Total part assuré : ${montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
            sendData = true;
        }

        if (sendData) {
            shower = this.alertService.showWarning(message);
            shower.accept = () => {
                this.saveDonneesFormulaireHospitalisationDemande();
            };
            shower.reject = () => {
            };
            shower.show();
        } else {
            this.alertService.showError(message).show();
        }
    }

    saveDonneesFormulaireHospitalisationDemande() {
        let args: any;

        args = {...this.dataHospitalisationSoin.datasourceSelected.reponseAdherant};
        args.idActe = args.idActeMedical;
        args.listDocumentExamens = this.formatDataDocumentSend(this.documentAjouter);
        args.listeDemandePriseEnChargeSoinHospitalisation = [
            ...this.dataHospitalisationSoin.datasourceSelected.reponseSoins,
            ...this.dataListActeSoin
        ];

        this.metierService.requeteSaveDonneesFormulaireHospitalisationDemande({}, args).subscribe(() => {
            this.fermerModale();
            this.saveDemandeSuccessEvent.emit({
                event: null,
                args: null,
            });
        });
    }

    /**
     *
     * END :: Fonctionnalité Section HOSPITALISATION
     *
     */


    private resetDatasComponent() {
        /**
         * Fonctionnalité Section NEW DEMANDE, CLÔTURE, POURSUITE
         */
        this.assureSelected = {};
        this.medecinSelected = {};
        this.listActesSelected = [];
        this.listAffectionsSelected = [];
        this.documentAjouter = [];
        this.descriptionAll = '';
        this.listActesComp = [];

        this.tabActive = 'Informations';
        this.listPrescriptionSelected = [];

        /**
         * Fonctionnalité Section HOSPITALISATION
         */
        this.affichageMontantTotal = {
            hospitalisation: 0,
            pharmacie: 0
        };
        this.dataHospitalisationAutres = {
            choixHospitalisation: null,
            hospitalisation: {
                objectType: {},
                montant: 0,
                objectChambre: {},
                nombreJour: 0,
                prixUnitaireChambre: 0,
                montantHorsPec: 0,
                ticketModerateur: 0,
                numeroChambre: '',
                service: '',
            },
            pharmacie: {
                objectType: {},
                montant: 0,
                montantHorsPec: 0,
                ticketModerateur: 0,
            },
            motifSoin: '',
            affichageMontantTotal: {
                hospitalisation: 0,
                pharmacie: 0,
            }
        };
        this.dataHospitalisationSoin = null;
        this.dataListActeSoin = [];
        this.numeroBon = '';

        /**
         * Fonctionnalité Section POURSUITE
         */
        this.isCalculerMontantPartAssure = {
            verif: false,
            montantTotal: 0
        };
    }


    /**
     *
     * Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     *
     */
    handleIsCalculerMontantPartAssure(event) {
        // console.log('handleIsCalculerMontantPartAssure : ', event);

        this.isCalculerMontantPartAssure = event;
    }

    creationContinuerPoursuiteDemandePEC() {

        /* else if (!this.isCalculerMontantPartAssure.verif) {
             message = VERIFICATION_CALCUL_PART_ASSURE;
         }*/

        let shower: any;
        let message = '';
        let sendData = false;

        if (!this.tableSelectedElement || objectKeys(this.tableSelectedElement).length === 0) {
            message = AUCUN_ASSURE_RECHERCHER;
        } else if (!this.medecinSelected || objectKeys(this.medecinSelected).length === 0) {
            message = AUCUN_MEDECIN_RECHERCHER;
        } else if (this.listActesSelected.length === 0) {
            message = AUCUN_ACTE_AJOUTER;
        } else {

            let countCommentaireValid: boolean;
            countCommentaireValid = this.listActesComp.every(
                (elt) => (elt.accordPrealable && elt.descriptions !== '') || (!elt.accordPrealable && elt.descriptions === ''));

            if (countCommentaireValid) {
                message = `Total part assuré : ${this.isCalculerMontantPartAssure.montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
                sendData = true;
            } else if (!countCommentaireValid && this.descriptionAll === '') {
                message = ERROR_MESSAGE_ADD_DESCRIPTION_ACTE;
            } else {
                message = `Total part assuré : ${this.isCalculerMontantPartAssure.montantTotal} F.cfa, Voulez - vous valider cette opération ?`;
                sendData = true;
            }
        }

        if (sendData) {
            shower = this.alertService.showWarning(message);
            shower.accept = () => {
                this.saveDonneesFormulaireContinuerPoursuiteDemande();
            };
            shower.reject = () => {
            };
            shower.show();
        } else {
            this.alertService.showError(message).show();
        }
    }

    saveDonneesFormulaireContinuerPoursuiteDemande() {
        const agrs = {
            adherant: this.tableSelectedElement,
            medecin: this.medecinSelected,
            listActes: [...this.listActesSelected, ...this.deletePropertyArrayObject(this.listActesComp)], //
            listAffections: this.deletePropertyArrayObject(this.listAffectionsSelected),
            listPrescriptions: this.deletePropertyArrayObject(this.listPrescriptionSelected),
            listDocuments: this.formatDataDocumentSend(this.documentAjouter),
            descriptionsAll: this.descriptionAll,
        };

        this.metierServicePoursuitePEC.requeteSaveDonneesFormulairePoursuiteDemande({}, agrs).subscribe((reponse) => {
            const response = reponse.body.items[0];
            this.saveDemandeSuccessEvent.emit({
                event: response,
                args: null,
            });
            this.fermerModale();
        });
    }

    /**
     *
     * END :: Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     *
     */


    /**
     *
     * Fonctionnalité Section NOUVELLE DEMANDE HOSPITALISATION
     *
     */

    /** Enregistre le formulaire pour la création demande avec hospitalisation */
    creationNouvelleDemandeHospitalisationPEC() {
        let shower: any;
        let titreAlert = 'Alerte';
        let messageAlert = '';
        let sendData = false;

        let montantTotal = 0;

        /* else if (this.listAffectionsSelected.length === 0) {
          message = AUCUNE_AFFECTION_AJOUTER;
        }*/

        console.log('this. : ', this.metierServiceDemandeHospitalisation);

        const arrayfindElt: any[] = this.listActesSelected.filter((elt) => elt.estChambre === true);

        if (arrayfindElt.length === 0 && this.metierServiceDemandeHospitalisation.choixHospitalisationSelected) {
            this.alertService.showError(ERROR_MESSAGE_ACTE_CHAMBRE_HOSPITALISATION_NOT_EXIST).show();
            return false;
        }

        if (!this.assureSelected || objectKeys(this.assureSelected).length === 0) {
            messageAlert = AUCUN_ASSURE_RECHERCHER;

        } else if (!this.medecinSelected || objectKeys(this.medecinSelected).length === 0) {
            messageAlert = AUCUN_MEDECIN_RECHERCHER;

        } else if (this.listActesSelected.length === 0) {
            messageAlert = AUCUN_ACTE_AJOUTER;

        } else if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.listGarantUser.affectionFacultativePrestation) &&
            this.listAffectionsSelected.length === 0) {
            messageAlert = AUCUNE_AFFECTION_AJOUTER;

        } else {

            if (this.metierServiceDemandeHospitalisation.choixHospitalisationSelected) {
                if (!this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected.accordPrealable) {
                    // calcul du montant total, ticket moderateur
                    this.listActesSelected.forEach((item) => {
                        if (!item.accordPrealable) {
                            montantTotal = montantTotal + item.ticketModerateur;
                        }
                    });
                }
            }

            const countCommentaireValid = this.funcCountCommentaireValid(this.listActesSelected);

            if (countCommentaireValid) {
                titreAlert = 'Valider une Hospitalisation ou une autres prestations';
                messageAlert = `Total partAssuré : ${montantTotal} , Voulez-vous enregistrer cette prestation ?`;
                // messageAlert = `Voulez-vous enregistrer cette demande d'hospitalisation ?`;
                sendData = true;
            } else if (!countCommentaireValid && this.descriptionAll === '') {
                messageAlert = ERROR_MESSAGE_ADD_DESCRIPTION_ACTE_HOSPITALISATION;
            } else {
                titreAlert = 'Valider une Hospitalisation ou une autres prestations';
                messageAlert = `Total partAssuré : ${montantTotal} , Voulez-vous enregistrer cette prestation ?`;
                // messageAlert = `Voulez-vous enregistrer cette demande d'hospitalisation ?`;
                sendData = true;
            }
        }

        if (sendData) {
            shower = this.alertService.showWarning(messageAlert, titreAlert);
            shower.accept = () => {
                this.saveDonneesFormulaireNouvelleDemandeHospitalisation();
            };
            shower.reject = () => {
            };
            shower.show();
        } else {
            this.alertService.showError(messageAlert).show();
        }
    }

    saveDonneesFormulaireNouvelleDemandeHospitalisation() {
        let reconstructionListActes: any;
        if (this.metierServiceDemandeHospitalisation.choixHospitalisationSelected) {
            const objectTypeHospitalisationSelected = this.metierServiceDemandeHospitalisation.objectTypeHospitalisationSelected;

            this.listActesSelected.forEach((item) => {
                item.montantAPayer = item.prixUnitaire;
                item.montantHT = item.prixUnitaire;
            });

            reconstructionListActes = [{
                accordPrealable: objectTypeHospitalisationSelected.accordPrealable,
                autoriseChambre: objectTypeHospitalisationSelected.autoriseChambre,
                estChambre: objectTypeHospitalisationSelected.estChambre,
                estHospitalisation: objectTypeHospitalisationSelected.estHospitalisation,
                idActeChambreGroupeSoin: objectTypeHospitalisationSelected.idActeChambreGroupeSoin,
                idActeMedical: objectTypeHospitalisationSelected.idActeMedical,
                isNewItem: objectTypeHospitalisationSelected.isNewItem,
                libelle: objectTypeHospitalisationSelected.libelle,
                requiredTenant: objectTypeHospitalisationSelected.requiredTenant,
                saisieUniquementMontant: objectTypeHospitalisationSelected.saisieUniquementMontant,

                listeDemandePriseEnChargeSoinHospitalisation: [...this.listActesSelected],

                numeroChambre: objectTypeHospitalisationSelected.numeroChambre ?
                    objectTypeHospitalisationSelected.numeroChambre :
                    '',
                serviceMedical: objectTypeHospitalisationSelected.service ?
                    objectTypeHospitalisationSelected.service :
                    '',
            }];

            // console.log('reconstructionListActes : ', reconstructionListActes);

        } else {
            reconstructionListActes = [...this.listActesSelected];
        }

        const args = {
            adherant: this.assureSelected,
            medecin: this.medecinSelected,
            listActes: reconstructionListActes,
            listAffections: this.listAffectionsSelected,
            listDocuments: this.formatDataDocumentSend(this.documentAjouter),
            descriptionAll: this.descriptionAll,
            numeroBon: this.numeroBon,

            modalNouvelleDemandeHospitalisation: true,
            estHospitalisation: this.metierServiceDemandeHospitalisation.choixHospitalisationSelected,
        };

        this.metierService.requeteSaveDonneesFormulaireNouvelleDemande({}, args).subscribe((reponse) => {
            this.saveDemandeSuccessEvent.emit({
                event: reponse.body.items[0],
                args: null,
            });
        });
    }

    /**
     *
     * END :: Fonctionnalité Section CLOTURE DEMANDE DE PRISE EN CHARGE
     *
     */

}

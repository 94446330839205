import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {ILanguageState, LanguageStoreService} from '@app/core/states/language/language.store.service';

@Injectable({providedIn: 'root'})
export class LanguageQueryService extends Query<ILanguageState> {
  lang$ = this.select('lang');

  constructor(protected store: LanguageStoreService) {
    super(store);
  }
}

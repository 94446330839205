import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {ILoaderStore, LoaderStoreService} from '@app/core/states/loader/loader.store.service';

@Injectable({providedIn: 'root'})
export class LoaderQueryService extends Query<ILoaderStore> {
  count$ = this.select('count');
  constructor(protected store: LoaderStoreService) {
    super(store);
  }
}

export const OperatorEnum = {
        EQUAL: 'EQUAL',
        NOTEQUAL: 'NOT EQUAL',
        BETWEEN: 'BETWEEN',
        STARSTWITH: 'STARTS WITH',
        ENDSWITH: 'ENDS WITH',
        CONTAINS: 'CONTAINS',
        LESS: 'LESS',
        LESSOREQUAL: 'LESS OR EQUAL',
        MORE: 'MORE',
        MOREOREQUAL: 'MORE OR EQUAL'
    };

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpErrorResponse, HttpResponse} from '@angular/common/http';
import {IMessageMute, ISgiHttpResponse, OptionsRequestType} from '@app/core/interfaces';
import {BasicRestService} from '@app/core/services/basic-rest.service';
import {MESSAGES_ERREURS} from '@app/core/erreurs/messages-erreurs';
import {RequestUtilities} from '@app/core/utilities/request-utilities';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {Autowired} from '@angular-ru/autowired';

/**
 * Implémentation des methodes REST de base (GET, POST, PUT, DELETE) pour la méthode SGI.
 */
@Injectable({providedIn: 'root'})
export class SgiRestService extends BasicRestService {
    @Autowired() securityService: SecurityStateService;

    /**
     * Afficher message d'erreur
     *
     * @param errorMute
     * @param error1
     */
    protected afficherMessageErreur(errorMute: boolean, error1: ISgiHttpResponse | any) {
        let shower: any;
        const showDefault = () => {
            shower = this.alertService.showError(MESSAGES_ERREURS['error.http.500']);
        };
        try {
            if (error1) {
                shower = this.alertService.showError(error1.error.message || error1.error || error1.message);
            } else {
                showDefault();
            }
        } catch (e) {
            showDefault();
        }
        if (!errorMute) {
            shower.show();
        }
    }

    /**
     * Requête DELETE
     *
     * @param url
     * @param args
     */
    deleteRequest(url: string, args: any | OptionsRequestType | IMessageMute = SgiRestService.deleleteArgs): any | Observable<any> {
        return this.httpClient.delete(url, {observe: 'response', headers: args.headers || null, params: args.params || null}).pipe(
            tap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status === 401) {
                    this.redirection401();
                }
            }, (err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.redirection401();
                }
            }),

            map((reponse: HttpResponse<any>) => {
                return RequestUtilities.ajusteSgiResponse(reponse);
            }),
            tap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status !== 200 || (reponse.body.hasError && !!!reponse.body.items)) {
                    this.afficherMessageErreur(args.errorMute, reponse);
                } else {
                    this.afficherMessageSucces(args.successMute);
                }
            }, err => {
                this.afficherMessageErreur(args.errorMute, err);
            })
        );
    }

    /**
     * Requête GET
     *
     * @param url
     * @param args
     */
    getRequest(url: string, args: any | OptionsRequestType | IMessageMute = SgiRestService.getArgs): any | Observable<any> {
        return this.httpClient.get(url, {observe: 'response', headers: args.headers || null, params: args.params || null}).pipe(
            tap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status === 401) {
                    this.redirection401();
                }
            }, (err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.redirection401();
                }
            }),
            map((reponse: HttpResponse<any>) => {
                return RequestUtilities.ajusteSgiResponse(reponse);
            }),
            tap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status !== 200 || (reponse.body.hasError && !!!reponse.body.items)) {
                    this.afficherMessageErreur(args.errorMute, reponse);
                } else {
                    this.afficherMessageSucces(args.successMute);
                }
            }, err => {
                this.afficherMessageErreur(args.errorMute, err);
            })
        );
    }

    /**
     * Requête POST
     *
     * @param url
     * @param datatap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status !== 200 || (reponse.body.hasError && !!!reponse.body.items)) {
                    this.afficherMessageErreur(args.errorMute, reponse);
                } else {
                    this.afficherMessageSucces(args.successMute);
                }
            }, err => {
                this.afficherMessageErreur(args.errorMute, err);
            })
     * @param args
     */
    postRequest(url: string, data: any, args: any | OptionsRequestType | IMessageMute = SgiRestService.postArgs): any | Observable<any> {
        return this.httpClient.post(url, data, {observe: 'response', headers: args.headers || null, params: args.params || null}).pipe(
            tap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status === 401) {
                    this.redirection401();
                }
            }, (err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.redirection401();
                }
            }),

            map((reponse: HttpResponse<any>) => {
                return RequestUtilities.ajusteSgiResponse(reponse);
            }),
            tap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status !== 200 || (reponse.body.hasError && !!!reponse.body.items)) {
                    this.afficherMessageErreur(args.errorMute, reponse);
                } else {
                    this.afficherMessageSucces(args.successMute);
                }
            }, err => {
                this.afficherMessageErreur(args.errorMute, err);
            })
        );
    }

    /**
     * Requête PUT
     *
     * @param url
     * @param data
     * @param args
     */
    putRequest(url: string, data: any, args: any | OptionsRequestType | IMessageMute = SgiRestService.putArgs): any | Observable<any> {
        return this.httpClient.put(url, data, {observe: 'response', headers: args.headers || null, params: args.params || null}).pipe(
            tap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status === 401) {
                    this.redirection401();
                }
            }, (err: HttpErrorResponse) => {
                if (err.status === 401) {
                    this.redirection401();
                }
            }),

            map((reponse: HttpResponse<any>) => {
                return RequestUtilities.ajusteSgiResponse(reponse);
            }),
            tap((reponse: HttpResponse<ISgiHttpResponse>) => {
                if (reponse.status !== 200 || (reponse.body.hasError && !!!reponse.body.items)) {
                    this.afficherMessageErreur(args.errorMute, reponse);
                } else {
                    this.afficherMessageSucces(args.successMute);
                }
            }, err => {
                this.afficherMessageErreur(args.errorMute, err);
            })
        );
    }

    redirection401() {
        this.securityService.deconnexionClient();
    }
}

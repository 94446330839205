import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {UtilisateurPasswordFullFormComponent} from './utilisateur-password.full-form.component';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule
    ],
    exports: [UtilisateurPasswordFullFormComponent],
    declarations: [UtilisateurPasswordFullFormComponent],
    providers: [],
})
export class UtilisateurPasswordFullFormModule {
}

import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {DemandePriseChargeRestApiService} from './demande-prise-charge.rest-api.service';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier, IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class DemandePriseChargeMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier, IRecuperationListeDonneesMetier {

    @Autowired() datePipe: DatePipe;
    @Autowired() formBuilder: FormBuilder;
    @Autowired() restApi: DemandePriseChargeRestApiService;

    medecins: any = [];
    actes: any = [];
    actesHospitalisation: any = [];
    affections: any = [];

    convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
        const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
        v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
        return v;
    }

    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
        const dateNow = this.datePipe.transform(Date.now(), 'dd/MM/yyyy');

        const formGroup: FormGroup = new FormGroup({});
        formGroup.setControl('dateDebut', new FormControl(this.restApi.dateSave.dateDebutDemande));
        formGroup.setControl('dateFin', new FormControl(this.restApi.dateSave.dateFinDemande));
        formGroup.setControl('dateNow', new FormControl(dateNow));
        return formGroup;
    }

    initialiserFormulaireDemandePEC<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            Matricule: !!datas && !!datas.matricule ? datas.matricule : null,
            Nom: !!datas && !!datas.nom ? datas.nom : null,
            Prenoms: !!datas && !!datas.prenoms ? datas.prenoms : null,
            Genre: !!datas && !!datas.sexe ? datas.sexe : null,
            medecin: !!datas && !!datas.medecin ? datas.medecin : null,
            numeroBon: !!datas && !!datas.numeroBon ? datas.numeroBon : null,
            tauxCouverture: !!datas && !!datas.tauxCouverture ? `${datas.tauxCouverture} %` : 0 + ' %',
        };

        return this.formBuilder.group({
            Matricule: data.Matricule,
            Nom: [{value: data.Nom, disabled: true}],
            Prenoms: [{value: data.Prenoms, disabled: true}],
            Genre: [{value: data.Genre, disabled: true}],
            medecin: data.medecin,
            numeroBon: data.numeroBon,
            tauxCouverture: [{value: data.tauxCouverture, disabled: true}],
        });
    }

    initialiserFormulaireDescripActe<T>(datas: T | any, args?: any): FormGroup {
        const formGroup: FormGroup = new FormGroup({});
        formGroup.setControl('descriptionsAll', new FormControl(null));
        return formGroup;
    }

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteRecuperationListeDonnees(data, args);
    }

    /*initialiserFormulaireActeSelected<T>(datas: T | any, args?: any): FormGroup {
      let disableInput: boolean;
      console.log(datas, args);

      const data: any = {
        libelleActe: !!datas && !!datas.libelleActe ? datas.libelleActe : null,
        montantActe: !!datas && !!datas.montantActe ? datas.montantActe : null,
        quantiteActe: !!datas && !!datas.quantiteActe ? datas.quantiteActe : null,
      };

      if (!!args && args === true) {
        disableInput = false;
        console.log(disableInput);

        return this.formBuilder.group({
          libelleActe: data.libelleActe,
          montantActe: [{value: data.montantActe, disabled: true}, Validators.required],
          quantiteActe: [{value: data.quantiteActe, disabled: disableInput}, Validators.required],
        });

      } else {
        disableInput = true;
        console.log(disableInput);

        return this.formBuilder.group({
          libelleActe: data.libelleActe,
          montantActe: [{value: data.montantActe, disabled: true}, Validators.required],
          quantiteActe: [{value: data.quantiteActe, disabled: disableInput}, Validators.required],
        });
      }

      /!*const formGroup: FormGroup = new FormGroup({});
      formGroup.setControl('libelleActe', new FormControl(data.libelleActe));
      formGroup.setControl('montantActe', new FormControl({value: data.montantActe, disabled: true}, Validators.required));
      formGroup.setControl('quantiteActe', new FormControl({value: data.quantiteActe, disabled: disableInput}, Validators.required));
      return formGroup;*!/
    }*/

    initialiserFormulaireActeSelected<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            libelleActe: !!datas && !!datas.libelleActe ? datas.libelleActe : null,
            montantActe: !!datas && !!datas.montantActe ? datas.montantActe : null,
            quantiteActe: !!datas && !!datas.quantiteActe ? datas.quantiteActe : null,
        };

        return this.formBuilder.group({
            libelleActe: data.libelleActe,
            montantActe: [{value: data.montantActe, disabled: true}, Validators.required],
            quantiteActe: [{value: data.quantiteActe, disabled: true}, Validators.required],
        });
    }

    initialiserFormulaireActeSelectedQuantite<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            libelleActe: !!datas && !!datas.libelleActe ? datas.libelleActe : null,
            montantActe: !!datas && !!datas.montantActe ? datas.montantActe : null,
            quantiteActe: !!datas && !!datas.quantiteActe ? datas.quantiteActe : null,
        };

        return this.formBuilder.group({
            libelleActe: data.libelleActe,
            montantActe: [{value: data.montantActe, disabled: true}, Validators.required],
            quantiteActe: [{value: data.quantiteActe, disabled: false}, Validators.required],
        });
    }

    initialiserFormulaireAffectionSelected<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            codeAffection: !!datas && !!datas.codeAffection ? datas.codeAffection : null,
        };

        return this.formBuilder.group({
            codeAffection: [data.codeAffection],
        });
    }

    initialiserFormulaireDocument<T>(datas: T | any, args?: any): FormGroup {
        const formGroup: FormGroup = new FormGroup({});
        formGroup.setControl('type', new FormControl(null));
        formGroup.setControl('titre', new FormControl(null));
        formGroup.setControl('fichier', new FormControl(null));
        return formGroup;
    }

    initialiserFormulaireActeDemandeHospitalisation<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            libelleActe: !!datas && !!datas.libelleActe ? datas.libelleActe : null,
            montantActe: !!datas && !!datas.montantActe ? datas.montantActe : null,
            quantiteActe: !!datas && !!datas.quantiteActe ? datas.quantiteActe : null,
        };

        return this.formBuilder.group({
            libelleActe: data.libelleActe,
            montantActe: [{value: data.montantActe, disabled: false}, Validators.required],
            quantiteActe: [{value: data.quantiteActe, disabled: false}, Validators.required],
        });
    }

    recupererOptions(args?: any): Observable<Array<any>> {
        return this.restApi.requeteRecuperationListeDonnees(null, args)
            .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
                if (!(reponse.body.items && reponse.body.items.length > 0)) {
                    return reponse;
                }
                return reponse.body.items;
            }));
    }

    /**
     * requête de récupération des médecins pour storage en local
     * @param data
     */
    rechercherMedecin(data?: any) {
        return this.restApi.recupererMedecin(data);
    }

    /**
     * requête de récupération des actes pour storage en local
     * @param data
     */
    recupererActes(data?: any) {
        return this.restApi.recupererActes(data);
    }

    /**
     * requête de récupération des actes pour storage en local
     * @param data
     */
    recupererActesHospitalisation(data?: any) {
        return this.restApi.recupererActesHospitalisation(data);
    }

    /**
     * requête de récupération des affections pour storage en local
     * @param data
     */
    recupererAffections(data?: any) {
        return this.restApi.recupererAffections(data);
    }

    /**
     * requête de récupération d'un assuré par son matricule
     * @param data
     */
    rechercherAssureParMatricule(data: any) {
        return this.restApi.rechercherAssureParMatricule(data);
    }

    /**
     * requête de vérification, si un prestataire a le droit de faire une nouvelle demande PEC
     * @param data
     */
    verificationNouvelleDemandePrestataire(data: any) {
        return this.restApi.requeteVerificationNouvelleDemande(data);
    }

    /**
     * requête de récupération des tyoes de documents pour une nouvelle demande PEC
     * @param data
     */
    recupererTypeDocumentDemandePEC(data?: any) {
        return this.restApi.requeteRecupererTypeDocumentDemandePEC(data);
    }

    /**
     * requête d'enregistrement d'une nouvelle demande
     * @param data
     * @param agrs
     */
    requeteSaveDonneesFormulaireNouvelleDemande(data: any, agrs: any) {
        return this.restApi.requeteSaveDonneesFormulaireNouvelleDemande(data, agrs);
    }

    /**
     * requête de recupération du document
     * @param data
     * @param agrs
     */
    requetePrintDocument(data: any, agrs: any) {
        return this.restApi.requetePrintDocument(data, agrs);
    }

    /**
     * requête de recupération du bon vide
     * @param data
     * @param agrs
     */
    requetePrintBonVide(data: any, agrs: any) {
        return this.restApi.requetePrintBonVide(data, agrs);
    }

    /**
     * requête de récupération de la liste de garant
     * @param data
     * @param agrs
     */
    requeteRecuperationListGarant(data: any, agrs: any) {
        return this.restApi.requeteRecuperationListGarant(data, agrs);
    }

    /**
     * requête de vérification possibilté de faire une clôture
     * @param data
     * @param agrs
     */
    requeteVerificationFaireAction(data: any, agrs: any) {
        return this.restApi.requeteVerificationFaireAction(data, agrs);
    }

    /**
     * requête de récupération des actes assuré clôture
     * @param data
     * @param agrs
     */
    requeteRecuperationActesCloture(data: any, agrs: any) {
        return this.restApi.requeteRecuperationActesCloture(data, agrs);
    }

    /**
     * requête d'enregistrement d'une clôture demande
     * @param data
     * @param agrs
     */
    requeteSaveDonneesFormulaireClotureDemande(data: any, agrs: any) {
        return this.restApi.requeteSaveDonneesFormulaireClotureDemande(data, agrs);
    }

    /**
     * requête de récupération des données de l'adhérant pour une hospitalisation
     * @param data
     * @param agrs
     */
    requeteRecuperationAdherantHospitalisation(data: any, agrs: any) {
        return this.restApi.requeteRecuperationAdherantHospitalisation(data, agrs);
    }

    /**
     * requête de récupération des actes pour le soins hospitalisation
     * @param data
     * @param agrs
     */
    requeteRecuperationSoinsHospitalisation(data: any, agrs: any) {
        return this.restApi.requeteRecuperationSoinsHospitalisation(data, agrs);
    }

    /**
     * requête d'enregistrement d'une hospitalisation
     * @param data
     * @param agrs
     */
    requeteSaveDonneesFormulaireHospitalisationDemande(data: any, agrs: any) {
        return this.restApi.requeteSaveDonneesFormulaireHospitalisationDemande(data, agrs);
    }

    /**
     * requête d'enregistrement d'une hospitalisation
     * @param data
     * @param agrs
     */
    requeteRecuperationActesAnalyse(data: any, agrs: any) {
        return this.restApi.requeteRecuperationActesAnalyse(data, agrs);
    }

}

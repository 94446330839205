import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PoursuitePecFormComponent} from './poursuite-pec-form/poursuite-pec-form.component';
import {PoursuitePriseChargeListeFeatureModule} from './liste/poursuite-prise-charge-liste.feature.module';

@NgModule({
  imports: [
      CommonModule,
      SharedModule,
      PoursuitePriseChargeListeFeatureModule,
  ],
  declarations: [PoursuitePecFormComponent],
  exports: [PoursuitePecFormComponent]
})
export class PoursuitePriseChargeFeatureModule {
}

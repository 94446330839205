import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {InformationFactureMetierService} from './information-facture.metier.service';
import {FormBuilder} from '@angular/forms';
import {Observable} from 'rxjs';

@Component({
    selector: 'app-information-facture',
    templateUrl: './information-facture.component.html'
})
export class InformationFactureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: InformationFactureMetierService;
    @Autowired() recuperationListeDonneesMetier: InformationFactureMetierService;
    @Autowired() metierService: InformationFactureMetierService;

    @Autowired() formBuilder: FormBuilder;
    @Input() source;
    @Input() sourceOpened = '';

    @Output() observation = new EventEmitter<any[]>();

    constructor() {
        super();
    }

    ngOnInit() {
        super.ngOnInit();
        this.initialiserFormulaire();
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
        };
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaireInformation(this.source);
    }

    addObservation() {
        this.observation.emit(this.formGroup.getRawValue().descriptions);
    }

}

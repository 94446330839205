import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../shared/shared.module';
import {BarchartFeatureComponent} from './barchart/barchart.feature.component';
import {LinechartFeatureComponent} from './linechart/linechart.feature.component';
import {NgxChartsModule} from '@swimlane/ngx-charts';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxChartsModule
  ],
  declarations: [BarchartFeatureComponent, LinechartFeatureComponent],
  exports: [BarchartFeatureComponent, LinechartFeatureComponent ]
})
export class NgxchartsFeatureModule {
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import { ModaleModifierBordereauComponent } from './modale-modifier-bordereau.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
    ],
    exports: [
        ModaleModifierBordereauComponent,
    ],
    declarations: [
        ModaleModifierBordereauComponent,
    ],
  providers: [],
})
export class ModaleModifierBordereauModule {
}

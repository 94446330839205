import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AtlantisAutocompleteComponent} from '@shared/behaviors/atlantis-extend-clv/atlantis-autocomplete/atlantis-autocomplete.component';
import {FormAutocompleteComponentModule} from '@shared/components/inputs/form-autocomplete/form-autocomplete.component.module';

@NgModule({
    imports: [
        CommonModule,
        FormAutocompleteComponentModule,
    ],
    declarations: [
        AtlantisAutocompleteComponent,
    ],
    exports: [
        AtlantisAutocompleteComponent,
    ]
})
export class AtlantisAutocompleteComponentModule {
}

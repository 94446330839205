import {NgModule} from '@angular/core';

import {InformationDossierTableComponent} from './information-dossier-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports: [InformationDossierTableComponent],
  declarations: [InformationDossierTableComponent],
  providers: [],
})
export class InformationDossierTableModule {
}

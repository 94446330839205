import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DatePipe} from '@angular/common';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
  API_RECUPERER_MALADIE_CHRONIQUE_DOSSIER_MEDICAL_ENCOURS,
} from '@app/core/constantes';


@Injectable({providedIn: 'root'})
export class MaladieChroniqueTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;
  @Autowired() datePipe: DatePipe;

  storageUser: IUserConnect = this.getUser();

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {

    let constructorParamRequest: any;

    if (args.typeAffichage === 'liste') {
      constructorParamRequest = {
        IdSouscriptionLigne: args.currentAssure.idSouscriptionLigne,
        InfoSearchAdresseGeographique: {Consider: false},
        InfoSearchDateCreation: {Consider: false},
        InfoSearchDatePrestation: {Consider: false},
        InfoSearchDateRealisation: {Consider: false},
        InfoSearchEmail: {Consider: false},
        InfoSearchFax: {Consider: false},
        InfoSearchIdBonPriseEnCharge: {Consider: false},
        InfoSearchIdMedicament: {Consider: false},
        InfoSearchIdMedicamentOrigine: {Consider: false},
        InfoSearchIdPoolMedicalMedecin: {Consider: false},
        InfoSearchIdPoolMedicalPharmacien: {Consider: false},
        InfoSearchIdPrestataireMedical: {Consider: false},
        InfoSearchIdPrestation: {Consider: false},
        InfoSearchIdPrestationAffection: {Consider: false},
        InfoSearchIdRefPrestataireMedical: {Consider: false},
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchIdTenant: {Consider: false},
        InfoSearchIsDeleted: {Consider: false},
        InfoSearchLibelle: {Consider: false},
        InfoSearchLibellePays: {Consider: false},
        InfoSearchLibelleTypeprestataire: {Consider: false},
        InfoSearchLibelleVille: {Consider: false},
        InfoSearchNomOrigine: {Consider: false},
        InfoSearchPosologie: {Consider: false},
        InfoSearchQuantite: {Consider: false},
        InfoSearchQuantiteRealise: {Consider: false},
        InfoSearchRaisonSociale: {Consider: false},
        InfoSearchRealise: {Consider: false},
        InfoSearchStatut: {Consider: true},
        InfoSearchSubstitue: {Consider: false},
        InfoSearchTauxSinistre: {Consider: false},
        InfoSearchTelephone1: {Consider: false},
        InfoSearchTelephone2: {Consider: false},
        Statut: 'Validé',
      };
    }
    if (args.typeAffichage === 'historique') {
      constructorParamRequest = {
        IdSouscriptionLigne: args.currentAssure.idSouscriptionLigne,
        InfoSearchAdresseGeographique: {Consider: false},
        InfoSearchDateCreation: {Consider: false},
        InfoSearchDatePrestation: {Consider: false},
        InfoSearchDateRealisation: {Consider: false},
        InfoSearchEmail: {Consider: false},
        InfoSearchFax: {Consider: false},
        InfoSearchIdBonPriseEnCharge: {Consider: false},
        InfoSearchIdMedicament: {Consider: false},
        InfoSearchIdMedicamentOrigine: {Consider: false},
        InfoSearchIdPoolMedicalMedecin: {Consider: false},
        InfoSearchIdPoolMedicalPharmacien: {Consider: false},
        InfoSearchIdPrestataireMedical: {Consider: false},
        InfoSearchIdPrestation: {Consider: false},
        InfoSearchIdPrestationAffection: {Consider: false},
        InfoSearchIdRefPrestataireMedical: {Consider: false},
        InfoSearchIdSouscriptionLigne: {Consider: true},
        InfoSearchIdTenant: {Consider: false},
        InfoSearchIsDeleted: {Consider: false},
        InfoSearchLibelle: {Consider: false},
        InfoSearchLibellePays: {Consider: false},
        InfoSearchLibelleTypeprestataire: {Consider: false},
        InfoSearchLibelleVille: {Consider: false},
        InfoSearchNomOrigine: {Consider: false},
        InfoSearchPosologie: {Consider: false},
        InfoSearchQuantite: {Consider: false},
        InfoSearchQuantiteRealise: {Consider: false},
        InfoSearchRaisonSociale: {Consider: false},
        InfoSearchRealise: {Consider: false},
        InfoSearchStatut: {consider: true, operatorToUse: 'NOT EQUAL', Consider: true},
        InfoSearchSubstitue: {Consider: false},
        InfoSearchTauxSinistre: {Consider: false},
        InfoSearchTelephone1: {Consider: false},
        InfoSearchTelephone2: {Consider: false},
        Statut: 'Validé',
      };
    }

    /*let constructorParamRequest: any;

    if (args.formGroup.value.selectTypeSearch === '' || args.formGroup.value.selectTypeSearch === null) {
      constructorParamRequest = {
      };
    }
    if (args.formGroup.value.selectTypeSearch === 'affection') {
      constructorParamRequest = {
      };
    }
    if (args.formGroup.value.selectTypeSearch === 'type_affection') {
      constructorParamRequest = {
      };
    }*/
    // update du paramètre
    data.SortOrder = null;

    const sendData = {
      ...data,
      itemToSearch: {
        ...data.itemToSearch,
        ...constructorParamRequest,
      }
    };

    // tslint:disable-next-line:max-line-length
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_MALADIE_CHRONIQUE_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
  }

}

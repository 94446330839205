import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {TranslateService} from '@ngx-translate/core';
import {LanguageStoreService} from '@app/core/states/language/language.store.service';
import {LanguageQueryService} from '@app/core/states/language/language.query.service';

/**
 * Service d'état de la langue active
 */
@Injectable({providedIn: 'root'})
export class LanguageStateService {
  @Autowired() store: LanguageStoreService;
  @Autowired() query: LanguageQueryService;
  @Autowired() translate: TranslateService;

  /**
   * Définir la langue active
   *
   * @param lang
   */
  setLanguage(lang: string) {
    window.localStorage.setItem('language', lang);
    this.translate.setDefaultLang(lang);
    this.store.update({lang: lang});
  }

  /**
   * Recuperer la langue active
   */
  getLanguage() {
    return this.query.__store__._value().lang;
  }

  /**
   * Initialise l'état
   */
  init(defaultLang: any) {
    try {
      let language = window.localStorage.getItem('language');
      if (!language) {
        language = defaultLang;
      }
      this.setLanguage(language);
    } catch (e) {
    }
  }
}

import {
    STATUT_CLOTURE,
    STATUT_EN_ACCORD_PREALABLE,
    STATUT_EN_ATTENTE_DE_CLOTURE,
    STATUT_EN_VALIDATION_AVIS_HOSPI,
    STATUT_REJETE
} from '@app/core/constantes/constantes-communes';

export interface SelectionCombo {
  label: string;
  value: any;
}

export const GENRES: SelectionCombo[] = [
  {label: 'Masculin', value: 'MASCULIN'},
  {label: 'Feminin', value: 'FEMININ'}
];

export const CIVILITES: SelectionCombo[] = [
  {label: 'Madame', value: 'MADAME'},
  {label: 'Mademoiselle', value: 'MADEMOISELLE'},
  {label: 'Monsieur', value: 'MONSIEUR'}
];

export const SENS_COMBO: SelectionCombo[] = [
  {label: 'Débit', value: 'DEBIT'},
  {label: 'Crédit', value: 'CREDIT'}
];

export const SENS_LIGNE_PLACEMENT_COMBO: SelectionCombo[] = [
  {label: 'Dépot', value: 'DEPOT'},
  {label: 'Retrait', value: 'RETRAIT'},
  {label: 'Achat', value: 'ACHAT'},
  {label: 'Rachat', value: 'RACHAT'},
  {label: 'Souscription', value: 'SOUSCRIPTION'},
  {label: 'Vente', value: 'VENTE'},
];

export const SENS_LIGNE_PLACEMENT_OPCVM_COMBO: SelectionCombo[] = [
  {label: 'Rachat', value: 'RACHAT'},
  {label: 'Souscription', value: 'SOUSCRIPTION'}
];

export const SENS_LIGNE_PLACEMENT_TITRE_CREANCE_COMBO: SelectionCombo[] = [
  {label: 'Vente', value: 'VENTE'},
  {label: 'Achat', value: 'ACHAT'},
];

export const SENS_LIGNE_PLACEMENT_DAT_COMBO: SelectionCombo[] = [
  {label: 'Dépot', value: 'DEPOT'},
  {label: 'Retrait', value: 'RETRAIT'},
];


export const TYPE_PENALITE_COMBO: SelectionCombo[] = [
  {label: 'Madame', value: 'MADAME'},
  {label: 'Mademoiselle', value: 'MADEMOISELLE'},
  {label: 'Monsieur', value: 'MONSIEUR'}
];

export const FORME_JURIDIQUES: SelectionCombo[] = [
  {label: 'SARL', value: 'SARL'},
  {label: 'SA', value: 'SA'},
  {label: 'SELARL', value: 'SELARL'},
  {label: 'EI', value: 'EI'},
  {label: 'EIRL', value: 'EIRL'},
  {label: 'SNC', value: 'SNC'},
  {label: 'SASU', value: 'SASU'},
  {label: 'SCI', value: 'SCI'},
  {label: 'SCP', value: 'SCP'},
  {label: 'SCM', value: 'SCM'}
];

export const SWAP_TYPE: SelectionCombo[] = [
  {label: 'Devise', value: 'DEVISE'},
  {label: 'Taux', value: 'TAUX'}
];

export const AGENCE_NOTATION_TYPES: SelectionCombo[] = [
  {label: 'Cedant', value: 'CEDANT'},
  {label: 'Société de gestion', value: 'SOCIETE_GESTION'},
  {label: 'Dépositaire', value: 'DEPOSITAIRE'},
  {label: 'Banque règlement', value: 'BANQUE_REGLEMENT'},
  {label: 'Commissaire au comptes', value: 'COMMISSAIRE_COMPTES'},
  {label: 'Agence notation', value: 'AGENCE_NOTATION'},
  {label: 'Arrangeur', value: 'ARRANGEUR'},
  {label: 'Régulateur', value: 'REGULATEUR'},
  {label: 'SGI (Société de Gestion et d\'Intermédiation)', value: 'SGI'},
  {label: 'Autres', value: 'AUTRE'},
];

export const TYPE_COLLECTE_FOND: SelectionCombo[] = [
  {label: 'APE', value: 'APE'},
  {label: 'PP', value: 'PP'}
];

export const PERIODICITE_COMBO: SelectionCombo[] = [
  {label: 'Journalier', value: 'JOURNALIER'},
  {label: 'Hebdomadaire', value: 'HEBDOMADAIRE'},
  {label: 'Mensuelle', value: 'MENSUEL'},
  {label: 'Trimestrielle', value: 'TRIMESTRIELLE'},
  {label: 'Semestrielle', value: 'SEMETRIELLE'},
  {label: 'Annuelle', value: 'ANNUEL'}
];

export const TYPE_TAUX_COMBO: SelectionCombo[] = [
  {label: 'Revisable', value: 'REVISABLE'},
  {label: 'Atypique', value: 'ATYPIQUE'},
  {label: 'Fixe', value: 'FIXE'},
];

export const COMPTE_TYPE_COMBO: SelectionCombo[] = [
  {label: 'Réserve', value: 'RESERVE'},
  {label: 'Général', value: 'GENERAL'},
  {label: 'Autres', value: 'AUTRE'},
];
export const TYPE_AMMORTISSEMENT_COMBO: SelectionCombo[] = [
  {label: 'Constant sur titre', value: 'CONSTANT_SUR_TITRE'},
  {label: 'Constant sur capital', value: 'CONSTANT_SUR_CAPITAL'},
  {label: 'Constant sur annuité', value: 'CONSTANT_SUR_ANNUITE'}
];

export const TYPE_AMMORTISSEMENT_TITRE_CREANCE_COMBO: SelectionCombo[] = [
  {label: 'Linéaire', value: 'LINEAIRE'},
  {label: 'In fine', value: 'IN_FINE'}
];


export const BOOLEAN_TYPE_COMBO: SelectionCombo[] = [
  {label: 'Oui', value: true},
  {label: 'Non', value: false}
];
export const TYPE_TITRE_FONDS_CREANCE_PART_RESIDUELLE_COMBO: SelectionCombo[] = [
  {label: 'Titre créance négociable', value: 'tcn'},
  {label: 'Part résiduelle', value: 'partResiduelle'}
];

export const TYPE_INVESSTISSEUR_COMBO: SelectionCombo[] = [
  {label: 'Investisseur physique', value: 'physique'},
  {label: 'Investisseur moral', value: 'moral'}
];

export const RATING_COMBO: SelectionCombo[] = [
  {label: 'Senior', value: 'SENIOR'},
  {label: 'Mezzanine', value: 'MEZZANINE'},
  {label: 'Junior', value: 'JUNIOR'}
];

export const COMPLEXITE_MOT_DE_PASSE_COMBO: SelectionCombo[] = [
  {label: 'Libre', value: true},
  {label: 'Alphanumérique', value: false},
  {label: 'Alphanumérique avec Lettres Majuscules', value: false},
  {label: 'Alphanumérique avec Caractères Spéciaux', value: false},
  {label: 'Alphanumérique avec Lettres Majuscules et Caractères Spéciaux', value: false},
  {label: 'Alphanumérique avec Lettres Majuscules et Caractères Spéciaux', value: false}
];

export const TYPE_PRIME_RISQUE_COMBO: SelectionCombo[] = [
  {label: 'Fixe', value: 'FIXE'},
  {label: 'Variable', value: 'VARIABLE'}
];

export const TYPE_PERSONNE_COMBO: Array<SelectionCombo> = [
  {value: 'PERSONNE_PHYSIQUE', label: 'Personne physique'},
  {value: 'PERSONNE_MORALE', label: 'Personne morale'},
];

export const TYPE_RESIDENT_COMBO: Array<SelectionCombo> = [
  {value: 'RESIDENT_IVOIRIEN', label: 'Résident ivoirien'},
  {value: 'RESIDENT_NON_IVOIRIEN', label: 'Résident non ivoirien'},
  {value: 'ETRANGE_NON_RESIDENT', label: 'Etrangé non résident'},
  {value: 'IVOIRIEN_NON_RESIDENT', label: 'Ivoirien non résident'},
];
export const TYPE_PIECE_COMBO: Array<SelectionCombo> = [
  {value: 'CNI', label: 'CNI'},
  {value: 'ATTESTATION_IDENTITE', label: 'attestation d\'identité'},
  {value: 'PERMIS_DE_CONDUIRE', label: 'Permis de conduire'},
  {value: 'PASSPORT', label: 'Passport'},
  {value: 'AUTRE', label: 'Autres'},
];
export const CATEGORIE_OBLIGATION_COMBO: Array<SelectionCombo> = [
  {value: 'BOND_TRESOR', label: 'Bon trésor'},
  {value: 'OBLIGATION', label: 'Obligation'},
  {value: 'TITRE_CREANCE_NEGOCIABLE', label: 'Titre créance négociable'},
  {value: 'BON_SOCIETE_FINANCIERE', label: 'Bon société financière'},
];
export const CATEGORIE_OPCVM_COMBO: Array<SelectionCombo> = [
  {value: 'MONETAIRE', label: 'Monétaire'},
  {value: 'ACTION', label: 'Action'},
  {value: 'OBLIGATOIRE', label: 'Obligataire'},
];

export const MODE_CALCUL_COMBO: SelectionCombo[] = [
  {label: 'Pourcentage', value: 'POURCENTAGE'},
  {label: 'Montant', value: 'MONTANT'}
];

export const BASE_ANNUALISATION_COMBO: SelectionCombo[] = [
  {label: 'Par défaut', value: 'PAR_DEFAUT'},
  {label: 'Nombre de jours exact', value: 'NOMBRE_DE_JOUR_EXACT'}
];

export const TYPE_DOCUMENT_COMBO: SelectionCombo[] = [
  {label: 'Cession', value: 'CESSION'},
  {label: 'Rachat', value: 'RACHAT'}
];

export const TYPE_PAIEMENT_COMBO: SelectionCombo[] = [
  {label: 'Chèque', value: 'CHEQUE'},
  {label: 'Espèce', value: 'ESPECE'},
  {label: 'Virement', value: 'VIREMENT'}
];

export const EN_COURS_BASE_COMBO: SelectionCombo[] = [
  {label: 'CRD créance', value: 'CRD_CREANCE'},
  {label: 'Déclaratif', value: 'DECLARATIF'}
];

export const SITUATION_MATRIMONIALE_COMBO: SelectionCombo[] = [
  {label: 'Marié(e)', value: 'MARIEE'},
  {label: 'Célibataire', value: 'CELIBATAIRE'},
];

export const PERIODE_WATERFALL_COMBO: SelectionCombo[] = [
  {label: 'Accélérée', value: 'ACCELERE'},
  {label: 'Anticipée', value: 'ANTICIPE'},
  {label: 'Normale', value: 'NORMAL'}
];

export const PERIODE_TAUX_COMBO: SelectionCombo[] = [
  {label: 'Date debut', value: 'DATE_DEBUT'},
  {label: 'Date fin', value: 'DATE_FIN'},
  {label: 'Taux facial', value: 'TAUX_FACIAL'},
  {label: 'Prime de risque', value: 'PRIME_DE_RISQUE'}
];

export const OPERATEUR_CONDITIONNEL_COMBO: SelectionCombo[] = [
  {label: '>', value: 'SUPERIEUR'},
  {label: '<', value: 'INFERIEUR'},
  {label: '>=', value: 'SUPERIEUR_OU_EGAL'},
  {label: '<=', value: 'INFERIEUR_OU_EGAL'},
  {label: '=', value: 'EGAL'},
  {label: '!=', value: 'DIFFERENT'},
];
export const OPERATEUR_CONDITIONNEL_STRING_BOOLEAN_COMBO: SelectionCombo[] = [
  {label: '=', value: 'EGAL'},
  {label: '!=', value: 'DIFFERENT'},
];

export const TYPE_CONDITION_CRITERE_COMBO: SelectionCombo[] = [
  {label: 'Numérique', value: 'NUMERIQUE'},
  {label: 'Date', value: 'DATE'},
  {label: 'Booléen', value: 'BOOLEAN'},
  {label: 'Caractère', value: 'CARACTERE'},
];

export const TYPE_COLONNE_TEMPLATE_COMBO: SelectionCombo[] = [
  {label: 'Numérique', value: 'NUMERIQUE'},
  {label: 'Date', value: 'DATE'},
  {label: 'Booléen', value: 'BOOLEAN'},
  {label: 'Caractère', value: 'CARACTERE'},
];

export const AGENCE_NOTATION_TYPES_LISTE = ['CEDANT', 'SOCIETE_GESTION', 'DEPOSITAIRE',
  'INVESTISSEUR', 'BANQUE_REGLEMENT', 'COMMISSAIRE_COMPTES', 'AGENCE_NOTATION', 'ARRANGEUR'];

export const MODE_CALCUL_TYPE_LISTE = ['MONTANT', 'POURCENTAGE'];

/** ADD */
export const TYPE_LIBELLE_STATUS_COMBO: SelectionCombo[] = [
  {label: 'En accord préalable', value: STATUT_EN_ACCORD_PREALABLE},
  {label: 'En attente de clôture', value: STATUT_EN_ATTENTE_DE_CLOTURE},
  {label: 'Rejete', value: STATUT_REJETE},
  {label: 'Clôturé', value: STATUT_CLOTURE},
  {label: 'En validation de l\'avis d\'hospit.', value: STATUT_EN_VALIDATION_AVIS_HOSPI},
];

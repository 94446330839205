import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {DatePipe} from '@angular/common';
import { InformationFactureRestApiService } from './information-facture.rest-api.service';

@Injectable({providedIn: 'root'})
  export class InformationFactureMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier {

  @Autowired() datePipe: DatePipe;
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: InformationFactureRestApiService;

  medecins: any = [];
  actes: any = [];
  affections: any = [];

  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
    v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
    return v;
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const dateNow = this.datePipe.transform(Date.now(), 'dd/MM/yyyy');

    const formGroup: FormGroup = new FormGroup({});
    formGroup.setControl('dateDebut', new FormControl(this.restApi.dateSave.dateDebut));
    formGroup.setControl('dateFin', new FormControl(this.restApi.dateSave.dateFin));
    formGroup.setControl('dateNow', new FormControl(dateNow));
    return formGroup;
  }


  initialiserFormulaireInformation<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      prestataireMedical: !!datas && !!datas.prestataireMedical ? datas.prestataireMedical : null,
      numeroBordereau: !!datas && !!datas.numeroBordereau ? datas.numeroBordereau : null,
      numBonPriseEnCharge: !!datas && !!datas.numBonPriseEnCharge ? datas.numBonPriseEnCharge : null,
      matricule: !!datas && !!datas.matricule ? datas.matricule : null,
      nomCompletPersonne: !!datas && !!datas.nomCompletPersonne ? datas.nomCompletPersonne : null,
      tauxSinistre: !!datas && !!datas.tauxSinistre ? datas.tauxSinistre : null,
      datePrestation: !!datas && !!datas.datePrestation ? this.datePipe.transform(datas.datePrestation, 'dd/MM/yyyy') : null,
      montantBrut: !!datas && !!datas.montantBrut ? datas.montantBrut : null,
      partAssuranceBrut: !!datas && !!datas.partAssuranceBrut ? datas.partAssuranceBrut : null,
      ticketModerateur: !!datas && !!datas.ticketModerateur ? datas.ticketModerateur : null,
      partAssurance: !!datas && !!datas.partAssurance ? datas.partAssurance : null,
      descriptions: !!datas && !!datas.descriptions ? datas.descriptions : null,
    };

    return this.formBuilder.group({
      prestataireMedical: [{value: data.prestataireMedical, disabled: true}],
      numeroBordereau: [{value: data.numeroBordereau, disabled: true}],
      numBonPriseEnCharge: [{value: data.numBonPriseEnCharge, disabled: true}],
      matricule: [{value: data.matricule, disabled: true}],
      nomCompletPersonne: [{value: data.nomCompletPersonne, disabled: true}],
      tauxSinistre: [{value: data.tauxSinistre, disabled: true}],
      datePrestation: [{value: data.datePrestation, disabled: true}],
      montantBrut: [{value: data.montantBrut, disabled: true}],
      partAssuranceBrut: [{value: data.partAssuranceBrut, disabled: true}],
      ticketModerateur: [{value: data.ticketModerateur, disabled: true}],
      partAssurance: [{value: data.partAssurance, disabled: true}],
      descriptions: [{value: data.descriptions, disabled: true}],
    });
  }


  recupererOptions(args?: any): Observable<Array<any>> {
    return null;
   /* return this.restApi.requeteRecuperationListeDonnees(null, args)
    .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
      if (!(reponse.body.items && reponse.body.items.length > 0)) {
        return reponse;
      }
      return reponse.body.items;
    })); */
  }

  neutraliserParents(data: any[]) {
    if (!data || !(data.length > 0)) {
      return data;
    }
    data.forEach(d => {
      d.parent = null;
      if (d.children) {
        this.neutraliserParents(d.children);
      }
    });
  }


}

import {NgModule} from '@angular/core';

import {PersonneMedicalTableComponent} from './personne-medical-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../../../../../shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [PersonneMedicalTableComponent],
    declarations: [PersonneMedicalTableComponent],
    providers: [],
})
export class PersonneMedicalTableModule {
}

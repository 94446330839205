import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

@Injectable({providedIn: 'root'})
export class ListAffectionTableListeRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;

  storageUser: IUserConnect = this.getUser();

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    /*const { currentDataUser } = args;

    let urlApi: string;
    if (currentDataUser.libelleTypeStatut === STATUT_CLOTURE) {
      urlApi = API_ACTE_PRESTATION_DETAILS_PRISE_EN_CHARGE;
    } else {
      urlApi = API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE;
    }

    const sendData = {
      ItemToSearch: {
        idDemandePriseEnCharge: currentDataUser.idDemandePriseEnCharge,
        InfoSearchidDemandePriseEnCharge: { Consider: true },
      },
      Size: 5,
      TakeAll: false,
    };

    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${urlApi}`, sendData);*/

    const body = [];
    return of({body: body});
  }
}

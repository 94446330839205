import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-cancel-button',
  template: `
  <button mat-raised-button>
    <mat-icon>{{icon}}</mat-icon>&nbsp;{{title}}
  </button>
  `
})
export class CancelButtonComponent {
  @Input('icon') icon = 'cancel';
  @Input('title') title = 'Annuler';
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '../../../shared/shared.module';
import {ResetPasswordComponent} from './layout/reset-password.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule
  ],
  declarations: [ResetPasswordComponent],
  exports: [ResetPasswordComponent]
})
export class ResetPasswordFeatureModule {
}

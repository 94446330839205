import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeComplementaireAutocompleteComponent} from './acte-complementaire.autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [ActeComplementaireAutocompleteComponent],
  exports: [ActeComplementaireAutocompleteComponent]
})
export class ActeComplementaireAutocompleteFormModule {
}


import {Injectable} from '@angular/core';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';

/**
 * Service de gestion d'écran de veille
 */
@Injectable({providedIn: 'root'})
export class EcranVeilleService {
  /**
   * Gestionnaire de modales. Permets de parametrer l'affichage et la reception de données d'une fenetre modale-subtitution
   */
  modalManager: GestionnaireDeModales = new class implements GestionnaireDeModales {
    datasourceSelected: any = null;
    display = false;
    typeOpenModal: 'update' | 'create' = 'create';
  };

  /**
   * Affiche l'écran de vérrouillage
   */
  verrouiller() {
    this.modalManager.display = true;
  }

  /**
   * Supprime l'écran de vérrouillage
   */
  deverrouiller() {
    this.modalManager.display = false;
  }
}

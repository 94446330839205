import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {InformationAssureRestApiService} from './information-assure.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class InformationAssureMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: InformationAssureRestApiService;
  @Autowired() formBuilder: FormBuilder;
  @Autowired() datePipe: DatePipe;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaireAssure<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      priseEnCharge: !!datas && !!datas.numBonPriseEnCharge ? datas.numBonPriseEnCharge : null,
      matricule: !!datas && !!datas.matricule ? datas.matricule : null,
      nomComplet: !!datas && !!datas.nomAssure ? datas.nomAssure : null,
      dateNaissance: !!datas && !!datas.dateNaissance
          ? this.datePipe.transform(datas.dateNaissance, 'dd/MM/yyyy')
          : null,
      genre: !!datas && !!datas.sexe ? datas.sexe : null,
    };

    return this.formBuilder.group({
      priseEnCharge: [{value: data.priseEnCharge, disabled: true}],
      matricule: [{value: data.matricule, disabled: true}],
      nomComplet: [{value: data.nomComplet, disabled: true}],
      dateNaissance: [{value: data.dateNaissance, disabled: true}],
      genre: [{value: data.genre, disabled: true}],
    });
  }

  initialiserFormulaireAssureSearch<T>(datas: T | any, args?: any): FormGroup {

    const data: any = {
      identifiantSearch: !!datas && !!datas.matricule ? { libelle: datas.matricule} : null,
      matricule: !!datas && !!datas.matricule ? datas.matricule : null,
      nomComplet: !!datas && !!datas.nomAssure ? datas.nomAssure : null,
      dateNaissance: !!datas && !!datas.dateNaissance
          ? this.datePipe.transform(datas.dateNaissance, 'dd/MM/yyyy')
          : null,
      genre: !!datas && !!datas.sexe ? datas.sexe : null,
    };

    return this.formBuilder.group({
      identifiantSearch: data.identifiantSearch,
      matricule: [{value: data.matricule, disabled: true}],
      nomComplet: [{value: data.nomComplet, disabled: true}],
      dateNaissance: [{value: data.dateNaissance, disabled: true}],
      genre: [{value: data.genre, disabled: true}],
    });
  }

}

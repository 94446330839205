import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';

import {UtilisateurProfilFullFormComponent} from './utilisateur-profil.full-form.component';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule
    ],
    exports: [UtilisateurProfilFullFormComponent],
    declarations: [UtilisateurProfilFullFormComponent],
    providers: [],
})
export class UtilisateurProfilFullFormModule {
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionTableModaleFeatureComponent} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-table/modale/prescription-table-modale.feature.component';
import {PersonneMedicalTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-table/composants/personne-medical-table/Personne-medical-table.module';
import {SpecialitesTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-table/composants/specialites-table/specialites-table.module';
import {ContactTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/prestation-dossier-table/prescription-table/composants/contact-table/contact-table.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        PersonneMedicalTableModule,
        SpecialitesTableModule,
        ContactTableModule,
    ],
    exports: [
        PrescriptionTableModaleFeatureComponent,
    ],
    declarations: [
        PrescriptionTableModaleFeatureComponent,
    ],
  providers: [],
})
export class PrescriptionTableModaleFeatureModule {
}

import {Component, ElementRef, HostListener, ViewChild, ViewEncapsulation} from '@angular/core';

const document: any = window.document;

@Component({
  selector: 'app-fullscreen',
  encapsulation: ViewEncapsulation.None,
  template: `
    <button mat-icon-button class="full-screen" matTooltipPosition="below" [matTooltip]="'Agrandir'">
        <mat-icon *ngIf="!toggle" #expand style="font-size: 30px">fullscreen</mat-icon>
        <mat-icon *ngIf="toggle" #compress>fullscreen_exit</mat-icon>
    </button>
  `
})
export class FullScreenComponent {
    toggle = false;
    @ViewChild('expand', {static: false}) private expand: ElementRef;
    @ViewChild('compress', {static: false}) private compress: ElementRef;

    static requestFullscreen(elem) {
        if (FullScreenComponent.requestFullscreen) {
            elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
            elem.webkitRequestFullscreen();
        } else if (elem.mozRequestFullScreen) {
            elem.mozRequestFullScreen();
        } else if (elem.msRequestFullscreen) {
            elem.msRequestFullscreen();
        } else {
            console.log('Fullscreen API is not supported.');
        }
    }

    static exitFullscreen() {
        if (FullScreenComponent.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        } else {
            console.log('Fullscreen API is not supported.');
        }
    }

    @HostListener('click') getFullscreen() {
        if (this.expand) {
            FullScreenComponent.requestFullscreen(document.documentElement);
        }
        if (this.compress) {
            FullScreenComponent.exitFullscreen();
        }
    }

    @HostListener('window:resize') onFullScreenChange() {
        const fullscreenElement = document.fullscreenElement || document.mozFullScreenElement ||
                                document.webkitFullscreenElement || document.msFullscreenElement;
        if (fullscreenElement != null) {
            this.toggle = true;
        } else {
            this.toggle = false;
        }
    }

}

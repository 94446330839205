import {AfterViewInit, ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {Settings} from '@app/app.settings.model';
import {AppSettings} from '@app/app.settings';
import {MenuService} from '@app/theme/components/menu/menu.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [ MenuService ]
})
export class BreadcrumbComponent implements OnInit, AfterViewInit {


  static breadcrumbs2: {
    name: string;
    url: string
  }[] = [];
    public pageTitle: string;
    breadcrumbs: {
        name: string;
        url: string
    }[] = [];

    @Input()
    titre: string;
    public settings: Settings;
    constructor(public appSettings: AppSettings,
                public router: Router,
                public activatedRoute: ActivatedRoute,
                public title: Title,
                private menuService: MenuService) {
            this.settings = this.appSettings.settings;
    }

    ngOnInit(): void {
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.breadcrumbs = [];
          this.parseRoute(this.router.routerState.snapshot.root);
          this.pageTitle = '';
          this.breadcrumbs.forEach(breadcrumb => {
            this.pageTitle += ' > ' + breadcrumb.name;
          });
          BreadcrumbComponent.breadcrumbs2 = [...this.breadcrumbs];
          this.title.setTitle(this.settings.name + this.pageTitle);
        }
      });
    }

  ngAfterViewInit(): void {
      this.breadcrumbs = [...BreadcrumbComponent.breadcrumbs2];
    }

  private parseRoute(node: ActivatedRouteSnapshot) {
        if (node.data['breadcrumb']) {
            if (node.url.length) {
                let urlSegments: UrlSegment[] = [];
                node.pathFromRoot.forEach(routerState => {
                    urlSegments = urlSegments.concat(routerState.url);
                });
                const url = urlSegments.map(urlSegment => {
                    return urlSegment.path;
                }).join('/');
                this.titre = url;
                this.breadcrumbs.push({
                    name: node.data['breadcrumb'],
                    url: '/' + url
                });
            }
        }
        if (node.firstChild) {
            this.parseRoute(node.firstChild);
        }
    }

    public closeSubMenus() {
        if (this.settings.menu == 'vertical') {
            this.menuService.closeAllSubMenus();
        }
    }


}

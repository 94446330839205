import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormInputSwitchComponent} from '@shared/components/inputs/form-input-switch/form-input-switch.component';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InputSwitchModule} from 'primeng/inputswitch';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        TranslateModule,
        InfoBullWidgetComponentModule,
        FormsModule,
        ReactiveFormsModule,
        InputSwitchModule,
    ],
    declarations: [
        FormInputSwitchComponent,
    ],
    exports: [
        FormInputSwitchComponent,
        TranslateModule,
        InfoBullWidgetComponentModule,
        FormsModule,
        ReactiveFormsModule,
        InputSwitchModule,
    ]
})
export class FormInputSwitchComponentModule {
}

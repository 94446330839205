import {Component} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

/**
 * Défini le process de traitement des formulaire à l'interieur d'un modale-subtitution
 *
 * Services metiers à implémenter:
 * * convertirFormulaireMetier: IConvertirFormulaireMetier;
 * * initialiserFormulaireMetier: IInitialiserFormulaireMetier;
 * * requeteCreationMetier: IRequeteCreationMetier;
 * * requeteModificationMetier: IRequeteModificationMetier;
 */
@Component({
    selector: 'app-sgi-dialog',
    template: `
      <p-dialog [header]="header" [(visible)]="modalManager.display" appendTo="body"
                [dismissableMask]="false" [modal]="modal"
                [responsive]="responsive" [style]="{width: width, minWidth: minWidth}" [minY]="0"
                [maximizable]="maximizable" [baseZIndex]="baseZIndex" [autoZIndex]="true"
                [positionTop]="70"
                [draggable]="draggable"
                [styleClass]="styleClass"
                (visibleChange)="lorsDeLaFermeture($event)">
        <div>
          <ng-content select=".content"></ng-content>
        </div>
        <p-footer>
          <ng-content select=".footer"></ng-content>
        </p-footer>
      </p-dialog>`
})
export class SgiDialogComponent extends DialogComponent {

  /**
   * Appelle la requête d'enregistrement d'une donnée
   */
  requeteEnregistrement() {
    this.requeteCreationMetier.requeteCreation(CommonUtilities.sgiSaveData(this.convertirFormulaire()), this.getArgs()).subscribe(() => {
      this.fermerModale();
      this.onSuccess.emit();
    });
  }

  /**
   * Appelle la requête de modification d'une donnée
   */
  requeteModification() {
    this.requeteModificationMetier.requeteModification(CommonUtilities.sgiSaveData(this.convertirFormulaire()), this.getArgs()).subscribe(() => {
      this.fermerModale();
      this.onSuccess.emit();
    });
  }
}

import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClvFileModel, ClvFilesUtils, ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {DomSanitizer} from '@angular/platform-browser';
import {Autowired} from '@angular-ru/autowired';
import {zoomerEnEntrantAnimation} from '@app/core/animations/animations';
import {FilesUtilities} from '@app/core/utilities/files.utilities';
import {AlertService} from '@app/core/services/alert.service';

@Component({
  selector: 'app-gravatar',
  template: `
      <div class="gravatar-container">
        <input type="file" #fileInput
               (change)="onSelectionChange($event)"
               [accept]="accept"
               style="position:absolute; visibility: hidden; left: -400%;">
        <div class="gravatar-image" [ngStyle]="{width: width, height: height,
        backgroundImage: 'url(' + image + ')'}">
          <button mat-icon-button color="primary" type="button" (click)="openFileSelector()"><mat-icon>create</mat-icon></button>
          <button mat-icon-button class="danger-color"
                  type="button" *ngIf="clvFile !== null"
                  @zoomerEnEntrantAnimation
                  (click)="clear()"><mat-icon>delete</mat-icon></button>
        </div>
      </div>
  `,
  styles: [`
  .gravatar-image {
    border: 2px solid #aaaaaaaa;
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
  }`],
  animations: [zoomerEnEntrantAnimation()]
})
export class GravatarComponent implements OnInit {
  @Input('width') width = '200px';
  @Input('height') height = '250px';

  /**
   * Taille maximale de l'image à charger. Est exprimée en bytes
   */
  // @Input('maxSize') maxSize = 300 * 1024;
  @Input('maxSize') maxSize: number = 1 * 1000000; // 1 000 000 Bytes = 1 MégaByte
  /**
   * Liste des extentions acceptées.
   *
   * Les extentions doivvent être séparées par des virgules. (Ex: '.docx, .xlsx')
   */
  @Input('accept') accept = 'image/png, image/jpg, image/jpeg';
  _defaultImage = '/assets/img/users/default-user.jpg';
  @Input('defaultImage') set defaultImage(value: string) {
    if (!ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(value)) {
      this._defaultImage = value;
    }
  }
  @Input('image') image = null;
  @ViewChild('fileInput', {static: false}) fileInput: ElementRef;
  @Input('clvFile') clvFile: ClvFileModel;
  @Output() clvFileChange = new EventEmitter<ClvFileModel>(true);
  @Autowired() alerteService: AlertService;

  constructor(private sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.setImageToDefault();
  }

  /**
   * Positionner l'image par défaut si aucune image n'a été selectionné
   */
  setImageToDefault() {
    if (ClvTsUtilsCommonsJs.isUndefinedOrNull(this.clvFile)) {
      this.image = ClvTsUtilsCommonsJs.copy(this._defaultImage);
    } else {
      this.image = this.clvFile.fullDataUrl;
    }
  }

  /**
   * Efface le champ.
   */
  clear() {
    this.clvFile = null;
    this.clvFileChange.emit(this.clvFile);
    this.setImageToDefault();
  }

  isImageNotDefined() {
    return ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(this.image);
  }

  /**
   * Ouvre la fenêtre de selection de fichiers
   */
  openFileSelector() {
    const input: HTMLInputElement = this.fileInput.nativeElement;
    input.click();
  }

  /**
   * Chargement du fichier sélectionné.
   *
   * @param event
   */
  onSelectionChange(event) {
    const file = event.target.files[0];
    if (file) {
      ClvFilesUtils.serializeFile(file).subscribe(clvFile => {
        if (FilesUtilities.fileMaxSizeExceded([clvFile], this.maxSize)) {
          this.alerteService.showError(`La taille maximale de l'image ne doit pas excéder ${this.maxSize}bytes!<br> Votre fichier fait ${file.size}bytes`).show();
          return;
        }

        if (!FilesUtilities.fileAccepted(clvFile, this.accept.toLowerCase())) {
          this.alerteService.showError(`L'extention du fichier chargé est inattendue!`).show();
          return;
        }
        this.clvFile = clvFile;
        this.clvFileChange.emit(this.clvFile);
        this.setImageToDefault();
      }, error1 => {
        this.clvFileChange.emit(null);
        this.setImageToDefault();
      });
    } else {
      this.clvFileChange.emit(null);
      this.setImageToDefault();
    }
  }
}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {Observable} from 'rxjs';
import {IRecuperationFullFormDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-full-form-donnees-requete';
import {IModificationFullFormulaireRequete} from '@app/core/interfaces/actions/i-modification-full-formulaire-requete';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {RequestUtilities} from '@app/core/utilities/request-utilities';
import {API_UPDATE_DATA_USER} from '@app/core/constantes';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';

@Injectable({providedIn: 'root'})
export class UtilisateurProfilRestApiService implements IRecuperationFullFormDonneesRequete, IModificationFullFormulaireRequete {
    @Autowired() restService: SgiRestService;

    @Autowired() securityStore: SecurityStateService;

    storageUser: IUserConnect = this.securityStore.getUser();

    requeteRecuperationFullFormDonnee<E, S>(data: any, args?: any): Observable<any | S> | Promise<any | S> | any {
        return this.restService.getRequest(`${WebUtilities.baseUrlApi()}utilisateur/profile`).pipe(
            map((reponse: HttpResponse<any>) => RequestUtilities.ajustementRecuperationFullForm(reponse))
        );
    }

    modificationFullFormulaire(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {
            ItemsToSave: [
                {
                    // IdCurrentSession: this.storageUser.idSessionUtilisateur,
                    // IdCurrentTenant: this.storageUser.tenantId,
                    // idCurrentUser: data.formGroup.value.utilisateurId,

                    Email: data.formGroup.value.email,
                    Login: data.formGroup.value.login,
                    Nom: data.formGroup.value.nom,
                    Photo: CommonUtilities.isDataNullUndefinedFalseEmpty(data.imageModel)
                        ? data.formGroup.value.photo : data.imageModel.fullDataUrl,
                    Prenoms: data.formGroup.value.prenoms,

                    UpdatePassword: data.formGroup.value.updatePassword,
                    Password: data.formGroup.value.updatePassword
                        ? window.btoa(window.btoa(window.btoa(data.formGroup.value.oldPassword))) : '',
                    NewPassword: data.formGroup.value.updatePassword
                        ? window.btoa(window.btoa(window.btoa(data.formGroup.value.newPassword))) : '',
                    ConfirmNewPassword: data.formGroup.value.updatePassword
                        ? window.btoa(window.btoa(window.btoa(data.formGroup.value.confirmNewPassword))) : '',
                }
            ],
        };

        // console.log('sendData : ', sendData);

        // const body = {items: []};
        // return of({body: body});

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_UPDATE_DATA_USER}`, sendData);
        /*.pipe(map((response: HttpResponse<any>) => {
            return response;
          }));*/
    }
}

import {TableColumn} from '@shared/components/tables/atlantis-table/atlantis-table.models';

export const columns: Array<TableColumn> = [
    {field: 'raisonSocialeGarant', header: 'Garant'},
    {field: 'photo', header: 'Photo', type: () => 'image'},
    {field: 'matricule', header: 'Matricule '},
    {field: 'nomCompletPersonne', header: 'Nom & Prénoms'},
    {field: 'age', header: 'Age'},
    {field: 'sexe', header: 'Sexe'},
];

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeSoinAutocompleteComponent} from './acte-soin.autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [ActeSoinAutocompleteComponent],
  exports: [ActeSoinAutocompleteComponent]
})
export class ActeSoinAutocompleteFormModule {
}


import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {SecurityStateService} from "@app/core/states/security/security.state.service";
import {AlertService} from "@app/core/services/alert.service";
import { ModaleModifierBordereauMetierService } from './modale-modifier-bordereau.metier.service';

@Component({
  selector: 'app-modale-modifier-bordereau',
  templateUrl: './modale-modifier-bordereau.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModaleModifierBordereauComponent extends DialogComponent implements OnInit {
  @Autowired() convertirFormulaireMetier: ModaleModifierBordereauMetierService;
  @Autowired() initialiserFormulaireMetier: ModaleModifierBordereauMetierService;
  @Autowired() requeteCreationMetier: ModaleModifierBordereauMetierService;
  @Autowired() requeteModificationMetier: ModaleModifierBordereauMetierService;
  @Autowired() metierService: ModaleModifierBordereauMetierService;
  @Autowired() securityService: SecurityStateService;
  @Autowired() alert: AlertService;

  searchForm: FormGroup;
  dataSource: any = [];

  constructor() {
    super();
  }

  ngOnInit() {
    this.formGroup = this.metierService.initialiserFormulaire(this.modalManager.datasourceSelected)
  }

  ngAfterViewInit() {
    super.ngAfterViewInit();
  }

  enregistrer() {
    this.modalManager.datasourceSelected.numeroFacture = this.formGroup.getRawValue().numeroFacture
    this.metierService.enregistrerModification(this.modalManager.datasourceSelected).subscribe((resp: any) => {
      if (resp.body.hasError === false) {
        this.fermerModale();
      };
    });
  }

}

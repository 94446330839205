import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SoinRestApiService} from './soin.rest-api.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class SoinMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: SoinRestApiService;
  @Autowired() formBuilder: FormBuilder;
  @Autowired() datePipe: DatePipe;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaireActeSoin<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      searchActe: !!datas && !!datas.searchActe ? datas.searchActe : null,
      accordPrealable: !!datas && !!datas.accordPrealable ? datas.accordPrealable : null,
      libelleActe: !!datas && !!datas.libelleActe ? datas.libelleActe : null,
      quantiteActe: !!datas && !!datas.quantiteActe ? datas.quantiteActe : null,
      montantActe: !!datas && !!datas.montantActe ? datas.montantActe : null,
      description: !!datas && !!datas.description ? datas.description : null,
    };

    return this.formBuilder.group({
      searchActe: data.searchActe,
      accordPrealable: [{value: data.accordPrealable, disabled: true}, Validators.required],
      libelleActe: [{value: data.libelleActe, disabled: true}, Validators.required],
      quantiteActe: data.quantiteActe,
      montantActe: data.montantActe,
      description: data.description,
    });
  }

  calculMontantActe(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.calculMontantActe(data, args);
  }

}

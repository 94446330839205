import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {SharedModule} from '@shared/shared.module';
import { FacturesComponent } from './factures.component';
import {FactureListeFeatureModule} from '@app/fonctionnalites/factures/prise-en-charge/liste/facture-liste.feature.module';
import {BordereauListeFeatureModule} from '@app/fonctionnalites/factures/bordereau/liste/bordereau-liste.feature.module';
// tslint:disable-next-line:max-line-length
import {PecRetourneListeFeatureModule} from '@app/fonctionnalites/factures/prise-en-charge-retourne/liste/pec-retourne-liste.feature.module';
import {PecEnAttenteListeFeatureModule} from "@app/fonctionnalites/factures/prise-en-charge-en-attente/liste/pec-en-attente-liste.feature.module";

const routes: Routes = [
    {path: '', component: FacturesComponent, data: {breadcrumb: 'Factures'}}
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(routes),
        SharedModule,
        FactureListeFeatureModule,
        BordereauListeFeatureModule,
        PecRetourneListeFeatureModule,
        PecEnAttenteListeFeatureModule
    ],
    declarations: [
        FacturesComponent
    ]
})
export class FacturesModule {
}

import {Component} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {UtilisateurPasswordMetierService} from '../utilisateur-password.metier.service';
import {ClvFileModel} from 'clv-ts-utils';
import {FullFormComponent} from '@shared/behaviors/full-form.component';

@Component({
    selector: 'app-utilisateur-password-full-form',
    templateUrl: './utilisateur-password.full-form.component.html'
})
export class UtilisateurPasswordFullFormComponent extends FullFormComponent {
    @Autowired() convertirFormulaireMetier: UtilisateurPasswordMetierService;
    @Autowired() initialiserFormulaireMetier: UtilisateurPasswordMetierService;
    @Autowired() modificationFullFormulaireMetier: UtilisateurPasswordMetierService;
    // @Autowired() recuperationFullFormDonneesMetier: UtilisateurPasswordMetierService;
    @Autowired() metierService: UtilisateurPasswordMetierService;

    imageModel: ClvFileModel;

    requeteEnregistrementUpdatePassword<T>(): any | T {
        console.log('requeteEnregistrementUpdatePassword : ', this.getArgs());

        this.modificationFullFormulaireMetier.modificationFullFormulaire(this.getArgs());
    }

    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args, imageModel: this.imageModel};
        this.args = {...arg};
    }
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {DemandeHospitalisationTableComponent} from '@app/fonctionnalites/demande-pec/composants/demande-hospitalisation-table/demande-hospitalisation-table.component';
import {ListActeHospitalisationTableModule} from '@app/fonctionnalites/demande-pec/composants/demande-hospitalisation-table/list-acte-hospitalisation-table/list-acte-hospitalisation-table.module';
import {ListAffectionTableListeModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/list-affection-table/liste/list-affection-table.liste.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ListActeHospitalisationTableModule,
        ListAffectionTableListeModule,
    ],
  exports: [DemandeHospitalisationTableComponent],
  declarations: [DemandeHospitalisationTableComponent],
  providers: [],
})
export class DemandeHospitalisationTableModule {
}

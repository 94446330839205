import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpResponse} from '@angular/common/http';
import {PageLayoutComponent} from '../page-layout/page-layout.component';
import {ConfirmationService} from 'primeng/api';
import {TranslateService} from '@ngx-translate/core';
import {Autowired} from '@angular-ru/autowired';
import {IGetAllRequestParam, IRecuperationListeDonneesMetier, ISuppressionDonneeMetier} from '@app/core/interfaces';
import {TableAction, TableColumn} from '../../components/tables/atlantis-table/atlantis-table.models';
import {RestService} from '@app/core/services/rest.service';
import {ListesStateService} from '@app/core/states/listes/listes.state.service';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';

/**
 * Gestion du processus des listes.
 * A la modification, la donnée du tableau est prise directement.
 *
 * Liste des services metiers à implementer:
 * * suppressionDonneeMetier: ISuppressionDonneeMetier
 * * recuperationListeDonneesMetier: IRecuperationListeDonneesMetier
 *
 */
@Component({
    selector: 'app-list-layout',
    templateUrl: './list-layout.component.html',
    styleUrls: ['./list-layout.component.scss']
})
export class ListLayoutComponent extends PageLayoutComponent implements OnInit, AfterViewInit, IGetAllRequestParam {
    /**
     * librairie de translation
     */
    @Autowired() translateService: TranslateService;

    /**
     * Service d'affichage des messages de confirmation (messages d'alertes pour la librairie primeNG)
     */
    @Autowired() confirmationService: ConfirmationService;

    /**
     * Implémentation des methodes REST de base (GET, POST, PUT, DELETE)
     */
    @Autowired() restService: RestService;

    /**
     * Service métier de récupération de liste de données
     */
    @Autowired() recuperationListeDonneesMetier: IRecuperationListeDonneesMetier;

    /**
     * Service de suppression d'une donnée
     */
    @Autowired() suppressionDonneeMetier: ISuppressionDonneeMetier;

    /**
     * Service d'état des paramètres de listes de l' application
     */
    @Autowired() listeStateService: ListesStateService;

    /**
     * Indique qu'il n'y a pas de bouton de suppression pour la le tableau
     */
    @Input('pasDeSupression') pasDeSupression = false;

    /**
     * Indique qu'il n'y apas de bouton de mise à jour pour le tableau
     */
    @Input('pasDeMiseAJour') pasDeMiseAJour = false;

    /**
     * Indique qu'il n'y a pas de bouton de création pour le tableau
     */
    @Input('pasDeCreation') pasDeCreation = false;

    /**
     * Indique qu'il n'y a pas de pagination
     */
    @Input('pasDePagination') pasDePagination = false;

    /**
     * Indique l'abscense de bouton de rechargement
     */
    @Input('pasDeRechargement') pasDeRechargement = false;

    /**
     * Indique qu'il n'y pas d'actions sur le tableau
     */
    @Input('pasDeListeActions') pasDeListeActions = false;

    /**
     * Indique qu'il n'y pas de recherche globale sur le tableau
     */
    @Input('pasDeRechercheGlobale') pasDeRechercheGlobale = false;

    /**
     * Indique qu'il n'y a pas de recherche
     */
    @Input('pasDeRecherche') pasDeRecherche = false;

    /**
     * Evènement émis lors du changement de la page
     */
    @Output() pageChange = new EventEmitter<number>(false);

    /**
     * Evènement émis lors du changement du nombre d'éléments par page
     */
    @Output() perPageChange = new EventEmitter<number>(false);

    /**
     * Evènement émis lorsqu'un click sur le bouton de modification est effectué
     */
    @Output() onModifier = new EventEmitter<any>(false);

    /**
     * Evènement émis lorsqu'un click sur le bouton de création est émis
     */
    @Output() onCreer = new EventEmitter<any>(false);

    /**
     * Données du tableau
     */
    @Input('dataSource') dataSource: Array<any> = [];

    /**
     * Evènement émis lorsque les données du tableau changent
     */
    @Output() dataSourceChange = new EventEmitter<any[]>(true);

    /**
     * Gestionnaire de modales. Une liste peut avoir plusieurs gestionnaires de modales. Cepandant, le premier est celui qui
     * est affecté au modale-subtitution de base, servant à la création et la modification des données.
     */
    gestionnaireDeModales: GestionnaireDeModales[] = [];

    /***
     * Colonnes du tableau
     */
    @Input('columns') cols: TableColumn[] = [];

    /**
     * Colonnes sélectionnées, ou visibles
     */
    @Input('selectedColumns') selectedColumns: TableColumn[] = [];

      /**
     * Ligne courante de détail
     */
    currentRow: any = {};

    /**
     * Mode d'affichage des listes.
     *
     * Par défaut table.
     *
     * Cette fonctionnalité ne fonctionne pas correctement.
     *
     * table|grid
     */
    listDiplayMode: string;
    listDiplayMode$: Observable<string>;

    /**
     * Nombre d'éléments par page
     */
    listPerPage: number;
    listPerPage$: Observable<number>;

    /**
     * Message de damande de confirmation de la suppression
     */
    messageDemandeSuppression = 'Voulez-vous vraimment effectuer cette suppression?';

    /**
     * Requête d'obtention des donnéess
     */
    requetteObtentionDatasource: () => Observable<HttpResponse<Object>>;

    /**
     * Affiche l'icone de chargement lazy
     */
    loading: boolean;

    /**
     * Indique le nombre total de lignes du tableau
     */
    totalRecords = 0;

    /**
     * Boutton d'ajout
     */
    ajouterAction: TableAction = {
        type: 'button', title: 'Ajouter', flat: true, icon: 'add', flatClass: 'success-color', actionPerformance: (data) => {
            this.ajouterNouvelleDonnee(this.gestionnaireDeModales[0]);
        }, canShow: (data) => true
    };

    /**
     * Bouton de modification
     */
    modifierAction: TableAction = {
        type: 'button', title: 'Modifier', flat: true, icon: 'create', flatClass: 'info-color', actionPerformance: (data) => {
            this.modifierDonnee(this.gestionnaireDeModales[0], data);
        }, canShow: (data) => true
    };

    /**
     * Bouton de suppression
     */
    supprimerAction: TableAction = {
        type: 'button', title: 'Supprimer', flat: true, icon: 'delete', flatClass: 'danger-color', actionPerformance: (data) => {
            this.demanderSuppression(data);
        }, canShow: (data) => true
    };

    /**
     * Bouton pour consulter un document
     */
    consulterAction: TableAction = {
        type: 'button', title: 'Consulter', flat: true, icon: 'visibility', flatClass: 'info-color', actionPerformance: (data) => {
            this.consulterDocument(data);
        }, canShow: (data) => true
    };

    /**
     * Bouton pour ajouter un commentaire dans le cas d'un acte soumis a accord préalable
     */
    ajouterCommentaireAction: TableAction = {
        // tslint:disable-next-line:max-line-length
        type: 'button', title: 'Ajouter commentaire', flat: true, icon: 'assignment', flatClass: 'warning-color', actionPerformance: (data) => {
            this.ajouterCommentairActe(data);
        }, canShow: (data) => !!data.accordPrealable
    };

    /**
     * Register des actions non communes à une ligne
     */
    notRowActions: Array<TableAction> = [this.ajouterAction];

    /**
     * Register des actions communes à une ligne
     */
    tableActions: Array<TableAction> = [this.modifierAction, this.supprimerAction];

    constructor() {
        super();
        this.hasToolbar = true;
        this.gestionnaireDeModales.push(
            {display: false, datasourceSelected: new Object(null), typeOpenModal: 'create'},
            {display: false, datasourceSelected: new Object(null), typeOpenModal: 'create'},
        );
        this.getListElements();
    }

    ngOnInit(): void {
        super.ngOnInit();
        this.requetteObtentionDatasource = () =>
            this.recuperationListeDonneesMetier.requeteRecuperationListeDonnees(this.getAllRequestParam(),
            {...this.getArgs()});
        this.selectedColumns = this.cols;
        this.recuperDonnees();
        this.getListElements();
    }

    ngAfterViewInit(): void {
      this.selectedColumns = this.cols;
        this.getListElements();
    }

    /**
     * Défini les parametres d'affichage de listes
     */
    protected getListElements() {
        this.listDiplayMode = this.listeStateService.getListeMode();
        this.listPerPage = this.listeStateService.getPerPage();
        this.listDiplayMode$ = this.listeStateService.query.listMode$;
        this.listPerPage$ = this.listeStateService.query.perPage$;
    }

    /**
     * Défini le mode d'affichage des listes à grid
     */
    setListDisplayModeGrid() {
        this.listeStateService.setListeMode('grid');
    }

    /**
     * Défini le mode d'affichage des listes à table
     */
    setListDisplayModeTable() {
        this.listeStateService.setListeMode('table');
    }

    /**
     * Ajoute une nouvelle donnée
     * @param gestionnaireDeModales
     */
    ajouterNouvelleDonnee(gestionnaireDeModales: GestionnaireDeModales) {
        gestionnaireDeModales.typeOpenModal = 'create';
        gestionnaireDeModales.datasourceSelected = this.defaultObject;
        gestionnaireDeModales.display = true;
        this.onCreer.emit(gestionnaireDeModales);
    }

    /**
     * Modifie une donnée
     *
     * @param modalManager
     * @param data
     */
    modifierDonnee(modalManager: GestionnaireDeModales, data: any) {
        modalManager.typeOpenModal = 'update';
        modalManager.datasourceSelected = data;
        modalManager.display = true;
        this.onModifier.emit(modalManager);
    }

    /**
     * récupère la liste des données
     * @param data
     */
    recuperDonnees(data?: any) {
        this.defineArgs();
        this.loading = true;
        this.requetteObtentionDatasource().subscribe((response: any) => {
            this.dataSource = response.body;
            try {
                this.dataSource.forEach((dat, index) => {
                    dat.id_key = index + 1;
                    this.totalRecords++;
                });
            } catch (e) {
            }
            // this.dataSource = (response.body.length > 0) ? response.body : [response.body];
            this.dataSourceChange.emit(this.dataSource);
            this.loading = false;
        });
    }

    /**
     * Réquête de suppression d'un élément.
     * @param data
     */
    requeteSuppression(data: any): Observable<HttpResponse<any>> {
        return this.suppressionDonneeMetier.requeteSuppression(data, {...this.getArgs()});
    }

    /**
     * Recupère tous les paramètres de la requête
     */
    getAllRequestParam<T>(): any | T {
        return null;
    }

    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     * @param data
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
    }
}

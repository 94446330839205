import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ResetPasswordFormComponent} from '@app/fonctionnalites/authentification/connexion/composants/reset-password-form/reset-password-form.component';



@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
    ],
    exports: [
        ResetPasswordFormComponent,
    ],
    declarations: [
        ResetPasswordFormComponent,
    ],
    providers: [],
})
export class ResetPasswordFormModule {
}

import {AfterViewInit, Component, EventEmitter, OnInit, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';

@Component({
  selector: 'app-ecran-veille',
  templateUrl: './ecran-veille.component.html',
  styleUrls: ['./ecran-veille.component.scss']
})
export class EcranVeilleComponent extends DialogComponent implements OnInit, AfterViewInit {
  @Output() modaleManagerChange = new EventEmitter<void>(false);

  constructor() {
    super();
    this.header = 'Ecran de veille';
  }
  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit(): void {
    super.ngAfterViewInit();
  }

  handleChangeModaleManager() {
    this.modaleManagerChange.emit();
  }
}

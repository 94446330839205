import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormInputCheckboxComponent} from '@shared/components/inputs/form-input-checkbox/form-input-checkbox.component';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {CheckboxModule} from 'primeng/checkbox';
import {TranslateModule} from '@ngx-translate/core';

@NgModule({
    imports: [
        CommonModule,
        InfoBullWidgetComponentModule,
        CheckboxModule,
        TranslateModule,
    ],
    declarations: [
        FormInputCheckboxComponent,
    ],
    exports: [
        FormInputCheckboxComponent,
        InfoBullWidgetComponentModule,
        CheckboxModule,
        TranslateModule,
    ]
})
export class FormInputCheckboxComponentModule {
}

import {AfterViewInit, Component, ContentChildren, EventEmitter, Input, OnInit, Output, QueryList, TemplateRef} from '@angular/core';
import {TableAction, TableColumn} from './atlantis-table.models';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {DomSanitizer} from '@angular/platform-browser';
import {Autowired} from '@angular-ru/autowired';
import {FileExportService} from '@app/core/services/file-export.service';
import {FORMAT_DATE} from '@app/core/constantes';
import {BehaviorSubject, Observable} from 'rxjs';
import {DefColumnTableDirective} from '@shared/components/tables/atlantis-table/def-column-table.directive';
import {ListesStateService} from '@app/core/states/listes/listes.state.service';

/**
 * Composant table. Il permet l'affichage d'une liste de données dans une table
 */
@Component({
    selector: 'app-atlantis-table',
    templateUrl: 'atlantis-table.component.html'
})
export class AtlantisTableComponent implements OnInit, AfterViewInit {

    /**
     * Service d'export de fichiers
     */
    @Autowired() fileExport: FileExportService;

    /**
     * Indique que le tableau est parginé coté serveur
     */
    @Input('lazy') lazy = false;
    @Input('showListActions') showListActions = true;

    /**
     * Indique le nombre total de données lorsque la pargination est effectuée coté serveur
     */
    @Input('totalRecords') totalRecords = 0;

    /**
     * Liste d'informations à afficher. Il s'agit d'une liste de données
     */
    @Input('dataSource') dataSource: any[] = null;

    /**
     * Affiche l'icone de chargement lazy
     */
    @Input('loading') loading: boolean;

    /**
     * Indique si le tableau peut être scrollable.
     *
     * Est à True par défaut.
     */
    @Input('scrollable') scrollable = true;

    /**
     * Indique si le tableau est responsive ou non.
     *
     * Est à True par défaut.
     */
    @Input('responsive') responsive = true;

    private _columns: Array<TableColumn> = [];
    /**
     * Représente la liste des colonnes du tableau à afficher.
     * Le format est:
     * <code>
     *   Array<{field: string, header: string}>
     * </code>
     *
     * Par example:
     * <code>
     *   [{field: 'toy', header: 'Toyota'},
     *    {field: 'nis', header: 'Nissan'}]
     * </code>
     *
     * <code>field</code> représente le champ et <code>header</code> le libellé du champ
     */
    @Input('columns') set columns(columns: Array<TableColumn>) {
        this._columns = columns;
        // this.setColumnsBodyTemplate();
        this._columns.forEach(col => {
            if (!!col.substitu) {
                col.substitu.objectList.subscribe(rep => {
                    col.substitu.objectListResolve = rep;
                });
            }
        });
    }

    get columns() {
        return this._columns;
    }

    /**
     * Indique que le tableau est paginé ou non. Par defaut est à true.
     */
    @Input('paginator') paginator = true;

    /**
     * Indique le nombre de lignes par pages, si le tableau est paginé.
     */
    @Input('perPage') perPage = 5;

    /**
     * Indique la clé unique d'identification de chaque ligne du tableau.
     * La valeur par defaut est <code>id_key</code>
     */
    @Input('dataKey') dataKey = 'id_key';

    /**
     * Message à afficher lorsqu'il n'y a aucune données.
     */
    @Input('emptyMessage') emptyMessage = 'Aucune donnée trouvée';

    /**
     * Indique le libellé de la colonne action. (langue par défaut: français)
     */
    @Input('actionLabel') actionLabel = 'Actions';

    /**
     * Indique les actions à mener dans la colonne Action.
     */
    @Input('actions') actions: Array<TableAction> = [];

    /**
     * Indique que les colonnes sont filtrables ou non.
     *
     * Par défaut: false
     */
    @Input('hasFiltres') hasFiltres = false;

    /**
     * Indique que le tableau a un filtre global ou non.
     *
     * Par défaut: false
     */
    @Input('hasFiltreGlobal') hasFiltreGlobal = false;

    /**
     * PLaceholder du filtre global
     */
    @Input('filtreGlobalPlaceholder') filtreGlobalPlaceholder = 'Rechercher tout';

    /**
     * Icone d'actualisation du tableau
     */
    @Input('refreshIcon') refreshIcon = 'refresh';

    /**
     * Action d'actualisation du tableau
     */
    @Output() onRefresh = new EventEmitter(false);

    /**
     * Evènement émit lors de la modification d'un champs dans le tableau
     */
    @Output() onCellUpdate = new EventEmitter<any>(false);

    /**
     * Indique les actions à mener, non applicables sur les lignes du tableau.
     */
    @Input('notRowActions') notRowActions: Array<TableAction> = [];

    /**
     * Format des dates sur le tableau.
     *
     * Format defaut: dd/MM/yyyy
     *
     * Formats:
     * dd: jours
     * MM: mois sur 2 digits
     * yyyy: années sur 4 digits
     */
    @Input('formatDate') formatDate = FORMAT_DATE;

    /**
     * Emet l'évènement lors  de la demande de chargement de nouvelles données
     */
    @Output() onLazyLoad = new EventEmitter();

    /**
     * Emet un évènement pour la sélection de l'élément courant du tableau en détail
     */
    @Output() onDetailOpen = new EventEmitter();

    protected _hasDetails = false;
    /**
     * Bouléen indiquant au tableau d'afficher les détails du tableau
     */
    @Input('hasDetails') set hasDetails(details: boolean) {
        this._hasDetails = details;
        this.largeurDetails = this.columns.length;
        if (this._hasDetails) {
            this.largeurDetails++;
        }

        if (!!this.actions && this.actions.length > 0) {
            this.largeurDetails++;
        }
    }

    get hasDetails() {
        return this._hasDetails;
    }

    /**
     * Variable indiquant que les lignes du tableau sont ordonnées.
     */
    private _reordonnerLignes: boolean;

    /**
     * Input indiquant que les lignes du tableau peuvent être reordonnées.
     * @param state
     */
    @Input('reordonnerLignes') set reordonnerLignes(reordonnerLignes: boolean) {
        this._reordonnerLignes = reordonnerLignes;
        if (this._reordonnerLignes) {
            this.largeurDetails++;
        }
    }


    /**
     * Retourne l'état de reordonnancement des lignes du tableau
     */
    get reordonnerLignes(): boolean {
        return this._reordonnerLignes;
    }

    /**
     * Indique le mode de sélection de lignes pour les actions groupés
     */
    private _selectionMode: '' | 'single' | 'multi' = '';

    /**
     * Input  permettant le renseignement du mode de selection de lignes pour les actions groupées
     * @param selectionMode
     */
    @Input('selectionMode') set selectionMode(selectionMode: '' | 'single' | 'multi') {
        this._selectionMode = selectionMode;
        if (this._selectionMode === 'multi' || this._selectionMode === 'single') {
            this.largeurDetails++;
        }
    }

    /**
     * Récupération du mode de selection de lignes
     */
    get selectionMode(): '' | 'single' | 'multi' {
        return this._selectionMode;
    }

    /**
     * Dééfini la/les ligne(s) sélectionnée (s)
     */
    @Input('selection') selection: any;

    /**
     * Retourne la sélection du tableau
     */
    @Output() selectionChange = new EventEmitter<any>(false);

    /**
     * Indique la largeur de la zone des détails
     */
    largeurDetails = this.columns.length;

    /**
     * Indique la presence de l'action d'export en PDF
     */
    @Input('exportPDF') exportPDF: boolean;

    /**
     * Indique la présence de l'action d'export en Excel
     */
    @Input('exportXLSX') exportXLSX: boolean;

    /**
     * Indique la présence de l'action d'export en CSV
     */
    @Input('exportCSV') exportCSV: boolean;

    /**
     * Indique que l'export porte sur toute ou une partie des données.
     */
    @Input('exportSelected') exportSelected: boolean;

    /**
     * Nom du fichier à exporter
     */
    @Input('exportName') exportName = `atlantis_${Date.now()}`;

    /**
     * Indique si le tableau peut etre rechargé
     */
    @Input('canRefresh') canRefresh = true;

    /**
     * Utilitaire commun
     */
    commonUtilities = CommonUtilities;

    /**
     * Indique les colonnes sélectionnées
     */
    _selectedColumns: any[];

    /**
     * Indique que l'on peut basculer les colonnes ou non
     */
    @Input('basculementColonnes') basculementColonnes: boolean;

    /**
     * Enregistre la dernière ligne ouverte
     */
    lastOpenedRow: any;

    /**
     * Styles d'une ligne
     */
    @Input('rowClass') rowClass: (row: any) => string;
    canShowSubject = new BehaviorSubject(true);
    canShow$: Observable<boolean> = this.canShowSubject.asObservable();
    @Input('detailsTemplate') detailsTemplate: TemplateRef<any>;
    @ContentChildren(DefColumnTableDirective) columnsDefinitions: QueryList<DefColumnTableDirective>;


    /**
     * Indique que le tableau est paginé ou non. Par defaut est à true.
     */
    @Input('sectionControlRowTable') sectionControlRowTable = null;


    /**
     * Gestion de pagination
     */
        // @Autowired() settings: AppSettings;
    @Autowired() listeStateService: ListesStateService;
    data: any[];

    constructor(public sanitier: DomSanitizer) {
    }


    ngOnInit() {
        this._selectedColumns = this.columns;

        this.data = [
            {'name': 5},
            {'name': 10},
            {'name': 20},
            {'name': 50},
            {'name': 100}
        ];
        // this.listeStateService.setPerPage(this.perPage);
    }

    handleChange(event) {
        // this.perPage = event.value.name;
        // this.listeStateService.setPerPage(this.perPage);
    }

    /**
     * recupère les colonnes sélectionnées
     */
    get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    /**
     * Défini les colonnes sélectionnées
     * @param val
     */
    set selectedColumns(val: any[]) {
        // restore original order
        this._selectedColumns = this.columns.filter(col => val.includes(col));
    }

    /**
     * Mets à jour (ajuste) la taille de la zone de détails
     * @param evt
     */
    updateDetailsLength(evt: any) {
        let len = evt.length;
        if (this._reordonnerLignes) {
            len++;
        }
        if (this._selectionMode === 'single' || this._selectionMode === 'multi') {
            len++;
        }
        if (this._hasDetails) {
            len++;
        }
        if (this.actions) {
            len++;
        }
        if (len < this.largeurDetails) {
            this.largeurDetails -= (this.largeurDetails - len);
        }
        if (len > this.largeurDetails) {
            this.largeurDetails += (len - this.largeurDetails);
        }
    }

    /**
     * Recsupère la valeur de substitution d'un champ
     *
     * @param col
     * @param row
     */
    getSubstituValue(col: TableColumn, row: any): any {
        try {
            return col.substitu.objectListResolve.find(v => v[col.substitu.key] === row[col.field])[col.substitu.replaceKey];
        } catch (e) {
            return row[col.field];
        }
    }

    /**
     * Emets un évenement lors de l'ouverture d'un détails du tableau et enregistre la derniere ligne ouverte
     * @param row
     */
    emitDetails(row: any) {
        this.lastOpenedRow = row;
        this.onDetailOpen.emit(row);
    }

    /**
     * Emets l'évènement de raffraichisement du tableau et mets à jours les détails de la ligne ouverte.
     * @param evt
     */
    emitRefresh(evt?: any) {
        this.onRefresh.emit(evt);
        setTimeout(() => {
            try {
                this.onDetailOpen.emit(this.dataSource.find(v => v[this.dataKey] === this.lastOpenedRow[this.dataKey]));
            } catch (e) {
            }
        }, 500);
    }

    /**
     * rafrechi la table. Utile lors de l'utilsation de la pagination serveur
     */
    refreshTable() {
        this.canShowSubject.next(false);
        this.selection = null;
        setTimeout(() => {
            this.canShowSubject.next(true);
        }, 200);
    }

    /**
     * coloration de ligne en cas de status à : En accord préalable, En validation de l'avis d'hospit
     */
    getClassRowTable(rowData): string {
        /**
         *
         */
        if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.sectionControlRowTable)) {
            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(rowData.libelleTypeStatut)) {
                if (rowData.libelleTypeStatut === 'En validation de l\'avis d\'hospit' ||
                    rowData.libelleTypeStatut === 'En accord préalable') {
                    return 'color-row-red-PEC';
                }
            }

            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(rowData.accordPrealable)) {
                if (rowData.accordPrealable) {
                    return 'color-row-red-PEC';
                }
            }

            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(rowData.accordPrealableHospit)) {
                if (rowData.accordPrealableHospit) {
                    return 'color-row-red-PEC';
                }
            }
        }

        /**
         *
         */
        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(this.sectionControlRowTable) && this.sectionControlRowTable === 'pharmacie-pec-list') {
            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(rowData.statutOrdonnance)) {
                if (rowData.statutOrdonnance !== 'Ordonnance autorisée') {
                    return 'color-row-red-PEC';
                }
            }
        }

        return '';
    }


    setColumnsBodyTemplate() {
        this.columns.forEach(value => {
            const defColumn = this.findColumnDefByField(value.field);
            if (defColumn) {
                value.body = defColumn.template;
            }
        });
    }

    findColumnDefByField(field: string) {
        try {
            return this.columnsDefinitions.find(item => item.appDefColumnTable === field);
        } catch (e) {
            return null;
        }
    }

    ngAfterViewInit(): void {
        this.setColumnsBodyTemplate();
    }
}

export const COMPLEXITE_MOT_DE_PASSE = '^(?=.{3,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\\W).*$';
export const FORMAT_TELEPHONE = '^[+]?[0-9]+$';
export const FORMAT_CHAMP_REQUIS = '^[ a-zA-Z0-9]*[a-zA-Z0-9]+$';
export const FORMAT_NUMERIQUE = '^([0-9]?[0-9]*[ 0-9]*[.0-9]?[0-9]*[0-9])?$';
export const FORMAT_NUMERIQUE_REQUIS = '^[0-9]?[0-9]*[ 0-9]*[.0-9]?[0-9]*[0-9]$';
export const FORMAT_TAUX = '(^[0-9]([.][0-9]+)?$|^[1-9][0-9]?([.][0-9]+)?$|^100$)?';
export const FORMAT_TAUX_REQUIS = '^[0-9]([.][0-9]+)?$|^[1-9][0-9]?([.][0-9]+)?$|^100$';
export const UUID_REGEX = '[0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12}';
export const FORMAT_DATE = 'dd/MM/yyyy';
export const FORMAT_DATE_HEURE = 'dd/MM/yyyy hh:mm:ss';

export const FORMULAIRE_INVALIDE_MESSAGE = 'Formulaire invalide. Veuillez remplir correctement tous les champs';
export const MOT_DE_PASSE_INVALIDE_MESSAGE = 'Le mot de passe doit avoir au moins 1 caractère majuscule, 1 caractère miniscule, 1 chiffre, 1 caractère spécial, 8 caratères minimum';
export const FORM_INVALID_MESSAGE = 'Veuillez remplir correctement le formulaire. Les champs marqués par les axtérix sont obligatoires.';
export const MESSAGE_ERREUR_TAUX = 'La valeur doit être comprise entre 0 et 100';
export const MESSAGE_ERREUR_IBAN = 'Le numéro IBAN n\'est pas correcte.';
export const MESSAGE_ERREUR_MIN = 'La valeur minimale doit être ';
export const DONNEES_FORMULAIRE_INCORRECTE = 'Les données ne sont pas correctement renseignées !';
export const FICHIERS_CSV_MULTIPLE_ONGLETS_NON_GERE = '<strong>Le système ne gère pas les fichiers à onglets multiples</strong>! <br> Veuillez charger s\'il vous plait un fichier avec un seule onglet.!';
export const PAGE_LICENCE_EXPIREE_TITLE = 'Licence non activée';
export const PAGE_LICENCE_EXPIREE_DETAIL = 'Licence non activée, veuillez contacter l\'éditeur du logiciel';
export const PAGE_LICENCE_EXPIREE_IMG = '../../../assets/img/licence_expiree.jpg';
export const SELECTIONNER_FONDS_ACTIF = `Vous n'avez aucun fonds actif! <br> Sélectionnez un fonds en cliquant sur <strong class="accent-color">Changer</strong> au haut de la page.`;


export const peuxGenererAmortissementTitreCreance = (type: string): boolean => {
    return type === 'OBLIGATION' || type === 'TITRE_CREANCE_NEGOCIABLE';
};

export const STATUT_EN_ACCORD_PREALABLE = 'En accord préalable';
export const STATUT_EN_ATTENTE_DE_CLOTURE = 'En attente de cloture';
export const STATUT_REJETE = 'Rejete';
export const STATUT_REJETE_PRESCRIPTION = 'Rejeté';
export const STATUT_CLOTURE = 'Cloturé';
export const STATUT_EN_VALIDATION_AVIS_HOSPI = 'En validation de l\'avis d\'hospit';

export const AUCUNE_DONNEE_TROUVEE = 'Aucune donnée trouvée';
export const AUCUN_MATRICULE_SAISI = 'Aucun matricule saisi';

export const AUCUN_ASSURE_RECHERCHER = 'Veuillez rechercher un assuré !';
export const AUCUN_MEDECIN_RECHERCHER = 'Veuillez rechercher un médecin !';
export const AUCUN_ACTE_AJOUTER = 'Veuillez ajouter au moins un acte !';
export const VERIFICATION_CALCUL_PART_ASSURE = 'Veuillez éffectuer le calcul pour la part de l\'assuré !';
export const AUCUN_ACTE_COMPLEMENTAIRE_AJOUTER = 'Veuillez ajouter au moins un acte complémentaire !';
export const AUCUNE_AFFECTION_AJOUTER = 'Veuillez ajouter au moins une affection !';
export const AUCUNE_PRESCRIPTION_AJOUTER = 'Veuillez ajouter au moins une prescription !';
export const AUCUN_DOCUMENT_AJOUTER = 'Veuillez ajouter au moins un document !';
export const ERROR_MESSAGE_ADD_DESCRIPTION_ACTE = 'Veuillez saisir les descriptions des actes soumis à accord préalable dans les champs' +
    ' colorés en jaune ou dans le champ renseignement clinique.';
export const ERROR_MESSAGE_ADD_DESCRIPTION_ACTE_HOSPITALISATION = 'Veuillez saisir la descriptions de l\'acte soumis à accord préalable.';

export const ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC = 'La demande de prise en charge ne doit pas ètre en statut clôturé';

export const ERROR_MESSAGE_CLOTURE_DEMANDE_PEC = 'La demande de prise en charge doit être en attente de clôture';

export const ERROR_MESSAGE_VERIFICATION_NOUVELLE_DEMANDE = 'Cet prestatire ne pas faire une nouvelle demande !';

export const ERROR_MESSAGE_ELEMENT_TABLE_NON_SELECTIONNE = 'Veuillez sélectionner un élément dans le tableau !';

export const ERROR_MESSAGE_VERIFICATION_RECHERCHE_POURSUITE = 'Veillez remplir au moins un champs !';

export const ERROR_MESSAGE_PRISE_EN_CHARGE_HOSPITILISATION = 'Veuillez sélectionner une prise en charge d\'hospitalisation';

export const ERROR_MESSAGE_ITEMS_VIDE = 'Contenu introuvable, Numéro de bon null !';

export const STORAGE = {
    USER_CONNECT: 'userConnect',
    MEDECINS: 'medecins',
    ACTES: 'actes',
    ACTES_HOSPITALISATION: 'actesHospitalisation',
    AFFECTIONS: 'affections',
    LIST_GARANT: 'listGarant',
};

export const CODE_OBJET_DEMANDE_PEC = 'OBJ0005';

export const MESSAGE_CONTINUE_FAMILLE_DEMANDE_PEC = 'Voulez-vous continuer avec cette famille ?';

export const URL_SOUND_ERROR = '../../../assets/sounds/sound_error.mp3';

export const CODE_ENTITE_DEMANDE_DOSSIER_MEDICAL = 'PrestataireMedical';


/** Droits actions pour la section DEMANDE */
export const PERMISSION_PRESTATION_DEMANDEPEC_AJOUTER = 'app.prestation.demandepec.ajouter';
export const PERMISSION_PRESTATION_DEMANDEPEC_CLOTURER = 'app.prestation.demandepec.cloturer';
export const PERMISSION_PRESTATION_DEMANDEPEC_IMPRIMERBON = 'app.prestation.demandepec.imprimerBon';
// app.prestation.demandepec.prolongerHospit
export const PERMISSION_PRESTATION_DEMANDEPEC_PROLONGERHOSPIT = 'app.prestation.demandepec.soinsHospit';
export const PERMISSION_PRESTATION_DEMANDEPEC_POURSUITE = 'app.prestation.demandepec.poursuite';
export const PERMISSION_PRESTATION_DEMANDEPEC_SUPPRIMER = 'app.prestation.demandepec.supprimer';

export const PERMISSION_PRESTATION_DEMANDEPEC_CLOTURER_CLOTURE_ANALYSE = 'app.prestation.laboratoire.cloturer';

export const PERMISSION_PRESTATION_DEMANDEPEC_NOUVELLE_DEMANDE_HOSPI = 'app.prestation.demandepec.ajouterHospitalisation';

/** Droits actions pour la section PHARMACIE */
export const PERMISSION_PRESTATION_PHARMACIE_PRISEENCHARGE = 'app.prestation.pharmacie.priseEnCharge';
export const PERMISSION_PRESTATION_PHARMACIE_AJOUTER = 'app.prestation.pharmacie.ajouter';
export const PERMISSION_PRESTATION_PHARMACIE_SUBSTITUER = 'app.prestation.pharmacie.substituer';

// à appliquer sur l'onglet Ordonnance en validation (Pharmacie)
export const PERMISSION_PRESTATION_PHARMACIE_ENATTENTE = 'app.prestation.pharmacie.enAttente';

export const PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE = 'app.prestation.pharmacie.maladieChronique';
export const PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE_SUBSTITUER = 'app.prestation.pharmacie.maladieChronique.substituer';
export const PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE_PRISEENCHARGE = 'app.prestation.pharmacie.maladieChronique.priseEnCharge';

/** Droits actions pour la section FACTURE */
export const PERMISSION_PRESTATION_FACTURE_BORDEREAU = 'app.prestation.facture.bordereau';
export const PERMISSION_PRESTATION_FACTURE_IMPRIMERBORDEREAU = 'app.prestation.facture.imprimerBordereau';
export const PERMISSION_PRESTATION_FACTURE_VALIDERBORDERAU = 'app.prestation.facture.validerBorderau';
export const PERMISSION_PRESTATION_FACTURE_LIERBORDEREAU = 'app.prestation.facture.lierBordereau';

// à appliquer aussi dans la liste de l'onglet Prises en charges en attente (Factures)
export const PERMISSION_PRESTATION_FACTURE_MODIFIERFACTURE = 'app.prestation.facture.modifierFacture';

export const PERMISSION_PRESTATION_FACTURE_MODIFIERBORDEREAU = 'app.prestation.facture.modifierBordereau';
export const PERMISSION_PRESTATION_FACTURE_RESSAISIRFACTURE = 'app.prestation.facture.ressaisirFacture';
export const PERMISSION_PRESTATION_FACTURE_RETIRERFACTUREBORDEREAU = 'app.prestation.facture.retirerFactureBordereau';

export const ERROR_MESSAGE_NOT_PERMISSION_ACTION = 'vous n\'avez pas les droits pour éffectuer cette action !';

export const ERROR_MESSAGE_NOT_TYPE_CHAMBRE_HOSPITALISATION = 'Veuillez sélectionner un acte d\'hospitalisation !';
export const ERROR_MESSAGE_ACTE_CHAMBRE_HOSPITALISATION_EXIST = 'Vous avez déjà sélectionné une chambre pour l\'hospitalisation !';
export const ERROR_MESSAGE_ACTE_CHAMBRE_HOSPITALISATION_NOT_EXIST = 'Veuillez sélectionner une chambre pour l\'hospitalisation !';

export const MESSAGE_POURSUIVRE_DEMANDE_APRES_CLOTURE = 'Voulez-vous poursuivre cette demande prise en charge';

export const ERROR_MESSAGE_IDENTIFIANT_INVALIDE = 'L\'identifiant de cette demande de prise en charge est invalide !';
export const STATUT_BORDEREAU = {
    CREE: 'Créé',
    VALIDERATTACHE: 'Validé rattaché à un décompte',
    VALIDENONRATTACHE: 'Validé non rattaché à un décompte',
    PAYE: 'Payé',
};
export const STATUT_SINISTRE = {
    CREE: 'Créé',
    RATTACHE: 'Rattaché à un bordereau',
    VALIDE: 'Validé'
};

export const ERROR_MESSAGE_BORDEREAU_STATUT_CREE = 'le bordereau n\'est pas au statut créé';

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListAffectionTableListeMetierService} from './list-affection-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {FormGroup} from '@angular/forms';
import {AUCUN_ASSURE_RECHERCHER, FORMULAIRE_INVALIDE_MESSAGE} from '@app/core/constantes';
import {objectKeys} from 'codelyzer/util/objectKeys';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IListGarant} from '@app/core/interfaces/list-garant/i-list-garant';
import {SecurityStateService} from '@app/core/states/security/security.state.service';

@Component({
    selector: 'app-list-affection-table-liste',
    templateUrl: './list-affection-table.liste.component.html'
})

export class ListAffectionTableListeComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: ListAffectionTableListeMetierService;
    @Autowired() demandePECMetierService: DemandePriseChargeMetierService;
    @Autowired() securityService: SecurityStateService;

    @Input() adherant: any;
    @Input() medecinSelected: any;
    @Input() dataListAffections: any[] = [];
    @Output() dataListAffectionsSelectedEvent = new EventEmitter<any[]>();

    affectionSelectedForm: FormGroup;
    affectionSelected: any;

    listGarantUser: IListGarant;

    constructor() {
        super();
        this.tableActions = [
            this.supprimerAction
        ];

        this.listGarantUser = this.securityService.getListGarant();
    }

    ngOnInit(): void {
        this.cols = [
            {field: 'codeAffection', header: 'Code Affection'},
            // {field: 'libelleAffection', header: 'Libelle'},
        ];

        this.initFormulaire();
        this.recuperationAffectionSave();

        this.formatTableListAddIndex(this.dataListAffections);
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initFormulaire() {
        this.affectionSelectedForm = this.demandePECMetierService.initialiserFormulaireAffectionSelected(null);
    }

    recuperationAffectionSave() {
        if (!this.demandePECMetierService.affections || this.demandePECMetierService.affections.length === 0) {
            const reqAtes = this.demandePECMetierService.recupererAffections({});
            reqAtes.subscribe(res => {
                this.demandePECMetierService.affections = res.body.items;
            });
        }
    }

    formatTableListAddIndex(responseData: any[] = []) {
        if (responseData === undefined || responseData.length === 0) {
            this.totalRecords = 0;
            return;
        }

        // this.listAffectionTable = [...this.listAffectionTable, ...responseData];
        this.totalRecords = null;
        if (this.dataListAffections && this.dataListAffections.length > 0) {
            this.dataListAffections.forEach((data, index) => {
                data.id_key = index + 1;
            });
        }
    }

    actionSelectedAffection(event) {
        this.affectionSelected = event;
        let ErrorAlert: any;

        if (!this.adherant || objectKeys(this.adherant).length === 0) {
            ErrorAlert = this.alertService.showError(AUCUN_ASSURE_RECHERCHER);
            ErrorAlert.show();
            this.affectionSelectedForm.patchValue({
                codeAffection: null
            });
            return;
        }
        /*if (!this.medecinSelected || objectKeys(this.medecinSelected).length === 0) {
          ErrorAlert = this.alertService.showError(AUCUN_MEDECIN_RECHERCHER);
          ErrorAlert.show();
          this.affectionSelectedForm.patchValue({
            codeAffection: null
          });
          return;
        }*/

        const findElt = this.dataListAffections.find((elt) => elt.codeAffection === this.affectionSelected.codeAffection);

        if (findElt !== undefined) {
            ErrorAlert = this.alertService.showError('Cette affection a déjà été ajoutée !');
            ErrorAlert.show();
            this.resetFormulaure();
            return;
        }
    }

    ajouterAffection() {
        console.log(this.affectionSelected);
        console.log(this.affectionSelectedForm.getRawValue());
        if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.affectionSelected)) {
            this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        if (!this.affectionSelectedForm.getRawValue().codeAffection) {
            this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        this.dataListAffections = [
            ...this.dataListAffections,
            this.affectionSelected
        ];

        if (this.dataListAffections && this.dataListAffections.length > 0) {
            this.dataListAffections.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }

        // this.resetFormulaure();
        this.affectionSelectedForm.reset();

        this.dataListAffectionsSelectedEvent.emit(this.dataListAffections);

        this.affectionSelected = null;
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {

            this.dataListAffections.forEach((elt) => {
                if ((elt.codeAffection === data.codeAffection) && elt.addElementExtern !== undefined) { elt.isDeleted = true; }
            });

            const findElt = this.dataListAffections.find((elt) =>
                (elt.codeAffection === data.codeAffection) && data.addElementExtern === undefined);

            if (findElt !== undefined) {
                this.dataListAffections = [...this.dataListAffections.filter((item) => item !== findElt)];
            }

            this.dataListAffections = [...this.dataListAffections.filter((item) => item !== data)];

            this.dataListAffectionsSelectedEvent.emit(this.dataListAffections);
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    resetFormulaure() {
        this.affectionSelectedForm.patchValue({
            codeAffection: null,
        });
    }

}

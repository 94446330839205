import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {MedecinActeTableRestApiService} from './medecin-acte-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class MedecinActeTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: MedecinActeTableRestApiService;
  @Autowired() formBuilder: FormBuilder;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaireMedecin<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      nomMedecin: null,
    };

    return this.formBuilder.group({
      nomMedecin: [{value: data.nomMedecin, disabled: true}],
    });
  }

  initialiserFormulaireMedecinAutoComplete<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      nomMedecin: !!datas && !!datas.medecin ? datas.medecin : null,
    };

    return this.formBuilder.group({
      nomMedecin: data.nomMedecin,
    });
  }

  calculerMontantTotalPartAssure(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteCalculerMontantTotalPartAssure(data, args);
  }

}

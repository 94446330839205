import {NgModule} from '@angular/core';

import {SpecialitesTableComponent} from './specialites-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [SpecialitesTableComponent],
    declarations: [SpecialitesTableComponent],
    providers: [],
})
export class SpecialitesTableModule {
}

import {Injectable} from '@angular/core';
import {IRestApi} from '@app/core/interfaces/rest/i-rest-api';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable, of} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {
    API_CLOTURE_POURSUITE_PEC,
    API_DELETE_PEC,
    API_ENREGISTRER_SOINS_HOSPITALISATION,
    API_GET_ACTES_DEMANDE_PRISE_EN_CHARGE,
    API_GET_ACTES_HOSPITILISATION_DEMANDE_PRISE_EN_CHARGE,
    API_GET_AFFECTIONS_DEMANDE_PRISE_EN_CHARGE,
    API_GET_ASSURE_NOUVELLE_DEMANDE_PRISE_EN_CHARGE,
    API_GET_LISTE_GARANT,
    API_GET_MEDECINS_DEMANDE_PRISE_EN_CHARGE,
    API_LISTE_DEMANDE_PRISE_EN_CHARGE,
    API_PRINT_BON_PEC,
    API_PRINT_BON_VIDE_PEC,
    API_RECUPERER_ACTES_ANALYSE,
    API_RECUPERER_ACTES_CLOTURE,
    API_RECUPERER_ADHERANT_HOSPITALISATION,
    API_RECUPERER_SOINS_HOSPITALISATION,
    API_SAVE_NOUVELLE_DEMANDE_PEC,
    API_TYPE_DOCUMENT_DEMANDE_PEC,
    API_VERIFICATION_ACTION,
    API_VERIFICATION_NOUVELLE_DEMANDE_PEC,
    CODE_OBJET_DEMANDE_PEC
} from '@app/core/constantes';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {OperatorEnum} from '@app/core/utilities/operators.utilities';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {DatePipe} from '@angular/common';

import * as moment from 'moment';

@Injectable({providedIn: 'root'})
export class DemandePriseChargeRestApiService extends SecurityStateService implements IRestApi {
    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;

    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

    dateSave = {
        // Date.now()
        dateDemande: this.datePipe.transform(moment().format(), 'dd/MM/yyyy'),
        dateDebutDemande: this.datePipe.transform(moment().format(), 'dd/MM/yyyy'),
        dateFinDemande: this.datePipe.transform(moment().format(), 'dd/MM/yyyy'),

        dateDebut: this.datePipe.transform(moment().weekday(-30).format(), 'dd/MM/yyyy'),
        dateFin: this.datePipe.transform(moment().format(), 'dd/MM/yyyy'),
    };


    requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = [];
        return of({body: body});
    }

    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        // sauvegarde de la date
        if (typeof args.formGroup.value.dateDebut !== 'string') {
            this.dateSave.dateDebutDemande = this.datePipe.transform(args.formGroup.value.dateDebut, 'dd/MM/yyyy'); // Ex: 09/07/2020
        }
        if (typeof args.formGroup.value.dateFin !== 'string') {
            this.dateSave.dateFinDemande = this.datePipe.transform(args.formGroup.value.dateFin, 'dd/MM/yyyy'); // Ex: 09/07/2020
        }

        // update du paramètre
        data.SortOrder = null;

        // console.log('U : ', this.storageUser.personne.idPrestataireMedical);
        // console.log('U ** : ', JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical);

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                IdPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                DateDemande: CommonUtilities.formatDateReverseToServer(args.formGroup.value.dateNow, this.datePipe),
                infoSearchDateDemande: {
                    Consider: true,
                    operatorToUse: OperatorEnum.BETWEEN,
                    intervalle: {
                        Debut: CommonUtilities.formatDateReverseToServer(args.formGroup.value.dateDebut, this.datePipe),
                        Fin: CommonUtilities.formatDateReverseToServer(args.formGroup.value.dateFin, this.datePipe),
                    },
                },
                infoSearchLibelleTypeStatut: {
                    consider: true,
                    operatorToUse: OperatorEnum.EQUAL,
                },
                // libelleTypeStatut: data.itemToSearch && data.itemToSearch.libelleTypeStatut ? data.itemToSearch.libelleTypeStatut : '',


                /*InfoSearchCivilite: {Consider: false},
                InfoSearchCreatedBy: {Consider: false},
                InfoSearchDataKey: {Consider: false},
                InfoSearchDateCreation: {Consider: false},
                InfoSearchDateDebutStatut: {Consider: false},
                InfoSearchDateDemande: {Consider: true},
                InfoSearchDateEmissionBon: {Consider: false},
                InfoSearchDateFinStatut: {Consider: false},
                InfoSearchDateMaj: {Consider: false},
                InfoSearchDateNaissance: {Consider: false},
                InfoSearchEntite: {Consider: false},
                InfoSearchFonction: {Consider: false},
                InfoSearchIdDemandePriseEnCharge: {Consider: false},
                InfoSearchIdDemandePriseEnChargeParent: {Consider: false},
                InfoSearchIdEntite: {Consider: false},
                InfoSearchIdMedecinConseil: {Consider: false},
                InfoSearchIdPersonne: {Consider: false},
                InfoSearchIdPrestataireMedical: {Consider: false},
                InfoSearchIdSouscriptionLigne: {Consider: false},
                InfoSearchIdTenant: {Consider: false},
                InfoSearchIdTypePrestataire: {Consider: false},
                InfoSearchIsDeleted: {Consider: false},
                InfoSearchLibelleTypeStatut: {Consider: false},
                InfoSearchLibelletypeprestataire: {Consider: false},
                InfoSearchMatricule: {Consider: false},
                InfoSearchModifiedBy: {Consider: false},
                InfoSearchMotifRejet: {Consider: false},
                InfoSearchNom: {Consider: false},
                InfoSearchNomGarant: {Consider: false},
                InfoSearchNumBonPriseEnCharge: {Consider: false},
                InfoSearchNumeroPiece: {Consider: false},
                InfoSearchPrenoms: {Consider: false},
                InfoSearchProfession: {Consider: false},
                InfoSearchRaisonSociale: {Consider: false},
                InfoSearchSexe: {Consider: false},
                InfoSearchSituationMatrimoniale: {Consider: false},
                InfoSearchStatut: {Consider: false},
                InfoSearchStatutValidation: {Consider: false},
                InfoSearchdateDemande: {IsOrderByField: true, Consider: false},
                InfoSearchidPrestataireMedical: {Consider: true},
                InfoSearchinfoSearchDateDemande: {Consider: true},*/
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_LISTE_DEMANDE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {

                    return response;
                }),
                tap(() => {
                    if (args.table) {
                        // (args.table as AtlantisTableComponent).refreshTable();
                    }
                })
            );
    }

    refreshRequeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;

        const sendData = {
            // ...data,
            itemToSearch: {
                // ...data.itemToSearch,
                IdPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                DateDemande: this.dateSave.dateDemande,
                infoSearchDateDemande: {
                    Consider: true,
                    operatorToUse: OperatorEnum.BETWEEN,
                    intervalle: {
                        Debut: this.dateSave.dateDebut,
                        Fin: this.dateSave.dateFin,
                    },
                },
                infoSearchLibelleTypeStatut: {
                    consider: true,
                    operatorToUse: OperatorEnum.EQUAL,
                },
            }
        };

        // console.log('sendData ', sendData);

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_LISTE_DEMANDE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {

                    /*console.log('test 2');
                    console.log('** ', response.body.items);
                    console.log('refreshActive 2', this.refreshActive);

                    if (response.body.items.length > 0) {
                      const acceptLunch = response.body.items.some((item) => item.libelleTypeStatut === STATUT_EN_ACCORD_PREALABLE);

                      console.log('acceptLunch 2', acceptLunch);

                      if (acceptLunch) {
                        this.lunchIntervalRefresh();
                      } else {
                        if (this.refreshActive) {
                          this.stopLunchIntervalRefresh();
                        }
                      }
                    }*/

                    return response;
                }),
                tap(() => {
                    if (args.table) {
                        // (args.table as AtlantisTableComponent).refreshTable();
                    }
                })
            );
    }

    rechercherAssureParMatricule(data: any): Observable<any> | Promise<any> | any {
        const {matricule, idPrestataireMedical} = data;

        const sendData = {
            itemToSearch: {
                matricule: matricule,
                infoSearchMatricule: {consider: true, operatorToUse: OperatorEnum.EQUAL}
            },
            itemsToSave: [
                {
                    idPrestataireMedical: idPrestataireMedical
                }]
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_GET_ASSURE_NOUVELLE_DEMANDE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
    }

    recupererMedecin(data?: string): Observable<any> | Promise<any> | any {
        const sendData = {
            itemToSearch: {
                idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                nomCompletMedecin: '',
            },
            TakeAll: true,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_GET_MEDECINS_DEMANDE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
    }

    recupererActes(data: any): Observable<any> | Promise<any> | any {
        const sendData = {
            itemToSearch: {
                idPrestataire: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                datePrestation: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'), // this.dateNow
                estActive: true,
                estGroupeSoin: false,
                idActeChambreGroupeSoin: 0,
            },
            TakeAll: true,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_GET_ACTES_DEMANDE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
    }

    recupererActesHospitalisation(data: any): Observable<any> | Promise<any> | any {
        const sendData = {
            itemToSearch: {
                idPrestataire: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                InfoSearchidPrestataire: {Consider: true}
            },
            TakeAll: true,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_GET_ACTES_HOSPITILISATION_DEMANDE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
    }

    recupererAffections(data: any): Observable<any> | Promise<any> | any {
        const sendData = {
            itemToSearch: {
                codeAffection: '',
            },
            TakeAll: true,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_GET_AFFECTIONS_DEMANDE_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
    }

    requeteSuppression(data: any, args?: any): Observable<any> | Promise<any> | any {
        // let id = null; try { id = data.id ? data.id : null; } catch (e) {}

        const sendData = {
            ItemsToSave: [
                {...data}
            ],
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_DELETE_PEC}`, sendData);
    }

    requeteVerificationNouvelleDemande(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                IdPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                InfoSearchIdPrestataireMedical: {Consider: true},
            }
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_VERIFICATION_NOUVELLE_DEMANDE_PEC}`, sendData, {...this.postArgsMute});
    }

    requeteRecupererTypeDocumentDemandePEC(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                codeObjet: CODE_OBJET_DEMANDE_PEC,
                InfoSearchcodeObjet: {Consider: true},
            }
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_TYPE_DOCUMENT_DEMANDE_PEC}`, sendData, {...this.postArgsMute});
    }

    requeteSaveDonneesFormulaireNouvelleDemande(data: any, args?: any): Observable<any> | Promise<any> | any {
        const {
            adherant,
            medecin,
            listActes,
            listAffections,
            listDocuments,
            descriptionAll,
            numeroBon,
            modalNouvelleDemandeHospitalisation,
            estHospitalisation,
        } = args;

        let formatDataItemToSave: any;

        if (modalNouvelleDemandeHospitalisation) {
            formatDataItemToSave = {
                idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                idSouscriptionLigne: adherant.idSouscriptionLigne,
                idTermeConvention: adherant.idTermeConvention,
                matricule: adherant.matricule,
                idPoolMedicalMedecin: medecin.idPoolMedical,
                idMedecin: medecin.idMedecin,
                estPoursuite: false,
                listDemandePriseEnChargeLigne: listActes,
                listAffection: listAffections,
                listDocumentExamens: listDocuments,
                descriptions: descriptionAll,
                numeroOrdre: numeroBon,

                estHospitalisation: estHospitalisation,

                numeroChambre: listActes[0].numeroChambre,
                serviceMedical: listActes[0].serviceMedical,

                estDemandeGroupeSoin: true,
            };

        } else {
            formatDataItemToSave = {
                idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                idSouscriptionLigne: adherant.idSouscriptionLigne,
                idTermeConvention: adherant.idTermeConvention,
                matricule: adherant.matricule,
                idPoolMedicalMedecin: medecin.idPoolMedical,
                idMedecin: medecin.idMedecin,
                estPoursuite: false,
                listDemandePriseEnChargeLigne: listActes,
                listAffection: listAffections,
                listDocumentExamens: listDocuments,
                descriptions: descriptionAll,
                numeroOrdre: numeroBon,
            };
        }

        const sendData = {
            bloqued: true,
            ItemsToSave: [
                formatDataItemToSave,
            ],
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_SAVE_NOUVELLE_DEMANDE_PEC}`, sendData);
    }

    requetePrintDocument(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                numBonPriseEnCharge: args.numBonPriseEnCharge,
                InfoSearchnumBonPriseEnCharge: {Consider: true}
            }
        };

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_PRINT_BON_PEC}`, sendData);
    }

    requetePrintBonVide(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                numBonPriseEnCharge: args.numBonPriseEnCharge,
                InfoSearchnumBonPriseEnCharge: {Consider: true}
            }
        };

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_PRINT_BON_VIDE_PEC}`, sendData);
    }

    requeteRecuperationListGarant(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {
            ...data,
            itemToSearch: {
                CanFilterByTenant: true,
                InfoSearchIdGarant: {Consider: true},
            }
        };

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_GET_LISTE_GARANT}`, sendData, {...this.postArgsMute});
    }

    requeteVerificationFaireAction(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
            }
        };

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_VERIFICATION_ACTION}`, sendData, {...this.postArgsMute});
    }

    requeteRecuperationActesCloture(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const {adherant} = args;
        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                InfoSearchidDemandePriseEnCharge: {Consider: true},
                idDemandePriseEnCharge: adherant.idDemandePriseEnCharge,
            }
        };

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ACTES_CLOTURE}`, sendData, {...this.postArgsMute});
    }

    requeteSaveDonneesFormulaireClotureDemande(data: any, args?: any): Observable<any> | Promise<any> | any {
        const {
            adherant,
            medecin,
            listActes,
            listAffections,
            listPrescriptions,
            listDocuments,
            descriptionsAll,
            acteGroupeSoin,
            chambreHospitalisation,
            currentChambreHospitalisation,
            currentTypeHospitalisation
        } = args;

        const sendData = {
            bloqued: true,
            ItemsToSave: [
                {
                    ...adherant,
                    accordPrealableDette: false,
                    descriptions: descriptionsAll,
                    estPoursuite: false,
                    idMedecin: medecin.idMedecin,
                    idPoolMedicalMedecin: medecin.idPoolMedical,
                    isDetailsVisible: false,
                    listAffection: listAffections,
                    listDemandePriseEnChargeLigne: listActes,
                    listDocumentExamens: listDocuments,
                    listPrescriptions: listPrescriptions,
                    miseEnHospitalisation: false,
                    selected: true,

                    idPrestataireCloture: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                    idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                    idSouscriptionLigne: adherant.idSouscriptionLigne,
                    idTermeConvention: adherant.idTermeConvention,
                    matricule: adherant.matricule,

                    // Ajout pour hospitalisation & autres
                    acteGroupeSoin: acteGroupeSoin,
                    chambreHospitalisation: chambreHospitalisation,
                    currentChambreHospitalisation: currentChambreHospitalisation,
                    currentTypeHospitalisation: currentTypeHospitalisation,

                    /**
                     *
                     */
                },
            ],
        };

        console.log('sendData : ', sendData);
        // return;

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_CLOTURE_POURSUITE_PEC}`, sendData);
    }

    requeteRecuperationAdherantHospitalisation(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const {adherant} = args;

        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                estChambre: false,
                idDemandePriseEnCharge: adherant.idDemandePriseEnCharge,
                InfoSearchestChambre: {Consider: true},
                InfoSearchidDemandePriseEnCharge: {Consider: true},
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ADHERANT_HOSPITALISATION}`, sendData, {...this.postArgsMute});
    }

    requeteRecuperationSoinsHospitalisation(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;

        const {adherant} = args;
        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                estRejeter: false,
                idDemandePriseEnCharge: adherant.idDemandePEC,
                idDemandePriseEnChargeLigne: adherant.idDemandePECLigne,
                InfoSearchestRejeter: {Consider: true},
                InfoSearchidDemandePriseEnCharge: {Consider: true},
                InfoSearchidDemandePriseEnChargeLigne: {Consider: true},
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_SOINS_HOSPITALISATION}`, sendData, {...this.postArgsMute});
    }

    requeteSaveDonneesFormulaireHospitalisationDemande(data: any, args?: any): Observable<any> | Promise<any> | any {

        const sendData = {
            bloqued: true,
            ItemsToSave: [
                {
                    ...args,
                },
            ],
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_ENREGISTRER_SOINS_HOSPITALISATION}`, sendData);
    }

    /**
     * View Tab Analyse
     */
    requeteRecuperationActesAnalyse(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {
            ...data, itemToSearch: {
                ...data.itemToSearch,
                InfoSearchidDemandePriseEnCharge: {Consider: true},
                idDemandePriseEnCharge: args.idDemandePriseEnCharge,
            }
        };

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ACTES_ANALYSE}`, sendData, {...this.postArgsMute});
    }

}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {ResultatDetailTableRestApiService} from './resultat-detail-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ResultatDetailTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: ResultatDetailTableRestApiService;
  @Autowired() formBuilder: FormBuilder;

  @Autowired() datePipe: DatePipe;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      interpretation: !!datas && !!datas.interpretation ? datas.interpretation : null,
    };

    return this.formBuilder.group({
      interpretation: [{value: data.interpretation, disabled: true}],
    });
  }

}

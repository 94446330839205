import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
    API_DOCUMENT_BD_DETAILS_PRISE_EN_CHARGE,
    API_DOCUMENT_BD_PRESTATION_DETAILS_PRISE_EN_CHARGE,
    STATUT_CLOTURE,
    STATUT_EN_ATTENTE_DE_CLOTURE
} from '@app/core/constantes';

@Injectable({providedIn: 'root'})
export class DocumentTableListeRestApiService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    const { currentDataUser, sectionAffichage } = args;

    let urlApi: string;
    let sendData: any;
    let type: string;

    if (sectionAffichage === 'demande') {
      if (currentDataUser.libelleTypeStatut === STATUT_CLOTURE || currentDataUser.libelleTypeStatut === STATUT_EN_ATTENTE_DE_CLOTURE) {
        urlApi = API_DOCUMENT_BD_PRESTATION_DETAILS_PRISE_EN_CHARGE;
        type = 'bd-prestation';
      } else {
        urlApi = API_DOCUMENT_BD_DETAILS_PRISE_EN_CHARGE;
        type = 'bd-list';
      }

        // update du paramètre
        data.SortOrder = null;

      if (type === 'bd-prestation') {
        sendData = {...data, itemToSearch: {
            ...data.itemToSearch,
            idDemandePriseEnCharge: currentDataUser.idDemandePriseEnCharge,
            InfoSearchidDemandePriseEnCharge: { Consider: true },
          }};
      } else if (type === 'bd-list') {
        sendData = {...data, itemToSearch: {
            ...data.itemToSearch,
            Entite: 'DemandePriseEnCharge',
            IdEntite: currentDataUser.idDemandePriseEnCharge,
            InfoSearchEntite: { Consider: true },
            InfoSearchIdEntite: { Consider: true },
          }};
      }
    }

    if (sectionAffichage === 'poursuite') {
      sendData = {...data, itemToSearch: {
          ...data.itemToSearch,
          Entite: 'DemandePriseEnCharge',
          IdEntite: currentDataUser.idDemandePriseEnCharge,
          InfoSearchEntite: { Consider: true },
          InfoSearchIdEntite: { Consider: true },
        }};
      urlApi = API_DOCUMENT_BD_DETAILS_PRISE_EN_CHARGE;
    }

    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${urlApi}`, sendData, {...this.postArgsMute});
  }
}

import {EventEmitter, Input, Output} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {ListFormErrorType} from '@shared/components/error/list-form-error/list-form-error.component';

export abstract class AbstractFormInputs {

  // @Input('id') id: string;
   @Input('id') id = Date.now() + CommonUtilities.randomValue();

  label = 'Libellé';
  @Input('label') set labels(label: string) {
    this.label = label;
    // this.id = 'inputs' + ClvTsUtilsCommonsJs.cleanSpace(label);
  }

  // @Input('disable') disable = false;

  @Input('required') required = false;
  @Input('ngModel') ngModel: any;
  @Input('useNgModel') useNgModel = false;
  @Output() ngModelChange = new EventEmitter<any>(false);

  @Input('placeholder') placeholder = '';
  @Input('infos') infos = '';

  @Input('minlength') minlength: string|number = 'inherit';

  @Input('maxlength') maxlength: string|number = 'inherit';

  @Input('min') min = 'inherit';

  @Input('max') max = 'inherit';

  @Input('submitted') submitted = false;

  @Input('email') email = false;

  @Input('control') control: AbstractControl = new FormControl('');
  defaultControl: AbstractControl = new FormControl('');

  @Input('errorTypes') errorTypes: ListFormErrorType[] = ['pattern', 'invalidEmail', 'mismatchedPasswords', 'maxLength', 'minLength', 'required', 'email'];

  @Input('messages') messages: {type: ListFormErrorType, message: string}[] = [];
  oldSubmmit = false;

  @Input('hasAction') hasAction = false;

  @Input('actionText') actionText = null;
  @Input('styleClass') styleClass = 'w-100';
  @Input('style') style;

  @Input('actionIcon') actionIcon = 'plus';

  @Input('actionColor') actionColor = 'primary';
  @Output() actionDeclenche = new EventEmitter<any>(false);
  @Output() onChange = new EventEmitter<any>(false);
  @Output() onClick = new EventEmitter<any>(false);
  @Output() onFocus = new EventEmitter<any>(false);
  @Output() onBlur = new EventEmitter<any>(false);
  @Output() onShow = new EventEmitter<any>(false);
  @Output() onHide = new EventEmitter<any>(false);
  @Output() onSelect = new EventEmitter<any>(false);

  getFullErrorCondition(control: AbstractControl, typ: ListFormErrorType[]) {
    if (control) {
      return CommonUtilities.getFullErrorCondition(control, typ);
    }
    return false;
  }
}

import {Injectable} from '@angular/core';

/**
 * Utiliaire d'horloge
 */
@Injectable({providedIn: 'root'})
export class HorlogeService {
  /**
   * Converti des secondes en Heures minutes secondes
   * @param totalSeconds
   */
  static convertSecondsToHourMinutesSeconds(totalSeconds: number):
      {hours: number, minutes: number, secondes: number} {
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds %= 3600;
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return {hours: hours, minutes: minutes, secondes: seconds};
  }
}


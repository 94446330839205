import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {Observable} from 'rxjs';
import {
    IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecuperationDonneeMetier,
    IRequeteCreationMetier,
    IRequeteModificationMetier
} from '@app/core/interfaces';
import {EvenementModaleRestApiService} from './evenement.modale.rest-api.service';

@Injectable({providedIn: 'root'})
export class EvenementModaleMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier, IRequeteCreationMetier, IRequeteModificationMetier, IRecuperationDonneeMetier {
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: EvenementModaleRestApiService;

  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    return formGroup.getRawValue();
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    let formGroup: FormGroup;
    const data: T | any = {				id: datas && datas.id ? datas.id : null,
				libelle: datas && datas.libelle ? datas.libelle : null,
};

    formGroup = this.formBuilder.group({				id: data.id,
				libelle: data.libelle,
});
    return formGroup;
    
  }

  requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteCreation(data, args);
  }

  requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteModification(data, args);
  }

  requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationDonnee(data, args);
  }
}

import {Component, OnInit} from '@angular/core';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {Autowired} from "@angular-ru/autowired";
import {ActesFactureMetierService} from "./actes-facture.metier.service";

@Component({
  selector: 'app-actes-facture',
  templateUrl: './actes-facture.component.html'
})
export class ActesFactureComponent extends SgiListLayoutComponent implements OnInit {

  @Autowired() recuperationListeDonneesMetier: ActesFactureMetierService;
  @Autowired() convertirFormulaireMetier: ActesFactureMetierService;
  @Autowired() initialiserFormulaireMetier: ActesFactureMetierService;
  @Autowired() requeteCreationMetier: ActesFactureMetierService;
  @Autowired() requeteModificationMetier: ActesFactureMetierService;
  @Autowired() metierService: ActesFactureMetierService;


  colDetails: any[];
  dataDetails: any[];

  constructor() {
    super();
  }

  ngOnInit() {
    this.cols = [
      {field: 'codeActeMedical', header: 'Code Acte'},
      {field: 'libelle', header: 'Lib. Acte'},
      {field: 'montantHT', header: 'Montant', type: () => 'price'},
      {field: 'quantite', header: 'Qte'},
      {field: 'montantDette', header: 'MT Dette', type: () => 'price'},
      {field: 'montantExclu', header: 'Montant Exclu', type: () => 'price'},
      {field: 'partAssureur', header: 'Part Assurance', type: () => 'price'},
      {field: 'partAssure', header: 'Part Assure', type: () => 'price'},
    ];

    this.notRowActions = [];
    this.tableActions = []

    this.colDetails = [
      {field: 'libelleTermeConvention', header: 'Produit'},
      {field: 'taux', header: 'Taux'},
      {field: 'montantActeBrut', header: 'Montant', type: () => 'price'},
      {field: 'quantite', header: 'Qte'},
      {field: 'montantHorsPEC', header: 'Part Hors Pec', type: () => 'price'},
      {field: 'montantExclu', header: 'Montant Exclu', type: () => 'price'},
      {field: 'partAssurance', header: 'MT. Assurance', type: () => 'price'},
      {field: 'partAssureNet', header: 'MT. Assure', type: () => 'price'},
    ];

  }

  recuperDonnees(data?: any) {
    super.recuperDonnees(data);
  }

  selectCurrentRow($event) {
    console.log("$event $event",$event)
    this.dataDetails = $event.facturationActes
  }

}

import {Component, Input} from '@angular/core';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';

@Component({
  selector: 'app-user-profil-image',
  template: `
    <img src="{{_url}}" class="mat-elevation-z2" alt="user-image">
  `,
  styles: [`
    img{
      border-radius: 50%;
      width: 95px;
      height: 95px;
      border: 1px solid rgba(0,0,0,.12);
    }
  `]
})
export class UserProfileImageComponent {
  _defaultUrl = 'assets/img/placeholders/avatar.jpg';
  _url: string;
  @Input('url') set url(value) {
    if (!ClvTsUtilsCommonsJs.stringIsUndefinedOrNull(value)) {
      this._url = value;
    } else {
      this._url = ClvTsUtilsCommonsJs.copy(this._defaultUrl);
    }
  }

  constructor () {
    this._url = ClvTsUtilsCommonsJs.copy(this._defaultUrl);
  }
}

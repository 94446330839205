import {NgModule} from '@angular/core';

import {PrescriptionMaladieChroniqueTableComponent} from './prescription-maladie-chronique-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports: [PrescriptionMaladieChroniqueTableComponent],
  declarations: [PrescriptionMaladieChroniqueTableComponent],
  providers: [],
})
export class PrescriptionMaladieChroniqueTableModule {
}

import {Directive, ElementRef, Input, OnInit} from '@angular/core';
import {DirectiveLazyUtilities} from '@app/core/utilities/directive-lazy.utilities';

// tslint:disable-next-line:directive-selector
@Directive({selector: '[showWhenScrollNotTop]'})
export class ShowWhenScrollNotTopDirective implements OnInit {
  private _showWhenScrollNotTop = 'main';
  /**
   * Indiquer l'id de l'élément auquel ecouter le scroll
   */
  @Input('showWhenScrollNotTop') set showWhenScrollNotTop(id: string) {
    if (id) {
      this._showWhenScrollNotTop = id;
    } else {
      this._showWhenScrollNotTop = 'main';
    }
  }

  /**
   * Inverse le comportement de la directive
   */
  @Input('inverseShowSens') inverseShowSens = false;
  element: ElementRef;

  constructor(el: ElementRef) {
    this.element = el;
    this.hide();
  }

  ngOnInit(): void {
    this.init();
    // excécute la methode d'affichage de l'élément
    DirectiveLazyUtilities.inView(this.element.nativeElement).subscribe(inv => {
      if (inv) {
        document.getElementById(this._showWhenScrollNotTop).addEventListener('scroll', () => {
          this.init();
        });
      }
    });

  }

  /**
   * Défini l'état initial de l'élément et s'exécute au scroll de la page
   */
  private init() {
    if (this.isOnTop()) {
      this.hide();
    } else {
      this.show();
    }
  }

  /**
   * Détermine si le document est au haut de la page
   */
  private isOnTop(): boolean {
    if (!this.inverseShowSens) {
      return document.getElementById(this._showWhenScrollNotTop).scrollTop === 0;
    } else {
      return document.getElementById(this._showWhenScrollNotTop).scrollTop !== 0;
    }
  }


  /**
   * Cache l'element
   */
  private hide() {
    this.element.nativeElement.style.visibility = 'hidden';
  }

  /**
   * affiche l'élément
   */
  private show() {
    this.element.nativeElement.style.visibility = 'visible';
  }
}

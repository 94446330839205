import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface ILanguageState {
  lang: string;
}

export function languageDefaultState(): ILanguageState {
  return {lang: 'fr'};
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'languageState'
})
export class LanguageStoreService extends Store<ILanguageState> {

  constructor() {
    super(languageDefaultState());
  }
}

import {Injectable} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import { SelectGarantRestApiService } from './select-garant.rest-api.service';

@Injectable({providedIn: 'root'})
export class SelectGarantMetierService implements IRecupererOptionsMetier {
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: SelectGarantRestApiService;


  recupererOptions(data, args?: any): Observable<Array<any>> {
    return this.restApi.requeteRecuperationListeDonnees(data, args)
    .pipe(map((reponse: HttpResponse<Array<any> | any>) => {

      if (!(reponse.body.items && reponse.body.items.length > 0)) {
        return reponse;
      }
      reponse.body.items.forEach((item: any) => {
        item.value = item.idClientGarant;
        item.label = item.raisonSociale;
      });
      return reponse.body.items;
    }));
  }
}

/**
 * Utilitaires de reporting
 */
export class RepportingUtilities {
  /**
   * Construit les données de la requête de reporting, selon la méthode SGI
   *
   * @param name
   * @param param
   */
  public static getSgiReportQuery(name: string, param: any) {
    return {
      ItemToSearch: {
        ReportName: name,
        Parameters: param
      }
    };
  }
}

import {NgModule} from '@angular/core';

import {PrescriptionTableComponent} from './prescription-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeComplementaireAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/composants/acte-complementaire-table/acte-complementaire-autocomplete/form/acte-complementaire-autocomplete.form.module';
import {PrescriptionAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/composants/prescription-table/prescription-autocomplete/form/prescription-autocomplete.form.module';

@NgModule({
    imports: [CommonModule, SharedModule, ActeComplementaireAutocompleteFormModule, PrescriptionAutocompleteFormModule],
  exports: [PrescriptionTableComponent],
  declarations: [PrescriptionTableComponent],
  providers: [],
})
export class PrescriptionTableModule {
}

import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'app-list-actions',
    templateUrl: './list-actions.component.html',
    styleUrls: ['./list-actions.component.scss']
})
export class ListActionsComponent {
    @Output() onChange = new EventEmitter(false);
}

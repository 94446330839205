import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'clv-clv-angular-directives-utilities',
  template: `
    <p>
      clv-angular-directives-utilities works!
    </p>
  `,
  styles: []
})
export class ClvAngularDirectivesUtilitiesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {FacturesRestApiService} from './factures.rest-api.service';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class FacturesMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier {

    @Autowired() datePipe: DatePipe;
    @Autowired() formBuilder: FormBuilder;
    @Autowired() restApi: FacturesRestApiService;

    medecins: any = [];
    actes: any = [];
    affections: any = [];

    convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
        const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
        v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
        return v;
    }

    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
        const dateNow = this.datePipe.transform(Date.now(), 'dd/MM/yyyy');

        const formGroup: FormGroup = new FormGroup({});
        formGroup.setControl('dateDebut', new FormControl(this.restApi.dateSave.dateDebut));
        formGroup.setControl('dateFin', new FormControl(this.restApi.dateSave.dateFin));
        formGroup.setControl('dateNow', new FormControl(dateNow));
        formGroup.setControl('searchKey', new FormControl(''));
        return formGroup;
    }


    initialiserFormulaireDemandePEC<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            Matricule: !!datas && !!datas.matricule ? datas.matricule : null,
            Nom: !!datas && !!datas.nom ? datas.nom : null,
            Prenoms: !!datas && !!datas.prenoms ? datas.prenoms : null,
            Genre: !!datas && !!datas.sexe ? datas.sexe : null,
            medecin: !!datas && !!datas.medecin ? datas.medecin : null,
        };

        return this.formBuilder.group({
            Matricule: data.Matricule,
            Nom: [{value: data.Nom, disabled: true}],
            Prenoms: [{value: data.Prenoms, disabled: true}],
            Genre: [{value: data.Genre, disabled: true}],
            medecin: data.medecin,
        });
    }


    recupererOptions(args?: any): Observable<Array<any>> {
        return;
        /* return this.restApi.requeteRecuperationListeDonnees(null, args)
         .pipe(map((reponse: HttpResponse<Array<any> | any>) => {
           if (!(reponse.body.items && reponse.body.items.length > 0)) {
             return reponse;
           }
           return reponse.body.items;
         })); */
    }

    neutraliserParents(data: any[]) {
        if (!data || !(data.length > 0)) {
            return data;
        }
        data.forEach(d => {
            d.parent = null;
            if (d.children) {
                this.neutraliserParents(d.children);
            }
        });
    }


}

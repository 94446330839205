import {AppSettings} from '@app/app.settings';

/**
 * Utilitaire des webservices
 */
export class WebUtilities {

    /**
     * Recupère l'url du serveur suivi de l'endpoint (Ex: http://localhost:8080/api/)
     */
    public static baseUrlApi() {
        return AppSettings.configs.urls.baseApi + AppSettings.configs.urls.api;
    }

    /**
     * Recupère l'url du serveur (Ex: http://localhost:8080/) ; pour la requète de recupération du token
     */
    public static baseUrlApiToken() {
        return AppSettings.configs.urls.baseApi;
    }

    /**
     * Retire le dernier slash de l'url s'il y en a
     * @param url
     */
    public static lessLastSlash(url: string): string {
        if (url[url.length - 1] === '/') {
            return url.substr(0, url.length - 1);
        }
        return url;
    }

    /**
     * Retourne l'url du serveur
     */
    public static baseUrl() {
        return AppSettings.configs.urls.baseApi;
    }
}

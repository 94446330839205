import {NgModule} from '@angular/core';

import {HospitalisationAutreTableComponent} from './hospitalisation-autre-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    exports: [HospitalisationAutreTableComponent],
    declarations: [HospitalisationAutreTableComponent],
    providers: [],
})
export class HospitalisationAutreTableModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ResultatExamenDossierTableMetierService} from './resultat-examen-dossier-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {ViewMenuResultatExamenEnum} from '@app/core/enums/view-menu-resultat-examen.enum';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';

@Component({
    selector: 'app-resultat-examen-dossier-table',
    templateUrl: './resultat-examen-dossier-table.component.html'
})

export class ResultatExamenDossierTableComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: ResultatExamenDossierTableMetierService;
    @Autowired() metierService: ResultatExamenDossierTableMetierService;
    @Autowired() metierServiceDemande: DemandePriseChargeMetierService;

    @Input() modalAssureSelected: any;
    @Input() responseResultatsExamen: any;
    formGroup: FormGroup;
    typeRechercheCombo: any[] = [];
    placeholderInputSearch: string;
    @Output() responseResultatsExamenEvent = new EventEmitter<any>();

    /** Détails tabs */
    currentDataTemp: any;
    responseResultatsExamenRealise: any;

    /**
     * View cloture demande
     */
    @Input() viewMenu: string;
    affichage = {
        dossierMedical: ViewMenuResultatExamenEnum.dossierMedical,
        clotureDemande: ViewMenuResultatExamenEnum.clotureDemande
    };
    medecinFormGroup: FormGroup;

    constructor() {
        super();
        this.cols = [
            {field: 'codeActeMedical', header: 'Code Acte Medical'},
            {field: 'libelle', header: 'Libelle'},
            {field: 'datePrestation', header: 'Date Prestation', type: () => 'date'},
            {field: 'dateRealisation', header: 'Date Realisation', type: () => 'date'},
        ];
        this.tableActions = [];
        this.notRowActions = [];
    }

    ngOnInit(): void {
        if (this.viewMenu === ViewMenuResultatExamenEnum.dossierMedical) {
            this.formGroup = this.metierService.initialiserFormulaire(null);
            this.initialiserTypeRechercheCombo();
            this.formatTableListAddIndex(this.responseResultatsExamen);

        } else if (this.viewMenu === ViewMenuResultatExamenEnum.clotureDemande) {

            this.medecinFormGroup = this.metierService.initialiserFormulaireMedecin(this.modalAssureSelected);
            this.metierServiceDemande.requeteRecuperationActesAnalyse(
                {}, {idDemandePriseEnCharge: this.modalAssureSelected.idDemandePriseEnCharge}).subscribe((r_ResultatsExamen) => {

                this.responseResultatsExamen = r_ResultatsExamen.body;

                const responseResultats: any[] = [];
                r_ResultatsExamen.body.items.forEach((v) => {
                   if (v.acteMedicalValeursExamen !== null) {
                       responseResultats.push(v);
                   }
                });
                this.responseResultatsExamen.items = responseResultats;

                // console.log('responseResultatsExamen : ', this.responseResultatsExamen);

                this.formatTableListAddIndex(this.responseResultatsExamen);

                this.responseResultatsExamenEvent.emit(this.responseResultatsExamen);
            });
        }
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    recuperationDonnees(data?: any) {
        const arg = {...this.args, lazyLoadEvent: data, successMute: true, assure: this.modalAssureSelected};
        this.args = {...arg};
        // tslint:disable-next-line:max-line-length
        this.metierService.requeteRecuperationListeDonnees(this.getAllRequestParam(), {...this.args}).subscribe((r_ResultatsExamen) => {
            this.formatTableListAddIndex(r_ResultatsExamen.body);
            this.responseResultatsExamenEvent.emit(r_ResultatsExamen.body);
        });
    }

    formatTableListAddIndex(responseData) {
        if (responseData === undefined) {
            this.dataSource = [];
            this.totalRecords = 0;
            return;
        }
        this.dataSource = responseData.items;
        this.totalRecords = responseData.count;
        if (this.dataSource && this.dataSource.length > 0) {
            this.dataSource.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }
    }

    initialiserTypeRechercheCombo() {
        this.placeholderInputSearch = 'Rechercher';
        this.typeRechercheCombo = [
            {label: 'Code acte médical', value: 'code_acte_medical'},
            {label: 'Libelle', value: 'libelle'},
            {label: 'Date prestation', value: 'date_prestation'},
            {label: 'Date réalisation', value: 'date_realisation'},

        ];
    }

    handleChangeTypeRecherche(event: any) {
        if (event.value === null) {
            this.placeholderInputSearch = 'Rechercher';

            this.formGroup.patchValue({inputSearch: null});

            // fonction de recherche assuré avec les valeurs null
            this.recuperationDonnees();
        }

        if (event.value === 'code_acte_medical') {
            this.placeholderInputSearch = 'Rechercher par code acte médical';
        }
        if (event.value === 'libelle') {
            this.placeholderInputSearch = 'Rechercher par libelle';
        }
        if (event.value === 'date_prestation' || event.value === 'date_realisation') {
            this.placeholderInputSearch = 'Rechercher par date';
        }

        this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue());
    }

    rechercheResultatsExamen(event) {
        if (this.formGroup.getRawValue().selectTypeSearch === null) {
            return false;
        }

        // fonction de recherche assuré avec les valeurs entrées
        this.recuperationDonnees();
    }

    /**
     *
     */

    /**
     * => return true, si l'objet est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }

    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = null;
        this.currentDataTemp = null;

        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
    }

    handleResponseResultatsExamenRealise(event: any) {
        this.responseResultatsExamenRealise = event;
    }

}

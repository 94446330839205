/**
 * Configurations de l'aplication.
 * Une instance de cette classe est crée dans l'application et contient les parametres de l'application.
 */
export class Settings {
    constructor(public name?: string,
                public miniName?: string,
                public description?: string,
                public logo?: string,
                public loadingSpinner?: boolean,
                public fixedHeader?: boolean,
                public fixedSidenav?: boolean,
                public fixedSidenavUserContent?: boolean,
                public fixedFooter?: boolean,
                public sidenavIsOpened?: boolean,
                public sidenavIsPinned?: boolean,
                public menu?: string,
                public menuType?: string,
                public theme?: string,
                public sidebarTheme?: string,
                public rtl?: boolean,
                public tentativesConnexion?: number,
                public deconnexionApresXSecondes?: number,
                public menuVertical?: any[],
                public menuHorizontal?: any[],
                public defaultLanguage?: string,
                public isBloqued?: boolean
                ) { }
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {PoursuitePriseChargeModaleFeatureComponent} from './poursuite-prise-charge-modale.feature.component';
import {MedecinFormModule} from '../../demande-prise-charge/form/medecin/form/medecin.form.module';
import {InformationAssureTableModule} from '@app/fonctionnalites/demande-pec/composants/information-assure-table/information-assure-table.module';
import {MedecinActeTableModule} from '@app/fonctionnalites/demande-pec/composants/medecin-acte-table/medecin-acte-table.module';
import {ActeComplementaireTableModule} from '@app/fonctionnalites/demande-pec/composants/acte-complementaire-table/acte-complementaire-table.module';
import {ListAffectionTableListeModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/list-affection-table/liste/list-affection-table.liste.module';
import {PrescriptionTableModule} from '@app/fonctionnalites/demande-pec/composants/prescription-table/prescription-table.module';
import {DemandePriseChargeModaleFeatureModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/modale/demande-prise-charge-modale.feature.module';
import {DemandePecDocumentModule} from '@app/fonctionnalites/demande-pec/composants/demande-pec-document/demande-pec-document.module';
import {DemandeDossierMedicalTableModule} from '@app/fonctionnalites/demande-pec/composants/demande-dossier-medical-table/demande-dossier-medical-table.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        MedecinFormModule,
        InformationAssureTableModule,
        MedecinActeTableModule,
        ActeComplementaireTableModule,
        ListAffectionTableListeModule,
        PrescriptionTableModule,
        DemandePriseChargeModaleFeatureModule,
        DemandePecDocumentModule,
        DemandeDossierMedicalTableModule,
    ],
  exports: [
      PoursuitePriseChargeModaleFeatureComponent,
  ],
  declarations: [
      PoursuitePriseChargeModaleFeatureComponent,
  ],
  providers: [],
})
export class PoursuitePriseChargeModaleFeatureModule {
}

import {Injectable, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Autowired} from '@angular-ru/autowired';
import {ThemeStateService} from '@app/core/states/theme/theme.state.service';
import {Settings} from '@app/app.settings.model';
import {environment} from '../environments/environment';

/**
 * Service de configuration de l'application
 */
// @dynamic
@Injectable()
export class AppSettings implements OnInit {
    static me: Settings;

    /**
     * Reçoit les configurations de l' application
     */
    static settings: Settings;

    /**
     * Reçoit les informations du fichier de configuration json dans les assets
     */
    static configs: any;

    /**
     * Reçoit les configurations de l' application
     */
    public settings: Settings;

    /**
     * Reçoit les informations du fichier de configuration json dans les assets
     */
    public configs: any;

    /**
     * Service d'état du theme de l'application
     */
    @Autowired() themeStateService: ThemeStateService;

    constructor(private http: HttpClient) {
        AppSettings.settings = AppSettings.me = this.settings = new Settings();
    }

    ngOnInit(): void {
        this.settings.theme = this.themeStateService.getTheme();
    }

    /**
     * Récupère les données de configuration des fichiers JSON dans les assets et les passent à l'objet de configuration de l'appliction
     *
     * @param config
     */
    setSettings(config: any) {
        this.settings = new Settings(
            config.app.name,   // theme name
            config.app.miniName,   // theme name
            config.app.description,   // theme name
            config.app.imageModel,   // theme name
            config.app.loadingSpinner,       // loadingSpinner
            config.app.fixedHeader,      // fixedHeader
            config.app.sidenav,      // fixedSidenav
            config.app.fixedSidenavUserContent,      // fixedSidenavUserContent
            config.app.fixedFooter,      // fixedFooter
            config.app.sidenavIsOpened,       // sidenavIsOpened
            config.app.sidenavIsPinned,       // sidenavIsPinned
            config.app.menu, // horizontal , vertical
            config.app.menuType,  // default, compact, mini
            config.theme.default,  // thm-indigo-light, thm-teal-light, thm-red-light, thm-gray-light, thm-blue-dark, thm-green-dark, thm-pink-dark, thm-gray-dark, thm-blue-light
            config.theme.sidebarDefault,  // sb-light, sb-dark
            config.app.rtl,       // true = rtl, false = ltr,
            config.app.tentativesConnexion,
            config.app.deconnexionApresXSecondes,
            config.menuVertical,
            config.menuHorizontal,
            config.language.default,
        );
    }

    /**
     * Charge les données de configuration des fichiers JSON dans les assets.
     *
     * Emmet une exception <code>Could not load file ...</code> lorsque le fichier JSON est introuvable ou qu'il comporte des erreurs
     */
    load() {
        const jsonFile = `assets/config/config.${environment.config}.json`;
        return new Promise<void>((resolve, reject) => {
            this.http.get(jsonFile).toPromise().then((response: any) => {
                AppSettings.configs = this.configs = response;
                this.setSettings(response);
                resolve();
            }).catch((response: any) => {
                reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}


import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {InformationAssureTableRestApiService} from './information-assure-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class InformationAssureTableMetierService implements IRecuperationListeDonneesMetier {
    @Autowired() restApi: InformationAssureTableRestApiService;
    @Autowired() formBuilder: FormBuilder;


    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteRecuperationListeDonnees(data, args);
    }

    initialiserFormulaireAssure<T>(datas: T | any, args?: any): FormGroup {
        const data: any = {
            nomPrenoms: null,
            dateNaissance: null,
            genre: null,
            dateDemande: null,
            prestataire: null,
            // prestataire: !!datas && !!datas.medecin ? datas.medecin : null,
        };

        return this.formBuilder.group({
            nomPrenoms: [{value: data.nomPrenoms, disabled: true}],
            dateNaissance: [{value: data.dateNaissance, disabled: true}],
            genre: [{value: data.genre, disabled: true}],
            dateDemande: [{value: data.dateDemande, disabled: true}],
            prestataire: [{value: data.prestataire, disabled: true}],
        });
    }

    requeteGetAssure(data: any, args?: any): Observable<any> | Promise<any> | any {
        return this.restApi.requeteGetAssure(data, args);
    }

}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {ListesStoreService} from '@app/core/states/listes/listes.store.service';
import {ListesQueryService} from '@app/core/states/listes/listes.query.service';

/**
 * Service d'état des paramètres de listes de l' application
 */
@Injectable({providedIn: 'root'})
export class ListesStateService {
  @Autowired() store: ListesStoreService;
  @Autowired() query: ListesQueryService;

  /**
   * Définir le mode de liste. Peut être table ou grid
   *
   * @param mode
   */
  setListeMode(mode: 'table'|'grid') {
    window.localStorage.setItem('listMode', mode);
    const perPage = window.localStorage.getItem('perPage');
    this.store.update({listMode: mode, perPage: +perPage});
  }

  /**
   * Recuperer le mode de liste
   */
  getListeMode(): 'table'|'grid' {
    return this.query.__store__._value().listMode;
  }

  /**
   * Définir le nombre d'éléments par page des listes.
   *
   * @param per
   */
  setPerPage(per: number) {
    window.localStorage.setItem('perPage', `${per}`);
    const listMode: 'table'|'grid' = window.localStorage.getItem('listMode') as 'table'|'grid';
    this.store.update({listMode: listMode || 'table', perPage: per});
  }

  /**
   * Recuperer le nombre d'éléments par page d'une liste
   */
  getPerPage(): number {
    return this.query.__store__._value().perPage;
  }

  /**
   * Initialise l'état
   */
  init() {
    try {
      let perPage = window.localStorage.getItem('perPage');
      if (!(+perPage > 0)) {
        perPage = 5 + '';
      }
      let listMode: 'table'|'grid' = window.localStorage.getItem('listMode') as 'table'|'grid';
      if (listMode !== 'table' && listMode !== 'grid') {
        listMode = 'table';
      }
      this.setPerPage(+perPage);
      this.setListeMode(listMode);
    } catch (e) {
    }
  }
}

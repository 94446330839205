import {Injectable} from '@angular/core';
import {Store, StoreConfig} from '@datorama/akita';

export interface ISecurityState {
  token: string;
  currentUser: any;
  themeApp: any;
  listGarant: any;
}

export function defaultSecurityState(): ISecurityState {
  return {
    token: null,
    currentUser: null,
    themeApp: null,
    listGarant: null,
  };
}

@Injectable({providedIn: 'root'})
@StoreConfig({
  name: 'security'
})
export class SecurityStoreService extends Store<ISecurityState> {

  constructor() {
    super(defaultSecurityState());
  }
}

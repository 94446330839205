import {Component} from '@angular/core';

/**
 * @deprecated
 */
@Component({
  selector: 'clv-overlays',
  template: `
<!--    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>-->
  `
})
export class ClvOverlaysComponent {
}

import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';

import {AgmCoreModule} from '@agm/core';
import {PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface, PerfectScrollbarModule} from 'ngx-perfect-scrollbar';
import {SharedModule} from '@shared/shared.module';
import {PipesModule} from './theme/pipes/pipes.module';
import {routing} from './app.routing';

import {AppSettings} from './app.settings';
import {AppComponent} from './app.component';
import {PagesComponent} from './pages/pages.component';
import {NotFoundComponent} from './pages/errors/not-found/not-found.component';

import {TopInfoContentComponent} from './theme/components/top-info-content/top-info-content.component';
import {SidenavComponent} from './theme/components/sidenav/sidenav.component';
import {VerticalMenuComponent} from './theme/components/menu/vertical-menu/vertical-menu.component';
import {HorizontalMenuComponent} from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import {FlagsMenuComponent} from './theme/components/flags-menu/flags-menu.component';
import {FullScreenComponent} from './theme/components/fullscreen/fullscreen.component';
import {MessagesComponent} from './theme/components/messages/messages.component';
import {UserMenuComponent} from './theme/components/user-menu/user-menu.component';
import {StoreModule} from '@ngrx/store';
import {TranslateModule} from '@ngx-translate/core';
import {ConfirmationService} from 'primeng/api';
import {loadInterceptorProvider, sgiRequestInterceptorProvider} from './core/intercepteurs';
import {RestService} from './core/services/rest.service';
import {HttpClientModule} from '@angular/common/http';
import {ServiceWorkerModule} from '@angular/service-worker';
import {environment} from '../environments/environment';
import {SocketIoConfig, SocketIoModule} from 'ngx-socket-io';
import {TopBarInfosComponent} from './theme/components/top-bar-infos/top-bar-infos.component';
import {HeureServeurHorlogeModule} from './fonctionnalites/administration/heure-serveur/horloge/heure-serveur-horloge-feature.module';
import {EcranVeilleComponent} from './theme/components/ecran-veille/ecran-veille.component';
import {VeilleButtonComponent} from './theme/components/veille-button/veille-button.component';

import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';
import {ConnexionModule} from '@app/fonctionnalites/authentification/connexion/connexion.module';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
    wheelPropagation: true,
    suppressScrollX: true
};

export function appSettingFactory(appSetting: AppSettings) {
    const appSettingFactoryResolver = () => appSetting.load();
    return appSettingFactoryResolver;
}

const socketConfig: SocketIoConfig = {url: environment.baseUrl, options: {}};

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
        }),
        PerfectScrollbarModule,
        SocketIoModule.forRoot(socketConfig),
        SharedModule,
        PipesModule,
        routing,
        StoreModule.forRoot({}),
        TranslateModule.forRoot(),
        ServiceWorkerModule.register('ngsw-worker.js', {enabled: environment.production}),
        HeureServeurHorlogeModule,
        LoadingBarHttpClientModule,
        ConnexionModule
    ],
    declarations: [
        AppComponent,
        PagesComponent,
        NotFoundComponent,
        TopInfoContentComponent,
        SidenavComponent,
        VerticalMenuComponent,
        HorizontalMenuComponent,
        FlagsMenuComponent,
        FullScreenComponent,
        MessagesComponent,
        UserMenuComponent,
        TopBarInfosComponent,
        EcranVeilleComponent,
        VeilleButtonComponent,
    ],
    providers: [
        AppSettings,
        {
            provide: APP_INITIALIZER,
            useFactory: appSettingFactory,
            deps: [AppSettings], multi: true
        },
        {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
        // authorizationInterceptorProvider,
        sgiRequestInterceptorProvider,
        loadInterceptorProvider,
        // errorMessageInterceptorProvider,
        RestService,
        ConfirmationService
    ],
    exports: [],
    entryComponents: [EcranVeilleComponent],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}


import {NgModule} from '@angular/core';

import {ListActeTableListeComponent} from './list-acte-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/acte-autocomplete/form/acte-autocomplete.form.module';

@NgModule({
    imports: [CommonModule, SharedModule, ActeAutocompleteFormModule],
  exports: [ListActeTableListeComponent],
  declarations: [ListActeTableListeComponent],
  providers: [],
})
export class ListActeTableListeModule {
}

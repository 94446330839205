import {Injectable} from '@angular/core';
import * as jsPDF from 'jspdf';
import * as xlsx from 'xlsx';
import * as FileSaver from 'file-saver';
import {Autowired} from '@angular-ru/autowired';
import {AlertService} from '@app/core/services/alert.service';

/**
 * Service d'export de fichiers
 */
@Injectable({providedIn: 'root'})
export class FileExportService {
  @Autowired() alerteService: AlertService;

  /**
   * Exporte les données au format pdf
   * @param columns
   * @param data
   * @param fileName
   */
  exportPdf(columns: Array<{title: string, dataKey: string}>, data: any, fileName: string = 'primengTable') {
    try {
      const doc = new jsPDF.default(0, 0);
      doc.autoTable(columns, data);
      doc.save(fileName + '.pdf');
    } catch (e) {
      this.alerteService.showError('Vérrifiez que vous fournissez des données correctes à exporter').show();
    }
  }

  /**
   * Converti les colonnes au format pour l'exportation en PDF
   * @param cols
   */
  converColumnsToExportPDF(cols: {header: string, field: string}[]) {
    return cols.map(col => ({title: col.header, dataKey: col.field}));
  }

  /**
   * Exporte les données au format excel
   * @param data
   * @param fileName
   */
  exportExcel(data: any, fileName: string = 'primengTable') {
    try {
      const worksheet = xlsx.utils.json_to_sheet(data);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, fileName);
    } catch (e) {
      this.alerteService.showError('Vérrifiez que vous fournissez des données correctes à exporter').show();
    }
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GravatarComponent} from '@shared/components/gravatar/gravatar.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatButtonModule,
    ],
    declarations: [
        GravatarComponent,
    ],
    exports: [
        GravatarComponent,
    ]
})
export class GravatarComponentModule {
}

import {NgModule} from '@angular/core';

import {PrescriptionTableListeComponent} from './prescription-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [PrescriptionTableListeComponent],
  declarations: [PrescriptionTableListeComponent],
  providers: [],
})
export class PrescriptionTableListeModule {
}

import {Component, EventEmitter, Input, Output} from '@angular/core';
@Component({
  selector: 'app-delete-button',
  template: `
    <ng-container *ngIf="isFlat">
      <button mat-icon-button [matTooltip]="title"
              [disabled]="disabled" (click)="handleClick.emit($event)"
              [ngClass]="'danger-color'">
        <mat-icon>{{icon}}</mat-icon>
      </button>
    </ng-container>
    <ng-container *ngIf="!isFlat">
      <button mat-raised-button
              [disabled]="disabled" (click)="handleClick.emit($event)"
              [ngClass]="'bg-danger'">
        <mat-icon>{{icon}}</mat-icon>&nbsp;{{title}}
      </button>
    </ng-container>
  `
})
export class DeleteButtonComponent {
  @Input('isFlat') isFlat = false;
  @Input('icon') icon = 'delete';
  @Input('title') title = 'Supprimer';
  @Input('disabled') disabled = false;
  @Output() handleClick = new EventEmitter(false);
}

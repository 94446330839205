import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DemandePriseChargeMetierService} from '../../demande-prise-charge/demande-prise-charge.metier.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {AlertService} from '@app/core/services/alert.service';
import {ClvFileModel, ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {FORMULAIRE_INVALIDE_MESSAGE, SelectionCombo} from '@app/core/constantes';
import {logAction} from "@datorama/akita";

@Component({
    selector: 'app-demande-pec-document',
    templateUrl: './demande-pec-document.component.html',
    styleUrls: ['./demande-pec-document.component.css']
})
export class DemandePecDocumentComponent extends SgiListLayoutComponent {
    @Autowired() formBuilder: FormBuilder;
    @Autowired() metierService: DemandePriseChargeMetierService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() alertService: AlertService;

    @Input() listDocumentAjouter: any[] = [];
    @Output() listDocumentAjouterEvent = new EventEmitter<any[]>();

    @Input() view = false;
    @Input() delete = false;

    documentForm: FormGroup;
    fileModel: ClvFileModel[] = [];
    typeDocumentResponse: any[] = [];
    typeDocumentCombo: SelectionCombo[] = [];
    listDocumentTable: any[] = [];


    constructor() {
        super();

        this.tableActions = [];
        this.cols = [
            {field: 'fileTableType', header: 'Type'},
            {field: 'fileTableTitre', header: 'Titre'},
        ];
    }

    ngOnInit() {
        this.recuperationTypeDocument();
        this.initFormulaire();

        if (this.view) {
            this.tableActions = [...this.tableActions, this.consulterAction];
        }
        if (this.delete) {
            this.tableActions = [...this.tableActions, this.supprimerAction];
        }

        this.formatTableListAddIndex(this.listDocumentAjouter);
    }

    recuperationTypeDocument() {
        const recupererTypeDocumentDemandePEC$ = this.metierService.recupererTypeDocumentDemandePEC({});
        recupererTypeDocumentDemandePEC$.subscribe(res => {
            this.typeDocumentResponse = res.body.items;

            this.typeDocumentResponse.forEach((item) => {
                this.typeDocumentCombo = [
                    ...this.typeDocumentCombo,
                    {label: item.libelleDocument, value: item.libelleDocument},
                ];
            });
        });

    }

    initFormulaire() {
        this.documentForm = this.metierService.initialiserFormulaireDocument(null);
    }

    formatTableListAddIndex(responseData) {
        if (responseData === undefined) {
            this.listDocumentTable = [];
            this.totalRecords = 0;
            return;
        }

        this.listDocumentTable = [...this.listDocumentTable, ...responseData];
        this.totalRecords = null;
        if (this.listDocumentTable && this.listDocumentTable.length > 0) {
            this.listDocumentTable.forEach((data, index) => {
                data.id_key = index + 1;

                /** contruct data table list */
                if (data.isDeleted !== undefined) {
                    data.fileTableType = data.libelle;
                    data.fileTableTitre = data.observations;
                    data.fileTableUrl = data.pieceJointe;
                    data.fileTableExtension = data.extension;
                }
            });
        }
    }

    handleFileChange(event) {
        this.documentForm.patchValue({
            fichier: event[0],
        });
    }

    handleListDocumentAjouter(event: any[]) {
        this.listDocumentAjouter = event;
    }

    ajouterDocument() {
        let ErrorAlert: any;
        if (!this.documentForm.getRawValue().type ||
            !this.documentForm.getRawValue().titre ||
            !this.documentForm.getRawValue().fichier) {
            ErrorAlert = this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE);
            ErrorAlert.show();
            return;
        }

        const find = this.listDocumentAjouter.find((elt) => {
            if (elt.isDeleted !== undefined && elt.isDeleted === false) {
                return elt.fileTableType === this.documentForm.getRawValue().type;
            }
            if (elt.isDeleted === undefined) {
                return elt.type.libelleDocument === this.documentForm.getRawValue().type;
            }
        });
        if (find !== undefined) {
            ErrorAlert = this.alertService.showError('Ce type de document a déjà été ajouté');
            ErrorAlert.show();
            return;
        }

        const findElt = this.typeDocumentResponse.find((elt) => elt.libelleDocument === this.documentForm.getRawValue().type);
        this.documentForm.patchValue({
            type: findElt
        });

        this.listDocumentAjouter = [
            ...this.listDocumentAjouter,

            /** contruct data table list */
            {
                ...this.documentForm.getRawValue(),
                fileTableType: this.documentForm.getRawValue().type.libelleDocument,
                fileTableTitre: this.documentForm.getRawValue().titre,
                fileTableUrl: this.documentForm.getRawValue().fichier.fullDataUrl, // downloadUrl
                fileTableExtension: this.documentForm.getRawValue().fichier.extension,
            }
        ];

        this.listDocumentTable = [
            ...this.listDocumentTable,

            /** contruct data table list */
            {
                fileTableType: this.documentForm.getRawValue().type.libelleDocument,
                fileTableTitre: this.documentForm.getRawValue().titre,
                fileTableUrl: this.documentForm.getRawValue().fichier.fullDataUrl,
                fileTableExtension: this.documentForm.getRawValue().fichier.extension,
            }
        ];

        if (this.listDocumentTable && this.listDocumentTable.length > 0) {
            this.listDocumentTable.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }

        this.fileModel = [];
        this.documentForm.reset();

        this.listDocumentAjouterEvent.emit(this.listDocumentAjouter);
    }

    /**
     * Effectue une demande de visualisation du document
     * @param data
     */
    consulterDocument(data: any) {
        const shower = this.alertService.showWarning(this.messageDemandeVisualisation);
        shower.accept = () => {
            const arrayConstruct: any[] = [];

            /*console.log(data.fileTableExtension);
            if (data.fileTableExtension !== 'pdf' || data.fileTableExtension !== '.pdf') {
                window.open(data.fileTableUrl);
                return;
            }*/

            if (data.isDeleted !== undefined) {
                arrayConstruct.push({
                    File: data.fileTableUrl,
                    Name: `-- Type: ${data.fileTableType}, Titre: ${data.fileTableTitre}`,
                });
            } else {
                arrayConstruct.push({
                    File: data.fileTableUrl,
                    Name: `-- Type: ${data.fileTableType}, Titre: ${data.fileTableTitre}`,
                });
            }

            this.gestionnaireDeModales[0].datasourceSelected = arrayConstruct;
            this.gestionnaireDeModales[0].display = true;

            this.listDocumentAjouterEvent.emit(this.listDocumentAjouter);
        };
        shower.reject = () => {
        };
        shower.show();
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        let shower: any;
        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.listDocumentAjouter.forEach((elt) => {
                if ((elt.fileTableType === data.fileTableType) && elt.isDeleted !== undefined) {
                    elt.isDeleted = true;
                }
            });

            const findElt = this.listDocumentAjouter.find((elt) => {
                if (elt.type && elt.type.libelleDocument) {
                    return elt.type.libelleDocument === data.fileTableType && data.isDeleted === undefined;
                }
                return undefined;
            });

            if (findElt !== undefined) {
                this.listDocumentAjouter = [...this.listDocumentAjouter.filter((item) => item !== findElt)];
            }

            this.listDocumentTable = [...this.listDocumentTable.filter((item) => item !== data)];

            this.listDocumentAjouterEvent.emit(this.listDocumentAjouter);
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

}

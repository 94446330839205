import {Component} from '@angular/core';
import {PageLayoutComponent} from '@shared/behaviors/page-layout/page-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {PERMISSION_PRESTATION_DEMANDEPEC_POURSUITE} from '@app/core/constantes';

@Component({
    selector: 'app-demande-pec',
    templateUrl: './demande-pec.component.html'
})
export class DemandePecComponent extends PageLayoutComponent {

    permissionViewTabPoursuite: boolean;

    constructor() {
        super();
        this.title = 'Demande PEC';
        this.icon = 'description';
        this.hasToolbar = false;

        this.permissionViewTabPoursuite = CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_DEMANDEPEC_POURSUITE);
    }
}

import {Component, OnInit} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC, PERMISSION_PRESTATION_FACTURE_MODIFIERFACTURE,} from '@app/core/constantes';
import {FormBuilder} from '@angular/forms';
import {PecEnAttenteMetierService} from './pec-en-attente.metier.service';

@Component({
    selector: 'app-pec-en-attente-liste-feature',
    templateUrl: './pec-en-attente-liste.feature.component.html'
})

export class PecEnAttenteListeFeatureComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() suppressionDonneeMetier: PecEnAttenteMetierService;
    @Autowired() recuperationListeDonneesMetier: PecEnAttenteMetierService;
    @Autowired() metierService: PecEnAttenteMetierService;

    tabActive = 'Actes';
    currentDataTemp: any;

    @Autowired() formBuilder: FormBuilder;

    affichModalPrintBon = false;
    tableSelectedElement: any;


    columns: any = [];
    placeholderInputSearch: string;
    typeRechercheCombo = [
        {label: 'N°Prise en charge', value: 'numBonPriseEnCharge'},
        {label: 'Garant', value: 'raisonSocialeGarant'},
    ];

    finResponse: boolean;

    constructor() {
        super();
        this.cols = [
            {field: 'numBonPriseEnCharge', header: 'N° Prise en charge'},
            {field: 'raisonSocialeGarant', header: 'Garant'},
            {field: 'montantBrut', header: 'Montant', type: () => 'price'},
            {field: 'partAssuranceBrut', header: 'Part Assurance Brut', type: () => 'price'},
            {field: 'ticketModerateur', header: 'Ticket Moderateur', type: () => 'price'},
            {field: 'montantDette', header: 'Montant Dette', type: () => 'price'},
            {field: 'partAssurance', header: 'Net à Payer', type: () => 'price'},
        ];
        this.notRowActions = [];
        this.tableActions = [];
    }

    ngOnInit() {
        super.ngOnInit();
        this.recuperDonnees();
        this.placeholderInputSearch = 'numBonPriseEnCharge';

        if (CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_FACTURE_MODIFIERFACTURE)) {
            this.tableActions = [...this.tableActions, this.modifierAction];
        }
    }


    selectChange(event) {
        this.recuperDonnees({idClientGarant: event.value});
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {
            ...this.args,
            // dateNow: this.formGroup.value.dateNow,
            // dateDebut: this.formGroup.value.dateDebut,
            // dateFin: this.formGroup.value.dateFin
        };
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(null);
    }


    /**
     * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
     */
    selectCurrentRow(data?: any) {
        this.currentRow = data;
        this.currentDataTemp = this.currentRow;
        this.tabActive = '';
    }

    /**
     * => return true, si le tableau est vide
     */
    isEmptyObject(obj) {
        return CommonUtilities.isEmptyObject(obj);
    }


    getTabChange(event) {
        this.currentRow = null;
        this.currentRow = this.currentDataTemp;
        this.tabActive = event.originalEvent.target['innerText'];
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;

        if (data.libelleTypeStatut === 'Cloturé') {
            shower = this.alertService.showError(ERROR_MESSAGE_SUPPRESSION_DEMANDE_PEC);
            shower.show();
            return;
        }

        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            this.metierService.requeteSuppression(data).subscribe(() => {
                // this.metierService.requeteRecuperationListeDonnees({});
                this.recuperDonnees();
            });
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    rechercheFacture($event: KeyboardEvent) {

        if (this.placeholderInputSearch === 'numBonPriseEnCharge') {
            this.recuperDonnees({numBonPriseEnCharge: this.formGroup.getRawValue().searchKey});
        } else if (this.placeholderInputSearch === 'raisonSocialeGarant') {
            this.recuperDonnees({raisonSocialeGarant: this.formGroup.getRawValue().searchKey});
        }
    }

    handleChangeTypeRecherche($event: any) {
        this.placeholderInputSearch = $event.value;
    }

    fermerModifierDialogue() {
        this.recuperDonnees();
    }

    finResponseEvent(event) {
        this.finResponse = event;
    }
}

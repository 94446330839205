import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {IListesState, ListesStoreService} from './listes.store.service';

@Injectable({providedIn: 'root'})
export class ListesQueryService extends Query<IListesState> {
  listMode$ = this.select('listMode');
  perPage$ = this.select('perPage');

  constructor(protected store: ListesStoreService) {
    super(store);
  }
}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {DemandePecDocumentComponent} from '@app/fonctionnalites/demande-pec/composants/demande-pec-document/demande-pec-document.component';
import {ListDocumentTableListeModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/list-document-table/liste/list-document-table.liste.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        ListDocumentTableListeModule,
    ],
  exports: [DemandePecDocumentComponent],
  declarations: [DemandePecDocumentComponent],
  providers: [],
})
export class DemandePecDocumentModule {
}

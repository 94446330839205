import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {BehaviorSubject, interval, Observable, Subscription} from 'rxjs';

@Component({
  selector: 'app-deminuteur',
  template: `
    <div class="deminuteur warn-color" [ngClass]="styleClass">
      <div class="heures" [ngClass]="heureStyleClass">{{heures$ | async}}</div>
      <div class="separateur" [ngClass]="separateurStyleClass">&nbsp;:&nbsp;</div>
      <div class="minutes" [ngClass]="minuteStyleClass">{{minutes$ | async}}</div>
      <div class="separateur" [ngClass]="separateurStyleClass">&nbsp;:&nbsp;</div>
      <div class="secondes" [ngClass]="separateurStyleClass">{{secondes$ | async}}</div>
    </div>
  `,
  styles: [`
      .deminuteur {
        display: flex;
        flex-direction: row;
        font-size: 3rem;
        justify-content: center;
        font-weight: bold;
      }
  `]
})

export class DeminuteurComponent implements OnInit, OnChanges {
  @Input('heures') heures = 0;
  @Input('minutes') minutes = 1;
  @Input('secondes') secondes = 0;

  @Input('styleClass') styleClass = '';
  @Input('heureStyleClass') heureStyleClass = '';
  @Input('minuteStyleClass') minuteStyleClass = '';
  @Input('secondeStyleClass') secondeStyleClass = '';
  @Input('separateurStyleClass') separateurStyleClass = '';

  heures$: Observable<number>;
  minutes$: Observable<number>;
  secondes$: Observable<number>;
  heuresSubject = new BehaviorSubject<number>(null);
  minutesSubject = new BehaviorSubject<number>(null);
  secondesSubject = new BehaviorSubject<number>(null);
  @Output() timeUp = new EventEmitter<void>(false);
  @Output() time = new EventEmitter<{h: number, m: number, s: number}>(false);
  compteur: Subscription;

  constructor() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.setSubjects();
    this.decompter();
  }

  ngOnInit() {
    this.initDemitnuteur();
    this.decompter();
  }

  initDemitnuteur() {
    this.heures$ = this.heuresSubject.asObservable();
    this.minutes$ = this.minutesSubject.asObservable();
    this.secondes$ = this.secondesSubject.asObservable();
    this.setSubjects();
  }

  setSubjects() {
    this.heuresSubject.next(this.heures);
    this.minutesSubject.next(this.minutes);
    this.secondesSubject.next(this.secondes);
  }

  decompter() {
    if (this.compteur) {
      this.compteur.unsubscribe();
    }
    this.compteur = interval(1000).subscribe(() => {
      this.decompterSeconde();
      this.time.emit({h: this.heures, m: this.minutes, s: this.secondes});

      // si le compteur est à zéro, arrêter le compteur
      if (this.heures === 0 && this.minutes === 0 && this.secondes === 0) {
        this.timeUp.emit();
        this.compteur.unsubscribe();
      }
    });
  }

  private decompterHeure() {
    const dec = () => {
      this.heures--;
      this.heuresSubject.next(this.heures);
    };

    if (this.heures === 0) {
      return;
    }
    dec();
  }

  private decompterMinute() {
    const dec = () => {
      this.minutes--;
      this.minutesSubject.next(this.minutes);
    };

    if (this.minutes === 0) {
      this.minutes = 59;
      this.minutesSubject.next(this.minutes);
      this.decompterHeure();
      return;
    }
    dec();
  }

  private decompterSeconde() {
    const dec = () => {
      this.secondes--;
      this.secondesSubject.next(this.secondes);
    };

    if (this.secondes === 0) {
      this.secondes = 59;
      this.secondesSubject.next(this.secondes);
      this.decompterMinute();
      return;
    }
    dec();
  }
}

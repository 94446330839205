import {Component, Input} from '@angular/core';
import {AbstractControl, FormControl} from '@angular/forms';

@Component({
  selector: 'app-form-error',
  template: `
    <mat-error *ngIf="(control?.dirty || control?.touched) && condition">
      <ng-content></ng-content>
    </mat-error>`
})
export class FormErrorComponent {
  @Input('control') control: AbstractControl = new FormControl();
  @Input('condition') condition = false;
}

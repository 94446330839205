import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {DemandeDossierMedicalTableRestApiService} from './demande-dossier-medical-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';

@Injectable({providedIn: 'root'})
export class DemandeDossierMedicalTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: DemandeDossierMedicalTableRestApiService;
  @Autowired() formBuilder: FormBuilder;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaireDossierMedical<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      nomPrenoms: !!datas && !!datas.nomPrenoms ? datas.nomPrenoms : null,
      dateNaissance: !!datas && !!datas.dateNaissance ? datas.dateNaissance : null,
      genre: !!datas && !!datas.genre ? datas.genre : null,
      numBonPriseEnCharge: !!datas && !!datas.numBonPriseEnCharge ? datas.numBonPriseEnCharge : null,
      prestataire: !!datas && !!datas.prestataire ? datas.prestataire : null,
      dateDebut: null,
      dateFin: null,
    };

    return this.formBuilder.group({
      nomPrenoms: [{value: data.nomPrenoms, disabled: true}],
      dateNaissance: [{value: data.dateNaissance, disabled: true}],
      genre: [{value: data.genre, disabled: true}],
      numBonPriseEnCharge: [{value: data.numBonPriseEnCharge, disabled: true}],
      prestataire: [{value: data.prestataire, disabled: true}],
      dateDebut: data.dateDebut,
      dateFin: data.dateFin,
    });
  }

}

import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {BasicRestMetierService} from '@app/core/services/basic-rest-metier.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {ClvTsUtilsCommonsJs} from 'clv-ts-utils';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {DatePipe} from '@angular/common';
import { AffectionsFactureRestApiService } from './affections-facture.rest-api.service';

@Injectable({providedIn: 'root'})
  export class AffectionsFactureMetierService extends BasicRestMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecupererOptionsMetier {

  @Autowired() datePipe: DatePipe;
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: AffectionsFactureRestApiService;

  medecins: any = [];
  actes: any = [];
  affections: any = [];

  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    const v: T | any = ClvTsUtilsCommonsJs.copy(formGroup.value);
    v.permissions = CommonUtilities.convertirArbrePermissionEnListeDeChainesDeCaracteres(v.permissions);
    return v;
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const dateNow = this.datePipe.transform(Date.now(), 'dd/MM/yyyy');

    const formGroup: FormGroup = new FormGroup({});
    return formGroup;
  }

  recupererOptions(args?: any): Observable<Array<any>> {
    console.log("args args args args args args",args)
    return
  }

  neutraliserParents(data: any[]) {
    if (!data || !(data.length > 0)) {
      return data;
    }
    data.forEach(d => {
      d.parent = null;
      if (d.children) {
        this.neutraliserParents(d.children);
      }
    });
  }


}

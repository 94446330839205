import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import { LierBordereauModaleListeFeatureComponent } from './lier-bordereau-modale-liste.feature.component';
import {RechercheBordereauAutocompleteFormModule} from "@app/fonctionnalites/factures/prise-en-charge/recherche-bordereau-autocomplete/recherche-bordereau-autocomplete.form.module";
import {RechercheFactureAutocompleteFormModule} from "@app/fonctionnalites/factures/prise-en-charge/recherche-facture-autocomplete/recherche-facture-autocomplete.form.module";

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        RechercheBordereauAutocompleteFormModule,
        RechercheFactureAutocompleteFormModule,
    ],
  exports: [LierBordereauModaleListeFeatureComponent],
  declarations: [LierBordereauModaleListeFeatureComponent],
  providers: [],
})
export class LierBordereauModaleListeFeatureModule {
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesMetier} from '@app/core/interfaces';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {ActeDossierTableRestApiService} from './acte-dossier-table.rest-api.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {AssureRestApiService} from '@app/fonctionnalites/dossier-medical-prestation/assure/assure.rest-api.service';

@Injectable({providedIn: 'root'})
export class ActeDossierTableMetierService implements IRecuperationListeDonneesMetier {
  @Autowired() restApi: ActeDossierTableRestApiService;
  @Autowired() formBuilder: FormBuilder;

  @Autowired() datePipe: DatePipe;


  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationListeDonnees(data, args);
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    const data: any = {
      inputSearch: null,
      selectTypeSearch: !!datas && !!datas.selectTypeSearch ? datas.selectTypeSearch : null,
    };

    return this.formBuilder.group({
      inputSearch: data.inputSearch,
      selectTypeSearch: data.selectTypeSearch,
    });
  }
}

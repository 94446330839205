import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces';
import {FormMultiselectComponent} from '@shared/components/inputs/form-multiselect/form-multiselect.component';
import {GestionnaireDeModales} from '@app/core/services/gestionnaire-de-modales';

/**
 * Champ de selection à choix multiple.
 *
 * Services metier à implémenter:
 * recupererOptionsMetier: IRecupererOptionsMetier
 */
@Component({
  selector: 'app-atlantis-multiselect',
  template: `
    <app-form-multiselect
        [label]="label"
        [required]="required"
        [placeholder]="placeholder"
        [control]="control"
        [options]="options$ | async"></app-form-multiselect>

    <ng-content></ng-content>
  `
})
export class AtlantisMultiselectComponent extends FormMultiselectComponent implements OnInit, OnChanges {
  /**
   * Liste d'options recupérée pour affichage
   */
  options$: Observable<Array<any>>;

  /**
   * Service metier
   */
  recupererOptionsMetier: IRecupererOptionsMetier;

  /**
   * Liste des champs dont le changement provoque le rechargement des données
   */
  mustReloadIfChange = ['control', 'value'];

  /**
   * Gestionnaire de modales. Permets de parametrer l'affichage et la reception de données d'une fenetre modale-subtitution
   */
  @Input('actionGestionnaireDeModale') actionGestionnaireDeModale: GestionnaireDeModales = new class implements GestionnaireDeModales {
    datasourceSelected: any;
    display = false;
    typeOpenModal: 'update' | 'create' = 'create';
  };

  ngOnChanges(changes: SimpleChanges): void {
    this.getMustReloadIfChange();
    const test = Object.keys(changes).find(v => {
      const must = this.mustReloadIfChange.find(item => item === v);
       return must === v;
    });
    if (test) {
      this.recupererOptions();
    }
  }

  ngOnInit() {
    this.recupererOptions();
  }

  /**
   * Mets à jours la liste d'options depuis la requête <code>recupererOptions</code> du metierService
   */
  recupererOptions() {
    if (!this.extOptions) {
      if (this.recupererOptionsMetier) {
        this.options$ = this.recupererOptionsMetier.recupererOptions(this.getArgs());
      }
    } else {
      this.options$ = of(this.extOptions);
    }
  }
}

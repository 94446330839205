import {NgModule} from '@angular/core';

import {AffectionTableListeComponent} from './affection-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {PrescriptionTableListeModule} from '@app/fonctionnalites/demande-pec/details-demande-table/prescription-table/liste/prescription-table.liste.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        PrescriptionTableListeModule
    ],
  exports: [AffectionTableListeComponent],
  declarations: [AffectionTableListeComponent],
  providers: [],
})
export class AffectionTableListeModule {
}

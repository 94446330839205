import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';
import {Autowired} from '@angular-ru/autowired';
import {FormGroup} from '@angular/forms';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {AlertService} from '@app/core/services/alert.service';
import {ModaleRessaisirFactureMetierService} from './modale-ressaisir-facture.metier.service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {STATUT_BORDEREAU, STATUT_SINISTRE} from '@app/core/constantes';

@Component({
    selector: 'app-modale-ressaisir-facture-feature',
    templateUrl: './modale-ressaisir-facture.feature.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ModaleRessaisirFactureFeatureComponent extends DialogComponent implements OnInit {
    @Autowired() convertirFormulaireMetier: ModaleRessaisirFactureMetierService;
    @Autowired() initialiserFormulaireMetier: ModaleRessaisirFactureMetierService;
    @Autowired() requeteCreationMetier: ModaleRessaisirFactureMetierService;
    @Autowired() requeteModificationMetier: ModaleRessaisirFactureMetierService;
    @Autowired() metierService: ModaleRessaisirFactureMetierService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() alert: AlertService;


    @Input() finResponse: boolean;
    @Output() finResponseEvent = new EventEmitter<boolean>();

    searchForm: FormGroup;
    dataSource: any;
    affections: any = [];
    actes: any = [];
    prescriptions: any = [];
    document: any[] = [];
    data: any;
    description: any;
    STATUT_BORDEREAU = STATUT_BORDEREAU;
    STATUT_SINISTRE = STATUT_SINISTRE;

    constructor() {
        super();
    }

    ngOnInit() {
        /*console.log('modal manager', this.modalManager.datasourceSelected.idPrestation);*/
        const data = {idPrestation: this.modalManager.datasourceSelected.idPrestation};
        this.finResponse = false;
        this.recupererDetatailsFacture(data);
    }

    /*ngAfterViewInit() {
        super.ngAfterViewInit();
        /!*console.log('modal manager', this.modalManager.datasourceSelected.idPrestation);
        const data = {idPrestation: this.modalManager.datasourceSelected.idPrestation};
        this.recupererDetatailsFacture(data);*!/
    }*/

    recupererDetatailsFacture(data) {
        this.metierService.recupererDonneesFacture(data).subscribe((response: any) => {
            if (response.body.items[0].prestationAffections != null) {
                response.body.items[0].prestationAffections.forEach((dat, index) => {
                    dat.id_key = index + 1;
                });
            }

            if (response.body.items[0].prestationActes != null) {
                response.body.items[0].prestationActes.forEach((dat, index) => {
                    dat.id_key = index + 1;
                });
            }

            if (response.body.items[0].prestationPrescriptionRealises != null) {
                response.body.items[0].prestationPrescriptionRealises.forEach((dat, index) => {
                    dat.id_key = index + 1;
                });
            }

            this.data = response.body.items[0];

            if (this.data.documentSinistres != null) {
                this.document = [...this.document, ...this.data.documentSinistres];
            }

            this.dataSource = response.body.items[0];

            this.finResponse = true;
            this.finResponseEvent.emit(this.finResponse);
        });
    }

    handleDocumentAjouter($event: any[]) {
        this.document = $event;
    }

    recupererObservation($event: any) {
        this.description = $event;
    }

    enregistrer() {
        this.data.documentSinistres = CommonUtilities.formatDataDocumentSend(this.document);

        this.metierService.misAjourDonneesFacture(this.data).subscribe(response => {
            if (response.body.hasError === false) {
                this.fermerModale();
            }
        });
    }

}

import {NgModule} from '@angular/core';

import {ActeComplementaireTableComponent} from './acte-complementaire-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeComplementaireAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/composants/acte-complementaire-table/acte-complementaire-autocomplete/form/acte-complementaire-autocomplete.form.module';

@NgModule({
    imports: [CommonModule, SharedModule, ActeComplementaireAutocompleteFormModule],
  exports: [ActeComplementaireTableComponent],
  declarations: [ActeComplementaireTableComponent],
  providers: [],
})
export class ActeComplementaireTableModule {
}

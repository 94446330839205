import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MedecinActeTableMetierService} from './medecin-acte-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {STORAGE} from '@app/core/constantes';

@Component({
  selector: 'app-medecin-acte-table',
  templateUrl: './medecin-acte-table.component.html'
})

export class MedecinActeTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: MedecinActeTableMetierService;
  @Autowired() metierService: MedecinActeTableMetierService;

  @Autowired() metierDemandePECService: DemandePriseChargeMetierService;

  @Autowired() datePipe: DatePipe;

  @Input() modalOpen: any;
  @Input() listActes: any;
  medecinForm: FormGroup;
  montantTotal: number;

  medecinSelected: any;
  @Output() medecinSelectedEvent = new EventEmitter<any>();
  @Output() listActesEvent = new EventEmitter<any>();
  @Input() isCalculerMontantPartAssure: any;
  @Output() isCalculerMontantPartEvent = new EventEmitter<any>();


  constructor() {
    super();
    this.tableActions = [];
  }

  ngOnInit(): void {
    if (this.modalOpen === 'cloture_demande') {
      this.cols = [
        { field: 'libelle', header: 'Acte' },
        { field: 'quantite', header: 'Quantité' },
        {field: 'realise', header: 'Réalisé', type: () => 'chip', states: [
            {key: true, label: 'Oui', classes: 'bg-success-imp'},
            {key: false, label: 'Non', classes: 'color-row-red-PEC'},
          ]},
        { field: 'montantHT', header: 'Montant', type: () => 'price' },
        { field: 'partAssuranceBrut', header: 'Part assurance', type: () => 'price' },
        { field: 'montantTicketModerateur', header: 'Ticket modérateur', type: () => 'price' },
        { field: 'montantHorsPEC', header: 'Hors pec', type: () => 'price' },
        { field: 'partAssure', header: 'A payer', type: () => 'price' },
      ];

    } else if (this.modalOpen === 'poursuite_demande') {
      this.cols = [
        { field: 'libelle', header: 'Acte' },
        { field: 'quantite', header: 'Quantité' },
        {field: 'estRealise', header: 'Réalisé',
          type: (data) => {
            if (data['estRealise']) { return 'input'; }
            return null;
          },
          inputType: () => 'dropdown',
          dropdownList: () => this.constantes.BOOLEAN_TYPE_COMBO
        },
        { field: 'montantHT', header: 'Montant', type: () => 'price' },
        { field: 'partAssurance', header: 'Part assurance', type: () => 'price' },
        { field: 'ticketModerateur', header: 'Ticket modérateur', type: () => 'price' },
        { field: 'montantHorsPec', header: 'Hors pec', type: () => 'price' },
        { field: 'partAssureNet', header: 'A payer', type: () => 'price' },
      ];
    }

    this.initFormulaire();

    this.constructDataTable();
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, };
    this.args = {...arg};
  }

  initFormulaire() {
    if (this.modalOpen === 'cloture_demande') {
      this.medecinForm = this.metierService.initialiserFormulaireMedecin(null);
      this.insertionDataInput();

    } else if (this.modalOpen === 'poursuite_demande') {
      this.medecinForm = this.metierService.initialiserFormulaireMedecinAutoComplete(null);
      this.recuperationMedecinsStorage();
    }
  }

  insertionDataInput() {
    this.medecinForm.patchValue({
      nomMedecin: this.listActes[0].nomCompletMedecin
    });

    this.medecinSelectedEvent.emit(this.listActes[0].nomCompletMedecin);
  }

  constructDataTable() {
    this.montantTotal = 0;
    if (this.listActes && this.listActes.length > 0) {
      this.listActes.forEach((data, index) => {
        data.id_key = index + 1;

        // Affichage du modal de clôture
        if (this.modalOpen === 'cloture_demande') { this.montantTotal = this.montantTotal + data.partAssure; }

        // Affichage du modal de poursuite
        if (this.modalOpen === 'poursuite_demande') {
          if (data.estConventionne === true) {
            data.estRealise = true;
          }
        }
      });
    }

    if (this.modalOpen === 'poursuite_demande') {
      this.montantTotal = this.isCalculerMontantPartAssure.montantTotal;
    }
  }

  calculerMontantTotalPartAssure() {
    if (this.verificationQteIsNumber(this.listActes)) {
      this.constructDataTable();

      this.metierService.calculerMontantTotalPartAssure({}, { actePoursuite: this.listActes }).subscribe((res) => {
        this.correspondanceCalculActes(res.body.items);
      });
    }
  }

  verificationQteIsNumber(arrayList: any[] = []) {
    let ErrorAlert: any ;

    let verifQteIsNumber = true;
    arrayList.forEach((item) => {
      const quantiteFormat = +item.quantite;
      if (!quantiteFormat) {
        verifQteIsNumber = false;
      }
    });
    if (!verifQteIsNumber) {
      ErrorAlert = this.alertService.showError('La quantité saisie doit être une valeur numérique !');
      ErrorAlert.show();
    }

    return verifQteIsNumber;
  }

  correspondanceCalculActes(arrayListCalculActes: any[]) {
    this.montantTotal = 0;

    arrayListCalculActes.forEach((elt) => {
      /** Assignation des variables dans la section calcul acte */
      this.montantTotal = this.montantTotal + elt.partAssureNet;

      /** Assignation des données calculées d'un actes correspondant */
      this.listActes.forEach((item) => {
        if (elt.idActe === item.idActe) {
          /** Assignation des variables dans la section calcul acte */
          item.montantHT = elt.montantHT;
          item.partAssurance = elt.partAssurance;
          item.partAssureNet = elt.partAssureNet;

          item.ticketModerateur = elt.ticketModerateur;
          item.montantHorsPec = elt.montantHorsPec;
        }
      });
    });

    this.listActesEvent.emit(this.listActes);
    this.isCalculerMontantPartEvent.emit({
      verif: true,
      montantTotal: this.montantTotal
    });
  }

  // :: Facultatif ::
  recuperationMedecinsStorage () {
    let medecinsList = window.localStorage.getItem(STORAGE.MEDECINS);
    if (!medecinsList) {
      this.metierDemandePECService.rechercherMedecin({}).subscribe(res => {
        this.metierDemandePECService.medecins = res.body.items;
      });
      return;
    }
    medecinsList = JSON.parse(medecinsList);
    this.metierDemandePECService.medecins = medecinsList;
  }

  /** ** récupération dun medecin selectioné ** */
  actionSelectedMedecin(event) {
    this.medecinSelected = event;
    this.medecinSelectedEvent.emit(this.medecinSelected);
  }

}

import {Injectable} from '@angular/core';
import {Query} from '@datorama/akita';
import {ISecurityState, SecurityStoreService} from '@app/core/states/security/security.store.service';

@Injectable({providedIn: 'root'})
export class SecurityQueryService extends Query<ISecurityState> {

  constructor(protected store: SecurityStoreService) {
    super(store);
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PrescriptionDossierTableMetierService} from './prescription-dossier-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
  selector: 'app-prescription-dossier-table',
  templateUrl: './prescription-dossier-table.component.html'
})

export class PrescriptionDossierTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: PrescriptionDossierTableMetierService;
  @Autowired() metierService: PrescriptionDossierTableMetierService;

  @Input() modalAssureSelected: any;
  @Input() responsePrescriptions: any;
  formGroup: FormGroup;
  typeRechercheCombo: any[] = [];
  placeholderInputSearch: string;
  @Output() responsePrescriptionsEvent = new EventEmitter<any>();

  /** Détails tabs */
  currentDataTemp: any;
  responsePrescriptionsRealise: any;


  constructor() {
    super();
    this.cols = [
      {field: 'libelle', header: 'Médicament'},
      {field: 'nomCompletMedecin', header: 'Médecin'},
      {field: 'quantite', header: 'Quantité'},
      {field: 'posologie', header: 'Posologie'},
      {field: 'datePrestation', header: 'Date prescription', type: () => 'date'},
    ];
    this.tableActions = [];
    this.notRowActions = [];
  }

  ngOnInit(): void {
    this.initialiserTypeRechercheCombo();

    this.initFormulaire();

    this.formatTableListAddIndex(this.responsePrescriptions);
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args};
    this.args = {...arg};
  }

  recuperationDonnees(data?: any) {
    const da = {...this.args, lazyLoadEvent: data, successMute: true, assure: this.modalAssureSelected};
    this.args = {...da};
    // tslint:disable-next-line:max-line-length
    this.metierService.requeteRecuperationListeDonnees(this.getAllRequestParam(), {...this.args}).subscribe((r_Prescriptions) => {
      const responsePrescriptions = r_Prescriptions.body;

      this.formatTableListAddIndex(responsePrescriptions);

      this.responsePrescriptionsEvent.emit(r_Prescriptions.body);
    });
  }

  formatTableListAddIndex(responseData) {
    if (responseData === undefined) {
      this.dataSource = [];
      this.totalRecords = 0;
      return;
    }
    this.dataSource = responseData.items;
    this.totalRecords = responseData.count;
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach((dat, index) => {
        dat.id_key = index + 1;
      });
    }
  }

  initFormulaire() {
    this.formGroup = this.metierService.initialiserFormulaire(null);
  }

  initialiserTypeRechercheCombo() {
    this.placeholderInputSearch = 'Rechercher';
    this.typeRechercheCombo = [
      { label: 'Médecin', value: 'medecin'},
      { label: 'Médicament', value: 'medicament'},
      { label: 'Date prescription', value: 'date_prescription'},

    ];
  }

  handleChangeTypeRecherche(event: any) {
    if (event.value === null) {
      this.placeholderInputSearch = 'Rechercher';

      this.formGroup.patchValue({ inputSearch: null });

      // fonction de recherche assuré avec les valeurs null
      this.recuperationDonnees();
    }

    if (event.value === 'medecin') {
      this.placeholderInputSearch = 'Rechercher par médecin';
    }
    if (event.value === 'medicament') {
      this.placeholderInputSearch = 'Rechercher par médicament';
    }
    if (event.value === 'date_prescription') {
      this.placeholderInputSearch = 'Rechercher par date';
    }

    this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue());
  }

  recherchePrestation(event) {
    if (this.formGroup.getRawValue().selectTypeSearch === null) { return false; }

    // fonction de recherche assuré avec les valeurs entrées
    this.recuperationDonnees();
  }

  /**
   *
   */

  /**
   * => return true, si l'objet est vide
   */
  isEmptyObject(obj) {
    return CommonUtilities.isEmptyObject(obj);
  }

  /**
   * Reccupère la ligne courante du tableau lorsqu'elle est sélectionnée
   */
  selectCurrentRow(data?: any) {
    this.currentRow = null;
    this.currentDataTemp = null;

    this.currentRow = data;
    this.currentDataTemp = this.currentRow;
  }

  handleResponsePrescriptionsRealise(event: any) {
    this.responsePrescriptionsRealise = event;
  }

}

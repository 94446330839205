import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {ResetPasswordRestApiService} from './reset-password-rest-api.service';
import {COMPLEXITE_MOT_DE_PASSE} from '../../../core/constantes';
import {Router} from '@angular/router';
import {IInitialiserFormulaireMetier} from '../../../core/interfaces/metier/i-initialiser-formulaire-metier';
import {IConvertirFormulaireMetier} from '../../../core/interfaces/metier/i-convertir-formulaire-metier';
import {IActiverUtilisateurMetier, IConfirmationMotDePasse, IModifierMotDePasseMetier} from './rest-password.interfaces';
import {AlertService} from '../../../core/services/alert.service';

@Injectable({providedIn: 'root'})
export class ResetPasswordMetierService implements IInitialiserFormulaireMetier, IConvertirFormulaireMetier,
    IActiverUtilisateurMetier, IModifierMotDePasseMetier, IConfirmationMotDePasse {
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: ResetPasswordRestApiService;
  @Autowired() alertService: AlertService;

  @Autowired() router: Router;

  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    const result: T | any = {
      ancienMotPasse: formGroup.value.ancienMotPasse,
      login: formGroup.value.login,
      nouveauMotPasse: formGroup.value.nouveauMotPasse,
    };
    return result;
  }

  initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
    let formGroup: FormGroup;

    formGroup = this.formBuilder.group({
      ancienMotPasse: ['', [Validators.pattern(COMPLEXITE_MOT_DE_PASSE), Validators.minLength(8)]],
      login: '',
      nouveauMotPasse: ['', [Validators.pattern(COMPLEXITE_MOT_DE_PASSE), Validators.minLength(8)]],
      confirmerMotPasse: ''
    });
    return formGroup;
  }

  isMotDePasseConfirme(formGroup: FormGroup): boolean {
    if (!formGroup.getRawValue().nouveauMotPasse || !formGroup.getRawValue().confirmerMotPasse) {
      return false;
    }
    const test = formGroup.getRawValue().nouveauMotPasse === formGroup.getRawValue().confirmerMotPasse;
    if (!test) {
      this.alertService.showError('Les mots de passe ne concordent pas').show();
    }
    return test;
  }

  activerUtilisateur(formGroup: FormGroup) {
    if (!this.isMotDePasseConfirme(formGroup)) {
      return;
    }
    if (formGroup.valid) {
      this.restApi.activerUtilisateur(this.convertirFormulaire(formGroup)).subscribe(() => {
        formGroup.reset();
        this.router.navigate(['/login']);
      });
    } else {
      this.alertService.showError('Le formulaire est invalide.').show();
    }
  }

  modifierMotDePasse(formGroup: FormGroup) {
    if (!this.isMotDePasseConfirme(formGroup)) {
      return;
    }
    if (formGroup.valid) {
      this.restApi.modifierMotDePasse(this.convertirFormulaire(formGroup)).subscribe(() => {
        formGroup.reset();
        this.router.navigate(['/login']);
      });
    } else {
      this.alertService.showError('Le formulaire est invalide.').show();
    }
  }
}

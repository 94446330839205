import {Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {Observable} from 'rxjs';
import {
    IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier,
    IRecuperationDonneeMetier,
    IRequeteCreationMetier,
    IRequeteModificationMetier
} from '@app/core/interfaces';
import { SubstitustionModaleRestApiService } from './substitustion.modale.rest-api.service';

@Injectable({providedIn: 'root'})
export class SubstitustionModaleMetierService implements IConvertirFormulaireMetier,
    IInitialiserFormulaireMetier, IRequeteCreationMetier, IRequeteModificationMetier, IRecuperationDonneeMetier {
  @Autowired() formBuilder: FormBuilder;
  @Autowired() restApi: SubstitustionModaleRestApiService;

  convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
    return formGroup.getRawValue();
  }


    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {

        const formGroup: FormGroup = new FormGroup({});
        formGroup.setControl('medicamentSubstituant', new FormControl(''));
        formGroup.setControl('prixUnitaire', new FormControl());
        formGroup.setControl('quantite', new FormControl());
        formGroup.setControl('posologie', new FormControl(''));
        return formGroup;
    }

  requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteCreation(data, args);
  }

  requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteModification(data, args);
  }

  requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restApi.requeteRecuperationDonnee(data, args);
  }
}

import {AfterContentChecked, Component, Input, OnInit} from '@angular/core';
import * as connexionConstantes from '../connexion.constante';
import {Autowired} from '@angular-ru/autowired';
import {ConnexionMetierService} from '../connexion.metier.service';
import {IConnexion} from '@app/core/interfaces';
import {AppSettings} from '@app/app.settings';
import {STORAGE} from '@app/core/constantes';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {IUserConnect, Personne} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
    selector: 'app-connexion-layout',
    templateUrl: './connexion-layout.component.html',
    styleUrls: ['./connexion-layout.component.scss']
})
export class ConnexionLayoutComponent extends SgiListLayoutComponent implements OnInit, AfterContentChecked, IConnexion {
    @Autowired() metierService: ConnexionMetierService;
    @Autowired() securityStore: SecurityStateService;
    @Autowired() metierServiceDemandePEC: DemandePriseChargeMetierService;


    connexionConstantes = connexionConstantes;

    @Input('nouvelleConnexion') nouvelleConnexion = true;

    lastUserConnect = window.localStorage.getItem(STORAGE.USER_CONNECT);

    bg: string = AppSettings.configs.assets.loginBackground;

    /**
     * Système de Reinitialisation du Password
     */
    affichModalReinitialiserUserPassword: boolean;
    tempUserConnect: any;
    tempAccessToken: any;
    storageUserConnect: any;


    ngOnInit() {
        super.ngOnInit();

        this.closeModal();
        this.storageUserConnect = window.localStorage.getItem(STORAGE.USER_CONNECT);
    }

    ngAfterContentChecked(): void {
        this.metierService.verifierTentativesConnexionRestantes(this.formGroup.value.login);
    }

    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args, nouvelleConnexion: this.nouvelleConnexion};
        this.args = {...arg};
    }

    initialiserFormulaire() {
        this.formGroup = this.metierService.initialiserFormulaire(this.defaultObject);

        /**
         * Systeme de détection du thème à utiliser
         */
        this.metierService.detectThemeAppUse();
    }

    /*connexion(data: any, args?: any) {
        this.metierService.connexion(data, this.getArgs());
    }*/

    connexion(data: any, args?: any) {
        // tslint:disable-next-line:prefer-const
        let userConnecte: IUserConnect = new class implements IUserConnect {
            permissions: any[] = [];
            idSessionUtilisateur: any;
            personne: Personne = new class implements Personne {
                nom: string;
                email: string;
                entite: string;
                idCentreConventionne: number;
                idMedecin: any;
                idPersonne: number;
                idPrestataireMedical: number;
                logoUrl: string;
                prenom: string;
                roleId: number;
                raisonSocial: string;
                userId: number;
                userName: string;
            };
            tenantId: any;
            utilisateurId: any;
        };

        if (this.formGroup.valid) {
            // vérification du nombre de tentatives de connexion
            if (SecurityStateService.getTentativesConnexion(this.formGroup.value.login) >= this.appSettings.settings.tentativesConnexion) {
                return this.metierService.verouillerCompteApresEchecTentatives(this.formGroup.value.login);
            }

            // Lancement de la réquette de connexion
            this.metierService.connexion({
                username: (this.storageUserConnect !== null && this.formGroup.value.login === null)
                    ? this.storageUserConnect
                    : this.formGroup.value.login,
                password: window.btoa(window.btoa(window.btoa(this.formGroup.value.password)))
            }).subscribe(reponseUserConnecte => {

                /** formatage des données de l'utilisateur connecté */
                userConnecte.utilisateurId = reponseUserConnecte.body.items[0].user.id;
                userConnecte.idSessionUtilisateur = reponseUserConnecte.body.items[0].user.idSessionUtilisateur;
                userConnecte.tenantId = reponseUserConnecte.body.items[0].user.garant.idTenant;

                userConnecte.personne.nom = reponseUserConnecte.body.items[0].user.userPrestataires[0].nom;
                userConnecte.personne.prenom = reponseUserConnecte.body.items[0].user.userPrestataires[0].prenoms;
                userConnecte.personne.logoUrl = reponseUserConnecte.body.items[0].user.userPrestataires[0].photo;
                userConnecte.personne.email = reponseUserConnecte.body.items[0].user.userPrestataires[0].email;
                userConnecte.personne.userId = reponseUserConnecte.body.items[0].user.userPrestataires[0].userId;

                userConnecte.personne.entite = reponseUserConnecte.body.items[0].user.userPrestataires[0].entite;
                // tslint:disable-next-line:max-line-length
                userConnecte.personne.idCentreConventionne = reponseUserConnecte.body.items[0].user.userPrestataires[0].idCentreConventionne;
                userConnecte.personne.idMedecin = reponseUserConnecte.body.items[0].user.userPrestataires[0].idMedecin;
                userConnecte.personne.idPersonne = reponseUserConnecte.body.items[0].user.userPrestataires[0].idPersonne;
                // tslint:disable-next-line:max-line-length
                userConnecte.personne.idPrestataireMedical = reponseUserConnecte.body.items[0].user.userPrestataires[0].idPrestataireMedical;
                userConnecte.personne.roleId = reponseUserConnecte.body.items[0].user.userPrestataires[0].roleId;
                userConnecte.personne.raisonSocial = reponseUserConnecte.body.items[0].user.userPrestataires[0].raisonSociale;
                userConnecte.personne.userName = reponseUserConnecte.body.items[0].user.userPrestataires[0].userName;


                /**
                 * récupéeration des permissions de l'utilisateur
                 * */
                // this.userRestApi.requeteRecuperationListeDonnees(null).subscribe(response => {
                //     userConnecte.groupeUtilisateurs = response.body.groupeUtilisateurs;
                // });

                userConnecte.permissions = ['app.tableau_de_bord', ...reponseUserConnecte.body.items[0].codeClaims];


                // console.log('userConnecte $$ : ', userConnecte);
                // console.log('reponseUserConnecte $$ : ', reponseUserConnecte.body.items[0].currentUser);
                // console.log('reponseUserConnecte $$ : ', reponseUserConnecte.body.items[0].codeClaims);


                this.securityStore.clearSotre();

                /**
                 * sauvegarde temporaire des données du user
                 */
                this.tempUserConnect = userConnecte;

                /**
                 * sauvegarde temporaire du token du user
                 */
                this.tempAccessToken = reponseUserConnecte.body.items[0].user.accessToken;

                /**
                 * :: Affichage du système de reset password ::
                 */
                if (!CommonUtilities.isDataNullUndefinedFalseEmpty(reponseUserConnecte.body.items[0].user.passwordResetCode)) {
                    this.reinitialiserUserPassword();
                    return;
                }

                /**
                 * Récupération des données restantes après connexion
                 */
                this.recuperationDonneesAfterConnexion();

            }, err => {
                // lors de la premiere connexion une exception est lancé et l'utilisateur doit changer son mot de passe
                this.metierService.redirigerVersChangerModeDePasse(err);
                // SecurityStateService.setTentativesConnexion(v.login);
            });
        }
    }

    recuperationDonneesAfterConnexion() {
        // enregistrement du login du currentUser connecté, pour reconnection au système de verrouillage
        window.localStorage.setItem(STORAGE.USER_CONNECT,
            (this.storageUserConnect !== null && this.formGroup.value.login === null)
                ? this.storageUserConnect
                : this.formGroup.value.login);


        this.securityStore.saveToken(this.tempAccessToken);
        this.securityStore.saveUser(this.tempUserConnect);

        SecurityStateService.resetTentativesConnexion(this.formGroup.value.login);

        /**
         * système de récupération et de stockage des médecins
         * */
        if (this.metierServiceDemandePEC.medecins.length === 0) {
            const reqMedecins = this.metierServiceDemandePEC.rechercherMedecin({});
            reqMedecins.subscribe(res => {
                // console.log('reqMedecins : ', res.body.items);

                this.metierServiceDemandePEC.medecins = res.body.items;
                window.localStorage.setItem(STORAGE.MEDECINS, JSON.stringify(this.metierServiceDemandePEC.medecins));
            });
        }

        /**
         * système de récupération et de stockage des actes
         * */
        if (this.metierServiceDemandePEC.actes.length === 0) {
            const reqAtes = this.metierServiceDemandePEC.recupererActes({});
            reqAtes.subscribe(res => {
                // console.log('reqAtes : ', res.body.items);

                this.metierServiceDemandePEC.actes = res.body.items;
                // window.localStorage.setItem(STORAGE.ACTES, JSON.stringify(this.metierServiceDemandePEC.actes));
            });
        }

        /**
         * système de récupération et de stockage des actes hospitalisation
         * */
        if (this.metierServiceDemandePEC.actesHospitalisation.length === 0) {
            const reqAtesHospitalisation = this.metierServiceDemandePEC.recupererActesHospitalisation({});
            reqAtesHospitalisation.subscribe(res => {
                // console.log('reqAtesHospitalisation : ', res.body.items);

                this.metierServiceDemandePEC.actesHospitalisation = res.body.items;
                // window.localStorage.setItem(
                //     STORAGE.ACTES_HOSPITALISATION, JSON.stringify(this.metierServiceDemandePEC.actesHospitalisation));
            });
        }

        /**
         * système de récupération et de stockage des affections
         * */
        if (this.metierServiceDemandePEC.affections.length === 0) {
            const reqAffections = this.metierServiceDemandePEC.recupererAffections({});
            reqAffections.subscribe(res => {
                // console.log('reqAffections : ', res.body.items);

                this.metierServiceDemandePEC.affections = res.body.items;
                window.localStorage.setItem(STORAGE.AFFECTIONS, JSON.stringify(this.metierServiceDemandePEC.affections));
            });
        }

        this.metierServiceDemandePEC.requeteRecuperationListGarant({}, {}).subscribe((reponseListGarant) => {

            this.securityStore.saveListGarant({
                affectionPrescriptionLier: reponseListGarant.body.items[0].affectionPrescriptionLier,
                affectionPrescriptionFacultativePrestation: reponseListGarant.body.items[0].affectionPrescriptionFacultativePrestation,
                horaireAutomatiquePrestation: reponseListGarant.body.items[0].horaireAutomatiquePrestation,
                autoriserMontantDette: reponseListGarant.body.items[0].autoriserMontantDette,
                automatiqueActeMontantDette: reponseListGarant.body.items[0].automatiqueActeMontantDette,
                affectionFacultativePrestation: reponseListGarant.body.items[0].affectionFacultativePrestation,
                gestionBonPhysique: reponseListGarant.body.items[0].gestionBonPhysique,
            });
            // console.log('getListGarant() : ', this.securityStore.getListGarant());
        });

        this.securityStore.reloadedAfterTokenExpired = false;

        if (this.getArgs().nouvelleConnexion) {
            this.securityStore.authentification(this.tempAccessToken, this.tempUserConnect);

            // Ajout pour le nouveau système de dévérouillage si statusCode 401
            this.securityStore.deverouillage(this.tempAccessToken, this.tempUserConnect);
        } else {
            this.securityStore.deverouillage(this.tempAccessToken, this.tempUserConnect);
        }
    }

    /**
     * :: Système de Reinitialisation du Password
     */

    closeModal() {
        this.affichModalReinitialiserUserPassword = false;
        this.gestionnaireDeModales[0].display = false;
        this.gestionnaireDeModales[0].datasourceSelected = undefined;
    }

    reinitialiserUserPassword() {

        this.closeModal();

        this.affichModalReinitialiserUserPassword = true;
        this.gestionnaireDeModales[0].id = 'reinitialiser-user-password';
        this.gestionnaireDeModales[0].datasourceSelected = this.tempUserConnect;
        this.gestionnaireDeModales[0].display = true;
    }

    /**
     * Systeme de detection et de rechargement des données après enregistrement
     * @param event
     */
    handleSaveDemandeSuccess(dataEvent) {

        // console.log(dataEvent.event, dataEvent.args);

        if (!CommonUtilities.isDataNullUndefinedFalseEmpty(dataEvent.event) &&
            CommonUtilities.isDataNullUndefinedFalseEmpty(dataEvent.args)) {

            /**
             * Récupération des données restantes après connexion
             */
            this.recuperationDonneesAfterConnexion();
        }
    }

}

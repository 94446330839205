import {Component, Input, OnInit} from '@angular/core';
import {SoinsHospitalisationTableListeMetierService} from './soins-hospitalisation-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';

@Component({
  selector: 'app-soins-hospitalisation-table-liste',
  templateUrl: './soins-hospitalisation-table.liste.component.html'
})

export class SoinsHospitalisationTableListeComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: SoinsHospitalisationTableListeMetierService;

  @Input('currentDataUser') currentDataUser: any;

  constructor() {
    super();
    this.cols = [
      { field: 'libelle', header: 'Libellé' },
      { field: 'prixUnitaire', header: 'Montant', type: () => 'price' },
      { field: 'quantite', header: 'Qté' },
      { field: 'montantHorsPec', header: 'Mt Hors PEC' , type: () => 'price' },
      { field: 'ticketModerateur', header: 'Ticket Mod.', type: () => 'price' },
      { field: 'partAssurance', header: 'P. Assurance' , type: () => 'price' },
      { field: 'partAssureNet', header: 'Total part assuré', type: () => 'price'  },
      { field: 'montantDette', header: 'Mt dette', type: () => 'price'  },
      // { field: 'accord', header: 'Accord préalable ?', type: () => 'dropdown', dropdownList: () => BOOLEAN_TYPE_COMBO }
    ];

    this.tableActions = [];
  }

  ngOnInit(): void {
    if (this.currentDataUser) {
      // console.log('currentDataUser : ', this.currentDataUser);

      this.recuperDonnees();
    }
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, currentDataUser: this.currentDataUser};
    this.args = {...arg};
  }

}

import {Component} from '@angular/core';
import {PageLayoutComponent} from '../../../shared/behaviors/page-layout/page-layout.component';
import * as resetPasswordConstantes from '../../../fonctionnalites/authentification/reset-password/reset-password.constante';

@Component({
  selector: 'app-refresh-password',
  templateUrl: './refresh-password.component.html'
})
export class RefreshPasswordComponent extends PageLayoutComponent {
  resetPasswordConstantes = resetPasswordConstantes;
}

import {Injectable} from '@angular/core';
import {IRestApi} from '@app/core/interfaces/rest/i-rest-api';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {
    API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE,
    API_CAN_CLOSE_POURSUITE_PEC,
    API_RECUPERER_BON_PRESCRIPTION_ADHERENT_AFFILLIE,
    API_RECUPERER_BON_PRESCRIPTION_PERIODIQUE,
    API_RECUPERER_PRIX_ACTES_POURSUITE
} from '@app/core/constantes';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class PrescriptionPeriodiqueRestApiService extends SecurityStateService implements IRestApi {
    @Autowired() restService: SgiRestService;
    @Autowired() datePipe: DatePipe;
    storageUser: IUserConnect = this.getUser();

    postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};


    requeteCreation(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = {items: []};
        return of({body: body});
    }

    requeteModification(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = {items: []};
        return of({body: body});
    }

    requeteRecuperationDonnee(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = {items: []};
        return of({body: body});
    }

    requeteSuppression(data: any, args?: any): Observable<any> | Promise<any> | any {
        const body = {items: []};
        return of({body: body});
    }


    requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {itemToSearch: data};

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_BON_PRESCRIPTION_ADHERENT_AFFILLIE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (!(response.body.items.length > 0)) {
                        return response;
                    }
                    return response;
                })
            );
    }

    requeteRecuperationListeDonneesMaladieChronique(data: any, args?: any): Observable<any> | Promise<any> | any {
        const sendData = {itemToSearch: data};

        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_BON_PRESCRIPTION_PERIODIQUE}`, sendData, {...this.postArgsMute})
            .pipe(
                map((response: HttpResponse<any>) => {
                    if (!(response.body.items.length > 0)) {
                        return response;
                    }
                    return response;
                })
            );
    }

    requeteRecuperationActesPoursuite(data: any, args?: any): Observable<any> | Promise<any> | any {
        // update du paramètre
        data.SortOrder = null;
        const {adherant} = args;

        const sendData = {
            ...data,
            itemToSearch: {
                ...data.itemToSearch,
                idDemandePriseEnCharge: adherant.idDemandePriseEnCharge,
            },
            Size: 20,
            TakeAll: false,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_ACTE_DEMANDE_DETAILS_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
    }

    requeteActionCanClosePoursuite(data: any, args?: any): Observable<any> | Promise<any> | any {
        const {actePoursuite} = args;

        const sendData = {
            ItemsToSave: actePoursuite,
        };
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_CAN_CLOSE_POURSUITE_PEC}`, sendData, {...this.postArgsMute});
    }

    requeteRecuperationPrixActesPoursuite(data: any, args?: any): Observable<any> | Promise<any> | any {
        const {actePoursuite} = args;

        const sendData = {
            ItemsToSave: actePoursuite,
        };
        // tslint:disable-next-line:max-line-length
        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PRIX_ACTES_POURSUITE}`, sendData, {...this.postArgsMute});
    }

}

import {Directive, ElementRef, Input} from '@angular/core';
/**
 * Cette directive fonctionne avec cette classe
 *
 * .disabled {
 *  pointer-events: none;
 *  filter: grayscale(100%);
 * }
 */
@Directive({
  selector: '[appDisableEffect]',
})
export class DisableEffectDirective {
  @Input('appDisableEffect') set appDisableEffect(disable: boolean) {
    if (disable) {
      this.disable();
    } else {
      this.enable();
    }
  }
  constructor(public el: ElementRef) {}
  disable() {
    const element: Element = this.el.nativeElement;
    if (!element.classList.contains('disabled')) {
      element.classList.add('disabled');
    }
  }
  enable() {
    const element: Element = this.el.nativeElement;
    if (element.classList.contains('disabled')) {
      element.classList.remove('disabled');
    }
  }
}

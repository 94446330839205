import {NgModule} from '@angular/core';

import {ListDocumentTableListeComponent} from './list-document-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {ActeAutocompleteFormModule} from '@app/fonctionnalites/demande-pec/demande-prise-charge/form/acte-autocomplete/form/acte-autocomplete.form.module';

@NgModule({
    imports: [CommonModule, SharedModule, ActeAutocompleteFormModule],
  exports: [ListDocumentTableListeComponent],
  declarations: [ListDocumentTableListeComponent],
  providers: [],
})
export class ListDocumentTableListeModule {
}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {API_HOSPITALISATION_DETAILS_PRISE_EN_CHARGE} from '@app/core/constantes';

@Injectable({providedIn: 'root'})
export class SoinsHospitalisationTableListeRestApiService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // update du paramètre
    data.SortOrder = null;
    const { currentDataUser } = args;

    const sendData = {...data, itemToSearch: {
      ...data.itemToSearch,
      estRejeter: false,
      idDemandePriseEnCharge: currentDataUser.idDemandePriseEnCharge,
      InfoSearchestRejeter: { Consider: true },
      InfoSearchidDemandePriseEnCharge: { Consider: true },
    }};

    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_HOSPITALISATION_DETAILS_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
  }
}

import {AfterViewChecked, Component, EventEmitter, Input, Output} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DemandePriseChargeMetierService} from '../demande-prise-charge.metier.service';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {AlertService} from '@app/core/services/alert.service';
import {AUCUN_MATRICULE_SAISI, AUCUNE_DONNEE_TROUVEE, STORAGE} from '@app/core/constantes';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {IListGarant} from '@app/core/interfaces/list-garant/i-list-garant';

@Component({
    selector: 'app-demande-pec-form',
    templateUrl: './demande-pec-form.component.html',
    styleUrls: ['./demande-pec-form.component.scss']
})
export class DemandePECFormComponent extends SgiListLayoutComponent implements AfterViewChecked {
    @Autowired() formBuilder: FormBuilder;
    @Autowired() metierService: DemandePriseChargeMetierService;
    @Autowired() securityService: SecurityStateService;
    @Autowired() alertService: AlertService;

    @Autowired() securityState: SecurityStateService;


    @Input() refresh: boolean;

    searchForm: FormGroup;
    @Output() assureTrouveEvent = new EventEmitter<any>();
    userData: IUserConnect;
    adherant: any;

    dataListActesSelected: any[] = [];
    @Output() dataListActesSelectedEvent = new EventEmitter<any[]>();

    medecinSelected: any;
    @Output() medecinSelectedEvent = new EventEmitter<any[]>();

    dataListAffectionsSelected: any[] = [];
    @Output() dataListAffectionsSelectedEvent = new EventEmitter<any[]>();

    descriptionsAll: any;
    @Output() descriptionsAllEvent = new EventEmitter<any>();

    @Output() numeroBonEvent = new EventEmitter<any>();

    dataListAdherant: any = [];
    adherantChoisit: any;

    @Output() resetDocumentEvent = new EventEmitter<any[]>();

    listGarantUser: IListGarant;


    constructor() {
        super();
        this.recuperationMedecinsStorage();
        // this.recuperationActesStorage();
        this.recuperationAffectionsStorage();
    }

    ngOnInit() {
        this.userData = this.securityService.getUser();

        this.initFormulaire();
        this.cols = [
            {field: 'nomCompletPersonne', header: 'Nom & Prénoms'},
            {field: 'matricule', header: 'Matricule'},
        ];

        this.listGarantUser = this.securityState.getListGarant();
    }

    ngAfterViewChecked() {
        if (this.refresh) {
            this.resetAll();

            /**
             * reset all datas
             */
            this.resetDatasComponent();
        }
    }

    resetDatasComponent() {
        this.adherant = {};
        this.medecinSelected = {};
        this.dataListActesSelected = [];
        this.dataListAffectionsSelected = [];
        this.descriptionsAll = '';
        this.adherantChoisit = {};
    }
    resetAll() {
        // this.searchForm = this.metierService.initialiserFormulaireDemandePEC(null);
        this.searchForm.patchValue({
            Matricule: null,
            Nom: null,
            Prenoms: null,
            Genre: null,
            medecin: null,
            numeroBon: null,
            tauxCouverture: null,
        });

        this.adherant = {};
        this.dataListActesSelected = [];
        this.medecinSelected = {};
        this.dataListAffectionsSelected = [];
        this.descriptionsAll = '';

        this.assureTrouveEvent.emit(this.adherant);
        this.dataListActesSelectedEvent.emit(this.dataListActesSelected);
        this.medecinSelectedEvent.emit(this.medecinSelected);
        this.dataListAffectionsSelectedEvent.emit(this.dataListAffectionsSelected);
        this.descriptionsAllEvent.emit(this.descriptionsAll);
        this.numeroBonEvent.emit('');
    }

    initFormulaire() {
        this.searchForm = this.metierService.initialiserFormulaireDemandePEC(null);
    }

    recuperationMedecinsStorage() {
        let medecinsList = window.localStorage.getItem(STORAGE.MEDECINS);
        if (!medecinsList) {
            this.metierService.rechercherMedecin({}).subscribe(res => {
                this.metierService.medecins = res.body.items;
            });
            return;
        }
        medecinsList = JSON.parse(medecinsList);
        this.metierService.medecins = medecinsList;
    }

    recuperationActesStorage() {
        let actesList = window.localStorage.getItem(STORAGE.ACTES);
        if (!actesList) {
            this.metierService.recupererActes({}).subscribe(res => {
                this.metierService.actes = res.body.items;
            });
            return;
        }
        actesList = JSON.parse(actesList);
        this.metierService.actes = actesList;
    }

    recuperationAffectionsStorage() {
        let affectionsList = window.localStorage.getItem(STORAGE.AFFECTIONS);
        if (!affectionsList) {
            this.metierService.recupererAffections({}).subscribe(res => {
                this.metierService.affections = res.body.items;
            });
            return;
        }
        affectionsList = JSON.parse(affectionsList);
        this.metierService.affections = affectionsList;
    }

    selectionChange($event) {
        /**
         * reset all datas
         */
        this.resetAll();
        this.resetDatasComponent();

        this.resetDocumentEvent.emit([]);

        this.searchForm = this.metierService.initialiserFormulaireDemandePEC($event);
        this.adherant = $event;

        this.assureTrouveEvent.emit(this.adherant);
    }

    rechercheAssure() {
        this.adherantChoisit = {};

        let ErrorAlert: any;

        if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.searchForm.value.Matricule)) {
            return false;
        }

        let matriculeRefact = this.searchForm.value.Matricule.trim();
        matriculeRefact = matriculeRefact.toUpperCase();
        this.searchForm.patchValue({
            Matricule: matriculeRefact,
        });

        if (this.searchForm.value.Matricule === null) {
            ErrorAlert = this.alertService.showError(AUCUN_MATRICULE_SAISI);
            ErrorAlert.show();
        } else {
            const data = {
                matricule: this.searchForm.value.Matricule,
                idPrestataireMedical: this.userData.personne.idPrestataireMedical,
            };
            const resultatRecherche$ = this.metierService.rechercherAssureParMatricule(data);
            resultatRecherche$.subscribe(reponse => {
                if (reponse.body.items.length === 0) {
                    ErrorAlert = this.alertService.showError(AUCUNE_DONNEE_TROUVEE);
                    ErrorAlert.show();
                } else {
                    this.dataListAdherant = reponse.body.items;
                    this.dataListAdherant.forEach((item, index) => {
                        item.id_key = index + 1;
                        if (this.searchForm.value.Matricule === item.matricule) {
                            this.adherant = item;

                            this.searchForm.patchValue({
                                Matricule: this.adherant.matricule,
                                Nom: this.adherant.nom,
                                Prenoms: this.adherant.prenoms,
                                Genre: this.adherant.sexe,
                                tauxCouverture: `${(this.adherant.tauxCouverture || this.adherant.tauxCouverture !== null)
                                    ? this.adherant.tauxCouverture
                                    : 0} %`,
                            });
                            this.assureTrouveEvent.emit(this.adherant);
                        }
                    });
                }
            });
        }
    }

    /** ** récupératyion et redistribution des données pour les actes ajouté ** */
    handleDataListActesSelected(event: any[]) {
        this.dataListActesSelected = event;
        this.dataListActesSelectedEvent.emit(this.dataListActesSelected);
    }

    /** ** récupératyion dun medecin selectioné ** */
    actionSelectedMedecin(event) {
        this.medecinSelected = event;
        this.medecinSelectedEvent.emit(this.medecinSelected);
    }

    /** ** récupération et redistribution des données pour les actes ajouté ** */
    handleDataListAffectionsSelected(event: any[]) {
        this.dataListAffectionsSelected = event;
        this.dataListAffectionsSelectedEvent.emit(this.dataListAffectionsSelected);
    }

    /** ** récupération et redistribution de la description générale pour les actes à accord préalable ** */
    handleDescriptionsAll(event: any[]) {
        this.descriptionsAll = event;
        this.descriptionsAllEvent.emit(this.descriptionsAll);
    }

    clearAutocomplete() {
        this.medecinSelected = {};
        this.medecinSelectedEvent.emit(this.medecinSelected);
        this.searchForm.patchValue({medecin: null});
    }

    onchangeNumeroBon(event) {
        this.numeroBonEvent.emit(event.target.value);
    }
}

export const TYPES_TIERS_REMPL = {
  CEDANT: 'Cedant',
  SOCIETE_GESTION: 'Société de gestion',
  DEPOSITAIRE: 'Dépositaire',
  INVESTISSEUR: 'Investisseur',
  BANQUE_REGLEMENT: 'Banque règlement',
  COMMISSAIRE_COMPTES: 'Commissaire au comptes',
  AGENCE_NOTATION: 'Agence notation',
  ARRANGEUR: 'Arrangeur'
};

export const TYPES_TIERS_KEYS = {
  CEDANT: 'CEDANT',
  SOCIETE_GESTION: 'SOCIETE_GESTION',
  DEPOSITAIRE: 'DEPOSITAIRE',
  INVESTISSEUR: 'INVESTISSEUR',
  BANQUE_REGLEMENT: 'BANQUE_REGLEMENT',
  COMMISSAIRE_COMPTES: 'COMMISSAIRE_COMPTES',
  AGENCE_NOTATION: 'AGENCE_NOTATION',
  ARRANGEUR: 'ARRANGEUR'
};

export const MODE_CALCUL_KEYS = {
  MONTANT: 'MONTANT',
  POURCENTAGE: 'POURCENTAGE'
};

export const TYPE_CONDITION_CRITERE_KEYS = {
  NUMERIQUE: 'NUMERIQUE',
  DATE: 'DATE',
  BOOLEAN: 'BOOLEAN',
  CARACTERE: 'CARACTERE',
  TAUX: 'TAUX',
};

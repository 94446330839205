import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DatePipe} from '@angular/common';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
  API_RECUPERER_ANTECEDENT_DOSSIER_MEDICAL_ENCOURS,
} from '@app/core/constantes';


@Injectable({providedIn: 'root'})
export class AntecedentDossierTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;
  @Autowired() datePipe: DatePipe;

  storageUser: IUserConnect = this.getUser();

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    const body = [];
    return of({body: body});
  }

  requeteRecuperationListeAntecedent(data: any, args?: any): Observable<any> | Promise<any> | any {
    // update du paramètre
    data.SortOrder = null;

    const sendData = {
      ...data,
      itemToSearch: {
        ...data.itemToSearch,
        IdSouscriptionLigne: args.assure.idSouscriptionLigne,
      },
      TakeAll: true,
    };

    // tslint:disable-next-line:max-line-length
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ANTECEDENT_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
  }

}

import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {API_PRESCRIPTION_DETAILS_PRISE_EN_CHARGE} from '@app/core/constantes';

@Injectable({providedIn: 'root'})
export class PrescriptionTableListeRestApiService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    const { currentDataUser, affectionPrescriptionLier, sectionAffichage } = args;

    // console.log('currentDataUser : ', currentDataUser);
    // console.log('affectionPrescriptionLier : ', affectionPrescriptionLier);
    // console.log('sectionAffichage : ', sectionAffichage);

    let sendData: any;

    // update du paramètre
    data.SortOrder = null;

    if (sectionAffichage === 'poursuite') {
      sendData = {
        ...data,
        itemToSearch: {
          ...data.itemToSearch,
          idBonPriseEnCharge: currentDataUser.idBonPriseEnChargeParent,
          IdPrestationAffection: affectionPrescriptionLier ? currentDataUser.idPrestationAffection : 0,
          InfoSearchidDemandePriseEnCharge: { Consider: true },
          InfoSearchIdPrestationAffection: { Consider: true },
        }
      };
    } else if (sectionAffichage === 'demande') {
      sendData = {
        ...data,
        itemToSearch: {
          ...data.itemToSearch,

          IdDemandePriseEnCharge: currentDataUser.idDemandePriseEnCharge,
          IdPrestationAffection: affectionPrescriptionLier ? currentDataUser.idPrestationAffection : 0,
          InfoSearchIdDemandePriseEnCharge: {Consider: true},
          InfoSearchIdPrestationAffection: {Consider: true},
          InfoSearchprenoms: {IsOrderByField: true, Consider: false},
        }
      };
    }

    // tslint:disable-next-line:max-line-length
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_PRESCRIPTION_DETAILS_PRISE_EN_CHARGE}`, sendData, {...this.postArgsMute});
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormDropdownComponent} from '@shared/components/inputs/form-dropdown/form-dropdown.component';
import {ListFormErrorComponentModule} from '@shared/components/error/list-form-error/list-form-error.component.module';
import {DropdownModule} from 'primeng/dropdown';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InfoBullWidgetComponentModule} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component.module';
import {ButtonModule} from 'primeng/button';

@NgModule({
    imports: [
        CommonModule,
        ListFormErrorComponentModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        InfoBullWidgetComponentModule,
        ButtonModule,
    ],
    declarations: [
        FormDropdownComponent,
    ],
    exports: [
        FormDropdownComponent,
        ListFormErrorComponentModule,
        DropdownModule,
        FormsModule,
        ReactiveFormsModule,
        InfoBullWidgetComponentModule,
        ButtonModule,
    ]
})
export class FormDropdownComponentModule {
}

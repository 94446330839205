import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import { DetailsAffectionTableListeComponent } from './details-affection-table.liste.component';
import {PrescriptionTableListeModule} from "@app/fonctionnalites/demande-pec/details-demande-table/prescription-table/liste/prescription-table.liste.module";

@NgModule({
    imports: [CommonModule, SharedModule, PrescriptionTableListeModule],
  exports: [DetailsAffectionTableListeComponent],
  declarations: [DetailsAffectionTableListeComponent],
  providers: [],
})
export class DetailsAffectionTableListeModule {
}

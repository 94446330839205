import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-save-button',
  template: `
  <button mat-raised-button [matTooltip]="title" [disabled]="disabled" [ngClass]="buttonClass">
    <mat-icon>{{icon}}</mat-icon>&nbsp;{{title}}
  </button>
  `
})
export class SaveButtonComponent {
  @Input('icon') icon = 'done';
  @Input('buttonClass') buttonClass = 'bg-info-sa-web';
  @Input('disabled') disabled = false;
  @Input('title') title = 'Enregistrer';
}

import {Component, Input, OnInit} from '@angular/core';
import {ActePrestationTableListeMetierService} from './acte-prestation-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {
  ERROR_MESSAGE_CLOTURE_DEMANDE_PEC,
  ERROR_MESSAGE_IDENTIFIANT_INVALIDE,
  ERROR_MESSAGE_ITEMS_VIDE,
  STATUT_EN_ATTENTE_DE_CLOTURE
} from '@app/core/constantes';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
  selector: 'app-acte-prestation-table-liste',
  templateUrl: './acte-prestation-table.liste.component.html'
})

export class ActePrestationTableListeComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: ActePrestationTableListeMetierService;
  @Autowired() metierService: ActePrestationTableListeMetierService;
  @Autowired() metierServiceDemandePEC: DemandePriseChargeMetierService;

  @Input() currentDataUser: any;
  affichModalRapport: boolean;
  affichModalHospitalisation: boolean;

  constructor() {
    super();
    this.tableActions = [];
  }

  ngOnInit(): void {
    if (this.currentDataUser && this.currentDataUser.libelleTypeStatut === 'Cloturé') {
      this.cols = [
        { field: 'libelle', header: 'Libellé' },
        { field: 'montantHT', header: 'Montant' , type: () => 'price'},
        { field: 'quantite', header: 'Qté' },
        { field: 'montantDette', header: 'Mt dette' , type: () => 'price'},
        { field: 'partAssureur', header: 'Part assureur' , type: () => 'price'},
        { field: 'partAssure', header: 'Part assuré' , type: () => 'price'},
        { field: 'dateRealisation', header: 'Date de réalisation ', type: () => 'date' },
      ];
    } else  {
      this.cols = [
        { field: 'libelle', header: 'Libellé' },
        { field: 'montantHT', header: 'Montant', type: () => 'price' },
        { field: 'quantite', header: 'Qté' },
        { field: 'montantHorsPec', header: 'Mt Hors PEC', type: () => 'price' },
        { field: 'ticketModerateur', header: 'Ticket Mod.', type: () => 'price' },
        { field: 'partAssurance', header: 'P. Assurance', type: () => 'price' },
        { field: 'partAssureNet', header: 'Total part assuré', type: () => 'price' },
        { field: 'montantDette', header: 'Mt dette', type: () => 'price' },
        // { field: 'estRealise', header: 'Réalisé ?', type: () => 'dropdown', dropdownList: () => BOOLEAN_TYPE_COMBO }
      ];
    }

    if (this.currentDataUser) {
      this.recuperDonnees();
    }
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, currentDataUser: this.currentDataUser};
    this.args = {...arg};
  }

  requetePrintAvisHospitalisation(arg, message, value) {
    this.metierService.requetePrintAvisHospitalisation({}, {...arg}).subscribe((reponse) => {
      const arrayConstruct: any[] = [];
      arrayConstruct.push({
        File: reponse.body.items[0],
        Name: `${message} ${value}`,
      });
      this.gestionnaireDeModales[1].datasourceSelected = arrayConstruct;
      this.gestionnaireDeModales[1].display = true;

      this.affichModalRapport = true;
    });
  }

  imprimerAvisHospitalisation() {
    this.closeAllModal();

    /*this.metierService.requetePrintDonnees({}, this.getArgs()).subscribe(reponse => {
     const arrayConstruct: any[] = [];
     arrayConstruct.push({
       File: reponse.body.items[0],
       Name: `-- Avis d'hospitalisation Matricule ${this.currentDataUser.matricule}`,
     });
     this.gestionnaireDeModales[0].datasourceSelected = arrayConstruct;
     this.gestionnaireDeModales[0].display = true;
   });*/

    if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.currentDataUser.idDemandePriseEnCharge)) {
      this.alertService.showError(ERROR_MESSAGE_IDENTIFIANT_INVALIDE).show();
      return false;
    }

   this.requetePrintAvisHospitalisation(
       {idDemandePriseEnCharge: this.currentDataUser.idDemandePriseEnCharge},
       '-- Avis d\'hospitalisation Matricule',
       this.currentDataUser.matricule
   );
  }

  imprimerBonPECHospitalisation() {
    this.closeAllModal();

    this.metierService.requetePrintBonPECHospitalisation({}, this.getArgs()).subscribe(reponse => {
      const arrayConstruct: any[] = [];
      arrayConstruct.push({
        File: reponse.body.items[0],
        Name: `-- Bon de prise en charge d'hospitalisation N° ${this.currentDataUser.numBonPriseEnCharge}`,
      });
      this.gestionnaireDeModales[1].datasourceSelected = arrayConstruct;
      this.gestionnaireDeModales[1].display = true;

      this.affichModalRapport = true;
    });
  }

  soinsHospitalisation() {
    this.closeAllModal();

    if (this.currentDataUser.libelleTypeStatut !== STATUT_EN_ATTENTE_DE_CLOTURE) {
      this.alertService.showError(ERROR_MESSAGE_CLOTURE_DEMANDE_PEC).show();
      return false;
    }

    this.metierServiceDemandePEC.requeteRecuperationAdherantHospitalisation(
        {}, {...this.getArgs(), adherant: this.currentDataUser}).subscribe((reponseAdherant) => {

      if (reponseAdherant.body.items.length === 0) {
        this.alertService.showError(ERROR_MESSAGE_ITEMS_VIDE).show();
        return false;
      }

      const dataAdherant = reponseAdherant.body.items[0];

      this.metierServiceDemandePEC.requeteRecuperationSoinsHospitalisation(
          {},
          {
            ...this.getArgs(),
            adherant: {
              idDemandePEC: dataAdherant.idDemandePriseEnCharge,
              idDemandePECLigne: dataAdherant.idDemandePriseEnChargeLigne
            }
          }).subscribe((reponseSoins) => {

        this.gestionnaireDeModales[0].id = 'prolonger_hospitalisation';
        this.gestionnaireDeModales[0].datasourceSelected = {
          adherant: this.currentDataUser,
          reponseAdherant: dataAdherant,
          reponseSoins: reponseSoins.body.items,
          dataExist: true,
          searchUser: false,
          choixHospitalisation: null,
          typeHospitalisation: null,
        };
        this.gestionnaireDeModales[0].display = true;

        this.affichModalHospitalisation = true;
      });
    });

  }

  closeAllModal() {
    this.affichModalHospitalisation = false;
    this.gestionnaireDeModales[0].display = false;
    this.gestionnaireDeModales[0].datasourceSelected = undefined;

    this.affichModalRapport = false;
    this.gestionnaireDeModales[1].display = false;
    this.gestionnaireDeModales[1].datasourceSelected = undefined;
  }

}

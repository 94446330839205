import {NgModule} from '@angular/core';

import {MotifAccordPrealableTableListeComponent} from './motif-accord-prealable-table.liste.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
  imports: [CommonModule, SharedModule],
  exports: [MotifAccordPrealableTableListeComponent],
  declarations: [MotifAccordPrealableTableListeComponent],
  providers: [],
})
export class MotifAccordPrealableTableListeModule {
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {HospitalisationAutreTableMetierService} from './hospitalisation-autre-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {AlertService} from '@app/core/services/alert.service';
import {objectKeys} from 'codelyzer/util/objectKeys';

interface IDataHospitalisationAutres {
  choixHospitalisation: boolean;
  hospitalisation: {
    objectType: any;
    montant: number;
    accordPrealable: string;
    objectChambre: any;
    nombreJour: number;
    prixUnitaireChambre: number;
    montantHorsPec: number;
    ticketModerateur: number;
    numeroChambre: any;
    service: any;
  };
  pharmacie: {
    objectType: any;
    montant: number;
    accordPrealable: string;
    montantHorsPec: number;
    ticketModerateur: number;
  };
  motifSoin: string;
  affichageMontantTotal: {
    hospitalisation: number;
    pharmacie: number;
  };
}

@Component({
  selector: 'app-hospitalisation-autre-table',
  templateUrl: './hospitalisation-autre-table.component.html'
})

export class HospitalisationAutreTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: HospitalisationAutreTableMetierService;
  @Autowired() metierService: HospitalisationAutreTableMetierService;
  @Autowired() alertService: AlertService;

  @Input() adherant: any;
  @Input() modalOpen: any;
  @Input() affichageMontantTotal: any;
  @Output() affichageMontantTotalEvent = new EventEmitter<any>();
  hospitalisationForm: FormGroup;
  pharmacieForm: FormGroup;

  typeHospitalisationCombo$: Observable<any> = new Observable<any[]>();
  typeHospitalisationSelected = '';
  objectTypeHospitalisationSelected: any;
  choixHospitalisationCombo: any[] = [];
  choixHospitalisationSelected: boolean;

  motifSoin: string;

  requestGetTypeHospitalisation: boolean;
  dataRequestGetTypeHospitalisation: any[] = [];

  dataRequestGetChambre: any[] = [];
  chambreCombo: any[] = [];
  objectChambreSelected: any;

  @Input() dataHospitalisationAutres = {
    choixHospitalisation: null,
    hospitalisation: {
      objectType: {},
      montant: 0,
      objectChambre: {},
      nombreJour: 0,
      prixUnitaireChambre: 0,
      montantHorsPec: 0,
      ticketModerateur: 0,
      numeroChambre: '',
      service: '',
    },
    pharmacie: {
      objectType: {},
      montant: 0,
      montantHorsPec: 0,
      ticketModerateur: 0,
    },
    motifSoin: '',
    affichageMontantTotal: {
      hospitalisation: 0,
      pharmacie: 0,
    }
  };
  @Output() dataHospitalisationAutresEvent = new EventEmitter<any>();


  constructor() {
    super();
  }

  ngOnInit(): void {
    this.choixHospitalisationCombo = [
      {label: 'Oui', value: true},
      {label: 'Non', value: false}
    ];

    if (!this.requestGetTypeHospitalisation) {
      this.metierService.requeteRecuperationTypeHospitalisation({}, this.getArgs()).subscribe((response) => {
        this.dataRequestGetTypeHospitalisation = response.body.items;
        this.requestGetTypeHospitalisation = true;

        this.typeHospitalisationCombo$ = of([
          ...this.dataRequestGetTypeHospitalisation.filter((elt) => elt.estHospitalisation === false)
        ]);
      });

      this.metierService.requeteRecuperationChambreHospitalisation({}, this.getArgs()).subscribe((response) => {
        this.dataRequestGetChambre = response.body.items;
        this.chambreCombo = [...this.dataRequestGetChambre];
      });
    }

    this.initFormulaireHospitalisation();
    this.initFormulairePharmacie();
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args, adherant: this.adherant};
    this.args = {...arg};
  }

  initFormulaireHospitalisation() {
    this.hospitalisationForm = this.metierService.initialiserFormulaireHospitalisation(null);
  }

  initFormulairePharmacie() {
    this.pharmacieForm = this.metierService.initialiserFormulairePharmacie(null);
  }

  handleChangeHospitalisation(event: any) {
    this.choixHospitalisationSelected = event.value;

    // console.log('choixHospitalisationSelected : ', this.choixHospitalisationSelected);

    this.typeHospitalisationCombo$ = of([]);
    this.typeHospitalisationSelected = '';

    if (this.choixHospitalisationSelected) {
      this.typeHospitalisationCombo$ = of([
          ...this.dataRequestGetTypeHospitalisation.filter((elt) => elt.estHospitalisation === true)
      ]);
    } else {
      this.typeHospitalisationCombo$ = of([
        ...this.dataRequestGetTypeHospitalisation.filter((elt) => elt.estHospitalisation === false)
      ]);
    }

    /** **************************************************** */
    /** **************************************************** */
    this.dataHospitalisationAutres.choixHospitalisation = this.choixHospitalisationSelected;
    this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
    /** **************************************************** */
  }

  handleChangeType(event: any) {
    this.typeHospitalisationSelected = '';
    this.typeHospitalisationSelected = event.value;

    if (this.choixHospitalisationSelected && this.typeHospitalisationSelected !== '' && this.typeHospitalisationSelected !== null) {
      // this.initFormulaireHospitalisation();

      // tslint:disable-next-line:max-line-length
      this.objectTypeHospitalisationSelected = this.dataRequestGetTypeHospitalisation.find((elt) => elt.libelle === this.typeHospitalisationSelected);

      this.hospitalisationForm.patchValue({
        montant: this.objectTypeHospitalisationSelected.montantAPayer,
        accordPrealable: this.objectTypeHospitalisationSelected.accordPrealable ? 'Oui' : 'Non',
      });

      /** **************************************************** */
      /** **************************************************** */
      this.dataHospitalisationAutres.hospitalisation.objectType = this.objectTypeHospitalisationSelected;
      this.dataHospitalisationAutres.hospitalisation.montant = this.hospitalisationForm.getRawValue().montant;
      this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
      /** **************************************************** */

    } else if (!this.choixHospitalisationSelected && this.typeHospitalisationSelected !== '' && this.typeHospitalisationSelected !== null) {
      // this.initFormulairePharmacie();

      // tslint:disable-next-line:max-line-length
      this.objectTypeHospitalisationSelected = this.dataRequestGetTypeHospitalisation.find((elt) => elt.libelle === this.typeHospitalisationSelected);

      this.pharmacieForm.patchValue({
        montant: this.objectTypeHospitalisationSelected.montantAPayer,
        accordPrealable: this.objectTypeHospitalisationSelected.accordPrealable ? 'Oui' : 'Non',
      });

      /** **************************************************** */
      /** **************************************************** */
      this.dataHospitalisationAutres.pharmacie.objectType = this.objectTypeHospitalisationSelected;
      this.dataHospitalisationAutres.pharmacie.montant = this.pharmacieForm.getRawValue().montant;
      this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
      /** **************************************************** */

      this.changeMontantInPharmacie();
    }
  }

  onchangeMotifSoin(event: any) {
    this.motifSoin = event.target.value;

    /** **************************************************** */
    /** **************************************************** */
    this.dataHospitalisationAutres.motifSoin = this.motifSoin;
    this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
    /** **************************************************** */
  }

  changeMontantInPharmacie(event?: any) {
    this.affichageMontantTotal.pharmacie = 0;

    const montant = +this.pharmacieForm.getRawValue().montant;

    this.objectTypeHospitalisationSelected.montantAPayer = montant;
    this.objectTypeHospitalisationSelected.montantHT = montant;
    this.objectTypeHospitalisationSelected.valueAccordPrealable = this.choixHospitalisationSelected ? 'Oui' : 'Non';

    const requeteCalculMontantTypeChoix$ = this.metierService.requeteCalculMontant({}, {
      adherant: this.adherant,
      dataActe: this.objectTypeHospitalisationSelected
    });
    requeteCalculMontantTypeChoix$.subscribe((res) => {
      const response = res.body.items[0];

      this.pharmacieForm.patchValue({
        montantHorsPec: response.montantHorsPec,
        ticketModerateur: response.ticketModerateur,
      });

      // tslint:disable-next-line:max-line-length
      this.affichageMontantTotal.pharmacie = this.pharmacieForm.getRawValue().montantHorsPec + this.pharmacieForm.getRawValue().ticketModerateur;
      this.affichageMontantTotalEvent.emit(this.affichageMontantTotal);

      /** **************************************************** */
      /** **************************************************** */
      this.dataHospitalisationAutres.pharmacie.objectType = this.objectTypeHospitalisationSelected;
      this.dataHospitalisationAutres.pharmacie.montantHorsPec = this.pharmacieForm.getRawValue().montantHorsPec;
      this.dataHospitalisationAutres.pharmacie.ticketModerateur = this.pharmacieForm.getRawValue().ticketModerateur;
      this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
      /** **************************************************** */
    });
  }



  handleChangeChambre(event: any) {
    const nombreJour = +this.hospitalisationForm.getRawValue().nombreJour;

    // tslint:disable-next-line:max-line-length
    this.objectChambreSelected = this.dataRequestGetChambre.find((elt) => elt.libelle === event.value);

    this.objectChambreSelected.montantHT = this.objectChambreSelected.montantAPayer;
    this.objectChambreSelected.valueAccordPrealable = this.choixHospitalisationSelected ? 'Oui' : 'Non';
    this.objectChambreSelected.quantite = nombreJour ? nombreJour : 1;

    /** **************************************************** */
    /** **************************************************** */
    this.dataHospitalisationAutres.hospitalisation.objectChambre = this.objectChambreSelected;
    this.dataHospitalisationAutres.hospitalisation.montant = this.objectChambreSelected.montantAPayer;
    this.dataHospitalisationAutres.hospitalisation.nombreJour = this.objectChambreSelected.quantite;
    this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
    /** **************************************************** */

    this.verificationActeNombrejour(this.objectChambreSelected.libelle, nombreJour);

    this.calculMontantChangeNbJour({ adherant: this.adherant, dataActe: this.objectChambreSelected });
  }

  changeMontantInHospitalisation(event: any) {
    this.affichageMontantTotal.hospitalisation = 0;

    if (!this.objectChambreSelected) {
      this.alertService.showError(`Veuillez selectionnez une chambre !`).show();
      this.hospitalisationForm.patchValue({
        nombreJour: null
      });

      return;
    }

    const nombreJour = +this.hospitalisationForm.getRawValue().nombreJour;

    this.objectChambreSelected.quantite = nombreJour ? nombreJour : 1;

    this.verificationActeNombrejour(this.objectChambreSelected.libelle, nombreJour);

    this.calculMontantChangeNbJour({ adherant: this.adherant, dataActe: this.objectChambreSelected });
  }

  calculMontantChangeNbJour(args) {
    const { dataActe } = args;
    const requeteCalculMontantChambre$ = this.metierService.requeteCalculMontant({}, args);
    requeteCalculMontantChambre$.subscribe((res) => {
      const response = res.body.items[0];

      this.hospitalisationForm.patchValue({
        prixUnitaireJour: response.montantHT,
        montantHorsPec: response.montantHorsPec,
        ticketModerateur: response.ticketModerateur,
        nombreJour: this.objectChambreSelected.quantite ? this.objectChambreSelected.quantite : 1
      });

      // tslint:disable-next-line:max-line-length
      this.affichageMontantTotal.hospitalisation = this.hospitalisationForm.getRawValue().montantHorsPec + this.hospitalisationForm.getRawValue().ticketModerateur;
      this.affichageMontantTotalEvent.emit(this.affichageMontantTotal);

      /** **************************************************** */
      /** **************************************************** */
      this.dataHospitalisationAutres.hospitalisation.nombreJour = this.objectChambreSelected.quantite;
      this.dataHospitalisationAutres.hospitalisation.prixUnitaireChambre = this.hospitalisationForm.getRawValue().prixUnitaireJour;
      this.dataHospitalisationAutres.hospitalisation.montantHorsPec = this.hospitalisationForm.getRawValue().montantHorsPec;
      this.dataHospitalisationAutres.hospitalisation.ticketModerateur = this.hospitalisationForm.getRawValue().ticketModerateur;
      this.dataHospitalisationAutres.affichageMontantTotal.hospitalisation = this.affichageMontantTotal.hospitalisation;
      this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
      /** **************************************************** */
    });
  }

  verificationActeNombrejour(acte: string, nombreJour: number) {
    if (acte === 'CHAMBRE 1ERE CATEGORIE B (02  LITS)' && nombreJour >= 5) {
      this.alertService.showError(`
      * Acte CHAMBRE 1ERE CATEGORIE B (02 LITS) limité par période ; Max : 3 par semaine - Quantité : 5 <br/>
      * Acte CHAMBRE 1ERE CATEGORIE B (02 LITS) limité par période ; Max : 3 par semaine - Déjà réalisé : 0.00 - Quantité saisie : 5
      `).show();
    }
  }

  changeNumeroChambre($event: Event) {
    /** **************************************************** */
    /** **************************************************** */
    this.dataHospitalisationAutres.hospitalisation.numeroChambre = this.hospitalisationForm.getRawValue().numeroChambre;
    this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
    /** **************************************************** */
  }

  changeService($event: Event) {
    /** **************************************************** */
    /** **************************************************** */
    this.dataHospitalisationAutres.hospitalisation.service = this.hospitalisationForm.getRawValue().service;
    this.dataHospitalisationAutresEvent.emit(this.dataHospitalisationAutres);
    /** **************************************************** */
  }
}

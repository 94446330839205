import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {MaladieChroniqueTableMetierService} from './maladie-chronique-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {CommonUtilities} from '@app/core/utilities/common.utilities';

@Component({
  selector: 'app-maladie-chronique-table',
  templateUrl: './maladie-chronique-table.component.html'
})

export class MaladieChroniqueTableComponent extends SgiListLayoutComponent implements OnInit {
  @Autowired() recuperationListeDonneesMetier: MaladieChroniqueTableMetierService;
  @Autowired() metierService: MaladieChroniqueTableMetierService;

  @Input() currentAssure: any;
  @Input() typeAffichage: any;
  @Input() responseMaladieChronique: any;
  @Output() responseMaladieChroniqueEvent = new EventEmitter<any>();

  formGroup: FormGroup;
  typeRechercheCombo: any[] = [];
  placeholderInputSearch: string;

  responsePrescriptionsMaladieChronique: any;


  constructor() {
    super();
    this.cols = [
      {field: 'codeAffection', header: 'Code affection '},
      {field: 'libelleAffection', header: 'Affection'},
      {field: 'libelleTypeAffection', header: 'Type affection'},
    ];
    this.tableActions = [];
    this.notRowActions = [];
  }

  ngOnInit(): void {
    this.initialiserTypeRechercheCombo();

    this.initFormulaire();

    this.formatTableListAddIndex(this.responseMaladieChronique);
  }

  /**
   * Définition des arguments
   */
  defineArgs(): void {
    super.defineArgs();
    const arg = {...this.args};
    this.args = {...arg};
  }

  /**
   * => return true, si l'objet est vide
   */
  isEmptyObject(obj) {
    return CommonUtilities.isEmptyObject(obj);
  }

  recuperationDonnees(data?: any) {
    const da = {
      ...this.args, lazyLoadEvent: data,
      successMute: true,
      currentAssure: this.currentAssure,
      typeAffichage: this.typeAffichage
    };
    this.args = {...da};
    this.metierService.requeteRecuperationListeDonnees(this.getAllRequestParam(), {...this.args}).subscribe((r_MaladieChronique) => {
      const responseMaladieChronique = r_MaladieChronique.body;

      this.formatTableListAddIndex(responseMaladieChronique);

      this.responseMaladieChroniqueEvent.emit(r_MaladieChronique.body);
    });
  }

  formatTableListAddIndex(responseData) {
    if (responseData === undefined) {
      this.dataSource = [];
      this.totalRecords = 0;
      return;
    }
    this.dataSource = responseData.items;
    this.totalRecords = responseData.count;
    if (this.dataSource && this.dataSource.length > 0) {
      this.dataSource.forEach((dat, index) => {
        dat.id_key = index + 1;
      });
    }
  }

  initFormulaire() {
    this.formGroup = this.metierService.initialiserFormulaire(null);
  }

  initialiserTypeRechercheCombo() {
    this.placeholderInputSearch = 'Rechercher';

    this.typeRechercheCombo = [
      { label: 'Affection', value: 'affection'},
      { label: 'Type affection', value: 'type_affection'},
    ];
  }

  handleChangeTypeRecherche(event: any) {
    if (event.value === null) {
      this.placeholderInputSearch = 'Rechercher';

      this.formGroup.patchValue({ inputSearch: null });

      // fonction de recherche assuré avec les valeurs null
      this.recuperationDonnees();
    }

    if (event.value === 'affection') {
      this.placeholderInputSearch = 'Rechercher par affection';
    }
    if (event.value === 'type_affection') {
      this.placeholderInputSearch = 'Rechercher par type affection';
    }

    this.formGroup = this.metierService.initialiserFormulaire(this.formGroup.getRawValue());
  }

  rechercheItems(event) {
    if (this.formGroup.getRawValue().selectTypeSearch === null) { return false; }

    // fonction de recherche assuré avec les valeurs entrées
    this.recuperationDonnees();
  }

  handleResponsePrescriptionsMaladieChronique(event) {
    this.responsePrescriptionsMaladieChronique = event;
  }

}

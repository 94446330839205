import {AfterViewInit, ChangeDetectionStrategy, Component, OnInit} from '@angular/core';
import {AppSettings} from './app.settings';
import {Settings} from './app.settings.model';
import {TraductionService} from './core/services/traduction.service';
import {EventsService} from './fonctionnalites/authentification/connexion/events.service';
import {Autowired} from '@angular-ru/autowired';
import {SecurityStateService} from './core/states/security/security.state.service';
import {LanguageStateService} from './core/states/language/language.state.service';
import {ListesStateService} from './core/states/listes/listes.state.service';
import {ThemeStateService} from './core/states/theme/theme.state.service';
import {LoaderStateService} from './core/states/loader/loader.state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit, AfterViewInit {
  /**
   * Service de configuration de l'application
   */
  @Autowired() appSettings: AppSettings;

  /**
   * Service de traduction. Recupère les fichiers de langues et initie les configurations de la traduction
   */
  @Autowired() traductionService: TraductionService;

  /**
   * Service d'évènements de l'application
   */
  @Autowired() eventService: EventsService;

  /**
   * Configurations de l'aplication.
   * Une instance de cette classe est crée dans l'application et contient les parametres de l'application.
   */
  public settings: Settings;

  // Services des etats de l'application
  /**
   * Gère la sécurité de l'application
   */
  @Autowired() securityStore: SecurityStateService;

  /**
   * Service d'état de la langue active
   */
  @Autowired() languageStore: LanguageStateService;

  /**
   * Service d'état des paramètres de listes de l' application
   */
  @Autowired() listeStore: ListesStateService;

  /**
   * Service d'état du thème de l'application
   */
  @Autowired() themeStore: ThemeStateService;

  /**
   * Service d'état du compteur de requêtes en cours pour l'affichage du composant de simulation de "chargenemt en cours"
   */
  @Autowired() loaderStore: LoaderStateService;

  constructor() {
    this.settings = this.appSettings.settings;
    this.traductionService.autoLoadTranslations();
    this.eventService.init();
    this.initStores();
  }

  /**
   * Initie tout les états de l'application
   */
  initStores() {
    this.securityStore.init();
    this.languageStore.init(this.appSettings.settings.defaultLanguage);
    this.listeStore.init();
    this.themeStore.init(this.appSettings.settings.theme, this.appSettings.settings.sidebarTheme);
  }

  ngOnInit() {
    this.initStores();
  }

  ngAfterViewInit() {
    this.settings.loadingSpinner = false;
  }
}

import {Component, Input} from '@angular/core';
import {AbstractFormOptionInputs} from '@shared/components/inputs/abstract-form-option-inputs';

@Component({
    selector: 'app-form-multiselect',
    template: `
        <div class="input-container">
            <div class="d-flex justify-between">
                <label for="{{id}}">{{label}}&nbsp;<span *ngIf="required" class="danger-color">*</span></label>
                <div>
                    <app-info-bull-widget [infos]="infos"></app-info-bull-widget>
                </div>
            </div>
            <div>
                <p-multiSelect [required]="required" id="{{id}}"
                               appendTo="body"
                               [styleClass]="'w-100'"
                               [defaultLabel]="placeholder"
                               [options]="options"
                               (onChange)="onChange?.emit($event)"
                               (onBlur)="onBlur?.emit($event)"
                               (onClick)="onClick?.emit($event)"
                               (onFocus)="onFocus?.emit($event)"
                               (show)="onShow?.emit($event)"
                               [formControl]="control"
                               [baseZIndex]="100002" [autoZIndex]="true"
                               [class.ng-dirty]="getFullErrorCondition(control, errorTypes)"
                               [class.ng-invalid]="getFullErrorCondition(control, errorTypes)"></p-multiSelect>
            </div>
            <app-list-form-error [control]="control"
                                 [types]="errorTypes"
                                 [submitted]="submitted"
                                 [messages]="messages"></app-list-form-error>
        </div>`,
})
export class FormMultiselectComponent extends AbstractFormOptionInputs {
    @Input('placeholder') placeholder = 'Sélectionnez';
}

import {Component} from '@angular/core';
import {DialogComponent} from '@shared/behaviors/dialog/dialog.component';

/**
 * Défini le process de traitement des formulaire à l'interieur d'un modale-subtitution
 *
 * Services metiers à implémenter:
 * convertirFormulaireMetier: IConvertirFormulaireMetier;
 * initialiserFormulaireMetier: IInitialiserFormulaireMetier;
 * requeteCreationMetier: IRequeteCreationMetier;
 * requeteModificationMetier: IRequeteModificationMetier;
 */
@Component({
  selector: 'app-atlantis-combo-modal',
  template: `
    <app-dialog [(modalManager)]="modalManager" [header]="header" (onHideDialog)="fermerModale()"
                [width]="width">
      <ng-container class="content">
        <ng-content></ng-content>
      </ng-container>
      <ng-container class="footer">
        <app-cancel-button (click)="fermerModale()" [title]="'Fermer'"></app-cancel-button>
      </ng-container>
    </app-dialog>
  `
})
export class AtlantisComboModalComponent extends DialogComponent {
  constructor() {
    super();
    this.header = 'Combo modal';
    this.width = '70%';
  }
}

import {Injectable} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {Autowired} from '@angular-ru/autowired';
import {UtilisateurPasswordRestApiService} from './utilisateur-password.rest-api.service';
import {IConvertirFormulaireMetier, IInitialiserFormulaireMetier} from '@app/core/interfaces';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {UtilisateurAuthentifieRestApiService} from '@app/fonctionnalites/authentification/utilisateur-authentifie/utilisateur-authentifie.rest-api.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {Observable} from 'rxjs';

@Injectable({providedIn: 'root'})
export class UtilisateurPasswordMetierService implements IConvertirFormulaireMetier, IInitialiserFormulaireMetier {
    // IModificationFullFormulaireMetier
    // IRecuperationFullFormDonneesMetier

    @Autowired() formBuilder: FormBuilder;
    @Autowired() restApi: UtilisateurPasswordRestApiService;
    @Autowired() userAuthRestApi: UtilisateurAuthentifieRestApiService;
    @Autowired() securityStore: SecurityStateService;

    storageUser: IUserConnect = this.securityStore.getUser();

    // userLogoUrl$: Observable<string> = new Observable<any>();

    convertirFormulaire<T>(formGroup: FormGroup, args?: any): T {
        const formdata: T | any = new FormData();
        if (args.imageModel && args.imageModel.file) {
            formdata.append('logo', args.imageModel.file);
        } else {
            formdata.append('logo', null);
        }
        const form = {
            /** Update infos User */
            utilisateurId: formGroup.value.utilisateurId,
            photo: formGroup.value.photo,
            nom: formGroup.value.nom,
            prenom: formGroup.value.prenom,
            login: formGroup.value.login,
            email: formGroup.value.email,

            /** Reset Password */
            // oldPassword: formGroup.value.oldPassword,
            // newPassword: formGroup.value.newPassword,
            // repeatNewPassword: formGroup.value.repeatNewPassword,
        };
        formdata.append('profile', new Blob([JSON.stringify(form)], {type: 'application/octet-binary'}));
        return formdata;
    }

    initialiserFormulaire<T>(datas: T | any, args?: any): FormGroup {
        // this.userLogoUrl$ = of(this.storageUser.personne.logoUrl);

        let formGroup: FormGroup;
        const initObject: T | any = {
            /** Update infos User */
            utilisateurId: this.storageUser.utilisateurId,
            photo: this.storageUser.personne.logoUrl,
            nom: this.storageUser.personne.nom,
            prenom: this.storageUser.personne.prenom,
            login: this.storageUser.personne.raisonSocial,
            email: this.storageUser.personne.email,

            /** Reset Password */
            // oldPassword: '',
            // newPassword: '',
            // repeatNewPassword: '',
        };
        formGroup = this.formBuilder.group({
            utilisateurId: initObject.utilisateurId,
            photo: initObject.photo,
            nom: initObject.nom,
            prenom: initObject.prenom,
            login: initObject.login,
            email: initObject.email,

            // oldPassword: initObject.oldPassword,
            // newPassword: initObject.newPassword,
            // repeatNewPassword: initObject.repeatNewPassword,
        });
        return formGroup;
    }


    modificationFullFormulaire(data: any, args?: any): Observable<any> | Promise<any> | any {

        console.log('modificationFullFormulaire ');
        console.log('data : ', data);
        console.log('args : ', args);

        this.restApi.modificationFullFormulaire(data, args).subscribe(() => {

            // this.securityStore.saveUser(reponseUserConnecte.body);

            // this.userAuthRestApi.requeteRecuperationListeDonnees(null, args).subscribe(reponseUserConnecte => {});
        });
    }


    /**
     * Système de récupération au chargement de la page
     */
    /*requeteRecuperationFullFormDonnee<E, S>(args?: any): Observable<any | S> | Promise<any | S> | any {
      return this.restApi.requeteRecuperationFullFormDonnee(null, args);
    }*/
}

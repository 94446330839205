import {NgModule} from '@angular/core';

import {DemandeDossierMedicalTableComponent} from './demande-dossier-medical-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
  exports: [DemandeDossierMedicalTableComponent],
  declarations: [DemandeDossierMedicalTableComponent],
  providers: [],
})
export class DemandeDossierMedicalTableModule {
}

import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {EvenementModaleComponent} from './evenement.modale.component';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    
  ],
  exports: [EvenementModaleComponent],
  declarations: [EvenementModaleComponent],
  providers: [],
})
export class EvenementModaleModule {
}

import {Component, Input, OnInit} from '@angular/core';
import {ActeARealiseDetailsTableListeMetierService} from './acte-a-realise-details-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';

@Component({
    selector: 'app-acte-a-realise-details-table-liste',
    templateUrl: './acte-a-realise-details-table.liste.component.html'
})

export class ActeARealiseDetailsTableListeComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: ActeARealiseDetailsTableListeMetierService;
    @Autowired() metierService: ActeARealiseDetailsTableListeMetierService;

    @Input('currentDataUser') currentDataUser: any = {};
    dataSourceLength: any;

    constructor() {
        super();
        this.tableActions = [];

    }


    ngOnInit(): void {
        this.cols = [
            {field: 'index', header: ''},
            {field: 'libelle', header: 'Libellé'},
        ];
        this.listPoursuiteActeAResRealise();
    }


    listPoursuiteActeAResRealise() {
        this.metierService.recupererPoursuiteActeAResRealise(this.currentDataUser).subscribe(response => {
            this.dataSource = response.body.items;
            this.dataSourceLength = response.body.items.length;
            this.dataSource.forEach((data, index) => {
                data.index = index + 1;
                this.dataSourceLength++;
            });
        });
    }

}

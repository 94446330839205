import {Component} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {AtlantisMultiselectComponent} from '@shared/behaviors/atlantis-extend-clv/atlantis-multiselect.component';
import {MedecinFormMetierService} from './medecin.form.metier.service';

@Component({
  selector: 'app-medecin-multiselect',
  template: `
    <app-atlantis-multiselect [label]="label"
                              [options]="options$ | async"
                              [hasAction]="hasAction"
                              [actionGestionnaireDeModale]="actionGestionnaireDeModale"
                              [required]="required"
                              [control]="control"
                              [placeholder]="placeholder"
                              [actionColor]="actionColor"
                              [actionIcon]="actionIcon"
                              (onChange)="onChange?.emit($event)"
                              (onBlur)="onBlur?.emit($event)"
                              (onClick)="onClick?.emit($event)"
                              (onFocus)="onFocus?.emit($event)"
                              (onShow)="onShow?.emit($event)"
                              (onHide)="onHide?.emit($event)"
                              (actionDeclenche)="actionDeclenche.emit($event)">
    </app-atlantis-multiselect>
  `
})
export class MedecinMultiselectComponent extends AtlantisMultiselectComponent {
  @Autowired() recupererOptionsMetier: MedecinFormMetierService;

  constructor() {
    super();
    this.label = 'Medecin';
    this.hasAction = true;
  }
}

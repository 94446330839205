import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';

import {LierBordereauModaleListeFeatureModule} from '@app/fonctionnalites/factures/prise-en-charge/lier-bordereau-modale-liste/lier-bordereau-modale-liste.feature.module';
import {ModaleRessaisirFactureFeatureComponent} from './modale-ressaisir-facture.feature.component';
import {InformationFactureModule} from '@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/information/information-facture.module';
import {AffectionFactureModule} from '@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/affection/affection-facture.module';
import {ActesFactureModule} from '@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/actes/actes-facture.module';
import {PrescriptionsFactureModule} from '@app/fonctionnalites/factures/prise-en-charge/Detail-modale-modifier-facture/prescriptions/prescriptions-facture.module';
import {DemandePecDocumentModule} from '@app/fonctionnalites/demande-pec/composants/demande-pec-document/demande-pec-document.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
        LierBordereauModaleListeFeatureModule,
        InformationFactureModule,
        AffectionFactureModule,
        ActesFactureModule,
        PrescriptionsFactureModule,
        DemandePecDocumentModule,
    ],
    exports: [
        ModaleRessaisirFactureFeatureComponent,
    ],
    declarations: [
        ModaleRessaisirFactureFeatureComponent,
    ],
    providers: [],
})
export class ModaleRessaisirFactureFeatureModule {
}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {Observable, of} from 'rxjs';
import {IRecuperationFullFormDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-full-form-donnees-requete';
import {IModificationFullFormulaireRequete} from '@app/core/interfaces/actions/i-modification-full-formulaire-requete';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {RequestUtilities} from '@app/core/utilities/request-utilities';
import {API_UPDATE_DATA_USER} from '@app/core/constantes';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';

@Injectable({providedIn: 'root'})
export class UtilisateurPasswordRestApiService implements IRecuperationFullFormDonneesRequete, IModificationFullFormulaireRequete {
    @Autowired() restService: SgiRestService;

    @Autowired() securityStore: SecurityStateService;

    storageUser: IUserConnect = this.securityStore.getUser();

    requeteRecuperationFullFormDonnee<E, S>(data: any, args?: any): Observable<any | S> | Promise<any | S> | any {
        return this.restService.getRequest(`${WebUtilities.baseUrlApi()}utilisateur/profile`).pipe(
            map((reponse: HttpResponse<any>) => RequestUtilities.ajustementRecuperationFullForm(reponse))
        );
    }

    modificationFullFormulaire(data: any, args?: any): Observable<any> | Promise<any> | any {

        console.log('**************************************** : ');
        console.log('data : ', data);
        console.log('args : ', args);

        const sendData = {
            ItemsToSave: [
                {
                    email: data.formGroup.value.email,
                    login: data.formGroup.value.login,
                    nom: data.formGroup.value.nom,
                    photo: CommonUtilities.isDataNullUndefinedFalseEmpty(data.imageModel)
                        ? data.formGroup.value.photo : data.imageModel.fullDataUrl,
                    prenom: data.formGroup.value.prenom,
                    idCurrentUser: data.formGroup.value.utilisateurId,

                    IdCurrentSession: this.storageUser.idSessionUtilisateur,
                    IdCurrentTenant: this.storageUser.tenantId,
                    // Password: '',
                    // ConfirmNewPassword: '',
                    // NewPassword: '',
                }
            ],
        };

        console.log('sendData : ', sendData);

        const body = {items: []};
        return of({body: body});

        return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_UPDATE_DATA_USER}`, sendData);
        /*.pipe(map((response: HttpResponse<any>) => {
            return response;
          }));*/
    }
}

import {NgModule} from '@angular/core';

import {MaladieChroniqueDossierTableComponent} from './maladie-chronique-dossier-table.component';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {MaladieChroniqueTableModule} from '@app/fonctionnalites/dossier-medical-prestation/composants/maladie-chronique-dossier-table/maladie-chronique-table/maladie-chronique-table.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        MaladieChroniqueTableModule,
    ],
  exports: [MaladieChroniqueDossierTableComponent],
  declarations: [MaladieChroniqueDossierTableComponent],
  providers: [],
})
export class MaladieChroniqueDossierTableModule {
}

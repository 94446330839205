import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {PrescriptionAutocompleteFormRestApiService} from './prescription-autocomplete.form.rest-api.service';
import {Observable} from 'rxjs';
import {IRecupererOptionsMetier} from '@app/core/interfaces/metier/i-recuperer-options-metier';
import {map} from 'rxjs/operators';
import {HttpResponse} from '@angular/common/http';
import {LoaderStateService} from '@app/core/states/loader/loader.state.service';

@Injectable({providedIn: 'root'})
export class PrescriptionAutocompleteFormMetierService implements IRecupererOptionsMetier {
  @Autowired() restApi: PrescriptionAutocompleteFormRestApiService;

  @Autowired() loaderStateService: LoaderStateService;


  recupererOptions(args?: any): Observable<Array<any>> {
    return this.restApi.requeteRecuperationListeDonnees(null, args).pipe(
        map((response: HttpResponse<any>) => {
          this.loaderStateService.decrement();

          response.body.items.forEach(v => {
            v.label = v.libelle;
            v.value = v.libelle;
          });

          this.loaderStateService.decrement();
          return response.body.items;
        })
    );
  }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedModule} from '@shared/shared.module';
import {AdherentAutocompleteComponent} from './adherent-autocomplete.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
  ],
  declarations: [AdherentAutocompleteComponent],
  exports: [AdherentAutocompleteComponent]
})
export class AdherentAutocompleteFormModule {
}


import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {DatePipe} from '@angular/common';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {
  API_RECUPERER_PRESCRIPTION_MALADIE_CHRONIQUE_DOSSIER_MEDICAL_ENCOURS,
} from '@app/core/constantes';


@Injectable({providedIn: 'root'})
export class PrescriptionMaladieChroniqueTableRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;
  @Autowired() datePipe: DatePipe;

  storageUser: IUserConnect = this.getUser();

  postArgsMute = {successMute: true, errorMute: false, headers: null, params: null};

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {

    let constructorParamRequest: any;

    if (args.formGroup.value.selectTypeSearch === '' || args.formGroup.value.selectTypeSearch === null) {
      if (args.typeAffichage === 'liste') {
        constructorParamRequest = {
          IdMaladieChronique: args.currentRow.idMaladieChronique,
          InfoSearchIdMaladieChronique: {Consider: true},
          InfoSearchstatut: {IsOrderByField: true, Consider: false},
        };
      }
      if (args.typeAffichage === 'historique') {
        constructorParamRequest = {
          IdMaladieChronique: args.currentRow.idMaladieChronique,
          InfoSearchIdMaladieChronique: {Consider: true},
          InfoSearchdateFin: {IsOrderByField: true, Consider: false},
        };
      }
    }
    if (args.formGroup.value.selectTypeSearch === 'medicament') {
      if (args.typeAffichage === 'liste') {
        constructorParamRequest = {
          IdMaladieChronique: args.currentRow.idMaladieChronique,
          InfoSearchIdMaladieChronique: {Consider: true},
          InfoSearchlibelleMedicament: {Consider: true},
          InfoSearchstatut: {IsOrderByField: true, Consider: false},
          libelleMedicament: args.formGroup.value.inputSearch,
        };
      }
      if (args.typeAffichage === 'historique') {
        constructorParamRequest = {
          IdMaladieChronique: args.currentRow.idMaladieChronique,
          InfoSearchIdMaladieChronique: {Consider: true},
          InfoSearchdateFin: {IsOrderByField: true, Consider: false},
          InfoSearchlibelleMedicament: {Consider: true},
          libelleMedicament: args.formGroup.value.inputSearch,
        };
      }
    }
    if (args.formGroup.value.selectTypeSearch === 'medecin') {
      if (args.typeAffichage === 'liste') {
        constructorParamRequest = {
          IdMaladieChronique: args.currentRow.idMaladieChronique,
          InfoSearchIdMaladieChronique: {Consider: true},
          InfoSearchnomCompletPersonneMedecin: {Consider: true},
          InfoSearchstatut: {IsOrderByField: true, Consider: false},
          nomCompletPersonneMedecin: args.formGroup.value.inputSearch,
        };
      }
      if (args.typeAffichage === 'historique') {
        constructorParamRequest = {
          IdMaladieChronique: args.currentRow.idMaladieChronique,
          InfoSearchIdMaladieChronique: {Consider: true},
          InfoSearchdateFin: {IsOrderByField: true, Consider: false},
          InfoSearchnomCompletPersonneMedecin: {Consider: true},
          nomCompletPersonneMedecin: args.formGroup.value.inputSearch,
        };
      }
    }
    if (args.formGroup.value.selectTypeSearch === 'freq_periodicite') {
      if (args.typeAffichage === 'liste') {
        constructorParamRequest = {
          IdMaladieChronique: args.currentRow.idMaladieChronique,
          InfoSearchIdMaladieChronique: {Consider: true},
          InfoSearchfrequencePeriodicite: {Consider: true},
          InfoSearchstatut: {IsOrderByField: true, Consider: false},
          frequencePeriodicite: args.formGroup.value.inputSearch,
        };
      }
      if (args.typeAffichage === 'historique') {
        constructorParamRequest = {
          IdMaladieChronique: args.currentRow.idMaladieChronique,
          InfoSearchIdMaladieChronique: {Consider: true},
          InfoSearchdateFin: {IsOrderByField: true, Consider: false},
          InfoSearchfrequencePeriodicite: {Consider: true},
          frequencePeriodicite: args.formGroup.value.inputSearch,
        };
      }
    }
    if (args.formGroup.value.selectTypeSearch === 'statut') {
      constructorParamRequest = {
        IdMaladieChronique: args.currentRow.idMaladieChronique,
        InfoSearchIdMaladieChronique: {Consider: true},
        InfoSearchstatut: {IsOrderByField: true, Consider: true},
        statut: args.formGroup.value.inputSearch,
      };
    }

    // update du paramètre
    data.SortOrder = null;

    const sendData = {
      ...data,
      itemToSearch: {
        ...data.itemToSearch,
        ...constructorParamRequest,
      }
    };

    // tslint:disable-next-line:max-line-length
    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_PRESCRIPTION_MALADIE_CHRONIQUE_DOSSIER_MEDICAL_ENCOURS}`, sendData, {...this.postArgsMute});
  }

}

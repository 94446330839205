import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InfoBullWidgetComponent} from '@shared/components/widgets/info-bull-widget/info-bull-widget.component';

@NgModule({
    imports: [
        CommonModule,
    ],
    declarations: [
        InfoBullWidgetComponent,
    ],
    exports: [
        InfoBullWidgetComponent,
    ]
})
export class InfoBullWidgetComponentModule {
}

import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {tap} from 'rxjs/operators';
import {Observable} from 'rxjs';
import {IErrorApi, IMessageMute, IRestRequests, OptionsRequestType} from '@app/core/interfaces';
import {AlertService} from '@app/core/services/alert.service';
import {ErreursUtilities} from '@app/core/utilities/erreurs.utilities';
import {ClvMessage} from 'clv-angular-boot';

/**
 * Implémentation des methodes REST de base (GET, POST, PUT, DELETE)
 */
@Injectable({
    providedIn: 'root'
})
export class BasicRestService implements IRestRequests {

    static deleleteArgs = {successMute: true, errorMute: true, headers: null, params: null};
    static getArgs = {successMute: true, errorMute: true, headers: null, params: null};
    static postArgs = {successMute: false, errorMute: false, headers: null, params: null};
    static putArgs = {successMute: false, errorMute: false, headers: null, params: null};

    /**
     * Service de requêtes http
     */
    @Autowired() protected httpClient: HttpClient;
    /**
     * Service d'affichage de messages d'alertes
     */
    @Autowired() alertService: AlertService;
    /**
     * Message de succès par défaut
     */
    protected defaultMessageSucces: ClvMessage =  new ClvMessage('Succès', 'L\'opération s\'est achevée avec succès');
    /**
     * Affiche un message de succès
     *
     * @param successMute
     * @param message
     */
    protected afficherMessageSucces(successMute: boolean, message: ClvMessage = this.defaultMessageSucces) {
        const shower = this.alertService.showSuccess(message.text);
        if (!successMute) {
            shower.show();
        }
    }
    /**
     * Afficher message d'erreur
     *
     * @param errorMute
     * @param error1
     */
    protected afficherMessageErreur(errorMute: boolean, error1: HttpErrorResponse | any) {
        const error: IErrorApi = error1.error;
        const shower = this.alertService.showError(ErreursUtilities.recupererMessageException(error));
        if (!errorMute) {
            shower.show();
        }
    }
    /**
     * Requête DELETE
     *
     * @param url
     * @param args
     */
    deleteRequest(url: string, args: any | OptionsRequestType | IMessageMute = BasicRestService.deleleteArgs): any | Observable<any> {
        return this.httpClient.delete(url, {observe: 'response', headers: args.headers || null, params: args.params || null }).pipe(
            tap(() => {
                this.afficherMessageSucces(args.successMute);
            }, (error1: HttpErrorResponse) => {
                this.afficherMessageErreur(args.errorMute, error1);
            })
        );
    }
    /**
     * Requête GET
     *
     * @param url
     * @param args
     */
    getRequest(url: string, args: any | OptionsRequestType | IMessageMute = BasicRestService.getArgs): any | Observable<any> {
        return this.httpClient.get(url, {observe: 'response', headers: args.headers || null, params: args.params || null }).pipe(
            tap(() => {
                this.afficherMessageSucces(args.successMute);
            }, (error1: HttpErrorResponse) => {
                this.afficherMessageErreur(args.errorMute, error1);
            })
        );
    }
    /**
     * Requête POST
     *
     * @param url
     * @param data
     * @param args
     */
    postRequest(url: string, data: any, args: any | OptionsRequestType | IMessageMute = BasicRestService.postArgs): any | Observable<any> {
        return this.httpClient.post(url, data, {observe: 'response', headers: args.headers || null, params: args.params || null}).pipe(
            tap(() => {
                this.afficherMessageSucces(args.successMute);
            }, (error1: HttpErrorResponse) => {
                this.afficherMessageErreur(args.errorMute, error1);
            })
        );
    }
    /**
     * Requête PUT
     *
     * @param url
     * @param data
     * @param args
     */
    putRequest(url: string, data: any,
               args: any | OptionsRequestType | IMessageMute = BasicRestService.putArgs): any | Observable<any> {
        return this.httpClient.put(url, data, {observe: 'response', headers: args.headers || null, params: args.params || null}).pipe(
            tap(() => {
                this.afficherMessageSucces(args.successMute);
            }, (error1: HttpErrorResponse) => {
                this.afficherMessageErreur(args.errorMute, error1);
            })
        );
    }
}

import {Component} from '@angular/core';
import {PageLayoutComponent} from '../../../shared/behaviors/page-layout/page-layout.component';
import * as resetPasswordConstantes from '../../../fonctionnalites/authentification/reset-password/reset-password.constante';

@Component({
  selector: 'app-activer-utilisateur',
  templateUrl: './activer-utilisateur.component.html'
})
export class ActiverUtilisateurComponent extends PageLayoutComponent {
  resetPasswordConstantes = resetPasswordConstantes;
}

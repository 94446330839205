import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-confirm-error',
    template: `<p-confirmDialog header="Confirmation"
                                appendTo="body"
                                styleClass="prime-ng-confirm-error {{otherClass}}"
                                rejectButtonStyleClass="rejected-button-class"
                                acceptButtonStyleClass="accepted-button-class"
                                dismissableMask="false"
                                icon="pi pi-exclamation-triangle"
                                [key]="key"
                                [baseZIndex]="100002"
                                [autoZIndex]="true"></p-confirmDialog>`
})
export class ConfirmErrorComponent {
    @Input('key') key: any;
    @Input('otherClass') otherClass: string;
}

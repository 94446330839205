import {Injectable} from '@angular/core';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {Observable, of} from 'rxjs';
import {Autowired} from '@angular-ru/autowired';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {IUserConnect} from '@app/core/interfaces/user/i-user-connect';
import {SecurityStateService} from '@app/core/states/security/security.state.service';
import {API_CALCULER_ACTES_DEMANDE_PEC, API_RECUPERER_ACTES_DEMANDE_PEC} from '@app/core/constantes';
import {DatePipe} from '@angular/common';

@Injectable({providedIn: 'root'})
export class ListDocumentTableListeRestApiService extends SecurityStateService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;
  @Autowired() datePipe: DatePipe;

  storageUser: IUserConnect = this.getUser();

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    const body = [];
    return of({body: body});
  }

  recupererMontantActe(data: any, args?: any): Observable<any> | Promise<any> | any {
    // console.log('data : ', data);
    // console.log('args : ', args);

    const { acte, adherant } = args;

      // update du paramètre
      data.SortOrder = null;

    const sendData = {...data, itemToSearch: {
        ...data.itemToSearch,
        idPrestataire: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
        idActeMedical: acte.idActeMedical,
        datePrestation: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
        estGroupeSoin: false,
        idActeChambreGroupeSoin: 0,
        idSouscriptionLigne: adherant.idSouscriptionLigne,
        // quantite: this.quantite != null ? this.quantite : 1,
      }};

    return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_RECUPERER_ACTES_DEMANDE_PEC}`, sendData);
  }

  recupererMontantPEC(data: any, args?: any): Observable<any> | Promise<any> | any {
      // console.log('data : ', data);
      // console.log('args : ', args);

      const { acte, adherant, quantiteActe } = args;

      const sendData = {...data, ItemsToSave: [
              {
                  idActe: acte.idActeMedical,
                  idPrestataire: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                  idPrestataireMedical: JSON.parse(window.sessionStorage.getItem('currentUser')).personne.idPrestataireMedical,
                  idSouscriptionLigne: adherant.idSouscriptionLigne,
                  montantHT: acte.montantAPayer,
                  accordPrealable: acte.accordPrealable,
                  dateRealisation: this.datePipe.transform(Date.now(), 'yyyy-MM-dd'),
                  quantite: quantiteActe ? quantiteActe : 1,
                  estActive: true,
                  // montantDette:?,
                  isDeleted: acte.isDeleted,
              },
          ]
      };

      return this.restService.postRequest(`${WebUtilities.baseUrlApi()}${API_CALCULER_ACTES_DEMANDE_PEC}`, sendData);
  }
}

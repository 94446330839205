import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ListActeTableListeMetierService} from './list-acte-table.liste.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {AUCUN_ASSURE_RECHERCHER, FORMULAIRE_INVALIDE_MESSAGE} from '@app/core/constantes';
import {FormGroup} from '@angular/forms';
import {DemandePriseChargeMetierService} from '@app/fonctionnalites/demande-pec/demande-prise-charge/demande-prise-charge.metier.service';
import {objectKeys} from 'codelyzer/util/objectKeys';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {response} from 'express';

@Component({
    selector: 'app-list-acte-table-liste',
    templateUrl: './list-acte-table.liste.component.html',
    styleUrls: ['./list-acte-table.css'],
})

export class ListActeTableListeComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: ListActeTableListeMetierService;
    @Autowired() metierService: ListActeTableListeMetierService;

    @Autowired() demandePECMetierService: DemandePriseChargeMetierService;

    @Input() adherant: any;
    @Input() medecinSelected: any;
    @Input() dataListActes: any[] = [];
    @Output() dataListActesSelectedEvent = new EventEmitter<any[]>();

    acteSelectedForm: FormGroup;
    descriptionsForm: FormGroup;
    @Input() descriptionsAll: any;
    @Output() descriptionsEvent = new EventEmitter<any[]>();
    acteSelected: any;
    reponseActeSelected: any;
    montantTotal: number;
    disabledInput: boolean;


    constructor() {
        super();
        this.tableActions = [
            this.supprimerAction
        ];
    }

    ngOnInit(): void {
        this.cols = [
            {field: 'libelle', header: 'Acte'},
            {field: 'montantHT', header: 'Prix', type: () => 'price'}, // , type: () => 'price'
            {field: 'quantite', header: 'Qté'},
            {
                field: 'accordPrealable', header: 'Accord préalable ?', type: () => 'chip', states: [
                    {key: true, label: 'Oui', classes: 'color-row-red-PEC'},
                    {key: false, label: 'Non', classes: ''},
                ]
            },
            {field: 'montantHorsPecList', header: 'Montant Hors PEC', type: () => 'price'},
            {field: 'ticketModerateurList', header: 'Ticket modérateur', type: () => 'price'},
            {
                field: 'descriptions', header: 'Commentaire', type: (data) => {
                    if (data['accordPrealable'] === true) {
                        return 'input';
                    }
                    return null;
                }
            },
        ];

        this.initFormulaire();
        this.recuperationActesSave();
        this.sytheseCalculMontanTotal(this.dataListActes);
    }

    supprimerActe(index) {
        this.dataListActes.splice(index, 1);
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initFormulaire() {
        this.acteSelectedForm = this.demandePECMetierService.initialiserFormulaireActeSelected(null);

        this.descriptionsForm = this.demandePECMetierService.initialiserFormulaireDescripActe(null);
        this.descriptionsForm.patchValue({
            descriptionsAll: this.descriptionsAll
        });

        this.disabledInput = true;
    }

    recuperationActesSave() {
        if (!this.demandePECMetierService.actes || this.demandePECMetierService.actes.length === 0) {
            const reqAtes = this.demandePECMetierService.recupererActes({});
            reqAtes.subscribe(res => {
                this.demandePECMetierService.actes = res.body.items;
            });
        }
    }

    actionSelectedActe(event) {

        // console.log('actionSelectedActe : ', this.adherant);

        this.acteSelected = event;
        let ErrorAlert: any;

        if (!this.adherant || objectKeys(this.adherant).length === 0) {
            ErrorAlert = this.alertService.showError(AUCUN_ASSURE_RECHERCHER);
            ErrorAlert.show();
            this.acteSelectedForm.patchValue({libelleActe: null});
            return;
        }
        /* if (!this.medecinSelected || objectKeys(this.medecinSelected).length === 0) {
          ErrorAlert = this.alertService.showError(AUCUN_MEDECIN_RECHERCHER);
          ErrorAlert.show();
          this.acteSelectedForm.patchValue({
            libelleActe: null
          });
          return;
        } */

        const args = {
            acte: this.acteSelected,
            adherant: this.adherant,
        };

        const findElt = this.dataListActes.findIndex((elt) => elt.libelle === this.acteSelected.libelle);
        if (findElt >= 0) {
            ErrorAlert = this.alertService.showError('Cet acte a déjà été ajouté !');
            ErrorAlert.show();
            this.resetFormulaure();
            return;
        }

        this.recupererMontantActe({}, args);
    }

    recupererMontantActe(data?, args?: any) {
        const recupererMontantActe$ = this.metierService.recupererMontantActe({}, args);
        recupererMontantActe$.subscribe((res) => {
            const reponse = res.body.items[0];

            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(reponse.descriptions)) {
                this.alertService.showError(`${reponse.descriptions}`).show();
                // return;
            }
            if (reponse.message && !CommonUtilities.isDataNullUndefinedFalseEmpty(reponse.message)) {
                this.alertService.showError(`${reponse.message}`).show();
                // return;
            }

            this.reponseActeSelected = {
                ...reponse,
                idActeMedical: reponse.idActeMedical,
                montantAPayer: reponse.montantAPayer,
                accordPrealable: reponse.accordPrealable,
                isDeleted: reponse.isDeleted,
                libelle: reponse.libelle,
            };

            this.acteSelectedForm.patchValue({
                montantActe: reponse.montantAPayer,
                quantiteActe: 1,
            });

            this.saisirQuantiteInput(reponse);
        });
    }

    saisirQuantiteInput(reponse) {
        if (reponse.quantiteEnSaisie) {
            // tslint:disable-next-line:max-line-length
            this.acteSelectedForm = this.demandePECMetierService.initialiserFormulaireActeSelectedQuantite(this.acteSelectedForm.getRawValue());
            this.disabledInput = false;
            this.dataListActesSelectedEvent.emit(this.dataListActes);
        } else {
            this.acteSelectedForm = this.demandePECMetierService.initialiserFormulaireActeSelected(this.acteSelectedForm.getRawValue());
            this.disabledInput = true;
            this.dataListActesSelectedEvent.emit(this.dataListActes);
        }

        this.acteSelectedForm.patchValue({
            montantActe: reponse.montantAPayer,
            quantiteActe: 1,
        });
    }

    ajouterActe() {
        if (CommonUtilities.isDataNullUndefinedFalseEmpty(this.acteSelected)) {
            this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        if (!this.acteSelectedForm.getRawValue().libelleActe ||
            !this.acteSelectedForm.getRawValue().quantiteActe) {
            this.alertService.showError(FORMULAIRE_INVALIDE_MESSAGE).show();
            return;
        }

        // console.log('reponseActeSelected : ', this.reponseActeSelected);

        /**
         * Ajout temporaire de l'acte avant la requête de calcul et l'ajout des autres données
         */
        const dataListActes = {
            ...this.reponseActeSelected,

            idActe: this.reponseActeSelected.idActeMedical,
            montantHT: this.reponseActeSelected.montantAPayer,
            quantite: this.acteSelectedForm.getRawValue().quantiteActe,
            montantHorsPecList: 0,
            ticketModerateurList: 0,
            descriptions: '',
            libelle: this.reponseActeSelected.libelle,
        };

        this.dataListActes = [...this.dataListActes, dataListActes];

        // console.log('dataListActes : ', this.dataListActes);

        /**
         *
         */

        const args = {
            acte: this.reponseActeSelected, // [...this.dataListActes, ...this.reponseActeSelected],
            adherant: this.adherant,
            quantiteActe: +this.acteSelectedForm.getRawValue().quantiteActe,
        };
        this.calculeMontantActe({}, args);
    }

    calculeMontantActe(data?, args?: any) {
        this.metierService.recupererMontantPEC({}, args).subscribe((res) => {
            const reponseActe = res.body.items[0];

            // console.log('reponseActe : ', reponseActe);


            if (!CommonUtilities.isDataNullUndefinedFalseEmpty(reponseActe.descriptions)) {
                this.alertService.showError(`${reponseActe.descriptions}`).show();
                // return;
            }

            /**
             *
             */
            /*const dataListActes = {
                ...this.reponseActeSelected,

                ...reponseActe,
                montantHorsPecList: reponseActe.accordPrealable ? '' : reponseActe.montantHorsPec,
                ticketModerateurList: reponseActe.accordPrealable ? '' : reponseActe.ticketModerateur,
                libelle: this.reponseActeSelected.libelle,

                /!** annulation de la description si contenu existe *!/
                descriptions: '',
            };
            this.dataListActes = [...this.dataListActes, dataListActes];*/
            /**
             *
             */

            if (this.dataListActes && this.dataListActes.length > 0) {
                this.dataListActes.forEach((item, index) => {
                    item.id_key = index + 1;
                    // item.idActeMedical = item.idActe;
                    // item.montantAPayer = item.montantHT;

                    if (item.idActe === reponseActe.idActe) {
                        item.idActe = reponseActe.idActe;
                        item.idActeMedical = reponseActe.idActe;
                        item.montantAPayer = reponseActe.montantHT;

                        item.montantHorsPecList = reponseActe.accordPrealable ? '' : reponseActe.montantHorsPec;
                        item.ticketModerateurList = reponseActe.accordPrealable ? '' : reponseActe.ticketModerateur;

                        item.montantHorsPec = reponseActe.montantHorsPec;
                        item.ticketModerateur = reponseActe.ticketModerateur;

                        item.montantHT = reponseActe.montantHT;
                        item.quantite = reponseActe.quantite;
                        item.descriptions = '';

                        item.idPrestataireMedical = reponseActe.idPrestataireMedical;
                        item.idPrestation = reponseActe.idPrestation;
                        item.idPrestationActe = reponseActe.idPrestationActe;
                        item.idPrestationImportRepriseLigne = reponseActe.idPrestationImportRepriseLigne;
                        item.idSouscriptionLigne = reponseActe.idSouscriptionLigne;

                        item.partAssurance = reponseActe.partAssurance;
                        item.partAssureNet = reponseActe.partAssureNet;
                        item.plafondActe = reponseActe.plafondActe;
                        item.tauxActe = reponseActe.tauxActe;
                    }
                });

                // console.log('dataListActes : ', this.dataListActes);
            }

            // calcul du montant total, ticket moderateur
            this.sytheseCalculMontanTotal(this.dataListActes);

            // this.resetFormulaure();
            this.acteSelectedForm.reset();

            this.dataListActesSelectedEvent.emit(this.dataListActes);

            this.acteSelected = null;
        });
    }

    /**
     * Effectue une demande de suppression.
     * @param data
     */
    demanderSuppression(data: any) {
        // console.log('demanderSuppression : ', data);

        let shower: any;
        shower = this.alertService.showWarning(this.messageDemandeSuppression);
        shower.accept = () => {
            const index = this.dataListActes.findIndex((elt) => elt.id_key === data.id_key);
            this.dataListActes.splice(index, 1);
            this.dataListActesSelectedEvent.emit(this.dataListActes);

            // calcul du montant total, ticket moderateur
            this.sytheseCalculMontanTotal(this.dataListActes);
        };
        shower.reject = () => {
            this.rejeterSuppressionElement(data);
        };
        shower.show();
    }

    resetFormulaure() {
        this.acteSelectedForm.patchValue({
            libelleActe: null,
            montantActe: null,
            quantiteActe: null,
        });
    }

    ajouterCommentairActe() {
        console.log('ajouterCommentairActe :: ');
    }

    sytheseCalculMontanTotal(array: any[]) {
        this.montantTotal = 0;

        array.forEach((item) => {
            if (!item.accordPrealable) {
                this.montantTotal = this.montantTotal + (item.ticketModerateur + item.montantHorsPec);
            }
        });
    }

    onchangeDescriptions(event) {
        this.descriptionsEvent.emit(event.target.value);
    }

}

import {NgModule} from '@angular/core';

import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {SharedModule} from "@shared/shared.module";
import { PrescriptionsFactureModifiabbleComponent } from './prescriptions-facture-modifiabble.component';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        SharedModule,
    ],
  exports: [PrescriptionsFactureModifiabbleComponent],
  declarations: [PrescriptionsFactureModifiabbleComponent],
  providers: [],
})
export class PrescriptionsFactureModifiabbleModule {
}

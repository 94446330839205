import {Observable} from 'rxjs';

const hasSupport = 'IntersectionObserver' in window;

/**
 * Utilitaires de directives
 */
// @dynamic
export class DirectiveLazyUtilities {
  /**
   * Permet d'exxecuter les directive de maniere paresseuses.
   * @param element
   * @param options
   */
  static inView(
      element: Element,
      options: IntersectionObserverInit = {
        root: null,
        threshold: 0.5,
      }
  ) {
    return new Observable((subscriber) => {
      if (!hasSupport) {
        subscriber.next(true);
        subscriber.complete();
      }

      const observer = new IntersectionObserver(([entry]) => {
        subscriber.next(entry.isIntersecting);
      }, options);

      observer.observe(element);

      return () => observer.disconnect();
    });
  }
}

import {Component} from '@angular/core';
import {PageLayoutComponent} from '@shared/behaviors/page-layout/page-layout.component';
import {CommonUtilities} from '@app/core/utilities/common.utilities';
import {
    PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE,
} from '@app/core/constantes';

@Component({
    selector: 'app-demande-pec',
    templateUrl: './prestation-pharmacie.component.html'
})
export class PrestationPharmacieComponent extends PageLayoutComponent {

    permissionViewTabPrescriptionsPeriodique: boolean;

    constructor() {
        super();
        this.title = 'Prestation Pharmacie';
        this.icon = 'assignment';
        this.hasToolbar = false;

        this.permissionViewTabPrescriptionsPeriodique =
            CommonUtilities.currentUserHasPermissions(PERMISSION_PRESTATION_PHARMACIE_MALADIECHRONIQUE);
    }
}

import {Component, Input, OnInit} from '@angular/core';
import {InformationAssureTableMetierService} from './information-assure-table.metier.service';
import {Autowired} from '@angular-ru/autowired';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';
import {FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-information-assure-table',
    templateUrl: './information-assure-table.component.html'
})

export class InformationAssureTableComponent extends SgiListLayoutComponent implements OnInit {
    @Autowired() recuperationListeDonneesMetier: InformationAssureTableMetierService;
    @Autowired() metierService: InformationAssureTableMetierService;

    @Autowired() datePipe: DatePipe;

    @Input() modalOpen: any;
    @Input() idDemandePriseEnCharge: any;
    @Input() nomPrenoms: any;
    @Input() dateNaissance: any;
    @Input() genre: any;
    @Input() dateDemande: any;
    @Input() prestataire: any;
    adherentForm: FormGroup;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.initFormulaire();

        this.metierService.requeteGetAssure({}, {idDemandePriseEnCharge: this.idDemandePriseEnCharge}).subscribe(res => {
            this.nomPrenoms = res.body.items[0].nomAssure;
            this.dateNaissance = res.body.items[0].dateNaissance;
            this.genre = res.body.items[0].sexe;
            this.dateDemande = res.body.items[0].dateDemande;
            this.prestataire = res.body.items[0].raisonSocialePrestataire;

            this.updateDataInput();
        });
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    initFormulaire() {
        return this.adherentForm = this.metierService.initialiserFormulaireAssure(null);
    }

    updateDataInput() {
        this.adherentForm.patchValue({
            nomPrenoms: this.nomPrenoms,
            dateNaissance: this.datePipe.transform(this.dateNaissance, 'dd/MM/yyyy'),
            genre: this.genre,
            dateDemande: this.datePipe.transform(this.dateDemande, 'dd/MM/yyyy'),
            prestataire: this.prestataire,
        });
    }

}

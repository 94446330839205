import {Component, Input, OnInit} from '@angular/core';
import {SgiListLayoutComponent} from '@shared/behaviors/list-layout/sgi/sgi-list-layout.component';

@Component({
    selector: 'app-specialites-table',
    templateUrl: './specialites-table.component.html'
})

export class SpecialitesTableComponent extends SgiListLayoutComponent implements OnInit {

    @Input() specialites: any[] = [];

    constructor() {
        super();
        this.cols = [
            {field: 'codeSpecialisation', header: 'Code '},
            {field: 'libelleSpecialisation', header: 'Libelé '},
        ];
        this.tableActions = [];
        this.notRowActions = [];
    }

    ngOnInit(): void {
    }

    /**
     * Définition des arguments
     */
    defineArgs(): void {
        super.defineArgs();
        const arg = {...this.args};
        this.args = {...arg};
    }

    formatTableListAddIndex(responseData) {
        if (responseData === undefined) {
            this.dataSource = [];
            this.totalRecords = 0;
            return;
        }
        this.dataSource = responseData;
        this.totalRecords = responseData.length();
        if (this.dataSource && this.dataSource.length > 0) {
            this.dataSource.forEach((dat, index) => {
                dat.id_key = index + 1;
            });
        }
    }

}

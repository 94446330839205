import {Injectable} from '@angular/core';
import {Autowired} from '@angular-ru/autowired';
import {WebUtilities} from '@app/core/utilities/web.utilities';
import {Observable} from 'rxjs';
import {IRecuperationListeDonneesRequete} from '@app/core/interfaces/actions/i-recuperation-liste-donnees-requete';
import {SgiRestService} from '@app/core/services/sgi/sgi-rest.service';

@Injectable({providedIn: 'root'})
export class MedecinFormRestApiService implements IRecuperationListeDonneesRequete {
  @Autowired() restService: SgiRestService;

  requeteRecuperationListeDonnees(data: any, args?: any): Observable<any> | Promise<any> | any {
    return this.restService.getRequest(`${WebUtilities.baseUrlApi()}evenement`);
  }

  requeteSuppression(data: any, args?: any): Observable<any> | Promise<any> | any {
    let id = null; try { id = data.id ? data.id : null; } catch (e) {}
    return this.restService.deleteRequest(`${WebUtilities.baseUrlApi()}evenement/${id}`);
  }
}
